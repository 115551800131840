import {select} from "redux-saga/effects"
import {propOr} from "ramda"

import {GET_ACTIVE_NODE} from "lib/redux/selectors"
import {
  CREATEPOINT_SET_FILTER,
  CREATEPOINT_SET_SEARCH_TEXT,
  CREATEPOINT_SET_SORT_OPTION,
} from "lib/redux/actions"

function* trackSort({location}) {
  try {
    const node = yield select(GET_ACTIVE_NODE)
    const detail = propOr("Qualcomm", "title", node)
    const {pathname: label} = location
    return {
      event: {
        action: "click",
        detail,
        ev: "sort",
        label,
        location: "document",
      },
      type: "link",
    }
  } catch (error) {
    // console.error(error)
    return null
  }
}

function* trackFilters({location}) {
  try {
    const node = yield select(GET_ACTIVE_NODE)
    const detail = propOr("Qualcomm", "title", node)
    const {pathname: label} = location
    return {
      event: {
        action: "filter select",
        detail,
        ev: "filter",
        label,
        location: "document",
      },
      type: "link",
    }
  } catch (error) {
    // console.error(error)
    return null
  }
}

function* trackSearch({query}) {
  try {
    const node = yield select(GET_ACTIVE_NODE)
    const detail = propOr("Qualcomm", "title", node)
    return {
      event: {
        detail,
        ev: "search results",
        label: "search",
        location: "document",
        search_term: query,
      },
      type: "link",
    }
  } catch (error) {
    // console.error(error)
    return null
  }
}

const CREATEPOINT_EVENT_MAP = {
  [CREATEPOINT_SET_FILTER]: trackFilters,
  [CREATEPOINT_SET_SEARCH_TEXT]: trackSearch,
  [CREATEPOINT_SET_SORT_OPTION]: trackSort,
}

export default CREATEPOINT_EVENT_MAP
