import React from "react"
import { string } from "prop-types"

const propTypes = {
    unique: string,
}

const HamburgerWosIcon = ({ unique = "", ...props }) => (
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <line x1="0.75" y1="1.25" x2="16.25" y2="1.25" stroke="#222222" stroke-width="1.5" stroke-linecap="round" />
        <line x1="0.75" y1="7.25" x2="16.25" y2="7.25" stroke="#222222" stroke-width="1.5" stroke-linecap="round" />
        <line x1="0.75" y1="13.25" x2="16.25" y2="13.25" stroke="#222222" stroke-width="1.5" stroke-linecap="round" />
    </svg>
)

HamburgerWosIcon.propTypes = propTypes
export default HamburgerWosIcon
