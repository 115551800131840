import React, {ReactElement} from "react"
import {useDispatch, useSelector} from "react-redux"
import {isEmpty} from "ramda"
import {GET_NAVIGATION_MAIN_MENU_PRODUCT_CTA} from "lib/redux/selectors"
import {TabMenuProps, ProductCtaProps} from "./TabMenu.interface"
import { MegaMenuItemType } from "../../utils/common.interface";
import TabMenuItem from "components/dx/atoms/TabMenuItem/TabMenuItem"
import CtaV2Dx from "components/dx/atoms/CtaV2Dx/CtaV2Dx"
import { INTERNAL_LINK } from "lib/redux/actions"

const ProductCta = ({
    ctaTitle,
    url,
    target,
    color,
    size,
    iconType, 
    style,
    width,
    targetType
}: ProductCtaProps) => (
    <div className="flex items-center">
        <CtaV2Dx
            ctaTitleClass="text-[14px] font-[520] leading-5 font-['Roboto_Flex']"
            color={color}
            ctaTitle={ctaTitle}
            iconType={iconType}
            size={size}
            style={style}
            width={width}
            target={target}
            targetType={targetType}
            url={url}
            megamenuCta={true}
        />
    </div>
)

const TabMenu = ({
    tabMenuItems,
    selectedItem,
    handleItemClick
}: TabMenuProps): ReactElement => {
    const dispatch = useDispatch()

    const trackAnalytics = (item:MegaMenuItemType)=>{
        const configuredAnalytics = {
            pathname:"/"+item.title,
            state: {
                text:item.title,
                detail: item.title,
                type: "megamenu",
                header_menu: "navigation"
            },
        }
        dispatch({ location: configuredAnalytics, type: INTERNAL_LINK })
        handleItemClick(item)
    }
    const productCta: any = useSelector(GET_NAVIGATION_MAIN_MENU_PRODUCT_CTA)

    const displayProductCta = productCta && !isEmpty(productCta) && productCta.ctaTitle && productCta.url

    return (
        <div className="bg-[#F8F8F8] w-full">
            <div className="flex m-auto justify-between w-full max-w-[1680px] px-s sm:px-l xl:px-[130px]">
                <div className="flex items-center gap-x-[24px]">
                    {tabMenuItems.map((item: MegaMenuItemType) => (
                        <TabMenuItem key={item.title} item={item} isActive={selectedItem === item} handleClick={(e)=>trackAnalytics(item)} />
                    ))}
                </div>

                {displayProductCta && <ProductCta {...productCta} />}
            </div>
        </div>
    )
}

export default TabMenu