import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Twitter = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id="Glyphs/Social/*Twitter"
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-twitter${unique}`}
        xlinkHref={`#path-1-twitter${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-twitter${unique}`}>
        <path
          d="M19.9627635,7.95293132 L19.9627635,8.48378222 C19.9627635,13.9015388 15.8421296,20.1517311 8.29635329,20.1517311 C5.9821511,20.1517311 3.80335436,19.6439607 2,18.4853209 C2.32158794,18.5314819 2.64625327,18.5545623 2.9709186,18.5545623 C4.89429143,18.5545623 6.67610402,17.7221266 8.06554855,16.6096477 C6.28219726,16.5865673 4.75273119,15.3848439 4.24496076,13.763056 C4.50038467,13.8092169 4.75273119,13.8322974 5.0081551,13.8322974 C5.37744268,13.8322974 5.75134636,13.7861364 6.09601477,13.6922758 C4.22188029,13.3229882 2.80935529,11.656578 2.80935529,9.66550248 L2.80935529,9.61934153 C3.36482536,9.91938769 3.98953685,10.1040315 4.66040929,10.1286507 C3.55100785,9.3869981 2.83243576,8.13757511 2.83243576,6.7250501 C2.83243576,5.96185577 3.04016002,5.33560557 3.38944453,4.73397455 C5.42668103,7.21127875 8.43483613,8.7638253 11.836898,8.94693039 C11.7676566,8.64842293 11.7461148,8.3222189 11.7461148,7.99755357 C11.7461148,5.73105103 13.5740883,3.9000001 15.8421296,3.9000001 C17.0238498,3.9000001 18.0870903,4.40930922 18.8533621,5.19712273 C19.7796584,5.01247894 20.6567164,4.66473313 21.4445299,4.20158495 C21.1444838,5.15096178 20.4951531,5.96185577 19.6380982,6.47116489 C20.4736113,6.3773043 21.2614248,6.14649956 22,5.82183422 C21.4445299,6.65580868 20.750577,7.37284207 19.9627635,7.95293132"
          fill="inherit"
          id={`path-1-twitter${unique}`}
        />
      </g>
    </g>
  </svg>
)

Twitter.propTypes = propTypes
export default Twitter
