import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Services = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/general/services-services${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`simplified_icons-general-services-services${unique}`}
        xlinkHref={`#path-1-services${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-services${unique}`}>
        <path
          d="M20.7642714,17.7123526 L13.3299402,10.2329547 C14.0652037,8.34255742 13.656724,6.12339541 12.104501,4.56176288 C10.4705821,2.91793917 8.01970364,2.58917443 6.0590009,3.49327747 L9.2215671,6.67501504 C9.4154069,6.8700299 9.4154069,7.184967 9.2215671,7.37998185 L7.47161556,9.14054155 C7.27769423,9.33563842 6.96260113,9.33744933 6.76645016,9.1445943 L3.52642654,5.95901304 C2.54607518,7.93160149 2.95455491,10.3973371 4.58847386,12.0411608 C6.14069685,13.6027933 8.34648742,14.0137492 10.2254942,13.2740286 L17.6598254,20.7534264 C17.9866092,21.0821912 18.4767849,21.0821912 18.8035687,20.7534264 L20.6825754,18.8630292 C21.0910552,18.5342644 21.0910552,17.9589261 20.7642714,17.7123526 Z"
          fill="inherit"
          id={`path-1-services${unique}`}
        />
      </g>
    </g>
  </svg>
)

Services.propTypes = propTypes
export default Services
