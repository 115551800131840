import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const MinusCircleOutline = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/minus_circle-minus-circle${unique}`}
      stroke="none"
      strokeWidth="1"
    >
      <g
        fill="inherit"
        id={`combined-shape-minus-circle${unique}`}
        transform="translate(-1255.000000, -544.000000)"
      >
        <g
          id={`combined-shape-minus-circle-2${unique}`}
          transform="translate(130.000000, 433.000000)"
        >
          <g
            id={`glyphs/nav/minus-Expanded${unique}`}
            transform="translate(0.000000, 71.000000)"
          >
            <g
              id={`glyphs/nav/minus-Expanded/Icons/16px/Collapse/Gray${unique}`}
              transform="translate(1125.000000, 40.000000)"
            >
              <path
                d="M12.00285,1.50015 C6.21135,1.50015 1.49985,6.21165 1.49985,12.00015 C1.49985,17.79015 6.21135,22.49865 12.00285,22.49865 C17.79135,22.49865 22.49985,17.79015 22.49985,12.00015 C22.49985,6.21165 17.79135,1.50015 12.00285,1.50015 M12.0032786,0.00015 C5.38436236,0.00015 -0.000257150511,5.38476951 -0.000257150511,12.0002572 C-0.000257150511,18.6174591 5.38436236,23.99865 12.0032786,23.99865 C18.6187662,23.99865 23.9999572,18.6174591 23.9999572,12.0002572 C23.9999572,5.38476951 18.6187662,0.00015 12.0032786,0.00015 M17.25585,13.42605 L6.73785,13.42605 C5.95335,13.42605 5.31435,12.78705 5.31435,12.00255 C5.31435,11.21355 5.95335,10.57305 6.73785,10.57305 L17.25585,10.57305 C18.04335,10.57305 18.68235,11.21205 18.68235,11.99805 C18.68235,12.78555 18.04335,13.42605 17.25585,13.42605"
                id={`path-1-minus-circle-outline${unique}`}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

MinusCircleOutline.propTypes = propTypes
export default MinusCircleOutline
