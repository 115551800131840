import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const WechatBox = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/wechat_box-wechat-box${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`combined-shape-wechat-box${unique}`}
        xlinkHref={`#path-1-wechat-box${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-wechat-box${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M10.25,6.05000019 C7.35025,6.05000019 5,8.00912519 5,10.4250002 C5,11.8180002 5.78575,13.0535002 7.004625,13.8532502 L6.3125,15.2375002 L8.198125,14.4298752 C8.602375,14.5733752 9.022375,14.6915002 9.47125,14.7466252 C9.41,14.4797502 9.375,14.2058752 9.375,13.9250002 C9.375,11.5126252 11.729625,9.55000019 14.625,9.55000019 C14.890125,9.55000019 15.149125,9.57187519 15.404625,9.60337519 C14.942625,7.58037519 12.812,6.05000019 10.25,6.05000019 Z M19,13.9250002 C19,11.9921252 17.040875,10.4250002 14.625,10.4250002 C12.209125,10.4250002 10.25,11.9921252 10.25,13.9250002 C10.25,15.8578752 12.209125,17.4250002 14.625,17.4250002 C15.02225,17.4250002 15.40025,17.3690002 15.766875,17.2893752 L18.125,18.3000002 L17.3095,16.6698752 C18.3315,16.0293752 19,15.0441252 19,13.9250002 Z M8.5,9.33125019 C8.13775,9.33125019 7.84375,9.03725019 7.84375,8.67500019 C7.84375,8.31275019 8.13775,8.01875019 8.5,8.01875019 C8.86225,8.01875019 9.15625,8.31275019 9.15625,8.67500019 C9.15625,9.03725019 8.86225,9.33125019 8.5,9.33125019 Z M12,9.33125019 C11.63775,9.33125019 11.34375,9.03725019 11.34375,8.67500019 C11.34375,8.31275019 11.63775,8.01875019 12,8.01875019 C12.36225,8.01875019 12.65625,8.31275019 12.65625,8.67500019 C12.65625,9.03725019 12.36225,9.33125019 12,9.33125019 Z M13.3125,13.7062502 C12.95025,13.7062502 12.65625,13.4122502 12.65625,13.0500002 C12.65625,12.6877502 12.95025,12.3937502 13.3125,12.3937502 C13.67475,12.3937502 13.96875,12.6877502 13.96875,13.0500002 C13.96875,13.4122502 13.67475,13.7062502 13.3125,13.7062502 Z M15.9375,13.7062502 C15.57525,13.7062502 15.28125,13.4122502 15.28125,13.0500002 C15.28125,12.6877502 15.57525,12.3937502 15.9375,12.3937502 C16.29975,12.3937502 16.59375,12.6877502 16.59375,13.0500002 C16.59375,13.4122502 16.29975,13.7062502 15.9375,13.7062502 Z"
          fill="inherit"
          id={`path-1-wechat-box${unique}`}
        />
      </g>
    </g>
  </svg>
)

WechatBox.propTypes = propTypes
export default WechatBox
