import { parse } from "query-string";
import {
  filter,
  forEachObjIndexed,
  is,
  join,
  map,
  omit,
  pipe,
  reduce,
  replace,
  toPairs,
} from "ramda";

import { ensureArray, hasData } from "lib/utils/helpers";

/**
 * @param search {string}
 * @returns {any}
 */
const parseLocationSearch = (search) =>
  parse(search, { arrayFormat: "comma", decode: false });

const amp = encodeURIComponent("&");
const comma = encodeURIComponent(",");
const minus = encodeURIComponent("-");
const plus = encodeURIComponent("+");

export const encodeUrlString = pipe(
  replace("&", amp),
  replace(/,/g, comma),
  replace(/-/g, minus),
  replace(/\+/g, plus),
  replace("/%20/g", "+"),
  replace(/ /g, "+"),
);

/**
 * Converts the filters from the url's representation to the format expected by the FilterStateContext.
 * @param filters
 * @returns {Object}
 */
export function urlToFilters(filters) {
  const activeFilters = {};
  const activeFiltersWithParent = {};
  forEachObjIndexed((value, key) => {
    const val = value?.replaceAll(", ", "*");
    const arrTransformed = val?.split(",");
    const transformed = pipe(ensureArray)(arrTransformed);
    const decodedTransformed = map((str) => {
      const transformedStr = str.replaceAll("*", ", ");
      const decondeVal = pipe(
        replace(/\+/g, " "),
        decodeURIComponent,
      )(transformedStr);
      activeFilters[decondeVal] = true;
      return decondeVal;
    }, transformed);
    activeFiltersWithParent[key] = decodedTransformed;
  }, filters);
  return [activeFilters, activeFiltersWithParent];
}

/**
 * Converts the filters from their Context format to string format, for the query params.
 * @param activeFiltersWithParent {Map<string,string[]>}
 * @param noQuery {boolean}
 * @returns {string}
 */
export const serializeFilters = pipe(
  toPairs,
  filter(([, values]) => values.length > 0),
  map(
    ([key, values]) =>
      `${encodeUrlString(key)}=${pipe(
        map(encodeUrlString),
        join(","),
      )(values)}`,
  ),
  join("&"),
);

/**
 * Extracts the query and filters from React Router's props.
 * @param search
 */
export function deserialize(search) {
  const parsed = parseLocationSearch(search);
  const filters = omit(["query", "activeCategory", "filters"], parsed);
  const [activeFilters, activeFiltersWithParent] = urlToFilters(filters);
  return {
    activeCategory: parsed.activeCategory || "",
    activeFilters,
    activeFiltersWithParent,
    searchQuery: is(Array, parsed.query)
      ? pipe(join("|"))(parsed.query)
      : parsed.query || "",
  };
}

/**
 * @param activeCategory
 * @param activeFilters
 * @param activeFiltersWithParent
 * @param pathname
 * @param query
 * @returns {string|*}
 */
export function serialize({
  activeCategory,
  activeFilters,
  activeFiltersWithParent,
  pathname,
  query,
  hash = "",
}) {
  const url = [];
  if (query) {
    url.push(`?query=${encodeUrlString(query)}`);
  }

  if (activeCategory) {
    url.push(url.length > 0 ? "&" : "?");
    url.push(`activeCategory=${encodeUrlString(activeCategory)}`);
  }

  if (hasData(activeFilters)) {
    url.push(url.length > 0 ? "&" : "?");
    url.push(serializeFilters(activeFiltersWithParent));
  }

  return `${pathname}${url.join("")}${hash}`;
}

/**
 * Creates a list of 1 or 0's depending on whether any children are checked
 */
export const createListOfCheckedChildren = (rootChildren, activeFilters) =>
  reduce(
    (acc, { children, id }) => {
      if (children?.length) {
        acc.push(...createListOfCheckedChildren(children, activeFilters));
      } else {
        acc.push(activeFilters[id] ? 1 : 0);
      }
      return acc;
    },
    [],
    rootChildren,
  );
