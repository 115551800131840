import {
  __,
  append,
  curry,
  memoizeWith,
  mergeDeepRight,
  pipe,
  prop,
  propOr,
  unnest,
} from "ramda";
import { darken, transparentize } from "polished";

const isHex = (color) => /^#[0-9A-F]{6}$/i.test(color);

export const defaultColors = {
  aqua: "#00a2b6",
  "aqua-2": "#00a2b6",
  black: "#000000",
  blue: "#005daa",
  "blue-1dx": "#276cb1",
  "blue-2": "#0033a0",
  boulder: "#7a7a7a",
  "color-info-search-grey": "#acacac",
  coolgray: "#4b555a",
  "coolgray-dark": "#2a2f32",
  crimson: "#e31215",
  dark: "#292f32",
  "dark-aqua": "#00acbd",
  "dark-blue": "#003b66",
  gray: "#e6e8e9",
  "gray-94": "#f0f0f0",
  "gray-border-bottom": "#cfd0d1",
  "gray-dark": "#222222",
  "gray-divider": "#eaeaea",
  "gray-drawer-base": "#272727",
  "gray-drawer-panel": "#313131",
  "gray-footer": "#b2b2b2",
  "gray-light": "#fafafa",
  "gray-menu": "#adadad",
  "gray-sub": "#777a7d",
  green: "#008d95",
  hollow: "rgba(0,0,0,0.05)",
  "hollow-inverted": "rgba(255,255,255,0.1)",
  light: "#fafafa",
  "light-aqua": "#89d1cc",
  "light-blue": "#70cfee",
  "light-blue-2": "#20b7e0",
  "light-grey": "#f8f8f8",
  "light-orange": "#ffb500",
  "light-purple": "#9d4d8c",
  "mine-shaft": "#3c3c3c",
  orange: "#f79111",
  "orange-2": "#f39100",
  purple: "#8f297c",
  red: "#ed2224",
  "red-1dx": "#e2231a",
  "red-2": "#e2231a",
  "red-bg-1dx": "#fce9e8",
  violet: "#8c3c93",
  white: "#ffffff",
  "white-alt": "#fafafa",
  "white-transparent": "rgba(255,255,255,0)",
};

export const defaultColorRoles = {
  background: "white",
  "background-button": "aqua",
  "background-dark": "gray",
  "background-light": "light",
  border: "gray",
  "border-focus": "blue",
  "border-hover": "blue",
  divider: "gray-divider",
  error: "red-1dx",
  "error-background": "red-bg-1dx",
  icon: "aqua",
  "icon-input": "aqua",
  "icon-light": "white",
  placeholder: "gray",
  shadow: "black",
  success: "aqua",
  text: "gray",
  "text-light": "light",
  "text-mid": "gray-sub",
  "text-title": "dark",
  warning: "orange",
};

export const breakpoints = [668, 1024, 1360];
export const fontSizes = [12, 14, 16, 18, 20, 24, 32, 48, 64];
export const lineHeights = [1, 1.2];
export const fontWeights = [200, 400, 600];
export const spacings = [0, 8, 16, 24, 32, 64];
export const defaultTheme = {
  breakpoints,
  defaultColorRoles,
  defaultColors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacings,
};

const themeKey = (theme) => JSON.stringify(theme);
const merge = memoizeWith(themeKey, (theme) =>
  mergeDeepRight(defaultTheme, theme),
);

export const getTheme = (props) => {
  const theme = prop("theme", props);
  return theme ? merge(theme) : defaultTheme;
};

export const getThemeValues = (key, theme) => prop(key, theme);

const getColor = (colorRole, theme) => {
  const themeColorRoles = prop("colorRoles", theme);
  const themeColors = prop("colors", theme);
  const colorName = propOr(colorRole, colorRole, themeColorRoles);
  return propOr(colorName, colorName, themeColors);
};

export const colors = curry((colorRole, props) => {
  const theme = getTheme(props);
  return getColor(colorRole, theme);
});

export const themeTransparentize = curry((amount, colorName, props) => {
  const color = colors(colorName, props);
  return isHex(color) && transparentize(amount, color);
});

export const themeDarken = curry((amount, colorName, props) => {
  const color = colors(colorName, props);
  return isHex(color) && darken(amount, color);
});

export const getArr = pipe(append(__, []), unnest);

export const arrToStyles = curry((breakpts, vals) =>
  vals.reduce(
    (accum, val, i) =>
      i === 0
        ? `
    ${val}
  `
        : `
    ${accum}
    @media (min-width: ${breakpts[i - 1]}px) {
      ${val}
    }
  `,
    "",
  ),
);
