import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Stop = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/av/stop-stop${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`rectangle-stop${unique}`}
        xlinkHref={`#path-1-stop${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-stop${unique}`}>
        <rect
          fill="inherit"
          height={18}
          id={`path-1-stop${unique}`}
          rx={1}
          width={18}
          x={3}
          y={3}
        />
      </g>
    </g>
  </svg>
)

Stop.propTypes = propTypes
export default Stop
