import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string
}

const Download = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/download-download${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-download${unique}`}
        xlinkHref={`#path-1-download${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-download${unique}`}>
        <path
          d="M18,20 L18,15.5 C18,15.2238576 18.2238576,15 18.5,15 L19.5,15 C19.7761424,15 20,15.2238576 20,15.5 L20,20.5 L20,21.5 C20,21.7761424 19.7761424,22 19.5,22 L4.5,22 C4.22385763,22 4,21.7761424 4,21.5 L4,20.5 L4,15.5 C4,15.2238576 4.22385763,15 4.5,15 L5.5,15 C5.77614237,15 6,15.2238576 6,15.5 L6,20 L18,20 Z M14.5,9 L18.5074145,9 C18.7835569,9 19.0074145,9.22385763 19.0074145,9.5 C19.0074145,9.63968935 18.9489787,9.77300947 18.8462581,9.86767515 L12.3388453,15.8648037 C12.1473998,16.0412369 11.8526036,16.0412369 11.6611581,15.8648037 L5.15374535,9.86767515 C4.95068397,9.680537 4.93777564,9.36421778 5.1249138,9.1611564 C5.21957948,9.0584358 5.35289959,9 5.49258895,9 L9.5,9 L9.5,2.5 C9.5,2.22385763 9.72385763,2 10,2 L14,2 C14.2761424,2 14.5,2.22385763 14.5,2.5 L14.5,9 Z"
          fill="inherit"
          id={`path-1-download${unique}`}
        />
      </g>
    </g>
  </svg>
)

Download.propTypes = propTypes
export default Download
