import React, {memo, useMemo} from "react"
import {bool, func, node as nodeType, number, shape} from "prop-types"
import {useSpring} from "react-spring"
import {easeCubic} from "d3-ease"

import Primitives from "primitives"

const propTypes = {
  children: nodeType,
  config: shape({friction: number, mass: number, tension: number}),
  delay: number,
  ease: func,
  enterDuration: number,
  exitDuration: number,
  fadeInOut: bool,
  show: bool,
  showAnimation: bool,
}

const TransitionWidth = ({
  children,
  config = {friction: 30, mass: 1, tension: 180},
  delay = 0,
  enterDuration,
  exitDuration,
  ease = easeCubic,
  fadeInOut = false,
  show,
  ...props
}) => {
  const configVals = useMemo(() => {
    if (enterDuration && exitDuration) {
      return {
        duration: show ? enterDuration : exitDuration,
        ease,
      }
    }
    return config
  }, [config, ease, enterDuration, exitDuration, show])
  const styles = useSpring({
    config: configVals,
    delay,
    from: {opacity: fadeInOut ? 1 : 0, transform: "scaleX(0)"},
    to: {opacity: 1, transform: show ? "scaleX(1)" : "scaleX(0)"},
  })
  return (
    <Primitives.AnimatedBox style={styles} {...props}>
      {children}
    </Primitives.AnimatedBox>
  )
}

TransitionWidth.propTypes = propTypes
TransitionWidth.displayName = "Animations.TransitionWidth"

export default memo(TransitionWidth)
