import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const ChipCode = ({unique = "", ...props}) => (
  <svg viewBox="3 3 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/technology/chip-code${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-chip-code${unique}`}
        xlinkHref={`#path-1-chip-code${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-chip-code${unique}`}>
        <path
          d="M20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 L20,3 Z M18.5,14 L12.5,14 C12.2238576,14 12,14.2238576 12,14.5 L12,14.5 L12,15.5 C12,15.7761424 12.2238576,16 12.5,16 L12.5,16 L18.5,16 C18.7761424,16 19,15.7761424 19,15.5 L19,15.5 L19,14.5 C19,14.2238576 18.7761424,14 18.5,14 L18.5,14 Z M6.06066017,8.14644661 L5.35355339,8.85355339 C5.15829124,9.04881554 5.15829124,9.36539803 5.35355339,9.56066017 L7.79289322,12 L5.35355339,14.4393398 C5.15829124,14.634602 5.15829124,14.9511845 5.35355339,15.1464466 L6.06066017,15.8535534 C6.25592232,16.0488155 6.57250481,16.0488155 6.76776695,15.8535534 L10.267767,12.3535534 C10.4630291,12.1582912 10.4630291,11.8417088 10.267767,11.6464466 L6.76776695,8.14644661 C6.57250481,7.95118446 6.25592232,7.95118446 6.06066017,8.14644661 Z"
          id={`path-1-chip-code${unique}`}
        />
      </g>
    </g>
  </svg>
)

ChipCode.propTypes = propTypes

export default ChipCode
