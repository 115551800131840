/**
 * Primitives are base level components that handle style related props. These are
 * the basis for all components in the library and allow higher level components to
 * be composed of a common set of functionality with predictable rules.
 * Primitives styles can be extended using styled-components. All primitive
 * components accept an "tag" prop which will dictate the underlying element.
 * Primitives components all automatically remove style related props from being
 * passed any further down the component tree.
 */
import AnimatedBox from "./AnimatedBox";
import Button from "./Button";
import Box from "./Box";
import Flex from "./Flex";
import Image from "./Image";
import Input from "./Input";
import RawHtml from "./RawHtml";
import Text from "./text";
import AnimatedRenderProps from "./AnimatedRenderProps";

export { default as customSystemProps } from "./custom";
export * from "./shared";

const Primitives = {
  AnimatedBox,
  AnimatedRenderProps,
  Box,
  Button,
  Flex,
  Image,
  Input,
  RawHtml,
  Text,
};
export default Primitives;
