import React, { ReactElement } from "react"
import {useDispatch, useSelector} from "react-redux"
import { GET_CURRENT_THEME_SETTING} from "lib/redux/selectors"
import { INTERNAL_LINK, SET_CURRENT_THEME, THEME_OPTIONS } from "lib/redux/actions"
import { cn } from "lib/utils/cn-helper"

const FooterThemeLink = (): ReactElement => {
  const dispatch = useDispatch()
  const currentTheme = useSelector(GET_CURRENT_THEME_SETTING)
  const updateTheme = (theme: string): void => {
    dispatch({ location: {
      label: "styling_tile",
      state: {
        document: theme,
        text: "click",
        type: "footer",
      }
    }, type: INTERNAL_LINK })
    dispatch({ type: SET_CURRENT_THEME, value: theme })
  }

  return  (
    <div className="flex flex-row rounded justify-between items-center w-[150px] p-[3px] border border-theme-neutral-300 mt-s">
      {THEME_OPTIONS.map(obj=>{
        const activeClasses = currentTheme === obj.value ? [
          "bg-[var(--theme-themeActive-background)]",
          "text-[var(--theme-themeActive-font-color)]"
        ] : [
          "text-[var(--theme-themeInactive-font-color)]"
        ]
        return (
          <button
            key={obj.value}
            className={cn([
              "body-xs",
              "flex",
              "items-center",
              "rounded-[2px]",
              "px-[10px]",
              "h-[24px]",
              "cursor-pointer",
              ...activeClasses
            ])}
            onClick={(): void => updateTheme(obj.value)}
          >
            {obj.label}
          </button>
        )
      })}
    </div>
  )
}

export default FooterThemeLink
