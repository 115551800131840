import { cva } from "class-variance-authority"

export const navMenuItemTextClass = cva(
    "body-s",
    {
        variants: {
            activeState: {
                active: "text-theme-blue-500",
                inactive: "text-theme-neutral-800"
            }
        }
    }
)