import RotateClockwise from 'components/animations/rotate-clockwise'
import { ArrowDownIcon } from 'components/glyphs/icons'
import React from 'react'
import iconStyles from "./Icon.module.css"

export const ArrowIcon = ({ direction, className, props }) => {
    return (
        <RotateClockwise {...props} className={`${iconStyles?.icon} ${className}`} defaultAngle={!direction ? 180 : 0}><ArrowDownIcon /></RotateClockwise>
    )
}

export const OpacityBar = () => {
    return (
        <div className={iconStyles?.opacityBar}> |</div>
    )
}