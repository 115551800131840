import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Launch = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/launch-launch${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-launch${unique}`}
        xlinkHref={`#path-1-launch${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-launch${unique}`}>
        <path
          d="M17.5857864,5 L14.5,5 C14.2238576,5 14,4.77614237 14,4.5 L14,3.5 C14,3.22385763 14.2238576,3 14.5,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,5 L21,9.5 C21,9.77614237 20.7761424,10 20.5,10 L19.5,10 C19.2238576,10 19,9.77614237 19,9.5 L19,6.41421356 L9.56066017,15.8535534 C9.36539803,16.0488155 9.04881554,16.0488155 8.85355339,15.8535534 L8.14644661,15.1464466 C7.95118446,14.9511845 7.95118446,14.634602 8.14644661,14.4393398 L17.5857864,5 Z M5,3 L11.5,3 C11.7761424,3 12,3.22385763 12,3.5 L12,4.5 C12,4.77614237 11.7761424,5 11.5,5 L5,5 L5,19 L19,19 L19,12.5 C19,12.2238576 19.2238576,12 19.5,12 L20.5,12 C20.7761424,12 21,12.2238576 21,12.5 L21,20 C21,20.5522847 20.5522847,21 20,21 L19,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,19 L3,4 C3,3.44771525 3.44771525,3 4,3 L5,3 Z"
          fill="inherit"
          id={`path-1-launch${unique}`}
        />
      </g>
    </g>
  </svg>
)

Launch.propTypes = propTypes
export default Launch
