import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Googleplus = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/googleplus-googleplus${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`fill-1-googleplus${unique}`}
        xlinkHref={`#path-1-googleplus${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-googleplus${unique}`}>
        <path
          d="M8.00000799,11.000032 L8.00000799,13.4000272 L11.9700253,13.4000272 C11.8100432,14.4299779 10.7699838,16.419997 8.00000799,16.419997 C5.61003364,16.419997 3.66001007,14.4399987 3.66001007,12.000008 C3.66001007,9.56001726 5.61003364,7.58001902 8.00000799,7.58001902 C9.36003164,7.58001902 10.2699958,8.15999808 10.7900255,8.6599861 L12.6900327,6.8299491 C11.4700373,5.69003268 9.88999433,5 8.00000799,5 C4.13002341,5 1,8.13002341 1,12.000008 C1,15.8699926 4.13002341,19.000016 8.00000799,19.000016 C12.0399955,19.000016 14.7200473,16.1599821 14.7200473,12.1599901 C14.7200473,11.6999976 14.6700309,11.3499708 14.6099937,11.000032 L8.00000799,11.000032 M23,11.0071831 L21.0000479,11.0071831 L21.0000479,9.00714316 L19.000008,9.00714316 L19.000008,11.0071831 L17.0000559,11.0071831 L17.0000559,13.0071352 L19.000008,13.0071352 L19.000008,15.0070872 L21.0000479,15.0070872 L21.0000479,13.0071352 L23,13.0071352 L23,11.0071831"
          fill="inherit"
          id={`path-1-googleplus${unique}`}
        />
      </g>
    </g>
  </svg>
)

Googleplus.propTypes = propTypes
export default Googleplus
