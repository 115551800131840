import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Book = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/media/book-book${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-book${unique}`}
        xlinkHref={`#path-1-book${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-book${unique}`}>
        <path
          d="M4,20 L4,17 L4,3 C4,2.44771525 4.44771525,2 5,2 L17,2 C17.5522847,2 18,2.44771525 18,3 L18,5 L18.0177222,5 C18.1390296,5.84807354 18.868382,6.5 19.75,6.5 C19.8880849,6.5 20.0000248,6.6119399 20.0000248,6.75002476 L20,21 C20,21.5522847 19.5522847,22 19,22 L7,22 L6,22 C4.8954305,22 4,21.1045695 4,20 Z M18,18 L6.5,18 C5.67157288,18 5,18.6715729 5,19.5 C5,20.3284271 5.67157288,21 6.5,21 L18.5,21 C18.7761424,21 19,20.7761424 19,20.5 L19,6.97499547 C18.5148805,6.87652142 18.1317807,6.49710276 18.0279219,6.01396097 L18,6.5 L18,18 Z"
          fill="inherit"
          id={`path-1-book${unique}`}
        />
      </g>
    </g>
  </svg>
)

Book.propTypes = propTypes
export default Book
