import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Wechat = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/wechat-wechat${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`combined-shape-wechat${unique}`}
        xlinkHref={`#path-1-wechat${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-wechat${unique}`}>
        <path
          d="M9.5,4 C5.3575,4 2,6.79875 2,10.25 C2,12.24 3.1225,14.005 4.86375,15.1475 L3.875,17.125 L6.56875,15.97125 C7.14625,16.17625 7.74625,16.345 8.3875,16.42375 C8.3,16.0425 8.25,15.65125 8.25,15.25 C8.25,11.80375 11.61375,9 15.75,9 C16.12875,9 16.49875,9.03125 16.86375,9.07625 C16.20375,6.18625 13.16,4 9.5,4 Z M7,8.6875 C6.4825,8.6875 6.0625,8.2675 6.0625,7.75 C6.0625,7.2325 6.4825,6.8125 7,6.8125 C7.5175,6.8125 7.9375,7.2325 7.9375,7.75 C7.9375,8.2675 7.5175,8.6875 7,8.6875 Z M12,8.6875 C11.4825,8.6875 11.0625,8.2675 11.0625,7.75 C11.0625,7.2325 11.4825,6.8125 12,6.8125 C12.5175,6.8125 12.9375,7.2325 12.9375,7.75 C12.9375,8.2675 12.5175,8.6875 12,8.6875 Z M22,15.25 C22,16.84875 21.045,18.25625 19.585,19.17125 L20.75,21.5 L17.38125,20.05625 C16.8575,20.17 16.3175,20.25 15.75,20.25 C12.29875,20.25 9.5,18.01125 9.5,15.25 C9.5,12.48875 12.29875,10.25 15.75,10.25 C19.20125,10.25 22,12.48875 22,15.25 Z M13.875,14.9375 C14.3925,14.9375 14.8125,14.5175 14.8125,14 C14.8125,13.4825 14.3925,13.0625 13.875,13.0625 C13.3575,13.0625 12.9375,13.4825 12.9375,14 C12.9375,14.5175 13.3575,14.9375 13.875,14.9375 Z M17.625,14.9375 C18.1425,14.9375 18.5625,14.5175 18.5625,14 C18.5625,13.4825 18.1425,13.0625 17.625,13.0625 C17.1075,13.0625 16.6875,13.4825 16.6875,14 C16.6875,14.5175 17.1075,14.9375 17.625,14.9375 Z"
          fill="inherit"
          id={`path-1-wechat${unique}`}
        />
      </g>
    </g>
  </svg>
)

Wechat.propTypes = propTypes
export default Wechat
