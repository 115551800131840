import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Webinar = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/media/webinar-webinar${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-webinar${unique}`}
        xlinkHref={`#path-1-webinar${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-webinar${unique}`}>
        <path
          d="M9,19 L9,17 L2.5,17 C2.22385763,17 2,16.7761424 2,16.5 L2,4.5 C2,4.22385763 2.22385763,4 2.5,4 L21.5,4 C21.7761424,4 22,4.22385763 22,4.5 L22,16.5 C22,16.7761424 21.7761424,17 21.5,17 L15,17 L15,19 L17.5,19 C17.7761424,19 18,19.2238576 18,19.5 C18,19.7761424 17.7761424,20 17.5,20 L6.5,20 C6.22385763,20 6,19.7761424 6,19.5 C6,19.2238576 6.22385763,19 6.5,19 L9,19 Z M3,5 L3,16 L21,16 L21,5 L3,5 Z M10.0736072,7.8618034 L14.4555732,10.0527864 C14.7025624,10.176281 14.8026746,10.4766175 14.67918,10.7236068 C14.6307981,10.8203705 14.5523369,10.8988317 14.4555732,10.9472136 L10.0736072,13.1381966 C9.82661793,13.2616912 9.52628141,13.1615791 9.40278679,12.9145898 C9.36807293,12.8451621 9.35000038,12.7686056 9.35000038,12.690983 L9.35000038,8.30901699 C9.35000038,8.03287462 9.57385801,7.80901699 9.85000038,7.80901699 C9.92762293,7.80901699 10.0041795,7.82708954 10.0736072,7.8618034 Z"
          fill="inherit"
          id={`path-1-webinar${unique}`}
        />
      </g>
    </g>
  </svg>
)

Webinar.propTypes = propTypes
export default Webinar
