import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Notification = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/communications/notification-notification${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`grey600-notification${unique}`}
        xlinkHref={`#path-1-notification${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-notification${unique}`}>
        <path
          d="M18.407265,15.8461538 L20.5440171,17.8974359 L20.5440171,18.9230769 L3.45000005,18.9230769 L3.45000005,17.8974359 L5.58675218,15.8461538 L5.58675218,10.7179487 C5.58675218,7.55897436 7.32820518,4.93333333 10.3944445,4.23589744 L10.3944445,3.53846154 C10.3944445,2.68717949 11.1102565,2 11.9970086,2 C12.8837607,2 13.5995727,2.68717949 13.5995727,3.53846154 L13.5995727,4.23589744 C16.6551283,4.93333333 18.407265,7.56923077 18.407265,10.7179487 L18.407265,15.8461538 Z M11.9970086,22 C10.8111112,22 9.86025646,21.0769231 9.86025646,19.9487179 L14.1337607,19.9487179 C14.1337607,21.0769231 13.1722223,22 11.9970086,22 Z"
          fill="inherit"
          id={`path-1-notification${unique}`}
        />
      </g>
    </g>
  </svg>
)

Notification.propTypes = propTypes
export default Notification
