import styled from "styled-components";

import { shouldForwardProp } from "../shared";
import textProps from "./shared";

const H6 = styled.h6.withConfig({
  shouldForwardProp,
})`
  ${textProps};
`;

export default H6;
