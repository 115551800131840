import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const QzoneBox = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/qzone_box-qzone-box${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`fill-1-qzone-box${unique}`}
        xlinkHref={`#path-1-qzone-box${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-qzone-box${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M19.4048626,10.3813988 C19.3674662,10.2681583 19.2651893,10.1875083 19.1447399,10.1763945 L14.4294912,9.7393524 L12.3820015,5.23076003 C12.3351434,5.1182705 12.2240056,5.04497959 12.1007026,5.04497959 C11.9775499,5.04497959 11.8662618,5.11842069 11.8197041,5.23076003 L9.70928636,9.64052982 L5.0252764,10.1763945 C4.9037757,10.1876585 4.80104828,10.2693598 4.76425264,10.3838018 C4.72730681,10.4982437 4.76365189,10.6233489 4.85631683,10.7012956 L8.31766021,13.8757832 L7.46580359,18.672583 C7.4383195,18.7898785 7.48472706,18.9116796 7.58354964,18.9822671 C7.63671558,19.0201141 7.69934327,19.0390376 7.76197095,19.0390376 C7.8161882,19.0390376 7.87085601,19.0246197 7.91921599,18.9957839 L12.1319414,16.5752315 L16.2818889,19.0271728 C16.3852171,19.0575105 16.5158791,19.0527045 16.6144013,18.9843697 C16.7130737,18.916035 16.7612835,18.7966369 16.7368032,18.6802425 L16.1097754,15.1290877 C16.2754309,15.0521923 16.7623348,14.8375761 17.0184024,14.5430608 C16.6008846,14.7081156 16.4437897,14.7516696 16.0261216,14.8305174 L16.0261216,14.8296163 C13.2382136,15.3651806 8.96060771,14.910867 8.81657906,14.9038083 L13.5082485,11.4681468 C11.9200285,11.1766352 8.23265477,11.0602408 8.00512255,11.0531821 C7.97553585,11.057988 7.97027933,11.0582884 7.99325783,11.0528817 C7.99325783,11.0528817 7.99746305,11.0528817 8.00512255,11.0531821 C8.19661007,11.0222437 13.0112821,10.2333151 15.8029448,11.0216429 L11.077934,14.3347524 C11.077934,14.3347524 14.7210028,14.6888917 16.0059967,14.5400571 L15.9199399,13.875633 L19.3486929,10.6979915 C19.4081667,10.6191437 19.4425593,10.4946392 19.4048626,10.3813988 Z"
          fill="inherit"
          id={`path-1-qzone-box${unique}`}
        />
      </g>
    </g>
  </svg>
)

QzoneBox.propTypes = propTypes
export default QzoneBox
