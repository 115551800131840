import React, { useMemo } from "react";
import { bool, node } from "prop-types";
import { ThemeProvider } from "styled-components";
import { forEachObjIndexed } from "ramda";

export const colors = {
  "alert-blue": "#7BA0FF",
  "alert-gold": "#EFC100",
  "alert-green": "#5AA700",
  "alert-red": "#E71D32",
  aqua: "#3253DC",
  black: "#000000",
  blue: "#3253DC",
  "blue-migration": "#3253DC",
  "body-grey": "#677283",
  "body-grey-migration": "#677283",
  "color-info-search-grey": "#949aa5",
  coolgray: "#677283",
  dark: "#292f32",
  "dark-aqua": "#3253DC",
  "dark-blue": "#1D37A6",
  "error-box": "#fae3e7",
  facebook: "#3664a2",
  google: "#dd4b39",
  gray: "#e9ecef",
  "gray-dark": "#222222",
  "gray-dark-footer-migration": "#d3d9de",
  "gray-divider": "#D3D9DE",
  "gray-footer": "#b2b2b2",
  "gray-light": "#F6F8FA",
  "gray-light-line-migration": "#e8e8e8",
  "gray-menu": "#BBC0C8",
  "gray-sub": "#677283",
  green: "#7BA0FF",
  grey: "#D3D9DE",
  "grey-migration": "#DAE2E5",
  gunmetal: "#4a5a75",
  instagram: "#3f729b",
  "legal-grey": "#B4B9BD",
  light: "#F6F8FA",
  "light-grey": "#E9ECEF",
  "light-gunmetal": "#929ba7",
  linkedin: "#0977b5",
  "primary-blue": "#3253DC",
  rffeTableHeaderBorder: "#ECEEF1",
  rffeTableRowBorder: "#F4F5F6",
  "secondary-blue": "#7BA0FF",
  "snapdragon-red": "#E04F4F",
  teal: "#6FB59F",
  "text-light-grey": "#BBC0C8",
  "title-black-migration": "#222222",
  twitter: "#55acee",
  "ultra-dark-blue": "#0B2742",
  "ultra-light-grey": "#F6F8FA",
  white: "#ffffff",
  youtube: "#cd201f",
};

export const colorRoles = {
  appBlocker: "black",
  background: "white",
  "background-button": "blue",
  "background-dark": "gunmetal",
  "background-highlight": "secondary-blue",
  "background-light": "light-grey",
  "background-selected": "blue",
  "background-ultra-light": "ultra-light-grey",
  border: "gray",
  "border-focus": "blue",
  "border-hover": "body-grey",
  browserUpdateBackground: "dark",
  browserUpdateBorder: "aqua",
  browserUpdateContainerBackground: "white",
  browserUpdateIcon: "dark-aqua",
  browserUpdateTextColor: "gray-dark",
  "button-hover": "blue",
  coverBandDeviceBackground: "gunmetal",
  coverControlsButtonFill: "white",
  coverControlsButtonFillAlt: "dark",
  divider: "light-grey",
  dropdownContainerBackground: "white",
  dropdownContainerBorder: "gray-divider",
  dropdownContainerShadow: "black",
  dropdownItemLinkBeforeBackground: "white",
  dropdownItemLinkBeforeBackgroundHover: "gray-dark",
  dropdownItemLinkDisabledTextColor: "color-info-search-grey",
  dropdownItemLinkTextColor: "gray-dark",
  error: "alert-red",
  "error-background": "snapdragon-red",
  errorBackground: "gray",
  footerBorder: "gray-divider",
  footerItemColor: "gray-menu",
  footerItemHover: "gray-dark",
  footerLanguageBackground: "white",
  footerLanguageBorder: "gray",
  footerLanguageBorderActive: "white",
  footerLanguageColor: "body-grey-migration",
  footerLanguageColorHover: "gray-dark",
  footerLegalBorder: "gray-menu",
  footerLegalCopyrightColor: "gray-menu",
  footerLegalLineSeparator: "gray-menu",
  footerLegalTextColor: "gray-footer",
  footerLinksItem: "gray-sub",
  footerLinksItemHover: "gray-dark",
  footerLogoColor: "aqua",
  footerLogoFill: "white",
  footerSocialLinkBackground: "gray-dark-footer-migration",
  footerSocialLinkFill: "white",
  footerTextColor: "body-grey-migration",
  footerTextColorHover: "gray-dark",
  icon: "blue",
  "icon-input": "text-light-grey",
  "icon-light": "white",
  megaMenuBackground: "white",
  megaMenuBorder: "gray-divider",
  megaMenuMobileBackground: "gray-light",
  megaMenuMobileIconFill: "gray-sub",
  megaMenuTextColor: "gray-dark",
  megaMenuToutBorder: "gray",
  notification: "alert-blue",
  pageControlsBackground: "black",
  pageControlsButtonFill: "gray-dark",
  placeholder: "body-grey",
  shadow: "black",
  success: "alert-green",
  text: "body-grey",
  "text-dark": "body-grey",
  "text-focus": "blue",
  "text-helper": "text-light-grey",
  "text-hover": "blue",
  "text-legal-copy": "legal-grey",
  "text-light": "white",
  "text-link": "blue",
  "text-mid": "gunmetal",
  "text-title": "black",
  "title-black": "black",
  warning: "alert-gold",
};

export const themeColors = {
  colorRoles,
  colors,
};

export const themeSettings = {
  breakpointInts: [
    // 0
    550, // 1
    950, // 2
    1200, // 3
    1440, // 4
    1800, // 5
  ],
  breakpoints: ["550px", "950px", "1200px", "1440px", "1800px"],
  fontSizes: [
    10, // 0
    12, // 1
    14, // 2
    16, // 3
    18, // 4
    20, // 5
    24, // 6
    28, // 7
    32, // 8
    36, // 9
    42, // 10
    48, // 11
    54, // 12
  ],
  fontWeights: [200, 400, 600],
  // Values for Grid used by GridSystem components
  grid: {
    columns: [6, 8, 12, 12, 12, 12],
    gutters: ["4px", "8px", "12px", "12px", "12px", "16px"],
    margins: [3, 5, 5, 5, 7],
    maxWidth: 1420,
  },
  lineHeights: [1, 1.125, 1.25, 1.5],
  shadows: [
    "none",
    "0 2px 4px 0 rgba(74,90,117,0.16)",
    "0 4px 16px 0 rgba(74,90,117,0.08)",
    "0px 4px 32px 0px rgba(74,90,117,0.24)",
    "0 12px 16px -8px rgba(74,90,117,0.26)",
    "0 15px 13px -10px rgba(0,0,0,0.4)",
    "0 16px 15px -11px rgba(0,0,0,0.4)",
    "0 27px 22px -18px rgba(0,0,0,0.4)",
    "0 35px 28px -23px rgba(0,0,0,0.4)",
  ],
  space: [
    0, // 0
    8, // 1
    16, // 2
    24, // 3
    32, // 4
    48, // 5
    96, // 6
    130, // 7
  ],
  spacings: [0, 8, 16, 24, 32, 48, 96, 130],
  unitPadding: [24, 48, 48, 48, 48, 48],
};

const propTypes = {
  children: node,
  hasColors: bool,
};

const mergedColors = () => {
  const cols = { ...colors };
  forEachObjIndexed((value, key) => {
    cols[key] = cols[value];
  }, colorRoles);
  return cols;
};

const DefaultTheme = ({ hasColors = true, children }) => {
  const themeOptions = useMemo(
    () =>
      hasColors
        ? { ...themeColors, colors: { ...mergedColors() }, ...themeSettings }
        : themeSettings,
    [hasColors],
  );

  return <ThemeProvider theme={themeOptions}>{children}</ThemeProvider>;
};

DefaultTheme.propTypes = propTypes;

export default DefaultTheme;
