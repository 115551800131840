import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Refresh = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/refresh-refresh${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`ic_refresh-refresh${unique}`}
        xlinkHref={`#path-1-refresh${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-refresh${unique}`}>
        <path
          d="M19.0500703,4.93782719 C17.0455201,2.93216585 14.2142597,1.76877116 11.0985398,2.03989657 C6.43496051,2.44325118 2.58920213,6.18344844 2.06584119,10.8348049 C1.38469415,16.8829016 6.07827504,22.0009493 11.9874756,22.0009493 C16.3877077,22.0009493 20.0990145,19.1619107 21.4368628,15.2161471 C21.5568692,14.8650174 21.2757432,14.5005538 20.9046126,14.5005538 L19.4234233,14.5005538 C19.1978559,14.5005538 19.0034012,14.6416723 18.9178411,14.8505722 C17.8011155,17.5773827 15.1220854,19.4985951 11.9930314,19.5008175 C7.97393057,19.504151 4.55263902,16.1428626 4.48819117,12.1248729 C4.42040982,7.93020724 7.80947745,4.50002632 11.9874756,4.50002632 C14.0631406,4.50002632 15.9132382,5.36340519 17.2633094,6.72569926 L14.3042644,9.68363305 C14.1253661,9.86253137 14.0731411,10.1314344 14.1687017,10.3647801 C14.2653735,10.5992369 14.4931633,10.750356 14.74651,10.750356 L21.3624145,10.750356 C21.7090994,10.750356 21.988003,10.4703412 21.988003,10.1258786 L21.988003,3.50997411 C21.988003,3.25662741 21.8357728,3.02883762 21.6024271,2.93105468 C21.3690815,2.83438292 21.1001784,2.88883023 20.9212801,3.06661739 L19.0500703,4.93782719 Z"
          fill="inherit"
          id={`path-1-refresh${unique}`}
        />
      </g>
    </g>
  </svg>
)

Refresh.propTypes = propTypes
export default Refresh
