import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Favorite = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/shopping/favorite-favorite${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-favorite${unique}`}
        xlinkHref={`#path-1-favorite${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-favorite${unique}`}>
        <path
          d="M20.9995061,9.07422344 C20.9562338,12.33369 18.0692451,16.5990936 12.3385399,21.8704342 L12.3385683,21.870465 C12.1472021,22.0464915 11.8528653,22.0464863 11.6615053,21.870453 C5.93082045,16.5987585 3.04381701,12.3333742 3.00049499,9.0743003 C3.00018068,9.04957548 3,9.02480884 3,9 C3,6.23857625 5.23857625,4 8,4 C9.63559896,4 11.0877712,4.7853431 12,5.99951255 C12.9122288,4.7853431 14.364401,4 16,4 C18.7614237,4 21,6.23857625 21,9 C21,9.02478313 20.9998197,9.04952415 20.9994603,9.07422181 L20.9995061,9.07422344 Z"
          fill="inherit"
          id={`path-1-favorite${unique}`}
        />
      </g>
    </g>
  </svg>
)

Favorite.propTypes = propTypes
export default Favorite
