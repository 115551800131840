import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Router = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/applications/networking/router-router${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`simplified_icons-technical-router-router${unique}`}
        xlinkHref={`#path-1-router${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-router${unique}`}>
        <path
          d="M11.5,8.41464715 C10.9174038,8.20872894 10.5,7.65310941 10.5,7 C10.5,6.17157288 11.1715729,5.5 12,5.5 C12.8284271,5.5 13.5,6.17157288 13.5,7 C13.5,7.65310941 13.0825962,8.20872894 12.5,8.41464715 L12.5,13 L20.1,13 C20.5970563,13 21,13.4029437 21,13.9 L21,17.4 C21,17.8970563 20.5970563,18.3 20.1,18.3 L19.2,18.3 L19.2,20.1 C19.2,20.5970563 18.7970563,21 18.3,21 L17.4,21 C16.9029437,21 16.5,20.5970563 16.5,20.1 L16.5,18.3 L7.5,18.3 L7.5,20.1 C7.5,20.5970563 7.09705627,21 6.6,21 L5.7,21 C5.20294373,21 4.8,20.5970563 4.8,20.1 L4.8,18.3 L3.9,18.3 C3.40294373,18.3 3,17.8970563 3,17.4 L3,13.9 C3,13.4029437 3.40294373,13 3.9,13 L11.5,13 L11.5,8.41464715 Z M14,9.23610651 C14.6137494,8.68678595 15,7.88849981 15,7 C15,5.34314575 13.6568542,4 12,4 C10.3431458,4 9,5.34314575 9,7 C9,7.88849981 9.38625064,8.68678595 10,9.23610651 L10,10.4648712 C8.80439726,9.77325238 8,8.48056471 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,8.48056471 15.1956027,9.77325238 14,10.4648712 L14,9.23610651 Z"
          fill="inherit"
          id={`path-1-router${unique}`}
        />
      </g>
    </g>
  </svg>
)

Router.propTypes = propTypes
export default Router
