import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Facebook = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/facebook-facebook${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`fill-1-facebook${unique}`}
        xlinkHref={`#path-1-facebook${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-facebook${unique}`}>
        <path
          d="M13.6654171,9.5001562 L17.4167448,9.5001562 L16.7644486,13.2502343 L13.6654171,13.2502343 L13.6654171,22 L9.91658857,22 L9.91658857,13.2502343 L7,13.2502343 L7,9.5001562 L9.91658857,9.5001562 L9.91658857,6.9996876 C9.91658857,4.24679788 11.975945,2 14.497657,2 L17.4167448,2 L17.4167448,5.7500781 L14.497657,5.7500781 C14.0365511,5.7500781 13.6654171,6.30615433 13.6654171,6.9996876 L13.6654171,9.5001562 Z"
          fill="inherit"
          id={`path-1-facebook${unique}`}
        />
      </g>
    </g>
  </svg>
)

Facebook.propTypes = propTypes
export default Facebook
