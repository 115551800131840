// eslint-disable-next-line import/prefer-default-export
export const env = typeof window !== "undefined" ? window.env : process.env;
// export const authToken = env.QC_PUBLIC_AUTH_TOKEN || "QID_SESSION"
function getMeta(metaName) {
    const metas = document.getElementsByTagName("meta");
    for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute("name") === metaName) {
            return metas[i].getAttribute("content");
        }
    }
    return null;
}

let envType = getMeta("Environment");

let tokenType;
if (envType === "prod") {
    tokenType = "QID_SESSION"
} else if (envType === "test") {
    tokenType = "QID_TST_SESSION"
} else if (envType === 'stage') {
    tokenType = "QID_STG_SESSION"
} else {
    tokenType = "QID_SESSION"
}
export const authToken = tokenType;
