import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const MoreCircle = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/more_circle-more-circle${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-more-circle${unique}`}
        xlinkHref={`#path-1-more-circle${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-more-circle${unique}`}>
        <g
          fill="inherit"
          id={`rectangle-copy-2-more-circle${unique}`}
          transform="translate(12.000000, 12.000000) rotate(90.000000) translate(-12.000000, -12.000000) "
        />
        <path
          d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M6,14 C7.1045695,14 8,13.1045695 8,12 C8,10.8954305 7.1045695,10 6,10 C4.8954305,10 4,10.8954305 4,12 C4,13.1045695 4.8954305,14 6,14 Z M12,14 C13.1045695,14 14,13.1045695 14,12 C14,10.8954305 13.1045695,10 12,10 C10.8954305,10 10,10.8954305 10,12 C10,13.1045695 10.8954305,14 12,14 Z M18,14 C19.1045695,14 20,13.1045695 20,12 C20,10.8954305 19.1045695,10 18,10 C16.8954305,10 16,10.8954305 16,12 C16,13.1045695 16.8954305,14 18,14 Z"
          fill="inherit"
          id={`path-1-more-circle${unique}`}
        />
      </g>
    </g>
  </svg>
)

MoreCircle.propTypes = propTypes
export default MoreCircle
