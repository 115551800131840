import {createSelector} from "reselect"
import {
  __,
  assoc,
  equals,
  last,
  map,
  pathOr,
  propOr,
  reduce,
  replace,
} from "ramda"
import {camelizeKeys} from "humps"

import {hasData} from "lib/utils/helpers"
import cookieControlConfig from "lib/cookie-control"
import {createStateSelector} from "lib/redux/utils"

const makeSelector = createStateSelector("drupalReducer")
const defaultUtcOffset = "-0700"

export const GET_ALERT_STRINGS = makeSelector("alertStrings", {})
export const GET_AUTHENTICATED = makeSelector("authenticated", false)
export const GET_COLOR_THEME = makeSelector("theme", {})
export const GET_CONTENT_LOCALE = makeSelector("contentLocale", "und")
export const GET_DOWNLOAD_PATHS = makeSelector("downloadPaths", {})
export const GET_FORMATTED_DOWNLOAD_PATHS = createSelector(
  pathOr({}, ["drupalReducer", "downloadPaths"]),
  camelizeKeys,
)
export const GET_GSA_CONFIG = makeSelector("gsa_config", {})
export const GET_HIDE_LOGIN = makeSelector("hideLogin", false)
export const GET_INCLUDE_ANNALYTICS = () =>
  process.env.NODE_ENV === "production"
export const GET_IS_CHINA = makeSelector("locale", false, equals("zh-cn"))
export const GET_INCLUDE_COOKIE_CONTROL = GET_IS_CHINA
export const GET_LEGAL_AGREEMENTS = makeSelector("licenseAgreements", {})
export const GET_LEGAL_DISCLAIMER_ITEMS = makeSelector(
  "footer_legal_strings",
  [],
)
export const GET_LOCALE = makeSelector("locale", "en")
export const GET_LOCALE_STRINGS = makeSelector("strings", {})
export const GET_NEW_RELIC = makeSelector("new_relic_url", null)
export const GET_PK_LEVEL_COMPOSITE_IDS = makeSelector(
  "pkLevelCompositeIds",
  [],
)
export const GET_SEARCH_PUBLISHING_APP = makeSelector("searchPublishingApp", "")
export const GET_SNAPDRAGON_TECH_SUMIIT = makeSelector(
  "snapdragonTechSummitEnabled",
  false,
)
export const GET_SWIFTYPE_SETTINGS = makeSelector("swiftype", {})
export const GET_TEALIUM_URL = makeSelector("utag_uri", null)
export const GET_UTC_OFFSET = makeSelector("utcOffset", defaultUtcOffset)

export const GET_COOKIE_CONTROL_STRINGS = createSelector(
  GET_LOCALE_STRINGS,
  (strings) =>
    reduce(
      (accum, prefix) =>
        assoc(
          prefix,
          propOr(null, `_cookie_control_${prefix}`, strings),
          accum,
        ),
      {},
      cookieControlConfig.strings,
    ),
)
export const GET_SWIFTTYPE_BLOG_SECTIONS = createSelector(
  GET_SWIFTYPE_SETTINGS,
  propOr("", "blog_sections"),
)
export const makeLocalizedStringSelector = ({key, values}) =>
  createSelector(GET_LOCALE_STRINGS, (strings) => {
    return !hasData(values)
      ? propOr(key, key, strings)
      : last(map(replace("%@", __, propOr(key, key, strings)), values))
  })
