import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Mobilefilters = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="inherit" id={`ic_menu_24px-mobilefilters${unique}`}>
      <path
        d="M3.5,6.5h17C20.8,6.5,21,6.7,21,7v0.8c0,0.3-0.2,0.5-0.5,0.5h-17C3.2,8.3,3,8.1,3,7.8V7C3,6.7,3.2,6.5,3.5,6.5z"
        fill="inherit"
      />
      <path
        d="M6.5,11.1h11c0.3,0,0.5,0.2,0.5,0.5v0.8c0,0.3-0.2,0.5-0.5,0.5h-11c-0.3,0-0.5-0.2-0.5-0.5v-0.8C6,11.3,6.2,11.1,6.5,11.1z
    "
        fill="inherit"
      />
      <path
        d="M9.5,15.7h5c0.3,0,0.5,0.2,0.5,0.5V17c0,0.3-0.2,0.5-0.5,0.5h-5C9.2,17.5,9,17.3,9,17v-0.8C9,15.9,9.2,15.7,9.5,15.7z"
        fill="inherit"
      />
    </g>
  </svg>
)

Mobilefilters.propTypes = propTypes
export default Mobilefilters
