import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const PhotoImage = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/media/photo_image-photo-image${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-photo-image${unique}`}
        xlinkHref={`#path-1-photo-image${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-photo-image${unique}`}>
        <path
          d="M3,5 L21,5 C21.5522847,5 22,5.44771525 22,6 L22,18 C22,18.5522847 21.5522847,19 21,19 L3,19 C2.44771525,19 2,18.5522847 2,18 L2,6 C2,5.44771525 2.44771525,5 3,5 Z M3.5,6 C3.22385763,6 3,6.22385763 3,6.5 L3,17.5 C3,17.7761424 3.22385763,18 3.5,18 L20.5,18 C20.7761424,18 21,17.7761424 21,17.5 L21,6.5 C21,6.22385763 20.7761424,6 20.5,6 L3.5,6 Z M6.5,11 C5.67157288,11 5,10.3284271 5,9.5 C5,8.67157288 5.67157288,8 6.5,8 C7.32842712,8 8,8.67157288 8,9.5 C8,10.3284271 7.32842712,11 6.5,11 Z M5,15 L7.46340744,12.5748314 C7.80295838,12.2405512 8.33120232,12.1922752 8.72572171,12.459469 L10.3920615,13.588021 C10.753831,13.8330345 11.2327858,13.8148074 11.5748875,13.5430074 L15.4606073,10.4558006 C15.8021364,10.1844555 16.2801436,10.1657902 16.6417916,10.4096774 L19,12 L19,16 L5,16 L5,15 Z"
          fill="inherit"
          id={`path-1-photo-image${unique}`}
        />
      </g>
    </g>
  </svg>
)

PhotoImage.propTypes = propTypes
export default PhotoImage
