import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Announcement = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/communications/announcement-announcement${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`grey600-announcement${unique}`}
        xlinkHref={`#path-1-announcement${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-announcement${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,16 C21,16.5522847 20.5522847,17 20,17 L7,17 L3.87370466,20.5170823 C3.69024565,20.7234736 3.37420946,20.742064 3.16781808,20.558605 C3.0610739,20.4637213 3,20.3277192 3,20.1849003 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M11.499865,5 C11.2237226,5 10.999865,5.22385763 10.999865,5.5 L10.999865,10.5 C10.999865,10.7761424 11.2237226,11 11.499865,11 L12.499865,11 C12.7760074,11 12.999865,10.7761424 12.999865,10.5 L12.999865,5.5 C12.999865,5.22385763 12.7760074,5 12.499865,5 L11.499865,5 Z M11.499865,13 C11.2237226,13 10.999865,13.2238576 10.999865,13.5 L10.999865,14.5 C10.999865,14.7761424 11.2237226,15 11.499865,15 L12.499865,15 C12.7760074,15 12.999865,14.7761424 12.999865,14.5 L12.999865,13.5 C12.999865,13.2238576 12.7760074,13 12.499865,13 L11.499865,13 Z"
          fill="inherit"
          id={`path-1-announcement${unique}`}
        />
      </g>
    </g>
  </svg>
)

Announcement.propTypes = propTypes
export default Announcement
