import React, {useCallback, useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {isEmpty} from "ramda"

import {GET_IS_MOBILE, GET_PDP_BANNER} from "lib/redux/selectors"
import BannerComponent from "./BannerComponent"
import {SET_PDP_BANNER_HEIGHT, SET_PDP_BANNER_UPDATE} from "lib/redux/actions"

const BannerContainer = () => {
  const dispatch = useDispatch()
  const isMobile = useSelector(GET_IS_MOBILE)
  const {
    backgroundColor,
    bannerText,
    showPdpBanner,
    isPdpBannerClosed,
    pdpBannerHeight,
  } = useSelector(GET_PDP_BANNER)

  const [bannerRef, setBannerRef] = useState({})

  const closeBanner = useCallback(() => {
    if (!bannerText) return

    dispatch({
      type: SET_PDP_BANNER_UPDATE,
      value: {
        isPdpBannerClosed: true,
      },
    })
  }, [dispatch, bannerText])

  const updateHeights = useCallback(() => {
    if (
      !bannerText ||
      isEmpty(bannerRef) ||
      pdpBannerHeight !== bannerRef?.offsetHeight
    ) {
      return
    }

    dispatch({
      type: SET_PDP_BANNER_HEIGHT,
      value: bannerRef?.offsetHeight || 0,
    })
  }, [dispatch, bannerRef, bannerText, pdpBannerHeight])

  const callbackRef = useCallback(
    (ref) => {
      if (ref !== null) {
        dispatch({
          type: SET_PDP_BANNER_HEIGHT,
          value: ref?.offsetHeight || 0,
        })
        setBannerRef(ref)
      }
    },
    [dispatch],
  )

  useEffect(() => {
    if (!bannerText) return

    dispatch({
      type: SET_PDP_BANNER_UPDATE,
      value: {
        showPdpBanner: !isMobile || !isPdpBannerClosed,
      },
    })
  }, [dispatch, isMobile, isPdpBannerClosed, bannerText])

  useEffect(() => {
    window.addEventListener("resize", updateHeights)
    window.addEventListener("DOMContentLoaded", updateHeights)
    return () => {
      window.removeEventListener("resize", updateHeights)
      window.removeEventListener("DOMContentLoaded", updateHeights)
    }
  }, [updateHeights])
  return (
    <>
      {showPdpBanner ? (
        <BannerComponent
          bannerRef={callbackRef}
          bannerText={bannerText}
          bg={backgroundColor}
          closeBanner={closeBanner}
          showCloseButton={isMobile}
        />
      ) : null}
    </>
  )
}

export default BannerContainer
