import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const PlayAlt = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/av/play_alt-play-alt${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`combined-shape-play-alt${unique}`}
        xlinkHref={`#path-1-play-alt${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-play-alt${unique}`}>
        <path
          d="M12,2 C17.52,2 22,6.48 22,12 C22,17.52 17.52,22 12,22 C6.48,22 2,17.52 2,12 C2,6.48 6.48,2 12,2 Z M10,16 C10,16.094693 10.0535006,16.1812588 10.1381966,16.2236068 C10.2228926,16.2659548 10.3242456,16.2568158 10.4,16.2 L15.8999996,12.2 C15.9629511,12.1527864 16,12.0786893 16,12 C16,11.9213107 15.9629511,11.8472136 15.8999996,11.8 L10.4,7.8 C10.3242456,7.74318419 10.2228926,7.7340452 10.1381966,7.7763932 C10.0535006,7.81874121 10,7.90530699 10,8 L10,16 Z"
          fill="inherit"
          id={`path-1-play-alt${unique}`}
        />
      </g>
    </g>
  </svg>
)

PlayAlt.propTypes = propTypes
export default PlayAlt
