import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Error = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id="Glyphs/Alert/*Error"
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`grey600-error${unique}`}
        xlinkHref={`#path-1-error${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-error${unique}`}>
        <path
          d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M11.5,7 L12.5,7 C12.7761424,7 13,7.22385763 13,7.5 L13,12.5 C13,12.7761424 12.7761424,13 12.5,13 L11.5,13 C11.2238576,13 11,12.7761424 11,12.5 L11,7.5 C11,7.22385763 11.2238576,7 11.5,7 Z M11.5,15 L12.5,15 C12.7761424,15 13,15.2238576 13,15.5 L13,16.5 C13,16.7761424 12.7761424,17 12.5,17 L11.5,17 C11.2238576,17 11,16.7761424 11,16.5 L11,15.5 C11,15.2238576 11.2238576,15 11.5,15 Z"
          fill="inherit"
          id={`path-1-error${unique}`}
        />
      </g>
    </g>
  </svg>
)

Error.propTypes = propTypes
export default Error
