import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Connectivity = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/technology/connectivity-connectivity${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-connectivity${unique}`}
        xlinkHref={`#path-1-connectivity${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-connectivity${unique}`}>
        <path
          d="M18.5,3 L20.5,3 C20.7761424,3 21,3.22385763 21,3.5 L21,20.5 C21,20.7761424 20.7761424,21 20.5,21 L18.5,21 C18.2238576,21 18,20.7761424 18,20.5 L18,3.5 C18,3.22385763 18.2238576,3 18.5,3 Z M13.5,7 L15.5,7 C15.7761424,7 16,7.22385763 16,7.5 L16,20.5 C16,20.7761424 15.7761424,21 15.5,21 L13.5,21 C13.2238576,21 13,20.7761424 13,20.5 L13,7.5 C13,7.22385763 13.2238576,7 13.5,7 Z M8.5,12 L10.5,12 C10.7761424,12 11,12.2238576 11,12.5 L11,20.5 C11,20.7761424 10.7761424,21 10.5,21 L8.5,21 C8.22385763,21 8,20.7761424 8,20.5 L8,12.5 C8,12.2238576 8.22385763,12 8.5,12 Z M3.5,16 L5.5,16 C5.77614237,16 6,16.2238576 6,16.5 L6,20.5 C6,20.7761424 5.77614237,21 5.5,21 L3.5,21 C3.22385763,21 3,20.7761424 3,20.5 L3,16.5 C3,16.2238576 3.22385763,16 3.5,16 Z"
          fill="inherit"
          id={`path-1-connectivity${unique}`}
        />
      </g>
    </g>
  </svg>
)

Connectivity.propTypes = propTypes
export default Connectivity
