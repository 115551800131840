import glyphMachineNameMap from "components/glyphs"
import {
  WosArrowLeft,
  WosArrowLeftDisabled,
  WosArrowRight,
  WosArrowRightDisabled,
  WosVisualStudio,
  WosCloud,
  WosWindows,
  WosMac,
  WosLinux,
  WosVisualProfiler,
  WosLlvmCompiler,
  WosNeuralProcessingSdk,
  WosArm64,
  WosUnrealEngine,
  WosMore,
  WosBookOpen,
  WosDown,
  } from "components/svgs"

const iconMachineNameMap = {
  WosArrowLeft,
  WosArrowLeftDisabled,
  WosArrowRight,
  WosArrowRightDisabled,
  WosVisualStudio,
  WosCloud,
  WosWindows,
  WosMac,
  WosLinux,
  WosVisualProfiler,
  WosLlvmCompiler,
  WosNeuralProcessingSdk,
  WosArm64,
  WosUnrealEngine,
  WosMore,
  WosBookOpen,
  WosDown, 
  "facebook-wos": glyphMachineNameMap.facebookwos,
  "instagram-wos": glyphMachineNameMap.instagramwos,
  "linkedin-wos": glyphMachineNameMap.linkedinwos,
  "twitter-wos": glyphMachineNameMap.twitterwos,
  "youtube-wos": glyphMachineNameMap.youtubewos,
  "discord-wos":glyphMachineNameMap.discordwos,
  "reddit-wos" : glyphMachineNameMap.redditwos
}

export default iconMachineNameMap
