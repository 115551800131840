import { arrayOf, number, oneOfType, shape, string } from "prop-types";

export const searchDropdownPropType = shape({
  id: oneOfType([number, string]),
  label: string,
  value: string,
});

export const contentPodPropType = shape({
  anonymous: shape({
    ctas: arrayOf(
      shape({
        text: string,
        url: string,
      }),
    ),
    description: string,
    image: string,
    subtitle: string,
    title: string,
  }),
  fru: shape({
    ctas: arrayOf(
      shape({
        text: string,
        url: string,
      }),
    ),
    description: string,
    image: string,
    subtitle: string,
    title: string,
  }),
  lru: shape({
    ctas: arrayOf(
      shape({
        text: string,
        url: string,
      }),
    ),
    description: string,
    image: string,
    subtitle: string,
    title: string,
  }),
});

export const reactDraggablePositionPropType = shape({ x: number, y: number });
