import React from "react"
import { bool, func, string } from "prop-types"
import { keyframes } from "styled-components"
import { css } from "styled-components/macro"
import Primitives from "primitives"
import { NavClose, NavSearch } from "components/glyphs/elements"

import { defaultEase } from "lib/styles"
import MenuIcon from "./IconComponent"
import { Icon } from "../../../../components/dx/atoms/Icon"

const animateRotation = keyframes`
  0% {
    transform: rotate(45deg)
  }
  100% {
    transform: rotate(90deg)
  }
`

const menuCloseCss = css`
  animation: ${animateRotation} 0.5s ${defaultEase} forwards;
`

const menuIconStyles = {
  "aria-haspopup": "true",
  cursor: "pointer",
  height: "24px",
  position: "relative",
  width: "28px",
}

const propTypes = {
  /** aria close text label for icon button  */
  closeText: string,
  /** bool value to display close or search text  */
  isActive: bool,
  /** onClick event handler  */
  onClick: func,
  /** aria search text label for icon button  */
  searchText: string,
}

const MainMenuSearchIcon = ({ closeText, isActive, onClick, searchText, wosFlag = true }) => {
  const val = "14px"
  const margin = [val, val, 0]

  return (
    <MenuIcon
      aria-expanded={isActive}
      aria-label={isActive ? closeText : searchText}
      isActive={isActive}
      mr={margin}
      onClick={onClick}
      {...menuIconStyles}
      wosFlag={wosFlag}
    >
      <Primitives.Flex data-test-id="global-search-icon" mt={!wosFlag ? "-2px" : 0}>
        {wosFlag ? <Primitives.Flex alignItems="center" justifyContent="center" position="relative" >
          {isActive ? (
            <Icon variant="dark-gray" size="large" glyphId="dx-close" />
          ) : (
            <Icon variant="dark-gray" size="large" glyphId="dx-search" />
          )}
        </Primitives.Flex> :  <Primitives.Box color="gunmetal" position="relative">
          {isActive ? (
            <NavClose css={menuCloseCss} height="28px" width="28px" />
          ) : (
            <NavSearch height="28px" width="28px" />
          )}
        </Primitives.Box>}
      </Primitives.Flex>
    </MenuIcon>
  )
}

MainMenuSearchIcon.propTypes = propTypes

export default MainMenuSearchIcon
