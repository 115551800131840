import { cva } from "class-variance-authority"
import { MapType } from "./CtaV2Dx.interface"

export const UndefinedIconValueMap: MapType = {
  external: {
    text: {
      ddp: "dx-link-out-simple",
      "non-ddp": "dx-link-out-simple"
    },
    outline: {
      ddp: "_none",
      "non-ddp": "_none"
    },
    fill: {
      ddp: "_none",
      "non-ddp": "_none"
    }
  },
  internal: {
    text: {
      ddp: "right-arrow",
      "non-ddp": "chevron"
    },
    outline: {
      ddp: "_none",
      "non-ddp": "_none"
    },
    fill: {
      ddp: "_none",
      "non-ddp": "_none"
    }
  }
}

export const IconValueMap: MapType = {
  video: {
    text: {
      ddp: "video-cta",
      "non-ddp": "video-cta"
    },
    outline: {
      ddp: "video-cta",
      "non-ddp": "video-cta"
    },
    fill: {
      ddp: "video-cta",
      "non-ddp": "video-cta"
    }
  },
  dam_video: {
    text: {
      ddp: "video-cta",
      "non-ddp": "chevron"
    },
    outline: {
      ddp: "video-cta",
      "non-ddp": "_none"
    },
    fill: {
      ddp: "video-cta",
      "non-ddp": "_none"
    }
  }
}

const baseGroupClasses = ["flex", "flex-wrap", "gap-xs"]

export const ctaGroupClasses = cva(baseGroupClasses, {
  variants: {
    alignment: {
      left: ["justify-start", "items-start"],
      right: ["justify-end", "items-end"],
      center: ["justify-center", "items-center"],
      "space-around": ["justify-around", "items-center"],
      "space-between": ["justify-between", "items-center"]
    },
    displayType: {
      inline: ["flex-row"],
      stacked: ["flex-col"]
    }
  }
})

const baseClasses = [
  "transition",
  "duration-700",
  "standard-ease",
  "cursor-pointer",
  "group",
  "inline-flex",
  "items-center",
  "gap-x-xxxs"
]
export const ctaClasses = cva(baseClasses, {
  variants: {
    color: {
      blue: "",
      "blue-gray": "",
      dark: "",
      gray: "",
      red: "",
      white: ""
    },
    iconAlign: {
      left: "flex-row-reverse",
      right: "flex-row"
    },
    style: {
      disabled: "",
      fill: "",
      "fill-hover-shadow-disabled": "",
      text: "",
      outline: ""
    },
    size: {
      "large-icon": ["p-xxs", "h-[40px]"],
      medium: ["py-xs", "px-s", "h-[52px]"],
      small: ["py-xxs", "px-xs", "h-[40px]"],
      text: ""
    },
    width: {
      "full-on-desktop": "lg:w-full",
      "full-on-mobile": "w-full md:w-auto",
      full: "w-full",
      auto: "w-auto"
    },
    alignment: {
      center: "justify-center",
      left: "justify-start",
      right: "justify-end"
    }
  },
  compoundVariants: [
    {
      color: "blue",
      style: "fill",
      class: [
        "rounded",
        "bg-theme-blue-500",
        "hover:bg-theme-blue-700",
        "hover:shadow-xl",
        "text-theme-neutral-000",
        "fill-theme-neutral-000",
        "stroke-theme-neutral-000"
      ]
    },
    {
      color: "blue-gray",
      style: "fill",
      class: [
        "rounded",
        "bg-theme-blue-500",
        "hover:bg-theme-blue-700",
        "hover:shadow-xl",
        "text-theme-neutral-000",
        "fill-theme-neutral-000",
        "stroke-theme-neutral-000",
        "border",
        "border-border"
      ]
    },
    {
      color: "dark",
      style: "fill",
      class: [
        "rounded",
        "bg-theme-neutral-800",
        "hover:bg-theme-blue-700",
        "hover:shadow-xl",
        "text-theme-neutral-000",
        "fill-theme-neutral-000",
        "stroke-theme-neutral-000"
      ]
    },
    {
      color: "gray",
      style: "fill",
      class: [
        "rounded",
        "bg-theme-neutral-600",
        "hover:bg-theme-blue-700",
        "hover:shadow-xl",
        "text-theme-neutral-000",
        "fill-theme-neutral-000",
        "stroke-theme-neutral-000"
      ]
    },
    {
      color: "red",
      style: "fill",
      class: [
        "rounded",
        "bg-theme-snapdragon-red",
        "hover:bg-theme-snapdragon-red-dark",
        "hover:shadow-xl",
        "text-theme-neutral-000",
        "fill-theme-neutral-000",
        "stroke-theme-neutral-000"
      ]
    },
    {
      color: "white",
      style: "fill-hover-shadow-disabled",
      class: [
        "rounded",
        "bg-theme-neutral-000",
        "text-theme-blue-500",
        "fill-theme-blue-500",
        "stroke-theme-blue-500"
      ]
    },
    {
      color: "white",
      style: "fill",
      class: [
        "rounded",
        "bg-theme-neutral-000",
        "hover:shadow-xl",
        "text-theme-blue-500",
        "fill-theme-blue-500",
        "stroke-theme-blue-500"
      ]
    },
    {
      color: "blue",
      style: "outline",
      class: [
        "rounded",
        "border",
        "border-theme-blue-500",
        "hover:border-theme-blue-700",
        "hover:bg-black/[.05]",
        "hover:shadow-xl",
        "text-theme-blue-500",
        "fill-theme-blue-500",
        "stroke-theme-blue-500",
        "hover:text-theme-blue-700",
        "hover:fill-theme-blue-700",
        "hover:stroke-theme-blue-700"
      ]
    },
    {
      color: "blue-gray",
      style: "outline",
      class: [
        "rounded",
        "border",
        "border-theme-neutral-300",
        "hover:bg-black/[.05]",
        "hover:shadow-xl",
        "text-theme-blue-500",
        "fill-theme-blue-500",
        "stroke-theme-blue-500",
        "hover:text-theme-blue-700",
        "hover:fill-theme-blue-700",
        "hover:stroke-theme-blue-700"
      ]
    },
    {
      color: "dark",
      style: "outline",
      class: [
        "rounded",
        "border",
        "border-theme-neutral-800",
        "hover:border-theme-blue-700",
        "hover:bg-black/[.05]",
        "hover:shadow-xl",
        "text-theme-neutral-800",
        "fill-theme-neutral-800",
        "stroke-theme-neutral-800",
        "hover:text-theme-blue-700",
        "hover:fill-theme-blue-700",
        "hover:stroke-theme-blue-700"
      ]
    },
    {
      color: "gray",
      style: "outline",
      class: [
        "rounded",
        "border",
        "border-theme-neutral-600",
        "hover:border-theme-blue-700",
        "hover:bg-black/[.05]",
        "hover:shadow-xl",
        "text-theme-neutral-600",
        "fill-theme-neutral-600",
        "stroke-theme-neutral-600",
        "hover:text-theme-blue-700",
        "hover:fill-theme-blue-700",
        "hover:stroke-theme-blue-700"
      ]
    },
    {
      color: "red",
      style: "outline",
      class: [
        "rounded",
        "border",
        "border-theme-snapdragon-red",
        "hover:border-theme-snapdragon-red-dark",
        "hover:bg-black/[.05]",
        "hover:shadow-xl",
        "text-theme-snapdragon-red",
        "fill-theme-snapdragon-red",
        "stroke-theme-snapdragon-red",
        "hover:text-theme-snapdragon-red-dark",
        "hover:fill-theme-snapdragon-red-dark",
        "hover:stroke-theme-snapdragon-red-dark"
      ]
    },
    {
      color: "white",
      style: "outline",
      class: [
        "rounded",
        "border",
        "border-theme-neutral-000",
        "hover:border-theme-neutral-000/[.8]",
        "hover:bg-black/[.05]",
        "hover:shadow-xl",
        "text-theme-neutral-000",
        "fill-theme-neutral-000",
        "stroke-theme-neutral-000",
        "hover:text-theme-neutral-000/[.8]",
        "hover:fill-theme-neutral-000/[.8]",
        "hover:stroke-theme-neutral-000/[.8]"
      ]
    },
    {
      color: "blue",
      style: "text",
      class: [
        "text-theme-blue-500",
        "fill-theme-blue-500",
        "stroke-theme-blue-500",
        "hover:text-theme-blue-700",
        "hover:fill-theme-blue-700",
        "hover:stroke-theme-blue-700"
      ]
    },
    {
      color: "blue-gray",
      style: "text",
      class: [
        "text-theme-blue-500",
        "fill-theme-blue-500",
        "stroke-theme-blue-500",
        "hover:text-theme-blue-700",
        "hover:fill-theme-blue-700",
        "hover:stroke-theme-blue-700"
      ]
    },
    {
      color: "dark",
      style: "text",
      class: [
        "text-theme-neutral-800",
        "fill-theme-neutral-800",
        "stroke-theme-neutral-800",
        "hover:text-theme-blue-700",
        "hover:fill-theme-blue-700",
        "hover:stroke-theme-blue-700"
      ]
    },
    {
      color: "gray",
      style: "text",
      class: [
        "text-theme-neutral-600",
        "fill-theme-neutral-600",
        "stroke-theme-neutral-600",
        "hover:text-theme-blue-700",
        "hover:fill-theme-blue-700",
        "hover:stroke-theme-blue-700"
      ]
    },
    {
      color: "red",
      style: "text",
      class: [
        "text-theme-snapdragon-red",
        "fill-theme-snapdragon-red",
        "stroke-theme-snapdragon-red",
        "hover:text-theme-snapdragon-red-dark",
        "hover:fill-theme-snapdragon-red-dark",
        "hover:stroke-theme-snapdragon-red-dark"
      ]
    },
    {
      color: "white",
      style: "text",
      class: [
        "text-theme-neutral-000",
        "fill-theme-neutral-000",
        "stroke-theme-neutral-000",
        "hover:text-theme-neutral-000/[.8]",
        "hover:fill-theme-neutral-000/[.8]",
        "hover:stroke-theme-neutral-000/[.8]"
      ]
    },

    {
      color: "white",
      style: "disabled",
      class: [
        "rounded",
        "border",
        "border-theme-neutral-000",
        "pointer-events-none",
        "text-theme-neutral-000",
        "fill-theme-neutral-000",
        "stroke-theme-neutral-000",
        "hover:text-theme-neutral-000/[.8]",
        "hover:fill-theme-neutral-000/[.8]",
        "hover:stroke-theme-neutral-000/[.8]"
      ]
    },
    {
      color: ["blue", "blue-gray", "dark", "gray", "red"],
      style: "disabled",
      class: [
        "rounded",
        "border",
        "border-theme-neutral-300",
        "pointer-events-none",
        "text-theme-neutral-600",
        "fill-theme-neutral-600",
        "stroke-theme-neutral-600",
        "hover:text-theme-blue-700",
        "hover:fill-theme-blue-700",
        "hover:stroke-theme-blue-700"
      ]
    }
  ]
})
