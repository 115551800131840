import { cva } from "class-variance-authority"

export const megaMenuToutClass = cva(
    "flex justify-end",
    {
        variants: {
            colSpan: {
                "col-1": "col-span-1",
                "col-2": "col-span-2",
                "col-3": "col-span-3",
                "col-4": "col-span-4",
            }
        }
    }
)