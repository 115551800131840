export default (to, external = false, newWindow = false) => {
  const {
    location: { host, origin },
  } = window;
  const { pathname = to, ...toRest } = to;
  if (
    window.router &&
    (pathname.includes(host) || pathname.startsWith("/")) &&
    !external &&
    !newWindow
  ) {
    window.router.push({
      ...toRest,
      pathname: pathname.replace(origin, ""),
    });
  } else if (newWindow) {
    window.open(pathname, "_blank");
  } else {
    window.location.assign(pathname);
  }
};
