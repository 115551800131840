import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Fullscreen = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/av/fullscreen-fullscreen${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-fullscreen${unique}`}
        xlinkHref={`#path-1-fullscreen${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-fullscreen${unique}`}>
        <path
          d="M5,19 L8.5,19 C8.77614237,19 9,19.2238576 9,19.5 L9,20.5 C9,20.7761424 8.77614237,21 8.5,21 L3.5,21 C3.22385763,21 3,20.7761424 3,20.5 L3,19.5 L3,15.5 C3,15.2238576 3.22385763,15 3.5,15 L4.5,15 C4.77614237,15 5,15.2238576 5,15.5 L5,19 Z M19,5 L15.5,5 C15.2238576,5 15,4.77614237 15,4.5 L15,3.5 C15,3.22385763 15.2238576,3 15.5,3 L19.5,3 L20.5,3 C20.7761424,3 21,3.22385763 21,3.5 L21,8.5 C21,8.77614237 20.7761424,9 20.5,9 L19.5,9 C19.2238576,9 19,8.77614237 19,8.5 L19,5 Z M4.5,3 L8.5,3 C8.77614237,3 9,3.22385763 9,3.5 L9,4.5 C9,4.77614237 8.77614237,5 8.5,5 L5,5 L5,8.5 C5,8.77614237 4.77614237,9 4.5,9 L3.5,9 C3.22385763,9 3,8.77614237 3,8.5 L3,3.5 C3,3.22385763 3.22385763,3 3.5,3 L4.5,3 Z M21,19.5 L21,20.5 C21,20.7761424 20.7761424,21 20.5,21 L15.5,21 C15.2238576,21 15,20.7761424 15,20.5 L15,19.5 C15,19.2238576 15.2238576,19 15.5,19 L19,19 L19,15.5 C19,15.2238576 19.2238576,15 19.5,15 L20.5,15 C20.7761424,15 21,15.2238576 21,15.5 L21,19.5 Z"
          fill="inherit"
          id={`path-1-fullscreen${unique}`}
        />
      </g>
    </g>
  </svg>
)

Fullscreen.propTypes = propTypes
export default Fullscreen
