


import React from "react"
import { string } from "prop-types"

const propTypes = {
    unique: string,
}

const WosMobileLoginIcon = ({ unique = "", ...props }) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path id={`path-1-wos-mobile-login${unique}`} d="M3 16.5001C3 13.1864 5.68629 10.5 9 10.5V10.5C12.3137 10.5 15 13.1864 15 16.5001V16.5001H3V16.5001Z" stroke="black" />
        <circle cx="9" cy="4.5" r="3" stroke="black" />
    </svg>

)

WosMobileLoginIcon.propTypes = propTypes
export default WosMobileLoginIcon
