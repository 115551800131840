import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}
const Envelope = (props) => (
  <svg {...props} fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.6668 4.6665L8.68683 8.4665C8.48101 8.59545 8.24304 8.66384 8.00016 8.66384C7.75729 8.66384 7.51932 8.59545 7.3135 8.4665L1.3335 4.6665M2.66683 2.6665H13.3335C14.0699 2.6665 14.6668 3.26346 14.6668 3.99984V11.9998C14.6668 12.7362 14.0699 13.3332 13.3335 13.3332H2.66683C1.93045 13.3332 1.3335 12.7362 1.3335 11.9998V3.99984C1.3335 3.26346 1.93045 2.6665 2.66683 2.6665Z" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
  </svg>
)

Envelope.propTypes = propTypes
export default Envelope
