import {
  AlertCopyright,
  AlertError,
  AlertNda,
  AlertWarning,
  ApplicationsAutomotiveCar,
  ApplicationsAutomotiveInfotainment,
  ApplicationsAutomotiveTelematics,
  ApplicationsCamera,
  ApplicationsCitySmart,
  ApplicationsConsumerElectronics,
  ApplicationsDocumentImagingScanner,
  ApplicationsGaming,
  ApplicationsHealthcare,
  ApplicationsIot,
  ApplicationsNetworkingNetworking,
  ApplicationsNetworkingNetworkingModem,
  ApplicationsNetworkingRouter,
  ApplicationsRobotics,
  ApplicationsSolutions,
  ApplicationsVoiceSound,
  AVExitFullscreen,
  AVFastForward,
  AVFullscreen,
  AVHd,
  AVLoop,
  AVPause,
  AVPlay,
  AVPlayAlt,
  AVRewind,
  AVSkipNext,
  AVSkipPrevious,
  AVStop,
  AVVolumeDown,
  AVVolumeMute,
  AVVolumeOff,
  AVVolumeUp,
  CommunicationsAnnouncement,
  CommunicationsBusiness,
  CommunicationsCall,
  CommunicationsChat,
  CommunicationsChatBubbleNews,
  CommunicationsComment,
  CommunicationsEmail,
  CommunicationsEmailAlternate,
  CommunicationsEmailSubscribe,
  CommunicationsEmailUnsubscribe,
  CommunicationsLiveHelp,
  CommunicationsNotification,
  CommunicationsRss,
  CommunicationsSmsMessage,
  CommunicationsSubscribe,
  CommunicationsUnsubscribe,
  DevicesDesktopComputer,
  DevicesDeviceHub,
  DevicesLaptop,
  DevicesPhoneMobileSmart,
  DevicesTablet,
  DevicesTv,
  DevicesWatch,
  DocumentsDoc,
  DocumentsGenericDocument,
  DocumentsNoteAdd,
  DocumentsPdf,
  DocumentsPpt,
  DocumentsTxt,
  DocumentsXls,
  DocumentsZip,
  DocSearch,
  FileAttachment,
  FileCloud,
  FileCloudDone,
  FileCloudOff,
  FileCloudUpload,
  FileCreateNewFolder,
  FileFolder,
  GeneralChartDataOutline,
  GeneralGroup,
  GeneralIntegration,
  GeneralPerson,
  GeneralPlaceholder,
  GeneralServices,
  MediaBlockDiagram,
  MediaBlogPost,
  MediaBook,
  MediaCommunityPost,
  MediaMediaChat,
  MediaPhotoImage,
  MediaVideo,
  MediaWebinar,
  NavAccount,
  NavAddPersonUser,
  NavAddPlus,
  NavAllDone,
  NavApplicationsApps,
  NavArrowCircleDown,
  NavArrowCircleLeft,
  NavArrowCircleRight,
  NavArrowCircleUp,
  NavArrowDown,
  NavArrowLeft,
  NavArrowRight,
  NavArrowUp,
  NavBackspaceDelete,
  NavBookmark,
  NavCancel,
  NavCaretDown,
  NavCaretLeft,
  NavCaretRight,
  NavCaretUp,
  NavCheck,
  NavCheckboxEmpty,
  NavCheckboxSelected,
  NavCheckCircle,
  NavClose,
  NavCompareArrows,
  NavControlTop,
  NavDashboard,
  NavDelete,
  NavDownload,
  NavEnlargeExpand,
  NavFilter,
  NavHelp,
  NavHome,
  NavInfo,
  NavLaunch,
  NavLink,
  NavLinkOut,
  NavLinkOutSimple,
  NavList,
  NavMenu,
  NavMinusCircleOutline,
  NavMobilefilters,
  NavMore,
  NavMoreCircle,
  NavMoreSites,
  NavOverflow,
  NavPlusCircleOutline,
  NavPreferences,
  NavProfile,
  NavRefresh,
  NavRemoveMinus,
  NavSearch,
  NavSelect,
  NavSettingsGear,
  NavShare,
  NavSitemap,
  NavTrash,
  NavTriangleDown,
  NavTriangleLeft,
  NavTriangleRight,
  NavTriangleUp,
  NavUpload,
  NavZoomIn,
  NavZoomOut,
  OsAndroid,
  OsLinux,
  OsMac,
  OsWindows,
  PlacesGlobeWorldNorthAmerica,
  PlacesLocationPin,
  ProductsChip,
  ProductsChipset,
  ProductsDevboard,
  ProductsDevkit,
  ProductsHardware,
  ProductsMemory,
  ProductsModem,
  ProductsPlatform,
  ProductsProcessor,
  ProductsSoc,
  ProductsSoftware,
  RffeArrows,
  RffeCar,
  RffeIcons,
  RffePhone,
  SecurityFingerprint,
  SecurityLock,
  SecurityLockCopy,
  SecurityVerifiedUser,
  Shield,
  ShoppingCreditCardPayment,
  ShoppingFavorite,
  ShoppingFavoriteBorder,
  ShoppingReceipt,
  ShoppingShoppingCart,
  ShoppingShoppingCartAdd,
  ShoppingShoppingCartRemove,
  ShoppingStar,
  ShoppingStarEmpty,
  ShoppingStarHalf,
  SignedLicenseIcon,
  SocialBaidu,
  SocialBaiduBox,
  SocialFacebook,
  SocialFacebookBox,
  SocialFlickr,
  SocialFlickrBox,
  SocialGoogleplus,
  SocialGoogleplusBox,
  SocialInstagram,
  SocialInstagramBox,
  SocialLinkedin,
  SocialLinkedinBox,
  SocialPinterest,
  SocialPinterestBox,
  SocialQq,
  SocialQqBox,
  SocialQzone,
  SocialQzoneBox,
  SocialRenren,
  SocialRenrenBox,
  SocialTumblr,
  SocialTumblrBox,
  SocialTwitter,
  SocialTwitterBox,
  SocialWechat,
  SocialWechatBox,
  SocialWeibo,
  SocialWeiboBox,
  SocialYoutube,
  SocialYoutubeBox,
  TechnologyApi,
  TechnologyBlockchain,
  TechnologyBluetooth,
  TechnologyBugReport,
  TechnologyChipCode,
  TechnologyCode,
  TechnologyConnectivity,
  TechnologyExtension,
  TechnologyPlatform,
  TechnologyPower,
  TechnologyTouchApp,
  TechnologyWifi,
  TimeCalendar,
  TimeEvent,
  UnsignedLicenseIcon,
  SocialFacebookWOS,
  SocialInstagramWOS,
  SocialTwitterWOS,
  SocialLinkedinWOS,
  SocialYoutubeWOS,
  SocialDiscordWOS,
  SocialRedditinWOS,
} from "./elements"
import Bookmark from "./elements/dx/Bookmark"
import DxLeftArrow from "./elements/dx/LeftArrow"
import DxRightArrow from "./elements/dx/RightArrow"
import DxClose from "./elements/dx/Close"
import DxCheckmark from "./elements/dx/Checkmark"
import DxEnvelope from "./elements/dx/Envelope"
import DxNavLinkOut from "./elements/dx/NavLinkOut"
import DxSearch from "./elements/dx/Search"
import DxChevronDown from "./elements/dx/ChevronDown"
import DxChevronRight from "./elements/dx/ChevronRight"
import DxUser from "./elements/dx/User"
import DxLoggedInUser from "./elements/dx/LoggedInUser"
import DxHamburger from "./elements/dx/Hamburger"
import DxPlus from "./elements/dx/Plus"
import DxMinus from "./elements/dx/Minus"
import RightArrow from "./elements/cta/RightArrow"
import VideoCta from "./elements/cta/Video"
import AccordionPlus from "./elements/accordion/accordionPlus"
import AccordionCollapse from "./elements/accordion/accordionCollapse"
import ChevronRightMenu from "./elements/dx/ChevronRightMenu"

export * from "./elements"

export default {
  "dx-arrow-left": DxLeftArrow,
  "dx-arrow-right": DxRightArrow,
  "dx-bookmark": Bookmark,
  "dx-download": NavDownload,
  "dx-envelope": DxEnvelope,
  "dx-chevron-down": DxChevronDown,
  "dx-chevron-right": DxChevronRight,
  "dx-chevron-right-menu": ChevronRightMenu,
  "dx-link-out-simple": DxNavLinkOut,
  "dx-close": DxClose,
  "dx-checkmark": DxCheckmark,
  "dx-search": DxSearch,
  "dx-user": DxUser,
  "dx-logged-in-user": DxLoggedInUser,
  "dx-hamburger": DxHamburger,
  "dx-plus": DxPlus,
  "dx-minus": DxMinus,

  "accordion-collapse":AccordionCollapse,
  "accordion-expand":AccordionPlus,
  "right-arrow":RightArrow,
  "video-cta":VideoCta,
  account: NavAccount,
  "add-person-user": NavAddPersonUser,
  "add-plus": NavAddPlus,
  "all-done": NavAllDone,
  android: OsAndroid,
  announcement: CommunicationsAnnouncement,
  api: TechnologyApi,
  "applications-apps": NavApplicationsApps,
  "arrow-circle-down": NavArrowCircleDown,
  "arrow-circle-left": NavArrowCircleLeft,
  "arrow-circle-right": NavArrowCircleRight,
  "arrow-circle-up": NavArrowCircleUp,
  "arrow-down": NavArrowDown,
  "arrow-left": NavArrowLeft,
  "arrow-right": NavArrowRight,
  "arrow-up": NavArrowUp,
  attachment: FileAttachment,
  "backspace-delete": NavBackspaceDelete,
  baidu: SocialBaidu,
  "baidu-box": SocialBaiduBox,
  "block-diagram": MediaBlockDiagram,
  blockchain: TechnologyBlockchain,
  "blog-post": MediaBlogPost,
  bluetooth: TechnologyBluetooth,
  book: MediaBook,
  bookmark: NavBookmark,
  "bug-report": TechnologyBugReport,
  business: CommunicationsBusiness,
  calendar: TimeCalendar,
  call: CommunicationsCall,
  camera: ApplicationsCamera,
  cancel: NavCancel,
  car: ApplicationsAutomotiveCar,
  "caret-down": NavCaretDown,
  "caret-left": NavCaretLeft,
  "caret-right": NavCaretRight,
  "caret-up": NavCaretUp,
  "chart-data-outline": GeneralChartDataOutline,
  chat: CommunicationsChat,
  "chat-bubble-news": CommunicationsChatBubbleNews,
  check: NavCheck,
  "check-circle": NavCheckCircle,
  "checkbox-empty": NavCheckboxEmpty,
  "checkbox-selected": NavCheckboxSelected,
  chip: ProductsChip,
  "chip-code": TechnologyChipCode,
  chipset: ProductsChipset,
  "city-smart": ApplicationsCitySmart,
  close: NavClose,
  cloud: FileCloud,
  "cloud-done": FileCloudDone,
  "cloud-off": FileCloudOff,
  "cloud-upload": FileCloudUpload,
  code: TechnologyCode,
  comment: CommunicationsComment,
  "community-post": MediaCommunityPost,
  "compare-arrows": NavCompareArrows,
  connectivity: TechnologyConnectivity,
  "consumer-electronics": ApplicationsConsumerElectronics,
  "control-top": NavControlTop,
  copyright: AlertCopyright,
  "create-new-folder": FileCreateNewFolder,
  "credit-card-payment": ShoppingCreditCardPayment,
  dashboard: NavDashboard,
  delete: NavDelete,
  "desktop-computer": DevicesDesktopComputer,
  devboard: ProductsDevboard,
  "device-hub": DevicesDeviceHub,
  devkit: ProductsDevkit,
  doc: DocumentsDoc,
  "doc-search":DocSearch,
  "document-imaging-scanner": ApplicationsDocumentImagingScanner,
  download: NavDownload,
  email: CommunicationsEmail,
  "email-alternate": CommunicationsEmailAlternate,
  "email-subscribe": CommunicationsEmailSubscribe,
  "email-unsubscribe": CommunicationsEmailUnsubscribe,
  "enlarge-expand": NavEnlargeExpand,
  error: AlertError,
  event: TimeEvent,
  "exit-fullscreen": AVExitFullscreen,
  extension: TechnologyExtension,
  facebook: SocialFacebook,
  "facebook-box": SocialFacebookBox,
  "fast-forward": AVFastForward,
  favorite: ShoppingFavorite,
  "favorite-border": ShoppingFavoriteBorder,
  filter: NavFilter,
  fingerprint: SecurityFingerprint,
  flickr: SocialFlickr,
  "flickr-box": SocialFlickrBox,
  folder: FileFolder,
  fullscreen: AVFullscreen,
  gaming: ApplicationsGaming,
  "generic-document": DocumentsGenericDocument,
  "globe-world-north-america": PlacesGlobeWorldNorthAmerica,
  googleplus: SocialGoogleplus,
  "googleplus-box": SocialGoogleplusBox,
  group: GeneralGroup,
  hardware: ProductsHardware,
  hd: AVHd,
  healthcare: ApplicationsHealthcare,
  help: NavHelp,
  home: NavHome,
  info: NavInfo,
  infotainment: ApplicationsAutomotiveInfotainment,
  instagram: SocialInstagram,
  "instagram-box": SocialInstagramBox,
  integration: GeneralIntegration,
  iot: ApplicationsIot,
  laptop: DevicesLaptop,
  launch: NavLaunch,
  link: NavLink,
  "link-out": NavLinkOut,
  "link-out-simple": NavLinkOutSimple,
  linkedin: SocialLinkedin,
  "linkedin-box": SocialLinkedinBox,
  linux: OsLinux,
  list: NavList,
  "live-help": CommunicationsLiveHelp,
  "location-pin": PlacesLocationPin,
  lock: SecurityLock,
  "lock-copy": SecurityLockCopy,
  loop: AVLoop,
  mac: OsMac,
  "media-chat": MediaMediaChat,
  memory: ProductsMemory,
  menu: NavMenu,
  "minus-circle-outline": NavMinusCircleOutline,
  mobilefilters: NavMobilefilters,
  modem: ProductsModem,
  more: NavMore,
  "more-circle": NavMoreCircle,
  "more-sites": NavMoreSites,
  nda: AlertNda,
  networking: ApplicationsNetworkingNetworking,
  "networking-modem": ApplicationsNetworkingNetworkingModem,
  "note-add": DocumentsNoteAdd,
  notification: CommunicationsNotification,
  overflow: NavOverflow,
  pause: AVPause,
  pdf: DocumentsPdf,
  person: GeneralPerson,
  "phone-mobile-smart": DevicesPhoneMobileSmart,
  "photo-image": MediaPhotoImage,
  pinterest: SocialPinterest,
  "pinterest-box": SocialPinterestBox,
  placeholder: GeneralPlaceholder,
  platform: ProductsPlatform,
  "platform-technology": TechnologyPlatform,
  play: AVPlay,
  "play-alt": AVPlayAlt,
  "plus-circle-outline": NavPlusCircleOutline,
  power: TechnologyPower,
  ppt: DocumentsPpt,
  preferences: NavPreferences,
  processor: ProductsProcessor,
  profile: NavProfile,
  qq: SocialQq,
  "qq-box": SocialQqBox,
  qzone: SocialQzone,
  "qzone-box": SocialQzoneBox,
  receipt: ShoppingReceipt,
  refresh: NavRefresh,
  "remove-minus": NavRemoveMinus,
  renren: SocialRenren,
  "renren-box": SocialRenrenBox,
  rewind: AVRewind,
  "rffe-arrows": RffeArrows,
  "rffe-car": RffeCar,
  "rffe-icons": RffeIcons,
  "rffe-phone": RffePhone,
  robotics: ApplicationsRobotics,
  router: ApplicationsNetworkingRouter,
  rss: CommunicationsRss,
  search: NavSearch,
  select: NavSelect,
  services: GeneralServices,
  "settings-gear": NavSettingsGear,
  share: NavShare,
  shield: Shield,
  "shopping-cart": ShoppingShoppingCart,
  "shopping-cart-add": ShoppingShoppingCartAdd,
  "shopping-cart-remove": ShoppingShoppingCartRemove,
  "signed-license-icon": SignedLicenseIcon,
  sitemap: NavSitemap,
  "skip-next": AVSkipNext,
  "skip-previous": AVSkipPrevious,
  "sms-message": CommunicationsSmsMessage,
  soc: ProductsSoc,
  software: ProductsSoftware,
  solutions: ApplicationsSolutions,
  star: ShoppingStar,
  "star-empty": ShoppingStarEmpty,
  "star-half": ShoppingStarHalf,
  stop: AVStop,
  subscribe: CommunicationsSubscribe,
  tablet: DevicesTablet,
  telematics: ApplicationsAutomotiveTelematics,
  "touch-app": TechnologyTouchApp,
  trash: NavTrash,
  "triangle-down": NavTriangleDown,
  "triangle-left": NavTriangleLeft,
  "triangle-right": NavTriangleRight,
  "triangle-up": NavTriangleUp,
  tumblr: SocialTumblr,
  "tumblr-box": SocialTumblrBox,
  tv: DevicesTv,
  twitter: SocialTwitter,
  "twitter-box": SocialTwitterBox,
  txt: DocumentsTxt,
  "unsigned-license-icon": UnsignedLicenseIcon,
  unsubscribe: CommunicationsUnsubscribe,
  upload: NavUpload,
  "verified-user": SecurityVerifiedUser,
  video: MediaVideo,
  "voice-sound": ApplicationsVoiceSound,
  "volume-down": AVVolumeDown,
  "volume-mute": AVVolumeMute,
  "volume-off": AVVolumeOff,
  "volume-up": AVVolumeUp,
  warning: AlertWarning,
  watch: DevicesWatch,
  webinar: MediaWebinar,
  wechat: SocialWechat,
  "wechat-box": SocialWechatBox,
  weibo: SocialWeibo,
  "weibo-box": SocialWeiboBox,
  wifi: TechnologyWifi,
  windows: OsWindows,
  xls: DocumentsXls,
  youtube: SocialYoutube,
  "youtube-box": SocialYoutubeBox,
  zip: DocumentsZip,
  "zoom-in": NavZoomIn,
  "zoom-out": NavZoomOut,
  facebookwos: SocialFacebookWOS,
  instagramwos: SocialInstagramWOS,
  twitterwos: SocialTwitterWOS,
  linkedinwos: SocialLinkedinWOS,
  youtubewos: SocialYoutubeWOS,
  discordwos: SocialDiscordWOS,
  redditwos: SocialRedditinWOS
}
