import React, {useMemo} from "react"
import {func, node, number, shape} from "prop-types"
import {useTransition} from "react-spring"
import {easeCubic} from "d3-ease"

import Primitives from "primitives"

const propTypes = {
  children: node,
  config: shape({friction: number, mass: number, tension: number}),
  delay: number,
  duration: number,
  ease: func,
}

const CrossFade = ({
  children,
  config = {friction: 30, mass: 1, tension: 180},
  delay,
  duration,
  ease = easeCubic,
  ...props
}) => {
  const items = React.Children.toArray(children) || []
  const configVals = useMemo(() => {
    if (duration) {
      return {
        ...config,
        duration,
        ease,
      }
    }
    return config
  }, [config, duration, ease])
  const transitions = useTransition(items, (item) => item.key, {
    config: configVals,
    delay,
    enter: {opacity: 1},
    from: {opacity: 0},
    initial: {opacity: 0},
    leave: {
      left: 0,
      opacity: 0,
      position: "absolute",
      top: 0,
      width: "100%",
      zIndex: 1,
    },
    unique: true,
  })

  return transitions.map(({item, props: styleProps, key}) => (
    <Primitives.AnimatedBox key={key} style={styleProps} {...props}>
      {item}
    </Primitives.AnimatedBox>
  ))
}

CrossFade.propTypes = propTypes
CrossFade.displayName = "Animations.CrossFade"

export default CrossFade
