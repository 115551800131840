import React, {ReactElement, useMemo} from "react"
import {GlobalNavMenuProps} from "./GlobalNavMenu.interface"
import {MegaMenuItemType} from "../../utils/common.interface"
import NavMenuItem from "../../atoms/NavMenuItem/NavMenuItem"
import MegaMenu from "../../organisms/MegaMenu/MegaMenu"
import {TransitionHeight} from "lib/animations"

const GlobalNavMenu = ({
    navMenuItems,
    handleNavItemClick,
    activeMenuItem,
    handleMegaMenuItemClick
}: GlobalNavMenuProps): ReactElement => {
    const activeMegaMenuItem = useMemo(() => navMenuItems.find(menuItem => menuItem.title === activeMenuItem), [activeMenuItem])

    return (
        <div className="flex-col">
            <div className="flex items-center gap-x-s">
                {navMenuItems.map((navMenuItem: MegaMenuItemType) => (
                    <NavMenuItem
                        key={navMenuItem.title}
                        navMenuItem={navMenuItem}
                        isActive={navMenuItem.title === activeMenuItem}
                        handleClick={handleNavItemClick}
                    />
                ))}
            </div>


            <div className="absolute left-0 w-full mt-[26px] z-10 bg-white tw-preflight">
                <TransitionHeight
                    className=""
                    config={{friction: 30, mass: 1, tension: 300}}
                    width={1}
                    overflow="hidden"
                    show={activeMenuItem && activeMegaMenuItem}
                    zIndex={1}
                    enterDuration={150}
                    exitDuration={100}
                    onAnimationClose={null}
                    onAnimationOpen={null}
                >
                    {activeMenuItem && activeMegaMenuItem && (
                        <MegaMenu
                            activeMenuItem={activeMegaMenuItem}
                            handleMegaMenuItemClick={handleMegaMenuItemClick}
                        />
                    )}
                </TransitionHeight>
            </div>
        </div>
    )
}

export default GlobalNavMenu