


import React from "react"
import {string} from "prop-types";



const propTypes = {
    unique: string,
}
const VideoCta = ({unique = "", ...props}) => (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path  d="M9.99935 18.3334C14.6017 18.3334 18.3327 14.6025 18.3327 10.0001C18.3327 5.39771 14.6017 1.66675 9.99935 1.66675C5.39698 1.66675 1.66602 5.39771 1.66602 10.0001C1.66602 14.6025 5.39698 18.3334 9.99935 18.3334Z" stroke="inherit" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.33268 6.66675L13.3327 10.0001L8.33268 13.3334V6.66675Z" stroke="inherit" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>


)


VideoCta.propTypes = propTypes
export default VideoCta



