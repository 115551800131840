export const CREATEPOINT_SET_SEARCH_TEXT = "CREATEPOINT_SET_SEARCH_TEXT"
export const CREATEPOINT_SET_FILTER = "CREATEPOINT_SET_FILTER"
export const CREATEPOINT_SET_SORT_OPTION = "CREATEPOINT_SET_SORT_OPTION"
export const CREATEPOINT_SHOW_AGREEMENTS_MODAL = "CREATEPOINT_SHOW_AGREEMENTS_MODAL"
export const CREATEPOINT_SHOW_ACTIVATION_MODAL =
  "CREATEPOINT_SHOW_ACTIVATION_MODAL"
export const CREATEPOINT_SHOW_CONTENT_POD_MODAL =
  "CREATEPOINT_SHOW_CONTENT_POD_MODAL"

const initialState = {
  missingAgreementTypes: null,
  activationCodeCallback: () => null,
  activationCodeData: {},
  isContentPodModalActive: false,
  isSoftwareActivationModalActive: false,
  qlaCallback: () => null,
  showQla: false,
  showSra: false,
}

const reducer = {
  [CREATEPOINT_SHOW_ACTIVATION_MODAL]: (
    state,
    {callback, data, isSoftwareActivationModalActive} = {},
  ) => ({
    ...state,
    activationCodeCallback: callback || initialState.activationCodeCallback,
    activationCodeData: data || initialState.activationCodeData,
    isSoftwareActivationModalActive:
      isSoftwareActivationModalActive ||
      initialState.isSoftwareActivationModalActive,
  }),
  [CREATEPOINT_SHOW_CONTENT_POD_MODAL]: (state, {value}) => ({
    ...state,
    isContentPodModalActive: value || initialState.isContentPodModalActive,
  }),
  [CREATEPOINT_SHOW_AGREEMENTS_MODAL]: (state, {missingAgreementTypes, callback, showQla, showSra} = {}) => {
    return {
      ...state,
      missingAgreementTypes,
      qlaCallback: callback || initialState.qlaCallback,
      showQla: showQla || initialState.showQla,
      showSra: showSra || initialState.showSra,
    }
  },
}

function createpointReducer(state = initialState, action) {
  const func = reducer[action.type]
  return func ? func(state, action) : state
}

export default createpointReducer
