import {gql} from "@apollo/client"

const GET_SEARCH_AUTOSUGGEST = gql`
  query SearchAutosuggest(
    $requestGuid: String!
    $sessionGuid: String!
    $searchText: String!
  ) {
    searchAutosuggest(
      requestGuid: $requestGuid
      sessionGuid: $sessionGuid
      searchText: $searchText
    ) {
      SuggestedSpellcheck
      SuggestedSearches {
        searchText
      }
      SuggestedFilters {
        SearchText
        filtersFields {
          field
          values
        }
      }
      SuggestedResources {
        searchText
        filterFields {
          field
          values
        }
      }
    }
  }
`

export default GET_SEARCH_AUTOSUGGEST
