import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const ArrowCircleRight = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/arrow_circle_right-arrow-circle-right${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`ic_cancel_24px-arrow-circle-right${unique}`}
        xlinkHref={`#path-1-arrow-circle-right${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-arrow-circle-right${unique}`}>
        <path
          d="M12,2 C17.53,2 22,6.47 22,12 C22,17.53 17.53,22 12,22 C6.47,22 2,17.53 2,12 C2,6.47 6.47,2 12,2 Z M14.7415836,11.0018565 L6.52413351,11.0018565 C6.24799113,11.0018565 6.02413351,11.2257142 6.02413351,11.5018565 L6.02413351,12.4414529 C6.02413351,12.7175953 6.24799113,12.9414529 6.52413351,12.9414529 L14.7374972,12.9414529 L11.2335419,16.4454081 C11.0382798,16.6406703 11.0382798,16.9572528 11.2335419,17.1525149 L11.8979369,17.8169099 C12.093199,18.012172 12.4097815,18.012172 12.6050436,17.8169099 L17.4805006,12.9414529 C17.8024045,12.6175058 17.9633565,12.2935586 17.9633565,11.9696115 C17.9633565,11.6456644 17.8024045,11.3217172 17.4805006,10.9977701 L12.6050436,6.1223131 C12.4097815,5.92705096 12.093199,5.92705096 11.8979369,6.1223131 L11.2335419,6.78670805 C11.0382798,6.9819702 11.0382798,7.29855269 11.2335419,7.49381483 L14.7415836,11.0018565 Z"
          fill="inherit"
          id={`path-1-arrow-circle-right${unique}`}
        />
      </g>
    </g>
  </svg>
)

ArrowCircleRight.propTypes = propTypes
export default ArrowCircleRight
