import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Instagram = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/instagram-instagram${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`fill-1-instagram${unique}`}
        xlinkHref={`#path-1-instagram${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-instagram${unique}`}>
        <path
          d="M15.6731501,21.0004173 L8.34692546,21.0004173 C5.54360519,21.0004173 3.25,18.7068121 3.25,15.9035294 L3.25,8.0968879 C3.25,5.29360519 5.54360519,3 8.34692546,3 L15.6731501,3 C18.4764328,3 20.7700005,5.29360519 20.7700005,8.0968879 L20.7700005,15.9035294 C20.7700005,18.7068121 18.4764328,21.0004173 15.6731501,21.0004173 Z M15.6731126,19.6536351 C16.6691792,19.6536351 17.6093538,19.2619771 18.3204382,18.5508926 C19.0315603,17.8397706 19.4231807,16.899596 19.4231807,15.9035294 L19.4231807,8.0968879 C19.4231807,7.1008588 19.0315603,6.1606467 18.3204382,5.44956223 C17.6093538,4.7384402 16.6691792,4.34681975 15.6731126,4.34681975 L8.34692546,4.34681975 C7.3508588,4.34681975 6.41068426,4.7384402 5.69956223,5.44956223 C4.9884402,6.1606467 4.59681975,7.1008588 4.59681975,8.0968879 L4.59681975,15.9035294 C4.59681975,16.899596 4.9884402,17.8397706 5.69956223,18.5508926 C6.41068426,19.2619771 7.3508588,19.6536351 8.34692546,19.6536351 L15.6731126,19.6536351 Z M16.8615713,11.9307041 C16.8615713,14.5951955 14.7015738,16.755193 12.0370824,16.755193 C9.3725909,16.755193 7.21263098,14.5951955 7.21263098,11.9307041 C7.21263098,9.26625017 9.3725909,7.10625269 12.0370824,7.10625269 C14.7015738,7.10625269 16.8615713,9.26625017 16.8615713,11.9307041 Z M12.0138691,8.77542873 C10.2691185,8.77542873 8.85476245,10.1898224 8.85476245,11.9345354 C8.85476245,13.679286 10.2691185,15.0936796 12.0138691,15.0936796 C13.7585821,15.0936796 15.1729758,13.679286 15.1729758,11.9345354 C15.1729758,10.1898224 13.7585821,8.77542873 12.0138691,8.77542873 Z M18.081368,7.03121902 C18.081368,7.66210882 17.569925,8.17358931 16.9390352,8.17358931 C16.3081079,8.17358931 15.7966649,7.66210882 15.7966649,7.03121902 C15.7966649,6.40032923 16.3081079,5.88884873 16.9390352,5.88884873 C17.569925,5.88884873 18.081368,6.40032923 18.081368,7.03121902 Z"
          fill="inherit"
          id={`path-1-instagram${unique}`}
        />
      </g>
    </g>
  </svg>
)

Instagram.propTypes = propTypes
export default Instagram
