import { useCallback, useEffect } from "react";

/** Hook for triggering a callback when the escape key is pressed */
const useEscapeClose = (callback) => {
  const close = useCallback(
    (evt) => {
      if (evt.keyCode && evt.keyCode === 27) {
        callback(evt);
      } else if (!evt.keyCode) {
        callback(evt);
      }
    },
    [callback],
  );

  useEffect(() => {
    document.body.addEventListener("keyup", close);
    return () => {
      document.body.removeEventListener("keyup", close);
    };
  }, [close]);

  return close;
};

export default useEscapeClose;
