import {
  __,
  addIndex,
  always,
  ap,
  append,
  assoc,
  complement,
  compose,
  contains,
  curry,
  dissoc,
  either,
  eqProps,
  flatten,
  head,
  ifElse,
  is,
  isEmpty,
  isNil,
  join,
  juxt,
  keys,
  map,
  modulo,
  multiply,
  not,
  nth,
  of,
  pathOr,
  pickBy,
  pipe,
  propOr,
  reduce,
  sortBy,
  tail,
  toLower,
  toUpper,
  useWith,
  without,
} from "ramda";
import {v4 as uuid} from "uuid"

export const generateGuid = () => uuid()

/** string number to int */

export const convertToInt = map(Number);

// strIntToBool :: String -> Boolean
export const strIntToBool = (x) => Boolean(parseInt(x, 10));
/*
 * Extract deeply nested keys from objects, return null if not present
 */
export const pathOrNull = pathOr(null);

/*
 * Extract value from object, return null if not present
 */
export const propOrNull = propOr(null);

/*
 * Extract first value from 'values' array, within 'fields', return defaultValue if not
 * present. Useful for extracting fields data from Node objects. 1-3 arity
 * function, returns partially applied fn if less than 3.
 */
export const fieldOr = curry((defaultValue, fieldName, node) =>
  pathOr(defaultValue, ["fields", fieldName, 0], node),
);

/**
 * Returns all values from node field.
 * fieldNames Array<String> || String
 */
export const fieldValuesOr = curry((defaultValue, fieldNames, node) =>
  pathOr(defaultValue, ["fields", ...flatten([fieldNames])], node),
);

/*
 * fieldOr with default value being null.
 */
export const fieldValuesOrNull = fieldValuesOr(null);

/*
 * fieldOr with default value being null.
 */
export const fieldOrNull = fieldOr(null);

/*
 * 1 arity function returning true if argument is not nit and false otherwise
 */
export const isDefined = complement(isNil);

/*
 * single arity function returning true if argument is nil/undefined or if it
 * is an empty data structure, ie: []/{},''.
 *
 * isEmptyOrUndefined :: a -> Boolean
 */
export const isEmptyOrUndefined = either(isNil, isEmpty);

/*
 * single arity function returning true if argument is defined and is not an
 * empty data structure, ie: []/{}/''.
 *
 * hasData :: a -> Boolean
 */
export const hasData = complement(isEmptyOrUndefined);

/*
 * a -> [a] -> [a]
 * 1 arity function taking an an item and returning a function that takes a list
 * and will return a new list with the item removed if it was contained in the
 * original list or added if it was not.
 */
export const removeOrAppend = curry((item, list) =>
  ifElse(contains(item), without([item]), append(item))(list),
);

/*
 * a: Number
 * b: Number
 * c: Number
 * a -> b -> c
 * pad number with leading zeroes
 *
 */
export const pad = (number, size) => ("0".repeat(size) + number).slice(-size);

/*
 * (a -> Int -> b) -> [a] -> [b]
 *
 * Map function which passes index as second argument to transform function.
 */
export const mapIndexed = addIndex(map);

/*
 * Reduce function which passes index as third argument to transform function.
 */
export const reduceIndexed = addIndex(reduce);

// capitalize :: String -> String
export const capitalize = pipe(juxt([pipe(head, toUpper), tail]), join(""));

// isOdd :: Number -> Boolean
export const isOdd = pipe(modulo(__, 2), Boolean);

// isEven :: Number -> Boolean
export const isEven = not(pipe(modulo(__, 2), Boolean));

/**
 * Rename object keys
 * {a: b} -> {a: *} -> {b: *}
 */
export const renameKeys = curry((keysMap, obj) =>
  reduce(
    (acc, key) => assoc(keysMap[key] || key, obj[key], acc),
    {},
    keys(obj),
  ),
);

/**
 * Finds the differences between two objects, returning only shallow keys/values changed in left
 * @param {Object} left Object to return without keys/values from right
 * @param {Object} right Object whose keys will be used to remove from left
 * @returns {Object} Difference in first object, compared to second object
 */
export const mergeLeftDiff = (left, right) =>
  pickBy((_, key) => !eqProps(key, left, right), left);

/**
 * Finds a key in an object, removes key if it exists, or adds key with provided value if not
 * @param {string} key Property to add/remove in object
 * @param {any} value Value to assign to key if it does not exist
 * @param {Object} obj Object to search
 */
export const assocOrDissoc = curry((key, value, obj) =>
  propOr(null, key, obj) ? dissoc(key, obj) : assoc(key, value, obj),
);

/**
 * Checks to see if `check` has data, and either equals `value` or, if
 * `check` is an array, if it includes `value`
 */
export const equalsOrContainsValue = curry(
  (check, value) =>
    check === value || (is(Array, check) && contains(value, check)),
);

/**
 * Sorts a list based on a prop in alphabetical order
 */
export const sortAlphabeticallyBy = curry((prop, list) =>
  sortBy(compose(toLower, propOr("", prop)), list),
);

/*
 * A helper for converting byte values to human readable values
 * like 1024 bytes to 1KB
 */
export const BytesToHumanize = (bytes, decimals = 0) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))}${sizes[i]}`;
};

/*
 * A helper for parsing topUnitPadding and bottomUnitPadding
 * provided from the Drupal CMS API into valid @mirum-qc style-system
 * values.
 */
export const parseUnitPadding = (paddingValue = 2) =>
  map(multiply(paddingValue), [25, 30, 45, 50, 50]);

/**
 * A helper for ensuring that a value is an array.  Useful for functions that need to
 * iterate over dynamic data.
 * @param param {*}
 * @returns {*[]}
 */
export const ensureArray = (param) => (is(Array, param) ? param : [param]);

/**
 * A function that will always return nothing.  Useful for default props.
 * @returns {() => null}
 */
export const RenderNothing = always(null);

/**
 * Calls the passed argument if it's a function.  Otherwise, does nothing.
 * @param funct
 * @param args
 * @returns {null}
 */
export const callIfFunc = (funct, ...args) =>
  typeof funct === "function" ? funct.apply(this, args) : null;

/**
 * A pickAll for lists.  Picks the elements at @indices from the supplied @array.
 * @param indices: [integer]
 * @param array: [any]
 * @returns {any}
 */
export const pickIdxs = (indices, array) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useWith(ap, [map(nth), of])(indices, array);

/**
 * returns a formatted string that replaces wither the supplied regex or anything proceeding a colon
 * @param str: string
 * @param id: string
 * @param regex: string?
 * @returns {string}
 */
export const formatDownloadUrl = (str, id, regex) =>
  `/${str?.replace(regex || /:.*/, id)}`;

/**
 * Used to add or remove a trailing `s` to strings based on the length of the item that they represent.
 * Example:
 * const items = ["one", "two"]
 * console.log(`There are ${items.length} item${getPluralitySuffix(items.length)}`)
 * // prints "There are 2 items"
 * @param length {number}
 * @returns {string}
 */
export const getPluralitySuffix = (length) => (length === 1 ? "" : "s");

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index += 1) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array);
  }
};

/**
 * add commas to a number for example
 * 1000 => 1,000 and 1000000 => 1,000,000
 * @param {number|string} x
 * @returns {string}
 */
export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
