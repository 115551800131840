import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}
const LoggedInUser = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 21V18.697C20 17.4754 19.5184 16.3038 18.6611 15.44C17.8038 14.5762 16.641 14.0909 15.4286 14.0909H8.57143C7.35901 14.0909 6.19625 14.5762 5.33894 15.44C4.48163 16.3038 4 17.4754 4 18.697V21" fill="#3253DC"/>
    <path d="M12 11.2121C14.5247 11.2121 16.5714 9.14992 16.5714 6.60606C16.5714 4.0622 14.5247 2 12 2C9.47527 2 7.42857 4.0622 7.42857 6.60606C7.42857 9.14992 9.47527 11.2121 12 11.2121Z" fill="#3253DC"/>
  </svg>  
)

LoggedInUser.propTypes = propTypes
export default LoggedInUser
