import { useCallback, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

import { deserialize, serialize } from "components/filter-provider/helpers";
import { toLower } from "ramda";
import { getMeta } from "lib/apolloClient/client";
import { urlMap } from "SecondaryNav";

const isPathActive = (pathname, currentLocationPathname) =>
  pathname === currentLocationPathname;

/**
 * @typedef {{
 *  activeCategory: string,
 *  searchQuery: string,
 *  searchValue: string,
 *  setActiveCategory: (category: string) => void,
 *  setSearchQuery: (query: string) => void,
 *  setSearchValue: React.Dispatch<any>
 * }} useSearchQueryRetVal
 */

/**
 * Provides the searchQuery, activeCategory, and route updating functionality.
 * Configured for cooperability between the Filter Provider and Global Search Nav.
 * Due to the filter provider's integration with the shareable urls, side-effects
 * are triggered whenever route parameters change.  Having more than one
 * filter provider active at a single time causes issues.
 * @param {string} pathname
 * @returns {useSearchQueryRetVal}
 */
export function useSearchQuery() {
  const env = toLower(getMeta("Environment") || "prod")
  const pathname = `${urlMap?.[env]}/search`
  const { push } = useHistory();
  const { pathname: currentLocationPathname, search } = useLocation();
  const {
    activeCategory: currentCategory,
    activeFilters,
    activeFiltersWithParent,
    searchQuery: currentQuery,
  } = useMemo(
    () =>
      isPathActive(pathname, currentLocationPathname)
        ? deserialize(search)
        : { searchQuery: "" },
    [currentLocationPathname, pathname, search],
  );

  const [searchValue, setSearchValue] = useState(currentQuery);

  const setSearchQuery = useCallback(
    (query) => {
      if (query !== currentQuery || pathname !== currentLocationPathname) {
        window.location.href = serialize(
          isPathActive(pathname, currentLocationPathname)
            ? {
                activeCategory: currentCategory,
                activeFilters,
                activeFiltersWithParent,
                pathname,
                query,
              }
            : { pathname, query },
        );
      }
    },
    [
      activeFilters,
      activeFiltersWithParent,
      currentCategory,
      currentLocationPathname,
      currentQuery,
      pathname,
    ],
  );

  const setActiveCategory = useCallback(
    (activeCategory) => {
      if (activeCategory !== currentCategory) {
        push(
          serialize(
            isPathActive(pathname, currentLocationPathname)
              ? {
                  activeCategory,
                  activeFilters,
                  activeFiltersWithParent,
                  pathname,
                  query: searchValue,
                }
              : { activeCategory, pathname, query: searchValue },
          ),
        );
      }
    },
    [
      activeFilters,
      activeFiltersWithParent,
      currentCategory,
      currentLocationPathname,
      pathname,
      push,
      searchValue,
    ],
  );
  return {
    activeCategory: currentCategory,
    searchQuery: currentQuery,
    searchValue,
    setActiveCategory,
    setSearchQuery,
    setSearchValue,
  };
}

export default useSearchQuery;
