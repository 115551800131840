import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const ApplicationsApps = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/applications_apps-applications-apps${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-applications-apps${unique}`}
        xlinkHref={`#path-1-applications-apps${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-applications-apps${unique}`}>
        <path
          d="M4.5,4 L7.5,4 C7.77614237,4 8,4.22385763 8,4.5 L8,7.5 C8,7.77614237 7.77614237,8 7.5,8 L4.5,8 C4.22385763,8 4,7.77614237 4,7.5 L4,4.5 C4,4.22385763 4.22385763,4 4.5,4 Z M4.5,10 L7.5,10 C7.77614237,10 8,10.2238576 8,10.5 L8,13.5 C8,13.7761424 7.77614237,14 7.5,14 L4.5,14 C4.22385763,14 4,13.7761424 4,13.5 L4,10.5 C4,10.2238576 4.22385763,10 4.5,10 Z M4.5,16 L7.5,16 C7.77614237,16 8,16.2238576 8,16.5 L8,19.5 C8,19.7761424 7.77614237,20 7.5,20 L4.5,20 C4.22385763,20 4,19.7761424 4,19.5 L4,16.5 C4,16.2238576 4.22385763,16 4.5,16 Z M10.5,4 L13.5,4 C13.7761424,4 14,4.22385763 14,4.5 L14,7.5 C14,7.77614237 13.7761424,8 13.5,8 L10.5,8 C10.2238576,8 10,7.77614237 10,7.5 L10,4.5 C10,4.22385763 10.2238576,4 10.5,4 Z M10.5,10 L13.5,10 C13.7761424,10 14,10.2238576 14,10.5 L14,13.5 C14,13.7761424 13.7761424,14 13.5,14 L10.5,14 C10.2238576,14 10,13.7761424 10,13.5 L10,10.5 C10,10.2238576 10.2238576,10 10.5,10 Z M10.5,16 L13.5,16 C13.7761424,16 14,16.2238576 14,16.5 L14,19.5 C14,19.7761424 13.7761424,20 13.5,20 L10.5,20 C10.2238576,20 10,19.7761424 10,19.5 L10,16.5 C10,16.2238576 10.2238576,16 10.5,16 Z M16.5,4 L19.5,4 C19.7761424,4 20,4.22385763 20,4.5 L20,7.5 C20,7.77614237 19.7761424,8 19.5,8 L16.5,8 C16.2238576,8 16,7.77614237 16,7.5 L16,4.5 C16,4.22385763 16.2238576,4 16.5,4 Z M16.5,10 L19.5,10 C19.7761424,10 20,10.2238576 20,10.5 L20,13.5 C20,13.7761424 19.7761424,14 19.5,14 L16.5,14 C16.2238576,14 16,13.7761424 16,13.5 L16,10.5 C16,10.2238576 16.2238576,10 16.5,10 Z M16.5,16 L19.5,16 C19.7761424,16 20,16.2238576 20,16.5 L20,19.5 C20,19.7761424 19.7761424,20 19.5,20 L16.5,20 C16.2238576,20 16,19.7761424 16,19.5 L16,16.5 C16,16.2238576 16.2238576,16 16.5,16 Z"
          fill="inherit"
          id={`path-1-applications-apps${unique}`}
        />
      </g>
    </g>
  </svg>
)

ApplicationsApps.propTypes = propTypes
export default ApplicationsApps
