import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const VerifiedUser = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/security/verified_user-verified-user${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`simplified_icons-technology-games-verified-user${unique}`}
        xlinkHref={`#path-1-verified-user${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-verified-user${unique}`}>
        <path
          d="M11.8269166,2.06748704 C11.9941194,1.96703607 12.1947614,1.98378032 12.3619643,2.08423129 C14.5523139,3.64128669 17.1941106,4.26076582 19.8359043,4.77977945 C20.638481,4.93046316 20.638481,4.89697467 20.7053563,5.65039326 C21.1735273,9.91974252 20.1034347,13.837496 17.5619569,17.3032012 C16.1574571,19.2285937 14.4048716,20.7689067 12.3148437,21.9241426 C12.1309213,22.0413404 12.010839,22.0078547 11.8269166,21.9241426 C7.46293542,19.4295029 4.72081705,15.7126616 3.61728173,10.8070962 C3.18255566,8.96541763 3.16583543,7.09025344 3.39991872,5.21508635 C3.43335915,4.89697467 3.61728173,4.89697467 3.85136502,4.83001219 C5.95811393,4.52864476 8.08158386,4.05984935 10.0211314,3.12227307 C10.6397798,2.82090563 11.2082681,2.45257572 11.8269166,2.06748704 Z M17.2552075,8.65663654 C17.5429442,8.30407644 17.4912418,7.7863945 17.1405624,7.49408174 C17.1360663,7.48961903 17.1315701,7.48515922 17.127074,7.48292278 C16.7808921,7.19507564 16.2683598,7.22854961 15.96264,7.56102388 C14.9218419,8.69680241 13.2179004,10.5577826 10.7631456,13.2510711 C10.4192103,12.9966921 9.56724022,12.3629768 8.74449202,11.7493443 C8.29265579,11.4124045 7.65423955,11.4860414 7.29681736,11.9211624 C6.94164178,12.3495886 6.98884808,12.9810734 7.40247024,13.353715 C7.87004286,13.7776799 8.82541917,14.6345338 9.81451342,15.5203961 C10.4102195,16.0536982 11.3363725,15.9800628 11.8376631,15.3597355 L17.2552075,8.65663654 Z"
          fill="inherit"
          id={`path-1-verified-user${unique}`}
        />
      </g>
    </g>
  </svg>
)

VerifiedUser.propTypes = propTypes
export default VerifiedUser
