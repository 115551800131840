/* eslint-disable no-console */
import {
  always,
  cond,
  equals,
  find,
  head,
  isEmpty,
  join,
  last,
  map,
  omit,
  pathOr,
  pipe,
  propEq,
  propOr,
  reject,
  split,
  T,
  toLower,
  isNil,
  values,
} from "ramda"
import { call, put, select } from "redux-saga/effects"

import createClient from "lib/apolloClient/client"
import { query } from "lib/apolloClient/operations"
import {
  DDP_RESOURCE_PATH,
  GET_ACTIVE_ITEMS,
  GET_ACTIVE_NODE,
  GET_ACTIVE_NODE_PATH,
  GET_CURRENT_THEME_SETTING,
  GET_LOGGED_IN,
  GET_MENU_ITEMS,
  GET_MODEL_DATA,
  GET_ONETRUST_CONSENT_STATUS,
  GET_PAGE_VIEW_STATUS,
  GET_SCROLL_CHECKPOINT,
  GET_SEARCH_ORIGIN,
  GET_SESSION_GUID,
  GET_USER,
  GET_VIEWPORT_ORIENTATION,
  IS_DDP_TEMPLATE,
} from "lib/redux/selectors"
import {
  SET_PAGE_VIEW_STATUS,
  USER_LOGGED_IN,
  USER_LOGIN_FAILURE,
  USER_LOGIN_REQUEST,
} from "lib/redux/actions"
import { hasData, isEmptyOrUndefined, pathOrNull, reduceIndexed } from "lib/utils/helpers"
import SEND_GLOBAL_SEARCH_CLICK_EVENT from "gql/queries/interceptor/getClickEvent"
import { getInterceptorSourceApplication } from "lib/utils/search-interceptor"
import { createMegaMenuSection } from "components/navigation/utils"

export function trackFooterLink({ type }) {
  try {
    return {
      event: {
        action: "open",
        ev: type,
        label: type
          .replace("TOGGLE_", "")
          .replace("_PANEL", "")
          .toLowerCase()
          .replace(/_/g, " "),
        location: "GLOBAL_FOOTER",
      },
      type: "link",
    }
  } catch (error) {
    return null
  }
}

export function* trackGlobalFooter() {
  try {
    const activeItems = yield select(GET_ACTIVE_ITEMS)
    return {
      event: {
        action: activeItems.includes("globalFooter") ? "open" : "close",
        ev: "nav",
        label: "more",
        location: "leftnav",
      },
      type: "link",
    }
  } catch (error) {
    
    return null
  }
}

export function trackVideoPlay({
  currentTime,
  title,
  location = "Brightcove",
  videoLength,
}) {
  try {
    return {
      event: {
        action: currentTime < 0.1 ? "start" : "play",
        ev: "Video",
        label: title,
        location,
        video_length: videoLength,
      },
      type: "link",
    }
  } catch (error) {
    
    return null
  }
}

export function trackVideoPause({
  percentComplete,
  title,
  location = "Brightcove",
  videoLength,
}) {
  try {
    return {
      event: {
        action: percentComplete === 100 ? "complete" : "pause",
        ev: "Video",
        label: title,
        location,
        video_length: videoLength,
      },
      type: "link",
    }
  } catch (error) {
    
    return null
  }
}

export function trackVideoMilestone({
  percentComplete,
  title,
  location = "Brightcove",
  videoLength,
}) {
  try {
    return {
      event: {
        action: `${percentComplete}%`,
        ev: "VIDEO",
        label: title,
        location,
        video_length: videoLength,
      },
      type: "link",
    }
  } catch (error) {
    
    return null
  }
}

export function trackDownload({ location, searchStatus = {} }) {
  try {
    const { action, detail, label, url } = location
    const event = {
      action,
      detail,
      ev: "download",
      label,
      location: url,
      ...searchStatus,
    }
    return {
      event,
      type: "link",
    }
  } catch (error) {
    
    return null
  }
}

export function* trackLink({ location }) {
  try {
    const path = yield select(GET_ACTIVE_NODE_PATH)
    const { action, label, pathname = "document", state = {} } = location
    const {
      type = "link",
      text = "unknown",
      loc = pathname,
      document = null,
      ...misc
    } = state
    const event = {
      action: action || "click",
      detail: document,
      ev: toLower(type),
      label: label || text || pathname,
      location: loc,
      path,
      ...misc,
    }
    return {
      event,
      type: "link",
    }
  } catch (error) {
    
    return null
  }
}

const getIsTabsParent = (activeNode, hash) => {
  const tabsDataItems = activeNode?.tabsData?.responsivegrid?.[":items"] || {}
  const tabs = find(propEq(":type", DDP_RESOURCE_PATH), values(tabsDataItems))?.menuItems || []
  if (hash || !hasData(tabs)) {
    return false
  }
  const currentPageIsTab = find(propEq("path", `/content/qcomm-martech/us/en/home${activeNode?.cqPath}`), tabs)
  return !currentPageIsTab
}

export function* trackView() {
  const activeNode = yield select(GET_MODEL_DATA)
  const user = yield select(GET_USER)
  const loggedIn = yield select(GET_LOGGED_IN)
  const oneTrustStatus = yield select(GET_ONETRUST_CONSENT_STATUS)
  const documentUrl = window.location.href
  const lastPageView = yield select(GET_PAGE_VIEW_STATUS)
  const hash = (window?.location?.hash || "#").toLowerCase().substring(1) || activeNode?.hash
  const isTabsParent = getIsTabsParent(activeNode, hash)
  if (
    !isTabsParent &&
    hasData(activeNode) &&
    (!loggedIn || hasData(user)) &&
    oneTrustStatus &&
    lastPageView !== documentUrl
  ) {
    const groups = pipe(split(","), reject(isEmpty))(oneTrustStatus)
    // One Trust active groups will always have at minimum ,C0001,
    if (groups?.length > 1) {
      yield put({ type: SET_PAGE_VIEW_STATUS, value: documentUrl })
    }

    const pathname = (window?.location?.pathname || "/").substring(1)
    const pathForSections = `${pathname === "" ? "home page" : pathname}${hash ? `/${hash}` : ""}`
    const sections = pathForSections.split("/")

    const sectionsObject = {}
    reduceIndexed((acc, section, index) => {
      const sec = `${acc}:${section}`
      sectionsObject[`sc_site_section_${index + 1}`] = sec
      return sec
    }, "qc:us", sections)

    const pageType = activeNode?.pageType
    const orientation = yield select(GET_VIEWPORT_ORIENTATION)
    const stakeHolder = yield pathOrNull(
      ["fields", "stakeholderAnalytics", 0, "title"],
      activeNode,
    )
    const promotion = pathOrNull(
      ["fields", "promotion", 0, "title"],
      activeNode,
    )
    const technologyTopic = pathOrNull(
      ["fields", "technologyTopic", 0, "title"],
      activeNode,
    )
    const addCMSFiledsData = (data) => {
        return data.join(":")
   }
    const pageTitle = propOr("Qualcomm", "title", activeNode)
    const sessionId = yield select(GET_SESSION_GUID)
    const sessionIdObject = sessionId ? { session_id: sessionId } : {}
    const themeValue = yield select(GET_CURRENT_THEME_SETTING)
    const isDdp = yield select(IS_DDP_TEMPLATE)
    const contentTags = !isNil(activeNode?.contentTags) ? {content_tag: activeNode?.contentTags.join(":")} : {content_tag: null}
    return {
      event: {
        country_code: "us",
        device_orientation: orientation,
        language_code: "en",
        page_name: last(values(sectionsObject)) || `${pageTitle}${activeNode?.hash || ""}`,
        page_title: pageTitle,
        page_type: pageType,
        promotion:!isNil(activeNode?.promotion) ? addCMSFiledsData(activeNode?.promotion) : null,
        ...sessionIdObject,
        document_url: documentUrl,
        impersonating: user?.impersonating || false,
        impersonator: user?.impersonator,
        one_trust_status: oneTrustStatus,
        org_id: user?.organization?.id,
        org_name: user?.organization?.name,
        page_last_modified_date: activeNode?.pageLastModifiedDate,
        page_publish_date: activeNode?.pagePublishDate,
        qcguid: user?.qcGuid,
        session_status: loggedIn ? "logged_in" : "not_logged_in",
        stakeholder: !isNil(activeNode?.stakeHolder) ? addCMSFiledsData(activeNode?.stakeHolder) : null,
        technology_topic: !isNil(activeNode?.technologyTopic) ? addCMSFiledsData(activeNode?.technologyTopic) : null,
        theme: isDdp ? themeValue : "NA",
        ...sectionsObject,
        ...contentTags,
      },
      type: "view",
    }
  }
  return null
}

export function* trackMenuSlider({ analytics }) {
  try {
    const { action, eventType, ev, label } = analytics
    const node = yield select(GET_ACTIVE_NODE)
    const detail = propOr("Qualcomm", "title", node)
    const activeMegaMenuItems = yield select(GET_ACTIVE_ITEMS)
    const menuItems = yield select(GET_MENU_ITEMS)
    const activeItems = yield map(
      (item) => menuItems[item],
      activeMegaMenuItems,
    )
    const topMenu = activeItems?.[0]?.title || ""
    return {
      event: {
        action,
        detail,
        ev,
        event_type: eventType,
        header_menu: "navigation",
        label,
        top_menu: topMenu,
      },
      type: "link",
    }
  } catch (error) {
    
    return null
  }
}

export function* trackMegaMenuEvent(
  { action = "click", detail = "", id = [], location = "", track = true, value } = {},
  prevState,
) {
  if (!track) {
    return null
  }
  try {
    const activeMegaMenuItems = yield select(GET_ACTIVE_ITEMS)
    const menuItems = yield select(GET_MENU_ITEMS)
    const topMenu = pathOr("", [head(activeMegaMenuItems), "title"], menuItems)
    const prevNav = pathOrNull(["ui", "mainMenu", "activeItems", 0], prevState)
   
    const label = createMegaMenuSection(value) || prevState.uiReducer.activeItems[0]

    const ev = "megamenu"
    // const label = menuItem ? menuItem.path : "";
    const actionFinal = isEmptyOrUndefined(activeMegaMenuItems) ? "close" : "open"
    const isNavClose = ["search", "cart", "profile"].filter(
      (item) => item === prevNav,
    )
    const headerMenuClose = isEmptyOrUndefined(isNavClose)
      ? "navigation"
      : prevNav
    const isNavOpen = ["search", "cart", "profile"].filter(
      (item) => item === id[0],
    )
    const headerMenuOpen = isEmptyOrUndefined(isNavOpen) ? "navigation" : id[0]
    const locationFallBack = label !== "rootItems" ? `/${label}` : null
    

    return {
      event: {
        action: action == "open-close" ? actionFinal : action,
        detail: detail ? detail : label,
        ev,
        header_menu: action === "open" ? headerMenuOpen : headerMenuClose,
        label,
        location: location ? location : locationFallBack,
        top_menu: topMenu,
      },
      type: "link",
    }
  } catch (error) {
    
    return null
  }
}

export function* trackBlocker(action, prevState) {
  return yield trackMegaMenuEvent(action, prevState)
}

export function* trackLogin() {
  return yield {
    event: {
      action: "click",
      ev: "megamenu",
      label: "account",
    },
    type: "link",
  }
}

export function* trackUserLogin({ type }) {
  try {
    const eventType = cond([
      [equals(USER_LOGIN_REQUEST), always("login attempted")],
      [equals(USER_LOGIN_FAILURE), always("login failed")],
      [equals(USER_LOGGED_IN), always("login successful")],
      [T, always("")],
    ])(type)

    const ev = "megamenu"
    const { pathname: label } = window.location
    const action = "Sign In"
    const node = yield select(GET_ACTIVE_NODE)
    const detail = propOr("Qualcomm", "title", node)
    const activeMegaMenuItems = yield select(GET_ACTIVE_ITEMS)
    const menuItems = yield select(GET_MENU_ITEMS)
    const topMenu = pathOr("", [head(activeMegaMenuItems), "title"], menuItems)
    const headerMenu = "profile"

    return {
      event: {
        action,
        detail,
        ev,
        event_type: eventType,
        header_menu: headerMenu,
        label,
        top_menu: topMenu,
      },
      type: "link",
    }
  } catch (error) {
    
    return null
  }
}

export function* trackScrollPosition() {
  try {
    const scrollPercentage = yield select(GET_SCROLL_CHECKPOINT)
    const node = yield select(GET_ACTIVE_NODE)
    const activeNode = propOr("Qualcomm", "title", node)
    return {
      event: {
        action: scrollPercentage,
        ev: "scroll_track",
        label: "page",
        pageName: activeNode,
      },
      type: "link",
    }
  } catch (error) {
    
    return null
  }
}

export function* trackSearchResults(parameters) {
  try {
    const node = yield select(GET_ACTIVE_NODE)
    const detail = propOr("Qualcomm", "title", node)
    const searchOrigin = yield select(GET_SEARCH_ORIGIN)
    return {
      event: {
        action: parameters?.eventLabel,
        detail,
        ev: parameters?.eventLabel,
        label: `${parameters?.eventLabel} ${
          parameters?.searchResultsCount === 0 ? "no results" : "results"
        }`,
        request_id: parameters?.requestGuid,
        search_filter: join(":", parameters?.searchFilters || []),
        search_origination: searchOrigin,
        search_results: parameters?.searchResultsCount,
        search_term: parameters?.searchQuery,
        session_id: parameters?.sessionGuid,
      },
      type: "link",
    }
  } catch (error) {
    
    return null
  }
}

export function* trackSearchResultClick({ parameters }) {
  try {
    const gqlParameters = omit(
      [
        "link",
        "searchFilters",
        "searchQuery",
        "searchResultsCount",
        "title",
        "url",
      ],
      parameters,
    )
    const client = createClient()
    // global search graphql analytics
    const queryFn = async () =>
      query({
        client,
        query: SEND_GLOBAL_SEARCH_CLICK_EVENT,
        variables: {
          input: {
            ...gqlParameters,
            sourceApplication: getInterceptorSourceApplication(),
            sourceUrl: window.location.href,
          },
        },
      })

    call(queryFn)
  } catch (error) {
    console.error(error)
  }
  const model = yield select(GET_MODEL_DATA)
  const detail = propOr("Qualcomm", "title", model)
  return {
    event: {
      action: "click",
      detail: parameters?.title || detail,
      ev: "search",
      label: "search result click",
      location: parameters?.link || parameters?.url,
      request_id: parameters?.requestGuid,
      resource_id: parameters?.resourceId,
      search_filter: join(":", parameters?.searchFilters),
      search_position: parameters?.searchPosition?.toString(),
      search_results: parameters?.searchResultsCount,
      search_term: parameters?.searchQuery,
      session_id: parameters?.sessionGuid,
    },
    type: "link",
  }
}

export function trackWebformSubmit({ triggeringElement, url }) {
  try {
    return {
      event: {
        action: "sign up now",
        detail: triggeringElement,
        ev: "contact us",
        label: url,
        location: "content",
      },
      type: "link",
    }
  } catch (error) {
    
    return null
  }
}

export function trackMarketoForm({ formData }) {
  try {
    const { ev, formAction, formID, formDetail, formLocation, type } = formData
    const event = {
      ev,
      formAction,
      formID,
      formDetail,
    }
    if (formLocation && formLocation.length > 0)
      event["formLocation"] = formLocation;
    return {
      event,
      type,
    }
  } catch (error) {

    return null
  }
}
