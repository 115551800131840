import React from "react"
import {string} from "prop-types";



const propTypes = {
    unique: string,
}
const RightArrow = ({unique = "", ...props}) => (
    <svg {...props} width="24" height="20" viewBox="0 0 24 20" fill="inherit" xmlns="http://www.w3.org/2000/svg">
        <g fill={'inherit'}>
        <path d="M5.25 10H15.75M15.75 10L10.5 4.75M15.75 10L10.5 15.25" stroke="inherit" stroke-opacity="1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
    </svg>


)


RightArrow.propTypes = propTypes
export default RightArrow
