import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const CheckCircle = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/check_circle-check-circle${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`grey600-check-circle${unique}`}
        xlinkHref={`#path-1-check-circle${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-check-circle${unique}`}>
        <path
          d="M12,2 C17.52,2 22,6.48 22,12 C22,17.52 17.52,22 12,22 C6.48,22 2,17.52 2,12 C2,6.48 6.48,2 12,2 Z M9.94216556,14.1977358 L6.9226205,11.1525253 C6.72818642,10.9564385 6.41160677,10.9550988 6.21552005,11.1495329 L5.49885242,11.8601603 C5.30535225,12.0520297 5.30112295,12.3635113 5.48934233,12.5605636 L9.40713257,16.6622118 C9.40977142,16.6649745 9.41244184,16.6677068 9.41514332,16.6704083 C9.61040546,16.8656705 9.92698795,16.8656705 10.1222501,16.6704083 L18.2857282,8.50693024 C18.4809903,8.31166809 18.4809903,7.9950856 18.2857282,7.79982346 L17.5828597,7.09695498 C17.5814554,7.09555064 17.5800427,7.09415468 17.5786217,7.09276718 C17.3810467,6.89984562 17.0644867,6.90361797 16.8715651,7.10119298 L9.94216556,14.1977358 Z"
          fill="inherit"
          id={`path-1-check-circle${unique}`}
        />
      </g>
    </g>
  </svg>
)

CheckCircle.propTypes = propTypes
export default CheckCircle
