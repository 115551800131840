import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Modem = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/products/modem-modem${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-modem${unique}`}
        xlinkHref={`#path-1-modem${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-modem${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M4.5,4 C4.22385763,4 4,4.22385763 4,4.5 L4,19.5 C4,19.7761424 4.22385763,20 4.5,20 L19.5,20 C19.7761424,20 20,19.7761424 20,19.5 L20,4.5 C20,4.22385763 19.7761424,4 19.5,4 L4.5,4 Z M5.5,5 L18.5,5 C18.7761424,5 19,5.22385763 19,5.5 L19,18.5 C19,18.7761424 18.7761424,19 18.5,19 L5.5,19 C5.22385763,19 5,18.7761424 5,18.5 L5,5.5 C5,5.22385763 5.22385763,5 5.5,5 Z M16,7 C15.7238576,7 15.5,7.22385763 15.5,7.5 L15.5,16.5 C15.5,16.7761424 15.7238576,17 16,17 L17,17 C17.2761424,17 17.5,16.7761424 17.5,16.5 L17.5,7.5 C17.5,7.22385763 17.2761424,7 17,7 L16,7 Z M13,9 C12.7238576,9 12.5,9.22385763 12.5,9.5 L12.5,16.5 C12.5,16.7761424 12.7238576,17 13,17 L14,17 C14.2761424,17 14.5,16.7761424 14.5,16.5 L14.5,9.5 C14.5,9.22385763 14.2761424,9 14,9 L13,9 Z M10,12 C9.72385763,12 9.5,12.2238576 9.5,12.5 L9.5,16.5 C9.5,16.7761424 9.72385763,17 10,17 L11,17 C11.2761424,17 11.5,16.7761424 11.5,16.5 L11.5,12.5 C11.5,12.2238576 11.2761424,12 11,12 L10,12 Z M7,14 C6.72385763,14 6.5,14.2238576 6.5,14.5 L6.5,16.5 C6.5,16.7761424 6.72385763,17 7,17 L8,17 C8.27614237,17 8.5,16.7761424 8.5,16.5 L8.5,14.5 C8.5,14.2238576 8.27614237,14 8,14 L7,14 Z"
          fill="inherit"
          id={`path-1-modem${unique}`}
        />
      </g>
    </g>
  </svg>
)

Modem.propTypes = propTypes
export default Modem
