import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Pinterest = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/pinterest-pinterest${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-pinterest${unique}`}
        xlinkHref={`#path-1-pinterest${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-pinterest${unique}`}>
        <path
          d="M12,2 C6.47692308,2 2,6.47846154 2,12 C2,16.0938462 4.46153846,19.6123077 7.98615385,21.1584615 C7.95538462,20.46 7.98,19.6215385 8.16,18.8615385 C8.35230769,18.0507692 9.44615385,13.4138462 9.44615385,13.4138462 C9.44615385,13.4138462 9.12769231,12.7738462 9.12769231,11.8323077 C9.12769231,10.3492308 9.98307692,9.24153846 11.0538462,9.24153846 C11.9646154,9.24153846 12.4061538,9.92615385 12.4061538,10.7446154 C12.4061538,11.6584615 11.82,13.0261538 11.52,14.2923077 C11.2692308,15.3538462 12.0538462,16.2184615 13.1,16.2184615 C14.9923077,16.2184615 16.2692308,13.7861538 16.2692308,10.9030769 C16.2692308,8.71076923 14.7938462,7.06923077 12.1076923,7.06923077 C9.07692308,7.06923077 7.18615385,9.33384615 7.18615385,11.8584615 C7.18615385,12.7307692 7.44153846,13.3446154 7.84461538,13.82 C8.03076923,14.0384615 8.05538462,14.1261538 7.98923077,14.3784615 C7.94,14.5615385 7.82923077,15.0046154 7.78461538,15.1784615 C7.71846154,15.4323077 7.51384615,15.5230769 7.28307692,15.4292308 C5.88769231,14.86 5.23692308,13.3292308 5.23692308,11.6092308 C5.23692308,8.76923077 7.63076923,5.36461538 12.3815385,5.36461538 C16.2,5.36461538 18.7123077,8.12615385 18.7123077,11.0907692 C18.7123077,15.0138462 16.5338462,17.9446154 13.3184615,17.9446154 C12.2369231,17.9446154 11.2215385,17.3630769 10.8769231,16.6984615 C10.8769231,16.6984615 10.2969231,19.0030769 10.1707692,19.4476923 C9.96,20.2184615 9.54461538,20.9892308 9.16615385,21.5892308 C10.0646154,21.8538462 11.0138462,22 12,22 C17.5230769,22 22,17.5230769 22,12 C22,6.47846154 17.5230769,2 12,2"
          fill="inherit"
          id={`path-1-pinterest${unique}`}
        />
      </g>
    </g>
  </svg>
)

Pinterest.propTypes = propTypes
export default Pinterest
