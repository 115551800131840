import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const EmailUnsubscribe = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/communications/email_unsubscribe-email-unsubscribe${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <g
        fill="inherit"
        id={`group-email-unsubscribe${unique}`}
        transform="translate(2.000000, 1.000000)"
      >
        <use
          fill="inherit"
          id={`mask-email-unsubscribe${unique}`}
          xlinkHref={`#path-1-email-unsubscribe${unique}`}
        />
        <g
          fill="inherit"
          id={`colors/core/gunmetal-email-unsubscribe${unique}`}
        >
          <g
            fill="inherit"
            id={`rectangle-copy-2-email-unsubscribe${unique}`}
            transform="translate(-2.000000, -1.000000)"
          />
          <path
            d="M17.7071068,5 L19.8284271,2.87867966 C20.0236893,2.68341751 20.0236893,2.36683502 19.8284271,2.17157288 C19.633165,1.97631073 19.3165825,1.97631073 19.1213203,2.17157288 L17,4.29289322 L14.8786797,2.17157288 C14.6834175,1.97631073 14.366835,1.97631073 14.1715729,2.17157288 C13.9763107,2.36683502 13.9763107,2.68341751 14.1715729,2.87867966 L16.2928932,5 L14.1715729,7.12132034 C13.9763107,7.31658249 13.9763107,7.63316498 14.1715729,7.82842712 C14.366835,8.02368927 14.6834175,8.02368927 14.8786797,7.82842712 L17,5.70710678 L19.1213203,7.82842712 C19.3165825,8.02368927 19.633165,8.02368927 19.8284271,7.82842712 C20.0236893,7.63316498 20.0236893,7.31658249 19.8284271,7.12132034 L17.7071068,5 Z M10.2889411,3 C10.1009227,3.63351622 10,4.30479189 10,5 C10,6.83813284 10.705526,8.50895974 11.8610057,9.75690852 L10,11.3799642 L2.79568183,5.0967975 C2.57299924,4.93349694 2.26009807,4.98163558 2.0967975,5.20431817 C1.93349694,5.42700076 1.98163558,5.73990193 2.20431817,5.9032025 L10,12.6200358 L12.5594294,10.4148072 C13.7669944,11.4057491 15.3130379,12 17,12 C18.0741303,12 19.0911297,11.7590798 20,11.3282376 L20,18 C20,18.5522847 19.5522847,19 19,19 L1,19 C0.44771525,19 6.76353751e-17,18.5522847 0,18 L0,4 C-6.76353751e-17,3.44771525 0.44771525,3 1,3 L10.2889411,3 Z M17,0 C19.765,0 22,2.235 22,5 C22,7.765 19.765,10 17,10 C14.235,10 12,7.765 12,5 C12,2.235 14.235,0 17,0 Z"
            fill="inherit"
            id={`path-1-email-unsubscribe${unique}`}
          />
        </g>
      </g>
    </g>
  </svg>
)

EmailUnsubscribe.propTypes = propTypes
export default EmailUnsubscribe
