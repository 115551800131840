import React, {KeyboardEvent, ReactElement, useCallback, useEffect, useRef, useState} from "react"

import {callIfFunc} from "lib/utils/helpers"
import { DropdownType } from "./Dropdown.interace"
import { cn } from "lib/utils/cn-helper"
import { findFirstFocusableElement, findFocusableSiblings } from "./utils"
import { indexOf } from "ramda"

const Dropdown = ({
  label,
  buttonOnClick,
  buttonProps,
  className,
  disabled = false,
  hideDropdown = false,
  id,
  renderButton,
  renderDropdown,
  ...rest
}: DropdownType): ReactElement => {
  const dropWrapperRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  // Callback to toggle dropdown open/closed
  const toggle = useCallback(
    (event, val) => {
      if (val && buttonRef.current) {
        buttonRef.current.focus()
      }
      callIfFunc(buttonOnClick, rest)
    },
    [buttonOnClick, rest],
  )
  const [show, setShow] = useState(false)
  const toggleShowDropdown = useCallback(() => {
    if (!disabled) {
      toggle(null, null)
      setShow(!show)
    }
  }, [show, setShow, toggle])
  /**
   * Handle keyboard navigation
   */
  const keyDownHandler = useCallback(
    (event: KeyboardEvent) => {
      if (disabled || ["Enter", "Escape", "ArrowDown"].includes(event.key)) {
        event.preventDefault()
      }
      if (event.key === "Enter") {
        toggleShowDropdown()
      }
      if (show && event.key === "Escape") {
        toggleShowDropdown()
      }
      if (event.key === "ArrowDown" && dropWrapperRef.current) {
        const firstElement = findFirstFocusableElement(dropWrapperRef.current) as HTMLElement
        if (firstElement) {
          firstElement?.focus()
        }
      }
    },
    [disabled, show, toggleShowDropdown],
  )

  // Callback to return focus
  const returnFocus = useCallback(() => {
    if (show && buttonRef.current) {
      buttonRef.current.focus()
    }
  }, [show])

  const itemOnKeyHandler = useCallback(
    (event) => {
      if (dropWrapperRef.current) {
        const focusableList = findFocusableSiblings(dropWrapperRef.current) as HTMLElement[]
        const targetIndex = indexOf(event.target, focusableList)
        if (event.key === "ArrowDown") {
          if (targetIndex + 1 <= focusableList?.length) {
            focusableList?.[targetIndex + 1]?.focus()
          }
        }
        if (event.key === "ArrowUp") {
          if (targetIndex - 1 >= 0) {
            focusableList?.[targetIndex - 1]?.focus()
          } else {
            returnFocus()
          }
        }
      }
    },
    [dropWrapperRef.current, returnFocus]
  )

  useEffect(
    () => {
      const handleClickOutside = (ev: MouseEvent): void => {
        if (wrapperRef?.current && !wrapperRef?.current?.contains(ev?.target as Node)) {
          setShow(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return (): void => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    },
    []
  )


  return (
    <div
      className={cn([className, "relative inline-block"])}
      ref={wrapperRef}
    >
      <button
        aria-expanded={show}
        aria-haspopup="menu"
        aria-owns={id}
        className="w-full"
        onClick={toggleShowDropdown}
        onKeyDown={keyDownHandler}
        ref={buttonRef}
        {...buttonProps}
      >
        {renderButton({show})}
      </button>
      {!hideDropdown && (
        <div aria-label={label} className="w-full" id={id} ref={dropWrapperRef} role="menu">
          {renderDropdown({
            itemOnKeyHandler,
            show,
            toggleShowDropdown
          })}
        </div>
      )}
    </div>
  )
}

export default Dropdown
