import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const VolumeUp = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/av/volume_up-volume-up${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-volume-up${unique}`}
        xlinkHref={`#path-1-volume-up${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-volume-up${unique}`}>
        <path
          d="M5.1089,8.0464 C5.1659,7.9344 5.2449,7.8324 5.3409,7.7474 L11.3409,2.4974 C11.7569,2.1334 12.3889,2.1754 12.7529,2.5914 C12.9119,2.7744 12.9999,3.0074 12.9999,3.2504 L12.9999,20.8424 C12.9999,21.3944 12.5519,21.8424 11.9999,21.8424 C11.7579,21.8424 11.5239,21.7544 11.3409,21.5944 L5.3409,16.3444 C5.2449,16.2594 5.1659,16.1584 5.1089,16.0464 L3.9999,16.0464 C2.8959,16.0464 1.9999,15.1504 1.9999,14.0464 L1.9999,10.0464 C1.9999,8.9424 2.8959,8.0464 3.9999,8.0464 L5.1089,8.0464 Z M14.9966,7.8179 C14.9966,7.3979 15.4916,7.1549 15.8156,7.4219 C17.1486,8.5219 17.9996,10.1829 17.9996,12.0469 C17.9996,13.9089 17.1486,15.5699 15.8156,16.6699 C15.4916,16.9379 14.9966,16.6949 14.9966,16.2749 L14.9966,7.8179 Z M14.9966,3.2149 C14.9966,2.8689 15.3446,2.6139 15.6666,2.7409 C19.3756,4.2039 21.9996,7.8179 21.9996,12.0469 C21.9996,16.2749 19.3756,19.8889 15.6666,21.3519 C15.3446,21.4789 14.9966,21.2239 14.9966,20.8769 L14.9966,19.7829 C14.9966,19.5869 15.1146,19.4139 15.2926,19.3329 C18.0676,18.0779 19.9996,15.2899 19.9996,12.0469 C19.9996,8.8029 18.0676,6.0149 15.2926,4.7589 C15.1146,4.6779 14.9966,4.5059 14.9966,4.3099 L14.9966,3.2149 Z"
          fill="inherit"
          id={`path-1-volume-up${unique}`}
        />
      </g>
    </g>
  </svg>
)

VolumeUp.propTypes = propTypes
export default VolumeUp
