export const setItem = (key, item) => {
  if (!window) {
    return;
  }
  const value = typeof item === "object" ? JSON.stringify(item) : item;
  window.localStorage.setItem(key, value);
};

export const getItem = (key) => {
  if (!window) {
    return null;
  }
  return window.localStorage.getItem(key);
};

export const clearAll = () => {
  if (!window) {
    return;
  }
  window.localStorage.clear();
};

export const setItemIfNotExists = (key, item) => {
  if (window && !getItem(key)) {
    setItem(key, item);
  }
};
