import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Healthcare = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/applications/healthcare-healthcare${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-healthcare${unique}`}
        xlinkHref={`#path-1-healthcare${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-healthcare${unique}`}>
        <path
          d="M14.5,9.5 L20.5,9.5 C20.7761424,9.5 21,9.72385763 21,10 L21,14 C21,14.2761424 20.7761424,14.5 20.5,14.5 L14.5,14.5 L14.5,20.5 C14.5,20.7761424 14.2761424,21 14,21 L10,21 C9.72385763,21 9.5,20.7761424 9.5,20.5 L9.5,14.5 L3.5,14.5 C3.22385763,14.5 3,14.2761424 3,14 L3,10 C3,9.72385763 3.22385763,9.5 3.5,9.5 L9.5,9.5 L9.5,3.5 C9.5,3.22385763 9.72385763,3 10,3 L14,3 C14.2761424,3 14.5,3.22385763 14.5,3.5 L14.5,9.5 Z"
          fill="inherit"
          id={`path-1-healthcare${unique}`}
        />
      </g>
    </g>
  </svg>
)

Healthcare.propTypes = propTypes
export default Healthcare
