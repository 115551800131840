import React, {useMemo} from "react"
import {
  bool,
  func,
  node as nodeType,
  number,
  object,
  oneOfType,
  shape,
} from "prop-types"
import {useSpring} from "react-spring"
import {easeCubic} from "d3-ease"

import Primitives from "primitives"

const propTypes = {
  children: nodeType,
  config: shape({friction: number, mass: number, tension: number}),
  delay: number,
  ease: func,
  enterDuration: number,
  exitDuration: number,
  fadeInOut: bool,
  horizontal: number,
  innerRef: oneOfType([object, func]),
  show: bool,
  showAnimation: bool,
  vertical: number,
}

const FadeSlideIn = ({
  children,
  config = {friction: 30, mass: 1, tension: 180},
  delay = 0,
  enterDuration,
  exitDuration,
  ease = easeCubic,
  fadeInOut = false,
  innerRef,
  vertical = 0,
  horizontal = 0,
  show = false,
  showAnimation = true,
  ...props
}) => {
  const configVals = useMemo(() => {
    if (enterDuration && exitDuration) {
      return {
        duration: show ? enterDuration : exitDuration,
        ease,
      }
    }
    return config
  }, [config, ease, enterDuration, exitDuration, show])
  const {xy, ...styles} = useSpring({
    config: configVals,
    delay,
    from: {
      opacity: fadeInOut ? 0 : 1,
      xy: [horizontal * 100, vertical * 100],
    },
    to: {
      opacity: show || !fadeInOut ? 1 : 0,
      xy: [show ? 0 : horizontal * 100, show ? 0 : vertical * 100],
    },
  })
  return (
    <Primitives.AnimatedBox
      ref={innerRef}
      style={
        showAnimation
          ? {
            transform: xy.interpolate((x, y) => `translate(${x}%, ${y}%)`),
            ...styles,
          }
          : {
            transform: "none",
          }
      }
      {...props}
    >
      {children}
    </Primitives.AnimatedBox>
  )
}

FadeSlideIn.propTypes = propTypes
FadeSlideIn.displayName = "Animations.FadeSlideIn"

export default FadeSlideIn
