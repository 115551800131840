import {css} from "styled-components"
import {
  colors,
  themeDarken,
  themeTransparentize 
} from "lib/styles/deprecated/system/utils"
import { transitionEase} from "lib/styles/deprecated/utils"

const spacingStyles = {
  large: {
    px: 5,
    py: 2
  },
  medium: {
    px: 4,
    py: 2
  },
  small: {
    px: 2,
    py: 1
  }
}

const uiColors = {
  dark: {
    link: {
      color: "background",
      hover: "background"
    },
    primary: {
      color: "background-selected",
      hover: "button-hover"
    },
    secondary: {
      color: "shadow",
      hover: "shadow"
    },
    "secondary-v2": {
      color: "background-selected",
      hover: "button-hover"
    },
    tertiary: {
      color: "background",
      hover: "background"
    },
    "tertiary-snapdragon-red": {
      color: "snapdragon-red",
      hover: "snapdragon-red",
    },
  },
  light: {
    link: {
      color: "background-selected",
      hover: "button-hover"
    },
    pill: {
      color: "secondary-blue",
      hover: "secondary-blue"
    },
    primary: {
      color: "background-selected",
      hover: "button-hover"
    },
    secondary: {
      color: "background-selected",
      hover: "button-hover"
    },
    "secondary-v2": {
      color: "background-selected",
      hover: "button-hover"
    },
    tertiary: {
      color: "background-selected",
      hover: "button-hover"
    },
    "tertiary-snapdragon-red": {
      color: "snapdragon-red",
      hover: "snapdragon-red",
    },
  }
}

export const getSpacing = (version, size) =>
  version !== "link" ? {...spacingStyles[size]} : {}

export const getUIColors = (ui, version) => uiColors[ui][version]

const common = css`
  border-radius: 50px;
  display: inline-block;
`

export const buttonStyles = ({color, hover, ui, version, ...props}) => {
  if (version === "secondary") {
    return css`
      ${common};
      fill: ${colors("text-light", props)};
      color: ${colors("text-light", props)};
      border: 1px solid transparent;
      background-color: ${ui === "dark"
      ? themeTransparentize(0.8, color, props)
      : colors(color, props)};
      &:hover {
        background-color: ${ui === "dark"
        ? themeTransparentize(0.65, hover, props)
        : themeDarken(0.15, hover, props)};
      }
    `
  }
  if (version === "tertiary" || version === "tertiary-snapdragon-red") {
    return css`
      ${common};
      fill: ${colors(color, props)};
      color: ${colors(color, props)};
      background-color: ${ui === "dark"
      ? themeTransparentize(1, "background", props)
      : "transparent"};
      border: 1px solid ${colors(color, props)};
      &:hover {
        ${ui === "dark"
        ? css`
              background-color: ${themeTransparentize(
          0.8,
          "background",
          props
        )};
            `
        : css`
              background-color: transparent;
              border: 1px solid ${themeDarken(0.15, hover, props)};
              fill: ${themeDarken(0.15, hover, props)};
              color: ${themeDarken(0.15, hover, props)};
            `};
      }
    `
  }
  if (version === "pill") {
    return css`
      ${common} ${transitionEase("0.2s", [
      "background-color",
      "color",
      "border-color",
      "opacity"
    ])};
      fill: ${colors(color, props)};
      color: ${colors(color, props)};
      fill: ${colors(color, props)};
      background-color: ${colors("background", props)};
      border: 1px solid ${colors(color, props)};
      &:hover {
        ${transitionEase("0.25s", [
      "background-color",
      "color",
      "border-color",
      "opacity"
    ])};
        background-color: ${colors(hover, props)};
        fill: ${colors("text-light", props)};
        color: ${colors("text-light", props)};
      }
    `
  }
  if (version === "snapdragon-red") {
    return css`
      ${common};
      fill: ${colors("text-light", props)};
      color: ${colors("text-light", props)};
      border: 1px solid transparent;
      background-color: ${ui === "dark"
      ? themeTransparentize(0.8, "red-2", props)
      : colors("red-2", props)};
      &:hover {
        background-color: ${ui === "dark"
        ? themeTransparentize(0.65, "red-2", props)
        : themeDarken(0.15, "red-2", props)};
      }
    `
  }

  if (version === "snapdragon-red-outline") {
    return css`
      ${common};
      fill: ${colors("red-2", props)};
      color: ${colors("red-2", props)};
      background-color: ${ui === "dark"
      ? themeTransparentize(1, "background", props)
      : "transparent"};
      border: 1px solid ${colors("red-2", props)};
      &:hover {
        ${ui === "dark"
        ? css`
              background-color: ${themeTransparentize(
          0.8,
          "background",
          props
        )};
            `
        : css`
              background-color: transparent;
              border: 1px solid ${themeDarken(0.15, "red-2", props)};
              fill: ${themeDarken(0.15, hover, props)};
              color: ${themeDarken(0.15, hover, props)};
            `};
      }
    `
  }

  return css`
    ${common};
    position: relative;
    fill: ${colors(color, props)};
    color: ${colors(color, props)};
    border: 1px solid ${colors("background", props)};
    background-color: ${colors("background", props)};
    &:hover {
      fill: ${themeDarken(0.15, hover, props)};
      color: ${themeDarken(0.15, hover, props)};
    }
  `
}

export const linkStyles = ({color, hover, ui, ...props}) => css`
  fill: ${colors(color, props)};
  color: ${colors(color, props)};
  &:hover {
    ${ui === "dark"
  ? css`
          opacity: 0.5;
        `
  : css`
          fill: ${themeDarken(0.15, hover, props)};
          color: ${themeDarken(0.15, hover, props)};
        `};
  }
`
