import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Cloud = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/file/cloud-cloud${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-cloud${unique}`}
        xlinkHref={`#path-1-cloud${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-cloud${unique}`}>
        <path
          d="M5.39999989,19.3999996 C2.96994705,19.3999996 1,17.4300526 1,14.9999997 C1,12.5699469 2.96994705,10.5999998 5.39999989,10.5999998 C5.44619388,10.5999998 5.49222161,10.6007117 5.5380727,10.602125 C6.02578023,8.70329692 7.74901122,7.29999992 9.79999978,7.29999992 C10.2158082,7.29999992 10.6181453,7.35767786 10.9994467,7.46546953 C11.427936,5.48432892 13.190626,4 15.2999996,4 C17.7300525,4 19.6999995,5.96994705 19.6999995,8.39999989 C19.6999995,8.85832112 19.6299245,9.30027545 19.4999042,9.71573326 C21.5262738,10.3355605 22.9999995,12.2206766 22.9999995,14.4499997 C22.9999995,16.9979042 21.0749723,19.0961944 18.5999996,19.3697888 L18.5999996,19.3999996 L5.39999989,19.3999996 Z"
          fill="inherit"
          id={`path-1-cloud${unique}`}
        />
      </g>
    </g>
  </svg>
)

Cloud.propTypes = propTypes
export default Cloud
