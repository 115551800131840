import { TransitionHeight } from 'lib/animations'
import React, { useEffect, useState } from 'react'
import { WosArrowDown, WosArrowUp } from "components/glyphs/elements"
import styles from "./MenuItemWithChildren.module.css"

export default function MenuItemWithChildren({ itemPage, onTabClick, titleClassName }) {
  const [showDrop, setShowDrop] = useState(false)
  let { id, title, childPages, urlSearch } = itemPage

  const closeMenu = () => {
    setTimeout(() => setShowDrop(false), 10)
  }

  useEffect(() => {
    if (showDrop) {
      document.addEventListener('mouseup', closeMenu)
    } else {
      document.removeEventListener('mouseup', closeMenu)
    } 

    return () => {
      document.removeEventListener('mouseup', closeMenu)
    }
  }, [showDrop])

  return (
    <div className={styles.dropDown} onClick={() => setShowDrop(!showDrop)}>
      <div className={styles.title}>{title}</div>
      {showDrop 
      ? <WosArrowUp className={styles.dropIcon}/> 
      : <WosArrowDown className={styles.dropIcon}/>}
      {showDrop && <TransitionHeight
        show={showDrop}
        className={styles.desktopTransitionDropdown}
      >
        <div className={styles.dropdownContainer}>
          {childPages.map((childPage) => (
            <a
              id={childPage.id}
              href={childPage.id ? `${urlSearch ? urlSearch : ""}#${id}~${childPage.id}` : childPage.path}
              className={styles.dropdownItem}
              onClick={(event) => {
                event.preventDefault()
                onTabClick({url: childPage.id ? `${urlSearch ? urlSearch : ""}#${id}~${childPage.id}` : childPage.path, title:  childPage.title})
              }}
            >
              {childPage.title}
            </a>
          ))}
        </div>
      </TransitionHeight>}
    </div>
  )
}