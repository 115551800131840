import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const CloudUpload = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/file/cloud_upload-cloud-upload${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-cloud-upload${unique}`}
        xlinkHref={`#path-1-cloud-upload${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-cloud-upload${unique}`}>
        <path
          d="M5.4,19.4 C2.9699471,19.4 1,17.4300529 1,15 C1,12.5699471 2.9699471,10.6 5.4,10.6 C5.44619399,10.6 5.49222172,10.6007119 5.53807281,10.6021252 C6.02578035,8.70329704 7.74901138,7.3 9.8,7.3 C10.2158085,7.3 10.6181455,7.35767795 10.999447,7.46546962 C11.4279362,5.48432896 13.1906263,4 15.3,4 C17.7300529,4 19.7,5.9699471 19.7,8.4 C19.7,8.85832124 19.629925,9.30027559 19.4999047,9.7157334 C21.5262743,10.3355607 23,12.2206768 23,14.45 C23,16.9979045 21.0749728,19.0961948 18.6,19.3697892 L18.6,19.4 L5.4,19.4 Z M13.5212263,13.6825231 L13.5212263,16.65 L10.6196933,16.65 L10.6196933,13.6825231 L8.88138493,13.6825231 C8.80802689,13.6825371 8.74188609,13.6373492 8.71381133,13.5680357 C8.68573657,13.4987222 8.70125837,13.4189372 8.75313717,13.3658933 L11.9422121,10.1043394 C11.9762224,10.069547 12.0223557,10.05 12.0704598,10.05 C12.118564,10.05 12.1646973,10.069547 12.1987076,10.1043394 L15.3877825,13.3658933 C15.4396613,13.4189372 15.4551831,13.4987222 15.4271084,13.5680357 C15.3990336,13.6373492 15.3328928,13.6825371 15.2595347,13.6825231 L13.5212263,13.6825231 Z"
          fill="inherit"
          id={`path-1-cloud-upload${unique}`}
        />
      </g>
    </g>
  </svg>
)

CloudUpload.propTypes = propTypes
export default CloudUpload
