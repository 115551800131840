import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const PhoneMobileSmart = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id="Glyphs/Devices/*Phone_Mobile_Smart"
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-phone-mobile-smart${unique}`}
        xlinkHref={`#path-1-phone-mobile-smart${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-phone-mobile-smart${unique}`}>
        <path
          d="M7,1 L17,1 C17.5522847,1 18,1.44771525 18,2 L18,22 C18,22.5522847 17.5522847,23 17,23 L7,23 C6.44771525,23 6,22.5522847 6,22 L6,2 C6,1.44771525 6.44771525,1 7,1 Z M7,2 L7,20 L17,20 L17,2 L7,2 Z M11.5,22 L12.5,22 C12.7761424,22 13,21.7761424 13,21.5 C13,21.2238576 12.7761424,21 12.5,21 L11.5,21 C11.2238576,21 11,21.2238576 11,21.5 C11,21.7761424 11.2238576,22 11.5,22 Z"
          fill="inherit"
          id={`path-1-phone-mobile-smart${unique}`}
        />
      </g>
    </g>
  </svg>
)

PhoneMobileSmart.propTypes = propTypes
export default PhoneMobileSmart
