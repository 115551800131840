import React from "react";
import { element, func, object, oneOfType, shape, string } from "prop-types";

import H1 from "./h1";
import H2 from "./h2";
import H3 from "./h3";
import H4 from "./h4";
import H5 from "./h5";
import H6 from "./h6";
import Label from "./label";
import P from "./p";
import Span from "./span";

const TagMap = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  label: Label,
  p: P,
  span: Span,
};

const Text = ({ textRef, tag = "span", ...props }) => {
  const Tag = TagMap[tag];
  return Tag ? (
    <Tag ref={textRef} {...props} />
  ) : (
    <Span as={tag} ref={textRef} {...props} />
  );
};

Text.propTypes = {
  tag: oneOfType([element, func, object, string]),
  textRef: oneOfType([func, shape({ current: object })]),
};

Text.displayName = "Primitives.Text";

export default Text;
