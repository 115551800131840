import React from "react";

import Blocker from "components/blocker";
import SearchTipsModal from "./search-tips-modal";

const Globals = () => (
  <>
    <SearchTipsModal />
    <Blocker />
  </>
);

export default Globals;
