import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const PinterestBox = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/pinterest_box-pinterest-box${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-pinterest-box${unique}`}
        xlinkHref={`#path-1-pinterest-box${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-pinterest-box${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M12,5 C8.13384615,5 5,8.13492308 5,12 C5,14.8656923 6.72307692,17.3286154 9.19030769,18.4109231 C9.16876923,17.922 9.186,17.3350769 9.312,16.8030769 C9.44661538,16.2355385 10.2123077,12.9896923 10.2123077,12.9896923 C10.2123077,12.9896923 9.98938462,12.5416923 9.98938462,11.8826154 C9.98938462,10.8444615 10.5881538,10.0690769 11.3376923,10.0690769 C11.9752308,10.0690769 12.2843077,10.5483077 12.2843077,11.1212308 C12.2843077,11.7609231 11.874,12.7183077 11.664,13.6046154 C11.4884615,14.3476923 12.0376923,14.9529231 12.77,14.9529231 C14.0946154,14.9529231 14.9884615,13.2503077 14.9884615,11.2321538 C14.9884615,9.69753846 13.9556923,8.54846154 12.0753846,8.54846154 C9.95384615,8.54846154 8.63030769,10.1336923 8.63030769,11.9009231 C8.63030769,12.5115385 8.80907692,12.9412308 9.09123077,13.274 C9.22153846,13.4269231 9.23876923,13.4883077 9.19246154,13.6649231 C9.158,13.7930769 9.08046154,14.1032308 9.04923077,14.2249231 C9.00292308,14.4026154 8.85969231,14.4661538 8.69815385,14.4004615 C7.72138462,14.002 7.26584615,12.9304615 7.26584615,11.7264615 C7.26584615,9.73846154 8.94153846,7.35523077 12.2670769,7.35523077 C14.94,7.35523077 16.6986154,9.28830769 16.6986154,11.3635385 C16.6986154,14.1096923 15.1736923,16.1612308 12.9229231,16.1612308 C12.1658462,16.1612308 11.4550769,15.7541538 11.2138462,15.2889231 C11.2138462,15.2889231 10.8078462,16.9021538 10.7195385,17.2133846 C10.572,17.7529231 10.2812308,18.2924615 10.0163077,18.7124615 C10.6452308,18.8976923 11.3096923,19 12,19 C15.8661538,19 19,15.8661538 19,12 C19,8.13492308 15.8661538,5 12,5"
          fill="inherit"
          id={`path-1-pinterest-box${unique}`}
        />
      </g>
    </g>
  </svg>
)

PinterestBox.propTypes = propTypes
export default PinterestBox
