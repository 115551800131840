import {
  array,
  arrayOf,
  bool,
  number,
  object,
  objectOf,
  oneOfType,
  shape,
  string,
} from "prop-types";

export const headingLevelPropType = oneOfType([number, string]);

export const nodePropType = objectOf(
  oneOfType([array, bool, string, number, object]),
);

const entitiesShape = shape({
  children: arrayOf(string),
  id: string,
  title: string,
  url: string,
});

export const breadcrumbEntitiesPropType = oneOfType([
  arrayOf(entitiesShape),
  objectOf(entitiesShape),
]);

export const breadcrumbResultsPropType = arrayOf(string);

export const breadcrumbPropType = shape({
  entities: breadcrumbEntitiesPropType,
  results: breadcrumbResultsPropType,
});

export const ctaPropType = shape({
  absolute: bool,
  attributes: shape({
    color: string,
    columns: string,
    outbound: bool,
    positioning: string,
    size: string,
  }),
  displayUrl: string,
  html: bool,
  targetId: string,
  targetType: string,
  title: string,
  url: string,
});
