import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Power = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/technology/power-power${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`fill-1-power${unique}`}
        xlinkHref={`#path-1-power${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-power${unique}`}>
        <path
          d="M9,3.1738 L9,4.2588 C9,4.4548 8.882,4.6278 8.702,4.7088 C5.632,6.0998 3.597,9.3728 4.067,13.0568 C4.527,16.6448 7.475,19.5418 11.069,19.9468 C15.904,20.4928 20,16.7248 20,11.9998 C20,8.7578 18.071,5.9658 15.299,4.7098 C15.119,4.6278 15,4.4548 15,4.2568 L15,3.1748 C15,2.8248 15.352,2.5698 15.676,2.6968 C19.725,4.2978 22.478,8.4658 21.931,13.1938 C21.404,17.7428 17.698,21.4308 13.146,21.9368 C7.109,22.6068 2,17.9008 2,11.9998 C2,7.7748 4.62,4.1618 8.325,2.6968 C8.649,2.5688 9,2.8248 9,3.1738 M11.5,0.9998 L12.5,0.9998 C12.776,0.9998 13,1.2238 13,1.4998 L13,9.4998 C13,9.7768 12.776,9.9998 12.5,9.9998 L11.5,9.9998 C11.224,9.9998 11,9.7768 11,9.4998 L11,1.4998 C11,1.2238 11.224,0.9998 11.5,0.9998"
          fill="inherit"
          id={`path-1-power${unique}`}
        />
      </g>
    </g>
  </svg>
)

Power.propTypes = propTypes
export default Power
