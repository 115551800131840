import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Share = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/share-share${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`fill-1-share${unique}`}
        xlinkHref={`#path-1-share${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-share${unique}`}>
        <path
          d="M16.25822,14.8907015 C15.7291242,15.0332189 15.2819758,15.3120186 14.9158843,15.6674213 L9.86542395,12.7502683 C9.99458034,12.2407686 9.97854713,11.7321597 9.85651661,11.2511634 L14.9158843,8.33490116 C15.2819758,8.68674099 15.7291242,8.96643139 16.25822,9.10983952 C17.9140941,9.55253419 19.6189584,8.56916414 20.0625438,6.91239938 C20.5061292,5.25296241 19.5236499,3.55166097 17.8651037,3.10718483 C16.2083389,2.66270869 14.5034745,3.64429727 14.0607799,5.30551571 C13.9316235,5.78562119 13.9378586,6.26750815 14.0296042,6.72801749 L8.91144811,9.68436279 C8.55960828,9.37171524 8.14185416,9.12854493 7.66174867,8.9984978 C6.00231171,8.5549124 4.30011952,9.53561025 3.85742485,11.1977194 C3.41116725,12.8544842 4.39631877,14.5584578 6.05130206,15.0020432 C7.10147714,15.2817336 8.16323177,14.9904637 8.91768325,14.3206319 L14.0296042,17.2725235 C13.9378586,17.7312514 13.9316235,18.2149198 14.0607799,18.6950253 C14.5034745,20.3562438 16.2083389,21.3378323 17.8651037,20.8933562 C19.5236499,20.4488801 20.5061292,18.7466879 20.0625438,17.0872509 C19.6189584,15.4295954 17.9140941,14.4471161 16.25822,14.8907015"
          fill="inherit"
          id={`path-1-share${unique}`}
        />
      </g>
    </g>
  </svg>
)

Share.propTypes = propTypes
export default Share
