import React, { forwardRef } from "react";

import Primitives from "primitives";

const Background = forwardRef(({ ...props }, ref) => (
  <Primitives.Flex
    backgroundPosition="center center"
    backgroundRepeat="no-repeat"
    backgroundSize="cover"
    ref={ref}
    {...props}
  />
));

Background.displayName = "Modules.Background";

export default Background;
