import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const FacebookBox = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/facebook_box-facebook-box${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`fill-1-facebook-box${unique}`}
        xlinkHref={`#path-1-facebook-box${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-facebook-box${unique}`}>
        <path
          d="M15.5982,21 L15.5982,14.7027 L17.8302,14.7027 L18.3,12.0018 L15.5982,12.0018 L15.5982,10.2009 C15.5982,9.7014 15.8655,9.3009 16.1976,9.3009 L18.3,9.3009 L18.3,6.6 L16.1976,6.6 C14.3814,6.6 12.8982,8.2182 12.8982,10.2009 L12.8982,12.0018 L10.7976,12.0018 L10.7976,14.7027 L12.8982,14.7027 L12.8982,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L15.5982,21 Z"
          fill="inherit"
          id={`path-1-facebook-box${unique}`}
        />
      </g>
    </g>
  </svg>
)

FacebookBox.propTypes = propTypes
export default FacebookBox
