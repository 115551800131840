import { execute } from "@apollo/client";
import { curry, pathOr } from "ramda";

import { getApolloLink } from "./client";

const graphqlAction = {
  mutation: "MUTATION",
  query: "QUERY",
};

const getResponse = ({ client = null, operation, action }) => {
  if (!client) {
    return new Promise((resolve) =>
      setTimeout(() => resolve(execute(getApolloLink(), operation))),
    )
      .then((res) => {
        console.debug("getResponse result", { res });
        return res;
      })
      .catch((err) => {
        console.debug("getResponse error", { err });
        return err;
      });
  }
  return action === graphqlAction.query
    ? client.query(operation)
    : client.mutate(operation);
};

const getData = curry((key, response) => pathOr({}, ["data", key], response));

export const query = async ({
  client,
  query: queryOperation,
  key,
  ...options
}) => {
  const res = await getResponse({
    action: graphqlAction.query,
    client,
    operation: {
      query: queryOperation,
      ...options,
    },
  });
  if (key) {
    return getData(key, res);
  }
  return res;
};

export const mutation = async ({
  client,
  mutation: mutationOperation,
  key,
  ...options
}) => {
  const res = await getResponse({
    action: graphqlAction.mutation,
    client,
    operation: {
      mutation: mutationOperation,
      ...options,
    },
  });
  if (key) {
    return getData(key, res);
  }
  return res;
};
