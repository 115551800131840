import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const UnsignedLicenseIcon = ({...props}) => (
  <svg
    height="99"
    viewBox="0 0 99 99"
    width="99"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="#f6f8fa" height="99" rx="6" width="99" />
    <g>
      <polygon
        fill="#fff"
        fillOpacity="0"
        fillRule="evenodd"
        points="26 79 70 79 70 19 26 19 26 79"
      />
      <path
        d="M26.2,22a2.27,2.27,0,0,1,2.27-2.29H67A2.28,2.28,0,0,1,69.28,22V76.6A2.28,2.28,0,0,1,67,78.89H28.47A2.27,2.27,0,0,1,26.2,76.6Z"
        fill="#b7bdc8"
        fillRule="evenodd"
      />
      <path
        d="M31,57.13H63.14a.82.82,0,0,1,.72.91.84.84,0,0,1-.72.87H31a.84.84,0,0,1-.72-.87A.82.82,0,0,1,31,57.13Z"
        fill="#4a5a75"
        fillRule="evenodd"
      />
      <path
        d="M31,53.28H63.14a.74.74,0,0,1,.72.76.79.79,0,0,1-.72.81H31a.79.79,0,0,1-.72-.81A.74.74,0,0,1,31,53.28Z"
        fill="#4a5a75"
        fillRule="evenodd"
      />
      <path
        d="M31,49.16H63.14a.83.83,0,0,1,.72.92.86.86,0,0,1-.72.92H31a.86.86,0,0,1-.72-.92A.83.83,0,0,1,31,49.16Z"
        fill="#4a5a75"
        fillRule="evenodd"
      />
      <path
        d="M30.85,43.47H56.77c.29,0,.57.43.57.91s-.28.91-.57.91H30.85c-.33,0-.57-.43-.57-.91S30.52,43.47,30.85,43.47Z"
        fill="#4a5a75"
        fillRule="evenodd"
      />
      <path
        d="M31,39.6H63.14a.86.86,0,0,1,.72.92.83.83,0,0,1-.72.92H31a.83.83,0,0,1-.72-.92A.86.86,0,0,1,31,39.6Z"
        fill="#4a5a75"
        fillRule="evenodd"
      />
      <path
        d="M31,35.75H63.14a.79.79,0,0,1,.72.81.74.74,0,0,1-.72.76H31a.74.74,0,0,1-.72-.76A.79.79,0,0,1,31,35.75Z"
        fill="#4a5a75"
        fillRule="evenodd"
      />
      <path
        d="M31,31.87H63.14a.8.8,0,0,1,.72.83.75.75,0,0,1-.72.77H31a.75.75,0,0,1-.72-.77A.8.8,0,0,1,31,31.87Z"
        fill="#4a5a75"
        fillRule="evenodd"
      />
    </g>
  </svg>
)

UnsignedLicenseIcon.propTypes = propTypes
export default UnsignedLicenseIcon
