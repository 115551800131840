import React from "react"
import {
  array,
  bool,
  func,
  node,
  number,
  objectOf,
  oneOfType,
  string
} from "prop-types"
import {isNil} from "ramda"

import Primitives from "primitives"
import ButtonContainer from "./ButtonContainer"
import ButtonGlyph from "./ButtonGlyph"

const propTypes = {
  /** A boolean to represent whether the cta right icon should be animated */
  animateGlyph: bool,
  /** Button content, usually a text string, but can be a React component */
  children: node,
  /** A string which represents a color from the color library */
  color: string,
  containerProps: objectOf(oneOfType([array, string, number])),
  /** A boolean to set whether the dark version of the cta is used */
  darkUi: bool,
  /** Cypress test id */
  "data-test-id": string,
  /** A boolean to represent whether the cta should be disabled */
  disabled: bool,
  /**
   * Determines font size of children text/components, implementing the style-system.
   * Defaults to a value of 2 for small ctas and a value of 3 otherwise
   */
  fontSize: oneOfType([array, string, number]),
  /** Array, string, or number for cta font weight */
  fontWeight: oneOfType([array, string, number]),
  /** Glyph node */
  glyphLeft: node,
  /** Glyph node */
  glyphRight: node,
  /** A number used to override icon size */
  iconSize: number,
  justifyContent: string,
  /** Array, string, or number for cta text letter-spacing */
  letterSpacing: oneOfType([array, string, number]),
  /** Array, string, or number for cta text line-height */
  lineHeight: oneOfType([array, string, number]),
  /** Margin bottom, implements style-system */
  mb: oneOfType([array, string, number]),
  /** Margin right, implements style-system */
  mr: oneOfType([array, string, number]),
  /** A function that will be called when the button is clicked */
  onClick: func,
  /** A function that will be called on mouse down */
  onMouseDown: func,
  /** Cta size, determines which size styling to apply */
  size: string,
  /**
   * Determines what to render for the CtaContainer.
   * Defaults to an anchor tag if a url prop is present and a <button> tag otherwise
   */
  tag: oneOfType([node, func]),
  /** Target for cta links, used for opening a new tab or window */
  target: string,
  /** Url provided to CtaContainer for links */
  url: string,
  /** Cta version, determines which version styles to apply */
  version: string,
  /** Determines the width of a Cta using the style-system, defaults to "auto" */
  width: oneOfType([array, string, number])
}

const defaultProps = {
  animateGlyph: false,
  darkUi: false,
  fontWeight: 2,
  lineHeight: 0,
  mb: 1,
  size: "large",
  version: "secondary"
}

/**
 * The CTA component is the generic button component used throughout the
 * application. It takes a function to execute on click or a url to make
 * it a link. Styles are dictated by size and version and ui. Glyph is same
 * height as text. Defaults to `<button />` tag element unless url is
 * present then uses `<a />`. Extra props are passed through to root
 * element attributes.
 */
const Cta = ({
  animateGlyph,
  children,
  color,
  containerProps,
  darkUi,
  "data-test-id": dataTestId = "cta",
  disabled,
  fontSize: fSize,
  fontWeight,
  iconSize,
  justifyContent = "center",
  lineHeight,
  letterSpacing,
  glyphRight,
  glyphLeft,
  mb,
  onClick,
  onMouseDown,
  size,
  url,
  version,
  width = "auto",
  tag,
  target,
  ...props
}) => {
  const defaultSize = size === "small" && version !== "link" ? 2 : 3
  const fontSize = !isNil(fSize) ? fSize : defaultSize
  const defaultIconSize = version === "link" ? 16 : 24
  const tagDefault = url ? "a" : "button"
  return (
    <ButtonContainer
      color={color}
      containerProps={containerProps}
      data-test-id={dataTestId}
      disabled={disabled}
      forwardedAs={tag || tagDefault}
      handleClick={disabled ? null : onClick}
      handleMouseDown={disabled ? null : onMouseDown}
      justifyContent={justifyContent}
      mb={mb}
      size={size}
      target={target}
      ui={darkUi ? "dark" : "light"}
      url={url}
      version={version}
      width={width}
      {...props}
    >
      <Primitives.Flex
        alignItems="center"
        as="span"
        justifyContent="center"
        lineHeight={0}
      >
        {glyphLeft && (
          <ButtonGlyph
            animateGlyph={false}
            height={iconSize || defaultIconSize}
            mr={iconSize < 16 ? "4px" : 1}
            version={version}
            width={iconSize || defaultIconSize}
          >
            {glyphLeft}
          </ButtonGlyph>
        )}
        {children && (
          <Primitives.Text
            color="inherit"
            fontSize={fontSize}
            fontWeight={fontWeight}
            letterSpacing={letterSpacing}
            lineHeight={lineHeight}
            tag="p"
          >
            {children}
          </Primitives.Text>
        )}
        {glyphRight && (
          <ButtonGlyph
            animateGlyph={!disabled && (version === "link" || animateGlyph)}
            height={iconSize || defaultIconSize}
            ml={iconSize < 16 ? "4px" : 1}
            version={version}
            width={iconSize || defaultIconSize}
          >
            {glyphRight}
          </ButtonGlyph>
        )}
      </Primitives.Flex>
    </ButtonContainer>
  )
}

Cta.propTypes = propTypes
Cta.defaultProps = defaultProps
Cta.displayName = "Modules.Cta"

export default Cta
