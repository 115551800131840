import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const BaiduBox = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/baidu_box-baidu-box${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-baidu-box${unique}`}
        xlinkHref={`#path-1-baidu-box${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-baidu-box${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M16.7654444,14.8044722 C16.7654444,14.8044722 15.0442222,13.4731111 14.0395278,12.03325 C12.6758889,9.91030556 10.74,10.775 10.0926944,11.853 C9.44752778,12.9337222 8.44477778,13.6160278 8.30127778,13.79725 C8.15622222,13.9747778 6.22208333,15.0199167 6.65122222,16.928 C7.08133333,18.8347222 8.58963889,18.7989444 8.58963889,18.7989444 C8.58963889,18.7989444 9.70127778,18.90725 10.9904444,18.6192778 C12.2821389,18.3316944 13.3937778,18.6900556 13.3937778,18.6900556 C13.3937778,18.6900556 16.4072778,19.7005833 17.23075,17.7561389 C18.0555833,15.8134444 16.7654444,14.8044722 16.7654444,14.8044722 Z M7.65475,12.3943333 C9.16169444,12.0705833 8.95616667,10.2676944 8.91183333,9.87375 C8.83755556,9.26611111 8.12355556,8.20502778 7.15425,8.28980556 C5.93411111,8.3985 5.756,10.1609444 5.756,10.1609444 C5.59072222,10.9772222 6.15033333,12.7188611 7.65475,12.3943333 Z M10.4531944,9.37208333 C11.2850278,9.37208333 11.9574167,8.41347222 11.9574167,7.22930556 C11.9574167,6.04513889 11.2850278,5.08788889 10.4531944,5.08788889 C9.62097222,5.08788889 8.94683333,6.04513889 8.94683333,7.22930556 C8.94683333,8.41347222 9.62097222,9.37208333 10.4531944,9.37208333 Z M14.0395278,9.51305556 C15.1527222,9.65888889 15.8665278,8.47122222 16.00925,7.57113889 C16.1550833,6.67222222 15.4356389,5.62669444 14.6491111,5.44702778 C13.8588889,5.26541667 12.8728611,6.53027778 12.784,7.35511111 C12.6758889,8.36447222 12.9275,9.37052778 14.0395278,9.51305556 Z M18.4493333,11.02525 C18.4493333,10.59475 18.0929167,9.29722222 16.7654444,9.29722222 C15.4356389,9.29722222 15.2594722,10.5218333 15.2594722,11.3871111 C15.2594722,12.2131111 15.3285,13.3648056 16.9797222,13.3290278 C18.6301667,13.2922778 18.4493333,11.4588611 18.4493333,11.02525 Z"
          fill="inherit"
          id={`path-1-baidu-box${unique}`}
        />
      </g>
    </g>
  </svg>
)

BaiduBox.propTypes = propTypes
export default BaiduBox
