import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Rewind = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/av/rewind-rewind${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <g fill="inherit" id={`colors/core/gunmetal-rewind${unique}`}>
        <path
          d="M2.81257668,3.1904804 L10.8627844,11.6784328 C11.0460997,11.8717162 11.0456881,12.1747817 10.8618486,12.3675664 L2.81164081,20.8094779 C2.62107007,21.0093214 2.30457683,21.0168384 2.10473338,20.8262676 C2.00578408,20.7319096 1.94979223,20.6011466 1.94979223,20.4644191 L1.94979223,3.53455516 C1.94979223,3.25841278 2.17364985,3.03455516 2.44979223,3.03455516 C2.58699152,3.03455516 2.71816306,3.09093286 2.81257668,3.1904804 Z M13.8125767,3.1904804 L21.8627844,11.6784328 C22.0460997,11.8717162 22.0456881,12.1747817 21.8618486,12.3675664 L13.8116408,20.8094779 C13.6210701,21.0093214 13.3045768,21.0168384 13.1047334,20.8262676 C13.0057841,20.7319096 12.9497922,20.6011466 12.9497922,20.4644191 L12.9497922,3.53455516 C12.9497922,3.25841278 13.1736499,3.03455516 13.4497922,3.03455516 C13.5869915,3.03455516 13.7181631,3.09093286 13.8125767,3.1904804 Z"
          fill="inherit"
          id={`path-1-rewind${unique}`}
          transform="translate(11.974896, 11.999488) scale(-1, 1) translate(-11.974896, -11.999488) "
        />
      </g>
    </g>
  </svg>
)

Rewind.propTypes = propTypes
export default Rewind
