import { createStateSelector } from "lib/redux/utils"

const makeSelector = createStateSelector("userReducer")

export const GET_LOGGING_IN = makeSelector("loggingIn")
export const GET_USER = makeSelector("userInfo")

export const GET_USER_ACTIVE_AGREEMENTS = makeSelector(
  ["userInfo", "activeAgreements"],
  [],
)

export const GET_USER_CAN_ACCESS_SALES_CENTER = makeSelector(
  ["userInfo", "canAccessSalesCenter"],
  false,
)

export const GET_SIGNED_AGREEMENTS = makeSelector("signedAgreements", {})

export const GET_UNSIGNED_AGREEMENTS = makeSelector("unsignedAgreements", {})

export const GET_USER_STATUS = makeSelector(["userInfo", "userStatus"], "")

export const GET_USER_FIRST_NAME = makeSelector(["userInfo", "firstName"], "")

export const GET_SESSION_GUID = makeSelector("sessionGuid", "")

export const IS_USER_INFO_LOADING_COMPLETED = makeSelector("isUserInfoLoadingCompleted")
