import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Chipset = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/products/chipset-chipset${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-chipset${unique}`}
        xlinkHref={`#path-1-chipset${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-chipset${unique}`}>
        <path
          d="M6,15 L6,16 L4,16 C3.44771525,16 3,15.5522847 3,15 L3,4 C3,3.44771525 3.44771525,3 4,3 L15,3 C15.5522847,3 16,3.44771525 16,4 L16,6 L15,6 L15,4.5 C15,4.22385763 14.7761424,4 14.5,4 L4.5,4 C4.22385763,4 4,4.22385763 4,4.5 L4,14.5 C4,14.7761424 4.22385763,15 4.5,15 L6,15 Z M9,8 L20,8 C20.5522847,8 21,8.44771525 21,9 L21,20 C21,20.5522847 20.5522847,21 20,21 L9,21 C8.44771525,21 8,20.5522847 8,20 L8,9 C8,8.44771525 8.44771525,8 9,8 Z M11,12 C11.5522847,12 12,11.5522847 12,11 C12,10.4477153 11.5522847,10 11,10 C10.4477153,10 10,10.4477153 10,11 C10,11.5522847 10.4477153,12 11,12 Z M11,15.5 C11.5522847,15.5 12,15.0522847 12,14.5 C12,13.9477153 11.5522847,13.5 11,13.5 C10.4477153,13.5 10,13.9477153 10,14.5 C10,15.0522847 10.4477153,15.5 11,15.5 Z M11,19 C11.5522847,19 12,18.5522847 12,18 C12,17.4477153 11.5522847,17 11,17 C10.4477153,17 10,17.4477153 10,18 C10,18.5522847 10.4477153,19 11,19 Z M18,12 C18.5522847,12 19,11.5522847 19,11 C19,10.4477153 18.5522847,10 18,10 C17.4477153,10 17,10.4477153 17,11 C17,11.5522847 17.4477153,12 18,12 Z M18,15.5 C18.5522847,15.5 19,15.0522847 19,14.5 C19,13.9477153 18.5522847,13.5 18,13.5 C17.4477153,13.5 17,13.9477153 17,14.5 C17,15.0522847 17.4477153,15.5 18,15.5 Z M18,19 C18.5522847,19 19,18.5522847 19,18 C19,17.4477153 18.5522847,17 18,17 C17.4477153,17 17,17.4477153 17,18 C17,18.5522847 17.4477153,19 18,19 Z M14.5,19 C15.0522847,19 15.5,18.5522847 15.5,18 C15.5,17.4477153 15.0522847,17 14.5,17 C13.9477153,17 13.5,17.4477153 13.5,18 C13.5,18.5522847 13.9477153,19 14.5,19 Z M14.5,12 C15.0522847,12 15.5,11.5522847 15.5,11 C15.5,10.4477153 15.0522847,10 14.5,10 C13.9477153,10 13.5,10.4477153 13.5,11 C13.5,11.5522847 13.9477153,12 14.5,12 Z"
          fill="inherit"
          id={`path-1-chipset${unique}`}
        />
      </g>
    </g>
  </svg>
)

Chipset.propTypes = propTypes
export default Chipset
