import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Loop = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/av/loop-loop${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`grey600-loop${unique}`}
        xlinkHref={`#path-1-loop${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-loop${unique}`}>
        <path
          d="M11.8394,5.8011 C8.1824,5.8011 5.2094,8.5801 5.2094,12.0001 C5.2094,12.9111 5.4204,13.7821 5.8054,14.5601 C5.9034,14.7611 5.8704,14.9981 5.7074,15.1511 L4.8164,15.9851 C4.5854,16.2001 4.2034,16.1571 4.0464,15.8841 C3.3824,14.7291 2.9994,13.4071 2.9994,12.0001 C2.9994,7.4331 6.9554,3.7351 11.8394,3.7351 L11.8394,1.2581 C11.8394,1.1541 11.9064,1.0601 12.0104,1.0201 C12.1134,0.9801 12.2324,1.0021 12.3114,1.0751 L16.0644,4.5851 C16.1154,4.6331 16.1454,4.6981 16.1454,4.7681 C16.1454,4.8361 16.1154,4.9021 16.0644,4.9501 L12.3114,8.4591 C12.2324,8.5331 12.1134,8.5551 12.0104,8.5151 C11.9064,8.4761 11.8394,8.3811 11.8394,8.2771 L11.8394,5.8011 Z M17.9694,8.8521 L18.8634,8.0151 C19.0944,7.7991 19.4754,7.8421 19.6324,8.1151 C20.2964,9.2711 20.6794,10.5931 20.6794,12.0001 C20.6794,16.5671 16.7234,20.2661 11.8394,20.2661 L11.8394,22.7411 C11.8394,22.8461 11.7724,22.9411 11.6694,22.9811 C11.5664,23.0201 11.4464,22.9981 11.3674,22.9241 L7.6144,19.4151 C7.5634,19.3661 7.5344,19.3011 7.5344,19.2331 C7.5344,19.1641 7.5634,19.0981 7.6144,19.0501 L11.3674,15.5401 C11.4464,15.4671 11.5664,15.4451 11.6694,15.4851 C11.7724,15.5251 11.8394,15.6181 11.8394,15.7231 L11.8394,18.1991 C15.4964,18.1991 18.4694,15.4201 18.4694,12.0001 C18.4694,11.0871 18.2494,10.2221 17.8684,9.4391 C17.7724,9.2391 17.8074,9.0031 17.9694,8.8521 Z"
          fill="inherit"
          id={`path-1-loop${unique}`}
        />
      </g>
    </g>
  </svg>
)

Loop.propTypes = propTypes
export default Loop
