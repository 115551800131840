import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const MoreSites = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/more_sites-more-sites${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-more-sites${unique}`}
        xlinkHref={`#path-1-more-sites${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-more-sites${unique}`}>
        <path
          d="M19,8.5 L6,8.5 L6,17.5 L5.5,17.5 C5.22385763,17.5 5,17.2761424 5,17 L5,7 C5,6.72385763 5.22385763,6.5 5.5,6.5 L18.5,6.5 C18.7761424,6.5 19,6.72385763 19,7 L19,8.5 Z M17,5.5 L4,5.5 L4,14.5 L3.5,14.5 C3.22385763,14.5 3,14.2761424 3,14 L3,4 C3,3.72385763 3.22385763,3.5 3.5,3.5 L16.5,3.5 C16.7761424,3.5 17,3.72385763 17,4 L17,5.5 Z M7.5,9.5 L20.5,9.5 C20.7761424,9.5 21,9.72385763 21,10 L21,20 C21,20.2761424 20.7761424,20.5 20.5,20.5 L7.5,20.5 C7.22385763,20.5 7,20.2761424 7,20 L7,10 C7,9.72385763 7.22385763,9.5 7.5,9.5 Z M8,12.5 L8,19.5 L20,19.5 L20,12.5 L8,12.5 Z"
          fill="inherit"
          id={`path-1-more-sites${unique}`}
        />
      </g>
    </g>
  </svg>
)

MoreSites.propTypes = propTypes
export default MoreSites
