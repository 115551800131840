import React, { useEffect } from "react";
import { createGlobalStyle } from "styled-components";
/**
 * I did a version of this component without react-spring that uses state and CSS for the same opacity animation, will put that in a separate PR
 */
import { useSpring } from "react-spring";
import { bool, func } from "prop-types";

import Primitives from "primitives";

/**
 * My personal preference is to put all global styles into one createGlobalStylesheet (i.e. existing global-styles.js)
 * But I guess it doesn't hurt to have it here and does make sense to have it co-located to the component where it's relevant!
 * I've always done dynamic global styling this way too but can the logic and style modifications to body be done in a more "React" way?
 * Is it possible to get a ref to body and control its styles with state (Redux?)? Maybe look into that sometime...
 */
const GlobalStyle = createGlobalStyle`
  body {
    &.no-scroll {
      overflow-y: scroll;
      position: fixed;
      width: 100vw;
    }
  }
`;

const propTypes = {
  /** Filter flag check */
  filtersActive: bool,
  /** Handle Click */
  onClick: func,
};

const Blocker = ({ filtersActive, onClick }) => {
  useEffect(() => {
    const scrollTop =
      document.querySelector("body").scrollTop ||
      document.documentElement.scrollTop ||
      0;
    document.body.style.top = `-${scrollTop}px`;
    document.body.classList.add("no-scroll");

    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.top = "";
      window.scrollTo(0, scrollTop);
    };
  });

  const props = useSpring({
    config: { duration: 250, friction: 26, mass: 1, tension: 170 },
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <Primitives.Box
      height="100vh"
      left={0}
      onClick={onClick}
      position="fixed"
      top={0}
      width="100vw"
      zIndex={filtersActive ? 10 : 2}
    >
      <GlobalStyle />
      <Primitives.AnimatedBox style={props}>
        <Primitives.Box
          backgroundColor="appBlocker"
          height={1}
          left={0}
          opacity="0.2"
          position="absolute"
          top={0}
          width={1}
        />
      </Primitives.AnimatedBox>
    </Primitives.Box>
  );
};

Blocker.propTypes = propTypes;

export default Blocker;
