import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const VolumeOff = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/av/volume_off-volume-off${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-volume-off${unique}`}
        xlinkHref={`#path-1-volume-off${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-volume-off${unique}`}>
        <path
          d="M5.1089,8.0464 C5.1659,7.9344 5.2449,7.8324 5.3409,7.7474 L11.3409,2.4974 C11.7569,2.1334 12.3889,2.1754 12.7529,2.5914 C12.9119,2.7744 12.9999,3.0074 12.9999,3.2504 L12.9999,20.8424 C12.9999,21.3944 12.5519,21.8424 11.9999,21.8424 C11.7579,21.8424 11.5239,21.7544 11.3409,21.5944 L5.3409,16.3444 C5.2449,16.2594 5.1659,16.1584 5.1089,16.0464 L3.9999,16.0464 C2.8959,16.0464 1.9999,15.1504 1.9999,14.0464 L1.9999,10.0464 C1.9999,8.9424 2.8959,8.0464 3.9999,8.0464 L5.1089,8.0464 Z"
          fill="inherit"
          id={`path-1-volume-off${unique}`}
        />
      </g>
    </g>
  </svg>
)

VolumeOff.propTypes = propTypes
export default VolumeOff
