import {gql} from "@apollo/client"

export const FACET_VALUE_RESPONSE_FRAGMENT = gql`
  fragment searchInterceptorFacetValues on FacetValue {
    count
    id
    label
    parentId
    value
  }
`

export const INTERCEPTOR_FACET_RESPONSE_FRAGMENT = gql`
  fragment searchInterceptorFacets on FacetField {
    count
    facetId
    label
    order
    values {
      ...searchInterceptorFacetValues
    }
  }
  ${FACET_VALUE_RESPONSE_FRAGMENT}
`
