import {createSelector} from "reselect"
import {difference, equals} from "ramda"

import {createStateSelector} from "lib/redux/utils"
import {GET_IS_MOBILE} from "../globals/globals-selectors"
import {getCookie} from "lib/cookies"

const makeSelector = createStateSelector("uiReducer")

const salesQuoteModalCookie = getCookie("sales-quote-modal")

export const GET_ACTIVE_DROPDOWN = makeSelector("activeDropdown", null)
export const GET_ACTIVE_GROWLER = makeSelector("growler", [])
export const GET_ACTIVE_ITEMS = makeSelector("activeItems", [])
export const GET_BREADCRUMB_OPEN = makeSelector("breadcrumbOpen", false)
export const GET_BREADCRUMB_DATA = makeSelector("breadcrumbData", false)
export const GET_BREADCRUMB_HIDE_SHOW = makeSelector("showhidebreadcrumbinpage", false)
export const GET_CURRENT_THEME = makeSelector("currentTheme", "light")
export const GET_CURRENT_THEME_SETTING = makeSelector("currentThemeSetting", "light")
export const GET_SECONDARYNAVSTICKY_STATE = makeSelector("secondarynavstickystate", false)
export const GET_PRODUCTTABS_STICKY_STATE = makeSelector("productstabsticky", false)
export const GET_TABS_STICKY_STATE = makeSelector("tabsticky", false)
export const GET_RFFETABS_STICKY_STATE = makeSelector("rffetabsticky", false)
export const GET_SNAPDRAGONTABS_STICKY_STATE = makeSelector("snapdragontabsticky", false)
export const GET_PRODUCTTABSSTICKYNAVVIEW_STICKY_STATE = makeSelector("producttabsstickyNav", false)
export const GET_BLOG_ARTICLE_TYPE = makeSelector("blogarticletype","")
export const GET_DROPDOWNS = makeSelector("dropdowns", [])
export const GET_SHOW_AGREEMENTS = makeSelector("showAgreements", false)
export const GET_SHOW_DOWNLOAD_MODAL = makeSelector("showDownloadModal", false)
export const GET_DOWNLOAD_MODAL_DOC = makeSelector("downloadModalDoc", {})
export const GET_SALES_QUOTE_MODAL_ACTIVE = makeSelector(
  "isSalesQuoteModalActive",
  false,
)
export const GET_SALES_QUOTE_MODAL_DISABLE = makeSelector(
  "isSalesQuoteModalDisable",
  salesQuoteModalCookie === "disable",
)
export const GET_IMPERSONATION_STATUS = makeSelector("impersonating", false)
export const GET_SHOW_IMPERSONATION = makeSelector("showImpersonation", false)
export const GET_SHOW_LOGIN_PANEL = makeSelector("showLoginPanel", false)
export const GET_SHOWING_BLOCKER = makeSelector("showingBlocker", false)
export const GET_SEARCH_TIPS_MODAL_ACTIVE = makeSelector(
  "isSearchTipsModalActive",
  false,
)
export const GET_SEARCH_MODAL_VALUE=makeSelector("searchModalValue","")
export const GET_SEARCH_TIPS_TITLE=makeSelector("searchTipsTitle","")
export const GET_SEARCH_PLACEHOLDER=makeSelector("searchPlaceholder","")
export const GET_SNAPDRAGON_INSIDER_ACTIVE=makeSelector("isSnapdragonInsider","")
export const GET_PDP_BANNER = makeSelector("pdpBanner", {})
export const GET_PDP_BANNER_HEIGHT = makeSelector("pdpBannerHeight", null)
export const GET_SUBSCRIBE_MODAL_ACTIVE = makeSelector(
  "showSubscribeModal",
  false,
)
export const GET_SUBSCRIBE_MODAL = makeSelector("subscribeModalProps", {})
export const GET_SUBSCRIBE_MODAL_PROCESSING = makeSelector(
  "subscribeProcessing",
  false,
)
export const GET_IS_MEGA_MENU_PANEL_OPEN = createSelector(
  [GET_ACTIVE_ITEMS, GET_IS_MOBILE],
  (items, isMobile) => {
    if (equals(items, ["search"])) {
      return true
    }
    if (!isMobile) {
      return difference(items, ["rootItems"]).length > 0
    }
    return items.length > 0
  },
)

export const GET_NAVIGATION_HIDE_SHOW = makeSelector("showhidenavigationinpage", false)
export const GET_IS_ROOT_ACTIVE = createSelector(
  [GET_ACTIVE_ITEMS],
  equals(["rootItems"]),
)

export const GET_IS_SEARCH_ACTIVE = createSelector(
  [GET_ACTIVE_ITEMS],
  equals(["search"]),
)


export const GET_PREVIOUS_MENU_ACTIVE_ITEM = makeSelector("previousItems", {})
export const GET_WOS_XP_MODAL_ACTIVE = makeSelector(
  "isWosXpModalActive",
  false,
)
export const GET_WOS_XP_PATH = makeSelector(
  "isWosXpPath",
  "",
)