import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const More = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/more-more${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-more${unique}`}
        xlinkHref={`#path-1-more${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-more${unique}`}>
        <g
          fill="inherit"
          id={`rectangle-copy-2-more${unique}`}
          transform="translate(12.000000, 12.000000) rotate(90.000000) translate(-12.000000, -12.000000) "
        />
        <path
          d="M5,14 C3.8954305,14 3,13.1045695 3,12 C3,10.8954305 3.8954305,10 5,10 C6.1045695,10 7,10.8954305 7,12 C7,13.1045695 6.1045695,14 5,14 Z M12,14 C10.8954305,14 10,13.1045695 10,12 C10,10.8954305 10.8954305,10 12,10 C13.1045695,10 14,10.8954305 14,12 C14,13.1045695 13.1045695,14 12,14 Z M19,14 C17.8954305,14 17,13.1045695 17,12 C17,10.8954305 17.8954305,10 19,10 C20.1045695,10 21,10.8954305 21,12 C21,13.1045695 20.1045695,14 19,14 Z"
          fill="inherit"
          id={`path-1-more${unique}`}
        />
      </g>
    </g>
  </svg>
)

More.propTypes = propTypes
export default More
