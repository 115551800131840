import React, { ReactElement } from "react"
import Dropdown from "components/dx/atoms/Dropdown/Dropdown"
import { DropdownButtonType, DropdownContainerType } from "components/dx/atoms/Dropdown/Dropdown.interace"
import { FooterLanguageDropdownType } from "../GlobalFooter.interface"
import Link from "components/linkTs"
import { cn } from "lib/utils/cn-helper"
import { TransitionHeight } from "lib/animations"

export const DropdownContainer = ({children, show}: DropdownContainerType): ReactElement => {
  const dropContainerClasses = cn([
    "absolute",
    "shadow-m",
    "bg-theme-neutral-000",
    "border",
    "border-border",
    "border-theme-neutral-200",
    "w-[165px]",
    "mt-xxs",
    "min-h-[36px]",
    "max-h-[216px]",
    "overflow-hidden",
    "right-0",
    "px-xxs"
  ])
  return (
    <TransitionHeight
      className={dropContainerClasses}
      enterDuration={150}
      exitDuration={150}
      fadeInOut={true}
      role="menu"
      show={show}
      onAnimationClose={null}
      onAnimationOpen={null}
    >
      {children}
    </TransitionHeight>
  )
}

const FooterLanguageDropdown = ({
  disabled = false,
  id,
  languageLabel,
  regions = [],
  selected
}: FooterLanguageDropdownType): ReactElement => {
  return (
    <Dropdown
      className="w-auto"
      disabled={disabled}
      id={id}
      renderButton={
        (): ReactElement<DropdownButtonType> => {
          return (
            <div className="inline-block body-xs standard-ease duration-300 text-theme-neutral-800 hover:text-theme-neutral-600">
              {`${languageLabel}: ${selected}`}
            </div>
          )
        }
      }
      renderDropdown={
        ({itemOnKeyHandler, show}): ReactElement<DropdownContainerType> => {
          return (
            <DropdownContainer show={show}>
              <div  className="py-xxs">
                <h3 className="body-xs text-theme-neutral-800 mb-xxs">Languages</h3>
                <ul className="flex flex-col gap-y-xxs">
                  {regions?.map(({language, linkAction, linkLabel, linkUrl}, i) => {
                    return (
                      <li key={`${linkUrl}-${i}`}>
                        <Link
                          className="body-xs standard-ease duration-300 text-theme-neutral-800 hover:text-theme-neutral-600"
                          href={linkUrl}
                          location={{
                            detail: linkLabel,
                            label: "footer",
                            url: linkUrl,
                            state: {
                              text: "click",
                              type: "footer",
                              loc: linkUrl,
                              document: linkLabel
                            }
                          }}
                          onKeyDown={itemOnKeyHandler}
                          tabIndex={0}
                          target={linkAction}
                        >
                          {`${language} ( ${linkLabel} )`}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </DropdownContainer>
          )
        }
      }
    />
  )
}
export default FooterLanguageDropdown
