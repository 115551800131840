export default {
  entities: {
    components: {
      152305: {
        bottomUnitPadding: "2",
        bundle: "one_column_hdi",
        css: [],
        custom: { headingLevel: "2" },
        entityType: "pod",
        fields: {
          bgGradientColor: ["gray"],
          bgGradientDirection: ["vertical"],
          bgGradientInverted: [false],
          collapsePaddingBottom: [false],
          collapsePaddingTop: [false],
          displayMobile: [false],
          references: [],
          subtitle: [
            [
              {
                type: "html",
                value:
                  "<p>The page you are looking for may have moved, been renamed or is temporarily unavailable.</p>\n",
              },
            ],
          ],
          textColor: ["dark"],
        },
        fontWeight: 2,
        hasHeaderAdjust: true,
        hasSvg: true,
        hasUnitPadding: true,
        htmlId: "error",
        id: "152305",
        parent: 0,
        region: "header",
        svg: [],
        svgDisabled: {
          bottom: {
            color: "red",
            enabled: 0,
            endCapFrom: "",
            endCapTo: "",
            type: "ninetyDegreeFromLeft",
          },
          top: {
            color: "red",
            enabled: 0,
            endCapFrom: "",
            endCapTo: "",
            type: "ninetyDegreeFromLeft",
          },
        },
        title: "Sorry, the page you requested is temporarily unavailable.",
        topUnitPadding: "2",
        type: "one_column_hdi",
        value: "default",
      },
      152306: {
        bottomUnitPadding: "2",
        bundle: "one_column_hdi",
        css: [],
        custom: { headingLevel: "2" },
        entityType: "pod",
        fields: {
          bgGradientColor: ["gray"],
          bgGradientDirection: ["vertical"],
          bgGradientInverted: [false],
          bgImagePosition: ["center_center"],
          collapsePaddingBottom: [false],
          collapsePaddingTop: [false],
          cta: [
            [
              {
                absolute: false,
                attributes: {
                  color: "blue",
                  columns: "1",
                  labelposition: "before",
                  outbound: false,
                  positioning: "inline",
                  size: "large",
                  style: "caret-right",
                },
                displayUrl: "https://www.qualcomm.com/contact/website",
                fragment: null,
                html: true,
                query: null,
                targetId: "31240",
                targetType: "page",
                title: "Report this error",
                url: "/contact/website",
              },
            ],
          ],
          displayMobile: [true],
          references: [],
          textColor: ["dark"],
        },
        fontWeight: 1,
        hasHeaderAdjust: true,
        hasSvg: true,
        hasUnitPadding: true,
        htmlId: "found-a-broken-link",
        id: "152306",
        parent: 0,
        region: "content",
        svg: [],
        svgDisabled: {
          bottom: {
            color: "aqua",
            enabled: 0,
            endCapFrom: "",
            endCapTo: "",
            type: "ninetyDegreeFromLeft",
          },
          top: {
            color: "aqua",
            enabled: 0,
            endCapFrom: "",
            endCapTo: "",
            type: "ninetyDegreeFromLeft",
          },
        },
        title: "Found a broken link?",
        topUnitPadding: "2",
        type: "one_column_hdi",
      },
    },
    node: {
      error: {
        analytics: {
          countryCode: "us",
          languageCode: "en",
          siteBrand: "qualcomm",
          siteName: "qualcomm",
          siteRegion: "north-america",
          siteTier: "region",
          typename: "NodeAnalytics",
        },
        authors: null,
        breadcrumb: null,
        changed: "1526488409",
        comparisonTable: null,
        components: ["152306"],
        coverItems: [],
        covers: null,
        cpProductKitIds: null,
        created: "1356978588",
        display: { shareOverlay: false, typename: "NodeDisplay" },
        entityType: "node",
        error: true,
        fallback: null,
        featured: null,
        fields: {
          promotion: [
            {
              bundle: "promotion",
              entityType: "taxonomy_term",
              fields: { references: [] },
              id: "19516",
              title: "none",
            },
          ],
          references: [],
          stakeholderAnalytics: [
            {
              bundle: "stakeholder_analytics",
              entityType: "taxonomy_term",
              fields: { references: [] },
              id: "19951",
              title: "none",
            },
          ],
          technologyTopic: [
            {
              bundle: "technology_topic",
              entityType: "taxonomy_term",
              fields: { references: [] },
              id: "19471",
              title: "none",
            },
          ],
        },
        full: true,
        headTitle: "Page unavailable",
        header: ["152305"],
        headerTags: [],
        id: "error",
        language: "en",
        locations: null,
        menu: null,
        message: "The page you are looking is temporarily unavailable.",
        nid: "22271",
        page: null,
        path: "/error",
        pdpTabs: null,
        secondaryNav: null,
        section: null,
        specifications: null,
        statusCode: "500",
        tertiaryNav: null,
        time: null,
        title: "Page Unavailable",
        type: "page",
        typename: "Route",
        url: "/error",
      },
    },
  },
  result: "error",
};
