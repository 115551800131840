/*
 * prop-types for react built-ins like elements with refs provided by useRef,
 * functional components, etc...
 */

import {
  bool,
  element,
  func,
  number,
  object,
  objectOf,
  oneOfType,
  shape,
  string,
} from "prop-types";

export const componentRefPropType = shape({ current: object });

export const locationPropType = shape({
  hash: string,
  key: string,
  pathname: string,
  search: string,
});

export const historyPropType = shape({
  block: func,
  createHref: func,
  go: func,
  goBack: func,
  goForward: func,
  length: number,
  listen: func,
  location: locationPropType,
  push: func,
  replace: func,
});

export const matchPropType = shape({
  isExact: bool,
  params: objectOf(string),
  path: string,
  url: string,
});

export const refPropType = shape({
  current: oneOfType([element, func, object]),
});
