import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const EnlargeExpand = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/enlarge_expand-enlarge-expand${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-enlarge-expand${unique}`}
        xlinkHref={`#path-1-enlarge-expand${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-enlarge-expand${unique}`}>
        <path
          d="M6,13.8284271 L6,7 C6,6.44771525 6.44771525,6 7,6 L13.7573593,6 L12.732233,7.02512627 C11.7559223,8.001437 11.7559223,9.58434944 12.732233,10.5606602 L13.4393398,11.267767 C14.4156506,12.2440777 15.998563,12.2440777 16.9748737,11.267767 L18,10.2426407 L18,17 C18,17.5522847 17.5522847,18 17,18 L10.3137085,18 L11.3388348,16.9748737 C12.3151455,15.998563 12.3151455,14.4156506 11.3388348,13.4393398 L10.631728,12.732233 C9.65541725,11.7559223 8.07250481,11.7559223 7.09619408,12.732233 L6,13.8284271 Z M17.5857864,5 L14.5,5 C14.2238576,5 14,4.77614237 14,4.5 L14,3.5 C14,3.22385763 14.2238576,3 14.5,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,9.5 C21,9.77614237 20.7761424,10 20.5,10 L19.5,10 C19.2238576,10 19,9.77614237 19,9.5 L19,6.41421356 L15.5606602,9.85355339 C15.365398,10.0488155 15.0488155,10.0488155 14.8535534,9.85355339 L14.1464466,9.14644661 C13.9511845,8.95118446 13.9511845,8.63460197 14.1464466,8.43933983 L17.5857864,5 Z M6.41421356,19 L9.5,19 C9.77614237,19 10,19.2238576 10,19.5 L10,20.5 C10,20.7761424 9.77614237,21 9.5,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,14.5 C3,14.2238576 3.22385763,14 3.5,14 L4.5,14 C4.77614237,14 5,14.2238576 5,14.5 L5,17.5857864 L8.43933983,14.1464466 C8.63460197,13.9511845 8.95118446,13.9511845 9.14644661,14.1464466 L9.85355339,14.8535534 C10.0488155,15.0488155 10.0488155,15.365398 9.85355339,15.5606602 L6.41421356,19 Z"
          fill="inherit"
          id={`path-1-enlarge-expand${unique}`}
        />
      </g>
    </g>
  </svg>
)

EnlargeExpand.propTypes = propTypes
export default EnlargeExpand
