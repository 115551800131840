import {filter, lensPath, mergeRight, over, propEq} from "ramda"

import {isEmptyOrUndefined} from "lib/utils/helpers"
import {ERROR_FIXTURE_ROUTE} from "lib/utils/error/fixtures"

export const FETCH_ADMIN_MENU = "FETCH_ADMIN_MENU"
export const FETCH_ADMIN_MENU_SUCCESS = "FETCH_ADMIN_MENU_SUCCESS"
export const FETCH_NODE_SUCCESS = "FETCH_NODE_SUCCESS"
export const ROUTE_TRANSITION = "ROUTE_TRANSTION"
export const SET_ACTIVE_NODE = "SET_ACTIVE_NODE"
export const SET_ACTIVE_NODE_ERROR = "SET_ACTIVE_NODE_ERROR"
export const SET_ACTIVE_ROUTE = "SET_ACTIVE_ROUTE"
export const SET_GLOBAL_ERROR_STATE_NODE = "SET_GLOBAL_ERROR_STATE_NODE"
export const SET_PAGE_STATE = "SET_PAGE_STATE"
export const TRANSITION_TO_NODE = "TRANSITION_TO_NODE"

export const getItems = (primary, bool) =>
  isEmptyOrUndefined(primary) ? null : filter(propEq("active", bool), primary)

export const nodeInitialState = {
  activeNodeId: null,
  activeRoute: null,
  admin: {},
  breadcrumb: {
    entities: {},
    results: [],
  },
  components: {},
  metaData: {
    headTags: [],
    headTitle: "",
  },
  nodes: {},
  upstreamHeaders: {},
}

const reducer = {
  [FETCH_ADMIN_MENU_SUCCESS]: (state, {primary, secondary}) => ({
    ...state,
    admin: {
      activeItems: getItems(primary, true),
      inactiveItems: getItems(primary, false),
      secondaryItems: secondary,
    },
  }),
  [FETCH_NODE_SUCCESS]: (state, {payload}) => ({
    ...state,
    components: {...state.components, ...payload.entities.components},
    nodes: {...state.nodes, ...payload.entities.node},
  }),
  [ROUTE_TRANSITION]: (state, {location}) => ({
    ...state,
    activeRoute: location?.path,
  }),
  [SET_ACTIVE_NODE]: (state, {path: pathParam}) => ({
    ...state,
    activeNodeId: pathParam,
  }),
  [SET_ACTIVE_NODE_ERROR]: (state, {path: pathParam, status}) =>
    over(
      lensPath(["nodes", pathParam]),
      mergeRight({
        error: true,
        statusCode: status,
      }),
      state,
    ),
  [SET_ACTIVE_ROUTE]: (state, {location}) => ({
    ...state,
    activeRoute: location?.path,
  }),
  [SET_GLOBAL_ERROR_STATE_NODE]: (state) => ({
    ...state,
    activeNodeId: "error",
    components: {
      ...state.components,
      ...ERROR_FIXTURE_ROUTE.entities.components,
    },
    nodes: {...state.nodes, ...ERROR_FIXTURE_ROUTE.entities.node},
  }),
  [SET_PAGE_STATE]: (state, {value = {}}) => ({
    ...state,
    ...value,
  }),
}

function nodeReducer(state = nodeInitialState, action) {
  const func = reducer[action.type]
  return func ? func(state, action) : state
}

export default nodeReducer
