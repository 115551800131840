import appSaga from "saga"
import initAnalytics from "./init-analytics"
import {
  FETCH_INITIAL_SCROLL_POSITION,
  FETCH_SCROLL_POSITION,
  FETCH_VIEWPORT,
  // SET_API_HEADERS,
  SET_BASE_URL,
} from "lib/redux/reducers/globals/globals-reducer"
import { getOSThemingOption, SET_CURRENT_THEME, SET_LOGIN_STATUS, THEME_CHANGED } from "lib/redux/actions"
import { hasAuthCookie } from "lib/utils/user-auth"

export default (store) => {
  // Start analytics saga
  initAnalytics(store)

  // Init Sagas
  store.runSaga(appSaga)

  // // Rebuild Header info
  // store.dispatch({
  //   type: SET_API_HEADERS,
  //   value: {
  //     host: window.location.origin,
  //     "user-agent": navigator.userAgent,
  //   },
  // });
  store.dispatch({ type: SET_LOGIN_STATUS, value: hasAuthCookie() })
  // Set url on client side to get proper protocol
  store.dispatch({
    type: SET_BASE_URL,
    value: window.location.origin,
  })

  // Get initial viewport size
  store.dispatch({ type: FETCH_VIEWPORT })

  // Get scroll percentage
  store.dispatch({ type: FETCH_INITIAL_SCROLL_POSITION })

  // Resize & Orientation Change Listeners
  window.addEventListener("resize", () =>
    store.dispatch({ type: FETCH_VIEWPORT }),
  )
  window.addEventListener("orientationchange", () =>
    store.dispatch({ type: FETCH_VIEWPORT }),
  )

  // Scroll Listener
  window.addEventListener("scroll", () =>
    store.dispatch({ type: FETCH_SCROLL_POSITION }),
  )
  // trigger when user click on light/dark and read the value from Cookie and then updates redux state
  window.addEventListener("toggleTheme", () => {
    store.dispatch({ type: THEME_CHANGED })
  })

  //read theme on app startup from OS setting and set to Cookie
  const osTheme = getOSThemingOption(true)
  store.dispatch({type: SET_CURRENT_THEME, value: osTheme})

  //listener  on OS color scheme change
  window.matchMedia("(prefers-color-scheme: light)").addEventListener("change",()=>{
    // const theme = ev.matches ? "light" : "dark"
    store.dispatch({type: THEME_CHANGED})
  })
  /**
   * @param {keyCode} param0 - destructured number from keyboard event
   * Add an event listener to window to check for keydown actions
   * If the key pressed is tab, add a class to body that indicates tab navigation is being used, and focus outlines should be displayed
   * When mouse is clicked, remove this class so focus outlines aren't visible
   * See global-styles.js for where this is implemented in styling
   */
  const setUserIsTabbing = ({ keyCode }) => {
    if (keyCode === 9) {
      document.body.classList.add("user-is-tabbing")

      window.removeEventListener("keydown", setUserIsTabbing)
      // eslint-disable-next-line no-use-before-define
      window.addEventListener("mousedown", userIsMousing)
    }
  }

  const userIsMousing = () => {
    document.body.classList.remove("user-is-tabbing")

    window.removeEventListener("mousedown", userIsMousing)
    window.addEventListener("keydown", setUserIsTabbing)
  }

  window.addEventListener("keydown", setUserIsTabbing)
}
