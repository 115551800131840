import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Infotainment = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/applications/automotive/infotainment-infotainment${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-infotainment${unique}`}
        xlinkHref={`#path-1-infotainment${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-infotainment${unique}`}>
        <path
          d="M9.17070571,17 C9.06015422,17.3127789 9,17.6493646 9,18 C9,18.3506354 9.06015422,18.6872211 9.17070571,19 L3.30901699,19 C3.11963097,19 2.94649941,18.8929988 2.8618034,18.7236068 L2.3618034,17.7236068 C2.23830877,17.4766175 2.33842095,17.176281 2.5854102,17.0527864 C2.65483791,17.0180725 2.73139445,17 2.80901699,17 L9.17070571,17 Z M14.8292943,17 L21.190983,17 C21.4671254,17 21.690983,17.2238576 21.690983,17.5 C21.690983,17.5776225 21.6729105,17.6541791 21.6381966,17.7236068 L21.1381966,18.7236068 C21.0535006,18.8929988 20.880369,19 20.690983,19 L14.8292943,19 C14.9398458,18.6872211 15,18.3506354 15,18 C15,17.6493646 14.9398458,17.3127789 14.8292943,17 Z M2.86013717,4.74162229 C5.91328341,4.24720743 8.95990507,4 12.0000021,4 C15.0401144,4 18.0867512,4.24720989 21.1399128,4.74162968 L21.1399055,4.74167462 C21.6850633,4.82995584 22.055435,5.34345968 21.9671538,5.88861755 C21.9651084,5.90124857 21.9628207,5.91383917 21.960292,5.92638239 L20.4545928,13.3952423 C20.2664163,14.3286713 19.4462439,15 18.4940358,15 L5.50596846,15 C4.55376043,15 3.73358795,14.3286713 3.54541151,13.3952423 L2.0397148,5.92638079 C1.93057197,5.38498946 2.28097861,4.85762733 2.82236994,4.74848449 C2.83491395,4.74595566 2.84750534,4.74366784 2.86013717,4.74162229 Z M5.5,6 C5.22385763,6 5,6.22385763 5,6.5 L5,13.5 C5,13.7761424 5.22385763,14 5.5,14 L18.5,14 C18.7761424,14 19,13.7761424 19,13.5 L19,6.5 C19,6.22385763 18.7761424,6 18.5,6 L5.5,6 Z M6.95050433,9 L8.98327343,9 L8.98327343,11 L6.95050433,11 L6.95050433,9 Z M10.9836176,9 L13.0163867,9 L13.0163867,11 L10.9836176,11 L10.9836176,9 Z M15.0006905,9 L17.0334596,9 L17.0334596,11 L15.0006905,11 L15.0006905,9 Z M12,20 C10.8954305,20 10,19.1045695 10,18 C10,16.8954305 10.8954305,16 12,16 C13.1045695,16 14,16.8954305 14,18 C14,19.1045695 13.1045695,20 12,20 Z M12,19 C12.5522847,19 13,18.5522847 13,18 C13,17.4477153 12.5522847,17 12,17 C11.4477153,17 11,17.4477153 11,18 C11,18.5522847 11.4477153,19 12,19 Z"
          fill="inherit"
          id={`path-1-infotainment${unique}`}
        />
      </g>
    </g>
  </svg>
)

Infotainment.propTypes = propTypes
export default Infotainment
