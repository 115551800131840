export const TRACK_GLOBAL_SEARCH_RESULTS = "TRACK_GLOBAL_SEARCH_RESULTS"
export const RESET_GLOBAL_SEARCH_STATE = "RESET_GLOBAL_SEARCH_STATE"
export const SET_GLOBAL_SEARCH_STATUS = "SET_GLOBAL_SEARCH_STATUS"
export const SET_NOT_LOGGEDIN_SESSION_ID = "SET_NOT_LOGGEDIN_SESSION_ID"
export const SEND_GLOBAL_SEARCH_CLICK_EVENT_PENDING = "SEND_GLOBAL_SEARCH_CLICK_EVENT_PENDING"
export const SEND_GLOBAL_SEARCH_CLICK_EVENT_FULFILLED = "SEND_GLOBAL_SEARCH_CLICK_EVENT_FULFILLED"
export const SEND_GLOBAL_SEARCH_CLICK_EVENT_REJECTED = "SEND_GLOBAL_SEARCH_CLICK_EVENT_REJECTED"

const initialState = {
  activeFilters: [],
  globalSearchStatus: {},
  loading: false,
  notLoggedInSessionId: null,
  page: 1,
  query: "",
  records: [],
  resultCount: 0,
  results: {},
  showFilters: false,
  globalSearchClickEventParameters: null
}

const reducer = {
  [RESET_GLOBAL_SEARCH_STATE]: () => initialState,
  [SET_GLOBAL_SEARCH_STATUS]: (state, { globalSearchStatus }) => {
    return {
      ...state,
      globalSearchStatus,
    }
  },
  [SET_NOT_LOGGEDIN_SESSION_ID]: (state, { sessionId }) => ({
    ...state,
    notLoggedInSessionId: sessionId,
  }),
  [SEND_GLOBAL_SEARCH_CLICK_EVENT_PENDING]: (state) => ({
    ...state,
    globalSearchClickEventParameters: null,
  }),
  [SEND_GLOBAL_SEARCH_CLICK_EVENT_FULFILLED]: (state, {parameters}) => ({
    ...state,
    globalSearchClickEventParameters: parameters,
  }),
  [SEND_GLOBAL_SEARCH_CLICK_EVENT_REJECTED]: (state, {parameters}) => ({
    ...state,
    globalSearchClickEventParameters: parameters,
  })
}

function globalSearchReducer(state = initialState, action) {
  const func = reducer[action.type]
  return func ? func(state, action) : state
}

export default globalSearchReducer
