import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isNil } from "ramda";

import { GET_ACTIVE_DROPDOWN, GET_SHOWING_BLOCKER } from "lib/redux/selectors";
import { BLOCKER_CLICK } from "lib/redux/actions";
import Blocker from "./BlockerComponent";

const BlockerContainer = (props) => {
  const active = useSelector(GET_SHOWING_BLOCKER);
  const dropdowns = useSelector(GET_ACTIVE_DROPDOWN);
  const dispatch = useDispatch();
  const blockerClickDispatch = useCallback(
    () => dispatch({ type: BLOCKER_CLICK }),
    [dispatch],
  );

  return active ? (
    <Blocker
      filtersActive={!isNil(dropdowns)}
      onClick={blockerClickDispatch}
      {...props}
    />
  ) : null;
};

export default BlockerContainer;
