import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Youtube = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/youtube-youtube${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-youtube${unique}`}
        xlinkHref={`#path-1-youtube${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-youtube${unique}`}>
        <path
          d="M15.4363132,12.0241669 L9.81083237,9.10987235 L9.81083237,14.9369227 L15.4363132,12.0241669 Z M22.0004,12.0241669 C22.0004,19.1083336 22.0004,19.1083336 11.9988613,19.1083336 C2.0004,19.1083336 2.0004,19.1083336 2.0004,12.0241669 C2.0004,4.94000006 2.0004,4.94000006 11.9988613,4.94000006 C22.0004,4.94000006 22.0004,4.94000006 22.0004,12.0241669 Z"
          fill="inherit"
          id={`path-1-youtube${unique}`}
        />
      </g>
    </g>
  </svg>
)

Youtube.propTypes = propTypes
export default Youtube
