import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Qzone = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/qzone-qzone${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`shape-qzone${unique}`}
        xlinkHref={`#path-1-qzone${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-qzone${unique}`}>
        <path
          d="M21.9785219,9.27497561 C21.9275406,9.12059838 21.7881097,9.01065068 21.6239047,8.9954996 L15.1957517,8.39969359 L12.4044722,2.25326874 C12.340592,2.09991523 12.1890812,2 12.0209861,2 C11.8530957,2 11.7013802,2.10011998 11.6379094,2.25326874 L8.76084206,8.26497182 L2.37527583,8.9954996 C2.20963766,9.01085542 2.06959254,9.12223634 2.01943018,9.27825152 C1.96906307,9.4342667 2.0186112,9.60481873 2.14493845,9.71108104 L6.86368108,14.038762 L5.70237123,20.5780912 C5.66490301,20.7379965 5.72816901,20.9040442 5.86289078,21.000274 C5.93537028,21.0518696 6.02074866,21.0776674 6.10612705,21.0776674 C6.18003975,21.0776674 6.25456669,21.0580119 6.32049436,21.018701 L12.0635729,17.7188366 L17.7210683,21.0614926 C17.8619324,21.1028509 18.0400599,21.0962991 18.1743722,21.0031404 C18.3088892,20.9099818 18.3746122,20.74721 18.3412389,20.5885332 L17.4864313,15.7473534 C17.7122643,15.6425243 18.3760454,15.3499447 18.7251345,14.9484411 C18.1559452,15.1734551 17.9417827,15.2328309 17.3723887,15.3403217 L17.3723887,15.3390932 C13.5717196,16.0692115 7.74019156,15.4498599 7.54384175,15.4402369 L13.9398499,10.7565056 C11.7746786,10.3590968 6.74779579,10.20042 6.43760812,10.190797 C6.39727349,10.1973488 6.39010744,10.1977583 6.42143332,10.1903875 C6.42143332,10.1903875 6.42716616,10.1903875 6.43760812,10.190797 C6.69865715,10.1486197 13.2623509,9.0730977 17.0681386,10.1478007 L10.6266772,14.6644607 C10.6266772,14.6644607 15.5931604,15.1472478 17.3449529,14.9443462 L17.2276344,14.0385572 L21.9019475,9.70657666 C21.9830263,9.59908589 22.0299127,9.42935284 21.9785219,9.27497561 Z"
          fill="inherit"
          id={`path-1-qzone${unique}`}
        />
      </g>
    </g>
  </svg>
)

Qzone.propTypes = propTypes
export default Qzone
