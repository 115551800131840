import {call, put, takeLatest} from "redux-saga/effects"

import apiRequest from "lib/api"
import {FETCH_ADMIN_MENU, FETCH_ADMIN_MENU_SUCCESS} from "lib/redux/actions"

export function* fetchAdminMenu({path}) {
  try {
    const response = yield call(apiRequest, `/menu/local-tasks?path=${path}`)
    yield put({type: FETCH_ADMIN_MENU_SUCCESS, ...response})
  } catch (error) {
    yield console.log(error)
  }
}

export default function* adminSaga() {
  yield takeLatest(FETCH_ADMIN_MENU, fetchAdminMenu)
}
