import React from "react"
import { string } from "prop-types"


const propTypes = {
  unique: string
}

const YoutubeWOS = ({ unique = "", ...props }) => (
  <svg fill={'inherit'} width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
  <path fill={'inherit'}  d="M18.2752 9.98996C18.2752 14.5656 14.5659 18.2748 9.99033 18.2748C5.41471 18.2748 1.70544 14.5656 1.70544 9.98996C1.70544 5.41435 5.41471 1.70508 9.99033 1.70508C14.5659 1.70508 18.2752 5.41435 18.2752 9.98996ZM15.5136 9.98997C15.5136 9.32136 14.971 8.77872 14.3024 8.77872C13.9729 8.77872 13.6822 8.90469 13.469 9.11787C12.6454 8.52679 11.502 8.13919 10.2423 8.09074L10.7946 5.50353L12.5872 5.88144C12.6066 6.33686 12.9845 6.70508 13.4496 6.70508C13.9244 6.70508 14.312 6.31748 14.312 5.84268C14.312 5.36787 13.9244 4.98028 13.4496 4.98028C13.1105 4.98028 12.8198 5.17407 12.6841 5.46477L10.6783 5.03841C10.6202 5.02872 10.562 5.03841 10.5136 5.06748C10.4651 5.09655 10.4361 5.145 10.4167 5.20314L9.80623 8.09074C8.51747 8.1295 7.36437 8.50741 6.53103 9.11787C6.31786 8.91438 6.01747 8.77872 5.6977 8.77872C5.0291 8.77872 4.48646 9.32136 4.48646 9.98997C4.48646 10.4842 4.77716 10.9008 5.20352 11.0946C5.18414 11.2109 5.17445 11.3369 5.17445 11.4628C5.17445 13.3233 7.3353 14.8252 10.0097 14.8252C12.6841 14.8252 14.845 13.3233 14.845 11.4628C14.845 11.3369 14.8353 11.2206 14.8159 11.1043C15.2132 10.9105 15.5136 10.4842 15.5136 9.98997ZM8.09111 9.98996C7.61631 9.98996 7.22871 10.3776 7.22871 10.8524C7.22871 11.3272 7.61631 11.7148 8.09111 11.7148C8.56592 11.7148 8.95351 11.3272 8.95351 10.8524C8.95351 10.3776 8.56592 9.98996 8.09111 9.98996ZM12.0446 13.1295C11.4535 13.7206 10.3295 13.7593 10 13.7593C9.67057 13.7593 8.53685 13.7109 7.95545 13.1295C7.86824 13.0423 7.86824 12.8969 7.95545 12.8097C8.04266 12.7225 8.18801 12.7225 8.27522 12.8097C8.64344 13.1779 9.43801 13.3136 10.0097 13.3136C10.5814 13.3136 11.3663 13.1779 11.7442 12.8097C11.8314 12.7225 11.9768 12.7225 12.064 12.8097C12.1318 12.9066 12.1318 13.0423 12.0446 13.1295ZM11.0272 10.8524C11.0272 11.3272 11.4148 11.7148 11.8896 11.7148C12.3644 11.7148 12.752 11.3272 12.752 10.8524C12.752 10.3776 12.3644 9.98996 11.8896 9.98996C11.4148 9.98996 11.0272 10.3776 11.0272 10.8524Z"
        />
  </svg>  
)

YoutubeWOS.propTypes = propTypes
export default YoutubeWOS
