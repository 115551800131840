export default {
  entities: {
    touts: {
      "8843-0": {
        description: "",
        desktopImage: "",
        headline: "Snapdragon 845 mobile platform",
        iconImage:
          "https://www.qualcomm.com/sites/ember/files/megamenu/snapdragon1_0.svg",
        id: "8843-0",
        linkPath: "/products/snapdragon-845-mobile-platform",
        linkText: "",
        mobileImage: "",
        vertical: 0,
      },
      "8843-1": {
        description: "",
        desktopImage: "",
        headline: "Snapdragon X50 5G modem",
        iconImage:
          "https://www.qualcomm.com/sites/ember/files/megamenu/snapdragon1.svg",
        id: "8843-1",
        linkPath: "/products/snapdragon/modems/5g/x50",
        linkText: "",
        mobileImage: "",
        vertical: 0,
      },
      "8843-2": {
        description: "",
        desktopImage: "",
        headline: "Product Finder",
        iconImage:
          "https://www.qualcomm.com/sites/ember/files/megamenu/chip-1.svg",
        id: "8843-2",
        linkPath: "/products/catalog",
        linkText: "",
        mobileImage: "",
        vertical: 0,
      },
      "8872-0": {
        description: "",
        desktopImage: "",
        headline: "AI is transforming everything.",
        iconImage: "https://www.qualcomm.com/sites/ember/files/megamenu/ai.svg",
        id: "8872-0",
        linkPath: "/invention/artificial-intelligence",
        linkText: "",
        mobileImage: "",
        vertical: 0,
      },
      "8872-1": {
        description: "",
        desktopImage: "",
        headline: "Leading the world to 5G.",
        iconImage: "https://www.qualcomm.com/sites/ember/files/megamenu/5g.svg",
        id: "8872-1",
        linkPath: "/invention/5g",
        linkText: "",
        mobileImage: "",
        vertical: 0,
      },
      "8872-2": {
        description: "",
        desktopImage: "",
        headline: "Snapdragon Mobile Platforms",
        iconImage:
          "https://www.qualcomm.com/sites/ember/files/megamenu/snapdragon1_0_0.svg",
        id: "8872-2",
        linkPath: "/snapdragon",
        linkText: "",
        mobileImage: "",
        vertical: 0,
      },
      "8913-0": {
        description: "",
        desktopImage: "",
        headline: "",
        iconImage: "",
        id: "8913-0",
        linkPath: "",
        linkText: "",
        mobileImage: "",
        vertical: 0,
      },
      "8928-0": {
        description:
          "Before there were smartphones or smart cities, before autonomous cars or 360 virtual reality videos, there was our technology.",
        desktopImage:
          "https://www.qualcomm.com/sites/ember/files/megamenu/welcome-to-invention-age.svg",
        headline: "",
        iconImage:
          "https://www.qualcomm.com/sites/ember/files/megamenu/campaign-mobile-3_0.svg",
        id: "8928-0",
        linkPath: "/weinvent",
        linkText: "Learn More",
        mobileImage:
          "https://www.qualcomm.com/sites/ember/files/megamenu/we_invent_the_tech_mobile.svg",
        vertical: 1,
      },
    },
    toutsGroup: {
      8843: { id: "8843", items: ["8843-0", "8843-1", "8843-2"] },
      8872: { id: "8872", items: ["8872-0", "8872-1", "8872-2"] },
      8913: { id: "8913", items: ["8913-0"] },
      8928: { id: "8928", items: ["8928-0"] },
    },
  },
  result: ["8843", "8872", "8913", "8928"],
};
