import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Memory = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/products/memory-memory${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-memory${unique}`}
        xlinkHref={`#path-1-memory${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-memory${unique}`}>
        <path
          d="M8.16666667,2 L19,2 C19.5522847,2 20,2.44771525 20,3 L20,9 L19,9 L19,13 L20,13 L20,21 C20,21.5522847 19.5522847,22 19,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,11.5020752 L5,11.5020752 L5,10 L4,10 L4,5.25 C4,5.09262135 4.07409708,4.94442719 4.2,4.85 L7.86666667,2.1 C7.95321475,2.03508894 8.05848156,2 8.16666667,2 Z M6.5,15 C6.22385763,15 6,15.2238576 6,15.5 L6,19.5 C6,19.7761424 6.22385763,20 6.5,20 L17.5,20 C17.7761424,20 18,19.7761424 18,19.5 L18,15.5 C18,15.2238576 17.7761424,15 17.5,15 L6.5,15 Z M8.5,3 C8.22385763,3 8,3.22385763 8,3.5 L8,6.5 C8,6.77614237 8.22385763,7 8.5,7 L9.5,7 C9.77614237,7 10,6.77614237 10,6.5 L10,3.5 C10,3.22385763 9.77614237,3 9.5,3 L8.5,3 Z M11.5,3 C11.2238576,3 11,3.22385763 11,3.5 L11,6.5 C11,6.77614237 11.2238576,7 11.5,7 L12.5,7 C12.7761424,7 13,6.77614237 13,6.5 L13,3.5 C13,3.22385763 12.7761424,3 12.5,3 L11.5,3 Z M14.5,3 C14.2238576,3 14,3.22385763 14,3.5 L14,6.5 C14,6.77614237 14.2238576,7 14.5,7 L15.5,7 C15.7761424,7 16,6.77614237 16,6.5 L16,3.5 C16,3.22385763 15.7761424,3 15.5,3 L14.5,3 Z M17.5,3 C17.2238576,3 17,3.22385763 17,3.5 L17,6.5 C17,6.77614237 17.2238576,7 17.5,7 L18.5,7 C18.7761424,7 19,6.77614237 19,6.5 L19,3.5 C19,3.22385763 18.7761424,3 18.5,3 L17.5,3 Z"
          fill="inherit"
          id={`path-1-memory${unique}`}
        />
      </g>
    </g>
  </svg>
)

Memory.propTypes = propTypes
export default Memory
