import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Tv = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/devices/tv-tv${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-tv${unique}`}
        xlinkHref={`#path-1-tv${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-tv${unique}`}>
        <path
          d="M13,17 L13,18 L20.5,18 C20.7761424,18 21,18.2238576 21,18.5 C21,18.7761424 20.7761424,19 20.5,19 L3.5,19 C3.22385763,19 3,18.7761424 3,18.5 C3,18.2238576 3.22385763,18 3.5,18 L11,18 L11,17 L3,17 C2.44771525,17 2,16.5522847 2,16 L2,6 C2,5.44771525 2.44771525,5 3,5 L21,5 C21.5522847,5 22,5.44771525 22,6 L22,16 C22,16.5522847 21.5522847,17 21,17 L13,17 Z M3,6 L3,16 L21,16 L21,6 L3,6 Z"
          fill="inherit"
          id={`path-1-tv${unique}`}
        />
      </g>
    </g>
  </svg>
)

Tv.propTypes = propTypes
export default Tv
