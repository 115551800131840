import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const ZoomIn = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/zoom_in-zoom-in${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`page-1-zoom-in${unique}`}
        xlinkHref={`#path-1-zoom-in${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-zoom-in${unique}`}>
        <path
          d="M14.5,9.5 L16,9.5 C16.2761424,9.5 16.5,9.72385763 16.5,10 L16.5,11 C16.5,11.2761424 16.2761424,11.5 16,11.5 L14.5,11.5 L14.5,13 C14.5,13.2761424 14.2761424,13.5 14,13.5 L13,13.5 C12.7238576,13.5 12.5,13.2761424 12.5,13 L12.5,11.5 L11,11.5 C10.7238576,11.5 10.5,11.2761424 10.5,11 L10.5,10 C10.5,9.72385763 10.7238576,9.5 11,9.5 L12.5,9.5 L12.5,8 C12.5,7.72385763 12.7238576,7.5 13,7.5 L14,7.5 C14.2761424,7.5 14.5,7.72385763 14.5,8 L14.5,9.5 Z M8.92069275,16.4935208 L4.56066017,20.8535534 C4.36539803,21.0488155 4.04881554,21.0488155 3.85355339,20.8535534 L3.14644661,20.1464466 C2.95118446,19.9511845 2.95118446,19.634602 3.14644661,19.4393398 L7.49369365,15.0920928 C6.50732577,13.8214322 5.91679789,12.2295447 5.91679789,10.50015 C5.91679789,6.36415 9.28079789,3.00015 13.4167979,3.00015 C17.5517979,3.00015 20.9167979,6.36415 20.9167979,10.50015 C20.9167979,14.63515 17.5517979,18.00015 13.4167979,18.00015 C11.7296036,18.00015 10.1746074,17.4367127 8.92069275,16.4935208 Z M13.5,16 C16.537,16 19,13.538 19,10.5 C19,7.462 16.537,5 13.5,5 C10.462,5 8,7.462 8,10.5 C8,13.538 10.462,16 13.5,16 Z"
          fill="inherit"
          id={`path-1-zoom-in${unique}`}
        />
      </g>
    </g>
  </svg>
)

ZoomIn.propTypes = propTypes
export default ZoomIn
