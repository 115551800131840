import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const LocationPin = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/places/location_pin-location-pin${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <path
        d="M12.058318,6.48824533 C13.0269464,6.48824533 13.7747471,7.22531059 13.7747471,8.12783937 C13.7747471,9.17076219 13.0269464,9.91284027 12.058318,9.91284027 C11.1499161,9.91284027 10.3920776,9.17076219 10.3920776,8.12783937 C10.3920776,7.22531059 11.1499161,6.48824533 12.058318,6.48824533 Z M12.0566298,4.09041478 C9.78485652,4.09041478 7.98553504,5.9562213 7.98553504,8.12797464 C7.98553504,10.3649352 9.78485652,12.230739 12.0566298,12.230739 C14.2278797,12.230739 16.1076193,10.3649352 16.1076193,8.12797464 C16.1076193,5.9562213 14.2278797,4.09041478 12.0566298,4.09041478 Z M12.0574915,2 C15.4300017,2 18.2194829,4.76814052 18.2194829,8.12802604 C18.2194829,9.39675553 17.8425274,10.5902657 17.1640049,11.6333319 C17.1640049,11.6333319 17.1640049,11.6333319 12.4796843,18.7743364 C12.379161,18.9047216 12.2183249,19 12.0574915,19 C11.8765501,19 11.715714,18.9047216 11.6352959,18.7743364 C11.0673479,17.9368715 9.80077482,16.0412991 6.85045257,11.6333319 C6.25234756,10.6504439 5.88041826,9.4619492 5.80000019,8.19321429 C5.80000019,4.82831867 8.58948226,2 12.0574915,2 Z M4.5,21 L19.5,21 C19.7761424,21 20,21.2238576 20,21.5 C20,21.7761424 19.7761424,22 19.5,22 L4.5,22 C4.22385763,22 4,21.7761424 4,21.5 C4,21.2238576 4.22385763,21 4.5,21 Z"
        fill="inherit"
        id={`fill-3-location-pin${unique}`}
      />
    </g>
  </svg>
)

LocationPin.propTypes = propTypes
export default LocationPin
