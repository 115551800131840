import React from "react"
import {string} from "prop-types"

const propTypes = {
  /** Unique string for svg */
  unique: string,
}
const defaultProps = {
  unique: "",
}

const Search = ({unique, ...props}) => (
  <svg viewBox="-2.5 -2.5 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <path
        d="M13.4167979,16.00015 C10.3787979,16.00015 7.91679789,13.53815 7.91679789,10.50015 C7.91679789,7.46215 10.3787979,5.00015 13.4167979,5.00015 C16.4537979,5.00015 18.9167979,7.46215 18.9167979,10.50015 C18.9167979,13.53815 16.4537979,16.00015 13.4167979,16.00015 M8.92069197,16.4935216 L4.56066017,20.8535534 C4.36539803,21.0488155 4.04881554,21.0488155 3.85355339,20.8535534 L3.14644661,20.1464466 C2.95118446,19.9511845 2.95118446,19.634602 3.14644661,19.4393398 L7.49369325,15.0920932 C6.50732577,13.8214322 5.91679789,12.2295447 5.91679789,10.50015 C5.91679789,6.36415 9.28079789,3.00015 13.4167979,3.00015 C17.5517979,3.00015 20.9167979,6.36415 20.9167979,10.50015 C20.9167979,14.63515 17.5517979,18.00015 13.4167979,18.00015 C11.7296036,18.00015 10.1746074,17.4367127 8.92069275,16.4935208 Z"
        fill="currentColor"
        id={`path-1-search${unique}`}
      />
    </defs>
    <g
      fill="currentColor"
      fillRule="evenodd"
      id="Glyphs/Nav/*Search"
      stroke="none"
      strokeWidth={1}
    >
      <g id="Nav_Anonymous" transform="translate(-1379.000000, -31.000000)">
        <g id="Nav">
          <g
            id="Glyphs/Nav/*Search"
            transform="translate(1376.000000, 28.000000)"
          >
            <use
              fill="currentColor"
              id={`page-1-search${unique}`}
              xlinkHref={`#path-1-search${unique}`}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

Search.propTypes = propTypes
Search.defaultProps = defaultProps
export default Search
