import React, {useMemo, ReactNode, ReactElement, HTMLAttributeAnchorTarget} from "react"
import {contains, replace, toLower} from "ramda"
import ExternalLink from "./external-link"

import { getMeta } from "lib/apolloClient/client"

interface LinkContainerType {
  className?: string;
  children?: ReactNode;
  location?: {
    action?: string;
    detail?: string;
    document?: string;
    label?: string;
    loc?: string;
    type?: string;
    url?: string;
    state?: {
      text?: string;
      type?: string;
      loc?: string;
      document?: string;
    };
  };
  linkHandler?: (event: MouseEvent) => void;
  onClick?: (event: MouseEvent) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  href?: string;
  tabIndex?: number;
  target?: HTMLAttributeAnchorTarget;
  url?: string;

}



interface urlMapType {
    test: string;
    stage: string;
    prod: string;
}
export const urlMap: urlMapType = {
  test: "https://test.www.qualcomm.com",
  stage: "https://stage.www.qualcomm.com",
  prod: "https://www.qualcomm.com",
}

// Util function to determine external vs. internal URLs
export const isExternalLink = contains("://")
export const configurePath = (path = "", url: string): string => {
  if (isExternalLink(path)) {
    return path
  }
  const relativePath = replace("/content/qcomm-martech/us/en/home/", "", path)
  return `${url}${relativePath}`
}

const LinkContainer = ({className, children, href, url, ...props}: LinkContainerType): ReactElement  => {
  const env = toLower(getMeta("Environment") || "prod") as "test" | "stage" | "prod"
  const location = useMemo(
    () => configurePath(href || url, urlMap?.[env]),
    [env, href, url]
  )
  return (
    <ExternalLink className={className} url={location} {...props}>{children}</ExternalLink>
  )
}

export default LinkContainer
