import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const RemoveMinus = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/remove_minus-remove-minus${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-remove-minus${unique}`}
        xlinkHref={`#path-1-remove-minus${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-remove-minus${unique}`}>
        <rect
          fill="inherit"
          height={2}
          id={`path-1-remove-minus${unique}`}
          rx="0.5"
          width={18}
          x={3}
          y={11}
        />
      </g>
    </g>
  </svg>
)

RemoveMinus.propTypes = propTypes
export default RemoveMinus
