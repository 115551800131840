import React, { ReactElement } from "react"
import FooterLogo from "./components/FooterLogo"
import { GlobalFooterType } from "./GlobalFooter.interface"
import FooterLinks from "./components/FooterLinks"
import CtaV2Dx from "../../atoms/CtaV2Dx/CtaV2Dx"
import Link from "components/linkTs"
import FooterThemeLink from "./components/FooterThemeLink"
import FooterLanguageDropdown from "./components/FooterLanguageDropdown"

export const GlobalFooter = ({
  componentID,
  footerDisclaimer,
  homePagePath,
  isDdp = false,
  linksSection = [],
  sublinksSection = [],
  sociallinksSection = [],
  countrylinksSection = [],
  languageLabel,
  quickLinksSectionList = [],
  description
}: GlobalFooterType): ReactElement => {
  return (
    <div className="tw-preflight">
      <div className="bg-[#F5F6F7] dark:bg-[rgba(255, 255, 255, 0.08)] h-[12px] relative"></div>
      <div className="flex flex-col w-full justify-center items-center bg-theme-neutral-000 relative">
        <div className="flex w-full max-w-[1680px] px-s sm:px-l xl:px-[130px] justify-end">
          {isDdp ? (
            <div className="">
              <FooterThemeLink />
            </div>
          ) : null}
        </div>
        <footer className="w-full justify-center items-center max-w-[1680px] px-s sm:px-l xl:px-[130px] pb-xl pt-l" id={componentID?.id} tabIndex={-1}>
          <FooterLogo homePagePath={homePagePath} />

          <div className="grid gap-x-s grid-cols-4 lg:grid-cols-12 pt-m">
            <div className="pb-xxl lg:pb-0 border-t border-theme-neutral-300 pt-m col-span-4">
              <div className="body-m mb-s text-theme-neutral-600">{description}</div>

              <div className="flex flex-row gap-x-xs">
                {sociallinksSection.map(
                  (item) => (
                    <CtaV2Dx
                      key={`social-footer-${item.socialLinkType}`}
                      animation={false}
                      aria-label={item.linkLabel}
                      color="blue-gray"
                      footerCta
                      iconType={item.socialLinkType}
                      size="large-icon"
                      style="outline"
                      target="_self"
                      targetType=""
                      url={item.linkUrl}
                      width="auto"
                    />
                  )
                )}
              </div>
            </div>

            <div className="hidden lg:block col-span-1" />

            <div className="pb-xxl border-t border-theme-neutral-300 pt-m col-span-2 sm:col-span-1 lg:col-span-2">
              <FooterLinks heading="Quick links" id="quickLinks" linkItems={quickLinksSectionList} />
            </div>

            <div className="pb-xxl border-t border-theme-neutral-300 pt-m col-span-2 sm:col-span-1 lg:col-span-2">
              <FooterLinks heading="Company info" id="companyInfo" linkItems={linksSection} />
            </div>

            <div className="pb-xxl border-t border-theme-neutral-300 pt-m col-span-4 sm:col-span-2 lg:col-span-3">
              <h2 className="heading-xxs mb-s text-theme-neutral-800">Stay connected</h2>
              <p className="body-xs mb-s text-theme-neutral-600">Get the latest Qualcomm and industry information delivered to your inbox.</p>
              <CtaV2Dx
                animation={false}
                color="blue-gray"
                ctaTitle="Subscribe"
                ctaTitleClass="rte-heading-xxs"
                footerCta
                iconAlign="left"
                iconType="dx-envelope"
                size="small"
                style="outline"
                target="_self"
                targetType=""
                url="https://assets.qualcomm.com/subscription-center-sign-up.html"
                width="auto"
              />
              <Link
                className="block body-xs mt-s standard-ease duration-300 text-theme-neutral-600 hover:text-theme-neutral-400"
                href="https://myaccount.qualcomm.com/profile"
                location={{
                  detail: "Manage your subscription",
                  label: "footer",
                  url: "https://myaccount.qualcomm.com/profile",
                  state: {
                    text: "click",
                    type: "footer",
                    loc: "https://myaccount.qualcomm.com/profile",
                    document: "Manage your subscription"
                  }
                }}
                target="_blank"
              >
              Manage your subscription
              </Link>
            </div>
          </div>

          <div className="flex flex-col gap-y-[10px] sm:flex-row items-start justify-between border-t border-theme-neutral-300 pt-xs pb-m leading-4">
            <nav aria-label="legal links">
              <ul className="flex flex-row gap-x-xs leading-4">
                {sublinksSection?.map(({linkAction, linkLabel, linkUrl}, i) => {
                  return (
                    <li key={`${linkUrl}-${i}`}>
                      <Link
                        className="inline-block body-xs standard-ease duration-300 text-theme-neutral-800 hover:text-theme-neutral-600"
                        href={linkUrl}
                        location={{
                          detail: linkLabel,
                          label: "footer",
                          url: linkUrl,
                          state: {
                            text: "click",
                            type: "footer",
                            loc: linkUrl,
                            document: linkLabel
                          }
                        }}
                        target={linkAction}
                      >
                        {linkLabel}
                      </Link>
                    </li>
                  )
                })}
                <li><div className="inline-block body-xs ot-sdk-show-settings text-theme-neutral-800 hover:text-theme-neutral-600 cursor-pointer">Cookie Settings</div></li>
              </ul>
            </nav>

            <FooterLanguageDropdown
              id="language-dropdown"
              languageLabel={languageLabel}
              regions={countrylinksSection}
              selected="English (US)"
            />
          </div>

          <div className="body-xs [&>p]:mb-[12px] text-theme-neutral-600" dangerouslySetInnerHTML={{__html: footerDisclaimer}}/>

        </footer>
      </div>
    </div>
  )
}
