import React from "react"
import {string} from "prop-types"

const defaultProps = {
  unique: "",
}

const propTypes = {
  /** Unique string for svg */
  unique: string,
}

function LinkOutSimple({unique, ...props}) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs fill="inherit" />

      <g
        fill="inherit"
        fillRule="evenodd"
        id={`glyphs/nav/link_out-link-out-simple${unique}`}
        stroke="none"
        strokeWidth={1}
      >
        <use
          fill="inherit"
          id={`combined-shape-link-out-simple${unique}`}
          xlinkHref={`#path-1-link-out-simple${unique}`}
        />
        <g fill="inherit" id={`colors/core/gunmetal-link-out-simple${unique}`}>
          <path
            d="M16.5454894,6 L10.2988455,6 C10.0227031,6 9.79884546,5.77614237 9.79884546,5.5 L9.79884546,4.5 C9.79884546,4.22385763 10.0227031,4 10.2988455,4 L18.7988455,4 C19.3511302,4 19.7988455,4.44771525 19.7988455,5 L19.7988455,6 L19.7988455,13.5 C19.7988455,13.7761424 19.5749878,14 19.2988455,14 L18.2988455,14 C18.0227031,14 17.7988455,13.7761424 17.7988455,13.5 L17.7988455,7.25187687 L5.42280075,19.622762 C5.22753008,19.8179535 4.91100894,19.8179249 4.71577353,19.6226981 L4.71569397,19.6227777 L4.17009278,19.0773598 C3.9748263,18.882102 3.97481928,18.5655195 4.1700771,18.370253 C4.17010362,18.3702265 4.17013014,18.3702 4.17015666,18.3701735 L16.5454894,6 Z"
            fill="inherit"
            id={`path-1-link-out-simple${unique}`}
          />
        </g>
      </g>
    </svg>
  )
}

LinkOutSimple.propTypes = propTypes
LinkOutSimple.defaultProps = defaultProps
export default LinkOutSimple
