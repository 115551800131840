import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const TriangleDown = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/triangle_down-triangle-down${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <g fill="inherit" id={`colors/core/gunmetal-triangle-down${unique}`}>
        <path
          d="M12.3951099,6.19361172 L20.8746195,17.1277161 C21.043846,17.3459293 21.0041345,17.6600113 20.7859213,17.8292378 C20.6982499,17.8972279 20.5904552,17.9341279 20.4795096,17.9341279 L3.52049045,17.9341279 C3.24434807,17.9341279 3.02049045,17.7102703 3.02049045,17.4341279 C3.02049045,17.3231823 3.05739044,17.2153876 3.12538055,17.1277161 L11.6048901,6.19361172 C11.7741166,5.97539855 12.0881986,5.93568704 12.3064118,6.10491358 C12.339584,6.13063899 12.3693845,6.16043949 12.3951099,6.19361172 Z"
          fill="inherit"
          id={`path-1-triangle-down${unique}`}
          transform="translate(12.000012, 11.967064) scale(1, -1) translate(-12.000012, -11.967064) "
        />
      </g>
    </g>
  </svg>
)

TriangleDown.propTypes = propTypes
export default TriangleDown
