import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const BlockDiagram = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/media/block_diagram-block-diagram${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-block-diagram${unique}`}
        xlinkHref={`#path-1-block-diagram${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-block-diagram${unique}`}>
        <path
          d="M18.5,16 L20.5,16 C20.7761424,16 21,16.2238576 21,16.5 L21,20.5 C21,20.7761424 20.7761424,21 20.5,21 L15.5,21 C15.2238576,21 15,20.7761424 15,20.5 L15,16.5 C15,16.2238576 15.2238576,16 15.5,16 L17.5,16 L17.5,13 L6.5,13 L6.5,16 L8.5,16 C8.77614237,16 9,16.2238576 9,16.5 L9,20.5 C9,20.7761424 8.77614237,21 8.5,21 L3.5,21 C3.22385763,21 3,20.7761424 3,20.5 L3,16.5 C3,16.2238576 3.22385763,16 3.5,16 L5.5,16 L5.5,12.5 C5.5,12.2238576 5.72385763,12 6,12 L11.5,12 L11.5,8 L9.5,8 C9.22385763,8 9,7.77614237 9,7.5 L9,3.5 C9,3.22385763 9.22385763,3 9.5,3 L14.5,3 C14.7761424,3 15,3.22385763 15,3.5 L15,7.5 C15,7.77614237 14.7761424,8 14.5,8 L12.5,8 L12.5,12 L18,12 C18.2761424,12 18.5,12.2238576 18.5,12.5 L18.5,16 Z"
          fill="inherit"
          id={`path-1-block-diagram${unique}`}
        />
      </g>
    </g>
  </svg>
)

BlockDiagram.propTypes = propTypes
export default BlockDiagram
