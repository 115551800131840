import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Folder = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/file/folder-folder${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-folder${unique}`}
        xlinkHref={`#path-1-folder${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-folder${unique}`}>
        <path
          d="M3,4 L10,4 L12,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,19 C22,19.5522847 21.5522847,20 21,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,5 C2,4.44771525 2.44771525,4 3,4 Z"
          fill="inherit"
          id={`path-1-folder${unique}`}
        />
      </g>
    </g>
  </svg>
)

Folder.propTypes = propTypes
export default Folder
