import React from "react";
import { func } from "prop-types";
import { useSelector } from "react-redux";

import Cta from "components/cta";
import Primitives from "primitives";
import { NavInfo } from "components/glyphs";
import { GET_SEARCH_TIPS_TITLE } from "lib/redux/reducers/ui/ui-selectors";

const iconContainerStyle = {
  cursor: "pointer",
  display: ["flex", "flex", "none"],
};

const ctaContainerStyle = {
  display: ["none", "none", "block"],
};

const SearchTips = ({ openModal }) => {
  const searchTipsTitle = useSelector(GET_SEARCH_TIPS_TITLE);

  return (
    <Primitives.Box>
      <Primitives.Flex {...iconContainerStyle} onClick={openModal}>
        <NavInfo fill="#A4ACBA" height="22px" width="22px" />
      </Primitives.Flex>
      <Primitives.Box {...ctaContainerStyle}>
        <Cta
          data-test-id="search-tips-cta"
          display="inline-block"
          fontSize={1}
          id="search-tips"
          letterSpacing="0.5px"
          lineHeight="1.1"
          mb={0}
          mr={0}
          onClick={openModal}
          version="link"
          whiteSpace="nowrap"
        >
          {searchTipsTitle}
        </Cta>
      </Primitives.Box>
    </Primitives.Box>
  );
};

SearchTips.propTypes = {
  openModal: func,
};
export default SearchTips;
