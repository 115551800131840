import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import SearchTipsComponent from "./SearchTipsComponent";
import { SET_SEARCH_TIPS_MODAL_ACTIVE } from "lib/redux/actions";

const SearchTipsContainer = () => {
  const dispatch = useDispatch();

  const openModal = useCallback(() => {
    dispatch({ type: SET_SEARCH_TIPS_MODAL_ACTIVE, value: true });
  }, [dispatch]);

  return <SearchTipsComponent openModal={openModal} />;
};

export default SearchTipsContainer;
