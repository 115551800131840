import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Platform = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/products/platform-platform${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-platform${unique}`}
        xlinkHref={`#path-1-platform${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-platform${unique}`}>
        <path
          d="M6,14 L6,16 L4,16 C3.44771525,16 3,15.5522847 3,15 L3,4 C3,3.44771525 3.44771525,3 4,3 L15,3 C15.5522847,3 16,3.44771525 16,4 L16,6 L14,6 L14,5.5 C14,5.22385763 13.7761424,5 13.5,5 L5.5,5 C5.22385763,5 5,5.22385763 5,5.5 L5,13.5 C5,13.7761424 5.22385763,14 5.5,14 L6,14 Z M9,8 L20,8 C20.5522847,8 21,8.44771525 21,9 L21,20 C21,20.5522847 20.5522847,21 20,21 L9,21 C8.44771525,21 8,20.5522847 8,20 L8,9 C8,8.44771525 8.44771525,8 9,8 Z M12.9866275,16.3415475 L11.1926896,14.5162442 L12.9866275,12.690941 C13.1644573,12.5100019 13.1644573,12.2166429 12.9866275,12.0357039 C12.8087977,11.8547649 12.5204796,11.8547649 12.3426498,12.0357039 L10.0673846,14.3507501 C10.0242396,14.3946378 10,14.4541694 10,14.5162442 C10,14.5783191 10.0242396,14.6378506 10.0673846,14.6817384 L12.3426498,16.9967846 C12.5204796,17.1777236 12.8087977,17.1777236 12.9866275,16.9967846 C13.1644573,16.8158455 13.1644573,16.5224865 12.9866275,16.3415475 Z M17.7753532,14.5162442 L15.9832373,16.3415475 C15.8055881,16.5224865 15.8055881,16.8158455 15.9832373,16.9967846 C16.1608865,17.1777236 16.4489117,17.1777236 16.626561,16.9967846 L18.8995154,14.6817384 C18.9426166,14.6378506 18.9668315,14.5783191 18.9668315,14.5162442 C18.9668315,14.4541694 18.9426166,14.3946378 18.8995154,14.3507501 L16.626561,12.0357039 C16.4489117,11.8547649 16.1608865,11.8547649 15.9832373,12.0357039 C15.8055881,12.2166429 15.8055881,12.5100019 15.9832373,12.690941 L17.7753532,14.5162442 Z"
          fill="inherit"
          id={`path-1-platform${unique}`}
        />
      </g>
    </g>
  </svg>
)

Platform.propTypes = propTypes
export default Platform
