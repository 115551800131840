import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const TriangleLeft = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/triangle_left-triangle-left${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`triangle-triangle-left${unique}`}
        xlinkHref={`#path-1-triangle-left${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-triangle-left${unique}`}>
        <path
          d="M6.22655951,12.3621621 L17.1606639,20.8416717 C17.3788771,21.0108982 17.692959,20.9711867 17.8621856,20.7529735 C17.9301757,20.6653021 17.9670757,20.5575074 17.9670757,20.4465618 L17.9670757,3.48754266 C17.9670757,3.21140028 17.7432181,2.98754266 17.4670757,2.98754266 C17.35613,2.98754266 17.2483354,3.02444265 17.1606639,3.09243276 L6.22655951,11.5719423 C6.00834634,11.7411689 5.96863483,12.0552508 6.13786137,12.273464 C6.16358677,12.3066362 6.19338728,12.3364367 6.22655951,12.3621621 Z"
          fill="inherit"
          id={`path-1-triangle-left${unique}`}
        />
      </g>
    </g>
  </svg>
)

TriangleLeft.propTypes = propTypes
export default TriangleLeft
