import React, { memo, useMemo } from "react"
import {
  bool,
  func,
  node as nodeType,
  number,
  shape,
  string,
} from "prop-types"
import { a, useSpring } from "react-spring"
import { easeCubic } from "d3-ease"

import { useMeasure } from "../utils"
import Primitives from "primitives"

const propTypes = {
  children: nodeType,
  className: string,
  config: shape({ friction: number, mass: number, tension: number }),
  delay: number,
  ease: func,
  enterDuration: number,
  exitDuration: number,
  fadeInOut: bool,
  show: bool,
  onAnimationClose: () => null,
  onAnimationOpen: () => null,
  showAnimation: bool,
}

const TransitionHeight = ({
  children,
  className,
  config = { friction: 30, mass: 1, tension: 180 },
  delay = 0,
  enterDuration = 0,
  exitDuration = 0,
  ease = easeCubic,
  fadeInOut = false,
  show = false,
  onAnimationClose,
  onAnimationOpen,
  ...props
}) => {
  const [bind, { height: viewHeight }] = useMeasure()
  const configVals = useMemo(() => {
    if (enterDuration && exitDuration) {
      return {
        duration: show ? enterDuration : exitDuration,
        ease,
      }
    }
    return config
  }, [config, ease, enterDuration, exitDuration, show])
  const styles = useSpring(
    {
      config: configVals,
      delay,
      from: { height: 0, opacity: fadeInOut ? 0 : 1 },
      to: {
        height: show ? viewHeight : 0,
        opacity: show || !fadeInOut ? 1 : 0,
      },
      onRest: (e) => {
        if (e.height === 0 && onAnimationClose) onAnimationClose()
        else if (e.height !== 0 && onAnimationOpen) onAnimationOpen()
      },
    },
    [show]
  )
  return (
    <Primitives.AnimatedBox className={className} style={styles} {...props}>
      <a.div {...bind}>{children}</a.div>
    </Primitives.AnimatedBox>
  )
}

TransitionHeight.propTypes = propTypes
TransitionHeight.displayName = "Animations.TransitionHeight"

export default memo(TransitionHeight)
