import { filter, keys, pipe, prop, reduce } from "ramda";

import { hasData } from "lib/utils/helpers";

export const getInterceptorSourceApplication = () =>
  false ? "www-local-dev" : "www";

export const getInterceptorSourceUrl = () =>
  typeof window !== "undefined" ? window.location.href : "";

/**
 *
 * @param activeFiltersWithParent {Object,<string, any>}
 * @returns [{field: string, values: string[]}]
 */
const formatActiveFiltersWithParent = (activeFiltersWithParent) =>
  pipe(
    keys,
    reduce((acc, key) => {
      const arrValue = pipe(
        prop(key),
        filter(hasData),
      )(activeFiltersWithParent);
      return arrValue?.length
        ? [
            ...acc,
            {
              field: key,
              values: arrValue,
            },
          ]
        : acc;
    }, []),
  )(activeFiltersWithParent);

export default formatActiveFiltersWithParent;
