import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Car = ({unique = "", ...props}) => (
  <svg viewBox="0 0 64 37" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      fillRule="evenodd"
      id={`desktop-car${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <g
        id={`1440px-rffe-sub-cat-products-v1-car${unique}`}
        transform="translate(-923.000000, -847.000000)"
      >
        <g
          id={`block-3-car${unique}`}
          transform="translate(431.000000, 777.000000)"
        >
          <g
            id={`flex-block-item-•-block-3-car${unique}`}
            transform="translate(452.000000, 0.000000)"
          >
            <g
              id={`content-car${unique}`}
              transform="translate(32.000000, 48.000000)"
            >
              <g
                id={`group-car${unique}`}
                transform="translate(8.000000, 22.000000)"
              >
                <path
                  d="M18.3094676,0 C18.3094676,0 33.6102067,0 45.7056397,0 C50.7554902,0 58.4058504,11.9473295 58.4058504,11.9473295 C58.4058504,11.9473295 64,14.0091588 64,19.4038158 C64,24.7278638 64,34.9805358 64,34.9805358 C64,36.1103075 63.0777231,37 61.8984163,37 C61.8984163,37 61.8984163,37 55.5029566,37 C54.3236405,37 53.3106549,36.1103075 53.3106549,34.9805358 C53.3106549,34.9805358 53.3106549,36.2091602 53.3106549,32.3538172 C53.2199368,32.4244281 42.8632152,33.3988571 32.0075583,33.3988571 C21.136777,33.3988571 10.6893467,32.3538172 10.6893467,32.3538172 C10.6893467,32.3538172 10.6893467,31.125191 10.6893467,34.9805358 C10.6893467,36.1103075 9.69146674,37 8.5121656,37 C8.5121656,37 8.5121656,37 2.10158246,37 C0.922277575,37 0,36.1103075 0,34.9805358 C0,34.9805358 0,24.7278638 0,19.4038158 C0,14.0091588 5.59414088,11.9473295 5.59414088,11.9473295 C5.59414088,11.9473295 13.244502,0 18.3094676,0 Z"
                  fill="#7BA0FF"
                  id={`fill-6-car${unique}`}
                />
                <path
                  d="M43.8899982,4 C43.8899982,4 43.8899982,4 21.1867098,4 C17.8221359,4 14.078672,9.56685955 12.8965274,11.4709281 C12.6843453,11.8052303 12.8359013,12.2267438 13.2147914,12.3720899 C17.3068417,14 17.3068417,14 17.3068417,14 C17.3068417,14 25.6576502,12.6191899 32.538354,12.6191899 C39.4038966,12.6191899 47.7547145,14 47.7547145,14 C51.8467555,12.3720899 51.8467555,12.3720899 51.8467555,12.3720899 C52.2256549,12.2267438 52.3772109,11.8052303 52.1801806,11.4709281 C50.9980361,9.56685955 47.2545721,4 43.8899982,4 Z"
                  fill="#CCD9FD"
                  id={`fill-7-car${unique}`}
                />
                <path
                  d="M5.33333333,21.8483171 C5.33333333,25.3787451 5.33333333,25.3787451 5.33333333,25.3787451 C5.33333333,25.8297616 5.66001573,26.1874716 6.0906416,26.2185802 C17.2720834,26.9961982 17.2720834,26.9961982 17.2720834,26.9961982 C17.7472523,27.042861 18.1333333,26.6540424 18.1333333,26.1563631 C18.1333333,25.285429 18.1333333,25.285429 18.1333333,25.285429 C18.1333333,24.9121647 17.8957443,24.5700089 17.5542164,24.4766928 C6.37277639,21.0395906 6.37277639,21.0395906 6.37277639,21.0395906 C5.8530544,20.8685127 5.33333333,21.272876 5.33333333,21.8483171 Z"
                  fill="#CCD9FD"
                  id={`fill-8-car${unique}`}
                />
                <path
                  d="M59.7333333,21.8483171 C59.7333333,25.3787451 59.7333333,25.3787451 59.7333333,25.3787451 C59.7333333,25.8297616 59.4066484,26.1874716 58.9908651,26.2185802 C47.794581,26.9961982 47.794581,26.9961982 47.794581,26.9961982 C47.3342577,27.042861 46.9333333,26.6540424 46.9333333,26.1563631 C46.9333333,25.285429 46.9333333,25.285429 46.9333333,25.285429 C46.9333333,24.9121647 47.1709198,24.5700089 47.5124453,24.4766928 C58.7087294,21.0395906 58.7087294,21.0395906 58.7087294,21.0395906 C59.2136019,20.8685127 59.7333333,21.272876 59.7333333,21.8483171 Z"
                  fill="#CCD9FD"
                  id={`fill-9-car${unique}`}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

Car.propTypes = propTypes
export default Car
