import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const CompareArrows = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/compare_arrows-compare-arrows${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-compare-arrows${unique}`}
        xlinkHref={`#path-1-compare-arrows${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-compare-arrows${unique}`}>
        <path
          d="M9,14 L9,11.505469 C9,11.2293266 9.22385763,11.005469 9.5,11.005469 C9.63244181,11.005469 9.75947317,11.0580156 9.85321359,11.1515761 L13.8564464,15.1464466 C14.051896,15.3415211 14.0522001,15.6581034 13.8571256,15.853553 L13.8567861,15.8538929 L13.8564466,15.8535534 L9.85355336,19.8564459 C9.65829119,20.051708 9.34170871,20.051708 9.14644658,19.8564458 C9.05267841,19.7626776 9,19.6355007 9,19.5028925 L9,17 L2.5,17 C2.22385763,17 2,16.7761424 2,16.5 L2,14.5 C2,14.2238576 2.22385763,14 2.5,14 L9,14 Z M15.0032328,6.99453104 L21.5032328,6.99453104 C21.7793751,6.99453104 22.0032328,7.21838866 22.0032328,7.49453104 L22.0032328,9.49453104 C22.0032328,9.77067341 21.7793751,9.99453104 21.5032328,9.99453104 L15.0032328,9.99453104 L15.0032328,12.4974235 C15.0032328,12.6300317 14.9505544,12.7572087 14.8567862,12.8509769 C14.6615241,13.046239 14.3449416,13.046239 14.1496794,12.8509769 L10.1467861,8.8480844 L10.1464466,8.8484239 L10.1461071,8.84808407 C9.95103266,8.65263444 9.95133678,8.33605209 10.1467864,8.14097762 L14.1500192,4.14610714 C14.2437596,4.05254664 14.370791,4 14.5032328,4 C14.7793751,4 15.0032328,4.22385763 15.0032328,4.5 L15.0032328,6.99453104 Z"
          fill="inherit"
          id={`path-1-compare-arrows${unique}`}
        />
      </g>
    </g>
  </svg>
)

CompareArrows.propTypes = propTypes
export default CompareArrows
