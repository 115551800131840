import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string
}

const CaretRight = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/caret_right-caret-right${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <g fill="inherit" id={`colors/core/gunmetal-caret-right${unique}`}>
        <path
          d="M16.7298146,20.1766484 L16.0654196,20.8410434 C15.8701575,21.0363055 15.553575,21.0363055 15.3583128,20.8410434 L7.48285586,12.9655864 C7.16095195,12.6416393 7,12.3176921 7,11.993745 C7,11.6697979 7.16095195,11.3458507 7.48285586,11.0219036 L15.3583128,3.14644661 C15.553575,2.95118446 15.8701575,2.95118446 16.0654196,3.14644661 L16.7295574,3.81109873 C16.9247192,4.00626049 16.9248342,4.32264472 16.7298144,4.51794834 L9.26539568,11.993745 L16.7298144,19.4695417 C16.9248342,19.6648453 16.9247192,19.9812295 16.7295574,20.1763913 Z"
          fill="inherit"
          id={`path-1-caret-right${unique}`}
          transform="translate(11.938002, 11.993745) scale(-1, -1) rotate(-360.000000) translate(-11.938002, -11.993745) "
        />
      </g>
    </g>
  </svg>
)

CaretRight.propTypes = propTypes
export default CaretRight
