import {
  arrayOf,
  bool,
  func,
  number,
  objectOf,
  shape,
  string,
} from "prop-types";

export const resetFiltersPropType = func;

/** active filters from the filter provider */
export const activeFiltersPropType = objectOf(bool);

export const activeFiltersWithParentPropType = objectOf(arrayOf(string));

export const categoryFilter = shape({
  id: string,
  label: string,
  order: number,
  value: string,
});

/** filter groups from the filter provider */
export const filterGroupsPropType = arrayOf(
  shape({
    count: number,
    facetId: string.isRequired,
    label: string.isRequired,
    values: arrayOf(
      shape({
        count: number,
        id: string.isRequired,
        label: string.isRequired,
        parentId: string.isRequired,
        value: string,
      }),
    ),
  }),
);

export const searchQueryPropType = string;

export const mobileFiltersPropType = {
  applyTemporaryFilters: func,
  cancelTemporaryFilters: func,
  isMobileFilterPanelActive: bool,
  showMobileFooter: bool,
  showMobileReset: bool,
  toggleMobileFiltersPanel: func,
};
