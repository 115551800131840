import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const FavoriteBorder = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/shopping/favorite_border-favorite-border${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-favorite-border${unique}`}
        xlinkHref={`#path-1-favorite-border${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-favorite-border${unique}`}>
        <path
          d="M20.9995061,9.07422344 C20.9562338,12.33369 18.0692451,16.5990936 12.3385399,21.8704342 L12.3385683,21.870465 C12.1472021,22.0464915 11.8528653,22.0464863 11.6615053,21.870453 C5.93082045,16.5987585 3.04381701,12.3333742 3.00049499,9.0743003 C3.00018068,9.04957548 3,9.02480884 3,9 C3,6.23857625 5.23857625,4 8,4 C9.63559896,4 11.0877712,4.7853431 12,5.99951255 C12.9122288,4.7853431 14.364401,4 16,4 C18.7614237,4 21,6.23857625 21,9 C21,9.02478313 20.9998197,9.04952415 20.9994603,9.07422181 L20.9995061,9.07422344 Z M18.9996824,9.04767398 C18.9999451,9.02260287 18.9999451,9.02260287 19,9 C19,7.34314575 17.6568542,6 16,6 C15.0427987,6 14.1639538,6.44889558 13.5989869,7.2008621 L12.3997467,8.79704152 C12.233875,9.01781555 11.9204366,9.06232263 11.6996626,8.89645087 C11.6620098,8.86816156 11.6285426,8.83469437 11.6002533,8.79704152 L10.4010131,7.2008621 C9.83604619,6.44889558 8.95720134,6 8,6 C6.34314575,6 5,7.34314575 5,9 C5.00005525,9.02324195 5.00005525,9.02324195 5.00031832,9.04771717 C5.03192866,11.4257331 7.32809507,14.9706269 12.000047,19.445695 C16.6719902,14.970917 18.9681075,11.4260374 18.9996824,9.04767398 Z"
          fill="inherit"
          id={`path-1-favorite-border${unique}`}
        />
      </g>
    </g>
  </svg>
)

FavoriteBorder.propTypes = propTypes
export default FavoriteBorder
