export default {
  apiKey: "db798485cda98fcb9fdc0394abf5761d133b85e0",
  countries: "",
  locales: ["de", "en-gb", "fr", "zh-cn"],
  strings: [
    "ab",
    "bs",
    "c",
    "full",
    "ias",
    "intro",
    "off",
    "on",
    "rl",
    "rm",
    "title"
  ]
}
