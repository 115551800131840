import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const SkipPrevious = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/av/skip_previous-skip-previous${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-skip-previous${unique}`}
        xlinkHref={`#path-1-skip-previous${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-skip-previous${unique}`}>
        <path
          d="M19.1563822,3.14221623 C19.249229,3.05437942 19.3721882,3.00543348 19.5,3.00543348 C19.7761424,3.00543348 20,3.22929111 20,3.50543348 L20,20.4946005 C20,20.6219432 19.9514118,20.7444875 19.8641488,20.8372309 C19.6749192,21.0383448 19.3584834,21.0479788 19.1573695,20.8587493 L10.1538499,12.3872669 C10.1467901,12.3806243 10.1399249,12.3737777 10.1332632,12.3667359 C9.94348826,12.1661366 9.95226321,11.8496757 10.1528626,11.6599008 L19.1563822,3.14221623 Z M4.5,3 L6.5,3 C6.77614237,3 7,3.22385763 7,3.5 L7,20.5 C7,20.7761424 6.77614237,21 6.5,21 L4.5,21 C4.22385763,21 4,20.7761424 4,20.5 L4,3.5 C4,3.22385763 4.22385763,3 4.5,3 Z"
          fill="inherit"
          id={`path-1-skip-previous${unique}`}
        />
      </g>
    </g>
  </svg>
)

SkipPrevious.propTypes = propTypes
export default SkipPrevious
