import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Extension = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/technology/extension-extension${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-extension${unique}`}
        xlinkHref={`#path-1-extension${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-extension${unique}`}>
        <path
          d="M9,20 L5,20 C4.44771525,20 4,19.5522847 4,19 L4,15 C5.38071187,15 6.5,13.8807119 6.5,12.5 C6.5,11.1192881 5.38071187,10 4,10 L4,6 C4,5.44771525 4.44771525,5 5,5 L9,5 C9,3.61928813 10.1192881,2.5 11.5,2.5 C12.8807119,2.5 14,3.61928813 14,5 L18,5 C18.5522847,5 19,5.44771525 19,6 L19,10 C20.3807119,10 21.5,11.1192881 21.5,12.5 C21.5,13.8807119 20.3807119,15 19,15 L19,19 C19,19.5522847 18.5522847,20 18,20 L14,20 C14,18.6192881 12.8807119,17.5 11.5,17.5 C10.1192881,17.5 9,18.6192881 9,20 Z"
          fill="inherit"
          id={`path-1-extension${unique}`}
        />
      </g>
    </g>
  </svg>
)

Extension.propTypes = propTypes
export default Extension
