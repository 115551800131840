import { anyPass, equals, pipe, propOr, split } from "ramda";

const testHome = pipe(
  split("?"),
  propOr("", 0),
  anyPass([equals(""), equals("/")]),
);

export default (routerLocation) =>
  testHome(routerLocation) ? "home" : routerLocation.replace(/^[/]+/, "");
