import React, {
  createContext,
  useState,
  useCallback,
  useRef,
  useMemo,
  useContext
} from "react"
import {useSelector} from "react-redux"
import {node} from "prop-types"
import {
  assocPath,
  cond,
  propEq,
  T,
  always,
  map,
  lensIndex,
  view
} from "ramda"
import {useSpring} from "react-spring"

import {hasData} from "lib/utils/helpers"
import {
  GET_ACTIVE_NODE_PATH,
  GET_AUTHENTICATED,
  GET_BREADCRUMB_OPEN,
  GET_SCROLL_DIRECTION,
  GET_IS_MOBILE,
  GET_IS_TABLET,
  GET_SHOW_LOGIN_PANEL,
  GET_ACTIVE_ITEMS,
  GET_BREADCRUMB_DATA,
  GET_BREADCRUMB_HIDE_SHOW,
  GET_SECONDARYNAVSTICKY_STATE,
  GET_IMPERSONATION_STATUS
} from "lib/redux/selectors"
import QualcommThemeContext from "components/QualcommThemeContext/ThemeContext"

export const initialState = {}

const configureHeights = (
  hasBreadcrumbs,
  showDrupalMenu,
  hasSecondaryAttached = false,
  hideshowbreadcrumb,
  impersonating,
  wosFlag = false
) => {
  const menuHeights = [77, 77, 77]
  const menuHeightBreadcrumbs = [77, 77, 118]
  const base = hasBreadcrumbs && hideshowbreadcrumb ? menuHeightBreadcrumbs : menuHeights

  const adjustment =
  (impersonating ? 41 : 0) +
  (showDrupalMenu ? 30 : 0) +
  (hasSecondaryAttached ? 72 : 0)
  return adjustment > 0 ? map((val) => val + adjustment, base) : base
}

export const NavigationContext = createContext(initialState)
export const NavigationUpdateContext = createContext(() => {})

const propTypes = {
  children: node.isRequired
}
// const parseNavState = (route, navState) => {
//   const routeState = pipe(propOr({}, `/${route}`), values)(navState)
//   return {
//     attached: includes("primary", routeState),
//     secondaryAttached: includes("secondary", routeState)
//   }
// }

export const NavigationContextProvider = ({children}) => {
  const value = useContext(QualcommThemeContext)
  const breadcrumbsOpen = useSelector(GET_BREADCRUMB_OPEN)
  const route = useSelector(GET_ACTIVE_NODE_PATH)
  const isMobile = useSelector(GET_IS_MOBILE)
  const isTablet = useSelector(GET_IS_TABLET)
  const showDrupalMenu = useSelector(GET_AUTHENTICATED)
  const megaMenuActive = useSelector(GET_ACTIVE_ITEMS)
  const showingLoginPanel = useSelector(GET_SHOW_LOGIN_PANEL)
  const results = useSelector(GET_BREADCRUMB_DATA)
  const stateRef = useRef(null)
  const [navState, setNavState] = useState(null)
  const hideshowbreadcrumb = useSelector(GET_BREADCRUMB_HIDE_SHOW)
  const stickyNavState = useSelector(GET_SECONDARYNAVSTICKY_STATE)
  const impersonating = useSelector(GET_IMPERSONATION_STATUS)
  const setContextState = useCallback(
    ({path, id, state}) => {
      if (path === `/${route}`) {
        stateRef.current = assocPath([path, id], state, stateRef.current)
        setNavState(stateRef.current)
      }
    },
    [setNavState, route]
  )
  const {showNavigation = true} = useSelector(GET_SCROLL_DIRECTION)
  const index = useMemo(
    () =>
      cond([
        [propEq("isTablet", true), always(1)],
        [propEq("isMobile", true), always(0)],
        [T, always(2)]
      ])({isMobile, isTablet}),
    [isMobile, isTablet]
  )
  const {heights, navHeight, secondaryNavStart, showing} = useMemo(() => {
    // const {attached} = parseNavState(route, navState)
    const heightsArray = configureHeights(
      results,
      showDrupalMenu,
      null,
      hideshowbreadcrumb,
      impersonating,
      value?.wosFlag
    )
    const mobileShowing =
      showNavigation ||
      hasData(megaMenuActive) ||
      route === "search" ||
      !stickyNavState
    const desktopShowing =
      breadcrumbsOpen ||
      showNavigation ||
      hasData(megaMenuActive) ||
      route === "search" ||
      showingLoginPanel
    return {
      heights: heightsArray,
      navHeight:
        view(lensIndex(index), heightsArray) - (impersonating ? 41 : 0),
      secondaryNavStart: view(lensIndex(index), heightsArray),
      showing: isMobile ? mobileShowing : desktopShowing
    }
  }, [results, showDrupalMenu, hideshowbreadcrumb, impersonating, value.wosFlag, showNavigation, megaMenuActive, route, stickyNavState, breadcrumbsOpen, showingLoginPanel, index, isMobile])
  const {y} = useSpring({
    config: {
      clamp: true,
      friction: 30,
      mass: 1,
      tension: !showing ? 450 : 320
    },
    from: {
      y: 0
    },
    to: {
      y: showing ? 0 : -navHeight
    }
  })

  const navigation = useMemo(() => {
    console.log({
      heights,
      navHeight,
      navState,
      secondaryNavStart,
      showing,
      y,
    })
    return {
      heights,
      navHeight,
      navState,
      secondaryNavStart,
      showing,
      y,
    }
  }, [heights, navHeight, navState, secondaryNavStart, showing, y])

  return (
    <NavigationContext.Provider value={navigation}>
      <NavigationUpdateContext.Provider value={setContextState}>
        {children}
      </NavigationUpdateContext.Provider>
    </NavigationContext.Provider>
  )
}
NavigationContextProvider.propTypes = propTypes

export default NavigationContext
