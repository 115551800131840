import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const TouchApp = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/technology/touch_app-touch-app${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`simplified_icons-technology-touch-app-touch-app${unique}`}
        xlinkHref={`#path-1-touch-app${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-touch-app${unique}`}>
        <path
          d="M12.4155437,11.2771305 C12.5264255,11.2916224 12.6344045,11.3246523 12.7348754,11.3751284 L18.4430625,14.2428932 C18.8249322,14.4347426 19.042685,14.8480548 18.984979,15.2714939 L18.1887277,21.1142793 C18.1211983,21.6098012 17.6979885,21.979248 17.1978864,21.979248 L12.9903009,21.979248 L10.993812,21.979248 C10.6762212,21.979248 10.3775127,21.8283882 10.1890091,21.5727902 L6.03479004,15.9399509 L6.03479004,15.4299002 C6.03479004,14.8776154 6.48250529,14.4299002 7.03479004,14.4299002 C7.1045701,14.4299002 7.17415851,14.437204 7.24241791,14.4516923 L10.1268135,15.0639148 C10.1367081,15.066015 10.146557,15.0682622 10.1563574,15.0706546 L10.1563574,5.96858844 C10.1563574,5.34473136 10.6620935,4.83899526 11.2859505,4.83899526 C11.9098076,4.83899526 12.4155437,5.34473136 12.4155437,5.96858844 L12.4155437,11.2771305 Z M8.35681152,9.49161004 C7.28644076,8.62313955 6.60236953,7.29712396 6.60236953,5.81133498 C6.60236953,3.19525041 8.7231251,1.07449484 11.3392097,1.07449484 C13.9552942,1.07449484 16.0760498,3.19525041 16.0760498,5.81133498 C16.0760498,7.28076997 15.4069548,8.59392945 14.3568115,9.46276654 L14.3568115,5.98844841 C14.3568115,4.33159416 13.0136658,2.98844841 11.3568115,2.98844841 C9.69995727,2.98844841 8.35681152,4.33159416 8.35681152,5.98844841 L8.35681152,9.49161004 Z"
          fill="inherit"
          id={`path-1-touch-app${unique}`}
        />
      </g>
    </g>
  </svg>
)

TouchApp.propTypes = propTypes
export default TouchApp
