import { is, test } from "ramda";

import linkTo from "../link-to";
import { clearAll } from "lib/utils/browserStorage";
// import {env} from "config";

export function getMeta(metaName) {
  const metas = document.getElementsByTagName("meta");
  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute("name") === metaName) {
      return metas[i].getAttribute("content");
    }
  }
  return null;
}
const QC_PUBLIC_CREATEPOINT_LOGIN =
  getMeta("loginEndpointUrl") || "https://myid.qualcomm.com/api/login?state=";
const QC_PUBLIC_CREATEPOINT_LOGOUT =
  getMeta("logoutEndpointUrl") ||
  "https://myid.qualcomm.com/api/logout?targetUrl=";
const QC_PUBLIC_CREATEPOINT_REGISTER =
  getMeta("registerEndpointUrl") || "https://myaccount.qualcomm.com/signup";
const QC_PUBLIC_CREATEPOINT_PROFILE =
  getMeta("profileEndpointUrl") || "https://myaccount.qualcomm.com/profile";

// Append timestamp as cache buster only if query string doesn't exist
const appendCacheBuster = (url) =>
  `${url}${!test(/\?/, url) ? `?${Date.now()}` : ""}`;

const urlRedirect = (url, cachebuster = false) =>
  linkTo(
    `${url}${encodeURIComponent(
      cachebuster ? appendCacheBuster(document.URL) : document.URL,
    )}`,
    true,
  );

const processLogout = () => {
  clearAll();
  urlRedirect(QC_PUBLIC_CREATEPOINT_LOGOUT);
};

export const handleLoginRedirect = () =>
  urlRedirect(QC_PUBLIC_CREATEPOINT_LOGIN);
// urlRedirect(env.QC_PUBLIC_CREATEPOINT_LOGIN)

export const handleLogoutRedirect = ({ redirectUrl } = {}) =>
  redirectUrl && is(String, redirectUrl)
    ? linkTo(redirectUrl)
    : processLogout();

export const handleImpersonationRelogin = () =>
  // process.env.BUILD_TARGET === "client"
  true
    ? linkTo(
        `${QC_PUBLIC_CREATEPOINT_LOGOUT}${encodeURIComponent(
          `${QC_PUBLIC_CREATEPOINT_LOGIN}${document.URL}`,
        )}`,
        true,
      )
    : null;

export const handleRegisterRedirect = () =>
  linkTo(QC_PUBLIC_CREATEPOINT_REGISTER, true);

export const handleProfileRedirect = () =>
  linkTo(QC_PUBLIC_CREATEPOINT_PROFILE, true, true);
