import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const ArrowCircleDown = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/arrow_circle_down-arrow-circle-down${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`ic_cancel_24px-arrow-circle-down${unique}`}
        xlinkHref={`#path-1-arrow-circle-down${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-arrow-circle-down${unique}`}>
        <path
          d="M12,2 C17.53,2 22,6.47 22,12 C22,17.53 17.53,22 12,22 C6.47,22 2,17.53 2,12 C2,6.47 6.47,2 12,2 Z M11.02599,14.7174501 L7.51794834,11.2094084 C7.32268619,11.0141463 7.0061037,11.0141463 6.81084156,11.2094084 L6.14644661,11.8738034 C5.95118446,12.0690655 5.95118446,12.385648 6.14644661,12.5809101 L11.0219036,17.4563671 C11.3458507,17.778271 11.6697979,17.939223 11.993745,17.939223 C12.3176921,17.939223 12.6416393,17.778271 12.9655864,17.4563671 L17.8410434,12.5809101 C18.0363055,12.385648 18.0363055,12.0690655 17.8410434,11.8738034 L17.1766484,11.2094084 C16.9813863,11.0141463 16.6648038,11.0141463 16.4695417,11.2094084 L12.9655864,14.7133637 L12.9655864,6.5 C12.9655864,6.22385763 12.7417288,6 12.4655864,6 L11.52599,6 C11.2498477,6 11.02599,6.22385763 11.02599,6.5 L11.02599,14.7174501 Z"
          fill="inherit"
          id={`path-1-arrow-circle-down${unique}`}
        />
      </g>
    </g>
  </svg>
)

ArrowCircleDown.propTypes = propTypes
export default ArrowCircleDown
