import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Renren = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/renren-renren${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-renren${unique}`}
        xlinkHref={`#path-1-renren${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-renren${unique}`}>
        <path
          d="M7.21608173,13.5705098 C7.6030186,15.1731477 8.75937076,16.5433603 10.200115,17.4537656 C9.31419468,17.939666 8.29722458,18.2165298 7.21549701,18.2165298 C6.1339887,18.2165298 5.11723787,17.9398122 4.23146379,17.4541311 C5.67250033,16.5437258 6.82914485,15.173367 7.21608173,13.5705098 Z M11.9592013,7.98660285 C11.0413409,9.06935368 10.4874671,10.470264 10.4874671,12.0012009 C10.4874671,13.5314068 11.0415601,14.9315862 11.9586897,16.0145563 C9.7757196,14.9569484 8.2520186,12.561945 8.2520186,9.77635036 L8.25172625,9.77635036 L8.25172625,5.87321414 C9.72981927,6.12135368 11.0307429,6.89128059 11.9592013,7.98660285 Z M11.9592013,7.98660285 C12.8878791,6.89106132 14.2319256,6.1212075 15.7102379,5.87306796 L15.7102379,9.80580551 C15.6995668,12.5794135 14.1354472,14.9619916 11.9594206,16.0154334 C11.9592013,16.0151411 11.9589821,16.0148487 11.9586897,16.0145563 C11.9589821,16.0146294 11.9592013,16.0147756 11.9594206,16.0149218 C12.8767694,14.9318786 13.4739123,13.5316261 13.4739123,12.0012009 C13.4739123,10.470264 12.8770618,9.06935368 11.9592013,7.98660285 Z M17.7821116,9.77637228 L17.7821116,5.87323607 C20.7212678,6.36681082 22.9613209,8.92166132 22.9613209,12.0011497 C22.9613209,13.5315749 22.4070817,14.9319005 21.489806,16.0149437 C19.3063973,14.9574819 17.782404,12.5622593 17.782404,9.77637228 L17.7821116,9.77637228 Z M16.7464379,13.5705098 C17.1333748,15.1731477 18.2897269,16.5433603 19.7304711,17.4537656 C18.8445508,17.939666 17.8275807,18.2165298 16.7457801,18.2165298 C15.6642718,18.2165298 14.647594,17.9398122 13.7618199,17.4541311 C15.2028565,16.5437258 16.359501,15.173367 16.7464379,13.5705098 Z M6.17991096,9.80578358 C6.16916678,12.5794646 4.64802392,14.9619696 2.47199734,16.0154115 C1.55442924,14.9323683 0.999970764,13.5318234 0.999970764,12.0011789 C0.999970764,8.9213982 3.24038937,6.36632843 6.17991096,5.87304604 L6.17991096,9.80578358 Z"
          fill="inherit"
          id={`path-1-renren${unique}`}
        />
      </g>
    </g>
  </svg>
)

Renren.propTypes = propTypes
export default Renren
