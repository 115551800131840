import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const LinkedinBox = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/linkedin_box-linkedin-box${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`fill-1-linkedin-box${unique}`}
        xlinkHref={`#path-1-linkedin-box${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-linkedin-box${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M5.22266597,19.00028 L8.18261599,19.00028 L8.18261599,9.56458902 L5.22266597,9.56458902 L5.22266597,19.00028 Z M12.8066876,13.5507069 C12.8066876,12.3399066 13.5355,11.7345064 14.3916116,11.7345064 C15.2457797,11.7345064 15.9425244,12.3399066 15.9425244,13.8538928 L15.9425244,19.00028 L18.7946102,19.00028 L18.7946102,13.5507069 C18.7946102,10.8259203 17.3690531,9.31290581 15.373079,9.31290581 C14.2322446,9.31290581 13.376133,10.0018764 12.8066876,10.9104626 L12.7114561,9.61609176 L9.90892959,9.61609176 C9.90892959,9.93482571 9.95557358,11.7345064 9.95557358,11.7345064 L9.95557358,19.00028 L12.8066876,19.00028 L12.8066876,13.5507069 Z M8.55528206,6.85340695 C8.55528206,5.83015437 7.72540769,5.00028 6.70312686,5.00028 C5.67987427,5.00028 4.8499999,5.83015437 4.8499999,6.85340695 C4.8499999,7.87568779 5.67987427,8.7045904 6.70312686,8.7045904 C7.72540769,8.7045904 8.55528206,7.87568779 8.55528206,6.85340695 Z"
          fill="inherit"
          id={`path-1-linkedin-box${unique}`}
        />
      </g>
    </g>
  </svg>
)

LinkedinBox.propTypes = propTypes
export default LinkedinBox
