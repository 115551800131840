import React from "react"
import {string} from "prop-types"
import style from "./theme-icon.module.css"

const propTypes = {
  unique: string
}

const TwitterWOS = ({unique = "", ...props}) => (
  <svg fill={'inherit'} width="20" height="20" viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg">
  <path  d="M16.6356 6.62744V7.06982C16.6356 11.5846 13.2017 16.7931 6.91359 16.7931C4.98509 16.7931 3.16942 16.37 1.66663 15.4044C1.93462 15.4429 2.20517 15.4621 2.47572 15.4621C4.07854 15.4621 5.56338 14.7684 6.72125 13.8414C5.23512 13.8221 3.96057 12.8207 3.53743 11.4692C3.75028 11.5077 3.96057 11.5269 4.17342 11.5269C4.48116 11.5269 4.79275 11.4884 5.07997 11.4102C3.51819 11.1025 2.34109 9.71381 2.34109 8.05459V8.01612C2.80398 8.26616 3.32457 8.42003 3.88363 8.44054C2.95913 7.8225 2.36032 6.78131 2.36032 5.60421C2.36032 4.96821 2.53343 4.44634 2.8245 3.94498C4.52219 6.0094 7.02899 7.30319 9.86404 7.45578C9.80634 7.20702 9.78839 6.93518 9.78839 6.66463C9.78839 4.77588 11.3117 3.25 13.2017 3.25C14.1865 3.25 15.0725 3.67442 15.7111 4.33094C16.483 4.17707 17.2139 3.88728 17.8704 3.50132C17.6204 4.29247 17.0793 4.96821 16.365 5.39264C17.0613 5.31442 17.7178 5.12208 18.3333 4.85153C17.8704 5.54651 17.2921 6.14403 16.6356 6.62744Z"
         fill={'inherit'} />
  </svg>
)

TwitterWOS.propTypes = propTypes
export default TwitterWOS
