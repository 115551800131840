import React, {useCallback, useContext, useEffect, useState} from "react"
import {bool, func, string} from "prop-types"

import Autosuggest from "./AutosuggestComponent"
import GET_SEARCH_AUTOSUGGEST from "gql/queries/interceptor/getSearchAutosuggest"
import useLazyQueryPromise from "lib/hooks/use-lazy-query-promise"
import {autosuggestInitialState, formatAutosuggestResponse} from "./utils"


// TODO: replace with current user state
const requestGuid = "test"
const sessionGuid = "test"

const propTypes = {
  isSearchFocused: bool,
  searchInputChanged: bool,
  searchValue: string,
  setActiveCategory: func,
  setUrlQuery: func,
}

function AutosuggestContainer({
  isSearchFocused,
  searchInputChanged,
  setActiveCategory,
  setUrlQuery,
  searchValue,
  wosFlagDesktop,
  wosFlagMobile,
  ...props
}) {
  const [autosuggest, setAutosuggest] = useState(autosuggestInitialState)
  const [showing, setShowing] = useState(false)
  const [doQuery] = useLazyQueryPromise(GET_SEARCH_AUTOSUGGEST)
  
  
  useEffect(() => {
    if (
      !searchValue ||
      !searchValue.trim() ||
      !isSearchFocused ||
      !searchInputChanged
    ) {
      setShowing(false)
    } else {
      setShowing(true)
      doQuery({
        fetchPolicy: "cache-and-network",
        variables: {
          requestGuid,
          searchText: searchValue,
          sessionGuid,
        },
      }).then(({data}) => {
        setAutosuggest(formatAutosuggestResponse(data?.searchAutosuggest || {}))
      })
    }
  }, [doQuery, isSearchFocused, searchValue, searchInputChanged])

  const onFilterClicked = useCallback(
    (id, value) => {
      setUrlQuery(value)
      setActiveCategory(id)
    },
    [setActiveCategory, setUrlQuery],
  )

  const onSuggestedSearchClicked = useCallback(
    (search) => {
      setUrlQuery(search)
    },
    [setUrlQuery],
  )

  return (
    <Autosuggest
      {...props}
      {...autosuggest}
      onFilterClicked={onFilterClicked}
      onSuggestedSearchClicked={onSuggestedSearchClicked}
      showing={showing}
      value={searchValue}
      wosFlagDesktop={wosFlagDesktop}
      wosFlagMobile={wosFlagMobile}
      
    />
  )
}

AutosuggestContainer.propTypes = propTypes
export default AutosuggestContainer
