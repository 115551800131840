import { combineReducers } from "redux"

import coverReducer from "./reducers/cover/cover-reducer"
import createpointReducer from "./reducers/createpoint/createpoint-reducer"
import dataReducer from "./reducers/data/data-reducer"
import drupalReducer from "./reducers/drupal/drupal-reducer"
import globalsReducer from "./reducers/globals/globals-reducer"
import globalSearchReducer from "./reducers/global-search/global-search-reducer"
import nodeReducer from "./reducers/node/node-reducer"
import uiReducer from "./reducers/ui/ui-reducer"
import userReducer from "./reducers/user/user-reducer"
import videosReducer from "./reducers/videos/videos-reducer"

export default combineReducers({
  coverReducer,
  createpointReducer,
  dataReducer,
  drupalReducer,
  globalSearchReducer,
  globalsReducer,
  nodeReducer,
  uiReducer,
  userReducer,
  videosReducer,
})
