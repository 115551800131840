import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Icons = ({unique = "", ...props}) => (
  <svg viewBox="0 0 69 63" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <path
        d="M9.5,5.11111111 L9.5,7.33333333 L10.3333333,7.33333333 L10.3333333,14 L12,14 L12,10.6666667 L13.2213669,11.4809112 C13.4995655,11.666377 13.6666667,11.9786083 13.6666667,12.3129615 L13.6666667,16.8333333 C13.6666667,17.1094757 13.442809,17.3333333 13.1666667,17.3333333 L0.833333333,17.3333333 C0.557190958,17.3333333 0.333333333,17.1094757 0.333333333,16.8333333 L0.333333333,13.6666667 C0.333333333,13.3905243 0.557190958,13.1666667 0.833333333,13.1666667 L2,13.1666667 L2,7.33333333 L3.66666667,7.33333333 L4.5,8.44444444 L4.5,15.1111111 L7,15.1111111 L7,7.33333333 L7.83333333,7.33333333 L7.83333333,5.11111111 L9.5,5.11111111 Z M8.66666667,0.666666667 C10.200791,0.666666667 11.4444444,1.91032014 11.4444444,3.44444444 C11.4444444,4.20587273 11.135842,4.91956156 10.5999465,5.43905912 L9.82657288,4.64127401 C10.1487479,4.32895723 10.3333333,3.90207614 10.3333333,3.44444444 C10.3333333,2.52396986 9.58714125,1.77777778 8.66666667,1.77777778 C7.74619208,1.77777778 7,2.52396986 7,3.44444444 C7,3.9114961 7.19234305,4.34643176 7.52631075,4.65992358 L6.76586364,5.47003968 C6.21041536,4.94864647 5.88888889,4.22159483 5.88888889,3.44444444 C5.88888889,1.91032014 7.13254236,0.666666667 8.66666667,0.666666667 Z M8.66666667,2.82500013 C9.01184464,2.82500013 9.29166667,3.10482216 9.29166667,3.45000013 C9.29166667,3.7951781 9.01184464,4.07500013 8.66666667,4.07500013 C8.3214887,4.07500013 8.04166667,3.7951781 8.04166667,3.45000013 C8.04166667,3.10482216 8.3214887,2.82500013 8.66666667,2.82500013 Z"
        id={`path-1-icons${unique}`}
      />
    </defs>
    <g
      fillRule="evenodd"
      id={`desktop-icons${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <g
        id={`1440px-rffe-sub-cat-products-v1-icons${unique}`}
        transform="translate(-469.000000, -1202.000000)"
      >
        <g
          id={`block-3-icons${unique}`}
          transform="translate(431.000000, 777.000000)"
        >
          <g
            id={`flex-block-item-•-block-3-copy-icons${unique}`}
            transform="translate(0.000000, 367.000000)"
          >
            <g
              id={`content-icons${unique}`}
              transform="translate(32.000000, 48.000000)"
            >
              <g
                id={`group-4-icons${unique}`}
                transform="translate(6.000000, 10.000000)"
              >
                <g
                  fill="#B0C6FF"
                  id={`group-icons${unique}`}
                  transform="translate(9.000000, 0.000000)"
                >
                  <path
                    d="M2.48181636,30.0903432 C3.71180874,34.8012217 6.07868843,39.0867739 9.33744213,42.483988 C9.74417275,42.9039354 9.75887355,43.6092152 9.37664426,44.0560737 C9.17082456,44.2929713 8.89640966,44.4060285 8.62198623,44.4006412 C8.3769644,44.3952623 8.13685136,44.2929713 7.94573245,44.0937679 C4.42726464,40.4273579 1.86436578,35.7972298 0.536359559,30.7040981 C0.384451308,30.1118755 0.693176599,29.4981121 1.2322144,29.3258284 C1.77126072,29.158932 2.32989959,29.4981121 2.48181636,30.0903432 Z M48.3786041,30.0903432 C48.5442417,29.4981121 49.1533575,29.158932 49.7410912,29.3258284 C50.32349,29.4981121 50.6654433,30.1118755 50.4944624,30.7040981 C49.0518325,35.7972298 46.257411,40.4273579 42.4210821,44.0937679 C42.2126972,44.2929713 41.9508867,44.3952623 41.6837412,44.4006412 C41.3845267,44.4060285 41.0853122,44.2929713 40.8609055,44.0560737 C40.4388009,43.6092152 40.4601746,42.9039354 40.9036444,42.483988 C44.4514456,39.0867739 47.0374993,34.8012217 48.3786041,30.0903432 Z M17.4952604,1.63743992 C22.4768198,0.358860759 27.7628435,0.358860759 32.7759095,1.63743992 C33.0488671,1.70628903 33.2640868,1.86365601 33.4058196,2.07019487 C33.5633013,2.30623687 33.6210392,2.59638064 33.5370529,2.881603 C33.3795712,3.42745752 32.7864089,3.74218301 32.2089871,3.59465888 C27.5686225,2.41443196 22.676299,2.41443196 18.0674325,3.59465888 C17.4900107,3.74710443 16.8915902,3.42745752 16.7341169,2.88651595 C16.5766352,2.34558285 16.9178386,1.78497252 17.4952604,1.63743992 Z"
                    id={`combined-shape-icons${unique}`}
                  />
                </g>
                <g id={`audio-icons${unique}`}>
                  <path
                    d="M0.0100290698,13.8588608 C0.0100290698,6.40301979 6.05418462,0.358860759 13.5100291,0.358860759 C20.96587,0.358860759 27.0100291,6.40301979 27.0100291,13.8588608 C27.0100291,21.3147017 20.96587,27.3588608 13.5100291,27.3588608 C6.05418462,27.3588608 0.0100290698,21.3147017 0.0100290698,13.8588608 Z"
                    fill="#CCD9FD"
                    id={`fill-7-icons${unique}`}
                  />
                  <path
                    d="M11.6138205,7.20872923 C12.3345346,6.68967571 13.3744222,7.19884403 13.3744222,8.0738156 L13.3744222,8.0738156 L13.3744222,19.7055714 C13.3744222,20.580543 12.3345346,21.0897113 11.6138205,20.5706578 L11.6138205,20.5706578 C11.5880076,20.5521197 11.2588927,20.3157596 6.73356278,17.0658077 L6.73356278,17.0658077 L5.25610014,17.0658077 C4.56627476,17.0658077 4,16.5319222 4,15.8645737 L4,15.8645737 L4,11.9148218 C4,11.2474649 4.56627476,10.7135793 5.25610014,10.7135793 L5.25610014,10.7135793 L6.73435476,10.7130105 L6.73435476,10.7130105 C6.77389548,10.6846136 7.17722257,10.3949566 11.6138205,7.20872923 Z M16.1094427,8.03347958 C18.9911342,8.82150521 21,11.2334784 21,14.000164 C21,16.7663772 18.9918155,19.1780396 16.1108142,19.9664731 C15.6559375,20.0909576 15.1757251,19.8580156 15.0382298,19.4461826 C14.9007345,19.0343496 15.158023,18.5995783 15.6128997,18.4750938 C17.7726036,17.8840552 19.2791382,16.0748326 19.2791382,14.000164 C19.2791382,11.9251417 17.772092,10.1156854 15.6118735,9.52495336 C15.1569679,9.40055517 14.8995788,8.96583273 15.0369788,8.55397362 C15.1743787,8.14211451 15.6545372,7.90908139 16.1094427,8.03347958 Z"
                    fill="#7BA0FF"
                    id={`combined-shape-icons${unique}`}
                  />
                </g>
                <g
                  id={`watch-icons${unique}`}
                  transform="translate(41.000000, 0.000000)"
                >
                  <path
                    d="M0.730959302,13.5170886 C0.730959302,6.25000311 6.65798347,0.358860759 13.9693314,0.358860759 C21.2806793,0.358860759 27.2077035,6.25000311 27.2077035,13.5170886 C27.2077035,20.7841741 21.2806793,26.6753165 13.9693314,26.6753165 C6.65798347,26.6753165 0.730959302,20.7841741 0.730959302,13.5170886 Z"
                    fill="#CCD9FD"
                    id={`fill-6-icons${unique}`}
                  />
                  <path
                    d="M16.2633383,4.34620253 C16.4735989,4.34620253 16.6676823,4.49053324 16.7377751,4.7070166 C16.8186439,4.93381716 16.9588118,6.91831148 17.2661185,7.5368523 C17.7297704,8.43889594 18.0208999,8.91311453 18.1449081,9.06775397 C18.2652326,9.14709077 18.3408527,9.27527918 18.3693104,9.43351896 C19.371916,10.5037485 19.9867733,11.9390497 19.9867733,13.5170886 C19.9867733,15.4513544 19.0629876,17.1711655 17.6300148,18.2644345 C17.5230632,18.4570092 17.4019997,18.6846227 17.2661185,18.9489822 C16.9588118,19.5623729 16.8186439,21.5468672 16.7377751,21.7788179 C16.6676823,21.9953097 16.4735989,22.1344818 16.2633383,22.1344818 L16.2633383,22.1344818 L11.6757016,22.1344818 L11.6757016,22.1344818 L11.5865176,22.1261414 C11.4121419,22.0931404 11.2609672,21.9643823 11.2008877,21.7788179 C11.1200189,21.5468672 10.979851,19.5623729 10.6725443,18.9489822 C10.5366631,18.6846227 10.4155996,18.4570092 10.3092178,18.2634166 C8.87567519,17.1711655 7.95188953,15.4513544 7.95188953,13.5170886 C7.95188953,11.9390497 8.5667468,10.5037485 9.57133185,9.43495097 C9.5978101,9.27527918 9.6734302,9.14709077 9.79375467,9.06775397 C9.91776292,8.91311453 10.2088924,8.43889594 10.6725443,7.5368523 C10.979851,6.91831148 11.1200189,4.93381716 11.2008877,4.7070166 C11.2709805,4.49053324 11.4650638,4.34620253 11.6753245,4.34620253 L11.6753245,4.34620253 Z"
                    fill="#7BA0FF"
                    id={`combined-shape-icons${unique}`}
                  />
                  <path
                    d="M9.5565407,13.5170886 C9.5565407,11.0947227 11.5322177,9.13101266 13.9693314,9.13101266 C16.4064451,9.13101266 18.3821221,11.0947227 18.3821221,13.5170886 C18.3821221,15.9394546 16.4064451,17.9031646 13.9693314,17.9031646 C11.5322177,17.9031646 9.5565407,15.9394546 9.5565407,13.5170886 Z"
                    fill="#CCD9FD"
                    id={`fill-22-icons${unique}`}
                  />
                  <path
                    d="M19.6999969,12.2177136 C20.1923927,12.2177136 20.5522172,12.7824237 20.5522172,13.4671285 C20.5522172,14.1871302 20.1923927,14.7588964 19.6999969,14.7588964 L19.6999969,12.2177136 Z"
                    fill="#CCD9FD"
                    id={`fill-23-icons${unique}`}
                  />
                </g>
                <path
                  d="M20.463314,49.0936472 C20.463314,41.8265617 26.5699383,35.9354193 34.1028488,35.9354193 C41.6357594,35.9354193 47.7423837,41.8265617 47.7423837,49.0936472 C47.7423837,56.3607352 41.6357594,62.251875 34.1028488,62.251875 C26.5699383,62.251875 20.463314,56.3607352 20.463314,49.0936472 Z"
                  fill="#CCD9FD"
                  id={`fill-3-icons${unique}`}
                />
                <g
                  id={`glyphs/applications/city-smart-icons${unique}`}
                  transform="translate(27.000000, 39.000000)"
                >
                  <mask fill="none" id={`mask-2-icons${unique}`}>
                    <use xlinkHref={`#path-1-icons${unique}`} />
                  </mask>
                  <use
                    fill="#7BA0FF"
                    id={`combined-shape-icons${unique}`}
                    xlinkHref={`#path-1-icons${unique}`}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

Icons.propTypes = propTypes
export default Icons
