
import React from "react"
import { string } from "prop-types"

const propTypes = {
    unique: string,
}

const WosArrowDown = ({ unique = "", ...props }) => (
    <svg width="10" height="10" viewBox="0 0 9 8" fill="inherit" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path id={`path-1-wos-arrow-down${unique}`} d="M4.42887 6L1.54212 2.5L7.31563 2.5L4.42887 6Z" fill="inherit" />
    </svg>
)

WosArrowDown.propTypes = propTypes
export default WosArrowDown
