import React from "react"
import MenuItemWithChildren from "./MenuItemWithChildren"
import CtaGroup from "components/cta-group/CtaGroupComponent"
import styles from "./MenuItemsLayoutDefault.module.css"
import Link from "components/link"

export default function MenuItemLayoutDefault({
  developerLinkTitle,
  developerLinkUrl,
  items,
  currentPageId,
  cta,
  urlSearch,
  onTabClick
}) {
  const isDeveloperLinkTitleValid =
    developerLinkTitle && developerLinkTitle !== ""
  const DeveloperLinkTag = developerLinkUrl ? Link : "div"
  return (
    <div className={styles.dropdownContainer}>
      {isDeveloperLinkTitleValid && (
        <DeveloperLinkTag
          href={developerLinkUrl}
          className={styles.secondaryMenuItemWrapperCTALink}
          location={{
            state: {
              text: developerLinkTitle,
              type: "CTA",
            }}
          }
        >
          <span style={{color: "var(--theme-heading-color)"}}>{developerLinkTitle}</span>
        </DeveloperLinkTag>
      )}
      {items.map((item) =>
        !item.childPages ? (
          //TODO : Clean up after proper set up for Hardware tab - Start
          item.title == "Hardware Dev" ? 
            <a key={"hardware-dev"}
              href={`${urlSearch ? urlSearch : ""}#hardware-dev`}
              className={`${styles.secondaryMenuItemWrapperLink}${
              item.id === currentPageId
                ? ` ${styles.secondaryMenuItemWrapperActive}`
                : ""
            }`}
              onClick={(event) => {
                event.preventDefault()
                onTabClick({url: `${urlSearch ? urlSearch : ""}#hardware-dev`, title:  item.title})
              }}
            >
              {item.title}
            </a>
            :
          //TODO : Clean up after proper set up for Hardware tab - End
            <a key={item.id}
              href={item.id ? `${urlSearch ? urlSearch : ""}#${item.id}` : item.path}
              className={`${styles.secondaryMenuItemWrapperLink}${
              item.id === currentPageId
                ? ` ${styles.secondaryMenuItemWrapperActive}`
                : ""
            }`}
              onClick={(event) => {
                event.preventDefault()
                onTabClick({url: item.id ? `${urlSearch ? urlSearch : ""}#${item.id}` : item.path, title:  item.title})
              }}
            >
              {item.title}
            </a>
        ) : (
          <MenuItemWithChildren key={item.id}
            itemPage={item}
            urlSearch={urlSearch}
            onTabClick={onTabClick}
          />
        )
      )}
      {cta ? <CtaGroup className={styles.menuItemCTA} ctas={[{...cta}]} /> : null}
    </div>
  )
}
