import React from "react"
import {css} from "styled-components"
import {arrayOf, bool, func, shape, string} from "prop-types"
import PropTypes from "prop-types"
import Primitives from "primitives"
import {mapIndexed} from "lib/utils/helpers"
import GridSystem from "lib/grid-system"

const propTypes = {
  onFilterClicked: func,
  onSuggestedSearchClicked: func,
  showing: bool,
  suggestedFilters: arrayOf(
    shape({
      facetId: string,
      label: string,
    }),
  ),
  /** array of suggested searches */
  suggestedSearches: arrayOf(
    shape({
      searchText: string,
    }),
  ),
  /** Input text value */
  value: string,
}

const suggestionWrapperCss = css`
  @media only screen and (max-height: 700px) {
    height: auto;
    max-height: 70vh;
    overflow: scroll;
  }
`

const suggestionWrapperStyles = {
  backgroundColor: "white",
  borderRadius: "8px",
  boxShadow:
    "0 0 12px 0 rgba(74, 90, 117, 0.08), 0 0 20px -12px rgba(74, 90, 117, 0.24)",
  flexDirection: "column",
  maxWidth: "456px",
  mt: "-8px",
  mx: [0, 3, 6, 7],
  pl: ["22px", "34px"],
  position: "absolute",
  px: [2, 3],

  width: 1,
  zIndex: 10,
}

const suggestionWrapperMobileStyles = {
  backgroundColor: "white",
  borderRadius: "0px",
  boxShadow: "none",
  flexDirection: "column",
  maxWidth: "100%",
  mt: "0px",
  mx: [0, 3, 6, 7],
  pl: ["22px", "34px"],
  position: "absolute",
  px: [2, 3],
  width: 1,
  zIndex: 10,
  margin: "0px !important",
  padding: "0px !important",
}

const suggestionWosWrapperStyles = {
  backgroundColor: "white",
  border:"1px solid #CDD4DE",
  borderTop: "0px",
  flexDirection: "column",
  maxWidth: "456px",
  mt: "0px",
  pl: ["22px", "34px"],
  position: "absolute",
  px: [2, 4],
  py: [2, 4],
  width: 1,
  zIndex: 10,
}



const suggestedFilterStyles = {
  flexDirection: "column",
  paddingTop : "0px",
  paddingBottom:"24px"
}

const suggestedFilterMobileStyles = {
  flexDirection: "column",
  
  // padding : "0px 24px",
  width:"100%",
  display:"flex",
  
}


const searchTextStyles = {
  color: "coolgray",
  cursor: "pointer",
  fontSize: 2,
  letterSpacing: "0.5px",
  overflow: "hidden",
  pt: [0, 0],
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",

}

const searchTextWosStyles = {
  color: "coolgray",
  cursor: "pointer",
  fontSize: 2,
  letterSpacing: "0.5px",
  overflow: "hidden",
  pt: [0, 0],
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  fontFamily:"Roboto Flex"
}

const drawerItemStyles = {
  color: "legal-grey",
  fontSize: 0,
  letterSpacing: "1.5px",
  textTransform: "uppercase",
}

const drawerItemMobileStyles = {
  height: "60px",
  alignItems: "center",
  fontSize: "14px",
  fontWeight: "560",	
  color: "#222222",
  fontFamily:"Roboto Flex"
}

const drawerItemWosStyles = {
  fontSize: "14px",
  letterSpacing: "1.5px",
  color:"#000000",
  fontFamily:"Roboto Flex",
  fontWeight:660
}

const truncatedFilterStyles = {
  alignItems: "center",
  cursor: "pointer",
  pt: [2, 2],
}

const truncatedFilterMobileStyles ={
  alignItems: "center",
  cursor: "pointer",
  paddingTop: "0px",
  height: "60px",
  fontSize:"14px",
  fontWeight: "400",
}

const truncatedFilterTitleStyles = {
  color: "coolgray",
  fontSize: 2,
  letterSpacing: "0.5px",
  maxWidth: "65%",
  overflow: "hidden",
  pr: 1,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}



const filterTypeStyles = {
  color: "gray-dark",
  fontSize: 2,
  letterSpacing: "0.5px",
  whiteSpace: "nowrap",
  width: "auto",
}

const filterTypeWosStyles = {
  color: "#000000",  
  fontSize: 2,
  letterSpacing: "0.5px",
  whiteSpace: "nowrap",
  width: "auto",
  fontFamily:"Roboto Flex"
}
const filterWrapper = {display: "flex",
  flexDirection: "column",
  width: "100%"}

export const Autosuggest = ({
  onFilterClicked,
  onSuggestedSearchClicked,
  suggestedFilters,
  suggestedSearches,
  showing,
  value,
  wosFlagDesktop,
  wosFlagMobile,
  
}) => {
  if (!showing) {
    return null
  }

  const truncatedFilterTitleCss = (wosFlagDesktop || wosFlagMobile) ? {...truncatedFilterTitleStyles,fontFamily:"Roboto Flex"}:truncatedFilterTitleStyles
  const searchTextCss = (wosFlagDesktop || wosFlagMobile) ? searchTextWosStyles : searchTextStyles
  const wrapperStyles = wosFlagDesktop ? suggestionWosWrapperStyles :suggestionWrapperStyles

  const drawerItemsStyles = wosFlagDesktop ? drawerItemWosStyles : drawerItemStyles

  const filtersTypeStyles = (wosFlagDesktop || wosFlagMobile) ? filterTypeWosStyles : filterTypeStyles

  const suggestWrapperStyles = wosFlagMobile ? suggestionWrapperMobileStyles : wrapperStyles

  const suggestedFiltersMobileStyles = wosFlagMobile ? suggestedFilterMobileStyles : suggestedFilterStyles

  const truncatedFilteredStyles = wosFlagMobile ? truncatedFilterMobileStyles : truncatedFilterStyles
  const WrapperTag = (wosFlagMobile)?GridSystem.Wrapper:React.Fragment
  return (
    <Primitives.Flex position="relative" maxWidth="100%" width={wosFlagMobile ? "100%":"456px"}>
      <Primitives.Flex
        css={!wosFlagDesktop ? suggestionWrapperCss : null}
        data-test-id="gs-autosuggest-wrapper"
        {...suggestWrapperStyles}
      >
        <WrapperTag>
          <div style={ (wosFlagDesktop || wosFlagMobile) ? filterWrapper :{}}>
            {suggestedSearches.length > 0 && (
              <Primitives.Flex {...suggestedFiltersMobileStyles}  >
                {wosFlagMobile ? <Primitives.Text {...drawerItemMobileStyles} alignItems="center" display="flex">
              Suggested Searches
                </Primitives.Text> : <Primitives.Text {...drawerItemsStyles}>
              Suggested Searches
                </Primitives.Text> }
                {suggestedSearches.map(({searchText}) => (
                  <Primitives.Flex
                    key={searchText}
                    cursor="pointer"
                    data-test-id="gs-autosuggest-suggested-search-wrapper"
                    onMouseDown={() => onSuggestedSearchClicked(searchText)}
                    {...truncatedFilteredStyles}
                  >
                    <Primitives.Text padding-top={wosFlagMobile? "0px":null} 
                      data-test-id="gs-autosuggest-suggested-search"
                      {...searchTextCss}
                    >
                      {searchText}
                    </Primitives.Text>
                  </Primitives.Flex>
                ))}
              </Primitives.Flex>
            )}
            {suggestedFilters.length > 0 && (
              <Primitives.Flex
                data-test-id="gs-autosuggest-suggested-filters"
                {...suggestedFiltersMobileStyles}
              >
                {wosFlagMobile ? <Primitives.Flex {...drawerItemMobileStyles}>
              Suggested Filters
                </Primitives.Flex>:<Primitives.Flex {...drawerItemsStyles} >
              Suggested Filters
                </Primitives.Flex>}
                <Primitives.Flex flexDirection="column">
                  {mapIndexed(
                    ({facetId, label}) => (
                      <Primitives.Flex
                        key={facetId}
                        onMouseDown={() => onFilterClicked(facetId, value)}
                        {...truncatedFilteredStyles}
                      >
                        <Primitives.Text
                          data-test-id="gs-autosuggest-search-text"
                          {...truncatedFilterTitleCss}
                        >
                          {value}
                        </Primitives.Text>

                        <Primitives.Text {...filtersTypeStyles}>
                      in{" "}
                          <span data-test-id="gs-autosuggest-suggested-filter-type">
                            {label}
                          </span>
                        </Primitives.Text>
                      </Primitives.Flex>
                    ),
                    suggestedFilters,
                  )}
                </Primitives.Flex>
              </Primitives.Flex>
            )}
          </div>
        </WrapperTag>
      </Primitives.Flex>
    </Primitives.Flex>
  )
}

Autosuggest.propTypes = {
  wosFlagDesktop: PropTypes.bool,
  wosFlagMobile: PropTypes.bool
}

Autosuggest.propTypes = propTypes
export default Autosuggest
