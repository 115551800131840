import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Comment = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/communications/comment-comment${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`combined-shape-comment${unique}`}
        xlinkHref={`#path-1-comment${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-comment${unique}`}>
        <path
          d="M20,3 C20.5522847,3 21,3.44771525 21,4 L21,20.1849003 C21,20.3277192 20.9389261,20.4637213 20.8321819,20.558605 C20.6257905,20.742064 20.3097543,20.7234736 20.1262953,20.5170823 L17,17 L4,17 C3.44771525,17 3,16.5522847 3,16 L3,4 C3,3.44771525 3.44771525,3 4,3 L20,3 Z M6.5,6.5 C6.22385763,6.5 6,6.72385763 6,7 C6,7.27614237 6.22385763,7.5 6.5,7.5 L17.5,7.5 C17.7761424,7.5 18,7.27614237 18,7 C18,6.72385763 17.7761424,6.5 17.5,6.5 L6.5,6.5 Z M6.5,9.5 C6.22385763,9.5 6,9.72385763 6,10 C6,10.2761424 6.22385763,10.5 6.5,10.5 L17.5,10.5 C17.7761424,10.5 18,10.2761424 18,10 C18,9.72385763 17.7761424,9.5 17.5,9.5 L6.5,9.5 Z M6.5,12.5 C6.22385763,12.5 6,12.7238576 6,13 C6,13.2761424 6.22385763,13.5 6.5,13.5 L17.5,13.5 C17.7761424,13.5 18,13.2761424 18,13 C18,12.7238576 17.7761424,12.5 17.5,12.5 L6.5,12.5 Z"
          fill="inherit"
          id={`path-1-comment${unique}`}
        />
      </g>
    </g>
  </svg>
)

Comment.propTypes = propTypes
export default Comment
