import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const CreditCardPayment = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/shopping/credit_card_payment-credit-card-payment${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`simplified_icons-general-credit-card-payment-credit-card-payment${unique}`}
        xlinkHref={`#path-1-credit-card-payment${unique}`}
      />
      <g
        fill="inherit"
        id={`colors/core/gunmetal-credit-card-payment${unique}`}
      >
        <path
          d="M21.4807766,8 L21.4807766,7 C21.4807766,6.44771525 21.0676368,6 20.558003,6 L3.94807766,6 C3.43844386,6 3.02530403,6.44771525 3.02530403,7 L3.02530403,8 L21.4807766,8 Z M21.4807766,11 L3.02530403,11 L3.02530403,17 C3.02530403,17.5522847 3.43844386,18 3.94807766,18 L20.558003,18 C21.0676368,18 21.4807766,17.5522847 21.4807766,17 L21.4807766,11 Z M3.86418915,5 L20.6418915,5 C21.6714547,5 22.5060806,5.8954305 22.5060806,7 L22.5060806,17 C22.5060806,18.1045695 21.6714547,19 20.6418915,19 L3.86418915,19 C2.83462591,19 2,18.1045695 2,17 L2,7 C2,5.8954305 2.83462591,5 3.86418915,5 Z"
          fill="inherit"
          id={`path-1-credit-card-payment${unique}`}
        />
      </g>
    </g>
  </svg>
)

CreditCardPayment.propTypes = propTypes
export default CreditCardPayment
