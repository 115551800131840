import React, {ReactElement, useState, useEffect, useCallback, useMemo} from "react"
import {MegaMenuProps} from "./MegaMenu.interface"
import {MegaMenuItemType} from "../../utils/common.interface"
import MegaMenuLevel3 from "../../molecules/MegaMenuLevel3/MegaMenuLevel3"
import TabMenu from "../../molecules/TabMenu/TabMenu"
import Touts from "../../molecules/Touts/Touts"
import {megaMenuToutClass} from "./types"

const MegaMenu = ({
    activeMenuItem,
    handleMegaMenuItemClick,
}: MegaMenuProps): ReactElement => {
    const {showImmediateChildrenAsTab, children = []} = activeMenuItem
    const [megaMenuSelectedItem, setMegaMenuSelectedItem] = useState<MegaMenuItemType | null>(null)

    useEffect(() => {
        setMegaMenuSelectedItem(showImmediateChildrenAsTab ? children[0] : activeMenuItem)
    }, [activeMenuItem, setMegaMenuSelectedItem])

    const updateSelectedMegaMenuItem = useCallback((item: MegaMenuItemType) => {
        setMegaMenuSelectedItem(item)
    }, [])

    
    const renderMegaMenu = () => {
        const megaMenuLevel3ItemLength = megaMenuSelectedItem?.children?.length || 0
        
        // Calculate col span for tout to allow it to take up remaining col space in grid
        const toutColSpan = megaMenuLevel3ItemLength === 0 ? 4 : 4 - (megaMenuLevel3ItemLength % 4)

        const toutColSpanMap: any = {
            1: "col-1",
            2: "col-2",
            3: "col-3",
            4: "col-4",
        }

        const toutClass = megaMenuToutClass({colSpan: toutColSpanMap[toutColSpan]})

        return (
            <div className="w-full border-t border-theme-neutral-300">
                <div className="grid grid-cols-4 gap-y-m gap-x-[24px] m-auto w-full max-w-[1680px] px-s sm:px-l xl:px-[130px] py-l">
                    {megaMenuSelectedItem?.children && megaMenuSelectedItem.children.map((item: MegaMenuItemType) => (
                        <MegaMenuLevel3
                            key={`mega-menu-item-${item.title}`}
                            item={item}
                            handleHeaderClick={() => null}
                            handleItemClick={handleMegaMenuItemClick}
                        />
                    ))}
                    {megaMenuSelectedItem?.hasTouts && megaMenuSelectedItem.touts && (
                        <div className={toutClass}>
                            <Touts touts={megaMenuSelectedItem.touts} />
                        </div>
                    )}
                </div>
            </div>
        )
    }

    return (
        <>
            {showImmediateChildrenAsTab && megaMenuSelectedItem && <TabMenu tabMenuItems={children} selectedItem={megaMenuSelectedItem} handleItemClick={updateSelectedMegaMenuItem} />}
            {megaMenuSelectedItem && renderMegaMenu()}
        </>
    )
}

export default MegaMenu
