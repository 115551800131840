import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Copyright = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/alert/copyright-copyright${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`grey600-copyright${unique}`}
        xlinkHref={`#path-1-copyright${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-copyright${unique}`}>
        <path
          d="M12.055,17 C9.22525,17 7,14.829625 7,12 C7,9.170375 9.22525,7 12.055,7 C14.142875,7 16.011,8.098875 16.642875,9.98075 C16.684125,10.1045 16.697875,10.228 16.49175,10.283 L15.118125,10.626375 C14.912125,10.667625 14.857125,10.530125 14.815875,10.434125 C14.321375,9.431375 13.401125,8.70325 12.068625,8.70325 C10.24175,8.70325 8.923125,10.145625 8.923125,12 C8.923125,13.854375 10.241875,15.29675 12.068625,15.29675 C13.401125,15.29675 14.321375,14.568625 14.815875,13.565875 C14.857125,13.46975 14.912125,13.332375 15.118125,13.373625 L16.491875,13.717 C16.69775,13.772 16.684125,13.895625 16.642875,14.01925 C16.011,15.901125 14.142875,17 12.055,17 Z M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 Z"
          fill="inherit"
          id={`path-1-copyright${unique}`}
        />
      </g>
    </g>
  </svg>
)

Copyright.propTypes = propTypes
export default Copyright
