import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware, { END } from "redux-saga";

import rootReducer from "lib/redux";
import initListeners from "lib/client/init-listeners";

// A nice helper to tell us if we're on the server
export const isServer = !(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export default (initialState = {}) => {
  const enhancers = [];

  // Dev tools are helpful
    // eslint-disable-next-line no-underscore-dangle
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === "function") {
      enhancers.push(
        devToolsExtension({
          trace: true,
          traceLimit: 25,
        })
      );
    }

  const sagaMiddleware = createSagaMiddleware();
  const composedEnhancers = compose(
    applyMiddleware(sagaMiddleware),
    ...enhancers
  );

  // Create the store
  const store = createStore(rootReducer, initialState, composedEnhancers);

  if (module.hot) {
    module.hot.accept("lib/redux", () => {
      store.replaceReducer(rootReducer);
    });
  }

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  initListeners(store);
  return store;
};
