import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const ShoppingCart = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id="Glyphs/Shopping/*Shopping_Cart"
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-shopping-cart${unique}`}
        xlinkHref={`#path-1-shopping-cart${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-shopping-cart${unique}`}>
        <path
          d="M6.49712449,5.00515121 L20.1787387,5.07891399 C20.4548771,5.08040276 20.6775245,5.30546401 20.6760358,5.58160237 C20.6758423,5.61749624 20.6717842,5.65326491 20.6639317,5.68828983 L18.8729413,13.6767618 C18.8251402,13.8899722 18.6444292,14.0474489 18.4266848,14.0656424 L8.22842475,14.9177528 C8.22997344,14.9261908 8.23152283,14.9346319 8.23307292,14.9430762 C8.3241232,15.5319313 7.34218616,15.870839 7.00963622,16.3059133 L7.00963622,16.537479 C14.325532,16.537479 17.9870538,16.537479 17.9942017,16.537479 C18.0049235,16.537479 18.9924316,16.5239851 18.9924316,17.2507865 C18.9924316,17.9775879 18.270344,17.9997856 17.9942017,17.9997856 L5.99382404,17.9997856 C5.74585186,17.9997856 5.50732543,17.8817827 5.32739258,17.6700912 C4.93926427,17.2134571 4.92299564,16.4529233 5.29105558,15.9713914 C5.74734133,15.5450555 6.06294759,15.2022837 6.23787435,14.9430762 C6.50026449,14.5542649 6.26822444,13.7125515 6.23787435,13.5162665 L4.4923294,3.94801947 L3.02459717,3.94801947 C2.05737305,3.94801947 2,3.29685724 2,2.95426081 C2,2.61166439 2.04473877,1.9999908 3.02459717,1.9999908 C4.09873454,1.9999908 4.90433757,1.9999908 5.44140625,1.9999908 C5.64017741,1.9999908 6.03309722,1.96853397 6.13832283,2.6846088 C6.2407965,3.38195626 6.36039706,4.15547039 6.49712449,5.00515121 Z M8.5,21.9997856 C7.67157288,21.9997856 7,21.3282127 7,20.4997856 C7,19.6713585 7.67157288,18.9997856 8.5,18.9997856 C9.32842712,18.9997856 10,19.6713585 10,20.4997856 C10,21.3282127 9.32842712,21.9997856 8.5,21.9997856 Z M16.5,21.9997856 C15.6715729,21.9997856 15,21.3282127 15,20.4997856 C15,19.6713585 15.6715729,18.9997856 16.5,18.9997856 C17.3284271,18.9997856 18,19.6713585 18,20.4997856 C18,21.3282127 17.3284271,21.9997856 16.5,21.9997856 Z"
          fill="inherit"
          id={`path-1-shopping-cart${unique}`}
        />
      </g>
    </g>
  </svg>
)

ShoppingCart.propTypes = propTypes
export default ShoppingCart
