import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const TriangleRight = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/triangle_right-triangle-right${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`triangle-triangle-right${unique}`}
        xlinkHref={`#path-1-triangle-right${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-triangle-right${unique}`}>
        <path
          d="M6.83935955,3.09245624 C6.62114638,2.9232297 6.30706443,2.96294121 6.13783789,3.18115438 C6.06984778,3.26882583 6.03294779,3.37662049 6.03294779,3.48756614 L6.03294779,20.4465852 C6.03294779,20.7227276 6.25680541,20.9465852 6.53294779,20.9465852 C6.64389344,20.9465852 6.7516881,20.9096852 6.83935955,20.8416951 L17.773464,12.3621856 C17.9916771,12.192959 18.0313887,11.8788771 17.8621621,11.6606639 C17.8364367,11.6274917 17.8066362,11.5976912 17.773464,11.5719658 L6.83935955,3.09245624 Z"
          fill="inherit"
          id={`path-1-triangle-right${unique}`}
        />
      </g>
    </g>
  </svg>
)

TriangleRight.propTypes = propTypes
export default TriangleRight
