import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const CaretUp = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/caret_up-caret-up${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <g fill="inherit" id={`colors/core/gunmetal-caret-up${unique}`}>
        <path
          d="M16.7855576,20.1209054 L16.1211626,20.7853004 C15.9259005,20.9805625 15.609318,20.9805625 15.4140558,20.7853004 L7.53859885,12.9098434 C7.21669494,12.5858963 7.05574299,12.2619491 7.05574299,11.938002 C7.05574299,11.6140549 7.21669494,11.2901077 7.53859885,10.9661606 L15.4140558,3.09070362 C15.609318,2.89544147 15.9259005,2.89544147 16.1211626,3.09070362 L16.7853004,3.75535574 C16.9804621,3.9505175 16.9805772,4.26690173 16.7855574,4.46220535 L9.32113867,11.938002 L16.7855574,19.4137987 C16.9805772,19.6091023 16.9804621,19.9254865 16.7853004,20.1206483 Z"
          fill="inherit"
          id={`path-1-caret-up${unique}`}
          transform="translate(11.993745, 11.938002) scale(-1, 1) rotate(-270.000000) translate(-11.993745, -11.938002) "
        />
      </g>
    </g>
  </svg>
)

CaretUp.propTypes = propTypes
export default CaretUp
