import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const ArrowCircleUp = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/arrow_circle_up-arrow-circle-up${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`ic_cancel_24px-arrow-circle-up${unique}`}
        xlinkHref={`#path-1-arrow-circle-up${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-arrow-circle-up${unique}`}>
        <path
          d="M12,2 C17.53,2 22,6.47 22,12 C22,17.53 17.53,22 12,22 C6.47,22 2,17.53 2,12 C2,6.47 6.47,2 12,2 Z M11.02599,9.22177287 L11.02599,17.439223 C11.02599,17.7153654 11.2498477,17.939223 11.52599,17.939223 L12.4655864,17.939223 C12.7417288,17.939223 12.9655864,17.7153654 12.9655864,17.439223 L12.9655864,9.22585932 L16.4695417,12.7298146 C16.6648038,12.9250767 16.9813863,12.9250767 17.1766484,12.7298146 L17.8410434,12.0654196 C18.0363055,11.8701575 18.0363055,11.553575 17.8410434,11.3583128 L12.9655864,6.48285586 C12.6416393,6.16095195 12.3176921,6 11.993745,6 C11.6697979,6 11.3458507,6.16095195 11.0219036,6.48285586 L6.14644661,11.3583128 C5.95118446,11.553575 5.95118446,11.8701575 6.14644661,12.0654196 L6.81084156,12.7298146 C7.0061037,12.9250767 7.32268619,12.9250767 7.51794834,12.7298146 L11.02599,9.22177287 Z"
          fill="inherit"
          id={`path-1-arrow-circle-up${unique}`}
        />
      </g>
    </g>
  </svg>
)

ArrowCircleUp.propTypes = propTypes
export default ArrowCircleUp
