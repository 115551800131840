import React from "react"
import { string } from "prop-types"

const propTypes = {
  unique: string,
}
const Hamburger = (props) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 12H20M4 6H20M4 18H20" stroke="#151515" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

)

Hamburger.propTypes = propTypes
export default Hamburger