import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const CitySmart = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/applications/city-smart-city-smart${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-city-smart${unique}`}
        xlinkHref={`#path-1-city-smart${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-city-smart${unique}`}>
        <path
          d="M4.5,17 L6,17 L6,10 L8,10 L9,11.3333333 L9,19.3333333 L12,19.3333333 L12,10 L13,10 L13,7.33333333 L15,7.33333333 L15,10 L16,10 L16,18 L18,18 L18,14 L19.5547002,15.0364668 C19.8328989,15.2219326 20,15.5341639 20,15.8685171 L20,21.5 C20,21.7761424 19.7761424,22 19.5,22 L4.5,22 C4.22385763,22 4,21.7761424 4,21.5 L4,17.5 C4,17.2238576 4.22385763,17 4.5,17 Z M14,6.09000015 C13.5857864,6.09000015 13.25,5.75421371 13.25,5.34000015 C13.25,4.92578659 13.5857864,4.59000015 14,4.59000015 C14.4142136,4.59000015 14.75,4.92578659 14.75,5.34000015 C14.75,5.75421371 14.4142136,6.09000015 14,6.09000015 Z M16.3199359,7.72687094 L15.3918875,6.76952881 C15.7784975,6.39474867 16,5.88249136 16,5.33333333 C16,4.22876383 15.1045695,3.33333333 14,3.33333333 C12.8954305,3.33333333 12,4.22876383 12,5.33333333 C12,5.89379532 12.2308117,6.41571811 12.6315729,6.79190829 L11.7190364,7.76404762 C11.0524984,7.13837576 10.6666667,6.2659138 10.6666667,5.33333333 C10.6666667,3.49238417 12.1590508,2 14,2 C15.8409492,2 17.3333333,3.49238417 17.3333333,5.33333333 C17.3333333,6.24704728 16.9630104,7.10347388 16.3199359,7.72687094 Z"
          fill="inherit"
          id={`path-1-city-smart${unique}`}
        />
      </g>
    </g>
  </svg>
)

CitySmart.propTypes = propTypes
export default CitySmart
