import React, { Component } from "react"
import GlobalFooterContainer from "./GlobalFooterContainer"
import { MapTo } from "@adobe/aem-react-editable-components"

const GlobalFooterEditConfig = {
  emptyLabel: "GlobalFooter",

  isEmpty: function (props) {
    return (
      !props ||
      !props.footerdisclaimer ||
      props.footerdisclaimer.trim().length < 1
    )
  },
}

export class FooterContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
    }
  }
  render() {
    const {isDdp} = this.props
    return (<GlobalFooterContainer isDdp={isDdp} />)
  }
}

export default MapTo(
  "qcomm-martech/components/content/globalfooter/v1/globalfooter"
)(FooterContainer, GlobalFooterEditConfig)
