import React, {useRef, useState} from "react"
import {bool} from "prop-types"

import CtaLink from "components/cta-link"
import styled from "styled-components"
import Icon from "../../glyph"
import {colors} from "lib/styles"
import {useSelector} from "react-redux";
import {IS_DDP_TEMPLATE} from "../../../lib/redux/reducers/globals/globals-selectors";


const propTypes = {
  isVideo: bool
}

export const CloseIcon = styled(Icon)`
  cursor: pointer;
  fill: ${props => colors("videosOverlayTextColor", props)};
  height: 30px;
  position: absolute;
  right: 3%;
  top: 5%;
  width: 30px;
`

const CtaGroupItem = ({ ...rest}) => {

  const isDdp = useSelector(IS_DDP_TEMPLATE)

  const [showFgVideoModal, setShowFgVideoModal] = useState(false)
  const toggleFgVideo = () => {
    setShowFgVideoModal(!showFgVideoModal)
  }

  return  <CtaLink {...rest} />
}
CtaGroupItem.propTypes = propTypes

export default CtaGroupItem
