import React from "react"
import { string } from "prop-types"


const propTypes = {
  unique: string
}

const InstagramWOS = ({ unique = "", ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.061 17.5003H6.95581C4.61971 17.5003 2.70837 15.589 2.70837 13.2529V6.74741C2.70837 4.41134 4.61971 2.5 6.95581 2.5H13.061C15.3971 2.5 17.3084 4.41134 17.3084 6.74741V13.2529C17.3084 15.589 15.3971 17.5003 13.061 17.5003ZM13.061 16.378C13.891 16.378 14.6745 16.0516 15.2671 15.4591C15.8597 14.8665 16.186 14.083 16.186 13.2529V6.74741C16.186 5.91738 15.8597 5.13387 15.2671 4.5413C14.6745 3.9487 13.891 3.62235 13.061 3.62235H6.95581C6.12576 3.62235 5.34228 3.9487 4.74968 4.5413C4.15707 5.13387 3.83072 5.91738 3.83072 6.74741V13.2529C3.83072 14.083 4.15707 14.8665 4.74968 15.4591C5.34228 16.0516 6.12576 16.378 6.95581 16.378H13.061ZM10.0309 13.9627C12.2514 13.9627 14.0514 12.1627 14.0514 9.94225C14.0514 7.72188 12.2514 5.92188 10.0309 5.92188C7.81053 5.92188 6.01057 7.72188 6.01057 9.94225C6.01057 12.1627 7.81053 13.9627 10.0309 13.9627ZM10.0116 7.31286C8.55764 7.31286 7.37901 8.49152 7.37901 9.94545C7.37901 11.3994 8.55764 12.5781 10.0116 12.5781C11.4655 12.5781 12.6442 11.3994 12.6442 9.94545C12.6442 8.49152 11.4655 7.31286 10.0116 7.31286ZM14.1159 6.81132C14.6416 6.81132 15.0678 6.38509 15.0678 5.85935C15.0678 5.33361 14.6416 4.90737 14.1159 4.90737C13.5901 4.90737 13.1639 5.33361 13.1639 5.85935C13.1639 6.38509 13.5901 6.81132 14.1159 6.81132Z"
     fill={'inherit'} />
  </svg>
)

InstagramWOS.propTypes = propTypes
export default InstagramWOS
