import { useCallback, useEffect, useRef } from "react";
import { useLazyQuery } from "@apollo/client";

function useLazyQueryPromise(query, queryOptions = {}) {
  const [execute, result] = useLazyQuery(query, queryOptions);

  const resolveRef = useRef();

  useEffect(() => {
    if (result.called && !result.loading && resolveRef.current) {
      resolveRef.current(result);
      resolveRef.current = undefined;
    }
  }, [result]);

  const queryLazily = useCallback(
    (options) => {
      execute({ ...options });
      return new Promise((resolve) => {
        resolveRef.current = resolve;
      });
    },
    [execute],
  );

  return [queryLazily, result];
}

export default useLazyQueryPromise;
