import { variant } from "styled-system";

export const h3Variants = variant({
  variants: {
    bold: {
      fontSize: 6,
      fontWeight: 600,
      letterSpacing: 0.3,
      lineHeight: "30px",
    },
  },
});

export const labelVariants = variant({
  variants: {
    large: {
      fontSize: 2,
      fontWeight: 400,
      letterSpacing: [1.5, 1.5, 4],
      lineHeight: "14px",
    },
  },
});

export const bodyVariants = variant({
  variants: {
    intro: {
      fontSize: [4, 4, 4, 4, 4, 5],
      fontWeight: 400,
      letterSpacing: 0.5,
      lineHeight: ["27px", "27px", "27px", "27px", "27px", "30px"],
    },
    legal: {
      fontSize: 1,
      fontWeight: 400,
      letterSpacing: 0.5,
      lineHeight: "15px",
    },
    metadata: {
      bold: {
        fontSize: 1,
        fontWeight: 600,
        letterSpacing: 0.5,
        lineHeight: "15px",
      },
      fontSize: 1,
      fontWeight: 400,
      letterSpacing: 0.5,
      lineHeight: "15px",
    },
    small: {
      fontSize: 2,
      fontWeight: 400,
      letterSpacing: 0.5,
      lineHeight: "18px",
    },
  },
});
