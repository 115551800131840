export const FETCH_DRUPAL_SETTINGS_SUCCESS = "FETCH_DRUPAL_SETTINGS_SUCCESS"

const initialState = {
  admin: {},
}

const reducer = {
  [FETCH_DRUPAL_SETTINGS_SUCCESS]: (state, {payload}) => {
    return {
      ...state.payload,
      ...payload
    }
  },
}

function drupalReducer(state = initialState, action) {
  const func = reducer[action.type]
  return func ? func(state, action) : state
}

export default drupalReducer
