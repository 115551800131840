import styled from "styled-components";
import { a } from "react-spring";

import { defaultSystemProps, shouldForwardProp } from "./shared";

const AnimatedBox = styled(a.div).withConfig({
  shouldForwardProp,
})`
  background-position: center right;
  ${defaultSystemProps};
`;

AnimatedBox.displayName = "Primitives.AnimatedBox";

export default AnimatedBox;
