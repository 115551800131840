import React, { useCallback } from "react";
import { func } from "prop-types";
import { useDispatch } from "react-redux";

import { callIfFunc } from "lib/utils/helpers";
import { EXTERNAL_LINK } from "lib/redux/actions";
import ExternalLinkComponent from "./ExternalLinkComponent";

const ExternalLinkContainer = ({
  onClick,
  linkHandler: linkHandlerOverride,
  ...props
}) => {
  const dispatch = useDispatch();
  const linkHandler = useCallback(
    (link, event) => {
      callIfFunc(onClick, event);
      callIfFunc(linkHandlerOverride, event, props);
      if (typeof linkHandlerOverride !== "function") {
        dispatch({ location: link, type: EXTERNAL_LINK });
      }
    },
    [dispatch, linkHandlerOverride, onClick, props],
  );
  return <ExternalLinkComponent linkHandler={linkHandler} {...props} />;
};
ExternalLinkContainer.propTypes = {
  linkHandler: func,
  onClick: func,
};

export default ExternalLinkContainer;
