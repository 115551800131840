import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const FastForward = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/av/fast_forward-fast-forward${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-fast-forward${unique}`}
        xlinkHref={`#path-1-fast-forward${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-fast-forward${unique}`}>
        <path
          d="M2.86278445,3.1904804 L10.9129922,11.6784328 C11.0963074,11.8717162 11.0958959,12.1747817 10.9120563,12.3675664 L2.86184858,20.8094779 C2.67127784,21.0093214 2.3547846,21.0168384 2.15494115,20.8262676 C2.05599185,20.7319096 2,20.6011466 2,20.4644191 L2,3.53455516 C2,3.25841278 2.22385763,3.03455516 2.5,3.03455516 C2.63719929,3.03455516 2.76837083,3.09093286 2.86278445,3.1904804 Z M13.8627845,3.1904804 L21.9129922,11.6784328 C22.0963074,11.8717162 22.0958959,12.1747817 21.9120563,12.3675664 L13.8618486,20.8094779 C13.6712778,21.0093214 13.3547846,21.0168384 13.1549412,20.8262676 C13.0559919,20.7319096 13,20.6011466 13,20.4644191 L13,3.53455516 C13,3.25841278 13.2238576,3.03455516 13.5,3.03455516 C13.6371993,3.03455516 13.7683708,3.09093286 13.8627845,3.1904804 Z"
          fill="inherit"
          id={`path-1-fast-forward${unique}`}
        />
      </g>
    </g>
  </svg>
)

FastForward.propTypes = propTypes
export default FastForward
