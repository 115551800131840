import React, {ReactElement} from "react"
import {TabMenuItemProps} from "./TabMenuItem.interface"
import {tabMenuItemButtonClass, tabMenuItemTextClass} from "./types"

const TabMenuItem = ({
  item,
  isActive,
  handleClick
}: TabMenuItemProps): ReactElement => {
  const buttonClass = tabMenuItemButtonClass({
    activeState: isActive ? "active" : "inactive",
  })
  const textClass = tabMenuItemTextClass({
    activeState: isActive ? "active" : "inactive",
  })
  return (
    <button aria-labelledby={item.title} className={buttonClass} onClick={() => handleClick(item)}>
      <p className={textClass}>{item.title}</p>
    </button>
  )
}

export default TabMenuItem