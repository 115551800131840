import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}
const User = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
    <path d="M15 19.0001V17.0001C15 15.9393 14.5786 14.9218 13.8284 14.1717C13.0783 13.4216 12.0609 13.0001 11 13.0001H5C3.93913 13.0001 2.92172 13.4216 2.17157 14.1717C1.42143 14.9218 1 15.9393 1 17.0001V19.0001M12 5.00012C12 7.20926 10.2091 9.00012 8 9.00012C5.79086 9.00012 4 7.20926 4 5.00012C4 2.79098 5.79086 1.00012 8 1.00012C10.2091 1.00012 12 2.79098 12 5.00012Z" stroke="#151515" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
  </svg>
)

User.propTypes = propTypes
export default User
