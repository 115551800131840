import React, { useState, useEffect, ReactElement, Fragment } from "react"
import CtaV2Dx from "../../atoms/CtaV2Dx/CtaV2Dx"
import { Icon } from "../../atoms/Icon"
import { cn } from "../../../../lib/utils/cn-helper"
import { useSelector } from "react-redux"
import {
  GET_LOGGED_IN,
  GET_NAVIGATION_MAIN_MENU_PRODUCT_CTA,
} from "../../../../lib/redux/selectors"
import { UserAccountModal } from "../../molecules/UserAccount"
import { Typography } from "../../atoms/Typography"
import Touts from "../../molecules/Touts/Touts"
import { GlobalNavMenuProps } from "../../molecules/GlobalNavMenu/GlobalNavMenu.interface"
import { MegaMenuItemType } from "../../utils/common.interface"
import Link from "components/linkTs"
import { TransitionHeight } from "lib/animations"

export interface NavMenuItemInterface {
  title: string;
  rootItemClicked: () => void;
}

export type SignupPagetype = Pick<NavMenuItemInterface, "rootItemClicked">;

export type GlobalNavMenuMobileType = Pick<GlobalNavMenuProps, "navMenuItems">;

export const NavMenuItemMobile = ({
  title,
  rootItemClicked,
}: NavMenuItemInterface) => (
  <button
    className="flex w-full items-center justify-between border-b border-solid border-theme-neutral-300 py-xs"
    onClick={rootItemClicked}
  >
    <Typography
      children={title}
      className="font-medium"
      variant={"heading-s"}
    />
    <Icon
      className="w-[8px] h-[16px]"
      glyphId="dx-chevron-right-menu"
      variant="blue"
    />
  </button>
)

export const SignupPage = ({
  rootItemClicked,
}: SignupPagetype): ReactElement => {
  const isLoggedIn: any = useSelector(GET_LOGGED_IN)
  return (
    <div className="w-full flex flex-wrap">
      <button
        className="py-xs w-full flex gap-xs items-center"
        onClick={() => {
          rootItemClicked()
        }}
      >
        <Icon
          className="rotate-180 w-[8px] h-[16px]"
          glyphId="dx-chevron-right-menu"
          variant="blue"
        />
        <Typography
          children="Back"
          className="font-medium"
          variant={"heading-s"}
        />
      </button>
      <UserAccountModal className="w-full py-s px-0" isLoggedIn={isLoggedIn} />
    </div>
  )
}

export const AccordianItem = ({
  title,
  children,
  shortUrlPath,
}: any): ReactElement => (
  <>
    <div className="flex justify-between items-center py-xs">
      {shortUrlPath ? (
        <Link
          aria-labelledby={title}
          className="flex w-full justify-between items-center cursor-pointer"
          url={shortUrlPath}
        >
          <Typography
            children={title}
            className="text-theme-neutral-600 "
            variant={"heading-xs"}
          />
          <Icon glyphId="dx-arrow-right" size="large" variant="blue" />
        </Link>
      ) : (
        <Typography
          children={title}
          className="text-theme-neutral-600"
          variant={"heading-xs"}
        />
      )}
    </div>
    <div className="border-l border-solid border-theme-neutral-300 flex flex-col">
      {children?.map(
        ({ title, shortUrlPath }: any): ReactElement => (
          <Link
            key={`${title}-${shortUrlPath}`}
            className="px-s py-xxxs w-full cursor-pointer"
            url={shortUrlPath}
          >
            <Typography
              children={title}
              className="text-theme-neutral-600"
              variant={"body-m"}
            />
          </Link>
        )
      )}
    </div>
  </>
)

const AccordianItemContainer = ({
  rootItem,
  title: accordianTitle,
  children: accordianChildren,
  hasTouts,
  touts: accordianTouts,
  shortUrlPath,
}: MegaMenuItemType & { rootItem: MegaMenuItemType }) => {
  const [isAccordianOpen, setIsAccoridanOpen] = useState<boolean>(false)

  const handleButtonClick = () => setIsAccoridanOpen(!isAccordianOpen)

  return (
    <div className="w-full">
      {rootItem.showImmediateChildrenAsTab ? (
        <>
          <button
            className="w-full flex justify-between items-center py-xs"
            onClick={handleButtonClick}
          >
            <Typography
              children={accordianTitle}
              className="font-medium"
              variant={"heading-s"}
            />
            <Icon
              glyphId={isAccordianOpen ? "dx-minus" : "dx-plus"}
              size="large"
              variant="blue"
            />
          </button>

          <TransitionHeight
            className=""
            config={{ friction: 30, mass: 1, tension: 300 }}
            onAnimationClose={null}
            onAnimationOpen={null}
            overflow="hidden"
            show={isAccordianOpen}
            width={1}
            zIndex={1}
          >
            {accordianChildren &&
              accordianChildren.map(
                ({ title, children, shortUrlPath }: any, index: number) => (
                  <Fragment key={`${title}-${index}`}>
                    <div className="py-xs">
                      <AccordianItem
                        children={children}
                        shortUrlPath={shortUrlPath}
                        title={title}
                      />
                    </div>
                    {hasTouts && accordianTouts && (
                      <div className="py-xs">
                        <Touts touts={accordianTouts} />
                      </div>
                    )}
                  </Fragment>
                )
              )}
          </TransitionHeight>
        </>
      ) : (
        <AccordianItem
          children={accordianChildren}
          shortUrlPath={shortUrlPath}
          title={accordianTitle}
        />
      )}
    </div>
  )
}

export const ProductSection = () => {
  const productCta: any = useSelector(GET_NAVIGATION_MAIN_MENU_PRODUCT_CTA)
  return productCta && productCta?.ctaTitle && productCta?.url ? (
    <div className="w-full bg-white flex flex-wrap border-t border-solid border-theme-neutral-300 py-s max-w-[1680px] px-s sm:px-l desktop:px-[130px]">
      <CtaV2Dx {...productCta} width="full" />
    </div>
  ) : (
    <></>
  )
}

export const GlobalNavMenuMobile: React.FC<GlobalNavMenuMobileType> = ({
  navMenuItems,
}) => {
  const [rootItem, setRootItem] = useState<MegaMenuItemType | null>(null)
  const [animation, setAnimation] = useState<
    "animate-fadeOut" | "animate-fadeIn"
  >("animate-fadeIn")

  const [innerHeight, setInnerHeight] = useState(0)
  useEffect(() => {
    const resizeHandler = () => {
      setInnerHeight(window.innerHeight - 76)
    }
    window.addEventListener("resize", resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener("resize", resizeHandler)
    }
  }, [setInnerHeight])

  const IS_ROOT_ITEM_PRODUCTS = rootItem?.showImmediateChildrenAsTab
  const IS_ROOT_ITEM_ACCOUNT = rootItem?.title === "Account"
  const IS_ROOT_ITEM_EMPTY = rootItem === null

  return (
    <div
      className={cn(
        "z-10 w-full bg-white flex flex-col absolute overflow-y-scroll justify-between"
      )}
      style={{ height: `${innerHeight}px` }}
    >
      <div>
        {!IS_ROOT_ITEM_EMPTY && (
          <div
            className={cn(
              "py-xs w-full text-theme-blue-500 bg-theme-neutral-200 max-w-[1680px] px-s sm:px-l desktop:px-[130px]",
              animation
            )}
          >
            <Typography
              children={rootItem.title}
              className="font-medium"
              variant="heading-s"
            />
          </div>
        )}

        <div className="max-w-[1680px] px-s sm:px-l desktop:px-[130px]">
          {!rootItem && (
            <div className={cn(animation)}>
              {navMenuItems.map((item: MegaMenuItemType, index: number) => (
                <NavMenuItemMobile
                  key={`${item.title}-${index}`}
                  rootItemClicked={(): void => {
                    setRootItem(item)
                    setAnimation("animate-fadeIn")
                  }}
                  title={item.title}
                />
              ))}
              <NavMenuItemMobile
                rootItemClicked={() => {
                  setRootItem({ title: "Account" })
                  setAnimation("animate-fadeIn")
                }}
                title="Account"
              />
            </div>
          )}

          {IS_ROOT_ITEM_ACCOUNT && (
            <div className={cn(animation)}>
              <SignupPage
                rootItemClicked={() => {
                  setRootItem(null)
                  setAnimation("animate-fadeOut")
                }}
              />
            </div>
          )}

          {!IS_ROOT_ITEM_ACCOUNT && !IS_ROOT_ITEM_EMPTY && (
            <div className={cn(animation, "h-full")}>
              <button
                className="py-xs w-full flex gap-xs items-center border-b border-solid border-theme-neutral-300"
                onClick={() => {
                  setRootItem(null)
                  setAnimation("animate-fadeOut")
                }}
              >
                <Icon
                  className="rotate-180 w-[8px] h-[16px]"
                  glyphId="dx-chevron-right-menu"
                  variant="blue"
                />
                <Typography
                  children="Back"
                  className="font-medium"
                  variant={"heading-s"}
                />
              </button>

              {rootItem &&
                rootItem.children?.map(
                  ({ title, ...childItem }, index: number): ReactElement => (
                    <AccordianItemContainer
                      key={`${title}-${index}`}
                      rootItem={rootItem}
                      title={title}
                      {...childItem}
                    />
                  )
                )}
            </div>
          )}
        </div>
      </div>

      {IS_ROOT_ITEM_PRODUCTS && (
        <div className={cn(animation, "bottom-0 sticky")}>
          <ProductSection />
        </div>
      )}
    </div>
  )
}
