import React from "react"
import styled from "styled-components"
import { bool, func, number, oneOfType, string } from "prop-types"
import { css } from "styled-components/macro"

import { HamburgerWosIcon , ControlCloseWosIcon} from "components/glyphs/elements"

import Primitives from "primitives"


const CrossIcon = styled(Primitives.Button)`
width:30px;
height:30px;
border:1px solid #EBEBEB;
`

const hamburgerWosIconCss = css({
  height: "100%",
  position: "relative"
})
const glyphStyles = {
  cursor: "pointer",
  fill: "mainMenuIconFill",
}

const rootIconComponentPropTypes = {
  /** aria close text label for icon button  */
  closeText: string,
  /** bool value to display close or menu text  */
  isActive: oneOfType([bool, number]),
  /** onClick event handler  */
  onClick: func
}
const mainMenuIconCss = css({
  "&:first-child": {
    marginLeft: 0
  },
  "-webkit-backface-visibility": "visible"
})

const RootIconComponent = ({ closeText, isActive, onClick}) => {
  return isActive ? (
    <CrossIcon 
      aria-label={isActive ? closeText : "Menu"}
      onClick={onClick}
      position="relative"
      tabIndex="0"
      width="28px"
      wosFlag
    >
      <ControlCloseWosIcon />
    </CrossIcon>
  ) : (
    <Primitives.Button
      aria-label={isActive ? closeText : "Menu"}
      css={mainMenuIconCss}
      display="flex"
      onClick={onClick}
      position="relative"
      tabIndex="0"
      wosFlag
      {...glyphStyles}
      height="14px"
      width="17px"
    >
      <HamburgerWosIcon css={hamburgerWosIconCss} />
    </Primitives.Button>
  )

}

RootIconComponent.propTypes = rootIconComponentPropTypes

export default RootIconComponent
