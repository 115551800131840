import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const CloudDone = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/file/cloud_done-cloud-done${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-cloud-done${unique}`}
        xlinkHref={`#path-1-cloud-done${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-cloud-done${unique}`}>
        <path
          d="M5.39999989,19.3999996 C2.96994705,19.3999996 1,17.4300526 1,14.9999997 C1,12.5699469 2.96994705,10.5999998 5.39999989,10.5999998 C5.44619388,10.5999998 5.49222161,10.6007117 5.5380727,10.602125 C6.02578023,8.70329692 7.74901122,7.29999992 9.79999978,7.29999992 C10.2158082,7.29999992 10.6181453,7.35767786 10.9994467,7.46546953 C11.427936,5.48432892 13.190626,4 15.2999996,4 C17.7300525,4 19.6999995,5.96994705 19.6999995,8.39999989 C19.6999995,8.85832112 19.6299245,9.30027545 19.4999042,9.71573326 C21.5262738,10.3355605 22.9999995,12.2206766 22.9999995,14.4499997 C22.9999995,16.9979042 21.0749723,19.0961944 18.5999996,19.3697888 L18.5999996,19.3999996 L5.39999989,19.3999996 Z M9.12106553,14.8779481 C9.39742621,15.1605897 9.81196724,15.5845521 10.3646886,16.1498354 C10.4092295,16.1954006 10.469647,16.2209998 10.5326456,16.2209998 C10.5956443,16.2209998 10.6560618,16.1954006 10.7006027,16.1498354 L15.7815063,10.9534567 C15.9715018,10.7591431 15.9715018,10.4486527 15.7815063,10.2543391 L15.1403153,9.5985756 C15.137809,9.59601237 15.1352753,9.59347616 15.1327145,9.59096743 C14.9354566,9.39772162 14.6188908,9.40097405 14.425645,9.59823193 L10.5326456,13.5720558 L9.0234319,12.0487577 C9.02193346,12.0472452 9.02042538,12.0457424 9.01890774,12.0442492 C8.82206464,11.850581 8.50549261,11.8531546 8.31182432,12.0499977 L7.67037342,12.701964 C7.4788059,12.8966719 7.47895445,13.2090839 7.67070704,13.4036096 L7.67069277,13.4036236 C8.39748661,14.140928 8.8809442,14.6323695 9.12106553,14.8779481 Z"
          fill="inherit"
          id={`path-1-cloud-done${unique}`}
        />
      </g>
    </g>
  </svg>
)

CloudDone.propTypes = propTypes
export default CloudDone
