import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Bluetooth = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/technology/bluetooth-bluetooth${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`path-7-bluetooth${unique}`}
        xlinkHref={`#path-1-bluetooth${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-bluetooth${unique}`}>
        <path
          d="M12.9836177,17.6997962 L14.6035675,16.0111427 L12.9875894,14.3795639 L12.9836177,17.6997962 Z M14.5854255,8.00036092 L12.9971183,6.41367385 L12.9933158,9.59247065 L14.5854255,8.00036092 Z M10.6083903,11.9773961 L10.4897871,11.8576478 L6.65234453,8.06260796 C6.45600062,7.8684336 6.45424186,7.55185599 6.64841622,7.35551209 L7.35158056,6.64448472 C7.54575492,6.44814082 7.86233252,6.44638205 8.05867643,6.64055641 C8.0586775,6.64055748 8.05867857,6.64055854 8.05867965,6.6405596 L10.9933737,9.5428851 L11.0014412,2.79868521 C11.0017715,2.52254304 11.2258968,2.29895335 11.5020389,2.29928368 C11.6343515,2.29944195 11.7612069,2.35203818 11.8548138,2.44554957 L17.061021,7.64608569 C17.2563828,7.84124818 17.2565443,8.15783062 17.0613818,8.35319238 C17.0613217,8.35325255 17.0612616,8.3533127 17.0612015,8.35337284 L13.4300143,11.9841993 L17.0534639,15.6425722 C17.2442846,15.8352321 17.2467567,16.1448811 17.0590361,16.3405631 L11.8399659,21.7809795 C11.6487996,21.9802533 11.3322853,21.9868258 11.1330115,21.7956595 C11.0345683,21.7012215 10.9789858,21.5706611 10.979149,21.4342442 L10.9875316,14.426682 L8.06066017,17.3535534 C7.86539803,17.5488155 7.54881554,17.5488155 7.35355339,17.3535534 L6.64644661,16.6464466 C6.45118446,16.4511845 6.45118446,16.134602 6.64644661,15.9393398 L10.6083903,11.9773961 Z"
          fill="inherit"
          id={`path-1-bluetooth${unique}`}
        />
      </g>
    </g>
  </svg>
)

Bluetooth.propTypes = propTypes
export default Bluetooth
