export const UPDATE_COVER_DIRECTION = "cover/UPDATE_COVER_DIRECTION"

const initialState = {
  coverDirection: null,
}

const reducer = {
  [UPDATE_COVER_DIRECTION]: (state, {value}) => ({
    ...state,
    ...value,
  }),
}

function coverReducer(state = initialState, action) {
  const func = reducer[action.type]
  return func ? func(state, action) : state
}

export default coverReducer
