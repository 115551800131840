import {gql} from "@apollo/client"

const END_IMPERSONATION_QUERY = gql`
  query EndImpersonation {
    deleteImpersonation {
      status
    }
  }
`
export default END_IMPERSONATION_QUERY
