import React, { useCallback, useContext, useState } from "react"
import { gql, useQuery } from "@apollo/client"

import { GlobalFooter } from "./GlobalFooter"
import QualcommThemeContext from "components/QualcommThemeContext/ThemeContext"
import { useSelector } from "react-redux"
import { GET_CURRENT_THEME } from "lib/redux/selectors"
import ThemeCss from "styles/theme.module.css"

const defaults = {
  componentID: {id: ""},
  footerDisclaimer: "",
  linksSection: [],
  sublinksSection: [],
  sociallinksSection: [],
  countrylinksSection: [],
  languageLabel: "",
  quickLinksSectionList: [],
  description: ""
}
const FOOTER_QUERY = gql`
  query GetFooter {
    getGlobalFooter {
      footerData
    }
  }
`

const GlobalFooterContainer = ({isDdp}) => {
  const currentTheme = useSelector(GET_CURRENT_THEME)
  const [footerProps, setFooterProps] = useState(defaults)
  const value = useContext(QualcommThemeContext)
  
  const themeClass = isDdp ? `${ThemeCss["common-theme"]} ${currentTheme} ${ThemeCss[`${currentTheme}-theme`]} allow-theme developer-detail`:`${ThemeCss["default-existing-theme"]}`


  const footerDataLoaded = useCallback(
    (data) => {
      const {
        componentID,
        footerDisclaimer,
        linksSection,
        sublinksSection,
        sociallinksSection,
        countrylinksSection,
        languageLabel,
        quickLinksSectionList,
        description
      } = data?.getGlobalFooter?.footerData ? data.getGlobalFooter.footerData : {}
      setFooterProps({
        componentID,
        footerDisclaimer,
        linksSection,
        sublinksSection,
        sociallinksSection,
        countrylinksSection,
        languageLabel,
        quickLinksSectionList,
        description
      })
    },
    [setFooterProps]
  )

  useQuery(FOOTER_QUERY, {onCompleted: footerDataLoaded})

  return (<div className={themeClass}><GlobalFooter homePagePath={value?.homePagePath} isDdp={isDdp} {...footerProps} /></div>)
}
export default GlobalFooterContainer
