import { propOr } from "ramda"
import { createSelector } from "reselect"

import { createStateSelector } from "lib/redux/utils"
import { initialScrollCheckpointMap } from "./globals-reducer"

export const DDP_RESOURCE_PATH = "qcomm-martech/components/content/tabs/v3/tabs"

const PAGE_TEMPLATES = {
  DDP: "developer-detail-page-template",
  BASE: "qualcomm-spa-base-page-template"
}

const makeSelector = createStateSelector("globalsReducer")

export const GET_API_HEADERS = makeSelector("headers", {})
export const GET_BASE_URL = makeSelector("baseUrl", null)
export const GET_CONTEXT = makeSelector("context", {})
export const GET_COOKIES_ALLOWED = makeSelector("cookiesAllowed", {})
export const GET_GLOBAL_ERROR_STATE = makeSelector("error", {})
export const GET_INITIAL_SCROLL_POSITION = makeSelector(
  "initialScrollPosition",
  0
)
export const GET_VIEWPORT_HEIGHT = makeSelector("height", 0)
export const GET_VIEWPORT_WIDTH = makeSelector("width", 0)
export const GET_VIEWPORT_ORIENTATION = createSelector(
  GET_VIEWPORT_WIDTH,
  GET_VIEWPORT_HEIGHT,
  (width, height) => (width <= height ? "portrait" : "landscape")
)
export const GET_IS_MOBILE = makeSelector(
  "width",
  true,
  (viewportWidth) => viewportWidth < 950
)
export const GET_IS_ONLY_MOBILE = makeSelector(
  "width",
  true,
  (viewportWidth) => viewportWidth < 550
)
export const GET_IS_TABLET = makeSelector(
  "width",
  true,
  (viewportWidth) => viewportWidth >= 550 && viewportWidth < 950
)

export const GET_IS_SMALL_DESKTOP = makeSelector(
  "width",
  true,
  (viewportWidth) => viewportWidth >= 1024
)

export const GET_IS_DESKTOP = makeSelector(
  "width",
  true,
  (viewportWidth) => viewportWidth >= 1440
)

export const GET_IS_ONLYDESKTOP = makeSelector(
  "width",
  true,
  (viewportWidth) => viewportWidth >= 1440 && viewportWidth < 1800
)

export const GET_IS_LARGEDESKTOP = makeSelector(
  "width",
  true,
  (viewportWidth) => viewportWidth >= 1800
)

export const GET_IS_LAPTOP = makeSelector(
  "width",
  true,
  (viewportWidth) => viewportWidth >= 950 && viewportWidth < 1440
)
export const GET_IS_LAPTOPBGIMAGEPRESET = makeSelector(
  "width",
  true,
  (viewportWidth) => viewportWidth >= 950 && viewportWidth < 1200
)

export const GET_IS_LARGELAPTOP = makeSelector(
  "width",
  true,
  (viewportWidth) => viewportWidth >= 1200 && viewportWidth < 1440
)
export const GET_ONETRUST_CONSENT_STATUS = makeSelector(
  "oneTrustConsentStatus",
  false
)
export const GET_IS_DX = makeSelector("isDX", null)
export const GET_PAGE_VIEW_STATUS = makeSelector("lastPageView", null)
export const GET_ONETRUST_LOADED = makeSelector("oneTrustLoaded", false)
export const GET_PREVIOUS_ROUTE = makeSelector("previousRoute", null)
export const GET_SCROLL_DIRECTION = makeSelector("scrollDirection", {})
export const GET_SCROLL_PERCENTAGE = makeSelector("scrollPercentage", 0)
export const GET_SCROLL_CHECKPOINT = makeSelector("scrollCheckpoint", {})
export const GET_SCROLL_CHECKPOINT_MAP = makeSelector(
  "scrollCheckpointMap",
  initialScrollCheckpointMap
)
export const GET_SEARCH_ORIGIN = makeSelector(
  "searchOrigin",
  window?.location?.pathname || ""
)
export const GET_ROOT_URL = makeSelector(
  "headers",
  {},
  ({ host, "x-forwarded-host": forwardedHost = null }) => forwardedHost || host
)
export const GET_SHARE_OVERLAY_OPEN = makeSelector("shareOverlayOpen", false)
export const GET_IS_IOS = makeSelector("headers", false, (headers) => {
  const deviceUA = propOr("", "deviceUA", headers)
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(deviceUA)
})

export const GET_PRODUCT_TITLE = makeSelector("producttitle", "")

export const GET_MODEL_DATA = makeSelector("pagemodeldata", {})

/* TODO: Change variable IS_DDP_TEMPLATE to IS_THEMING ENABLED & IS_DDP_TEMPLATE_REAL to IS_DDP_TEMPLATE */
const isThemeEnabled = (pageModelData) => {
  //check whether theme is enabled from ddp component
  const componentList = pageModelData?.tabsData?.responsivegrid
    ? pageModelData?.tabsData?.responsivegrid[":items"]
    : null
  if (componentList) {
    return Object.keys(componentList).reduce((acc, key) => {
      return componentList[`${key}`]?.[":type"] === DDP_RESOURCE_PATH
        ? acc || componentList[`${key}`]?.allowTheme
        : acc
    }, false)
  }
  return false
}
export const isDdp = (pageModelData) =>
  (pageModelData && pageModelData.pageTemplate === PAGE_TEMPLATES.DDP) ||
  isThemeEnabled(pageModelData)
export const IS_DDP_TEMPLATE = makeSelector(
  "pagemodeldata",
  {},
  (pageModelData) => {
    return isDdp(pageModelData)
  }
)
export const IS_NON_DDP_TEMPLATE = makeSelector(
  "pagemodeldata",
  {},
  (pageModelData) => {
    return pageModelData && pageModelData.pageTemplate !== PAGE_TEMPLATES.DDP
  }
)
export const IS_CTA_V2 = makeSelector("pagemodeldata", {}, (pageModelData) => {
  return pageModelData && pageModelData.pageTemplate === PAGE_TEMPLATES.DDP
})
export const GET_DISQUSSHORT_NAME = makeSelector("disqusShortName", "")

export const TYPE_FLEX_CONTAINER = "flex-container"
