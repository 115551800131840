import React, { ReactElement, ReactNode, useCallback } from "react"
import { func } from "prop-types"
import { useDispatch } from "react-redux"

import { callIfFunc } from "lib/utils/helpers"
import { INTERNAL_LINK } from "lib/redux/actions"
import InternalLinkComponent from "./InternalLinkComponent"


interface InternalLinkContainerType {
  className?: string | undefined;
  children: ReactNode;
  linkHandler?: (event: MouseEvent) => void;
  onClick?: (event: MouseEvent) => void;
  url?: string | undefined; 
}

const InternalLinkContainer = ({
  className,
  children,
  onClick,
  linkHandler: linkHandlerOverride,
  url,
  ...props
}: InternalLinkContainerType): ReactElement => {
  const dispatch = useDispatch()
  const linkHandler = useCallback(
    (link, event) => {
      callIfFunc(onClick, event)
      callIfFunc(linkHandlerOverride, event, props)
      if (typeof linkHandlerOverride !== "function") {
        dispatch({ location: link, type: INTERNAL_LINK })
      }
    },
    [dispatch, linkHandlerOverride, onClick, props],
  )
  return <InternalLinkComponent className={className} linkHandler={linkHandler} url={url} {...props}>{children}</InternalLinkComponent>
}
InternalLinkContainer.propTypes = {
  linkHandler: func,
  onClick: func,
}

export default InternalLinkContainer
