import {SET_LOGIN_STATUS} from "lib/redux/reducers/data/data-reducer"
import {generateGuid, hasData} from "lib/utils/helpers"

export const USER_INFO_LOADED = "USER_INFO_LOADED"
export const USER_UPDATE_ACTIVE_AGREEMENTS = "USER_UPDATE_ACTIVE_AGREEMENTS"
export const SET_USER_INFO_LOADING_COMPLETED = "SET_USER_INFO_LOADING_COMPLETED"

const initialState = {
  loggingIn: true,
  qlaLoading: true,
  qlaStatus: {},
  sessionGuid: "",
  signedAgreements: [],
  unsignedAgreements: null,
  userInfo: {},
  isUserInfoLoadingCompleted: false
}

const reducer = {
  // value === hasAuthCookie
  [SET_LOGIN_STATUS]: (state, {value}) => {
    return {...state, loggingIn: value, sessionGuid: generateGuid()}
  },
  [SET_USER_INFO_LOADING_COMPLETED]: (state, {value}) => {
    return {...state, isUserInfoLoadingCompleted: value}
  },
  [USER_INFO_LOADED]: (state, {userInfo, reset = false}) => {
    const unsignedAgreements =
      hasData(state?.unsignedAgreements) && !reset
        ? state?.unsignedAgreements
        : {
          qla: userInfo?.qlaSigned,
          sra: userInfo?.sraSigned,
          ...userInfo?.activeAgreements,
        }
    return {
      ...state,
      loggingIn: false,
      unsignedAgreements,
      userInfo,
      isUserInfoLoadingCompleted: true,
    }
  },
  [USER_UPDATE_ACTIVE_AGREEMENTS]: (state, {agreementType}) => {
    return {
      ...state,
      signedAgreements: [...state?.signedAgreements, agreementType],
    }
  },
}

function userReducer(state = initialState, action) {
  const func = reducer[action.type]
  return func ? func(state, action) : state
}

export default userReducer
