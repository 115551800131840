import React, {memo, useContext} from "react"
import {bool, func, string} from "prop-types"

import Autosuggest from "./autosuggest"
import GlobalSearchBar from "./search-bar"
import Primitives from "primitives"
import QualcommThemeContext from "components/QualcommThemeContext/ThemeContext"
import {GET_IS_LAPTOP,GET_IS_DESKTOP, GET_IS_MOBILE, GET_IMPERSONATION_STATUS} from "lib/redux/selectors"
import { useSelector } from "react-redux"
import SearchStyles from "./search-bar.module.css"

const propTypes = {
  fixed: bool,
  isOnSearchPage: bool,
  isSearchActive: bool,
  isSearchFocused: bool,
  onSearchBlur: func,
  onSearchChange: func,
  onSearchFocus: func,
  searchInputChanged: bool,
  searchValue: string,
  setActiveCategory: func,
  setUrlQuery: func,
  urlQuery: string,
  userHasTyped: bool,
}

/**
 * @return {null}
 */
function GlobalSearchNav({
  fixed,
  isOnSearchPage,
  isSearchActive,
  isSearchFocused,
  onSearchBlur,
  onSearchChange,
  onSearchFocus,
  searchValue,
  searchInputChanged,
  setActiveCategory,
  setUrlQuery,
  urlQuery,
  ...props
}) {

  const value = useContext(QualcommThemeContext)
  const isDesktop = useSelector(GET_IS_DESKTOP)
  const isLaptop = useSelector(GET_IS_LAPTOP)
  const isMobile = useSelector(GET_IS_MOBILE)
  const isWosFlagDesktop =  value?.wosFlag && (isDesktop  || isLaptop)
  const isWosFlagMobile = isMobile &&  value?.wosFlag
   
  const isImpersonating = useSelector(GET_IMPERSONATION_STATUS)
  const searchTop = isImpersonating ? "41px":"-1px"

  if (isWosFlagDesktop && !isOnSearchPage) {
    return null
  }

  if (isWosFlagDesktop && isOnSearchPage) {
    return (
      <div data-test-id="gs-nav-wrapper" className={SearchStyles.searchBar}>
        <div className={SearchStyles.searchBarWrapper}>
          <div className="grid-container">
            <div className={SearchStyles.searchBarContainer}>
              <GlobalSearchBar
                {...props}
                isOnSearchPage={isOnSearchPage}
                isSearchActive={isSearchActive}
                onSearchBlur={onSearchBlur}
                onSearchChange={onSearchChange}
                onSearchFocus={onSearchFocus}
                onSearchSubmit={setUrlQuery}
                urlQuery={urlQuery}
                wosFlagDesktop={isWosFlagDesktop}
                wosFlagMobile={isWosFlagMobile}
                wosFlag={value?.wosFlag}
              />
              {isOnSearchPage || isSearchActive  ? (
                <Primitives.Box position="absolute" left="0" top="100%">
                  <Autosuggest
                    {...props}
                    isSearchFocused={isSearchFocused}
                    searchInputChanged={searchInputChanged}
                    searchValue={searchValue}
                    setActiveCategory={setActiveCategory}
                    setUrlQuery={setUrlQuery}
                    wosFlagDesktop={isWosFlagDesktop}
                    wosFlagMobile={isWosFlagMobile}
                  />
                </Primitives.Box>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <Primitives.Box
      data-test-id="gs-nav-wrapper"
      position="absolute"
      width={1}
      zIndex={1}
    >
      <GlobalSearchBar
        {...props}
        isOnSearchPage={isOnSearchPage}
        isSearchActive={isSearchActive}
        onSearchBlur={onSearchBlur}
        onSearchChange={onSearchChange}
        onSearchFocus={onSearchFocus}
        onSearchSubmit={setUrlQuery}
        urlQuery={urlQuery}
        wosFlagDesktop={isWosFlagDesktop}
        wosFlagMobile={isWosFlagMobile}
        wosFlag={value?.wosFlag}
      />
      {isOnSearchPage || isSearchActive  ? (
        <Autosuggest
          {...props}
          isSearchFocused={isSearchFocused}
          searchInputChanged={searchInputChanged}
          searchValue={searchValue}
          setActiveCategory={setActiveCategory}
          setUrlQuery={setUrlQuery}
          wosFlagDesktop={isWosFlagDesktop}
          wosFlagMobile={isWosFlagMobile}
        />
      ) : null}
    </Primitives.Box>
  )
}

GlobalSearchNav.propTypes = propTypes

export default memo(GlobalSearchNav)
