import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Chip = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id="Glyphs/Products/*Chip"
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-chip${unique}`}
        xlinkHref={`#path-1-chip${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-chip${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M5.5,5 C5.22385763,5 5,5.22385763 5,5.5 L5,18.5 C5,18.7761424 5.22385763,19 5.5,19 L18.5,19 C18.7761424,19 19,18.7761424 19,18.5 L19,5.5 C19,5.22385763 18.7761424,5 18.5,5 L5.5,5 Z M8,9 C7.44771525,9 7,8.55228475 7,8 C7,7.44771525 7.44771525,7 8,7 C8.55228475,7 9,7.44771525 9,8 C9,8.55228475 8.55228475,9 8,9 Z M12,9 C11.4477153,9 11,8.55228475 11,8 C11,7.44771525 11.4477153,7 12,7 C12.5522847,7 13,7.44771525 13,8 C13,8.55228475 12.5522847,9 12,9 Z M16,9 C15.4477153,9 15,8.55228475 15,8 C15,7.44771525 15.4477153,7 16,7 C16.5522847,7 17,7.44771525 17,8 C17,8.55228475 16.5522847,9 16,9 Z M16,13 C15.4477153,13 15,12.5522847 15,12 C15,11.4477153 15.4477153,11 16,11 C16.5522847,11 17,11.4477153 17,12 C17,12.5522847 16.5522847,13 16,13 Z M16,17 C15.4477153,17 15,16.5522847 15,16 C15,15.4477153 15.4477153,15 16,15 C16.5522847,15 17,15.4477153 17,16 C17,16.5522847 16.5522847,17 16,17 Z M12,17 C11.4477153,17 11,16.5522847 11,16 C11,15.4477153 11.4477153,15 12,15 C12.5522847,15 13,15.4477153 13,16 C13,16.5522847 12.5522847,17 12,17 Z M8,17 C7.44771525,17 7,16.5522847 7,16 C7,15.4477153 7.44771525,15 8,15 C8.55228475,15 9,15.4477153 9,16 C9,16.5522847 8.55228475,17 8,17 Z M8,13 C7.44771525,13 7,12.5522847 7,12 C7,11.4477153 7.44771525,11 8,11 C8.55228475,11 9,11.4477153 9,12 C9,12.5522847 8.55228475,13 8,13 Z"
          fill="inherit"
          id={`path-1-chip${unique}`}
        />
      </g>
    </g>
  </svg>
)

Chip.propTypes = propTypes
export default Chip
