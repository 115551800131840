import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Call = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/communications/call-call${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-call${unique}`}
        xlinkHref={`#path-1-call${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-call${unique}`}>
        <path
          d="M8,13.2222222 L8.92226224,8.40596386 C8.96737745,8.17036218 9.1734575,8 9.41333983,8 L14.5866602,8 C14.8265425,8 15.0326225,8.17036218 15.0777378,8.40596386 L16,13.2222222 L20.2860906,13.8889474 C20.7122365,13.9552368 21.047877,14.2872619 21.1187775,14.7126649 L21.8059335,18.835601 C21.8967286,19.3803713 21.5287089,19.8955989 20.9839386,19.9863939 C20.9296071,19.9954492 20.8746204,20 20.8195396,20 L3.18046042,20 C2.62817567,20 2.18046042,19.5522847 2.18046042,19 C2.18046042,18.9449191 2.18501126,18.8899324 2.1940665,18.835601 L2.88122252,14.7126649 C2.95212302,14.2872619 3.28776355,13.9552368 3.71390943,13.8889474 L8,13.2222222 Z M21.8444,7.37147 C22.2964,7.82447 22.9664,8.94547 23.0744,9.51047 C23.1824,10.07447 23.2344,11.50347 23.0394,11.59647 C22.4234,11.88847 20.3434,11.97347 19.2984,11.97347 C18.1324,11.97347 17.7774,12.07747 17.4044,11.59647 C16.9234,10.97347 17.0114,9.37747 17.0054,9.04547 L17,7 C15.72,6.552 13.4804,6.20647 12.0854,6.20847 C10.6894,6.20647 8.28,6.552 7,7 L7.1664,9.04547 C7.1604,9.37747 7.2484,10.97347 6.7654,11.59647 C6.3934,12.07747 6.0394,11.97347 4.8734,11.97347 C3.8274,11.97347 1.7484,11.88847 1.1324,11.59647 C0.9364,11.50347 0.9874,10.07447 1.0954,9.51047 C1.2054,8.94547 1.8754,7.82447 2.3274,7.37147 C5.1194,4.58047 8.3684,4.00847 12.0854,3.99947 C15.8024,4.00847 19.0524,4.58047 21.8444,7.37147 Z M12,18 C13.6568542,18 15,16.6568542 15,15 C15,13.3431458 13.6568542,12 12,12 C10.3431458,12 9,13.3431458 9,15 C9,16.6568542 10.3431458,18 12,18 Z M12,17.000265 C13.1047159,17.000265 14.000265,16.1047159 14.000265,15 C14.000265,13.8952841 13.1047159,12.999735 12,12.999735 C10.8952841,12.999735 9.999735,13.8952841 9.999735,15 C9.999735,16.1047159 10.8952841,17.000265 12,17.000265 Z"
          fill="inherit"
          id={`path-1-call${unique}`}
        />
      </g>
    </g>
  </svg>
)

Call.propTypes = propTypes
export default Call
