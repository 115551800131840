import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Play = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id="Glyphs/AV/*Play"
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`triangle-2-play${unique}`}
        xlinkHref={`#path-1-play${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-play${unique}`}>
        <path
          d="M6.77534035,3.01150927 L19.7673728,11.582642 C19.9978732,11.7347083 20.0614567,12.0448399 19.9093904,12.2753403 C19.8721441,12.3317979 19.8238304,12.3801117 19.7673728,12.417358 L6.77534035,20.9884907 C6.54483989,21.140557 6.23470828,21.0769735 6.08264201,20.8464731 C6.02873523,20.7647618 6,20.6690239 6,20.5711327 L6,3.42886726 C6,3.15272489 6.22385763,2.92886726 6.5,2.92886726 C6.59789116,2.92886726 6.69362903,2.9576025 6.77534035,3.01150927 Z"
          fill="inherit"
          id={`path-1-play${unique}`}
        />
      </g>
    </g>
  </svg>
)

Play.propTypes = propTypes
export default Play
