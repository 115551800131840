import {useEffect, useState} from "react"

// Hook that alerts clicks outside of the passed ref
const useDetectOutsideClick = (ref, overrideRef) => {
  const [isOutsideClickDetected, setIsOutsideClickDetected] = useState(false)

  useEffect(() => {
    // Alert if clicked on outside of element
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (overrideRef && overrideRef?.current) {
          !overrideRef.current.contains(event.target) && setIsOutsideClickDetected(true)
        } else {
          setIsOutsideClickDetected(true)
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref])

  return {isOutsideClickDetected}
}

export default useDetectOutsideClick