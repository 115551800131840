import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Nda = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/alert/nda-nda${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-nda${unique}`}
        xlinkHref={`#path-1-nda${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-nda${unique}`}>
        <path
          d="M2,6 L22,6 C22.5522847,6 23,6.44771525 23,7 L23,17 C23,17.5522847 22.5522847,18 22,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M6.482,14.811 C6.563,14.946 6.68,15 6.869,15 L7.76,15 C7.859,15 7.958,14.919 7.958,14.811 L7.958,8.889 C7.958,8.79 7.859,8.7 7.76,8.7 L6.932,8.7 C6.833,8.7 6.752,8.79 6.752,8.889 L6.752,12.993 L4.115,8.853 C4.043,8.736 3.944,8.7 3.836,8.7 L2.765,8.7 C2.657,8.7 2.576,8.79 2.576,8.889 L2.576,14.811 C2.576,14.919 2.657,15 2.765,15 L3.593,15 C3.701,15 3.782,14.919 3.782,14.811 L3.782,10.608 L6.482,14.811 Z M11.907,8.7 L9.549,8.7 C9.441,8.7 9.36,8.79 9.36,8.889 L9.36,14.802 C9.36,14.91 9.441,15 9.549,15 L11.907,15 C13.896,15 15.156,13.713 15.156,11.841 C15.156,9.96 13.896,8.7 11.907,8.7 Z M11.925,13.893 L10.566,13.893 L10.566,9.807 L11.925,9.807 C13.095,9.807 13.896,10.635 13.896,11.841 C13.896,13.038 13.095,13.893 11.925,13.893 Z M20.185,14.829 C20.23,14.937 20.275,15 20.383,15 L21.328,15 C21.472,15 21.571,14.892 21.499,14.712 L19.222,8.889 C19.168,8.754 19.06,8.7 18.97,8.7 L17.854,8.7 C17.764,8.7 17.656,8.754 17.602,8.889 L15.334,14.721 C15.271,14.892 15.352,15 15.487,15 L16.378,15 C16.477,15 16.54,14.928 16.576,14.829 L17.089,13.47 L19.663,13.47 L20.185,14.829 Z M17.467,12.453 L18.367,10.059 L19.276,12.453 L17.467,12.453 Z"
          fill="inherit"
          id={`path-1-nda${unique}`}
        />
      </g>
    </g>
  </svg>
)

Nda.propTypes = propTypes
export default Nda
