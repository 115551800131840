import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Pause = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/av/pause-pause${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-pause${unique}`}
        xlinkHref={`#path-1-pause${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-pause${unique}`}>
        <path
          d="M15,3 L18,3 C18.5522847,3 19,3.44771525 19,4 L19,20 C19,20.5522847 18.5522847,21 18,21 L15,21 C14.4477153,21 14,20.5522847 14,20 L14,4 C14,3.44771525 14.4477153,3 15,3 Z M6,3 L9,3 C9.55228475,3 10,3.44771525 10,4 L10,20 C10,20.5522847 9.55228475,21 9,21 L6,21 C5.44771525,21 5,20.5522847 5,20 L5,4 C5,3.44771525 5.44771525,3 6,3 Z"
          fill="inherit"
          id={`path-1-pause${unique}`}
        />
      </g>
    </g>
  </svg>
)

Pause.propTypes = propTypes
export default Pause
