import React from "react";
import { node } from "prop-types";
import { ThemeProvider } from "styled-components";
import { forEachObjIndexed, mergeDeepLeft } from "ramda";

import DefaultTheme from "./default";

/**
 * Collects and merges drupal.theme from store with DefaultTheme
 */
const ThemeContainer = ({ children }) => {
  const theme = {};
  return (
    <DefaultTheme>
      <ThemeProvider
        theme={(defaultTheme) => {
          const merged = mergeDeepLeft(theme, defaultTheme);
          const { colors, colorRoles } = merged;
          forEachObjIndexed((value, key) => {
            colors[key] = colors[value];
          }, colorRoles);
          return merged;
        }}
      >
        {children}
      </ThemeProvider>
    </DefaultTheme>
  );
};

ThemeContainer.propTypes = {
  children: node,
};

export default ThemeContainer;
