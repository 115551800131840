import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Cancel = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/cancel-cancel${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`ic_cancel_24px-cancel${unique}`}
        xlinkHref={`#path-1-cancel${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-cancel${unique}`}>
        <path
          d="M12,2 C17.53,2 22,6.47 22,12 C22,17.53 17.53,22 12,22 C6.47,22 2,17.53 2,12 C2,6.47 6.47,2 12,2 Z M12,10.5857864 L8.06066017,6.64644661 C7.86539803,6.45118446 7.54881554,6.45118446 7.35355339,6.64644661 L6.64644661,7.35355339 C6.45118446,7.54881554 6.45118446,7.86539803 6.64644661,8.06066017 L10.5857864,12 L6.64644661,15.9393398 C6.45118446,16.134602 6.45118446,16.4511845 6.64644661,16.6464466 L7.35355339,17.3535534 C7.54881554,17.5488155 7.86539803,17.5488155 8.06066017,17.3535534 L12,13.4142136 L15.9393398,17.3535534 C16.134602,17.5488155 16.4511845,17.5488155 16.6464466,17.3535534 L17.3535534,16.6464466 C17.5488155,16.4511845 17.5488155,16.134602 17.3535534,15.9393398 L13.4142136,12 L17.3535534,8.06066017 C17.5488155,7.86539803 17.5488155,7.54881554 17.3535534,7.35355339 L16.6464466,6.64644661 C16.4511845,6.45118446 16.134602,6.45118446 15.9393398,6.64644661 L12,10.5857864 Z"
          fill="inherit"
          id={`path-1-cancel${unique}`}
        />
      </g>
    </g>
  </svg>
)

Cancel.propTypes = propTypes
export default Cancel
