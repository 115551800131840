/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useMemo, useRef } from "react"
import { Waypoint } from "react-waypoint"
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  objectOf,
  oneOfType,
  string,
} from "prop-types"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

import {
  GET_IMPERSONATION_STATUS,
  GET_IS_MOBILE,
  GET_PDP_BANNER,
  GET_PDP_BANNER_HEIGHT,
  GET_SCROLL_DIRECTION,
} from "lib/redux/selectors"
import Primitives from "primitives"
import NavigationContext, {
  NavigationUpdateContext,
} from "components/navigation/navigation-provider"
import { isEmptyOrUndefined } from "lib/utils/helpers"

const propTypes = {
  bottomOffsetNumber: oneOfType([string, number]),
  children: node,
  /** Boolean value to represent whether the nav bar has reached the top or not */
  isSticky: bool,
  nav: node,
  nid: string,
  onEnterHandler: func,
  onLeaveHandler: func,
  onPositionChangeHandler: func,
  onTabClickHandler: func,
  screenHeight: number,
  stickyNavHeight: number,
  topOffsetNumber: number,
  type: string,
  wrapperProps: objectOf(
    oneOfType([string, number, arrayOf(string), arrayOf(number)]),
  ),
}
const navWrapperProps = {
  backgroundColor: "white",
  left: 0,
  width: 1,
}

const StickyNavWrapper = ({
  bottomOffsetNumber = -400, // Changed from 0 to -400 to fix, seeing Tabs above Image
  children,
  isSticky,
  nav,
  nid,
  onEnterHandler,
  onLeaveHandler,
  onPositionChangeHandler,
  stickyNavHeight = 72,
  topOffsetNumber = 0,
  type = "primary",
  wrapperProps,
  htmlId,
  comparisionTableSticky,
  logoImage,
  tabItems,
}) => {
  const { showPdpBanner } = useSelector(GET_PDP_BANNER)
  // const dispatch = useDispatch();
  const isMobile = useSelector(GET_IS_MOBILE)
  const { pathname } = useLocation()
  const stateRef = useRef(null)
  const isImpersonating = useSelector(GET_IMPERSONATION_STATUS)
  const impersonationOffset = isImpersonating ? 41 : 0
  const { showNavigation = true } = useSelector(GET_SCROLL_DIRECTION)
  const stickyTopValue = 0
  const setNavState = useContext(NavigationUpdateContext)
  const pdpBannerHeight = useSelector(GET_PDP_BANNER_HEIGHT)
  const { heights, navHeight, y } = useContext(NavigationContext)

  useEffect(() => {
    const newState = isSticky ? type : null
    if (stateRef.current !== newState) {
      stateRef.current = newState
      setNavState({ id: nid, path: pathname, state: newState })
    }
    return () => {
      setNavState({ id: nid, path: pathname, state: null })
    }
  }, [isMobile, isSticky, nid, pathname, setNavState, type])

  const computedTopOffset =
    (type === "primary" ? topOffsetNumber : topOffsetNumber + 72) + // 72 is the height of primary nav
    impersonationOffset +
    pdpBannerHeight
  const stickyTopOffset =
    type === "primary" && !comparisionTableSticky
      ? computedTopOffset + navHeight
      : computedTopOffset
  const stickyHeights = heights
  return (
    <Primitives.Box id={`sticky-nav-wrapper-${nid}`} {...wrapperProps}>
      <Waypoint
        bottomOffset={bottomOffsetNumber}
        onEnter={onEnterHandler}
        onLeave={
          !isEmptyOrUndefined(tabItems)
            ? onLeaveHandler
            : logoImage
              ? null
              : onLeaveHandler
        }
        onPositionChange={onPositionChangeHandler}
        topOffset={`${
          isSticky || isMobile ? stickyTopOffset : computedTopOffset
        }px`}
      >
        <div id={htmlId}>
          <Primitives.Box position="relative" pt={stickyNavHeight}>
            <Primitives.AnimatedBox
              mt={isSticky && type === "primary" ? stickyHeights : 0}
              position={isSticky ? "fixed" : "absolute"}
              style={{
                transform: y?.interpolate((val) =>
                  isSticky && type === "primary"
                    ? `translateY(${val}px)`
                    : "translateY(0px)",
                ),
                zIndex: y?.interpolate((val) => {
                  return isSticky && type === "primary"
                    ? "8"
                    : type === "primary"
                      ? 2
                      : val < 0
                        ? 2
                        : -2
                }),
              }}
              top={
                isImpersonating && comparisionTableSticky && !showNavigation
                  ? stickyTopValue + impersonationOffset
                  : type === "secondary" && isSticky
                    ? `${
                      72 +
                      (showPdpBanner ? pdpBannerHeight : 0) +
                      (isImpersonating ? impersonationOffset : 0)
                    }px`
                    : stickyTopValue
              }
              zIndex={isSticky && type === "primary" ? 8 : 2}
              {...navWrapperProps}
            >
              {nav}
            </Primitives.AnimatedBox>
            {children}
          </Primitives.Box>
        </div>
      </Waypoint>
    </Primitives.Box>
  )
}

StickyNavWrapper.propTypes = propTypes
export default StickyNavWrapper
