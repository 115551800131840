
import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}
const RightArrow = (props) => (
  <svg {...props} fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M3.33334 7.99992H12.6667M12.6667 7.99992L8.00001 3.33325M12.6667 7.99992L8.00001 12.6666" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
    </g>
  </svg>
)

RightArrow.propTypes = propTypes
export default RightArrow
