import React, {useCallback, useEffect, useRef} from "react"
import {
  array,
  bool,
  func,
  number,
  object,
  oneOfType,
  shape,
  string,
} from "prop-types"
// import css from "@styled-system/css"
import { css } from "styled-components/macro"
import SearchStyles from "../product-documents/filter-panel.module.css"

import {callIfFunc} from "lib/utils/helpers"
import Primitives from "primitives"
import {NavClose, NavSearch} from "components/glyphs"
import SearchTips from "components/search-tips"
import {WosSearch} from "components/glyphs/elements"
const inputCss = css({
  "&::-ms-clear": {
    display: "none",
  },
  "&::placeholder": {
    color: "#BBC0C8"
  },
  textIndent: "10px",
})

const DefaultSearchIcon = (props) => (
  <NavSearch cursor="pointer" fill="black" height={24} width={24} {...props} />
)

const WosDefaultSearchIcon = (props) => (
  <WosSearch cursor="pointer"  height={14} width={14} {...props} />
)

const defaultFont = {
  color: "black",
  fontSize: [5, 6],
  fontWeight: 2,
  height: [35, 30],
  letterSpacing: "0.3px",
}

const defaultWosFont = {
  color: "black",
  fontSize: ["16px", "16px", "14px"],
  fontWeight: 1,
  height: [35, 30],
  letterSpacing: "0.3px",
}

const propTypes = {
  autoFocus: bool,
  // Font object passed to the SearchInput component
  inputFont: shape({
    color: string,
    fontSize: oneOfType([array, string, number]),
    fontWeight: oneOfType([array, string, number]),
    height: oneOfType([array, string, number]),
    letterSpacing: oneOfType([array, string, number]),
    lineHeight: oneOfType([array, string, number]),
  }),
  onCancelClick: func,
  onChange: func,
  onFocus: func,
  onSubmit: func,
  placeholder: string,
  searchBarExtraStyles: shape({}),
  searchBarHeight: oneOfType([number, array, string]),
  searchLabel: oneOfType([func, object]),
  showCancel: bool,
  showSearchTips: bool,
  value: string,
}

const SearchInputComponent = ({
  autoFocus,
  inputFont = defaultFont,
  onCancelClick,
  onChange,
  onFocus,
  onSubmit,
  placeholder,
  searchBarExtraStyles = {},
  searchBarHeight = [50, 75],
  showSearchTips = false,
  searchLabel = (searchIconClick) => (
    showSearchTips ? <WosDefaultSearchIcon onClick={searchIconClick}  /> : <DefaultSearchIcon onClick={searchIconClick}  />
  ),
  showCancel,
  value,
  renderType,
  searchFormStyles = {},
  ...props
}) => {
  const searchBarHeights = showSearchTips ? [50, 60] : searchBarHeight
  const handleSubmit = useCallback(
    (evt) => {
      callIfFunc(onSubmit, evt)
      evt.preventDefault()
    },
    [onSubmit],
  )

  const searchTipsContainerStyle = {
    pl: showCancel ? 2 : 0,
  }

  const searchInput = useRef(null)

  useEffect(() => {
    if (searchInput && searchInput.current && autoFocus) {
      searchInput.current.focus()
    }
  }, [autoFocus])

  const searchIconClick = () => {
    if (searchInput && searchInput.current) {
      searchInput.current.focus()
    }
  }
  const inputFonts = showSearchTips ? defaultWosFont : inputFont

  return (
    <Primitives.Flex
      alignItems="center"
      width={1}
      {...searchBarExtraStyles}
      {...props}
    >
      <Primitives.Flex
        alignItems="center"
        as="form"
        height={searchBarHeights}
        onSubmit={handleSubmit}
        width={1}
        {...searchFormStyles}
      >
        <Primitives.Flex>
          {callIfFunc(searchLabel, searchIconClick)}
          {typeof searchLabel === "object" ? searchLabel : null}
        </Primitives.Flex>
        <Primitives.Input
          autoFocus
          className={renderType == "wos" ? SearchStyles.searchStyles : ""}
          border="none"
          css={inputCss}
          data-test-id="gs-search-bar-input"
          fontFamily="inherit"
          onChange={onChange}
          onFocus={onFocus}
          padding="0 !important"
          placeholder={placeholder}
          fontSize={!showSearchTips ? "14px" : "24px"}
          fontWeight={showSearchTips? 400:600}
          ref={searchInput}
          type="text"
          value={value}
          width={1}
          aria-label={"Search Products"}
          {...inputFonts}
        />
        {showCancel && (
          <Primitives.Button type={"button"} tabIndex={0} aria-label={"Clear Search"} ml={2} onClick={onCancelClick}>
            <NavClose  cursor="pointer" fill="black" height={16} width={16} />
          </Primitives.Button>
        )}
      </Primitives.Flex>
      {!showSearchTips ? (
        <Primitives.Button tabIndex={0} {...searchTipsContainerStyle}>
          <SearchTips />
        </Primitives.Button>
      ):null}
    </Primitives.Flex>
  )
}

SearchInputComponent.propTypes = propTypes

export default SearchInputComponent
