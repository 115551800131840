import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Delete = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/delete-delete${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-delete${unique}`}
        xlinkHref={`#path-1-delete${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-delete${unique}`}>
        <path
          d="M8,3 L8.85355339,2.14644661 C8.94732158,2.05267842 9.07449854,2 9.20710678,2 L14.7928932,2 C14.9255015,2 15.0526784,2.05267842 15.1464466,2.14644661 L16,3 L19.5,3 C19.7761424,3 20,3.22385763 20,3.5 L20,4.5 C20,4.77614237 19.7761424,5 19.5,5 L4.5,5 C4.22385763,5 4,4.77614237 4,4.5 L4,3.5 C4,3.22385763 4.22385763,3 4.5,3 L8,3 Z M5,6 L19,6 L18.0586014,21.0623783 C18.0256617,21.5894132 17.5886121,22 17.0605488,22 L6.93945122,22 C6.41138794,22 5.97433833,21.5894132 5.94139864,21.0623783 L5,6 Z M12.0248001,12.6621814 L9.51009388,10.1463837 L9.50996807,10.1462579 C9.31467119,9.95103053 8.9980887,9.95108686 8.8028613,10.1463837 L8.09625766,10.8534905 L8.09638352,10.8536163 C7.90120524,11.048864 7.90120524,11.3653495 8.09638352,11.5605973 L10.6111449,14.0764501 L8.09638352,16.592303 C7.90120524,16.7875508 7.90120524,17.1040362 8.09638352,17.299284 L8.8028613,18.0065166 C8.9980887,18.2018134 9.31467119,18.2018698 9.50996807,18.0066424 L9.51009388,18.0065166 L12.0248001,15.4907189 L14.5395063,18.0065166 L14.5396321,18.0066424 C14.734929,18.2018698 15.0515115,18.2018134 15.2467389,18.0065166 L15.9532167,17.299284 C16.1483949,17.1040362 16.1483949,16.7875508 15.9532167,16.592303 L13.4384553,14.0764501 L15.9532167,11.5605973 C16.1483949,11.3653495 16.1483949,11.048864 15.9532167,10.8536163 L15.9533425,10.8534905 L15.2467389,10.1463837 C15.0515115,9.95108686 14.734929,9.95103053 14.5396321,10.1462579 L14.5395063,10.1463837 L12.0248001,12.6621814 Z"
          fill="inherit"
          id={`path-1-delete${unique}`}
        />
      </g>
    </g>
  </svg>
)

Delete.propTypes = propTypes
export default Delete
