import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const TwitterBox = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/twitter_box-twitter-box${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-twitter-box${unique}`}
        xlinkHref={`#path-1-twitter-box${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-twitter-box${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M17.5739345,9.13705204 C18.1254039,8.73098957 18.6111709,8.2290662 19,7.64528408 C18.4829974,7.87254981 17.9315279,8.03411313 17.3466687,8.09981555 C17.9466072,7.74329916 18.4011386,7.17567337 18.6111709,6.51110959 C18.0597015,6.83531332 17.4457609,7.07873538 16.7973534,7.20798603 C16.2609632,6.65651658 15.5166949,6.30000019 14.6894907,6.30000019 C13.1018618,6.30000019 11.8222804,7.58173584 11.8222804,9.16828762 C11.8222804,9.39555335 11.8373596,9.62389617 11.8858286,9.8328514 C9.50438529,9.70467783 7.39867672,8.61789525 5.97261117,6.88378231 C5.72811202,7.30492403 5.58270503,7.74329916 5.58270503,8.2775352 C5.58270503,9.2663027 6.08570549,10.1408988 6.86228651,10.6600556 C6.3926758,10.6428222 5.95537775,10.5135715 5.5665487,10.3035392 L5.5665487,10.3358519 C5.5665487,11.7296047 6.5553162,12.8960919 7.86721034,13.1545932 C7.62594245,13.2202956 7.36420988,13.2526083 7.10570857,13.2526083 C6.92691183,13.2526083 6.75026927,13.236452 6.57147253,13.2041393 C6.92691183,14.3393909 7.99753808,15.1805972 9.24588398,15.1967535 C8.27327281,15.9754887 7.026004,16.5581938 5.67964302,16.5581938 C5.45237729,16.5581938 5.22511156,16.5420374 5,16.5097248 C6.26234805,17.3207726 7.78750577,17.6762119 9.4074473,17.6762119 C14.6894907,17.6762119 17.5739345,13.3010773 17.5739345,9.50864767 L17.5739345,9.13705204 Z"
          fill="inherit"
          id={`path-1-twitter-box${unique}`}
        />
      </g>
    </g>
  </svg>
)

TwitterBox.propTypes = propTypes
export default TwitterBox
