import { filter, find } from "ramda"

export const isElementFocusable = (item: Element): boolean => {
  if (item instanceof HTMLElement && item?.tabIndex < 0) {
    return false
  }
  if (item instanceof HTMLAnchorElement) {
    return !!item?.href
  }
  if (item instanceof HTMLInputElement) {
    return item?.type !== "hidden" && !item?.disabled
  }
  if (item instanceof HTMLSelectElement ||
    item instanceof HTMLTextAreaElement ||
    item instanceof HTMLButtonElement) {
    return !item?.disabled
  }
  return false
}

export const findFirstFocusableElement = (container: HTMLDivElement): Element | undefined => {
  const childElements = container.getElementsByTagName("*")
  return find(isElementFocusable, [...childElements])
}

export const findFocusableSiblings = (container: HTMLDivElement): Element[] => {
  const childElements = container.getElementsByTagName("*")
  return filter(isElementFocusable, [...childElements])
}
