import React from "react"
import styled from "styled-components"
import {string} from "prop-types"
import {always, merge, propOr} from "ramda"

import iconMachineNameMap from "./icon-map"
import iconMachineNameMapNew from "components/glyphs"
import customSystemProps from "../../primitives/custom"
import {defaultSystemProps, shouldForwardProp} from "../../primitives/shared"

export const iconMachineNameMapAll = merge(
  iconMachineNameMap,
  iconMachineNameMapNew
)

const propTypes = {
  id: string.isRequired
}

export const GlyphBase = ({id, ...props}) => {
  const Constructor = propOr(always(null), id, iconMachineNameMapAll)

  return <Constructor {...props} />
}

GlyphBase.propTypes = propTypes

const Glyph = styled(GlyphBase).withConfig({shouldForwardProp})`
  ${defaultSystemProps};
  ${customSystemProps};
`

Glyph.displayName = "Modules.Glyph"
export default Glyph
