import {gql} from "@apollo/client"

// FACET_RESPONSE_FRAGMENT uses activeCategory
const SEND_GLOBAL_SEARCH_CLICK_EVENT = gql`
  query searchInterceptorClickEvent($input: InterceptorClickEvent!) {
    searchInterceptorClickEvent(input: $input)
  }
`

export default SEND_GLOBAL_SEARCH_CLICK_EVENT
