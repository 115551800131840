import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const LinkOut = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/link_out-link-out${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-link-out${unique}`}
        xlinkHref={`#path-1-link-out${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-link-out${unique}`}>
        <path
          d="M17.5857864,5 L14.5,5 C14.2238576,5 14,4.77614237 14,4.5 L14,3.5 C14,3.22385763 14.2238576,3 14.5,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,5 L21,9.5 C21,9.77614237 20.7761424,10 20.5,10 L19.5,10 C19.2238576,10 19,9.77614237 19,9.5 L19,6.41421356 L9.56066017,15.8535534 C9.36539803,16.0488155 9.04881554,16.0488155 8.85355339,15.8535534 L8.14644661,15.1464466 C7.95118446,14.9511845 7.95118446,14.634602 8.14644661,14.4393398 L17.5857864,5 Z M11.7573593,8 L6.73223305,13.0251263 C5.75592232,14.001437 5.75592232,15.5843494 6.73223305,16.5606602 L7.43933983,17.267767 C8.41565056,18.2440777 9.998563,18.2440777 10.9748737,17.267767 L16,12.2426407 L16,20 C16,20.5522847 15.5522847,21 15,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,9 C3,8.44771525 3.44771525,8 4,8 L11.7573593,8 Z"
          fill="inherit"
          id={`path-1-link-out${unique}`}
        />
      </g>
    </g>
  </svg>
)

LinkOut.propTypes = propTypes
export default LinkOut
