import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const BlogPost = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/media/blog_post-blog-post${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`grey600-blog-post${unique}`}
        xlinkHref={`#path-1-blog-post${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-blog-post${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,16 C21,16.5522847 20.5522847,17 20,17 L7,17 L3.87370466,20.5170823 C3.69024565,20.7234736 3.37420946,20.742064 3.16781808,20.558605 C3.0610739,20.4637213 3,20.3277192 3,20.1849003 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M13.5,5 C13.2238576,5 13,5.22385763 13,5.5 L13,11.5 C13,11.7761424 13.2238576,12 13.5,12 L18.5,12 C18.7761424,12 19,11.7761424 19,11.5 L19,5.5 C19,5.22385763 18.7761424,5 18.5,5 L13.5,5 Z M14,6 L18,6 L18,11 L14,11 L14,6 Z M5.5,5 C5.22385763,5 5,5.22385763 5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L10.5,6 C10.7761424,6 11,5.77614237 11,5.5 C11,5.22385763 10.7761424,5 10.5,5 L5.5,5 Z M5.5,8 C5.22385763,8 5,8.22385763 5,8.5 C5,8.77614237 5.22385763,9 5.5,9 L10.5,9 C10.7761424,9 11,8.77614237 11,8.5 C11,8.22385763 10.7761424,8 10.5,8 L5.5,8 Z M5.5,11 C5.22385763,11 5,11.2238576 5,11.5 C5,11.7761424 5.22385763,12 5.5,12 L10.5,12 C10.7761424,12 11,11.7761424 11,11.5 C11,11.2238576 10.7761424,11 10.5,11 L5.5,11 Z M5.5,14 C5.22385763,14 5,14.2238576 5,14.5 C5,14.7761424 5.22385763,15 5.5,15 L18.5,15 C18.7761424,15 19,14.7761424 19,14.5 C19,14.2238576 18.7761424,14 18.5,14 L5.5,14 Z"
          fill="inherit"
          id={`path-1-blog-post${unique}`}
        />
      </g>
    </g>
  </svg>
)

BlogPost.propTypes = propTypes
export default BlogPost
