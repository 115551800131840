import React, { useMemo } from "react";
import { useTheme } from "styled-components";
import {
  array,
  bool,
  func,
  number,
  objectOf,
  oneOfType,
  string,
} from "prop-types";

import useEscapeClose from "lib/hooks/useEscapeClose";
import ModalComponent from "./ModalComponent";

const propTypes = {
  /** Function called when the user clicks the close icon for the Modal */
  closeModal: func.isRequired,
  /** Sets max-width of modal to large breakpoint if enabled */
  large: bool,
  modalStyles: objectOf(oneOfType([string, number, array])),
  /** Sets modal to fill the screen at mobile sizes */
  showFullscreenMobile: bool,
};

const containerBaseStyles = {
  backgroundColor: "white",
  position: "relative",
};

const ModalContainer = ({
  closeModal,
  large,
  modalStyles,
  showFullscreenMobile,
  ...props
}) => {
  const theme = useTheme();
  const close = useEscapeClose(closeModal);

  const modalContainerStyle = useMemo(
    () => ({
      ...containerBaseStyles,
      alignItems: "flex-start",
      borderRadius: [showFullscreenMobile ? "0" : "10px", "10px"],
      flexDirection: "column",
      height: [showFullscreenMobile ? 1 : "auto", "auto"],
      justifyContent: "flex-start",
      large: { large },
      maxHeight: [showFullscreenMobile ? "100%" : "auto", "90%"],
      maxWidth: ["100%", large ? `${theme.breakpointInts[1]}px` : "577px"],
      overflowY: "scroll",
      width: [showFullscreenMobile ? 1 : "auto", "auto"],
      ...modalStyles,
    }),
    [showFullscreenMobile, large, theme.breakpointInts, modalStyles],
  );

  return (
    <ModalComponent
      close={close}
      modalContainerStyle={modalContainerStyle}
      {...props}
    />
  );
};

ModalContainer.propTypes = propTypes;

export default ModalContainer;
