import React from "react"
import { arrayOf, bool, func, node, oneOfType, string } from "prop-types"
import css from "@styled-system/css"
import { useSelector } from "react-redux"

import Primitives from "primitives"
import Link from "components/link"
import { GET_ACTIVE_ITEMS, GET_IS_MOBILE } from "lib/redux/selectors"
import { createMegaMenuSection } from "components/navigation/utils"

const propTypes = {
  /** active of type bool to state which tab is active */
  active: bool,
  /** analytics of type node */
  analytics: node,
  /** childeren of type node */
  children: node,
  color: string,
  /** hasPopup of type bool to check if the menuItem has a popup  */
  hasPopup: bool,
  hoverColor: string,
  /** Link Url */
  href: string,
  /** Props to pass to root element */
  includeProps: arrayOf(string),
  /** Does menu item link off site */
  isExternalLink: bool,
  /** Root element type */
  tag: oneOfType([string, func]),
}
 

const MenuItem = ({
  active,
  analytics,
  color = "gunmetal",
  hasPopup,
  hoverColor = "megaMenuTextColor",
  isExternalLink,
  href,
  enableLink,
  step2,
  ...props
}) => {
  const activeMegaMenuItems = useSelector(GET_ACTIVE_ITEMS)
  const { children } = props
  const isMobile = useSelector(GET_IS_MOBILE)  
  return (
    <Primitives.Button
      active={active}
      tabIndex="0"
      as={href && enableLink ? Link : null}
      color={ (step2 === false && isMobile ) ? "#BBC0C8" : color }
      //color={color}
      backgroundColor={step2 ? "transparent !important":null}
      css={css({
        "&:hover": {
          color: hoverColor,
        },
        "&:visited": {
          color,
        },
        
      })}
      location={
        href
          ? {
            label: createMegaMenuSection(activeMegaMenuItems),
            state: {
              analytics,
              detail: typeof children === "string" ? children : children[0],
              ev: "megamenu",
              header_menu: "navigation",
              loc: href,
              text: "click",
              type: "header",
            },
          }
          : null
      }
      
      target={href && isExternalLink ? "_blank" : null}
      textDecoration="none"
      textAlign="left"
      fontWeight={step2 ? "560 !important" : 400}
      transition="color 0.5s"
      url={href}
      {...props}
    />
  )
}

MenuItem.propTypes = propTypes
export default MenuItem
