import React, {useCallback, useEffect, useState} from "react";
import {arrayOf, boolean, func, objectOf, oneOfType, shape, string,} from "prop-types";

import Primitives from "primitives";
import HeaderTag from "components/header-tag";
import CtaGroupItem from "./cta-group-item";
import {GET_IS_ONLY_MOBILE} from "lib/redux/selectors";
import {useSelector} from "react-redux";
import {GET_VIEWPORT_WIDTH} from "../../lib/redux/reducers/globals/globals-selectors";
import {themeSettings} from "components/theme/default";
import calculateCtaPadding from "../../lib/utils/calculate-cta-padding";

const propTypes = {
  className: string,

  ctaArrays: arrayOf(
    arrayOf(
      shape({
        attributes: shape({
          color: string,
          size: string,
          style: string,
          target: string,
          version: string,
          textColor: string,
          styles: string,
          hoverColor: string,
          hoverTextColor: string,
          ctaType: string,
        }),
        title: string.isRequired,
        url: string,
        urltype: string,
      })
    )
  ),
  groupCtas:arrayOf(
          shape({
            groupDesktopPosition: string,
            groupAlignment: string,
            enableMobileWidth: boolean,
            groupMobilePosition: string,
            topPadding: string,
            bottomPadding: string,
            ctaModelList: arrayOf(
                shape({
                  attributes: shape({
                    color: string,
                    size: string,
                    style: string,
                    target: string,
                    version: string,
                    textColor: string,
                    styles: string,
                    hoverColor: string,
                    hoverTextColor: string,
                    ctaType: string,
                    paddingStacked: string,
                    paddingInline: string,
                  }),
                  title: string.isRequired,
                  url: string,
                  urltype: string,
                })
            )
          })
  ),
  references: oneOfType([arrayOf(string), func]),
  size: string,
  style: objectOf(string),
  textColor: string,
  title: string,
};

const defaultProps = {
  style: {},
  textColor: "dark",
};

const CtaGroup = ({
  className,
   isCarousel,
  references,
  size,
  style,
  textColor,
  title,
  ctas,
    groupCtas,
  hoverColor,
  hoverTextColor,
  ctaType,
  urltype,
    width,
  ...props
}) => {
  const ctaArrays = (groupCtas && groupCtas.length) ?groupCtas:[ctas];
  const isMobile = useSelector(GET_IS_ONLY_MOBILE);
  const [paddingInline, setPaddingInline] = useState(0)
  const [paddingStacked, setPaddingStacked] = useState(0)
  const isStacked = (ctaArray,index) => {
    return ctaArray[index]?.attributes?.positioning === "stacked" || (!isMobile && ctaArray['groupDesktopPosition'] === "stacked") || (isMobile && ctaArray['groupMobilePosition'] === "stacked") || enableFullWidth(ctaArray)
  }
  const enableFullWidth = (ctaArray) => {
    return isMobile && ctaArray['enableMobileFullWidth']
  }
  useEffect(() => {
      if(isMobile) {
          setPaddingInline(width*0.0025)
          setPaddingStacked(width*0.0025)
      } else {
          setPaddingInline(width*0.01)
          setPaddingStacked(width*0.01)
      }
  },[width])
  return (
    <div className={className} style={style}>
      {title ? (
        <HeaderTag data-test-id="cta-group-title" level={4} title={title} />
      ) : null}
      {ctaArrays?.map((ctaArray, index) => (
          ctaArray && ctaArray['ctaModelList']?.length > 0
              ?
              <Primitives.Box
          key={index}
          data-test-id="cta-group-row"
          position="relative"
          display="flex"
          flexDirection={  isStacked(ctaArray,index)
              ? "column"
              : "row"
          }
          width={isCarousel?'100%':width}
          justifyContent = {
           !enableFullWidth(ctaArray) && !isStacked(ctaArray,index) ?ctaArray['groupAlignment'] === 'left'? 'flex-start':ctaArray['groupAlignment'] === 'right'?'flex-end':ctaArray['groupAlignment'] === 'center'?'center':ctaArray['groupAlignment'] === 'equallySpaced'?'space-between':'left':'left'
          }
          alignItems = {
            !enableFullWidth(ctaArray) && isStacked(ctaArray,index)?ctaArray['groupAlignment'] === 'left'? 'flex-start':ctaArray['groupAlignment'] === 'right'?'flex-end':ctaArray['groupAlignment'] === 'center'?'center':'left':'left'
          }
          paddingTop = {ctaArray['topPadding']?calculateCtaPadding(ctaArray['topPadding'],themeSettings):null}
          paddingBottom = {ctaArray['bottomPadding']?calculateCtaPadding(ctaArray['bottomPadding'],themeSettings):null}
        > {ctaArray['ctaModelList'].map((cta, subindex) => (
                  <CtaGroupItem
                      key={subindex}
                      cta={cta}
                      data-test-id="cta-group-item"
                      references={references}
                      textColor={cta?.attributes?.textColor}
                      color={cta?.attributes?.color}
                      hoverColor={cta?.attributes?.hoverColor}
                      hoverTextColor={cta?.attributes?.hoverTextColor}
                      ctaType={cta?.attributes?.ctaType}
                      alignment={cta?.attributes?.alignment}
                      paddingInline={[paddingInline*cta.attributes.paddingInline]}
                      paddingStacked={[paddingStacked*cta.attributes.paddingStacked]}
                      v2StyleColorClass={cta?.attributes?.v2StyleColorClass}
                      v2StylePositionClass={cta?.attributes?.v2StylePositionClass}
                      v2StyleSizeClass={cta?.attributes?.v2StyleSizeClass}
                      v2StyleVariant={cta?.attributes?.v2StyleVariant}
                      mobileDisplayType={cta?.attributes?.mobileDisplayType}
                      urltype={cta?.urltype}
                      isGroupStacked={isStacked(ctaArray,index)}
                      width={enableFullWidth(ctaArray)?width:null}
                      {...props}
                  />))}
              </Primitives.Box>

            :  <Primitives.Box
                  key={index}
                  data-test-id="cta-group-row"
                  position="relative"
                  display={
                      (ctaArray[index]?.attributes?.positioning === "stacked")
                          ? "grid"
                          : "inline"
                  }
              >
                  {ctaArray?.length > 0 && ctaArray?.map((cta, subindex) => (
                  <CtaGroupItem
                      key={subindex}
                      cta={cta}
                      data-test-id="cta-group-item"
                      references={references}
                      textColor={cta?.attributes?.textColor}
                      color={cta?.attributes?.color}
                      hoverColor={cta?.attributes?.hoverColor}
                      hoverTextColor={cta?.attributes?.hoverTextColor}
                      ctaType={cta?.attributes?.ctaType}
                      alignment={cta?.attributes?.alignment}
                      mobileDisplayType={cta?.attributes?.mobileDisplayType}
                      v2StyleColorClass={cta?.attributes?.v2StyleColorClass}
                      v2StylePositionClass={cta?.attributes?.v2StylePositionClass}
                      v2StyleSizeClass={cta?.attributes?.v2StyleSizeClass}
                      v2StyleVariant={cta?.attributes?.v2StyleVariant}
                      urltype={cta?.urltype}
                      {...props}
                  />
                  ))}
              </Primitives.Box>
      ))}
    </div>
  );
};

CtaGroup.propTypes = propTypes;
CtaGroup.defaultProps = defaultProps;

export default CtaGroup;
