import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Chat = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id="Glyphs/Communications/*Chat"
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`combined-shape-chat${unique}`}
        xlinkHref={`#path-1-chat${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-chat${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,16 C21,16.5522847 20.5522847,17 20,17 L7,17 L3.87370466,20.5170823 C3.69024565,20.7234736 3.37420946,20.742064 3.16781808,20.558605 C3.0610739,20.4637213 3,20.3277192 3,20.1849003 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M6.5,6.5 C6.22385763,6.5 6,6.72385763 6,7 C6,7.27614237 6.22385763,7.5 6.5,7.5 L17.5,7.5 C17.7761424,7.5 18,7.27614237 18,7 C18,6.72385763 17.7761424,6.5 17.5,6.5 L6.5,6.5 Z M6.5,9.5 C6.22385763,9.5 6,9.72385763 6,10 C6,10.2761424 6.22385763,10.5 6.5,10.5 L17.5,10.5 C17.7761424,10.5 18,10.2761424 18,10 C18,9.72385763 17.7761424,9.5 17.5,9.5 L6.5,9.5 Z M6.5,12.5 C6.22385763,12.5 6,12.7238576 6,13 C6,13.2761424 6.22385763,13.5 6.5,13.5 L14.5,13.5 C14.7761424,13.5 15,13.2761424 15,13 C15,12.7238576 14.7761424,12.5 14.5,12.5 L6.5,12.5 Z"
          fill="inherit"
          id={`path-1-chat${unique}`}
        />
      </g>
    </g>
  </svg>
)

Chat.propTypes = propTypes
export default Chat
