import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Code = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/technology/code-code${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-code${unique}`}
        xlinkHref={`#path-1-code${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-code${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M10.1177669,15.1464466 C10.313029,14.9511845 10.313029,14.634602 10.1177669,14.4393398 L7.67842703,12 L10.1177669,9.56066017 C10.313029,9.36539803 10.313029,9.04881554 10.1177669,8.85355339 L9.41066008,8.14644661 C9.21539793,7.95118446 8.89881544,7.95118446 8.7035533,8.14644661 L5.2035533,11.6464466 C5.00829115,11.8417088 5.00829115,12.1582912 5.2035533,12.3535534 L8.7035533,15.8535534 C8.89881544,16.0488155 9.21539793,16.0488155 9.41066008,15.8535534 L10.1177669,15.1464466 Z M13.9035536,15.1464466 L14.6106604,15.8535534 C14.8059225,16.0488155 15.122505,16.0488155 15.3177671,15.8535534 L18.8177671,12.3535534 C19.0130293,12.1582912 19.0130293,11.8417088 18.8177671,11.6464466 L15.3177671,8.14644661 C15.122505,7.95118446 14.8059225,7.95118446 14.6106604,8.14644661 L13.9035536,8.85355339 C13.7082914,9.04881554 13.7082914,9.36539803 13.9035536,9.56066017 L16.3428934,12 L13.9035536,14.4393398 C13.7082914,14.634602 13.7082914,14.9511845 13.9035536,15.1464466 Z"
          fill="inherit"
          id={`path-1-code${unique}`}
        />
      </g>
    </g>
  </svg>
)

Code.propTypes = propTypes
export default Code
