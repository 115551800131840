import React from "react"
import css from "@styled-system/css"
import { number, func } from "prop-types"
import { keyframes } from "styled-components"
import { css as cssStyles } from "styled-components/macro"

import Primitives from "primitives"
import { LogoQualcommIcon } from "components/glyphs/icons"
import Link from "components/link"
import { defaultEase } from "lib/styles"
import QualcommThemeContext from "components/QualcommThemeContext/ThemeContext"
const animateRotation = keyframes`
  0% {
    transform: rotate(45deg)
  }
  100% {
    transform: rotate(90deg)
  }
`

const menuCloseCss = cssStyles`
  animation: ${animateRotation} 0.5s ${defaultEase} forwards;
`

const logoCss = css({
  fill: "blue-migration",
  height: "20px",
})
const propTypes = {
  /** megaMenu Items Length */
  megaMenuActiveDesktop: number,
  /** onClick Handler */
  onClick: func,
}

const linkStyle = {
  alignItems: "center",
  "aria-label": "Qualcomm",
  display: "inline-flex",
  location: {
    state: { event_type: "logo click", text: "header logo", type: "header" },
  },
  textDecoration: "none",
  url: "/",
  verticalAlign: "middle",
}

const LogoComponent = () => (
  <QualcommThemeContext.Consumer>
    {(value) => {
      return (
        <Primitives.Box {...linkStyle}>
          <Link
            location={{
              detail: "megamenu logo",
              label: "megamenu",
              url: value?.homePagePath,
              state: {
                text: "click",
                type: "megamenu",
                loc: value?.homePagePath,
                document: "megamenu logo"
              }
            }}
            url={value?.homePagePath}
          >
            <LogoQualcommIcon css={logoCss} />
          </Link>
        </Primitives.Box>
      )
    }}
  </QualcommThemeContext.Consumer>
)

LogoComponent.propTypes = propTypes

export default LogoComponent
