import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const ControlTop = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/control_top-control-top${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-control-top${unique}`}
        xlinkHref={`#path-1-control-top${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-control-top${unique}`}>
        <path
          d="M4.5,4 C4.22385763,4 4,3.77614237 4,3.5 L4,2.5 C4,2.22385763 4.22385763,2 4.5,2 L19.5,2 C19.7761424,2 20,2.22385763 20,2.5 L20,3.5 C20,3.77614237 19.7761424,4 19.5,4 L4.5,4 Z M12.9677549,9.22293335 L12.9677549,21.4403835 C12.9677549,21.7165258 12.7438973,21.9403835 12.4677549,21.9403835 L11.5281586,21.9403835 C11.2520162,21.9403835 11.0281586,21.7165258 11.0281586,21.4403835 L11.0281586,9.22701981 L5.52420334,14.7309751 C5.3289412,14.9262372 5.01235871,14.9262372 4.81709656,14.7309751 L4.15270161,14.0665801 C3.95743947,13.871318 3.95743947,13.5547355 4.15270161,13.3594733 L11.0281586,6.48401635 C11.3521057,6.16211244 11.6760529,6.00116049 12,6.00116049 C12.3239471,6.00116049 12.6478943,6.16211244 12.9718414,6.48401635 L19.8472984,13.3594733 C20.0425605,13.5547355 20.0425605,13.871318 19.8472984,14.0665801 L19.1829034,14.7309751 C18.9876413,14.9262372 18.6710588,14.9262372 18.4757967,14.7309751 L12.9677549,9.22293335 Z"
          fill="inherit"
          id={`path-1-control-top${unique}`}
        />
      </g>
    </g>
  </svg>
)

ControlTop.propTypes = propTypes
export default ControlTop
