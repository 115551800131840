import DefaultTheme, {
  colorRoles,
  colors,
  themeColors,
  themeSettings,
} from "./DefaultTheme";

export { colorRoles, colors, themeColors, themeSettings };

export default DefaultTheme;
