import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const PlusCircleOutline = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <svg
      height="24px"
      version="1.1"
      viewBox="0 0 24 24"
      width="24px"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        fill="inherit"
        fillRule="evenodd"
        id={`glyphs/nav/Design---MVP-+-Plus${unique}`}
        stroke="none"
        strokeWidth="1"
      >
        <g
          fill="inherit"
          id={`colors/core/combine-shape-plus-circle${unique}`}
          transform="translate(-1283.000000, -541.000000)"
        >
          <g id="release-history" transform="translate(130.000000, 433.000000)">
            <g
              id={`colors/core/combine-shape-plus-circle_1${unique}`}
              transform="translate(0.000000, 71.000000)"
            >
              <g id="Icon" transform="translate(1153.000000, 37.000000)">
                <path
                  d="M17.2558413,13.4275017 L13.4233385,13.4275017 L13.4233385,17.2617413 C13.4233385,18.0477905 12.782838,18.6868304 11.9968374,18.6868304 C11.2093368,18.6868304 10.5673364,18.0477905 10.5673364,17.2617413 L10.5673364,13.4275017 L6.73783354,13.4275017 C5.95333296,13.4275017 5.31433249,12.7869617 5.31433249,12.0024126 C5.31433249,11.2148634 5.95333296,10.5728233 6.73783354,10.5728233 L10.5673364,10.5728233 L10.5673364,6.74008375 C10.5673364,5.95403462 11.2093368,5.31199449 11.9968374,5.31199449 C12.782838,5.31199449 13.4233385,5.95403462 13.4233385,6.74008375 L13.4233385,10.5728233 L17.2558413,10.5728233 C18.0433419,10.5728233 18.6823423,11.2133633 18.6823423,11.9964123 C18.6823423,12.7869617 18.0433419,13.4275017 17.2558413,13.4275017 M12.0028374,1.50025626 C6.21133315,1.50025626 1.49982968,6.21205074 1.49982968,12.0009125 C1.49982968,17.7912744 6.21133315,22.5000687 12.0028374,22.5000687 C17.7913417,22.5000687 22.4998452,17.7912744 22.4998452,12.0009125 C22.4998452,6.21205074 17.7913417,1.50025626 12.0028374,1.50025626 M12.003266,0.0001625 C5.3843449,0.0001625 -0.000278579721,5.38511857 -0.000278579721,12.0010197 C-0.000278579721,18.6186352 5.3843449,24.0001625 12.003266,24.0001625 C18.6187585,24.0001625 23.9999534,18.6186352 23.9999534,12.0010197 C23.9999534,5.38511857 18.6187585,0.0001625 12.003266,0.0001625"
                  id={`path-1-plus-circle${unique}`}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </svg>
)

PlusCircleOutline.propTypes = propTypes
export default PlusCircleOutline
