import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const ChartDataOutline = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/general/chart_data_outline-chart-data-outline${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-chart-data-outline${unique}`}
        xlinkHref={`#path-1-chart-data-outline${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-chart-data-outline${unique}`}>
        <path
          d="M9.52622691,13.8295122 L8.71967215,13.6943155 C8.53780525,13.8827821 8.28259148,14 8,14 C7.44771525,14 7,13.5522847 7,13 C7,12.4477153 7.44771525,12 8,12 C8.45535521,12 8.83962515,12.3043522 8.96048279,12.7207294 L9.99399809,12.8939698 L11.1654313,10.5511034 C11.0608791,10.3930919 11,10.2036555 11,10 C11,9.44771525 11.4477153,9 12,9 C12.5321742,9 12.9672557,9.41570322 12.9982356,9.94010083 L15.5774823,11.2297241 L19.0860509,6.40644277 C19.0307416,6.28225794 19,6.14471901 19,6 C19,5.44771525 19.4477153,5 20,5 C20.5522847,5 21,5.44771525 21,6 C21,6.55228475 20.5522847,7 20,7 C19.9644849,7 19.9294023,6.9981486 19.8948433,6.99453704 L16.4842619,11.6831139 L19.4488966,13.1654313 C19.6069081,13.0608791 19.7963445,13 20,13 C20.5522847,13 21,13.4477153 21,14 C21,14.5522847 20.5522847,15 20,15 C19.4678258,15 19.0327443,14.5842968 19.0017644,14.0598992 L15.8878864,12.5029602 L14.9716368,13.7625421 C14.9901737,13.8386558 15,13.9181804 15,14 C15,14.5522847 14.5522847,15 14,15 C13.6008101,15 13.2562511,14.7660975 13.0959,14.4278697 L10.5578032,14.0024276 L9.83456868,15.4488966 C9.93912092,15.6069081 10,15.7963445 10,16 C10,16.5522847 9.55228475,17 9,17 C8.44771525,17 8,16.5522847 8,16 C8,15.4678258 8.41570322,15.0327443 8.94010083,15.0017644 L9.52622691,13.8295122 Z M14.9811068,12.0495704 L12.5511034,10.8345687 C12.4086952,10.9287966 12.240762,10.9875508 12.0598992,10.9982356 L11.0255744,13.0668852 L13.1796683,13.4279598 C13.3604206,13.1692355 13.6604468,13 14,13 C14.0914185,13 14.1799718,13.0122672 14.2641037,13.0352452 L14.9811068,12.0495704 Z M5,19 L20.5,19 C20.7761424,19 21,19.2238576 21,19.5 L21,20.5 C21,20.7761424 20.7761424,21 20.5,21 L4.5,21 L3.5,21 C3.22385763,21 3,20.7761424 3,20.5 L3,3.5 C3,3.22385763 3.22385763,3 3.5,3 L4.5,3 C4.77614237,3 5,3.22385763 5,3.5 L5,19 Z"
          fill="inherit"
          id={`path-1-chart-data-outline${unique}`}
        />
      </g>
    </g>
  </svg>
)

ChartDataOutline.propTypes = propTypes
export default ChartDataOutline
