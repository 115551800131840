import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Windows = ({unique = "", ...props}) => (
  <svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/os/windows-windows${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`grey600-windows${unique}`}
        xlinkHref={`#path-1-windows${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-windows${unique}`}>
        <path
          d="M8.5,1.5 L18.6,0 L18.6,8.9 C15.2,8.9 11.8,9 8.5,9 L8.5,1.5 Z"
          id={`path-1-windows${unique}`}
        />
        <path
          d="M-8.8817842e-16,2.7 C2.5,2.3 5.1,1.9 7.7,1.7 L7.7,9 C5.1,9 2.6,9.1 -8.8817842e-16,9.1 L-8.8817842e-16,2.7 Z"
          id={`path-2-windows${unique}`}
        />
        <path
          d="M-8.8817842e-16,9.8 C2.5,9.8 5.1,9.9 7.7,9.8 L7.7,17.2 C5.1,16.9 2.6,16.5 -8.8817842e-16,16.2 L-8.8817842e-16,9.8 Z"
          id={`path-3-windows${unique}`}
        />
        <path
          d="M8.5,9.9 L18.6,9.9 L18.6,18.8 C15.2,18.3 11.8,17.8 8.5,17.4 L8.5,9.9 Z"
          id={`path-4-windows${unique}`}
        />
      </g>
    </g>
  </svg>
)

Windows.propTypes = propTypes
export default Windows
