import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const VoiceSound = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/applications/voice_sound-voice-sound${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-voice-sound${unique}`}
        xlinkHref={`#path-1-voice-sound${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-voice-sound${unique}`}>
        <path
          d="M5.13397312,8 C5.20061783,7.88455684 5.290515,7.78211375 5.4,7.7 L11.4,3.2 C11.8418278,2.86862915 12.4686292,2.9581722 12.8,3.4 C12.9298221,3.57309617 13,3.78362979 13,4 L13,20 C13,20.5522847 12.5522847,21 12,21 C11.7836298,21 11.5730962,20.9298221 11.4,20.8 L5.4,16.3 C5.290515,16.2178862 5.20061783,16.1154432 5.13397312,16 L4,16 C2.8954305,16 2,15.1045695 2,14 L2,10 C2,8.8954305 2.8954305,8 4,8 L5.13397312,8 Z M15.5,10 C15.7761424,10 16,10.2238576 16,10.5 L16,13.5 C16,13.7761424 15.7761424,14 15.5,14 C15.2238576,14 15,13.7761424 15,13.5 L15,10.5 C15,10.2238576 15.2238576,10 15.5,10 Z M18.5,7 C18.7761424,7 19,7.22385763 19,7.5 L19,16.5 C19,16.7761424 18.7761424,17 18.5,17 C18.2238576,17 18,16.7761424 18,16.5 L18,7.5 C18,7.22385763 18.2238576,7 18.5,7 Z M21.5,8 C21.7761424,8 22,8.22385763 22,8.5 L22,15.5 C22,15.7761424 21.7761424,16 21.5,16 C21.2238576,16 21,15.7761424 21,15.5 L21,8.5 C21,8.22385763 21.2238576,8 21.5,8 Z"
          fill="inherit"
          id={`path-1-voice-sound${unique}`}
        />
      </g>
    </g>
  </svg>
)

VoiceSound.propTypes = propTypes
export default VoiceSound
