import React, {useMemo, forwardRef} from "react"
import {both, contains, replace, toLower} from "ramda"
import {string} from "prop-types"

import {isDefined} from "../../lib/utils/helpers.js"
import ExternalLink from "./external-link"
import { getMeta } from "lib/apolloClient/client";
import InternalLink from "./internal-link"
import { urlMap } from "SecondaryNav"

// Util function to determine external vs. internal URLs
export const isExternalLink = contains("://")
export const configurePath = (path = "", url) => {
  if (isExternalLink(path)) {
    return path
  }
  const relativePath = replace("/content/qcomm-martech/us/en/home/", "", path)
  return `${url}${relativePath}`
}

const LinkContainer = forwardRef(({href, url, ...props}, ref) => {
  const env = toLower(getMeta("Environment") || "prod")
  const location = useMemo(
    () => configurePath(href || url, urlMap?.[env]),
    [env, href, url]
  )
  return (
    <ExternalLink innerRef={ref} url={location} {...props} />
  )
})
LinkContainer.propTypes = {
  href: string,
  url: string
}

export default LinkContainer
