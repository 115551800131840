import {FilterCategoriesArray} from "gql/queries/interceptor/globalSearch"

export const autosuggestInitialState = {
  suggestedFilters: FilterCategoriesArray,
  suggestedResources: [],
  suggestedSearches: [],
  suggestedSpellcheck: "",
}

export const formatAutosuggestResponse = (data) => {
  return {
    suggestedFilters: FilterCategoriesArray,
    suggestedResources:
      data?.SuggestedResources || autosuggestInitialState.suggestedResources,
    suggestedSearches:
      data?.SuggestedSearches || autosuggestInitialState.suggestedSearches,
    suggestedSpellcheck:
      data?.SuggestedSpellcheck || autosuggestInitialState.suggestedSpellcheck,
  }
}
