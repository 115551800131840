import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const ChatBubbleNews = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/communications/chat_bubble_news-chat-bubble-news${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`grey600-chat-bubble-news${unique}`}
        xlinkHref={`#path-1-chat-bubble-news${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-chat-bubble-news${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,16 C21,16.5522847 20.5522847,17 20,17 L7,17 L3.87370466,20.5170823 C3.69024565,20.7234736 3.37420946,20.742064 3.16781808,20.558605 C3.0610739,20.4637213 3,20.3277192 3,20.1849003 L3,4 C3,3.44771525 3.44771525,3 4,3 Z"
          fill="inherit"
          id={`path-1-chat-bubble-news${unique}`}
        />
      </g>
    </g>
  </svg>
)

ChatBubbleNews.propTypes = propTypes
export default ChatBubbleNews
