import React, {useMemo} from "react"
import {always, cond, contains, equals, identity, prop, T} from "ramda"
import {array, func, node, number, objectOf, oneOf, oneOfType, string,} from "prop-types"

import Cta from "components/cta"
import Glyph from "components/glyph"
import styled from "styled-components"
import {NavLinkOutSimple} from "components/glyphs"

export const LinkOut = styled(NavLinkOutSimple)`
  margin-left: 6px;

  width: 16px;

  height: 16px;

  fill: currentColor;

  vertical-align: top;
`
const isDarkUi = (color) => contains(color, ["hollow", "transparent", "white"])

const versionMap = {
  blue: "secondary",
  "blue-outline": "tertiary",
  transparent: "secondary",
  white: "secondary",
  "white-outline": "tertiary",
}

const getCtaVersion = (color) => prop(color, versionMap)

const getCtaSize = cond([
  [equals("multiple"), always("small")],
  [T, identity],
])

//Cta.displayName = "PositionedCta";

const propTypes = {
  /** string class name added to cta */
  className: string,
  /** Color used to determine the version of cta used */
  textColor: string,
  "data-test-id": string,
  /** Determines font size of children text/components */
  fontSize: number,
  /** Cta Icon ID, null for no icon */
  icon: string,
  /** A number used to override icon size */
  iconSize: number,
  /** click handler */
  onClick: func,
  /** string value used to determine display type */
  positioning: string,
  /** Cta size */
  size: oneOf(["small", "medium", "text", "multiple"]),

  styles: objectOf(oneOfType([string, number, array])),

  /** tag component/function used for link */
  tag: oneOfType([node, func]),

  /** link target type */
  target: oneOf(["_self", "_blank", "_top", "_parent"]),

  /** Cta text */
  title: string.isRequired,
  /** Link Path */
  url: string,
  urltype: string,
}

/**
 * CtaLink component is a CTA that uses a Link component from react-router as
 * the root element. This is the main CTA component used throughout
 * qualcomm.com.
 */
const CtaLink = ({
  className,
  color,
  "data-test-id": dataTestId,
  fontSize,
  iconSize,
  icon,
  onClick,
  positioning,
  url,
  size,
  styles,
  tag,
  target,
  title,
  textColor,
  hoverColor,
  hoverTextColor,
  fontWeight,
  ctaType,
  urltype,
  width,
  paddingInline,
  paddingStacked,
  isGroupStacked,
                   ...rest
}) => {
  const ctaCss = useMemo(
    () => ({
      display: `${positioning === "stacked" ? "block" : "inline-block"}`,
      marginRight: "10px",
    }),
    [positioning]
  )

  return title ? (
    <Cta
      className={className}
      css={ctaCss}
      darkUi={isDarkUi(color)}
      data-test-id={dataTestId}
      fontSize={fontSize}
      glyphRight={
        icon && size === "text" && urltype && urltype === "external" ? (
          <LinkOut />
        ) : (size === "text" || icon==='video-cta')  ? (
          <Glyph id={icon} />
        ) : null
      }
      iconSize={iconSize}
      justifyContent={positioning === "stacked" ? "flex-start" : "center"}
      location={{
        state: {
          text: title,
          type: "CTA",
        },
      }}
      onClick={onClick}
      positioning={positioning}
      size={getCtaSize(size)}
      tag={tag}
      target={target}
      url={url}
      version={size === "text" ? "link" : getCtaVersion(color)}
      {...styles}
      color={color}
      textColor={textColor}
      hoverColor={hoverColor}
      hoverTextColor={hoverTextColor}
      fontWeight={fontWeight}
      ctaType={ctaType}
      width={width}
      isGroupStacked={isGroupStacked}
      paddingInline={paddingInline}
      paddingStacked={paddingStacked}
      {...rest}
    >
      {title}
    </Cta>
  ) : null
}

CtaLink.propTypes = propTypes
CtaLink.displayName = "CtaLink"
export default CtaLink
