import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Help = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/help-help${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`grey600-help${unique}`}
        xlinkHref={`#path-1-help${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-help${unique}`}>
        <path
          d="M12,2 C17.52,2 22,6.48 22,12 C22,17.52 17.52,22 12,22 C6.48,22 2,17.52 2,12 C2,6.48 6.48,2 12,2 Z M11.617,5 C9.5713,5 8.2398,6.0035 7.6029,7.007 C7.2362,7.586 7.5257,7.8562 7.7187,7.9912 L8.6064,8.6088 C8.8959,8.821 9.224,8.8017 9.4362,8.5123 C9.9573,7.7982 10.6134,7.3158 11.424,7.3158 C12.4661,7.3158 13.0836,7.9912 13.0836,8.7824 C13.0836,10.4228 10.4783,11.6579 10.4783,13.5877 L10.4783,14.0123 C10.4783,14.2438 10.652,14.4175 10.8643,14.4175 L12.5625,14.4175 C12.7555,14.4175 12.9485,14.2825 12.9485,14.0315 L12.9485,13.8965 C12.9485,12.4298 15.7468,11.1754 15.7468,8.5702 C15.7468,6.7561 14.3187,5 11.617,5 Z M11.7134,18.9719 C12.6204,18.9719 13.3538,18.2386 13.3538,17.3508 C13.3538,16.4631 12.6204,15.7298 11.7134,15.7298 C10.8064,15.7298 10.0731,16.4631 10.0731,17.3509 C10.0731,18.2386 10.8064,18.9719 11.7134,18.9719 Z"
          fill="inherit"
          id={`path-1-help${unique}`}
        />
      </g>
    </g>
  </svg>
)

Help.propTypes = propTypes
export default Help
