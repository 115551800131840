import React, { useContext, useEffect, useCallback } from "react"
import { MapTo } from "@adobe/aem-react-editable-components"
import { useDispatch, useSelector } from "react-redux"
import { string } from "prop-types"
import { useLocation } from "react-router-dom"
import { gql, useQuery } from "@apollo/client"
import Primitives from "primitives"
import {
  // GET_PAGE_BREADCRUMB,
  GET_IS_MOBILE,
  // GET_AUTHENTICATED,
  GET_LOGGED_IN,
  GET_IMPERSONATION_STATUS,
  GET_MODEL_DATA,
  GET_NAVIGATION_HIDE_SHOW
} from "lib/redux/selectors"

import GET_USER_INFO from "gql/queries/user/getInfo"
import { USER_INFO_LOADED, SET_USER_INFO_LOADING_COMPLETED } from "lib/redux/reducers/user/user-reducer"
import {
  FETCH_MENUS_DATA_SUCCESS,
  SET_NAVIGATION_MAIN_MENU_ITEMS,
  SET_NAVIGATION_MAIN_MENU_PRODUCT_CTA,
  SET_SEARCH_TIPS_MODAL_VALUE,
  SET_SEARCH_PLACEHOLDER,
  SET_SEARCH_TIPS_TITLE} from "lib/redux/actions"
import GlobalSearchNav from "./global-search-nav"
import NavigationContext from "./navigation-provider"
import useUserInfo from "./useUserInfo"
import ImpersonationBanner from "./impersonation-banner"
import Banner from "components/banner"
import { hasData } from "lib/utils/helpers"

import GlobalNav from "components/dx/organisms/GlobalNav/GlobalNav"


const HEADER_QUERY = gql`
  query GetMegaMenu {
    getMegaMenu {
      headerData
    }
    getGlobalHeaderConfig {
      search
    }
  }
`

const globalheaderEditConfig = {
  emptyLabel: "globalheader",

  isEmpty: function (props) {
    return (
      !props ||
      !props.footerDisclaimer ||
      props.footerDisclaimer.trim().length < 1
    )
  },
}

function UserInfo() {
  const isLoggedIn = useSelector(GET_LOGGED_IN)
  const dispatch = useDispatch()
  const onCompleted = useCallback(
    data => dispatch({ type: USER_INFO_LOADED, userInfo: data?.userInfo || {} }),
    [dispatch]
  )
  if (!isLoggedIn) {
    dispatch({ type: SET_USER_INFO_LOADING_COMPLETED, value: !isLoggedIn })
  }
  useQuery(GET_USER_INFO, {
    fetchPolicy: "cache-first",
    onCompleted,
    skip: !isLoggedIn,
    variables: { ssr: false }
  })
}
const propTypes = {
  /* className of type string */
  className: string,
}

const animatedBoxStyle = {
  position: "fixed",
  top: 0,
  width: 1,
  zIndex: [8, 8, 10],
}


const Navigation = ({ className }) => {
  const navigationShow = useSelector(GET_NAVIGATION_HIDE_SHOW)
  const dispatch = useDispatch()
  const isMobile = useSelector(GET_IS_MOBILE)
  const isImpersonating = useSelector(GET_IMPERSONATION_STATUS)
  const { heights, y } = useContext(NavigationContext)
  const modelData = useSelector(GET_MODEL_DATA)
  const toggleNavigationhideshow = !hasData(modelData)  ? true : navigationShow
  UserInfo()
  const loginProps =useUserInfo()
  // const showDrupalMenu = useSelector(GET_AUTHENTICATED);
  const configuredHeights =  heights 
  // showDrupalMenu && isMobile ? [85, 105, 146] : heights;
  const [htmltId, setHtmltId] = React.useState([])
  function getMeta(metaName) {
    const metas = document.getElementsByTagName("meta")
    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute("name") === metaName) {
        return metas[i].getAttribute("content")
      }
    }
    return ""
  }


  const onHeaderCompleted = useCallback(
    (data) => {
      console.log(data?.getMegaMenu?.headerData)
      if (data?.getMegaMenu?.headerData && data?.getGlobalHeaderConfig?.search) {
        const result = data?.getMegaMenu?.headerData
        const search = data?.getGlobalHeaderConfig?.search

        console.log(result?.nav)
        dispatch({
          type: SET_NAVIGATION_MAIN_MENU_ITEMS,
          value: result?.nav || []
        })
        dispatch({
          type: SET_SEARCH_TIPS_MODAL_VALUE,
          value: search?.description
        })
        dispatch({
          type: SET_SEARCH_TIPS_TITLE,
          value: search?.title
        })
        dispatch({type:SET_SEARCH_PLACEHOLDER,value:result?.search?.placeholder})
        dispatch({
          type: FETCH_MENUS_DATA_SUCCESS,
          value: result?.nav?.children,
        })
        setHtmltId(result?.componentID?.id)
      }
    },
    [dispatch],
  )

  useQuery(HEADER_QUERY, {
    fetchPolicy: "cache-and-network",
    onCompleted: onHeaderCompleted,
  })

  useEffect(() => {
    const localUrl = process.env.REACT_APP_LOCALDEV_MEGA_MENU
    const megamenuUrl = localUrl ? localUrl : getMeta("megamenu-endurl")
    fetch(megamenuUrl)
      .then((res) => res.json())
      .then((result) => {
        dispatch({
          type: SET_NAVIGATION_MAIN_MENU_ITEMS,
          value: result?.megaMenu || []
        })
        dispatch({
          type: SET_NAVIGATION_MAIN_MENU_PRODUCT_CTA,
          value: result?.productCtaProperty || {}
        })
      })
  }, [dispatch])
  if (!toggleNavigationhideshow) {
    return null
  }
  return (<>
    {isImpersonating ? <ImpersonationBanner {...loginProps} /> : null}
    <Primitives.Box className={className} id={htmltId} position="relative" width={1}>
      <Primitives.AnimatedBox
        key={isMobile ? "mobile-menu" : "desktop-menu"}
        height={heights}
        style={{
          transform: y?.interpolate((yVal) => `translateY(${yVal}px)`),
        }}
        {...animatedBoxStyle}
      >
        {/* <MainMenu location={location}  loginProps={loginProps} /> */}
        <GlobalNav isImpersonating={isImpersonating} />
        <GlobalSearchNav />
        <Banner />
      </Primitives.AnimatedBox>


      <Primitives.Box mt={configuredHeights} />
    </Primitives.Box>
  </>)
}
Navigation.propTypes = propTypes

export default MapTo(
  "qcomm-martech/components/content/globalheader/v1/globalheader"
)(Navigation, globalheaderEditConfig)