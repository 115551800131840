import { createSelector } from "reselect"
import { pick, pipe, values } from "ramda"

import { createStateSelector } from "lib/redux/utils"

const makeSelector = createStateSelector("dataReducer")

export const GET_TOUTS = makeSelector(
  ["featuredTouts", "entities", "touts"],
  {}
)
export const GET_FEATURED_TOUTS = makeSelector(
  ["featuredTouts", "entities", "toutsGroup"],
  {}
)
export const GET_GLOBAL_SEARCH_SUGGESTIONS = makeSelector(
  "globalSearchSuggestions",
  []
)
export const GET_LOGGED_IN = makeSelector("loggedIn", false)
export const GET_MENUS = makeSelector("menus", [])
export const GET_MENU_ITEMS = makeSelector(
  ["menus", "entities", "menuItems"],
  []
)
export const GET_PRODUCT_ROUTES = makeSelector("productRoutes", [])
export const GET_MAIN_MENU_ITEMS = makeSelector(
  ["menus", "entities", "menu", "mainMenu", "items"],
  []
)
export const GET_FOOTER_MENU_ITEMS = makeSelector(
  ["menus", "entities", "menu", "footer", "items"],
  []
)
export const GET_LANGUAGE_FOOTER_ITEMS = makeSelector(
  ["menus", "entities", "menu", "regions"],
  [],
  values
)
export const GET_LEGAL_FOOTER_MENU_ITEMS = makeSelector(
  ["menus", "entities", "menu", "legalFooter", "items"],
  []
)
export const GET_SOCIAL_FOOTER_MENU_ITEMS = makeSelector(
  ["menus", "entities", "menu", "socialFooter", "items"],
  []
)
export const GET_MAIN_MENU_ITEM_DATA = createSelector(
  [GET_MAIN_MENU_ITEMS, GET_MENU_ITEMS],
  (mainMenuItems, menuItems) => pipe(pick(mainMenuItems), values)(menuItems)
)
export const GET_FOOTER_ITEMS = createSelector(
  [GET_FOOTER_MENU_ITEMS, GET_MENU_ITEMS],
  (itemIds, menuItems) => itemIds.map((id) => menuItems[id])
)
export const GET_LEGAL_FOOTER_ITEMS = createSelector(
  [GET_LEGAL_FOOTER_MENU_ITEMS, GET_MENU_ITEMS],
  (itemIds, menuItems) => itemIds.map((id) => menuItems[id])
)

export const GET_SOCIAL_FOOTER_ITEMS = createSelector(
  [GET_SOCIAL_FOOTER_MENU_ITEMS, GET_MENU_ITEMS],
  (itemIds, menuItems) => itemIds.map((id) => menuItems[id])
)

export const GET_MEGA_MENU_DATA = makeSelector("megaMenuData", [])
export const GET_MEGA_MENU_ENTITIES = createSelector([GET_MENUS], (menus) => {
  const flatten = []
  function flatTheLinks(arr) {
    arr.forEach((item) => {
      flatten.push(item)
      if (item.children != null) {
        flatTheLinks(item.children)
      }
    })
  }
  flatTheLinks(menus)
  return flatten
})
export const makeMenuItemSelector = ({ id }) =>
  makeSelector(["menus", "entities", "menuItems", id], [])

export const GET_NAVIGATION_MAIN_MENU_ITEMS = makeSelector("mainMenuItems", [])
export const GET_NAVIGATION_MAIN_MENU_PRODUCT_CTA = makeSelector("productCta", {})
