import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Flickr = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/flickr-flickr${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-flickr${unique}`}
        xlinkHref={`#path-1-flickr${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-flickr${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M20,4 L4,4 L4,20 L20,20 L20,4 Z M8.75,14.75 C7.23614627,14.75 6,13.5138537 6,12 C6,10.4861463 7.23614627,9.25 8.75,9.25 C10.2638537,9.25 11.5,10.4861463 11.5,12 C11.5,13.5138537 10.2638537,14.75 8.75,14.75 Z M15.2512166,14.75 C13.7373629,14.75 12.4996695,13.5138537 12.4996695,12 C12.4996695,10.4861463 13.7373629,9.25 15.2512166,9.25 C16.7650703,9.25 18.0012166,10.4861463 18.0012166,12 C18.0012166,13.5138537 16.7650703,14.75 15.2512166,14.75 Z"
          fill="inherit"
          id={`path-1-flickr${unique}`}
        />
      </g>
    </g>
  </svg>
)

Flickr.propTypes = propTypes
export default Flickr
