import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const ConsumerElectronics = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/applications/consumer_electronics-consumer-electronics${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-consumer-electronics${unique}`}
        xlinkHref={`#path-1-consumer-electronics${unique}`}
      />
      <g
        fill="inherit"
        id={`colors/core/gunmetal-consumer-electronics${unique}`}
      >
        <path
          d="M18,10 L17,10 L17,5 L3,5 L3,13 L14,13 L14,14 L12,14 L12,15 L13.6666667,15 C13.8507616,15 14,15.2238576 14,15.5 C14,15.7761424 13.8507616,16 13.6666667,16 L6.33333333,16 C6.14923842,16 6,15.7761424 6,15.5 C6,15.2238576 6.14923842,15 6.33333333,15 L8,15 L8,14 L2.5,14 C2.22385763,14 2,13.7761424 2,13.5 L2,4.5 C2,4.22385763 2.22385763,4 2.5,4 L17.5,4 C17.7761424,4 18,4.22385763 18,4.5 L18,10 Z M16.5,12 L21.5,12 C21.7761424,12 22,12.2238576 22,12.5 L22,19.5 C22,19.7761424 21.7761424,20 21.5,20 L16.5,20 C16.2238576,20 16,19.7761424 16,19.5 L16,12.5 C16,12.2238576 16.2238576,12 16.5,12 Z M17,13 L17,19 L21,19 L21,13 L17,13 Z"
          fill="inherit"
          id={`path-1-consumer-electronics${unique}`}
        />
      </g>
    </g>
  </svg>
)

ConsumerElectronics.propTypes = propTypes
export default ConsumerElectronics
