import styled from "styled-components";
import { animated } from "react-spring/renderprops.cjs";

import { defaultSystemProps, shouldForwardProp } from "./shared";

const AnimatedRenderProps = styled(animated.div).withConfig({
  shouldForwardProp,
})`
  background-position: center right;
  ${defaultSystemProps};
`;

AnimatedRenderProps.displayName = "Primitives.AnimatedRenderProps";

export default AnimatedRenderProps;
