import { cva, type VariantProps } from "class-variance-authority"

export const IconVariants = cva("", {
  variants: {
    variant: {
      blue: "fill-theme-blue-500 stroke-theme-blue-500",
      "blue-outlined": "fill-theme-blue-500 stroke-theme-blue-500",
      "blue-solid": "fill-theme-neutral-000 stroke-theme-neutral-000",
      gray: "fill-theme-neutral-600 stroke-theme-neutral-600",
      "gray-outlined": "fill-theme-neutral-600 stroke-theme-neutral-600",
      "gray-solid": "fill-theme-neutral-000 stroke-theme-neutral-000",
      white: "fill-theme-neutral-000 stroke-theme-neutral-000",
      "white-outlined": "fill-theme-neutral-000 stroke-theme-neutral-000",
      "white-solid": "fill-theme-neutral-600 stroke-theme-neutral-600",
      "dark-gray": "fill-theme-neutral-800 stroke-theme-neutral-800"
    },
    size: {
      small: "w-3",
      medium: "w-4",
      large: "w-6",
      auto: "w-auto"
    }
  },
  defaultVariants: {
    variant: "blue",
    size: "medium"
  }
})

export interface IconProps extends VariantProps<typeof IconVariants> {
  glyphId?: string
  remoteIconPath?: string
  className?: string
  alt?: string
}

export type IconVariant =
  | "blue"
  | "blue-outlined"
  | "blue-solid"
  | "gray"
  | "gray-outlined"
  | "gray-solid"
  | "white"
  | "white-outlined"
  | "white-solid"
  | "dark-gray"
  | null
  | undefined
