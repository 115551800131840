import { useCallback, useState } from "react";

function useOnOffSwitch(initialState = false) {
  const [isToggled, setIsToggled] = useState(initialState);
  const toggleOn = useCallback(() => {
    setIsToggled(true);
  }, [setIsToggled]);
  const toggleOff = useCallback(() => {
    setIsToggled(false);
  }, [setIsToggled]);
  return [isToggled, toggleOn, toggleOff];
}

export default useOnOffSwitch;
