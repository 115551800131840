import React from "react"
import { string } from "prop-types"

const propTypes = {
    unique: string,
}
const Plus = (props) => (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 12H19M12 5.00003V19" stroke="#3253DC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

Plus.propTypes = propTypes
export default Plus