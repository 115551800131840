import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Select = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/select-select${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`triangle-select${unique}`}
        xlinkHref={`#path-1-select${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-select${unique}`}>
        <path
          d="M12.3451362,2.14110046 L19.6795439,9.13822616 C19.8793461,9.32884018 19.8867946,9.64533503 19.6961806,9.84513721 C19.601827,9.94403886 19.4710966,10 19.3344067,10 L4.66559123,10 C4.38944886,10 4.16559123,9.77614237 4.16559123,9.5 C4.16559123,9.36331011 4.22155238,9.23257969 4.32045403,9.13822616 L11.6548618,2.14110046 C11.8480472,1.95679891 12.1519508,1.95679891 12.3451362,2.14110046 Z M12.3451362,21.8588995 C12.1519508,22.0432011 11.8480472,22.0432011 11.6548618,21.8588995 L4.32045403,14.8617738 C4.22155238,14.7674203 4.16559123,14.6366899 4.16559123,14.5 C4.16559123,14.2238576 4.38944886,14 4.66559123,14 L19.3344067,14 C19.4710966,14 19.601827,14.0559611 19.6961806,14.1548628 C19.8867946,14.354665 19.8793461,14.6711598 19.6795439,14.8617738 L12.3451362,21.8588995 Z"
          fill="inherit"
          id={`path-1-select${unique}`}
        />
      </g>
    </g>
  </svg>
)

Select.propTypes = propTypes
export default Select
