import React from "react"
import { bool } from "prop-types"
import css from "@styled-system/css"
import { useSelector } from "react-redux"
import { GET_IS_MOBILE } from "lib/redux/selectors"
import Primitives from "primitives"

const glyphStyles = {
  cursor: "pointer",
  fill: "mainMenuIconFill",
  height: 20,
  width: 20
}

const propTypes = {
  /** noMargin check boolean */
  noMargin: bool
}

const mainMenuIconCss = css({
  "&:first-child": {
    marginLeft: 0
  },
  "-webkit-backface-visibility": "visible"
})

const MainMenuIcon = ({noMargin = false, wosFlag,...props}) => {
  const isMobile = useSelector(GET_IS_MOBILE)
  return (
    <Primitives.Button
      css={mainMenuIconCss}
      ml={!noMargin ? isMobile ? 0 : 3 : 0}
      {...glyphStyles}
      {...props}
      mr={wosFlag && isMobile?"0px":"9px"}
      // width="16px"
      // height={wosFlag && isMobile?"16px":"14px"}
    />
  )
}

MainMenuIcon.propTypes = propTypes

export default MainMenuIcon
