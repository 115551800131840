import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Hd = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/av/hd-hd${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`grey600-hd${unique}`}
        xlinkHref={`#path-1-hd${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-hd${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M11.4286,9.185 C11.4286,9.0881 11.3493,9 11.2435,9 L10.4327,9 C10.327,9 10.2477,9.0881 10.2477,9.185 L10.2477,11.538 L7.1809,11.538 L7.1809,9.185 C7.1809,9.0881 7.1016,9 6.9958,9 L6.185,9 C6.0793,9 6,9.0882 5.9999,9.185 L5.9999,14.975 C5.9999,15.0807 6.0792,15.1689 6.185,15.1689 L6.9958,15.1689 C7.1015,15.1689 7.1808,15.0807 7.1808,14.975 L7.1808,12.5956 L10.2478,12.5956 L10.2478,14.975 C10.2478,15.0807 10.327,15.1689 10.4328,15.1689 L11.2436,15.1689 C11.3493,15.1689 11.4286,15.0807 11.4286,14.975 L11.4286,9.185 Z M15.0506,9 L15.0507,9 L12.7417,9 C12.6359,9 12.5567,9.0881 12.5567,9.185 L12.5567,14.975 C12.5567,15.0807 12.6359,15.1689 12.7417,15.1689 L15.0506,15.1689 C16.9982,15.1689 18.232,13.9086 18.232,12.0756 C18.232,10.2338 16.9982,9 15.0506,9 Z M15.0682,14.085 C16.2139,14.085 16.9982,13.2477 16.9982,12.0756 C16.9982,10.8947 16.2139,10.084 15.0682,10.084 L13.7375,10.084 L13.7375,14.085 L15.0682,14.085 Z"
          fill="inherit"
          id={`path-1-hd${unique}`}
        />
      </g>
    </g>
  </svg>
)

Hd.propTypes = propTypes
export default Hd
