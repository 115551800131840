import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Baidu = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/baidu-baidu${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-baidu${unique}`}
        xlinkHref={`#path-1-baidu${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-baidu${unique}`}>
        <path
          d="M18.0794605,15.6147033 C18.0794605,15.6147033 19.7544031,16.9246169 18.6835506,19.4467498 C17.6144652,21.9711546 13.7021426,20.6592214 13.7021426,20.6592214 C13.7021426,20.6592214 12.2589404,20.1939736 10.5819782,20.5673331 C8.90829786,20.9411974 7.46509563,20.8005881 7.46509563,20.8005881 C7.46509563,20.8005881 5.50691482,20.8470371 4.94851648,18.3716058 C4.39138034,15.8944073 6.9024058,14.5375397 7.0907264,14.3070616 C7.27702747,14.0717871 8.57886303,13.1859734 9.41646055,11.7829092 C10.2568349,10.3833792 12.7701323,9.26077637 14.5404974,12.0169215 C15.8448574,13.8862433 18.0794605,15.6147033 18.0794605,15.6147033 Z M6.2513618,12.4857035 C4.2982298,12.9070267 3.57170609,10.6459173 3.7862805,9.58617215 C3.7862805,9.58617215 4.01751598,7.29805163 5.60158,7.1569374 C6.8599958,7.04687335 7.78695724,8.42444105 7.88338951,9.21331756 C7.94094594,9.72476197 8.20777553,12.0653901 6.2513618,12.4857035 Z M9.8844852,8.56202112 C8.80403994,8.56202112 7.92882879,7.31748955 7.92882879,5.78012702 C7.92882879,4.24276449 8.80403994,3 9.8844852,3 C10.9644256,3 11.8373648,4.24276449 11.8373648,5.78012702 C11.8373648,7.31748955 10.9644256,8.56202112 9.8844852,8.56202112 Z M14.5404974,8.74504046 C13.0967903,8.56000159 12.7701323,7.25387454 12.9104892,5.94345601 C13.0258545,4.87260349 14.3059802,3.23047816 15.3318983,3.46625757 C16.3530201,3.69951257 17.2870499,5.05688504 17.0977195,6.22391738 C16.9124282,7.39246437 15.9857192,8.93437083 14.5404974,8.74504046 Z M20.2655951,10.7082701 C20.2655951,11.2712124 20.5003647,13.6514737 18.3576499,13.6991849 C16.2139254,13.745634 16.124309,12.250429 16.124309,11.1780618 C16.124309,10.0547017 16.3530201,8.46483153 18.0794605,8.46483153 C19.8028717,8.46483153 20.2655951,10.1493669 20.2655951,10.7082701 Z"
          fill="inherit"
          id={`path-1-baidu${unique}`}
        />
      </g>
    </g>
  </svg>
)

Baidu.propTypes = propTypes
export default Baidu
