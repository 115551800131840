import React, { ReactElement } from "react"
import { NavMenuItemProps } from "./NavMenuItem.interface"
import { Icon } from "components/dx/atoms/Icon"
import { navMenuItemTextClass } from "./types"

const NavMenuItem = ({
  navMenuItem,
  isActive,
  handleClick,
}: NavMenuItemProps): ReactElement => {
  const textClass = navMenuItemTextClass({
    activeState: isActive ? "active" : "inactive",
  })
  return (
    <button
      aria-labelledby={navMenuItem.title}
      className="flex items-center gap-x-xxxs group"
      onClick={(evt) => handleClick(navMenuItem, evt)}
    >
      <h1 className={textClass}>{navMenuItem.title}</h1>
      <Icon
        className="group-hover:translate-y-[2px] rotate-90 flex justify-center w-[5px] h-[10px] ml-[5px]"
        glyphId="dx-chevron-right-menu"
        variant={isActive ? "blue" : "dark-gray"}
      />
    </button>
  )
}

export default NavMenuItem
