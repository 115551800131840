import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Rss = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/communications/rss-rss${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-rss${unique}`}
        xlinkHref={`#path-1-rss${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-rss${unique}`}>
        <path
          d="M7.01530553,18.7131079 C7.01530553,19.8212768 6.11592206,20.7206603 5.00775314,20.7206603 C3.89958423,20.7206603 3.00020076,19.8212768 3.00020076,18.7131079 C3.00020076,17.604939 3.89958423,16.7055555 5.00775314,16.7055555 C6.11592206,16.7055555 7.01530553,17.604939 7.01530553,18.7131079 Z M18.4793331,20.6892421 C18.2183513,20.6892421 18.008562,20.4874831 17.9874827,20.227505 C17.3711642,12.6510023 11.1628084,6.65645089 3.48693183,6.4115295 C3.21691604,6.40349929 3.00010038,6.18969496 3.00010038,5.91867538 L3.00010038,3.90811167 C3.00010038,3.62605056 3.23498401,3.3921707 3.51804889,3.40020091 C12.8160278,3.6621865 20.3383266,10.9566281 20.9988113,20.1512181 C21.0188868,20.4413094 20.7819957,20.6892421 20.4909006,20.6892421 L18.4793331,20.6892421 Z M11.373501,20.6892421 C11.1305872,20.6892421 10.9318395,20.5115737 10.8876733,20.2736787 C10.2241773,16.6952166 7.19478072,13.9478812 3.4808088,13.7280542 C3.21280055,13.7119938 3,13.5022045 3,13.2341963 L3,11.221625 C3,10.9385601 3.23789496,10.701669 3.52095985,10.714718 C8.83193969,10.961647 13.1592189,14.9606913 13.9200812,20.1221085 C13.9642474,20.4202301 13.7203298,20.6892421 13.4181931,20.6892421 L11.373501,20.6892421 Z"
          fill="inherit"
          id={`path-1-rss${unique}`}
        />
      </g>
    </g>
  </svg>
)

Rss.propTypes = propTypes
export default Rss
