import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const CommunityPost = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/media/community_post-community-post${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-community-post${unique}`}
        xlinkHref={`#path-1-community-post${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-community-post${unique}`}>
        <path
          d="M9.21579372,13.7052377 L9.16666667,14 L8.67703296,14 C8.26812837,14 7.90041962,14.2489509 7.74855627,14.6286093 L6.54855627,17.6286093 C6.50130816,17.7467296 6.47703296,17.8727806 6.47703296,18 C6.47703296,18.5522847 6.92474821,19 7.47703296,19 L12.5302546,19 C12.655928,19 12.7804754,18.9763111 12.8973736,18.930174 C13.4110945,18.7274198 13.6631828,18.1466019 13.4604286,17.632881 L12.2763954,14.632881 C12.1256631,14.2509688 11.7568029,14 11.3462214,14 L10.8333333,14 L10.7842063,13.7052377 C11.2136262,13.3534091 11.5,12.7212953 11.5,12 C11.5,10.8954305 10.8284271,10 10,10 C9.17157288,10 8.5,10.8954305 8.5,12 C8.5,12.7212953 8.7863738,13.3534091 9.21579372,13.7052377 Z M5,2 L13.7928932,2 C13.9255015,2 14.0526784,2.05267842 14.1464466,2.14644661 L19.8535534,7.85355339 C19.9473216,7.94732158 20,8.07449854 20,8.20710678 L20,21 C20,21.5522847 19.5522847,22 19,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,3 C4,2.44771525 4.44771525,2 5,2 Z M13,4 L13,9 L18,9 L13,4 Z"
          fill="inherit"
          id={`path-1-community-post${unique}`}
        />
      </g>
    </g>
  </svg>
)

CommunityPost.propTypes = propTypes
export default CommunityPost
