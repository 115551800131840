import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Receipt = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/shopping/receipt-receipt${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-receipt${unique}`}
        xlinkHref={`#path-1-receipt${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-receipt${unique}`}>
        <path
          d="M6,1 L8,3 L10,1 L12,3 L13.9995117,1 L15.9951172,3 L18,1 L18,23 L16,21 L14,23 L12,21 L10,23 L8,21 L6,23 L6,1 Z M8.5,7 C8.22385763,7 8,7.22385763 8,7.5 C8,7.77614237 8.22385763,8 8.5,8 L15.5,8 C15.7761424,8 16,7.77614237 16,7.5 C16,7.22385763 15.7761424,7 15.5,7 L8.5,7 Z M8.5,10 C8.22385763,10 8,10.2238576 8,10.5 C8,10.7761424 8.22385763,11 8.5,11 L15.5,11 C15.7761424,11 16,10.7761424 16,10.5 C16,10.2238576 15.7761424,10 15.5,10 L8.5,10 Z M8.5,13 C8.22385763,13 8,13.2238576 8,13.5 C8,13.7761424 8.22385763,14 8.5,14 L15.5,14 C15.7761424,14 16,13.7761424 16,13.5 C16,13.2238576 15.7761424,13 15.5,13 L8.5,13 Z M8.5,16 C8.22385763,16 8,16.2238576 8,16.5 C8,16.7761424 8.22385763,17 8.5,17 L15.5,17 C15.7761424,17 16,16.7761424 16,16.5 C16,16.2238576 15.7761424,16 15.5,16 L8.5,16 Z"
          fill="inherit"
          id={`path-1-receipt${unique}`}
        />
      </g>
    </g>
  </svg>
)

Receipt.propTypes = propTypes
export default Receipt
