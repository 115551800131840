import React, { useMemo } from "react";
import styled, { withTheme } from "styled-components";
import { last, pathOr } from "ramda";
import {
  array,
  node,
  number,
  object,
  objectOf,
  oneOfType,
  string,
} from "prop-types";

import normalizeArray from "lib/utils/normalizeArray";
import { themePropType } from "lib/prop-types";
import Background from "components/background/modules/background";
import Primitives from "primitives";

const MaxContainer = styled(({ maxWidth, ...props }) => (
  <Primitives.Flex {...props} />
))`
  max-width: ${({ maxWidth }) => maxWidth};
`;

const propTypes = {
  as: oneOfType([node, string]),
  bgVideo: object,
  children: node,
  containerProps: objectOf(oneOfType([array, string, number])),
  htmlId: string,
  maxWidth: number,
  position: oneOfType([array, string]),
  px: oneOfType([array, string, number]),
  theme: themePropType,
};

const GridWrapper = ({
  as = "section",
  children,
  containerProps,
  htmlId,
  maxWidth,
  position = "relative",
  px,
  theme,
  bgVideo = null,
  ...props
}) => {
  const computedProps = useMemo(() => {
    const margins = pathOr([], ["grid", "margins"], theme);
    const pxFixed = normalizeArray(px, margins.length);
    const maxWidthVal = maxWidth || pathOr([], ["grid", "maxWidth"], theme);
    const sidemargin = pathOr(0, ["spacings", last(margins)], theme);
    return {
      maxWidth: bgVideo ? "100%" : `${maxWidthVal + 2 * sidemargin}px`,
      mx: bgVideo ? "0px" : "auto",
      px: px ? pxFixed : margins,
      width: bgVideo ? "100%" : 1,
      ...containerProps,
    };
  }, [bgVideo, containerProps, maxWidth, px, theme]);
  return (
    <Background
      forwardedAs={as}
      id={htmlId}
      position={position}
      width={1}
      {...props}
    >
      <MaxContainer position="relative" {...computedProps}>
        {children}
      </MaxContainer>
    </Background>
  );
};
GridWrapper.propTypes = propTypes;

export default withTheme(GridWrapper);
