import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Soc = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/products/soc-soc${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-soc${unique}`}
        xlinkHref={`#path-1-soc${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-soc${unique}`}>
        <path
          d="M19,19 L5,19 L5,5 L19,5 L19,19 Z M19.5,20 C19.7761424,20 20,19.7761424 20,19.5 L20,4.5 C20,4.22385763 19.7761424,4 19.5,4 L4.5,4 C4.22385763,4 4,4.22385763 4,4.5 L4,19.5 C4,19.7761424 4.22385763,20 4.5,20 L19.5,20 Z M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M12.5,7 L12.5,17 L17,17 L17,7 L12.5,7 Z M7,7 L7,11.5 L11.5,11.5 L11.5,7 L7,7 Z M7,12.5 L7,17 L11.5,17 L11.5,12.5 L7,12.5 Z"
          fill="inherit"
          id={`path-1-soc${unique}`}
        />
      </g>
    </g>
  </svg>
)

Soc.propTypes = propTypes
export default Soc
