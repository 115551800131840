import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}
const ChevronRight = (props) => (
  <svg fill="none" viewBox="0 0 8 14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1 13L7 7L1 1" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  </svg>
)

ChevronRight.propTypes = propTypes
export default ChevronRight
