import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const AddPlus = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/add_plus-add-plus${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-add-plus${unique}`}
        xlinkHref={`#path-1-add-plus${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-add-plus${unique}`}>
        <path
          d="M13,11 L20.5,11 C20.7761424,11 21,11.2238576 21,11.5 L21,12.5 C21,12.7761424 20.7761424,13 20.5,13 L13,13 L13,20.5 C13,20.7761424 12.7761424,21 12.5,21 L11.5,21 C11.2238576,21 11,20.7761424 11,20.5 L11,13 L3.5,13 C3.22385763,13 3,12.7761424 3,12.5 L3,11.5 C3,11.2238576 3.22385763,11 3.5,11 L11,11 L11,3.5 C11,3.22385763 11.2238576,3 11.5,3 L12.5,3 C12.7761424,3 13,3.22385763 13,3.5 L13,11 Z"
          fill="inherit"
          id={`path-1-add-plus${unique}`}
        />
      </g>
    </g>
  </svg>
)

AddPlus.propTypes = propTypes
export default AddPlus
