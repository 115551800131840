import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const BackspaceDelete = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/backspace_delete-backspace-delete${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`fill-1-backspace-delete${unique}`}
        xlinkHref={`#path-1-backspace-delete${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-backspace-delete${unique}`}>
        <path
          d="M10.3432955,4 L21,4 C21.5522847,4 22,4.44771525 22,5 L22,19 C22,19.5522847 21.5522847,20 21,20 L10.3432955,20 C9.80408477,20 9.28771838,19.7822753 8.91129526,19.3962003 L2.38064767,12.6981001 C2.0019812,12.3097243 2.0019812,11.6902757 2.38064767,11.3018999 L8.91129526,4.60379973 C9.28771838,4.21772474 9.80408477,4 10.3432955,4 Z M16.9393398,7.64644661 L14,10.5857864 L11.0606602,7.64644661 C10.865398,7.45118446 10.5488155,7.45118446 10.3535534,7.64644661 L9.64644661,8.35355339 C9.45118446,8.54881554 9.45118446,8.86539803 9.64644661,9.06066017 L12.5857864,12 L9.64644661,14.9393398 C9.45118446,15.134602 9.45118446,15.4511845 9.64644661,15.6464466 L10.3535534,16.3535534 C10.5488155,16.5488155 10.865398,16.5488155 11.0606602,16.3535534 L14,13.4142136 L16.9393398,16.3535534 C17.134602,16.5488155 17.4511845,16.5488155 17.6464466,16.3535534 L18.3535534,15.6464466 C18.5488155,15.4511845 18.5488155,15.134602 18.3535534,14.9393398 L15.4142136,12 L18.3535534,9.06066017 C18.5488155,8.86539803 18.5488155,8.54881554 18.3535534,8.35355339 L17.6464466,7.64644661 C17.4511845,7.45118446 17.134602,7.45118446 16.9393398,7.64644661 Z"
          fill="inherit"
          id={`path-1-backspace-delete${unique}`}
        />
      </g>
    </g>
  </svg>
)

BackspaceDelete.propTypes = propTypes
export default BackspaceDelete
