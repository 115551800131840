import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Filter = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/filter-filter${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-filter${unique}`}
        xlinkHref={`#path-1-filter${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-filter${unique}`}>
        <path
          d="M9.5,11.25 L3.52061163,4.67267279 C3.35327348,4.48860083 3.2605517,4.24876605 3.2605517,4 C3.2605517,3.44771525 3.70826695,3 4.2605517,3 L19.7394483,3 C19.9882143,3 20.2280491,3.09272178 20.4121211,3.26005993 C20.8207787,3.63156685 20.8508953,4.26401518 20.4793884,4.67267279 L14.5,11.25 L14.5,20.413149 C14.5,20.6892914 14.2761424,20.913149 14,20.913149 C13.900895,20.913149 13.8040255,20.8836974 13.721692,20.828534 L9.94338407,18.2970674 C9.6662916,18.1114154 9.5,17.7998344 9.5,17.4662974 L9.5,11.25 Z"
          fill="inherit"
          id={`path-1-filter${unique}`}
        />
      </g>
    </g>
  </svg>
)

Filter.propTypes = propTypes
export default Filter
