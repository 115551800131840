/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2020 Adobe Systems Incorporated
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License");
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

import React, { Component } from "react"
import QualcommThemeContext from "components/QualcommThemeContext/ThemeContext"
 
/**
  * Common Configuration React component
  */
 
class CommonConfiguration extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
    }
  }
  componentDidMount() {
    function getMeta(metaName) {
      const metas = document.getElementsByTagName("meta")
      for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute("name") === metaName) {
          return metas[i].getAttribute("content")
        }
      }
      return ""
    }
    const commonConfigUrl = getMeta("commonconfiguration-endurl")
    if(commonConfigUrl && !commonConfigUrl.startsWith("/conf")){
      fetch(commonConfigUrl)
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              items: result,
            })
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            })
          }
        )
    }
  }
  render() {
    const { items } = this.state
    return (
      <QualcommThemeContext.Provider value={{...items, wosFlag: true}}>
        {this.props.children}
      </QualcommThemeContext.Provider>
    )
  }
}

 
export default CommonConfiguration
 