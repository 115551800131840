import React, {useMemo} from "react"
import {
  array,
  bool,
  func,
  node,
  number,
  objectOf,
  shape,
  string,
} from "prop-types"
import {animated, Transition} from "react-spring/renderprops.cjs"
import {easeCubic} from "d3-ease"

import Primitives from "primitives"

const propTypes = {
  animationProps: objectOf([array, string, number]),
  children: node,
  config: shape({friction: number, mass: number, tension: number}),
  delayIn: number,
  delayOut: number,
  display: string,
  duration: number,
  ease: func,
  fadeInOut: bool,
  horizontal: number,
  vertical: number,
}

const FadeSlideInTransition = ({
  children,
  config = {friction: 30, mass: 1, tension: 180},
  delayIn = 0,
  delayOut = 0,
  duration,
  ease = easeCubic,
  fadeInOut = false,
  animationProps,
  vertical = 0,
  horizontal = 0,
  ...props
}) => {
  const items = React.Children.toArray(children) || []
  const configVals = useMemo(() => {
    if (duration) {
      return {
        ...config,
        duration,
        ease,
      }
    }
    return config
  }, [config, duration, ease])

  let index = -1
  return (
    <Primitives.Box {...props}>
      <Transition
        config={(item, type) => {
          index += 1
          return {
            ...configVals,
            delay: type === "enter" ? index * delayIn : index * delayOut,
          }
        }}
        enter={{
          opacity: 1,
          transform: "translate3d(0%,0%,0)",
        }}
        from={{
          opacity: !fadeInOut ? 1 : 0,
          transform: `translate3d(${horizontal * 100}%,${vertical * 100}%,0)`,
        }}
        items={items}
        keys={(item) => item.key}
        leave={{
          opacity: !fadeInOut ? 1 : 0,
          transform: `translate3d(${horizontal * 100}%,${vertical * 100}%,0)`,
        }}
        native
        unique
      >
        {(item) => (styles) => {
          return item ? (
            <Primitives.Box
              key={item?.key}
              as={animated.div}
              style={styles}
              {...animationProps}
            >
              {item}
            </Primitives.Box>
          ) : null
        }}
      </Transition>
    </Primitives.Box>
  )
}

FadeSlideInTransition.propTypes = propTypes
FadeSlideInTransition.displayName = "Animations.FadeSlideInTransition"

export default FadeSlideInTransition
