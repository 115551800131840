/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { HTMLAttributeAnchorTarget, ReactElement, ReactNode } from "react"

import Primitives from "primitives"

interface ExternalLinkType {
  className?: string | undefined;
  /** Child content string or react node */
  children?: ReactNode;
  /** Action creator used by external link analytics events, provided via redux connect HoC */
  linkHandler?: Function;
  /** Location object, used only for analytics on external links */
  location?: {
    state?: {
      text?: string[] | string;
      type?: string;
    };
  };
  /** Click handler function */
  onClick?: Function;
  /** Link target type */
  target?: HTMLAttributeAnchorTarget;
  /** Link path */
  url?: string | undefined;
}

const ExternalLink = ({
  className,
  children,
  url = "",
  location = {},
  linkHandler,
  onClick,
  target,
  ...props
}: ExternalLinkType): ReactElement => (
  <a
    className={className}
    href={url}
    onClick={evt => {
      if (onClick) {
        onClick(evt)
      } else if (linkHandler) {
        linkHandler(
          {
            pathname: url,
            ...location
          },
          evt
        )
      }
    }}
    target={target}
    {...props}
  >
    {children}
  </a>
)

export default ExternalLink
