import React, { ReactElement } from "react"

import { FooterLinksType } from "../GlobalFooter.interface"
import Link from "components/linkTs"


interface FooterLinksComponentType {
  heading: string;
  id: string;
  linkItems: FooterLinksType[]
}

const FooterLinks = ({
  id,
  heading,
  linkItems = []
}: FooterLinksComponentType): ReactElement => (
  <nav aria-labelledby={id}>
    <h2 className="heading-xxs mb-s" id={id}>
      {heading}
    </h2>
    <ul className="flex flex-col gap-y-xs leading-4">
      {linkItems?.map(({linkAction, linkLabel, linkUrl}, i) => {
        return (
          <li key={`${linkUrl}-${i}`}>
            <Link
              className="body-xs standard-ease duration-300 text-theme-neutral-800 hover:text-theme-neutral-600"
              href={linkUrl}
              location={{
                detail: linkLabel,
                label: "footer",
                url: linkUrl,
                state: {
                  text: "click",
                  type: "footer",
                  loc: linkUrl,
                  document: linkLabel
                }
              }}
              target={linkAction}
            >
              {linkLabel}
            </Link>
          </li>
        )
      })}
    </ul>
  </nav>
)
export default FooterLinks
