import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const GoogleplusBox = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/googleplus_box-googleplus-box${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`fill-1-googleplus-box${unique}`}
        xlinkHref={`#path-1-googleplus-box${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-googleplus-box${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M9.45455054,11.3182022 L9.45455054,12.8454718 L11.9809252,12.8454718 C11.8791184,13.500895 11.2172624,14.7672708 9.45455054,14.7672708 C7.93365777,14.7672708 6.69273368,13.5072719 6.69273368,11.9545505 C6.69273368,10.4018292 7.93365777,9.14183028 9.45455054,9.14183028 C10.3200201,9.14183028 10.8990882,9.51090787 11.2300162,9.82908206 L12.4391117,8.66451306 C11.662751,7.93911171 10.6572691,7.5 9.45455054,7.5 C6.99183308,7.5 5,9.49183308 5,11.9545505 C5,14.417268 6.99183308,16.4091011 9.45455054,16.4091011 C12.0254517,16.4091011 13.7309392,14.6018068 13.7309392,12.0563573 C13.7309392,11.7636348 13.6991106,11.5408905 13.6609051,11.3182022 L9.45455054,11.3182022 Z M19,11.3227529 L17.7273032,11.3227529 L17.7273032,10.0500002 L16.4545505,10.0500002 L16.4545505,11.3227529 L15.1818538,11.3227529 L15.1818538,12.5954497 L16.4545505,12.5954497 L16.4545505,13.8681464 L17.7273032,13.8681464 L17.7273032,12.5954497 L19,12.5954497 L19,11.3227529 Z"
          fill="inherit"
          id={`path-1-googleplus-box${unique}`}
        />
      </g>
    </g>
  </svg>
)

GoogleplusBox.propTypes = propTypes
export default GoogleplusBox
