import React from "react"
import styled from "styled-components"
import { bool, func, object, oneOfType, string } from "prop-types"
import { WosLoginIcon } from "components/glyphs/elements"
import { colors } from "lib/styles"
import customSystemProps from "primitives/custom"
import Primitives from "primitives"
import { NavAccount } from "components/glyphs/elements"
import { WosArrowDown, WosMobileLoginIcon } from "components/glyphs/elements"


const userTextStyles = {
  alignItems: "center",
  display: ["none", "none", "none", "flex", "flex"],
  justifyContent: "center"
}

const userTextTextStyles = {
  color: "text-light-grey-migration",
  cursor: "text",
  fontSize: 1,
  letterSpacing: "0.5px",
  lineHeight: "14px",
  mr: 1,
  pt: "1px",
  tag: "span"
}

/**
 * @param {string} firstName - name from logged in user
 * Not sure why this component had a text primitive wrapping another text primitive? It was rendering two spans?
 * Simplifying down to one element and merging the styles displays the same
 */
export const UserText = ({ firstName }) => (
  <Primitives.Flex {...userTextStyles}>
    <Primitives.Text {...userTextTextStyles}>
      {`Hi, ${firstName}`}
    </Primitives.Text>
  </Primitives.Flex>
)

UserText.propTypes = {
  firstName: string
}

export const LoginInIcon = styled(WosMobileLoginIcon)`
& #arrow-icon-login {
  transition: 166ms transform cubic-bezier(0.230,1.000,0.320,1.000);
}
&:hover #arrow-icon-login {
  transform: translateY(2px);
}
cursor:pointer;
`
export const LoginInOut = styled(WosLoginIcon)`
& #arrow-icon-login {
  transition: 166ms transform cubic-bezier(0.230,1.000,0.320,1.000);
}
&:hover #arrow-icon-login {
  transform: translateY(2px);
}
cursor:pointer;
`

export const MainIcon = styled(NavAccount)`
  ${customSystemProps};
  position: relative;
  cursor: pointer;
  &:hover {
    fill: ${({ isLoggedIn, ...props }) =>
  isLoggedIn
    ? colors("mainMenuIconFill", props)
    : colors("body-grey", props)};
  }
`

const propTypes = {
  /** ref for button element - technically this can be either a function or native DOM Element but SSR doesn't like Element */
  accountIconRef: oneOfType([func, object]),
  /** firstname: String that represent logged in user firstname */
  firstName: string,
  /** onBlur handler to control modal visibility when focus changes */
  handleModalCloseOnFocusChange: func,
  /** isLoggedIn: Boolean that represent isLogged in value */
  isLoggedIn: bool,
  /** toggleLoginPanel: Handler for opening/closing login panel */
  toggleLoginPanel: func,
  /** User status lru, fru */
  userStatus: string
}

const accountIconButtonStyles = {
  alignItems: "center",
  color: "body-grey-migration",
  display: "flex",
  flexWrap: "wrap",
  userSelect: "text"
}

const TitleWrapper = styled(Primitives.Flex)`
& #arrow-icon-login {
  transition: 166ms transform cubic-bezier(0.6,0.0,0.61,1.000);
}
&:hover #arrow-icon-login {
  transform: translateY(2px);
}
`


const LinkArrow = styled(Primitives.Flex)`
  vertical-align: middle;
`

const AccountIcon = ({
  accountIconRef,
  firstName,
  handleModalCloseOnFocusChange,
  isLoggedIn,
  toggleLoginPanel,
  userStatus,
  wosFlag
}) => {
  const state = userStatus ? userStatus.toUpperCase() : "LRU"
  const WosMainIcon = isLoggedIn ? LoginInOut : LoginInIcon


  /**
   * Wrapping, interactive element was previously a flex primitive, use a button for better semantic indication of its behavior
   * This also naturally adds it to the tab navigation order without the need for tabIndex
   * Also does not need custom keyboard event handler as a button
   */
  return (
    <Primitives.Button
      cursor="pointer"
      data-test-id="login-account-icon"
      id="account-login"
      onBlur={() => handleModalCloseOnFocusChange("fromIcon")}
      onClick={toggleLoginPanel}
      ref={accountIconRef}
      {...accountIconButtonStyles}
    >
      {isLoggedIn && firstName && !wosFlag ? <UserText firstName={firstName} /> : null}
      {state === "FRU" ? (
        <Primitives.Flex alignItems="center" mt={wosFlag ? 0 : "-6px"} mr={wosFlag ? 0 : "-11px"}>
          {wosFlag ? (
            <TitleWrapper 
              alignItems="center"
              justifyContent="flex-start"
              flexDirection="row"
            >
              <WosMainIcon
                height="18px"
                width="18px"
                isLoggedIn={isLoggedIn}
                state={state}
              />
              <LinkArrow
                id="arrow-icon-login"
                fill={isLoggedIn && "#3253DC"}
                height="10px"
                width="10px"
                position={["absolute", "absolute", "relative"]}
              >
                <WosArrowDown />
              </LinkArrow>
            </TitleWrapper>
          ) : <MainIcon
            fill={isLoggedIn ? "blue" : "text-light-grey"}
            height="31px"
            isLoggedIn={isLoggedIn}
            state={state}
            width="35px"
          />
          }
        </Primitives.Flex>
      ) : (
        <Primitives.Flex>
          {wosFlag ? (
            <TitleWrapper 
              alignItems="center"
              justifyContent="flex-start"
              flexDirection="row"
            >
              <WosMainIcon
                height="18px"
                width="18px"
                isLoggedIn={isLoggedIn}
                state={state}
              /> 
              <LinkArrow
                id="arrow-icon-login"
                fill={isLoggedIn && "#3253DC"}
                height="10px"
                width="10px"
                position={["absolute", "absolute", "relative"]}
              >
                <WosArrowDown />
              </LinkArrow>
            </TitleWrapper>
          ) : <MainIcon
            fill={isLoggedIn ? "blue" : "text-light-grey"}
            height="24px"
            isLoggedIn={isLoggedIn}
            state={state}
            width="24px"
          />
          }
        </Primitives.Flex>
      )}
    </Primitives.Button>
  )
}

AccountIcon.propTypes = propTypes

export default AccountIcon
