import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Qq = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/qq-qq${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`fill-1-qq${unique}`}
        xlinkHref={`#path-1-qq${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-qq${unique}`}>
        <path
          d="M20.1833845,14.8350212 C20.172225,13.6386059 19.7268749,12.989477 19.2159743,12.1430399 C18.9857501,11.7894981 18.7199843,11.5527095 18.4899477,11.1992616 C18.4541246,11.0076738 18.5289592,10.8641002 18.4755059,10.3605142 C18.4242095,9.60551034 18.1593814,9.31292407 18.1035837,9.2204593 C18.127497,9.01968134 18.0507869,7.36488087 17.4758361,6.38593595 C15.7221002,3.12125451 12.4003082,3.00215689 11.9937821,3 L11.9898434,3 C11.5833173,3.00215689 8.26152525,3.12134828 6.50788314,6.38593595 C5.93283857,7.3647871 5.85612845,9.01968134 5.88004175,9.2204593 C5.82424404,9.31292407 5.55941595,9.60551034 5.50821335,10.3605142 C5.45476009,10.8641002 5.52959466,11.0076738 5.49377159,11.1992616 C5.26364123,11.5528033 4.99778159,11.7895919 4.76765122,12.1430399 C4.25665679,12.989477 3.81130672,13.6386059 3.80024095,14.8350212 C3.79198852,15.7269404 3.99445447,16.9435179 4.59575679,16.9602104 C4.78509387,16.9445495 4.94676654,16.8136359 5.27555099,16.4299915 C5.3737362,16.3145512 5.71986952,15.8298145 5.78982765,15.6953374 C5.85734756,15.8809233 6.01714468,17.1751487 7.13441164,17.8493163 C5.98666694,18.4119822 5.42512635,18.7678684 5.40543304,19.498115 C5.42944012,19.9205833 5.91370793,20.4822177 6.8043143,20.7439511 C7.92608259,21.0705787 9.23099863,21.0778933 10.3171314,20.8048128 C10.9593208,20.6434215 11.1525965,20.4817488 11.9918127,20.0009508 C12.8311227,20.4817488 13.0243047,20.6435152 13.6664941,20.8048128 C14.7526269,21.0777996 16.0574491,21.0705787 17.1793112,20.7439511 C18.0698238,20.4822177 18.5540916,19.9205833 18.5780987,19.498115 C18.5584991,18.7678684 17.9969586,18.4119822 16.8492139,17.8493163 C17.966387,17.1752425 18.1261842,15.8810171 18.1937041,15.6953374 C18.263756,15.8298145 18.6098893,16.3145512 18.7079807,16.4299915 C19.036859,16.8137297 19.1984378,16.9445495 19.3877749,16.9602104 C19.9890772,16.9436117 20.191637,15.7269404 20.1833845,14.8350212 Z"
          fill="inherit"
          id={`path-1-qq${unique}`}
        />
      </g>
    </g>
  </svg>
)

Qq.propTypes = propTypes
export default Qq
