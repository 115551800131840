import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const LiveHelp = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/communications/live_help-live-help${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <g
        fill="inherit"
        id={`group-live-help${unique}`}
        transform="translate(8.750000, 5.000000)"
      />
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`grey600-live-help${unique}`}
        xlinkHref={`#path-1-live-help${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-live-help${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,16 C21,16.5522847 20.5522847,17 20,17 L7,17 L3.87370466,20.5170823 C3.69024565,20.7234736 3.37420946,20.742064 3.16781808,20.558605 C3.0610739,20.4637213 3,20.3277192 3,20.1849003 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M12.0621616,5.51948052 C10.6423636,5.51948052 9.71824242,6.19109091 9.27630303,6.8627013 C9.02175758,7.25012987 9.22272727,7.43090909 9.35662626,7.52137662 L9.97278788,7.9345974 C10.1736768,8.07672727 10.4013131,8.06379221 10.5487071,7.87007792 C10.9103232,7.39218182 11.3656768,7.06935065 11.9281818,7.06935065 C12.6514141,7.06935065 13.080101,7.5212987 13.080101,8.05085714 C13.080101,9.1487013 11.2719394,9.97522078 11.2719394,11.2667013 L11.2719394,11.5508831 C11.2719394,11.7059481 11.3925051,11.8221299 11.5398182,11.8221299 L12.718404,11.8221299 C12.8523838,11.8221299 12.9862828,11.7317403 12.9862828,11.5638182 L12.9862828,11.4734286 C12.9862828,10.4918442 14.9284242,9.65238961 14.9284242,7.90880519 C14.9284242,6.69477922 13.9372323,5.51948052 12.0621616,5.51948052 Z M12.1291515,14.8701299 C12.7586465,14.8701299 13.2675758,14.3793766 13.2675758,13.7852208 C13.2675758,13.1911429 12.7586465,12.7003896 12.1291515,12.7003896 C11.4996566,12.7003896 10.9906465,13.1911429 10.9906465,13.7852208 C10.9906465,14.3793766 11.4996566,14.8701299 12.1291515,14.8701299 Z"
          fill="inherit"
          id={`path-1-live-help${unique}`}
        />
      </g>
    </g>
  </svg>
)

LiveHelp.propTypes = propTypes
export default LiveHelp
