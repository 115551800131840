import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const ArrowCircleLeft = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/arrow_circle_left-arrow-circle-left${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`ic_cancel_24px-arrow-circle-left${unique}`}
        xlinkHref={`#path-1-arrow-circle-left${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-arrow-circle-left${unique}`}>
        <path
          d="M12,2 C17.53,2 22,6.47 22,12 C22,17.53 17.53,22 12,22 C6.47,22 2,17.53 2,12 C2,6.47 6.47,2 12,2 Z M9.24590637,11.0018565 L12.7539481,7.49381483 C12.9492102,7.29855269 12.9492102,6.9819702 12.7539481,6.78670805 L12.0895531,6.1223131 C11.894291,5.92705096 11.5777085,5.92705096 11.3824464,6.1223131 L6.50698937,10.9977701 C6.18508546,11.3217172 6.02413351,11.6456644 6.02413351,11.9696115 C6.02413351,12.2935586 6.18508546,12.6175058 6.50698937,12.9414529 L11.3824464,17.8169099 C11.5777085,18.012172 11.894291,18.012172 12.0895531,17.8169099 L12.7539481,17.1525149 C12.9492102,16.9572528 12.9492102,16.6406703 12.7539481,16.4454081 L9.24999283,12.9414529 L17.4633565,12.9414529 C17.7394989,12.9414529 17.9633565,12.7175953 17.9633565,12.4414529 L17.9633565,11.5018565 C17.9633565,11.2257142 17.7394989,11.0018565 17.4633565,11.0018565 L9.24590637,11.0018565 Z"
          fill="inherit"
          id={`path-1-arrow-circle-left${unique}`}
        />
      </g>
    </g>
  </svg>
)

ArrowCircleLeft.propTypes = propTypes
export default ArrowCircleLeft
