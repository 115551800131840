


import React from "react"
import { string } from "prop-types"

const propTypes = {
    unique: string,
}

const WosLoginIcon = ({ unique = "", ...props }) => (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="#3253DC" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M3.66138 16.5001C3.66138 13.1864 6.34767 10.5 9.66138 10.5V10.5C12.9751 10.5 15.6614 13.1864 15.6614 16.5001V16.5001H3.66138V16.5001Z" stroke="#3253DC"/>
        <circle cx="9.66138" cy="4.5" r="3" stroke="#3253DC"/>
    </svg>
)

WosLoginIcon.propTypes = propTypes
export default WosLoginIcon
