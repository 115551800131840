import React, { useCallback } from "react";
import {
  array,
  arrayOf,
  func,
  number,
  objectOf,
  oneOfType,
  shape,
  object,
  string,
} from "prop-types";
import { test } from "ramda";

import Link from "components/link"
import CtaGroupItemComponent from "./CtaGroupItemComponent"
import {useSelector} from "react-redux";
import {IS_DDP_TEMPLATE} from "../../../lib/redux/reducers/globals/globals-selectors";

const propTypes = {
  cta: oneOfType(object,shape({
    attributes: shape({
      color: string,
      hoverColor: string,
      positioning: string,
      style: string,
      target: string,
      textColor: string,
    }),
    linkHandler: func,
    location: shape({
      state: {
        text: string,
        type: string,
      },
    }),
    targetId: string,
    targetType: string,
    title: string,
    url: string,
    urltype: string,
  })),
  ctaType: string,
  hoverColor: string,
  hoverTextColor: string,
  references: oneOfType([arrayOf(string), func]),
  size: string,
  styles: objectOf(oneOfType([string, number, array])),
  textColor: string,
  urltype: string,
}

const CtaGroupItemContainer = ({
  children,
  cta,
  references = [],
  styles,
  textColor,
  hoverColor,
  hoverTextColor,
  ctaType,
  urltype,
  width,
  paddingInline,
  paddingStacked,
  isGroupStacked,

                                ...rest
}) => {
  const isDdp = useSelector(IS_DDP_TEMPLATE)
  const attributes = cta?.attributes || {}
  const { color, positioning, target, style } = attributes
  const {
    linkHandler,
    location,
    targetId,
    targetType,
    title,
    url,
    brightcoveVideo,
  } = cta
  const tag = useCallback(
    (props) => {
      const configuredLocation = test(
        /.pdf|.doc|.docx|.ppt|.pptx|.zip|.xls|.xlxs|.txt|.ics/,
        url,
      )
        ? {
          label: title,
          state: {
            document: title,
            label: title,
            loc: url,
            text: "document",
            type: "cta",
          },
        }
        : {
          state: {
            text: title,
            type: "CTA",
          },
        }

      return (
        <Link
          linkHandler={linkHandler}
          location={location || configuredLocation}
          {...props}
        />
      )
    },
    [linkHandler, location, title, url],
  )

  const computedProps =
    targetType === "file"
      ? {
        includeProps: ["location", "url", "target", "rel"],
        rel: "noopener",
        target: "_blank",
      }
      : {}
  return (
    <CtaGroupItemComponent
      color={color}
      ctaType={ctaType}
      hoverColor={hoverColor}
      hoverTextColor={hoverTextColor}
      icon={isDdp?"right-arrow":(style || "chevron")}
      inverted={textColor !== "dark"}
      positioning={positioning}
      references={references}
      size={attributes?.size}
      styles={styles}
      tag={tag}
      target={target}
      targetId={targetId}
      targetType={targetType}
      textColor={textColor}
      title={title}
      url={url}
      urltype={urltype}
      width={width}
      paddingInline={paddingInline}
      paddingStacked={paddingStacked}
      isGroupStacked={isGroupStacked}
      {...computedProps}
      brightcoveVideo={targetType === "video" ? brightcoveVideo : null}
      children={children}
      {...rest}
    />
  )
}

CtaGroupItemContainer.propTypes = propTypes

export default CtaGroupItemContainer
