import React from "react"

import { cn } from "../../../../lib/utils/cn-helper"
import { IconProps, IconVariants } from "./Icon.interface"
import Glyph from "../../../glyph"

export const Icon = ({
  glyphId: localGlyphId,
  remoteIconPath,
  variant,
  size,
  alt,
  className
}: IconProps) => {
  return (
    <>
      {!!localGlyphId || !!remoteIconPath ? (
        <div className={cn(IconVariants({ variant, size, className }))}>
          {!remoteIconPath && localGlyphId && <Glyph id={localGlyphId} />}
          {!localGlyphId && remoteIconPath && <img src={remoteIconPath} alt={alt || "default icon alt text"} />}
        </div>
      ) : null}
    </>
  )
}
