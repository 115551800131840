import { useEffect, useState } from "react";

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      /*
       * Return a cleanup function that will be called every time useEffect is re-called.
       * This is how we prevent debouncedValue from changing if value is changed within the delay period.
       * Timeout gets cleared and restarted.  To put it in context, if the user is typing within our app's
       * search box, we don't want the debouncedValue to update until they've stopped typing for more than
       * the supplied delay.
       */
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    [delay, value],
  );

  return debouncedValue;
}

export default useDebounce;
