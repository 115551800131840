import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Warning = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/alert/warning-warning${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`grey600-warning${unique}`}
        xlinkHref={`#path-1-warning${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-warning${unique}`}>
        <path
          d="M2.97425587,20.6470588 L21.0258794,20.6470588 C21.5637888,20.6470588 22,20.2082398 22,19.6668956 C21.999986,19.4944529 21.9547586,19.3250658 21.8688834,19.1758339 L12.8430716,3.48910604 C12.6689158,3.18639237 12.3476386,3 12.0000189,3 C11.6523992,3 11.3311221,3.18639237 11.1569663,3.48910604 L2.13125191,19.1758339 C1.86171274,19.6443519 2.02059181,20.2439177 2.48622146,20.5151289 C2.63453341,20.6015368 2.80287622,20.6470447 2.97425587,20.6470588 Z M11.5,9 L12.5,9 C12.7761424,9 13,9.22385763 13,9.5 L13,13.5 C13,13.7761424 12.7761424,14 12.5,14 L11.5,14 C11.2238576,14 11,13.7761424 11,13.5 L11,9.5 C11,9.22385763 11.2238576,9 11.5,9 Z M11.5,16 L12.5,16 C12.7761424,16 13,16.2238576 13,16.5 L13,17.5 C13,17.7761424 12.7761424,18 12.5,18 L11.5,18 C11.2238576,18 11,17.7761424 11,17.5 L11,16.5 C11,16.2238576 11.2238576,16 11.5,16 Z"
          fill="inherit"
          id={`path-1-warning${unique}`}
        />
      </g>
    </g>
  </svg>
)

Warning.propTypes = propTypes
export default Warning
