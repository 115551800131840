import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Robotics = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/applications/robotics-robotics${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-robotics${unique}`}
        xlinkHref={`#path-1-robotics${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-robotics${unique}`}>
        <path
          d="M13.1866009,7.05054165 L12.1705442,5.94999981 L9.42323984,9 L12.5430108,12 L18,12 C18.5522847,12 19,12.4477153 19,13 L19,13.2560586 C18.5308316,13.0902313 18.0259531,13 17.5,13 C15.0147186,13 13,15.0147186 13,17.5 C13,17.6690045 13.0093167,17.8358331 13.0274644,18 L8.96455557,18 C8.72194074,16.3038529 7.26323595,15 5.5,15 C4.96320135,15 4.45462769,15.1208455 4,15.3368156 L4,13 C4,12.4477153 4.44771525,12 5,12 L8.67117901,12 L7.04397797,10.3725038 C6.27952896,9.60791609 6.26111977,8.37421929 7.00241519,7.58716293 L10.6662859,3.69711766 C11.4236094,2.89304375 12.6893721,2.85514509 13.493446,3.61246862 C13.5383159,3.65472966 13.5812081,3.6990424 13.6219853,3.74526492 L15.1700001,5.5 L15.1483139,5.52454307 C15.2632191,5.5083658 15.3806326,5.5 15.5,5.5 C16.8807119,5.5 18,6.61928813 18,8 C18,8.27614237 17.7761424,8.5 17.5,8.5 C17.2238576,8.5 17,8.27614237 17,8 C17,7.17157288 16.3284271,6.5 15.5,6.5 C14.6715729,6.5 14,7.17157288 14,8 C14,8.82842712 14.6715729,9.5 15.5,9.5 C15.7761424,9.5 16,9.72385763 16,10 C16,10.2761424 15.7761424,10.5 15.5,10.5 C14.1192881,10.5 13,9.38071187 13,8 C13,7.66389804 13.0663251,7.34328714 13.1866009,7.05054165 Z M5.5,21 C4.11928813,21 3,19.8807119 3,18.5 C3,17.1192881 4.11928813,16 5.5,16 C6.88071187,16 8,17.1192881 8,18.5 C8,19.8807119 6.88071187,21 5.5,21 Z M17.5,21 C15.5670034,21 14,19.4329966 14,17.5 C14,15.5670034 15.5670034,14 17.5,14 C19.4329966,14 21,15.5670034 21,17.5 C21,19.4329966 19.4329966,21 17.5,21 Z M12.1999998,5.5999999 C12.7522846,5.5999999 13.1999998,5.15228465 13.1999998,4.5999999 C13.1999998,4.04771515 12.7522846,3.5999999 12.1999998,3.5999999 C11.6477151,3.5999999 11.1999998,4.04771515 11.1999998,4.5999999 C11.1999998,5.15228465 11.6477151,5.5999999 12.1999998,5.5999999 Z M8,10 C8.55228475,10 9,9.55228475 9,9 C9,8.44771525 8.55228475,8 8,8 C7.44771525,8 7,8.44771525 7,9 C7,9.55228475 7.44771525,10 8,10 Z"
          fill="inherit"
          id={`path-1-robotics${unique}`}
        />
      </g>
    </g>
  </svg>
)

Robotics.propTypes = propTypes
export default Robotics
