import { curry, join, map, pipe, reduce } from "ramda";
import { transitions } from "polished";

import { defaultEase } from "./variables";

const transitionMapper = (ease, duration, properties) =>
  map((property) => `${property} ${duration} ${ease}`, properties);

// transition :: String -> String -> [String] -> [String]
export const transition = curry((ease, duration, properties) =>
  transitions(...transitionMapper(ease, duration, properties)),
);

// transitionEase :: String -> [String] -> [String]
export const transitionEase = transition(defaultEase);

const transitionProperties = curry((ease, duration, properties) =>
  transitionMapper(ease, duration, properties),
);

export const transitionEaseInline = pipe(
  transitionProperties(defaultEase),
  join(", "),
);

// defaultTransition :: (String, ..., String) -> [String]
export const defaultTransition = (...properties) =>
  transitionEase("0.7s", properties);

// toPx :: String | Number -> String
export const toPx = (num) => `${num}px`;

// toPercentage :: String | Number -> String
export const toPercentage = (num) => `${num}%`;

// mapStyle :: String -> String | Number -> [String]
export const mapStyle = curry((property, value) => `${property}: ${value};`);

// mapStyles :: [String] -> String | Number -> [String]
export const mapStyles = curry((properties, value) =>
  reduce(
    (accum, property) => `
      ${accum}
      ${mapStyle(property, value)}
    `,
    "",
    properties,
  ),
);

// colorFill: String -> String
export const colorFill = mapStyles(["color", "fill"]);

// fontSize :: String | Number -> String
export const fontSize = mapStyle("font-size");

// transX :: String | Number -> String
export const transX = (val) => `translate3d(${val}, 0, 0)`;
/*
 *
 * transY :: String | Number -> String
 */
export const transY = (val) => `translate3d(0, ${val}, 0)`;

// translateX :: String | Number -> [String]
export const translateX = (val) => mapStyle("transform", transX(val));

// translateY :: String | Number -> [String]
export const translateY = (val) => mapStyle("transform", transY(val));

// marginDir :: String -> String | Number -> [String]
export const marginDir = (dir) => mapStyle(`margin-${dir}`);

// marginTop :: String | Number -> [String]
export const marginTop = marginDir("top");

// marginBottom :: String | Number -> [String]
export const marginBottom = marginDir("bottom");

// marginLeft :: String | Number -> [String]
export const marginLeft = marginDir("left");

// marginRight :: String | Number -> [String]
export const marginRight = marginDir("right");
