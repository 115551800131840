import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const LockCopy = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/security/lock-copy-lock-copy${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-copy-lock-copy${unique}`}
        xlinkHref={`#path-1-lock-copy${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-lock-copy${unique}`}>
        <path
          d="M7,9 L7,6 C7,3.790861 8.790861,2 11,2 L13,2 C15.209139,2 17,3.790861 17,6 L17,6.5 C17,6.77614237 16.7761424,7 16.5,7 L15.5,7 C15.2238576,7 15,6.77614237 15,6.5 L15,6 C15,4.8954305 14.1045695,4 13,4 L11,4 C9.8954305,4 9,4.8954305 9,6 L9,9 L18,9 C18.5522847,9 19,9.44771525 19,10 L19,21 C19,21.5522847 18.5522847,22 18,22 L6,22 C5.44771525,22 5,21.5522847 5,21 L5,10 C5,9.44771525 5.44771525,9 6,9 L7,9 Z M12.9210096,15.6840384 L13.5,18 L10.5,18 L11.0789904,15.6840384 C10.7266317,15.4095613 10.5,14.981247 10.5,14.5 C10.5,13.6715729 11.1715729,13 12,13 C12.8284271,13 13.5,13.6715729 13.5,14.5 C13.5,14.981247 13.2733683,15.4095613 12.9210096,15.6840384 Z M7.5,11 C7.22385763,11 7,11.2238576 7,11.5 L7,19.5 C7,19.7761424 7.22385763,20 7.5,20 L16.5,20 C16.7761424,20 17,19.7761424 17,19.5 L17,11.5 C17,11.2238576 16.7761424,11 16.5,11 L7.5,11 Z"
          fill="inherit"
          id={`path-1-lock-copy${unique}`}
        />
      </g>
    </g>
  </svg>
)

LockCopy.propTypes = propTypes
export default LockCopy
