import {authToken} from "config"

/**
 * Checks for the existence of an auth cookie on the document or express object
 * @param {Express.Request} [request=null] If checking server-side, pass the express request
 * @returns {Boolean} boolean of auth cookie existence
 */
export const hasAuthCookie = (request = null) =>
 // process.env.BUILD_TARGET === "client"
   true ? Boolean(
        document && document.cookie && document.cookie.includes(authToken),
      )
    : Boolean(request && request.cookies && !!request.cookies[authToken])

// eslint-disable-next-line no-return-assign
export const removeClientAuthCookie = () =>
  document
    ? (document.cookie = `${authToken}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.qualcomm.com;`)
    : false

/**
 * Sets a cookie informing the browser to expire the auth token
 * @param {Express.Response} [response] Response object to set null cookie
 */
export const removeServerAuthCookie = (response) =>
  response && response.clearCookie
    ? response.clearCookie(authToken, {
        domain: ".qualcomm.com",
        path: "/",
      })
    : false

/**
 * Removes both client and server
 * @param {Express.Response} [response=null] Response object to set null cookie
 */
export const removeAuthCookie = (response = null) =>
  process.env.BUILD_TARGET === "client"
    ? removeClientAuthCookie()
    : removeServerAuthCookie(response)
