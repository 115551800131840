import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Unsubscribe = ({...props}) => (
  <svg
    height="16"
    version="1.1"
    viewBox="0 0 19 16"
    width="19"
    x="0"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    y="0"
    {...props}
  >
    <path
      d="M16.8.6H2.2C1.2.6.5 1.4.5 2.3v11.3c0 1 .8 1.7 1.7 1.7h14.5c1 0 1.7-.8 1.7-1.7V2.4c.1-1-.7-1.8-1.6-1.8zm.8 2.1L9.7 9.6c-.2.1-.3.1-.5.1s-.4 0-.5-.2L1.6 2.9c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0L9.2 8l7.4-6.4c.3-.3.8-.2 1.1.1.2.2.2.7-.1 1z"
      fill="inherit"
    />
  </svg>
)

Unsubscribe.propTypes = propTypes
export default Unsubscribe
