import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}
const ChevronDown = (props) => (
  <svg {...props} fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M4 6L8 10L12 6" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
    </g>
  </svg>
)

ChevronDown.propTypes = propTypes
export default ChevronDown

