import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Bookmark = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/bookmark-bookmark${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`rectangle-bookmark${unique}`}
        xlinkHref={`#path-1-bookmark${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-bookmark${unique}`}>
        <path
          d="M6,2 L18,2 C19.1045695,2 20,2.8954305 20,4 L20,21.190983 C20,21.4671254 19.7761424,21.690983 19.5,21.690983 C19.4223775,21.690983 19.3458209,21.6729105 19.2763932,21.6381966 L12,18 L4.7236068,21.6381966 C4.47661755,21.7616912 4.17628103,21.6615791 4.0527864,21.4145898 C4.01807255,21.3451621 4,21.2686056 4,21.190983 L4,4 C4,2.8954305 4.8954305,2 6,2 Z"
          fill="inherit"
          id={`path-1-bookmark${unique}`}
        />
      </g>
    </g>
  </svg>
)

Bookmark.propTypes = propTypes
export default Bookmark
