import {append, includes, propEq, reject} from "ramda"

import {getCookie} from "lib/cookies"

export const ADD_DROPDOWN_ITEM = "ADD_DROPDOWN_ITEM"
export const ADD_GROWLER_ITEM = "ADD_GROWLER_ITEM"
export const BLOCKER_CLICK = "BLOCKER_CLICK"
export const CLEAR_ALL_MEGA_MENU_SEARCH_FILTERS =
  "CLEAR_ALL_MEGA_MENU_SEARCH_FILTERS"
export const CLOSE_MAIN_MENU = "CLOSE_MAIN_MENU"
export const CLEAR_GROWLER_ITEMS = "CLEAR_GROWLER_ITEMS"
export const CLOSE_LOGIN_PANEL = "CLOSE_LOGIN_PANEL"
export const REMOVE_DROPDOWN_ITEM = "REMOVE_DROPDOWN_ITEM"
export const REMOVE_GROWLER_ITEM = "REMOVE_GROWLER_ITEM"
export const SET_ACTIVE_DROPDOWN = "SET_ACTIVE_DROPDOWN"
export const SET_CURRENT_THEME = "SET_CURRENT_THEME"
export const SET_IMPERSONATION_STATUS = "SET_IMPERSONATION_STATUS"
export const SET_MAIN_MENU_ACTIVE_ITEM = "SET_MAIN_MENU_ACTIVE_ITEM"
export const SET_MAIN_MENU_ACTIVE_ITEM_NOTRACK = "SET_MAIN_MENU_ACTIVE_ITEM_NOTRACK"
export const SET_PREVIOUS_MENU_ACTIVE_ITEM = "SET_PREVIOUS_MENU_ACTIVE_ITEM"
export const SET_SEARCH_TIPS_MODAL_ACTIVE = "SET_SEARCH_TIPS_MODAL_ACTIVE"
export const SET_SEARCH_TIPS_MODAL_VALUE = "SET_SEARCH_TIPS_MODAL_VALUE"
export const SET_SHOW_AGREEMENTS = "SET_SHOW_AGREEMENTS"
export const SET_SHOW_IMPERSONATION = "SET_SHOW_IMPERSONATION"
export const SET_SHOW_LOGIN_PANEL = "SET_SHOW_LOGIN_PANEL"
export const SET_SHOWING_BLOCKER = "SET_SHOWING_BLOCKER"
export const SET_BREADCRUMB_OPEN = "SET_BREADCRUMB_OPEN"
export const TOGGLE_MEGA_MENU_SEARCH_FILTER = "TOGGLE_MEGA_MENU_SEARCH_FILTER"
export const SET_SEARCH_PLACEHOLDER = "SET_SEARCH_PLACEHOLDER"
export const SET_SEARCH_TIPS_TITLE = "SET_SEARCH_TIPS_TITLE"
export const SET_BREADCRUMB_DATA = "SET_BREADCRUMB_DATA"
export const SET_BREADCRUMB_HIDE_SHOW = "SET_BREADCRUMB_HIDE_SHOW"
export const SET_NAVIGATION_HIDE_SHOW = "SET_NAVIGATION_HIDE_SHOW"
export const SET_SNAPDRAGON_INSIDER_ACTIVE = "SET_SNAPDRAGON_INSIDER_ACTIVE"
export const SET_PDP_BANNER = "SET_PDP_BANNER"
export const SET_PDP_BANNER_UPDATE = "SET_PDP_BANNER_UPDATE"
export const SET_PDP_BANNER_HEIGHT = "SET_PDP_BANNER_HEIGHT"
export const SET_SALES_QUOTE_MODAL_ACTIVE = "SET_SALES_QUOTE_MODAL_ACTIVE"
export const SET_SALES_QUOTE_MODAL_DISABLE = "SET_SALES_QUOTE_MODAL_DISABLE"
export const SET_SUBSCRIBE_MODAL_ACTIVE = "SET_SUBSCRIBE_MODAL_ACTIVE"
export const SET_SUBSCRIBE_MODAL_PROCESSING = "SET_SUBSCRIBE_MODAL_PROCESSING"
export const SET_SECONDARYNAVSTICKY_STATE = "SET_SECONDARYNAVSTICKY_STATE"
export const SET_PRODUCTTABS_STICKY_STATE = "SET_PRODUCTTABS_STICKY_STATE"
export const SET_TABS_STICKY_STATE = "SET_TABS_STICKY_STATE"
export const SET_RFFETABS_STICKY_STATE = "SET_RFFETABS_STICKY_STATE"
export const SET_SNAPDRAGONTABS_STICKY_STATE = "SET_SNAPDRAGONTABS_STICKY_STATE"
export const SET_PRODUCTTABSSTICKYNAVVIEW_STICKY_STATE =
  "SET_PRODUCTTABSSTICKYNAVVIEW_STICKY_STATE"
export const SET_BLOG_ARTICLE_TYPE = "SET_BLOG_ARTICLE_TYPE"
export const THEME_CHANGED = "THEME_CHANGED"
export const SET_SHOW_DOWNLOAD_MODAL = "SET_SHOW_DOWNLOAD_MODAL"

export const LIGHT_THEME_LABEL = "Light"
export const DARK_THEME_LABEL = "Dark"
export const AUTO_THEME_LABEL = "Auto"

export const LIGHT_THEME_ID = "light"
export const DARK_THEME_ID = "dark"
export const AUTO_THEME_ID = "auto"

export const LIGHT_THEME_VALUE = "light"
export const DARK_THEME_VALUE = "dark"
export const AUTO_THEME_VALUE = "auto"
export const SET_WOS_XP_MODAL_ACTIVE = "SET_WOS_XP_MODAL_ACTIVE"
export const SET_WOS_XP_PATH = "SET_WOS_XP_PATH"

export const THEME_OPTIONS = [
  {
    label:LIGHT_THEME_LABEL,
    value:LIGHT_THEME_VALUE,
    id:LIGHT_THEME_ID
  },
  {
    label:DARK_THEME_LABEL,
    value:DARK_THEME_VALUE,
    id:DARK_THEME_ID,
  },
  {
    label:AUTO_THEME_LABEL,
    value:AUTO_THEME_VALUE,
    id:AUTO_THEME_ID
  }
]

export const initialState = {
  activeDropdown: null,
  activeItems: [],
  activeMenuItemIds: [],
  breadcrumbData: false,
  breadcrumbOpen: false,
  currentTheme: "light",
  currentThemeSetting: "light",
  downloadModalDoc: {},
  dropdowns: [],
  fixed: false,
  growler: [],
  hoverItem: null,
  impersonating: false,
  isSalesQuoteModalActive: false,
  isSearchTipsModalActive: false,
  isSnapdragonInsider: false,
  searchModalValue: "",
  showAgreements: false,
  showDownloadModal: false,
  showImpersonation: false,
  showLoginPanel: false,
  showhidebreadcrumbinpage: false,
  showhidenavigationinpage: false,
  showingBlocker: null,
}

export const getOSThemingOption =(fromCookie)=>{
  const uiTheme = getCookie("themeSetting")
  const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)")
  const preference = prefersDarkScheme?.matches ? "dark" : "light"
  if(fromCookie){
    return uiTheme ||  AUTO_THEME_VALUE
  }
  return preference || uiTheme

}

const reducer = {
  [ADD_DROPDOWN_ITEM]: (state, {value}) => ({
    ...state,
    dropdowns: append(value, state.dropdowns),
  }),
  [ADD_GROWLER_ITEM]: (state, {value}) => {
    const growlers = state?.growler
    if (includes(value, growlers)) {
      return state
    }
    return {
      ...state,
      growler: append(value, growlers),
    }
  },
  [BLOCKER_CLICK]: (state) => ({
    ...state,
    activeDropdown: null,
    activeItems: [],
    showingBlocker: false,
  }),
  [CLEAR_GROWLER_ITEMS]: (state) => ({
    ...state,
    growler: [],
  }),
  [CLOSE_LOGIN_PANEL]: (state) => ({
    ...state,
    showLoginPanel: false,
  }),
  [CLOSE_MAIN_MENU]: (state) => ({
    ...state,
    activeItems: [],
    activeMenuItemIds: [],
  }),
  [REMOVE_DROPDOWN_ITEM]: (state, {value}) => ({
    ...state,
    dropdowns: reject(propEq("id", value), state.dropdowns),
  }),
  [REMOVE_GROWLER_ITEM]: (state, {value}) => ({
    ...state,
    growler: reject(propEq("id", value), state.growler),
  }),
  [SET_ACTIVE_DROPDOWN]: (state, {value}) => ({
    ...state,
    activeDropdown: value,
  }),
  [SET_BLOG_ARTICLE_TYPE]: (state, {value}) => ({
    ...state,
    blogarticletype: value,
  }),
  [SET_BREADCRUMB_DATA]: (state, {value}) => ({
    ...state,
    breadcrumbData: value,
  }),
  [SET_BREADCRUMB_HIDE_SHOW]: (state, {value}) => ({
    ...state,
    showhidebreadcrumbinpage: value,
  }),
  [SET_BREADCRUMB_OPEN]: (state, {value}) => ({
    ...state,
    breadcrumbOpen: value,
  }),
  [SET_IMPERSONATION_STATUS]: (state, {value}) => ({
    ...state,
    impersonating: value,
  }),
  [SET_MAIN_MENU_ACTIVE_ITEM]: (state, {value}) => ({
    ...state,
    activeItems: value,
  }),
  [SET_MAIN_MENU_ACTIVE_ITEM_NOTRACK]: (state, {value}) => ({
    ...state,
    activeItems: value,
  }),
  [SET_PREVIOUS_MENU_ACTIVE_ITEM]: (state, {value}) => ({
    ...state,
    previousItems: value,
  }),
  [SET_NAVIGATION_HIDE_SHOW]: (state, {value}) => ({
    ...state,
    showhidenavigationinpage: value,
  }),
  [SET_PDP_BANNER]: (state, {value}) => ({
    ...state,
    pdpBanner: value,
  }),
  [SET_PDP_BANNER_HEIGHT]: (state, {value}) => ({
    ...state,
    pdpBannerHeight: value,
  }),
  [SET_PDP_BANNER_UPDATE]: (state, {value}) => ({
    ...state,
    pdpBanner: {...state.pdpBanner, ...value},
  }),
  [SET_PRODUCTTABS_STICKY_STATE]: (state, {value}) => ({
    ...state,
    productstabsticky: value,
  }),
  [SET_PRODUCTTABSSTICKYNAVVIEW_STICKY_STATE]: (state, {value}) => ({
    ...state,
    producttabsstickyNav: value,
  }),
  [SET_RFFETABS_STICKY_STATE]: (state, {value}) => ({
    ...state,
    rffetabsticky: value,
  }),
  [SET_SALES_QUOTE_MODAL_ACTIVE]: (state, {value}) => ({
    ...state,
    isSalesQuoteModalActive: value,
  }),
  [SET_SALES_QUOTE_MODAL_DISABLE]: (state, {value}) => ({
    ...state,
    isSalesQuoteModalDisable: value,
  }),
  [SET_SEARCH_PLACEHOLDER]: (state, {value}) => ({
    ...state,
    searchPlaceholder: value,
  }),
  [SET_SEARCH_TIPS_MODAL_ACTIVE]: (state, {value}) => ({
    ...state,
    isSearchTipsModalActive: value,
  }),
  [SET_SEARCH_TIPS_MODAL_VALUE]: (state, {value}) => ({
    ...state,
    searchModalValue: value,
  }),
  [SET_SEARCH_TIPS_TITLE]: (state, {value}) => ({
    ...state,
    searchTipsTitle: value,
  }),
  [SET_SECONDARYNAVSTICKY_STATE]: (state, {value}) => ({
    ...state,
    secondarynavstickystate: value,
  }),
  [SET_SHOW_AGREEMENTS]: (state, {value}) => ({
    ...state,
    showAgreements: value,
  }),
  [SET_SHOW_DOWNLOAD_MODAL]: (state, {showDownloadModal, downloadModalDoc}) => ({
    ...state,
    downloadModalDoc,
    showDownloadModal
  }),
  [SET_SHOW_IMPERSONATION]: (state, {value}) => ({
    ...state,
    showImpersonation: value,
    showingBlocker: value,
  }),
  [SET_SHOW_LOGIN_PANEL]: (state, {value}) => ({
    ...state,
    showLoginPanel: value,
  }),
  [SET_SHOWING_BLOCKER]: (state, {value = false}) => ({
    ...state,
    showingBlocker: value,
  }),
  [SET_SNAPDRAGON_INSIDER_ACTIVE]: (state, {value}) => ({
    ...state,
    isSnapdragonInsider: value,
  }),
  [SET_SNAPDRAGONTABS_STICKY_STATE]: (state, {value}) => ({
    ...state,
    snapdragontabsticky: value,
  }),
  [SET_SUBSCRIBE_MODAL_ACTIVE]: (
    state,
    {showSubscribeModal = false, subscribeModalProps},
  ) => ({
    ...state,
    showSubscribeModal,
    subscribeModalProps,
    subscribeProcessing: false,
  }),
  [SET_SUBSCRIBE_MODAL_PROCESSING]: (state, {subscribeProcessing = false}) => ({
    ...state,
    subscribeProcessing,
  }),
  [SET_TABS_STICKY_STATE]: (state, {value}) => ({
    ...state,
    tabsticky: value,
  }),
  [THEME_CHANGED]: (state) => {
    const themeSetting = getCookie("themeSetting")
    return {
      ...state,
      currentTheme: themeSetting === AUTO_THEME_VALUE ? getOSThemingOption(false) : themeSetting,
      currentThemeSetting: themeSetting,
    }
  },
  [SET_WOS_XP_MODAL_ACTIVE]: (state, {value}) => ({
    ...state,
    isWosXpModalActive: value,
  }),
  [SET_WOS_XP_PATH]: (state, {value}) => ({
    ...state,
    isWosXpPath: value,
  }),
  [SET_WOS_XP_MODAL_ACTIVE]: (state, {value}) => ({
    ...state,
    isWosXpModalActive: value,
  }),
  [SET_WOS_XP_PATH]: (state, {value}) => ({
    ...state,
    isWosXpPath: value,
  })
}

function uiReducer(state = initialState, action) {
  const func = reducer[action.type]
  return func ? func(state, action) : state
}

export default uiReducer
