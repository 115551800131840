import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { sha256 } from "crypto-hash";

import { createErrorHttpLink, createPersistedQueryLink } from "./middleware";
import { setHttpMethod } from "./context";
import { cacheConfig } from "lib/apolloClient/cache";

export function getMeta(metaName) {
  const metas = document.getElementsByTagName("meta");
  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute("name") === metaName) {
      return metas[i].getAttribute("content");
    }
  }
  return null;
}

const graphQLUrl = getMeta("graphqlEndpointUrl");

// Generates a unique ID for every graphQL request, allowing caching
const persistedLink = createPersistedQueryLink({
  sha256,
  useGETForHashedQueries: true,
});

const defaultLink = new HttpLink({
  credentials: "include",
  uri: graphQLUrl || "https://www.qualcomm.com/graphql",
});

// apollo client singleton
const internals = {
  apolloClient: null,
  errorLink: null,
  link: null,
};

const createClient = ({ state, store } = {}) => {
  if (!internals.errorLink) {
    internals.errorLink = createErrorHttpLink(store);
  }
  if (!internals.link) {
    const links = [internals.errorLink, defaultLink];
    const oneDxLink = [persistedLink, setHttpMethod, ...links];
    internals.link = from(oneDxLink);
  }

  if (!internals.apolloClient) {
    internals.apolloClient = new ApolloClient({
      cache: new InMemoryCache(cacheConfig).restore(state),
      link: internals.link,
      name: "React Client",
    });
  }
  return internals.apolloClient;
};

export const getApolloLink = () => internals.link;
export default createClient;
