import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Trash = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/trash-trash${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-trash${unique}`}
        xlinkHref={`#path-1-trash${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-trash${unique}`}>
        <path
          d="M8,3 L8.85355339,2.14644661 C8.94732158,2.05267842 9.07449854,2 9.20710678,2 L14.7928932,2 C14.9255015,2 15.0526784,2.05267842 15.1464466,2.14644661 L16,3 L19.5,3 C19.7761424,3 20,3.22385763 20,3.5 L20,4.5 C20,4.77614237 19.7761424,5 19.5,5 L4.5,5 C4.22385763,5 4,4.77614237 4,4.5 L4,3.5 C4,3.22385763 4.22385763,3 4.5,3 L8,3 Z M5,6 L19,6 L18.0586014,21.0623783 C18.0256617,21.5894132 17.5886121,22 17.0605488,22 L6.93945122,22 C6.41138794,22 5.97433833,21.5894132 5.94139864,21.0623783 L5,6 Z"
          fill="inherit"
          id={`path-1-trash${unique}`}
        />
      </g>
    </g>
  </svg>
)

Trash.propTypes = propTypes
export default Trash
