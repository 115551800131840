import { KeyboardEvent, MouseEvent } from "react"

export enum TargetTypeValueArray {
  TARGET_TYPE_BRIGHTCOVE = "video",
  TARGET_TYPE_CALL_BACK = "call_back",
  TARGET_TYPE_DAM_FILE = "file",
  TARGET_TYPE_DAM_VIDEO = "dam_video",
  TARGET_TYPE_EMPTY = ""
}
export enum TargetValueArray {
  TARGET_SELF = "_self",
  TARGET_BLANK = "_blank",
  TARGET_MODAL_POPUP = "modal-popup"
}

export interface BrightCoveVideoType {
  account: string;
  player: string;
  videoId: string;
  title: string;
  dateTime: string;
  videoThumbnail: string;
  videoOriginal: string;
  videoPoster: string;
  brightcoveViralSharing?: boolean;
}

export type TargetType = "video" | "dam_video" | "file" | ""

export type CallbackMouseEventType = (event: MouseEvent) => void

export interface CtaItemsType {
  animation?: boolean;
  brightcoveVideo?: BrightCoveVideoType;
  callback?: CallbackMouseEventType
  ctaTitle?: string;
  ctaTitleClass?: string;
  disabled?: boolean;
  footerCta?: boolean;
  url: string;
  target: string;
  color: "blue" | "blue-gray" | "dark" | "gray" | "red" | "white";
  size: "small" | "medium" | "text" | "large-icon";
  iconAlign?: "left" | "right";
  iconType: string;
  onClick?: (event: MouseEvent) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  role?: string;
  style: "fill" | "text" | "outline" | "fill-hover-shadow-disabled";
  width:  "full-on-desktop" | "full-on-mobile" | "full" | "auto";
  alignment?: "left" | "right" | "center";
  targetType: string;
  megamenuCta?: boolean

}
export interface CtaV2DxGroupContainerType {
  ctaItems?: CtaItemsType[];
  ctaTitleClass?: string;
  displayType?: "inline" | "stacked";
  alignment?: "left" | "right" | "center" | "space-around" | "space-between";
  title?: string;
}

export interface MapType {
  [key: string]: {
    [key: string]: {
      [key: string]: string;
    };
  };
}