import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const CloudOff = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/file/cloud_off-cloud-off${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-cloud-off${unique}`}
        xlinkHref={`#path-1-cloud-off${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-cloud-off${unique}`}>
        <path
          d="M20.7085197,19.9727554 C20.8142562,20.1629743 20.7863559,20.4075424 20.6248189,20.5690794 L19.9177122,21.2761861 C19.72245,21.4714483 19.4058675,21.4714483 19.2106054,21.2761861 L17.3344189,19.3999996 L5.39999989,19.3999996 C2.96994705,19.3999996 1,17.4300526 1,14.9999997 C1,12.5699469 2.96994705,10.5999998 5.39999989,10.5999998 C5.44619388,10.5999998 5.49222161,10.6007117 5.5380727,10.602125 C5.72684269,9.86717274 6.10070909,9.20645702 6.60703451,8.67261526 L4.55220833,6.61778908 C4.35694618,6.42252693 4.35694618,6.10594444 4.55220833,5.9106823 L5.25931511,5.20357552 C5.45457726,5.00831337 5.77115974,5.00831337 5.96642189,5.20357552 L7.01354103,6.25069466 L7,6.26423569 L20.7085197,19.9727554 Z M21.5322284,17.9680369 L11.0048812,7.44068979 C11.4426556,5.47196474 13.1994297,4 15.2999996,4 C17.7300525,4 19.6999995,5.96994705 19.6999995,8.39999989 C19.6999995,8.85832112 19.6299245,9.30027545 19.4999042,9.71573326 C21.5262738,10.3355605 22.9999995,12.2206766 22.9999995,14.4499997 C22.9999995,15.8260115 22.4385446,17.0708884 21.5322284,17.9680369 Z"
          fill="inherit"
          id={`path-1-cloud-off${unique}`}
        />
      </g>
    </g>
  </svg>
)

CloudOff.propTypes = propTypes
export default CloudOff
