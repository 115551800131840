import { arrayOf, number, oneOfType, shape, string } from "prop-types";

export const responsiveStringPropType = oneOfType([arrayOf(string), string]);
export const responsiveNumberPropType = oneOfType([arrayOf(number), number]);
export const responsiveStringOrNumberPropType = oneOfType([
  arrayOf(oneOfType([number, string])),
  number,
  string,
]);
export const fontPropType = shape({
  color: responsiveStringPropType,
  fontSize: responsiveStringOrNumberPropType,
  fontWeight: responsiveStringOrNumberPropType,
  justifyContent: responsiveStringPropType,
  letterSpacing: responsiveStringOrNumberPropType,
  lineHeight: responsiveStringOrNumberPropType,
  mb: responsiveStringOrNumberPropType,
  textAlign: responsiveStringPropType,
});
