import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Camera = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/applications/camera-camera${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-camera${unique}`}
        xlinkHref={`#path-1-camera${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-camera${unique}`}>
        <path
          d="M7.5,7 L8.7236068,4.5527864 C8.89299881,4.21400238 9.23926193,4 9.61803399,4 L14.381966,4 C14.7607381,4 15.1070012,4.21400238 15.2763932,4.5527864 L16.5,7 L21,7 C21.5522847,7 22,7.44771525 22,8 L22,18 C22,18.5522847 21.5522847,19 21,19 L3,19 C2.44771525,19 2,18.5522847 2,18 L2,8 C2,7.44771525 2.44771525,7 3,7 L7.5,7 Z M12,8.5 C9.52105239,8.5 7.5,10.5210528 7.5,12.9999993 C7.5,15.4789459 9.52105239,17.5 12,17.5 C14.4789476,17.5 16.5,15.4789459 16.5,12.9999993 C16.5,10.5210528 14.4789476,8.5 12,8.5 Z M12,15.5 C10.6294421,15.5 9.5,14.3705592 9.5,13 C9.5,11.6294408 10.6294421,10.5 12,10.5 C13.3705579,10.5 14.5,11.6294408 14.5,13 C14.5,14.3705592 13.3705579,15.5 12,15.5 Z"
          fill="inherit"
          id={`path-1-camera${unique}`}
        />
      </g>
    </g>
  </svg>
)

Camera.propTypes = propTypes
export default Camera
