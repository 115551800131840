export const getCookie = (name) => {
  if (typeof document !== "undefined") {
    const matches = document.cookie.match(
      new RegExp(
        `(?:^|; )${name.replace(/([.$?*|{}()[]\\\/\+^])/g, "\\$1")}=([^;]*)`,
      ),
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  return undefined;
};

export const setCookie = (name, value, opt = {}) => {
  if (typeof document !== "undefined") {
    const options = {
      path: "/",
      // add other defaults here if necessary
      ...opt,
    };

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(
      value,
    )}`;

    Object.keys(options).forEach((optionKey) => {
      updatedCookie += `; ${optionKey}`;
      const optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += `=${optionValue}`;
      }
    });

    document.cookie = updatedCookie;
  }
};

export const deleteCookie = (name) => {
  setCookie(name, "", {
    "max-age": -1,
  });
};

/**
 * Return a Date adding time in hours according the timeToExpire parameter.
 * Eg. timeToExpire = 4 the time will be set 4 hours after the current time,
 * if timeToExpire = 24 mean one day and so on
 * @param timeToExpire time in hours
 * @returns {Date}
 */
export const getExpireTime = (timeToExpire) => {
  const now = new Date();
  const time = now.getTime();
  const expireTime = time + parseInt(timeToExpire, 10) * 3600 * 1000;
  now.setTime(expireTime);
  return now;
};
