import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const ArrowDown = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/arrow_down-arrow-down${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <g fill="inherit" id={`colors/core/gunmetal-arrow-down${unique}`}>
        <path
          d="M6.25216138,12.9385269 L12.7602031,19.4465686 C12.9554652,19.6418308 12.9554652,19.9584133 12.7602031,20.1536754 L12.0958081,20.8180704 C11.900546,21.0133325 11.5839635,21.0133325 11.3887014,20.8180704 L3.51324437,12.9426134 C3.19134046,12.6186662 3.03038851,12.2947191 3.03038851,11.970772 C3.03038851,11.6468248 3.19134046,11.3228777 3.51324437,10.9989306 L11.3887014,3.12347359 C11.5839635,2.92821144 11.900546,2.92821144 12.0958081,3.12347359 L12.7602031,3.78786854 C12.9554652,3.98313069 12.9554652,4.29971317 12.7602031,4.49497532 L6.25624783,10.9989306 L20.4696115,10.9989306 C20.7457539,10.9989306 20.9696115,11.2227882 20.9696115,11.4989306 L20.9696115,12.4385269 C20.9696115,12.7146693 20.7457539,12.9385269 20.4696115,12.9385269 L6.25216138,12.9385269 Z"
          fill="inherit"
          id={`path-1-arrow-down${unique}`}
          transform="translate(12.000000, 11.970772) scale(-1, -1) rotate(-270.000000) translate(-12.000000, -11.970772) "
        />
      </g>
    </g>
  </svg>
)

ArrowDown.propTypes = propTypes
export default ArrowDown
