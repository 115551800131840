import React, { forwardRef, useMemo } from "react";
import { withTheme } from "styled-components";
import { map, pathOr } from "ramda";
import { string } from "prop-types";

import normalizeArray from "lib/utils/normalizeArray";
import {
  responsiveStringOrNumberPropType,
  themePropType,
} from "lib/prop-types";
import Primitives from "primitives";
import { mapIndexed } from "lib/utils/helpers";

const propTypes = {
  columns: responsiveStringOrNumberPropType,
  href: string,
  theme: themePropType,
};

const GridElement = forwardRef(({ columns, href, theme, ...props }, ref) => {
  const themeColumns = pathOr([], ["grid", "columns"], theme);
  const columnsArray = normalizeArray(columns, themeColumns.length);
  const columnWidths = useMemo(
    () => mapIndexed((elem, index) => columnsArray[index] / elem, themeColumns),
    [columnsArray, themeColumns],
  );
  const display = useMemo(
    () => map((elem) => (elem === 0 ? "none" : "inherit"), columnWidths),
    [columnWidths],
  );
  const themeGutters = pathOr([], ["grid", "gutters"], theme);

  return (
    <Primitives.Flex
      as={href ? "a" : "div"}
      display={display}
      flex="0 0 auto"
      href={href}
      px={themeGutters}
      ref={ref}
      width={columnWidths}
      {...props}
    />
  );
});

GridElement.propTypes = propTypes;
GridElement.displayName = "Modules.GridElement";

export default withTheme(GridElement);
