import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Tumblr = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/tumblr-tumblr${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-tumblr${unique}`}
        xlinkHref={`#path-1-tumblr${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-tumblr${unique}`}>
        <path
          d="M17.6475179,10.5106383 L13.3921988,10.5106383 C13.3921988,10.5106383 13.3921988,15.9007092 13.3921988,16.0425532 C13.3921988,17.035461 13.5340427,17.6028369 14.9524825,17.6028369 C16.2290782,17.6028369 17.6475179,17.6028369 17.6475179,17.6028369 L17.6475179,21.858156 C17.6475179,21.858156 16.2290782,22 14.6687945,22 C10.9808513,22 9.13687962,19.7304965 9.13687962,17.177305 C9.13687962,15.4751773 9.13687962,10.5106383 9.13687962,10.5106383 L6.30000019,10.5106383 L6.30000019,6.53900709 C9.70425551,6.25531915 9.98794345,3.70212766 10.2716314,2 L13.3921988,2 L13.3921988,6.25531915 L17.6475179,6.25531915 L17.6475179,10.5106383 Z"
          fill="inherit"
          id={`path-1-tumblr${unique}`}
        />
      </g>
    </g>
  </svg>
)

Tumblr.propTypes = propTypes
export default Tumblr
