import { TransitionHeight } from "lib/animations"
import React, { useEffect, useMemo, useState } from "react"
import { OpacityBar } from "./icons/Icon"
import styles from "./MenuItemsLayoutCompact.module.css"
import CtaGroup from "components/cta-group/CtaGroupComponent"
import { WosArrowDown, WosArrowUp } from "components/glyphs/elements"
import Link from "components/link"

const MenuItemsLayoutCompact = ({
  developerLinkTitle,
  developerLinkUrl,
  items = [],
  currentPageId,
  currentPageChildrenId,
  cta,
  urlSearch,
  onTabClick
}) => {
  const flatItems = useMemo(() => {
    return items.map((item) => {
      if (item.childPages) {
        return item.childPages.map((child) => {
          return { ...child, id: `${item.id}~${child.id}` }
        })
      } else {
        return item
      }
    }).flat()
  }, [items])

  const [showDrop, setShowDrop] = useState(false)
  const [primaryMenuItem, setPrimaryMenuItem] = useState({})
  const [otherMenuItems, setOtherMenuItems] = useState([])

  useEffect(() => {
    let currentPageIdValue = currentPageId ? currentPageId : (flatItems && flatItems.length && flatItems[0].id)
    currentPageIdValue = currentPageChildrenId ? `${currentPageIdValue}~${currentPageChildrenId}` : currentPageIdValue
    const primaryMenuItemInitialValue = flatItems.find((item) => item.id === currentPageIdValue)
    const otherMenuItemsInitialValue = flatItems.filter((item) => item.id !== currentPageIdValue)
    setPrimaryMenuItem(primaryMenuItemInitialValue)
    setOtherMenuItems(otherMenuItemsInitialValue)
  }, [flatItems, currentPageId, currentPageChildrenId])

  const afterClick = (item1) => {
    setShowDrop(!showDrop)
    setPrimaryMenuItem(item1)
    setOtherMenuItems(flatItems.filter((item) => item1.id !== item.id))
    onTabClick({url: item1.id
      ? `${urlSearch ? urlSearch : ""}#${item1.id}`
      : item1.path, title: item1?.title})
  }

  const isDeveloperLinkTitleValid = developerLinkTitle && developerLinkTitle !== ""
  const DeveloperLinkTag = developerLinkUrl ? Link : "div"

  return (
    <>
      <div className={styles.dropdownHeader}>
        {isDeveloperLinkTitleValid && (
          <>
            <DeveloperLinkTag
              className={styles.dropdownItem}
              href={developerLinkUrl}
              location={{
                state: {
                  text: developerLinkTitle,
                  type: "CTA",
                }}
              }
            >
              {developerLinkTitle}
            </DeveloperLinkTag>
            <OpacityBar />
          </>
        )}
        <div className={styles.menuContainer}
          onClick={() => {
            setShowDrop(!showDrop)
          }}
        >
          <div className={styles.dropdownItem}>{primaryMenuItem?.title}</div>
          {otherMenuItems.length > 0 && <>{showDrop
            ? <WosArrowUp className={styles.dropIcon} />
            : <WosArrowDown className={styles.dropIcon} />}</>}
        </div>
      </div>
      {otherMenuItems.length > 0 && showDrop && (
        <TransitionHeight
          className={styles.mobileTransitionDropdown}
          show={showDrop}
        >
          <div className={styles.dropdownItemContainer}>
            {otherMenuItems.map((item) => (
              <a
                onClick={(event) => {
                  event.preventDefault()
                  afterClick(item)
                }}
                className={styles.dropdownItemWrapper}
                href={
                  item.id
                    ? `${urlSearch ? urlSearch : ""}#${item.id}`
                    : item.path
                }
              >
                {item.title}
              </a>
            ))}
            {cta?.title ? (
              <CtaGroup className={styles.dropdownItemWrapper} ctas={[cta]} />
            ) : null}
          </div>
        </TransitionHeight>
      )}
    </>
  )
}

export default MenuItemsLayoutCompact