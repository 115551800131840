import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const EmailAlternate = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/communications/email_alternate-email-alternate${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`grey600-email-alternate${unique}`}
        xlinkHref={`#path-1-email-alternate${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-email-alternate${unique}`}>
        <path
          d="M12.1738989,22 C6.41310479,22 2,17.5435018 2,12.0433934 C2,6.02180516 6.65220221,2 12.1957041,2 C17.3042959,2 21.7172923,5.5 21.7172923,11.0870037 C21.7172923,14.7174007 20,17.2174007 17.4564982,17.2174007 C15.8912996,17.2174007 15.1087004,16.3261011 14.8042959,15.4348015 C14.1957041,16.4783033 12.9564982,17.2174007 11.4781948,17.2174007 C8.80429594,17.2174007 6.63039705,15.0651985 6.63039705,12.130397 C6.63039705,9.21740074 8.80440443,7.10870037 11.4783033,7.10870037 C12.7609026,7.10870037 13.8912996,7.67389889 14.6087004,8.60870037 L14.6087004,7.93480148 C14.6087004,7.67389889 14.8042959,7.47830332 15.0651985,7.47830332 L16.5,7.47830332 C16.7825993,7.47830332 16.9783033,7.67389889 16.9783033,7.93480148 L16.9783033,13.6738989 C16.9783033,14.5 17.1738989,15.0216967 17.8912996,15.0216967 C18.6738989,15.0216967 19.5435018,13.934693 19.5435018,10.9783033 C19.5435018,6.73909742 16.2390974,3.97830332 12.1957041,3.97830332 C8.13039705,3.97830332 4.30429594,6.95649816 4.30429594,12 C4.30429594,16.6087004 8.08700369,20.0216967 12.3912996,20.0216967 C13.4783033,20.0216967 14.5870037,19.8477978 15.5435018,19.4781948 C15.9783033,19.3042959 16.3912996,19.0870037 16.7390974,18.869603 C16.8261011,18.8042959 17.1087004,18.5651985 17.4348015,18.7825993 L18.5651985,19.5435018 C18.7390974,19.6522022 18.7390974,19.8477978 18.630397,19.9348015 C17.3042959,21.1522022 15.1087004,22 12.1738989,22 Z M11.7825993,9.43480148 C10.3261011,9.43480148 9.30429594,10.5651985 9.30429594,12.0870037 C9.30429594,13.5651985 10.3261011,14.7390974 11.7825993,14.7390974 C13.2174007,14.7390974 14.2609026,13.565307 14.2609026,12.0870037 C14.2609026,10.5651985 13.2172923,9.43480148 11.7825993,9.43480148 Z"
          fill="inherit"
          id={`path-1-email-alternate${unique}`}
        />
      </g>
    </g>
  </svg>
)

EmailAlternate.propTypes = propTypes
export default EmailAlternate
