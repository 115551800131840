import * as React from "react"
import { cn } from "../../../../lib/utils/cn-helper"

import { LineSpacerProps } from "./LineSpacer.interface"

export const LineSpacer = ({
  variant = "border",
  className
}: LineSpacerProps) => {
  const bg = {
    "bg-black": variant === "black",
    "bg-border": variant === "border",
    "bg-white": variant === "white"
  }
  return (
    <div className={cn("w-full py-4 px-2", className)}>
      <div className={cn("h-[1px] w-full", bg)}></div>
    </div>
  )
}
