import { arrayOf, number, shape, string } from "prop-types";

export const themePropType = shape({
  breakpoints: arrayOf(string),
  columns: arrayOf(number),
  fontSizes: arrayOf(number),
  fontWeights: arrayOf(number),
  gutters: arrayOf(string),
  lineHeights: arrayOf(number),
  spacings: arrayOf(number),
  unitPadding: arrayOf(number),
});
