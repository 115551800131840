import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}
const Checkmark = (props) => (
  <svg {...props} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M13.3334 4.5L6.00002 11.8333L2.66669 8.5" stroke="inherit" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
)

Checkmark.propTypes = propTypes
export default Checkmark
