import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Attachment = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/file/attachment-attachment${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`fill-1-attachment${unique}`}
        xlinkHref={`#path-1-attachment${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-attachment${unique}`}>
        <path
          d="M7.0003,5.9998 C3.6863,5.9998 1.0003,8.6868 1.0003,11.9998 C1.0003,15.3128 3.6863,17.9998 7.0003,17.9998 L17.5003,17.9998 C17.7753,17.9998 18.0003,17.7758 18.0003,17.4998 L18.0003,16.4998 C18.0003,16.2238 17.7753,15.9998 17.5003,15.9998 L7.0003,15.9998 C4.7903,15.9998 3.0003,14.2088 3.0003,11.9998 C3.0003,9.7908 4.7903,7.9998 7.0003,7.9998 L11.4633,7.9998 L14.7603,7.9998 L18.5003,7.9998 C19.8803,7.9998 21.0003,9.1188 21.0003,10.4998 C21.0003,11.8808 19.8803,12.9998 18.5003,12.9998 L14.7603,12.9998 L12.9103,12.9998 L10.8583,12.9998 L8.0003,12.9998 C7.4473,12.9998 7.0003,12.5528 7.0003,11.9998 C7.0003,11.4478 7.4473,10.9998 8.0003,10.9998 L10.8163,10.9998 L12.9053,10.9998 L14.0503,10.9998 L16.0503,10.9998 L17.5003,10.9998 C17.7764424,10.9998 18.0003,10.7759424 18.0003,10.4998 L18.0003,9.4998 C18.0003,9.22365763 17.7764424,8.9998 17.5003,8.9998 L16.5113,8.9998 L14.2753,8.9998 L12.1903,8.9998 L9.6203,8.9998 L8.0003,8.9998 C6.3433,8.9998 5.0003,10.3438 5.0003,11.9998 C5.0003,13.6568 6.3433,14.9998 8.0003,14.9998 L9.6163,14.9998 L12.1883,14.9998 L18.5003,14.9998 C20.9853,14.9998 23.0003,12.9848 23.0003,10.4998 C23.0003,8.0148 20.9853,5.9998 18.5003,5.9998 L7.0003,5.9998 Z"
          fill="inherit"
          id={`path-1-attachment${unique}`}
        />
      </g>
    </g>
  </svg>
)

Attachment.propTypes = propTypes
export default Attachment
