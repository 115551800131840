import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}
const Bookmark = (props) => (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.1667 14L8.5 11.3333L3.83333 14V3.33333C3.83333 2.97971 3.9738 2.64057 4.22385 2.39052C4.4739 2.14048 4.81304 2 5.16666 2H11.8333C12.187 2 12.5261 2.14048 12.7761 2.39052C13.0262 2.64057 13.1667 2.97971 13.1667 3.33333V14Z"  fill="none" stroke="inherit" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>


)

Bookmark.propTypes = propTypes
export default Bookmark
