import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const CheckboxSelected = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/checkbox_selected-checkbox-selected${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-checkbox-selected${unique}`}
        xlinkHref={`#path-1-checkbox-selected${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-checkbox-selected${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M4.5,4 C4.22385763,4 4,4.22385763 4,4.5 L4,19.5 C4,19.7761424 4.22385763,20 4.5,20 L19.5,20 C19.7761424,20 20,19.7761424 20,19.5 L20,4.5 C20,4.22385763 19.7761424,4 19.5,4 L4.5,4 Z M16.6551852,6.25715994 L17.5127836,6.77147982 C17.7496029,6.91350533 17.8264484,7.22061971 17.6844229,7.45743897 L11.6180489,17.5727639 C11.4760234,17.8095832 11.1689091,17.8864287 10.9320898,17.7444032 C10.9168929,17.7352893 10.9021913,17.725374 10.8880471,17.7146992 L6.79669006,14.6268965 C6.57627585,14.4605469 6.53244782,14.1470129 6.69879742,13.9265987 L7.30120258,13.1284082 C7.46755218,12.907994 7.78108619,12.864166 8.0015004,13.0305156 L10.7610269,15.1131677 L15.969226,6.42879921 C16.1112515,6.19197995 16.4183659,6.11513443 16.6551852,6.25715994 Z"
          fill="inherit"
          id={`path-1-checkbox-selected${unique}`}
        />
      </g>
    </g>
  </svg>
)

CheckboxSelected.propTypes = propTypes
export default CheckboxSelected
