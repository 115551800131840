import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Tablet = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/devices/tablet-tablet${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-tablet${unique}`}
        xlinkHref={`#path-1-tablet${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-tablet${unique}`}>
        <path
          d="M6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,21 C19,21.5522847 18.5522847,22 18,22 L6,22 C5.44771525,22 5,21.5522847 5,21 L5,3 C5,2.44771525 5.44771525,2 6,2 Z M6,3 L6,19 L18,19 L18,3 L6,3 Z M11,21 L13,21 C13.2761424,21 13.5,20.7761424 13.5,20.5 C13.5,20.2238576 13.2761424,20 13,20 L11,20 C10.7238576,20 10.5,20.2238576 10.5,20.5 C10.5,20.7761424 10.7238576,21 11,21 Z"
          fill="inherit"
          id={`path-1-tablet${unique}`}
        />
      </g>
    </g>
  </svg>
)

Tablet.propTypes = propTypes
export default Tablet
