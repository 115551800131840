import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const DocumentImagingScanner = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/applications/document_imaging_scanner-document-imaging-scanner${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-document-imaging-scanner${unique}`}
        xlinkHref={`#path-1-document-imaging-scanner${unique}`}
      />
      <g
        fill="inherit"
        id={`colors/core/gunmetal-document-imaging-scanner${unique}`}
      >
        <path
          d="M3,12 L21,12 C21.5522847,12 22,12.4477153 22,13 L22,19 C22,19.5522847 21.5522847,20 21,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,13 C2,12.4477153 2.44771525,12 3,12 Z M10,15 C9.44771525,15 9,15.4477153 9,16 C9,16.5522847 9.44771525,17 10,17 L19,17 C19.5522847,17 20,16.5522847 20,16 C20,15.4477153 19.5522847,15 19,15 L10,15 Z M6,17 C6.55228475,17 7,16.5522847 7,16 C7,15.4477153 6.55228475,15 6,15 C5.44771525,15 5,15.4477153 5,16 C5,16.5522847 5.44771525,17 6,17 Z M5.63180195,4.16628315 L20.7600992,9.37537362 C21.8044901,9.73498627 22.3596127,10.8731561 22,11.9175471 L3.08962849,5.40618399 C3.44924114,4.36179301 4.58741097,3.8066705 5.63180195,4.16628315 Z"
          fill="inherit"
          id={`path-1-document-imaging-scanner${unique}`}
        />
      </g>
    </g>
  </svg>
)

DocumentImagingScanner.propTypes = propTypes
export default DocumentImagingScanner
