import React, {
  ReactElement,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react"
import { useSelector, useDispatch } from "react-redux"
import { useQuery } from "@apollo/client"
import GET_USER_INFO from "gql/queries/user/getInfo"
import {
  UserAccountProps,
  UserAccountModalProps,
} from "./UserAccount.interface"
import { Icon } from "components/dx/atoms/Icon"
import CtaV2Dx from "components/dx/atoms/CtaV2Dx/CtaV2Dx"
import { LineSpacer } from "components/dx/atoms/LineSpacer"
import { TransitionHeight } from "lib/animations"
import useDetectOutsideClick from "lib/hooks/useDetectOutsideClick"
import { GET_IS_MOBILE, GET_LOGGED_IN } from "lib/redux/selectors"
import {
  SET_SHOW_IMPERSONATION,
  SET_SHOW_LOGIN_PANEL,
} from "lib/redux/actions"
import {
  handleLoginRedirect,
  handleLogoutRedirect,
  handleProfileRedirect,
  handleRegisterRedirect,
} from "lib/utils/createpoint"
import { TargetTypeValueArray } from "components/dx/atoms/CtaV2Dx/CtaV2Dx.interface"
import { cn } from "../../../../lib/utils/cn-helper"
import { Typography } from "../../atoms/Typography"
import useUserInfo from "components/navigation/useUserInfo"
import { getMeta } from "lib/utils/createpoint"
import { INTERNAL_LINK } from "lib/redux/actions"

export const UserAccountModal = ({
  setIsModalOpen,
  isLoggedIn,
  className,
  buttonRef = null,
}: UserAccountModalProps): ReactElement => {
  const dispatch = useDispatch()
  const isMobile = useSelector(GET_IS_MOBILE)
  const { isInternalUser, isImpersonating } = useUserInfo()

  const wrapperRef = useRef(null)
  const { isOutsideClickDetected } = useDetectOutsideClick(
    wrapperRef,
    buttonRef
  )

  useEffect(() => {
    if (isOutsideClickDetected && setIsModalOpen) setIsModalOpen(false)
  }, [isOutsideClickDetected])

  const { data, loading } = useQuery(GET_USER_INFO, {
    skip: !isLoggedIn,
    variables: {
      ssr: false,
    },
  })

  const getUsersName = useCallback(() => {
    const { firstName, lastName } = data?.userInfo || {}
    return `${firstName} ${lastName}`
  }, [data?.userInfo])

  const impersonateOnClick = useCallback(() => {
    dispatch({ type: SET_SHOW_IMPERSONATION, value: true })
    dispatch({ type: SET_SHOW_LOGIN_PANEL, value: false })
    setIsModalOpen && setIsModalOpen(false)
  }, [dispatch])

  const createOneAccountClick = () => {
    dispatch({
      location: {
        label: "Sign up",
        state: {
          loc: getMeta("registerEndpointUrl"),
          text: "click",
          type: "create account",
          detail: "megamenu",
        },
        url: getMeta("registerEndpointUrl"),
      },
      type: INTERNAL_LINK,
    })
    handleRegisterRedirect()
  }
  const loginClick = () => {
    const configuredAnalytics = {
      pathname: getMeta("loginEndpointUrl"),
      state: {
        text: "sign in",
        detail: "megamenu",
        type: "SIGN IN",
      },
    }
    dispatch({ location: configuredAnalytics, type: INTERNAL_LINK })
    handleLoginRedirect()
  }

  return (
    <>
      {loading ? null : (
        <div
          className={cn(
            "flex flex-col gap-s p-s border-t-[1px] border-theme-neutral-300",
            className
          )}
          ref={wrapperRef}
        >
          {isLoggedIn ? (
            <>
              <div>
                <h2 className="rte-heading-xs">{`Hi, ${getUsersName()}.`}</h2>
                <h2 className="rte-body-s text-black/60">
                  {data?.userInfo?.username}
                </h2>
              </div>
              <LineSpacer className="p-0" />
              <div className="flex flex-col gap-xxs items-start">
                {isInternalUser && !isImpersonating && (
                  <CtaV2Dx
                    callback={impersonateOnClick}
                    color="white"
                    ctaTitle="Impersonate External User"
                    ctaTitleClass="body-s"
                    iconType="default"
                    size="text"
                    style="fill-hover-shadow-disabled"
                    target=""
                    targetType={TargetTypeValueArray.TARGET_TYPE_CALL_BACK}
                    url=""
                    width="auto"
                  />
                )}

                <CtaV2Dx
                  callback={handleProfileRedirect}
                  color="white"
                  ctaTitle="Account Settings"
                  ctaTitleClass="body-s"
                  iconType="default"
                  size="text"
                  style="fill-hover-shadow-disabled"
                  target=""
                  targetType={TargetTypeValueArray.TARGET_TYPE_CALL_BACK}
                  url=""
                  width="auto"
                />

                <CtaV2Dx
                  callback={() => handleLogoutRedirect()}
                  color="white"
                  ctaTitle="Sign Out"
                  ctaTitleClass="body-s"
                  iconType="default"
                  size="text"
                  style="fill-hover-shadow-disabled"
                  target=""
                  targetType={TargetTypeValueArray.TARGET_TYPE_CALL_BACK}
                  url=""
                  width="auto"
                />
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col gap-xs">
                <h2 className="rte-heading-xs">Sign in to your account</h2>
                <CtaV2Dx
                  callback={loginClick}
                  color="blue"
                  ctaTitle="Sign in"
                  ctaTitleClass="rte-heading-xxs leading-5"
                  iconType="default"
                  size="small"
                  style="fill"
                  target=""
                  targetType={TargetTypeValueArray.TARGET_TYPE_CALL_BACK}
                  url=""
                  width="full"
                />
              </div>

              <div className="flex flex-col md:flex-row gap-xxs md:items-center items-start">
                <Typography
                  children="Don't have an account?"
                  variant={isMobile ? "body-m" : "body-s"}
                />
                <CtaV2Dx
                  callback={createOneAccountClick}
                  color="white"
                  ctaTitle="Sign up"
                  ctaTitleClass={`${
                    !isMobile ? "rte-heading-xxs" : "rte-heading-xs"
                  } leading-5`}
                  iconType="dx-arrow-right"
                  size="text"
                  style="fill-hover-shadow-disabled"
                  target=""
                  targetType={TargetTypeValueArray.TARGET_TYPE_CALL_BACK}
                  url=""
                  width="auto"
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}

const UserAccount = ({
  handleUserIconClick,
}: UserAccountProps): ReactElement => {
  const isLoggedIn: any = useSelector(GET_LOGGED_IN)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleUserIconOnClick = () => {
    setIsModalOpen(!isModalOpen)
    handleUserIconClick()
  }

  return (
    <div className="relative">
      <button
        className="flex items-center"
        disabled={false}
        onClick={handleUserIconOnClick}
        ref={buttonRef}
      >
        {isLoggedIn ? (
          <Icon glyphId="dx-logged-in-user" size="large" />
        ) : (
          <Icon
            glyphId="dx-user"
            size="large"
            variant={`${isModalOpen ? "blue" : "dark-gray"}`}
          />
        )}
      </button>

      <div className="absolute right-0 z-10 mt-[24px] min-w-[276px] bg-white whitespace-nowrap shadow-[-2px_10px_6px_0px_rgba(0,0,0,0.1)]">
        <TransitionHeight
          className=""
          config={{ friction: 30, mass: 1, tension: 300 }}
          enterDuration={250}
          exitDuration={100}
          onAnimationClose={null}
          onAnimationOpen={null}
          overflow="hidden"
          show={isModalOpen}
          width={1}
          zIndex={1}
        >
          {isModalOpen && (
            <UserAccountModal
              buttonRef={buttonRef}
              isLoggedIn={isLoggedIn}
              setIsModalOpen={setIsModalOpen}
            />
          )}
        </TransitionHeight>
      </div>
    </div>
  )
}

export default UserAccount
