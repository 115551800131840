import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const DeviceHub = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/devices/device_hub-device-hub${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`simplified_icons-technology-device-hub-device-hub${unique}`}
        xlinkHref={`#path-1-device-hub${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-device-hub${unique}`}>
        <path
          d="M10.9854736,13.5020336 L10.9854736,9.85070285 C9.54719907,9.41580406 8.5,8.08019526 8.5,6.5 C8.5,4.56700338 10.0670034,3 12,3 C13.9329966,3 15.5,4.56700338 15.5,6.5 C15.5,8.09081575 14.4386772,9.43374627 12.9854736,9.85934945 L12.9854736,13.4812816 L17,16.3488005 L17,14.5 C17,14.2238576 17.2238576,14 17.5,14 L20.4709473,14 C20.7470896,14 20.9709473,14.2238576 20.9709473,14.5 L20.9709473,20.5 C20.9709473,20.7761424 20.7470896,21 20.4709473,21 L17.5,21 C17.2238576,21 17,20.7761424 17,20.5 L17,18.7940567 L12,15.2226281 L7,18.7940567 L7,20.5 C7,20.7761424 6.77614237,21 6.5,21 L3.5,21 C3.22385763,21 3,20.7761424 3,20.5 L3,14.5 C3,14.2238576 3.22385763,14 3.5,14 L6.5,14 C6.77614237,14 7,14.2238576 7,14.5 L7,16.3488005 L10.9854736,13.5020336 Z"
          fill="inherit"
          id={`path-1-device-hub${unique}`}
        />
      </g>
    </g>
  </svg>
)

DeviceHub.propTypes = propTypes
export default DeviceHub
