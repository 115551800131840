import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Menu = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/menu-menu${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`ic_menu_24px-menu${unique}`}
        xlinkHref={`#path-1-menu${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-menu${unique}`}>
        <path
          d="M3.5,6 L20.5,6 C20.7761424,6 21,6.22385763 21,6.5 L21,7.5 C21,7.77614237 20.7761424,8 20.5,8 L3.5,8 C3.22385763,8 3,7.77614237 3,7.5 L3,6.5 C3,6.22385763 3.22385763,6 3.5,6 Z M3.5,11 L20.5,11 C20.7761424,11 21,11.2238576 21,11.5 L21,12.5 C21,12.7761424 20.7761424,13 20.5,13 L3.5,13 C3.22385763,13 3,12.7761424 3,12.5 L3,11.5 C3,11.2238576 3.22385763,11 3.5,11 Z M3.5,16 L20.5,16 C20.7761424,16 21,16.2238576 21,16.5 L21,17.5 C21,17.7761424 20.7761424,18 20.5,18 L3.5,18 C3.22385763,18 3,17.7761424 3,17.5 L3,16.5 C3,16.2238576 3.22385763,16 3.5,16 Z"
          fill="inherit"
          id={`path-1-menu${unique}`}
        />
      </g>
    </g>
  </svg>
)

Menu.propTypes = propTypes
export default Menu
