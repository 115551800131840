import {createGlobalStyle} from "styled-components"
import QualcommNextThinWoff from "../../public/fonts/QualcommNext-Thin.woff"
import QualcommNextThinTtf from "../../public/fonts/QualcommNext-Thin.ttf"
import QualcommNextRegularWoff from "../../public/fonts/QualcommNext-Regular.woff"
import QualcommNextRegularTTF from "../../public/fonts/QualcommNext-Regular.ttf"
import QualcommNextMediumWoff from  "../../public/fonts/QualcommNext-Medium.woff"
import QualcommNextMediumTTF from "../../public/fonts/QualcommNext-Medium.ttf"
import RobotoFlexVariableTTF from  "../../public/fonts/RobotoFlex-VariableFont.ttf"
import RobotoFlexTTF from "../../public/fonts/RobotoFlex-Regular.ttf"

export default createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  body {
    font-family: 'QualcommNext', Arial, Sans-Serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    margin: 0;
  }
  body:lang(zh-cn) {
    font-family: 'QualcommNext', SimHei, Hei, Arial, Sans-Serif;
    -webkit-font-smoothing: antialiased !important;
    text-rendering: optimizeLegibility !important;
  }
 /* .user-is-tabbing class is added on tab navigation (see client/initial-render.js) - when this class isn't present, remove focus outlines */
  body:not(.user-is-tabbing) a:focus,
  body:not(.user-is-tabbing) button:focus,
  body:not(.user-is-tabbing) input:focus,
  body:not(.user-is-tabbing) select:focus,
  body:not(.user-is-tabbing) video:focus,
  body:not(.user-is-tabbing) textarea:focus {
    outline: none;
  }
  input {
    font-family: 'QualcommNext', Arial, Sans-Serif;
  }
  input:lang(zh-cn) {
    font-family: 'QualcommNext', SimHei, Hei, Arial, Sans-Serif;
  }
  a:link,a:visited,a:hover,a:active {
    text-decoration: none;
  }
  @font-face {
    font-family: 'QualcommNext';
    font-style: normal;
    font-weight: 200;
    src: local('QualcommNext Thin'),
      url(${QualcommNextThinWoff}) format('woff'),
      url(${QualcommNextThinTtf}) format('truetype');
  }
  @font-face {
    font-family: 'QualcommNext';
    font-style: normal;
    font-weight: 400;
    src: local('QualcommNext Regular'),
      url(${QualcommNextRegularWoff}) format('woff'),
      url(${QualcommNextRegularTTF}) format('truetype');
  }
  @font-face {
    font-family: 'QualcommNext';
    font-style: normal;
    font-weight: 600;
    src: local('QualcommNext Medium'), 
      url(${QualcommNextMediumWoff}) format('woff'),
      url(${QualcommNextMediumTTF}) format('truetype');
  }
  @font-face {
    font-family: 'Roboto Flex';
    font-style: normal;
    src: local('Roboto Flex'), 
      url(${RobotoFlexVariableTTF}) format('truetype'),
      url(${RobotoFlexTTF}) format('truetype');
  }
`