import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Check = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/check-check${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`path-15-check${unique}`}
        xlinkHref={`#path-1-check${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-check${unique}`}>
        <path
          d="M21.0789523,4.38486641 L21.8651336,5.17104774 C22.0603957,5.36630989 22.0603957,5.68289238 21.8651336,5.87815452 L8.1439753,19.5993128 C7.94871316,19.794575 7.63213067,19.794575 7.43686852,19.5993128 L2.13486641,14.2973107 C1.93960426,14.1020485 1.93960426,13.7854661 2.13486641,13.5902039 L2.92104774,12.8040226 C3.11630989,12.6087604 3.43289238,12.6087604 3.62815452,12.8040226 L7.79042191,16.96629 L20.3718455,4.38486641 C20.5671076,4.18960426 20.8836901,4.18960426 21.0789523,4.38486641 Z"
          fill="inherit"
          id={`path-1-check${unique}`}
        />
      </g>
    </g>
  </svg>
)

Check.propTypes = propTypes
export default Check
