import React from "react"
import { string } from "prop-types"

const propTypes = {
  unique: string,
}

const WosSearch = ({ fill ="#222222", unique = "", ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M15.0001 15.0001L10.6924 10.6924" stroke={fill} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
    <path id={`path-1-wos-search${unique}`} d="M6.38462 11.7692C9.35846 11.7692 11.7692 9.35846 11.7692 6.38462C11.7692 3.41077 9.35846 1 6.38462 1C3.41077 1 1 3.41077 1 6.38462C1 9.35846 3.41077 11.7692 6.38462 11.7692Z" stroke={fill} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

WosSearch.propTypes = propTypes
export default WosSearch
