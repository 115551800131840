import React, { forwardRef } from "react";
import { string } from "prop-types";
import styled from "styled-components";
import { compose, layout, position, space } from "styled-system";

import customSystemProps from "./custom";
import { shouldForwardProp } from "./shared";

const styleProps = compose(layout, position, space);

const Image = styled.img.withConfig({
  shouldForwardProp,
})`
  ${styleProps};
  ${customSystemProps};
`;

const propTypes = {
  alt: string,
  // Font size css style system prop
  imageAlt: string,
};

const ImagePrimitive = forwardRef(({ alt, imageAlt = "", ...props }, ref) => (
  <Image alt={alt || imageAlt} ref={ref} {...props} />
));

ImagePrimitive.propTypes = propTypes;
Image.displayName = "Primitives.Image";

export default ImagePrimitive;
