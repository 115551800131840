import React, {memo, useMemo} from "react"
import {bool, func, string} from "prop-types"

import {TransitionHeight} from "lib/animations"
import Primitives from "primitives"
import SearchInput from "components/search-input"
import GridSystem from "lib/grid-system"

const searchInputWrapperStyles = {
  backgroundColor: "white",
  borderBottomColor: "gray",
  justifyContent: "center",
  overflow: "hidden",
  px: [3, 3, 6, 7],
  width: 1,
  zIndex: 0,
}
const searchInputMobileWrapperStyles = {
  backgroundColor: "white",
  borderBottomColor: "gray",
  justifyContent: "center",
  overflow: "hidden",
  // px: [3, 3, 6, 7],
  width: 1,
  zIndex: 0,
}

const searchInputWosDeskWrapperStyles = {
  backgroundColor: "white",
  borderBottomColor: "gray",
  justifyContent: "center",
  overflow: "hidden",
  px: [3, 3, 1, 1],
  width: 1,
  zIndex: 0,
}

const searchInputWrapperMobileStyles = {
  borderBottomColor: "red",
}

const slideAnimationConfig = {
  clamp: true,
  friction: 40,
  mass: 1,
  tension: 400,
}
const propTypes = {
  /** Handle focus lost */
  handleBlur: func,
  /** Handle change */
  handleChange: func,
  /** Handle focus gained */
  handleFocus: func,
  /** Handle Submit */
  handleSubmit: func,
  isOnSearchPage: bool,
  isSearchActive: bool,
  /** Placeholder string */
  placeholder: string,
  /** value of search component */
  value: string,
}

/**
 * GlobalSearchBar renders search bar and query suggestions from GraphQL API call. It passes
 * these props to the Search component from @mirum-qc/components.
 */
export function GlobalSearchBar({
  handleBlur,
  handleChange,
  handleFocus,
  handleSubmit,
  isOnSearchPage,
  isSearchActive,
  placeholder,
  value,
  wosFlagDesktop,
  wosFlagMobile,
  wosFlag
}) {
  const Wrapper = useMemo(
    () => (wosFlagMobile ? TransitionHeight : Primitives.Flex),
    [wosFlagMobile],
  )
  const wrapperStyles = wosFlag? searchInputMobileWrapperStyles : searchInputWrapperStyles

  const WrapperTag = (wosFlag && wosFlagMobile) ?GridSystem.Wrapper:React.Fragment

  if (wosFlagDesktop && isOnSearchPage) {
    return (
      <SearchInput
        onChange={handleChange}
        onFocus={handleFocus}
        onSubmit={handleSubmit}
        onBlur={handleBlur}
        placeholder={placeholder}
        renderType="wos"
        searchFormStyles={{
          border: "1px solid",
          borderColor: "rgba(0,0,0,0.3)",
          height: "40px",
          px: "14px",
          py: "10px"
        }}
        showSearchTips
        value={value}
      />
    )
  }


  return (
    <Wrapper
      config={slideAnimationConfig}
      delayIn={0}
      horizontal={0.4}
      width={1}
      borderBottom={isOnSearchPage || isSearchActive ? "1px solid" : null}
      boxShadow={wosFlagMobile ? "0px 1px 0px #e9ebee":"gray"}
      data-test-id="global-search-bar"
      onBlur={handleBlur}
      show={!isOnSearchPage ? isSearchActive : isOnSearchPage}
      {...wrapperStyles}
    >
      <WrapperTag>
        {isSearchActive || isOnSearchPage ? (
          <SearchInput
            onChange={handleChange}
            onFocus={handleFocus}
            onSubmit={handleSubmit}
            placeholder={placeholder}
            showSearchTips={wosFlagMobile}
            value={value}
        
          />
        ) : null}
      </WrapperTag>
    </Wrapper>
  )
}

GlobalSearchBar.propTypes = propTypes
export default memo(GlobalSearchBar)
