import {
  all,
  put,
  select,
  takeEvery,
  takeLatest,
  throttle,
} from "redux-saga/effects"
import {equals} from "ramda"

import {
  FETCH_INITIAL_SCROLL_POSITION,
  FETCH_SCROLL_POSITION,
  FETCH_VIEWPORT,
  RESET_SCROLL_CHECKPOINT_MAP,
  SET_ACTIVE_NODE,
  SET_INITIAL_SCROLL_POSITION,
  SET_SCROLL_DIRECTION,
  SET_CURRENT_THEME,
  TRIGGER_SCROLL_CHECKPOINT,
  UPDATE_SCROLL_CHECKPOINT_MAP,
  UPDATE_SCROLL_POSITION,
  UPDATE_VIEWPORT,
} from "lib/redux/actions"
import {
  GET_INITIAL_SCROLL_POSITION,
  GET_SCROLL_CHECKPOINT_MAP,
  GET_SCROLL_DIRECTION,
} from "lib/redux/selectors"
import { setCookie } from "lib/cookies"

export const getCurrentScrollPercentage = () => {
  const h = document.documentElement
  const b = document.body
  return Math.round(
    ((h.scrollTop || b.scrollTop) /
      ((h.scrollHeight || b.scrollHeight) - h.clientHeight)) *
      100,
  )
}

export function* handleViewportData() {
  yield put({
    type: UPDATE_VIEWPORT,
    value: {
      height: window.innerHeight,
      width: window.innerWidth,
    },
  })
}

let scrollMax = 0
let lastScrollTop = 0
export function* handleScrollData() {
  const h = document.documentElement
  const b = document.body
  const newScrollTop = h.scrollTop || b.scrollTop
  if (newScrollTop !== lastScrollTop) {
    yield put({
      scrollPercentage: getCurrentScrollPercentage(),
      type: UPDATE_SCROLL_POSITION,
    })
    const currentDirection = yield select(GET_SCROLL_DIRECTION)
    /*
     * lastScrollTop will be 0 right after blocker component is closed.
     * so set direction to up to keep main menu on the page.
     * Show nav on scroll up within 2000 of top, or scrolled more than 2000.
     */
    const direction = lastScrollTop > newScrollTop ? "up" : "down"
    const newDirection = {
      direction,
      showNavigation:
        (lastScrollTop > newScrollTop ||
          lastScrollTop === 0 ||
          newScrollTop < 10) &&
        (newScrollTop < 2000 || scrollMax - newScrollTop > 2000),
      showPageControls: lastScrollTop > newScrollTop && newScrollTop >= 500,
    }
    if (!equals(newDirection, currentDirection)) {
      yield put({type: SET_SCROLL_DIRECTION, value: newDirection})
    }
    if (newScrollTop > scrollMax || newScrollTop > lastScrollTop) {
      scrollMax = newScrollTop
    }
    lastScrollTop = newScrollTop
  }
}

export function* setInitialScroll() {
  yield put({
    type: SET_INITIAL_SCROLL_POSITION,
    value: getCurrentScrollPercentage(),
  })
  yield put({
    type: UPDATE_SCROLL_POSITION,
    value: getCurrentScrollPercentage(),
  })
}

export function* resetCheckpoints() {
  yield put({type: RESET_SCROLL_CHECKPOINT_MAP})
}

export function* handleScrollCheckpoint({scrollPercentage}) {
  const checkpoints = yield select(GET_SCROLL_CHECKPOINT_MAP)
  const initialPosition = yield select(GET_INITIAL_SCROLL_POSITION)
  const hitCheckpointScrollingDown =
    checkpoints.some((checkpoint) => scrollPercentage >= checkpoint) &&
    scrollPercentage > initialPosition
  const hitCheckpointScrollingUp =
    checkpoints.some((checkpoint) => scrollPercentage <= checkpoint) &&
    scrollPercentage < initialPosition
  if (hitCheckpointScrollingDown) {
    yield put({
      type: TRIGGER_SCROLL_CHECKPOINT,
      value: checkpoints
        .filter((checkpoint) => scrollPercentage >= checkpoint)
        .slice(-1)[0],
    })
    yield put({
      type: UPDATE_SCROLL_CHECKPOINT_MAP,
      value: checkpoints.filter((checkpoint) => scrollPercentage < checkpoint),
    })
  } else if (hitCheckpointScrollingUp) {
    yield put({
      type: TRIGGER_SCROLL_CHECKPOINT,
      value: checkpoints
        .filter((checkpoint) => scrollPercentage <= checkpoint)
        .slice(-1)[0],
    })
    yield put({
      type: UPDATE_SCROLL_CHECKPOINT_MAP,
      value: checkpoints.filter((checkpoint) => scrollPercentage > checkpoint),
    })
  }
}

function* handleThemeChange({value}) {
  try {
    yield setCookie("themeSetting", value, {domain: ".qualcomm.com"})

    const themeEvent = new Event("toggleTheme", { bubbles: true })
    if (typeof document !== undefined) {
      yield document.dispatchEvent(themeEvent)
    }
  } catch(e) {
    console.error(e)
  }
}

export default function* globalsSaga() {
  yield all([
    throttle(200, FETCH_VIEWPORT, handleViewportData),
    takeEvery(SET_ACTIVE_NODE, resetCheckpoints),
    takeEvery(FETCH_INITIAL_SCROLL_POSITION, setInitialScroll),
    takeLatest(FETCH_SCROLL_POSITION, handleScrollData),
    takeEvery(UPDATE_SCROLL_POSITION, handleScrollCheckpoint),
    takeEvery(SET_CURRENT_THEME, handleThemeChange)
  ])
}
