import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const CreateNewFolder = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/file/create_new_folder-create-new-folder${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-create-new-folder${unique}`}
        xlinkHref={`#path-1-create-new-folder${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-create-new-folder${unique}`}>
        <path
          d="M16,12 L16,9.5 C16,9.22385763 15.7761424,9 15.5,9 L14.5,9 C14.2238576,9 14,9.22385763 14,9.5 L14,12 L11.5,12 C11.2238576,12 11,12.2238576 11,12.5 L11,13.5 C11,13.7761424 11.2238576,14 11.5,14 L14,14 L14,16.5 C14,16.7761424 14.2238576,17 14.5,17 L15.5,17 C15.7761424,17 16,16.7761424 16,16.5 L16,14 L18.5,14 C18.7761424,14 19,13.7761424 19,13.5 L19,12.5 C19,12.2238576 18.7761424,12 18.5,12 L16,12 Z M3,4 L10,4 L12,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,19 C22,19.5522847 21.5522847,20 21,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,5 C2,4.44771525 2.44771525,4 3,4 Z"
          fill="inherit"
          id={`path-1-create-new-folder${unique}`}
        />
      </g>
    </g>
  </svg>
)

CreateNewFolder.propTypes = propTypes
export default CreateNewFolder
