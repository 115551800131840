import React, {ReactElement} from "react"
import {ToutsProps} from "./Touts.interface"
import {Tout} from "../../utils/common.interface"
import CtaV2Dx from "../../atoms/CtaV2Dx/CtaV2Dx"
import Link from "components/linkTs"

const Touts = ({touts}: ToutsProps): ReactElement => (
  <>
    {touts.map(({
      iconImage,
      desktopImage,
      mobileImage,
      headline,
      description,
      linkText,
      linkPath,
      vertical
    }: Tout) => (
      <Link
        key={`mega-menu-tout-${headline}`}
        background={`url(${iconImage})`}
        backgroundPosition="inherit"
        backgroundRepeat="round"
        backgroundSize="auto"
        className="min-h-[168px] h-auto rounded-sm p-s justify-end"
        display="flex"
        flexDirection="column"
        location={{
          detail: headline,
          state: {
            document: headline,
            loc: linkPath,
            text: "click",
            type: "megamenu",
          },
          linkPath,
        }}
        textDecoration="none"
        url={linkPath}
      >
        <div className="flex gap-[10px] flex-col ">    
          <p className="heading-s text-white">{headline}</p>
                
          <CtaV2Dx
            alignment="left"
            color="white"
            ctaTitle={linkText}
            ctaTitleClass="heading-xs"
            iconType="dx-arrow-right"
            size="small"
            style="text"
            target="_self"
            targetType=""
            url={linkPath}
            width="auto"
          />
        </div>
      </Link>
    ))}
  </>
)

export default Touts