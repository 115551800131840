import {
  all,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects"

import getNodePath from "lib/compute-node-path"
import {
  ROUTE_TRANSITION,
  SCROLL_TO_HASH,
  SET_ACTIVE_NODE,
  TRANSITION_TO_NODE,
} from "lib/redux/actions"
import {GET_ACTIVE_NODE_PATH} from "lib/redux/selectors"
import {isDefined} from "lib/utils/helpers"
import adminSaga from "./admin"
import globalsSaga from "./globals"

export function setPreviousPath(path = null) {
  const referrerOrNull = (referrer) => (isDefined(referrer) ? referrer : null)
  window.previousPath = path || referrerOrNull(document.referrer)
}

export function* handleRoute({location: {hash, pathname}}) {
  const nodePath = getNodePath(pathname)
  const activeNode = yield select(GET_ACTIVE_NODE_PATH)
  if (nodePath !== activeNode) {
    yield setPreviousPath(
      `${window.location.origin}/${activeNode !== "home" ? activeNode : ""}`,
    )
    yield put({type: TRANSITION_TO_NODE, value: nodePath})
    yield take(SET_ACTIVE_NODE)
  }
  if (hash) {
    yield put({type: SCROLL_TO_HASH, value: hash})
  } else {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 20)
  }
}

export function* scrollToHash({hash}) {
  yield delay(0)
  const el = document.querySelector(hash)
  if (el) {
    el.scrollIntoView()
  }
}

export default function* appSaga() {
  setPreviousPath()
  yield all([
    takeEvery(ROUTE_TRANSITION, handleRoute),
    takeLatest(SCROLL_TO_HASH, scrollToHash),
    fork(adminSaga),
    fork(globalsSaga),
  ])
}
