import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Profile = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/profile${unique}`}
      stroke="none"
      strokeWidth="1"
    >
      <g fill="inherit" id={`colors/flat/gunmetal-preferences${unique}`}>
        <path
          d="M12.0278341,13.9999333 C12.1820923,13.9999333 12.3350543,14.0145999 12.4867199,14.0225998 C15.9516968,14.2252652 18.8372332,16.850581 19.7394494,20.1652256 C19.76019,20.2425584 19.778338,20.3212245 19.796486,20.3998907 C19.9014853,21.050553 19.088713,21.99988 18.2422371,21.99988 L18.2422371,21.99988 L5.8134311,21.99988 C4.96695526,21.99988 4.1541829,21.0492197 4.2591822,20.3998907 C4.27862651,20.3225578 4.29547825,20.2425584 4.31621885,20.1665589 C5.21843506,16.850581 8.10526767,14.2252652 11.5689483,14.0225998 C11.7219102,14.0145999 11.8735759,13.9999333 12.0278341,13.9999333 Z M12.0278341,1.99988 C14.8913335,1.99988 17.1403926,4.37053086 17.2129847,7.33317778 C17.2129847,10.2784915 14.8913335,12.6664756 12.0278341,12.6664756 C9.16433468,12.6664756 6.84268349,10.2784915 6.84268349,7.33317778 C6.84268349,4.38786408 9.16433468,1.99988 12.0278341,1.99988 Z"
          fill="inherit"
          id={`path-1-profile${unique}`}
        />
      </g>
    </g>
  </svg>
)

Profile.propTypes = propTypes
export default Profile
