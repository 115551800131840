import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Info = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/info-info${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`grey600-info${unique}`}
        xlinkHref={`#path-1-info${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-info${unique}`}>
        <path
          d="M12,2 C17.52,2 22,6.48 22,12 C22,17.52 17.52,22 12,22 C6.48,22 2,17.52 2,12 C2,6.48 6.48,2 12,2 Z M12,9 C13.1046,9 14,8.1046 14,7 C14,5.8954 13.1046,5 12,5 C10.8954,5 10,5.8954 10,7 C10,8.1046 10.8954,9 12,9 Z M11,11 C10.4477153,11 10,11.4477153 10,12 L10,18 C10,18.5522847 10.4477153,19 11,19 L13,19 C13.5522847,19 14,18.5522847 14,18 L14,12 C14,11.4477153 13.5522847,11 13,11 L11,11 Z"
          fill="inherit"
          id={`path-1-info${unique}`}
        />
      </g>
    </g>
  </svg>
)

Info.propTypes = propTypes
export default Info
