import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Link = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/link-link${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`combined-shape-link${unique}`}
        xlinkHref={`#path-1-link${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-link${unique}`}>
        <path
          d="M13.0194341,7.76776695 L12.3398114,7.06066017 C12.1521387,6.86539803 12.1521387,6.54881554 12.3398114,6.35355339 L14.718491,3.87867966 C15.8445269,2.70710678 17.6701917,2.70710678 18.7962276,3.87867966 L20.1554731,5.29289322 C21.281509,6.46446609 21.281509,8.36396103 20.1554731,9.53553391 L15.0583024,14.8388348 C13.9322666,16.0104076 12.1066017,16.0104076 10.9805659,14.8388348 L10.6407545,14.4852814 C10.4530818,14.2900192 10.4530818,13.9734367 10.6407545,13.7781746 L11.3203772,13.0710678 C11.5080499,12.8758057 11.8123274,12.8758057 12,13.0710678 L12.3398114,13.4246212 C12.7151567,13.8151455 13.3237116,13.8151455 13.6990569,13.4246212 L18.7962276,8.12132034 C19.1715729,7.73079605 19.1715729,7.09763107 18.7962276,6.70710678 L17.4369821,5.29289322 C17.0616368,4.90236893 16.4530818,4.90236893 16.0777366,5.29289322 L13.6990569,7.76776695 C13.5113843,7.9630291 13.2071068,7.9630291 13.0194341,7.76776695 Z M13.3592455,10.9497475 L12.6796228,11.6568542 C12.4919501,11.8521164 12.1876726,11.8521164 12,11.6568542 L11.6601886,11.3033009 C11.2848433,10.9127766 10.6762884,10.9127766 10.3009431,11.3033009 L5.20377241,16.6066017 C4.82842712,16.997126 4.82842712,17.630291 5.20377241,18.0208153 L6.56301793,19.4350288 C6.93836321,19.8255531 7.54691816,19.8255531 7.92226345,19.4350288 L10.3009431,16.9601551 C10.4886157,16.764893 10.7928932,16.764893 10.9805659,16.9601551 L11.6601886,17.6672619 C11.8478613,17.862524 11.8478613,18.1791065 11.6601886,18.3743687 L9.28150896,20.8492424 C8.15547311,22.0208153 6.32980827,22.0208153 5.20377241,20.8492424 L3.84452689,19.4350288 C2.71849104,18.263456 2.71849104,16.363961 3.84452689,15.1923882 L8.94169758,9.8890873 C10.0677334,8.71751442 11.8933983,8.71751442 13.0194341,9.8890873 L13.3592455,10.2426407 C13.5469182,10.4379028 13.5469182,10.7544853 13.3592455,10.9497475 Z"
          fill="inherit"
          id={`path-1-link${unique}`}
        />
      </g>
    </g>
  </svg>
)

Link.propTypes = propTypes
export default Link
