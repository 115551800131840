import React from "react"
import {bool, func, string} from "prop-types"
import styled from "styled-components"

import Primitives from "primitives"
import GridSystem from "lib/grid-system"
import {NavClose} from "components/glyphs"

const containerStyle = {
  justifyContent: "center",
  py: "12px",
  textAlign: ["left", "center"],
  width: 1,
}

const buttonStyles = {
  cursor: "pointer",
  height: "18px",
  position: "absolute",
  right: 12,
  top: 12,
  width: "18px",
}

const propTypes = {
  bannerRef: func,
  bannerText: string,
  closeBanner: func,
  showCloseButton: bool,
}

const BannerContainer = styled.div`
  h1, h2, h3, h4, h5, h6, p { margin: 0 };
  h1, h2, h3, h4, h5, h6, a {
  color: white;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 600;
  margin-bottom: 4px
  }
  color: white;
  font-size: 14px;
  line-height: 1.3;
`

const BannerComponent = ({
  bannerRef,
  bannerText,
  closeBanner,
  showCloseButton,
  ...props
}) => (
  <GridSystem.Wrapper zIndex={-100} {...props}>
    {showCloseButton && (
      <Primitives.Button onClick={closeBanner} {...buttonStyles}>
        <NavClose fill="white" />
      </Primitives.Button>
    )}
    <Primitives.Flex
      pr={showCloseButton ? ["22px", 0] : ""}
      ref={bannerRef}
      {...containerStyle}
    >
      <BannerContainer>
        <Primitives.RawHtml
          html={bannerText}
        />
      </BannerContainer>
    </Primitives.Flex>
  </GridSystem.Wrapper>
)

BannerComponent.propTypes = propTypes
export default BannerComponent
