import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Android = ({unique = "", ...props}) => (
  <svg viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/os/android-android${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`grey600-android${unique}`}
        xlinkHref={`#path-1-android${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-android${unique}`}>
        <path
          d="M4,8 L4.001,17.444 C4.001,18.305 4.697,19 5.558,19 L6,19 L6,22.542 C6,23.346 6.707,24 7.51,24 C8.316,24 9,23.346 9,22.541 L9,19 L11,19 L11,22.542 C11,23.346 11.707,24 12.511,24 C13.317,24 14,23.346 14,22.541 L14,19 L14.444,18.999 C15.302,18.999 16,18.303 16,17.442 L16,8 L4,8 Z M20,9.471 C20,8.666 19.305,7.99999888 18.5,7.99999888 C17.695,7.999 17,8.667 17,9.472 L17,15.578 C17,16.384 17.694,16.9999989 18.5,16.9999989 C19.305,16.9999989 20,16.385 20,15.578 L20,9.471 Z M3,9.471 C3,8.666 2.305,7.99999888 1.5,7.99999888 C0.695,7.999 0,8.667 0,9.472 L0,15.578 C0,16.384 0.694,16.9999989 1.5,16.9999989 C2.305,16.9999989 3,16.385 3,15.578 L3,9.471 Z M12.951,2.159 L13.891,0.3 C13.959,0.168 13.872,-2.35922393e-16 13.728,-2.35922393e-16 C13.662,-2.35922393e-16 13.598,0.038 13.564,0.105 L12.615,1.983 C11.084,1.246 9.071,1.171 7.386,1.983 L6.436,0.105 C6.403,0.038 6.339,-2.35922393e-16 6.272,-2.35922393e-16 C6.128,-2.35922393e-16 6.041,0.168 6.109,0.3 L7.049,2.159 C5.204,3.193 4,4.743 4,6.999 L16,6.999 C16,4.743 14.796,3.193 12.951,2.159 Z M7.501,5 C7.225,5 7,4.776 7,4.5 C7,4.226 7.225,4 7.501,4 C7.777,4 8,4.226 8,4.5 C8,4.776 7.777,5 7.501,5 Z M12.499,5 C12.223,5 12,4.776 12,4.5 C12,4.226 12.223,4 12.499,4 C12.775,4 13,4.226 13,4.5 C13,4.776 12.775,5 12.499,5 Z"
          id={`path-1-android${unique}`}
        />
      </g>
    </g>
  </svg>
)

Android.propTypes = propTypes
export default Android
