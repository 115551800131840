import { gql } from "@apollo/client"

export const INTERCEPTOR_COMPUTED_RESOURCE_FIELDS_FRAGMENT = gql`
  fragment interceptorComputedResourceFields on InterceptorResourceFields {
    downloadOptions
  }
`

export const INTERCEPTOR_RESOURCE_FIELDS_FRAGMENT = gql`
  fragment interceptorResourceFields on InterceptorResourceFields {
    artHelpUrl
    canRedistribute
    category
    chipProducts
    classification
    contentAccessLevel
    contentType
    context
    dcn
    description
    distroType
    documentDistroIds
    documentToolSuiteIds
    entityId
    featuredForPimProductIds
    highlights
    icon
    isDownloadable
    isLatest
    link
    hostOs
    missingClickthroughAgreements
    os
    packageType
    patchToolReleaseIds
    pimProductIds
    productFamilyName
    publishedOn
    publishingApplication
    publishingApplicationResourceId
    requiresAuthorization
    requiresVerification
    relatedFileDetails
    releaseNotes
    releaseVersion
    resourceId
    resourceSubType
    resourceType
    revision
    size
    softwareBuildType
    softwareProduct
    suiteId
    suiteName
    suiteReleaseId
    title
    url
  }
`

export const INTERCEPTOR_RESOURCE_FIELDS_FRAGMENT_SOFTWARE = gql`
  fragment interceptorResourceFields on InterceptorResourceFields {
    artHelpUrl
    canRedistribute
    category
    chipProducts
    classification
    contentAccessLevel
    contentType
    context
    dcn
    description
    distroType
    documentDistroIds
    documentToolSuiteIds
    entityId
    featuredForPimProductIds
    highlights
    isDownloadable
    isLatest
    link
    hostOs
    missingClickthroughAgreements
    os
    packageType
    patchToolReleaseIds
    pimProductIds
    productFamilyName
    publishedOn
    publishingApplication
    publishingApplicationResourceId
    requiresAuthorization
    requiresVerification
    relatedFileDetails
    releaseNotes
    releaseVersion
    resourceId
    resourceSubType
    resourceType
    revision
    size
    softwareBuildType
    softwareProduct
    suiteId
    suiteName
    suiteReleaseId
    title
    url
  }
`
