import React from "react"

const AccordionPlus = ({unique = "", ...props}) => {

    return <svg {...props} width="16" height="16" viewBox="0 0 16 16" stroke={'inherit'} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.00065 3.33301V12.6663M3.33398 7.99967H12.6673" stroke="inherit" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

}

export default AccordionPlus