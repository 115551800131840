import React from "react";
import { NavLink } from "react-router-dom";
import { arrayOf, func, node, oneOfType, shape, string } from "prop-types";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import Primitives from "primitives";
import { SCROLL_TO_HASH } from "lib/redux/actions";
// Pull out props here to prevent DOM errors
export const StyledLink = styled(
  ({
    alignItems,
    children,
    flexDirection,
    innerRef,
    justifyContent,
    lineHeight,
    to,
    ...props
  }) => (
    <NavLink ref={innerRef} to={to} {...props}>
      {children}
    </NavLink>
  ),
)``;

const propTypes = {
  children: node,
  linkHandler: func,
  /** React router DOM location object, can contain router state, query params, etc. */
  location: shape({
    //  A hash to put in the URL, e.g. #a-hash.
    hash: string,
    //  A string representing the path to link to.
    pathname: string,
    //  A string representation of query parameters.
    search: string,
    //  State obj to persist to the location, { fromDashboard: true }
    state: shape({
      blog_text: string,
      detail: string,
      loc: string,
      page_action: string,
      text: oneOfType([arrayOf(string), string]),
      type: string,
    }),
  }),

  onClick: func,
  /** Link path */
  url: string,
};

const InternalLink = ({
  children,
  url = null,
  onClick = null,
  linkHandler,
  location = {},
  ...props
}) => {
  const [pathname, search = ""] = url ? url.split("?") : [];
  const dispatch = useDispatch();
  const scrollToHah = () => {
    const hashVal =
      url.split("#").length > 2
        ? `#${url.split("#")[2]}`
        : `#${url.split("#")[1]}`;
    if (hashVal) {
      dispatch({ hash: hashVal, type: SCROLL_TO_HASH });
    }
  };
  return (
    <Primitives.Flex
      as={StyledLink}
      onClick={(evt) => {
        if (onClick) {
          onClick(evt);
        } else if (linkHandler) {
          linkHandler(
            {
              pathname: url,
              ...location,
            },
            evt,
          );
        } else if (url.includes("#")) {
          scrollToHah();
        }
      }}
      to={{
        ...location,
        pathname,
        search,
      }}
      {...props}
    >
      {children}
    </Primitives.Flex>
  );
};
InternalLink.propTypes = propTypes;

export default InternalLink;
