import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const SmsMessage = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/communications/sms_message-sms-message${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`combined-shape-sms-message${unique}`}
        xlinkHref={`#path-1-sms-message${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-sms-message${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,16 C21,16.5522847 20.5522847,17 20,17 L7,17 L3.87370466,20.5170823 C3.69024565,20.7234736 3.37420946,20.742064 3.16781808,20.558605 C3.0610739,20.4637213 3,20.3277192 3,20.1849003 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M8,10.5 C8.55228475,10.5 9,10.0522847 9,9.5 C9,8.94771525 8.55228475,8.5 8,8.5 C7.44771525,8.5 7,8.94771525 7,9.5 C7,10.0522847 7.44771525,10.5 8,10.5 Z M12,10.5 C12.5522847,10.5 13,10.0522847 13,9.5 C13,8.94771525 12.5522847,8.5 12,8.5 C11.4477153,8.5 11,8.94771525 11,9.5 C11,10.0522847 11.4477153,10.5 12,10.5 Z M16,10.5 C16.5522847,10.5 17,10.0522847 17,9.5 C17,8.94771525 16.5522847,8.5 16,8.5 C15.4477153,8.5 15,8.94771525 15,9.5 C15,10.0522847 15.4477153,10.5 16,10.5 Z"
          fill="inherit"
          id={`path-1-sms-message${unique}`}
        />
      </g>
    </g>
  </svg>
)

SmsMessage.propTypes = propTypes
export default SmsMessage
