import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Wifi = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id="Glyphs/Technology/*WiFi"
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-wifi${unique}`}
        xlinkHref={`#path-1-wifi${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-wifi${unique}`}>
        <path
          d="M12,19.75 C10.8954305,19.75 10,18.8545695 10,17.75 C10,16.6454305 10.8954305,15.75 12,15.75 C13.1045695,15.75 14,16.6454305 14,17.75 C14,18.8545695 13.1045695,19.75 12,19.75 Z M8.60674709,14.4718854 C8.16353273,14.8013994 7.53711263,14.709227 7.20759863,14.2660127 C6.87808464,13.8227983 6.970257,13.1963782 7.41347137,12.8668642 C10.4322431,10.6225156 13.5817543,10.6225156 16.600526,12.8668642 C17.0437404,13.1963782 17.1359128,13.8227983 16.8063988,14.2660127 C16.4768848,14.709227 15.8504647,14.8013994 15.4072503,14.4718854 C13.0968361,12.7541753 10.9171613,12.7541753 8.60674709,14.4718854 Z M5.65994601,12.4504076 C5.2390707,12.8080141 4.6079865,12.7567246 4.25037998,12.3358493 C3.89277346,11.914974 3.94406294,11.2838898 4.36493825,10.9262833 C9.4013553,6.6469741 14.6126421,6.6469741 19.6490591,10.9262833 C20.0699345,11.2838898 20.1212239,11.914974 19.7636174,12.3358493 C19.4060109,12.7567246 18.7749267,12.8080141 18.3540514,12.4504076 C14.064393,8.80559933 9.94960438,8.80559933 5.65994601,12.4504076 Z M3.62458798,9.50874782 C3.18720805,9.84596769 2.55927084,9.76477191 2.22205097,9.32739198 C1.8848311,8.89001205 1.96602688,8.26207484 2.40340681,7.92485497 C8.75853974,3.02504834 15.2554577,3.02504834 21.6105906,7.92485497 C22.0479705,8.26207484 22.1291663,8.89001205 21.7919464,9.32739198 C21.4547266,9.76477191 20.8267893,9.84596769 20.3894094,9.50874782 C14.7538739,5.16375073 9.26012346,5.16375073 3.62458798,9.50874782 Z"
          fill="inherit"
          id={`path-1-wifi${unique}`}
        />
      </g>
    </g>
  </svg>
)

Wifi.propTypes = propTypes
export default Wifi
