import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const StarHalf = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/shopping/star_half-star-half${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`star-star-half${unique}`}
        xlinkHref={`#path-1-star-half${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-star-half${unique}`}>
        <path
          d="M11.9980021,17.2092722 L6.68814269,20.0008304 C6.4437204,20.1293308 6.14140682,20.0353576 6.01290641,19.7909353 C5.96173688,19.693605 5.94407954,19.582121 5.96266796,19.4737422 L6.97676087,13.5611269 L2.68099438,9.37378944 C2.48325259,9.18103883 2.47920644,8.8644822 2.67195705,8.66674041 C2.74871135,8.58799859 2.84928255,8.536755 2.95810107,8.52094275 L8.89470435,7.6583038 L11.549634,2.27882866 C11.6718452,2.03120183 11.9716581,1.92953233 12.2192849,2.05174348 C12.3178912,2.1004086 12.397705,2.18022236 12.4463701,2.27882866 L15.1012998,7.6583038 L21.0379031,8.52094275 C21.3111755,8.56065156 21.5005163,8.81437298 21.4608074,9.08764541 C21.4449952,9.19646393 21.3937516,9.29703514 21.3150098,9.37378944 L17.0192433,13.5611269 L18.0333362,19.4737422 C18.0800167,19.7459104 17.8972228,20.0043884 17.6250546,20.0510689 C17.5166758,20.0696573 17.4051917,20.052 17.3078615,20.0008304 L11.9980021,17.2092722 Z M11.9988694,15.3954983 L16.1232262,17.563801 L15.3355442,12.9712622 L18.672219,9.71880807 L14.0610478,9.04876568 L11.9988694,4.87033578 L11.9988694,15.3954983 Z"
          fill="inherit"
          id={`path-1-star-half${unique}`}
        />
      </g>
    </g>
  </svg>
)

StarHalf.propTypes = propTypes
export default StarHalf
