
import React from "react"
import { string } from "prop-types"

const propTypes = {
    unique: string,
}

const WosArrowUp = ({ unique = "", ...props }) => (
    <svg width="10" height="10" viewBox="0 0 9 8" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M4.42861 2L7.31536 5.5L1.54186 5.5L4.42861 2Z" />
    </svg>
    
)

WosArrowUp.propTypes = propTypes
export default WosArrowUp
