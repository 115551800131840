import React from "react";
import {
  array,
  element,
  func,
  number,
  objectOf,
  oneOfType,
  string,
} from "prop-types";

import Primitives from "primitives";
import { NavClose } from "components/glyphs";

const buttonStyles = {
  cursor: "pointer",
  height: "24px",
  position: "absolute",
  right: [12, 24],
  top: [12, 24],
  width: "24px",
};

const dataContainerStyle = {
  maxHeight: "100vh",
};
const overlayStyles = {
  backgroundColor: "black",
  height: 1,
  left: "0",
  opacity: "0.3",
  position: "absolute",
  top: "0",
  width: 1,
};
const wrapperStyles = {
  alignItems: "center",
  height: 1,
  justifyContent: "center",
  left: "0",
  position: "fixed",
  top: "0",
  width: 1,
  zIndex: "10",
};

const propTypes = {
  /** The React component to display inside the Modal */
  children: element.isRequired,
  /** Function to close the modal */
  close: func,
  containerPadding: objectOf(oneOfType([string, number, array])),
  /** Styles object */
  modalContainerStyle: objectOf(oneOfType([string, number, array])),
};

const ModalComponent = ({
  children,
  close,
  containerPadding = [4, "72px"],
  modalContainerStyle,
}) => (
  <Primitives.Flex {...wrapperStyles}>
    <Primitives.Box onClick={close} {...overlayStyles} />
    <Primitives.Flex {...modalContainerStyle}>
      <Primitives.Button onClick={close} {...buttonStyles}>
        <NavClose fill="gray-dark" />
      </Primitives.Button>
      <Primitives.Box p={containerPadding} {...dataContainerStyle}>
        {children}
      </Primitives.Box>
    </Primitives.Flex>
  </Primitives.Flex>
);

ModalComponent.propTypes = propTypes;

export default ModalComponent;
