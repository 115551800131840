import {createStateSelector} from "lib/redux/utils"

const makeSelector = createStateSelector("createpointReducer")

export const GET_ACTIVATION_CODE_DATA = makeSelector("activationCodeData", {})
export const GET_ACTIVATION_CODE_CALLBACK = makeSelector(
  "activationCodeCallback",
  () => null,
)
export const GET_IS_CONTENT_MODAL_ACTIVE = makeSelector(
  "isContentPodModalActive",
  false,
)

export const GET_SHOW_QLA = makeSelector("showQla", false)
export const GET_SHOW_SRA = makeSelector("showSra", false)
export const GET_QLA_CALLBACK = makeSelector("qlaCallback", () => null)
export const GET_MISSING_AGREEMENT_TYPES = makeSelector("missingAgreementTypes", null)
export const GET_IS_ACTIVATION_MODAL_ACTIVE = makeSelector(
  "isSoftwareActivationModalActive",
  false,
)
