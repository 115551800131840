// import {css} from "styled-components"

import { css } from "styled-components/macro";

const breakpoints = {
  desk: "min-width: 1024px",
  deskWide: "min-width: 1360px",
  desktop: "max-width: 1359px",
  jumbo: "min-width: 1600px",
  landscape: "orientation: landscape",
  lap: "min-width: 668px",
  mobile: "max-width: 479px",
  palm: "max-width: 667px",
  phablet: "min-width: 480px",
  portable: "max-width: 1023px",
  portrait: "orientation: portrait",
  short: "max-height: 650px",
  tall: "min-height: 650px",
};

const makeMediaQuery =
  (query) =>
  (...styles) =>
    css`
      @media (${query}) {
        ${css(...styles)};
      }
    `;
const queries = (targets) =>
  makeMediaQuery(targets.map((target) => breakpoints[target]).join(") and ("));

export default {
  ...Object.keys(breakpoints).reduce(
    (accum, breakpoint) => ({
      ...accum,
      [breakpoint]: makeMediaQuery(breakpoints[breakpoint]),
    }),
    {},
  ),
  queries,
};
