import React from "react";
import {
  arrayOf,
  bool,
  elementType,
  func,
  node,
  oneOfType,
  string,
} from "prop-types";

import Box from "./Box";

const propTypes = {
  /**  Font size css style system prop */
  className: string,
  /** test id for selecting this element in Cypress */
  "data-test-id": string,
  /**  Margin css style system prop */
  html: oneOfType([arrayOf(string), string]),
  /** Padding css style system prop  */
  onClick: func,
  /** sanitize boolean prop */
  sanitize: bool,
  /** Polymorphic prop that determines what's ultimately being rendered  */
  tag: oneOfType([elementType, node, func, string]),
};

const RawHtml = ({
  className,
  "data-test-id": dataTestId,
  html,
  onClick = null,
  sanitize,
  tag = "div",
  ...props
}) => (
  <Box
    as={tag}
    className={className}
    dangerouslySetInnerHTML={{ __html: html }}
    data-test-id={dataTestId}
    onClick={onClick}
    sanitize={sanitize}
    {...props}
  />
);

RawHtml.propTypes = propTypes;
RawHtml.displayName = "Primitives.RawHtml";

export default RawHtml;
