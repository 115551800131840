import {
  assoc,
  dissoc,
  has,
  isNil,
  lensProp,
  not,
  or,
  over,
  pipe,
  prop,
  when,
} from "ramda";

export const API_ROOT =
  process.env.BUILD_TARGET === "client"
    ? "/api"
    : `https://test.content.qualcomm.com/api`;

export const apiUrl = (endpoint) => `${API_ROOT}${endpoint}`;

export const formatCredentials = assoc("credentials", "same-origin");
export const formatAcceptHeader = assoc(
  "accept",
  "application/json; charset=utf-8",
);

export const formatAuthHeader = when(
  () => not(isNil(process.env.RAZZLE_AUTH)),
  (obj) => assoc("authorization", process.env.RAZZLE_AUTH, obj),
);

export const setForwardedHost = when(
  has("x-forwarded-host"),
  pipe(
    (headers) => ({
      ...headers,
      host: prop("x-forwarded-host", headers),
    }),
    dissoc("x-forwarded-host"),
  ),
);

export const dissocHost = when(
  () =>
    or(
      process.env.BUILD_TARGET === "client",
      process.env.NODE_ENV !== "production",
    ),
  dissoc("host"),
);

export const formatHostHeader = pipe(setForwardedHost, dissocHost);

export const removeHeaders = pipe(
  dissoc("content-length"),
  dissoc("accept-encoding"),
);

export const formatHeaders = pipe(
  formatAcceptHeader,
  formatAuthHeader,
  formatHostHeader,
  removeHeaders,
);

export const formatOptionsHeaders = over(lensProp("headers"), formatHeaders);

export const apiOptions = pipe(formatCredentials, formatOptionsHeaders);

export default (endpoint, options = {}) =>
  fetch(apiUrl(endpoint), apiOptions(options)).then((response) =>
    response.json(),
  );
