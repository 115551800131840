import React from "react"
import {bool, number, oneOfType, string} from "prop-types"

const propTypes = {
  // Unique identification for generating tag ids
  isLoggedIn: bool,
  unique: oneOfType([number, string]),
}

const defaultProps = {
  unique: "",
}

const FRUAccount = ({unique, isLoggedIn, ...props}) => (
  <svg
    viewBox="0 0 36 32"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path
        d="M12.3728038,12.7499317 C12.4384283,13.1565956 11.9304456,13.749925 11.4013982,13.749925 L3.63339443,13.749925 C3.10434704,13.749925 2.59636431,13.1557623 2.66198887,12.7499317 C2.67414157,12.7015987 2.68467391,12.651599 2.69763678,12.6040993 C3.26152191,10.5316131 5.06579229,8.89079073 7.23059268,8.76412491 C7.32619389,8.75912494 7.42098492,8.74995833 7.51739632,8.74995833 C7.61380771,8.74995833 7.70940893,8.75912494 7.80419996,8.76412491 C9.96981053,8.89079073 11.7732707,10.5316131 12.3371559,12.603266 C12.3501187,12.651599 12.3614612,12.7007653 12.3728038,12.7499317 Z M7.51739632,7.91654722 C5.72770918,7.91654722 4.27667718,6.42405717 4.27667718,4.58323611 C4.27667718,2.74241505 5.72770918,1.249925 7.51739632,1.249925 C9.30708346,1.249925 10.7127454,2.73158179 10.7581155,4.58323611 C10.7581155,6.42405717 9.30708346,7.91654722 7.51739632,7.91654722 Z"
        id={`path-1-account${unique}`}
      />
      <path
        d="M6.80568551,0.119425428 C6.93578532,0.0419150148 7.09191095,0.0548303877 7.22201442,0.132344435 C8.92637612,1.33379037 10.9820132,1.81178276 13.0376502,2.21226473 C13.6621491,2.32853217 13.6621491,2.30269415 13.7141897,2.88404224 C14.0784816,6.17832384 13.2458202,9.20130991 11.2682423,11.8754937 C10.1753702,13.3611505 8.8092819,14.5238394 7.18298301,15.4152328 C7.03987029,15.5056658 6.92277607,15.5056658 6.77966335,15.4410708 C3.38395653,13.516175 1.27627517,10.6482134 0.417591524,6.86301672 C0.0793217667,5.44195493 0.0663088559,3.99505148 0.248456646,2.54815167 C0.274475142,2.30269415 0.417591524,2.30269415 0.599735652,2.25102176 C2.23904378,2.01848325 3.89136116,1.6567583 5.40056583,0.933308389 C5.8819483,0.700769881 6.32429937,0.416558976 6.80568551,0.119425428 Z"
        id={`path-3-account${unique}`}
      />
    </defs>
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`Symbols-nav-account-${unique}`}
      stroke="none"
      strokeWidth="1"
    >
      <g
        id={`Nav_FRU-1-account-${unique}`}
        transform="translate(-1267.000000, -19.000000)"
      >
        <g id="Nav">
          <g id="avatar" transform="translate(1161.000000, 21.000000)">
            <g
              id={`user-icon-verified-2-${unique}`}
              transform="translate(106.000000, 0.000000)"
            >
              <circle cx="12.5" cy="17.5" id="Oval" r="12.5" />
              <g
                id={`Glyphs/Nav/Account-${unique}`}
                transform="translate(5.000000, 10.000000)"
              >
                <use
                  fill="white"
                  fillRule="evenodd"
                  id={`combined-shape-account${unique}`}
                  xlinkHref={`#path-1-account${unique}`}
                />
              </g>
              <g id="badge" transform="translate(18.000000, 0.000000)">
                <g id="Fill-3">
                  <use
                    fill="black"
                    fillOpacity="1"
                    filter="url(#filter-4)"
                    xlinkHref={`#path-3-account${unique}`}
                  />
                  <use
                    fill="#B0C6FF"
                    fillRule="evenodd"
                    xlinkHref={`#path-3-account${unique}`}
                  />
                </g>
                <path
                  d="M11.102953,5.15439613 C11.3271831,4.88103589 11.2868918,4.47964514 11.0136097,4.25299543 C11.0101083,4.24953849 11.0066033,4.24607791 11.0031019,4.24434762 C10.7333212,4.02115849 10.3339131,4.0471092 10.0956666,4.30490047 C9.28458094,5.18554134 7.95671465,6.62847166 6.0437435,8.71674533 C5.77571709,8.5195069 5.11178395,8.02815203 4.47062432,7.55236249 C4.11851392,7.29111426 3.62100173,7.34820654 3.34246391,7.68558385 C3.34246391,7.68558385 3.34246391,7.68558385 3.34246391,7.68558385 C3.06567677,8.01777029 3.10246672,8.50739851 3.42479722,8.79633136 C3.78917337,9.12505723 4.53368914,9.78943012 5.30448344,10.4762895 C5.76871071,10.8897923 6.49045663,10.8327 6.88110765,10.3517196 L11.102953,5.15439613 Z"
                  fill="#3253DC"
                  id={`Fill-4-${unique}`}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

FRUAccount.propTypes = propTypes
FRUAccount.defaultProps = defaultProps

const LRUAccount = ({unique, isLoggedIn, ...props}) => (
  <svg
    viewBox="0 0 25 25"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <path
        d="M12.3728038,12.7499317 C12.4384283,13.1565956 11.9304456,13.749925 11.4013982,13.749925 L3.63339443,13.749925 C3.10434704,13.749925 2.59636431,13.1557623 2.66198887,12.7499317 C2.67414157,12.7015987 2.68467391,12.651599 2.69763678,12.6040993 C3.26152191,10.5316131 5.06579229,8.89079073 7.23059268,8.76412491 C7.32619389,8.75912494 7.42098492,8.74995833 7.51739632,8.74995833 C7.61380771,8.74995833 7.70940893,8.75912494 7.80419996,8.76412491 C9.96981053,8.89079073 11.7732707,10.5316131 12.3371559,12.603266 C12.3501187,12.651599 12.3614612,12.7007653 12.3728038,12.7499317 Z M7.51739632,7.91654722 C5.72770918,7.91654722 4.27667718,6.42405717 4.27667718,4.58323611 C4.27667718,2.74241505 5.72770918,1.249925 7.51739632,1.249925 C9.30708346,1.249925 10.7127454,2.73158179 10.7581155,4.58323611 C10.7581155,6.42405717 9.30708346,7.91654722 7.51739632,7.91654722 Z"
        id={`path-1-account${unique}`}
      />
    </defs>
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`Symbols-nav-account-${unique}`}
      stroke="none"
      strokeWidth="1"
    >
      <g
        id={`Nav_LRU-1-account-${unique}`}
        transform="translate(-1267.000000, -26.000000)"
      >
        <g id="Nav">
          <g
            id={`avatar-nav-account-${unique}`}
            transform="translate(1161.000000, 26.000000)"
          >
            <g
              id={`user-icon-${unique}`}
              transform="translate(106.000000, 0.000000)"
            >
              <circle cx="12.5" cy="12.5" fill="inherit" id="Oval" r="12.5" />
              <g
                id={`Glyphs/Nav/Account-${unique}`}
                transform="translate(5.000000, 5.000000)"
              >
                <use
                  fill="white"
                  fillRule="evenodd"
                  id={`combined-shape-account${unique}`}
                  xlinkHref={`#path-1-account${unique}`}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

LRUAccount.propTypes = propTypes
LRUAccount.defaultProps = defaultProps

const AccountPropTypes = {
  ...propTypes,
  /** account state */
  state: string,
}
const AccountDefaultProps = {
  ...defaultProps,
  state: "LRU",
}

/**
 * Account component recieves a state prop with user type.
 * Depending on that the prop the avatar component is rendered.
 * LRU and Anonymous user have same svg icon, so using LRUAccount component for both.
 */
const AvatarStates = (props) => ({
  FRU: <FRUAccount {...props} />,
  LRU: <LRUAccount {...props} />,
})

const Account = ({state, ...props}) => <>{AvatarStates(props)[state]}</>

Account.propTypes = AccountPropTypes
Account.defaultProps = AccountDefaultProps

export default Account
