import React, {useCallback, useEffect, useState, useContext} from "react"
import {bool} from "prop-types"
import {useDispatch, useSelector} from "react-redux"
import {equals} from "ramda"

import GlobalSearchNav from "./GlobalSearchNavComponent"
import useOnOffSwitch from "lib/hooks/use-on-off-switch"
import useSearchQuery from "lib/hooks/use-search-query"
import {GET_ACTIVE_ITEMS, GET_IS_MOBILE, GET_SHOW_LOGIN_PANEL} from "lib/redux/selectors"
import {SET_SHOWING_BLOCKER} from "lib/redux/actions"
import QualcommThemeContext from "components/QualcommThemeContext/ThemeContext"
import { useLocation } from "react-router"

const propTypes = {
  fixed: bool,
}

/**
 * This container is the parent of the search-bar and autosuggest containers.
 * @returns {*}
 * @constructor
 */
function GlobalSearchNavContainer(props) {
  const dispatch = useDispatch()
  const isMobile = useSelector(GET_IS_MOBILE)
  const value = useContext(QualcommThemeContext)
  const isMainMenuSearchActive = equals(useSelector(GET_ACTIVE_ITEMS), [
    "search",
  ])
  const showingLoginPanel = useSelector(GET_SHOW_LOGIN_PANEL)
  const isSearchActive = isMainMenuSearchActive && !showingLoginPanel

  const {
    searchQuery,
    searchValue,
    setActiveCategory,
    setSearchQuery,
    setSearchValue,
  } = useSearchQuery(value?.searchResultsPagePath)

  const {pathname, search} = useLocation()

  const [isFocused, onFocus, onBlur] = useOnOffSwitch(false)
  const [searchInputChanged, setSearchBarChanged] = useState(false)

  // Used to hide the autosuggest on submit regardless of the query value
  const setUrlQuery = useCallback(
    (query) => {
      onBlur()
      setSearchQuery(query || "")
    },
    [onBlur, setSearchQuery],
  )

  useEffect(() => {
    setSearchValue(searchQuery)
  }, [searchQuery, setSearchValue])

  useEffect(() => {
    onBlur()
  }, [onBlur, search])

  useEffect(() => {
    if (!value?.wosFlag && !isMobile) {
      dispatch({
        type: SET_SHOWING_BLOCKER,
        value: isSearchActive ? "all" : null,
      })
    }
  }, [dispatch, isMobile, isSearchActive, value?.wosFlag])

  const onSearchChange = useCallback(
    (value) => {
      if (value !== searchValue) {
        setSearchBarChanged(true)
        setSearchValue(value)
      }
    },
    [searchValue, setSearchValue],
  )

  /**
   * The drawer should only appear after the user has typed.
   * Every time the search bar loses focus, reset the userHasTyped condition to false
   */
  const onSearchBlur = useCallback(() => {
    onBlur()
    setSearchBarChanged(false)
  }, [onBlur, setSearchBarChanged])

  return (
    <GlobalSearchNav
      isOnSearchPage={pathname === value?.searchResultsPagePath}
      isSearchActive={isSearchActive}
      isSearchFocused={isFocused}
      onSearchBlur={onSearchBlur}
      onSearchChange={onSearchChange}
      onSearchFocus={onFocus}
      searchInputChanged={searchInputChanged}
      searchValue={searchValue}
      setActiveCategory={setActiveCategory}
      setSearchValue={setSearchValue}
      setUrlQuery={setUrlQuery}
      urlQuery={searchQuery}
      {...props}
    />
  )
}

GlobalSearchNavContainer.propTypes = propTypes

export default GlobalSearchNavContainer
