export const UPDATE_ACTIVE_VIDEO_ID = "UPDATE_ACTIVE_VIDEO_ID"
export const VIDEO_PLAY = "VIDEO_PLAY"
export const VIDEO_PAUSE = "VIDEO_PAUSE"
export const VIDEO_MILESTONE = "VIDEO_MILESTONE"

const initialState = {
  activeVideoId: null,
  videoMeta: {
    date: "",
    showGlobalSearch: false,
    title: "",
  },
  videoOverlay: false,
}

const reducer = {
  [UPDATE_ACTIVE_VIDEO_ID]: (state, {videoId, videoMeta, videoOverlay}) => ({
    ...state,
    activeVideoId: videoId,
    videoMeta: videoMeta || initialState.videoMeta,
    videoOverlay: videoOverlay || false,
  }),
}

function videosReducer(state = initialState, action) {
  const func = reducer[action.type]
  return func ? func(state, action) : state
}

export default videosReducer
