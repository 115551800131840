import { createStateSelector } from "lib/redux/utils"

const makeSelector = createStateSelector("globalSearchReducer")

export const GET_GLOBAL_SEARCH_RESULTS = makeSelector("results", [])
export const GET_GLOBAL_SEARCH_RESULTS_COUNT = makeSelector("resultCount", [])
export const GET_GLOBAL_SEARCH_STATUS = makeSelector("globalSearchStatus", {})
export const GET_GLOBAL_SEARCH_ACTIVE_FILTERS = makeSelector(
  "activeFilters",
  []
)
export const GET_GLOBAL_SEARCH_QUERY = makeSelector("query", [])
export const GET_NOT_LOGGED_IN_SESSION_ID = makeSelector(
  "notLoggedInSessionId",
  []
)
export const GET_GLOBAL_SEARCH_CLICK_EVENT_PARAMS = makeSelector(
  "globalSearchClickEventParameters", {}
)
