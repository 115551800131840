import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const TumblrBox = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit">
      <polygon
        fill="inherit"
        id={`path-3-tumblr-box${unique}`}
        points="0 0 12.9978 0 12.9978 10.5618 0 10.5618"
      />
    </defs>
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/tumblr_box-tumblr-box${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-tumblr-box${unique}`}
        xlinkHref={`#path-1-tumblr-box${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-tumblr-box${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M15.9432624,10.9574468 L15.9432624,7.9787234 L12.964539,7.9787234 L12.964539,5 L10.7801418,5 C10.5815603,6.19148936 10.3829787,7.9787234 8,8.17730496 L8,10.9574468 L9.9858156,10.9574468 C9.9858156,10.9574468 9.9858156,14.4326241 9.9858156,15.6241135 C9.9858156,17.4113475 11.2765957,19 13.858156,19 C14.9503546,19 15.9432624,18.9007092 15.9432624,18.9007092 L15.9432624,15.9219858 C15.9432624,15.9219858 14.9503546,15.9219858 14.0567376,15.9219858 C13.0638298,15.9219858 12.964539,15.5248227 12.964539,14.8297872 C12.964539,14.7304965 12.964539,10.9574468 12.964539,10.9574468 L15.9432624,10.9574468 Z"
          fill="inherit"
          id={`path-1-tumblr-box${unique}`}
        />
      </g>
      <g fill="inherit" id={`page-1-tumblr-box${unique}`} mask="url(#mask-2)">
        <g fill="inherit" transform="translate(22.000000, 23.000000)">
          <mask fill="none" id={`mask-4-tumblr-box${unique}`}>
            <use fill="inherit" xlinkHref={`#path-3-tumblr-box${unique}`} />
          </mask>
          <g fill="inherit" id={`clip-2-tumblr-box${unique}`} stroke="none" />
        </g>
      </g>
    </g>
  </svg>
)

TumblrBox.propTypes = propTypes
export default TumblrBox
