import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const FlickrBox = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/flickr_box-flickr-box${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-flickr-box${unique}`}
        xlinkHref={`#path-1-flickr-box${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-flickr-box${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M8.21712,15.44979 C9.97842,15.44979 11.41662,14.01159 11.41662,12.25029 C11.41662,10.48899 9.97842,9.05079 8.21712,9.05079 C6.45582,9.05079 5.01762,10.48899 5.01762,12.25029 C5.01762,14.01159 6.45582,15.44979 8.21712,15.44979 Z M15.78099,15.44979 C17.54229,15.44979 18.98049,14.01159 18.98049,12.25029 C18.98049,10.48899 17.54229,9.05079 15.78099,9.05079 C14.01969,9.05079 12.57969,10.48899 12.57969,12.25029 C12.57969,14.01159 14.01969,15.44979 15.78099,15.44979 Z"
          fill="inherit"
          id={`path-1-flickr-box${unique}`}
        />
      </g>
    </g>
  </svg>
)

FlickrBox.propTypes = propTypes
export default FlickrBox
