import { onError } from "@apollo/client/link/error";
import { cond, equals, F, map, merge, pathOr, pipe, when } from "ramda";

import { hasData } from "../utils/helpers";
import { handleLogoutRedirect } from "../utils/createpoint";

export { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";

const getCodeFromGQLError = pathOr({}, ["extensions", "code"]);

const hasAuthenticationError = pipe(
  getCodeFromGQLError,
  equals("UNAUTHENTICATED"),
);

const hasPersistedQueryError = pipe(
  getCodeFromGQLError,
  equals("PERSISTED_QUERY_NOT_FOUND"),
);

/**
 * @param {Object} obj - graphQL error obj
 * @param {string} obj.message - Error from graphQL server
 * @param {string} obj.code - Type of error (ex. GRAPHQL_VALIDATION_FAILED)
 */
const delegateError = () =>
  cond([
    [hasAuthenticationError, handleLogoutRedirect],
    [hasPersistedQueryError, F],
  ]);

const mapGQLErrors = (store) => when(hasData, map(delegateError(store)));

export const createErrorHttpLink = (store) =>
  onError(({ graphQLErrors, operation }) => {
    const decoratedContextErrors = map(
      (err) => merge(err, operation.getContext()),
      graphQLErrors || [],
    );
    mapGQLErrors(store)(decoratedContextErrors);
    return null;
  });
