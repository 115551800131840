import React from "react"
import styled, {css} from "styled-components"
import {bool, number} from "prop-types"

import {defaultTransition} from "lib/styles/deprecated/utils"
import Primitives from "primitives"
/* Import ButtonContainer to be able to transform the icon on parent hover */
import {ButtonContainer, LinkContainer} from "./ButtonContainer"

const GlyphContainer = styled(({animateGlyph, ...props}) => (
  <Primitives.Box {...props} />
))`
  ${defaultTransition("transform")};
  ${({animateGlyph}) =>
  animateGlyph
    ? css`
          ${ButtonContainer}:hover & {
            transform: translate3d(5px, 0, 0);
          }
          ${LinkContainer}:hover & {
            transform: translate3d(5px, 0, 0);
          }
        `
    : null};
`

const propTypes = {
  /** A boolean to represent whether the glyph should be animated or not */
  animateGlyph: bool,
  /** Determines the height of the button glyph */
  height: number,
  /** Determines the width of the button glyph */
  width: number
}

const ButtonGlyph = ({
  animateGlyph = true,
  height = 24,
  width = 24,
  ...props
}) => (
  <GlyphContainer
    animateGlyph={animateGlyph}
    display="inline-block"
    flex="0 0 auto"
    height={height}
    lineHeight="0px"
    width={width}
    {...props}
  />
)

ButtonGlyph.propTypes = propTypes
ButtonGlyph.displayName = "Modules.ButtonGlyph"

export default ButtonGlyph
