import { isEmpty, pipe, reject, split } from "ramda"

import analyticsSaga from "saga/analytics"
import {
  SET_ONETRUST_CONSENT_STATUS,
  SET_ONETRUST_LOADED,
} from "lib/redux/reducers/globals/globals-reducer"

// window.utag = {
//   view: console.log,
//   link: console.log
// }

const oneTrustCallback = (store) => {
  const state = store.getState()
  if (
    state?.globalsReducer?.oneTrustConsentStatus !== window.OnetrustActiveGroups
  ) {
    store.dispatch({
      type: SET_ONETRUST_CONSENT_STATUS,
      value: window.OnetrustActiveGroups,
    })
  }
}

const initializeOneTrust = (store) => {
  const state = store.getState()
  if (!state?.globalsReducer?.oneTrustLoaded) {
    store.dispatch({ type: SET_ONETRUST_LOADED, value: true })
    const groups = pipe(
      split(","),
      reject(isEmpty),
    )(window?.OnetrustActiveGroups)
    // One Trust active groups will always have at minimum ,C0001, and will give a length of 3 after split
    if (groups?.length > 1) {
      oneTrustCallback(store)
    }
    window.OneTrust.OnConsentChanged(() => {
      oneTrustCallback(store)
    })
  }
}

export default (store) => {
  // Run analytics middleware in production and when utag is defined
  // if (process.env.NODE_ENV === "production") {
  store.runSaga(analyticsSaga)
  // if (window?.OneTrust) {
  //   initializeOneTrust(store)
  // } else {
  //   window.OptanonWrapper = () => {
  //     initializeOneTrust(store)
  //   }
  // }
  // }
}
