import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Subscribe = ({...props}) => (
  <svg
    height="16"
    version="1.1"
    viewBox="0 0 19 16"
    width="19"
    x="0"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    y="0"
    {...props}
  >
    <path
      d="M16.8.6H2.2C1.2.6.5 1.4.5 2.3v11.3c0 1 .8 1.7 1.7 1.7h14.5c1 0 1.7-.8 1.7-1.7V2.4c.1-1-.7-1.8-1.6-1.8zm-.9 1.5L9.2 8 3 2.1h12.9zm.9 11.8H2.2c-.1 0-.2-.1-.2-.2V3.2l6.7 6.3c.1.2.3.2.5.2s.3-.1.5-.2L17 3.2v10.5c0 .1-.1.2-.2.2z"
      fill="inherit"
    />
  </svg>
)

Subscribe.propTypes = propTypes
export default Subscribe
