import React from "react"
import {string} from "prop-types"
import style from "./theme-icon.module.css"

const propTypes = {
  unique: string
}

const LinkedinWOS = ({unique = "", ...props}) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="inherit" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.36049 6.4689C5.45579 6.4689 6.34494 5.58079 6.34494 4.48549C6.34494 3.38915 5.45579 2.5 4.36049 2.5C3.26415 2.5 2.375 3.38915 2.375 4.48549C2.375 5.58079 3.26415 6.4689 4.36049 6.4689ZM10.9 11.6612C10.9 10.3639 11.6809 9.71524 12.5982 9.71524C13.5133 9.71524 14.2598 10.3639 14.2598 11.986V17.5H17.3157V11.6612C17.3157 8.74176 15.7883 7.12067 13.6497 7.12067C12.4274 7.12067 11.5101 7.85885 10.9 8.83234L10.798 7.44551H7.79528C7.79528 7.78701 7.84526 9.71524 7.84526 9.71524V17.5H10.9V11.6612ZM2.77429 17.5H5.94566V7.39033H2.77429V17.5Z"
        fill="inherit"/>
  </svg>
)

LinkedinWOS.propTypes = propTypes
export default LinkedinWOS
