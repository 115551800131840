import React, { MouseEvent, ReactElement, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { test } from "ramda"

import Link from "components/linkTs"
import {cn} from "lib/utils/cn-helper"
import Glyph from "components/glyph"
import {INTERNAL_LINK, SET_WOS_XP_MODAL_ACTIVE, SET_WOS_XP_PATH} from "lib/redux/actions"
import {IS_DDP_TEMPLATE} from "lib/redux/selectors"
import {
  IconValueMap,
  UndefinedIconValueMap,
  ctaClasses
} from "./types"
import {
  CtaItemsType,
  TargetValueArray,
  TargetTypeValueArray
} from "./CtaV2Dx.interface"

const CtaV2Dx = ({
  animation = true,
  callback,
  color = "blue",
  disabled = false,
  footerCta,
  size,
  url,
  ctaTitle,
  ctaTitleClass = "rte-heading-xs",
  iconAlign = "right",
  iconType,
  role,
  style = "text",
  target,
  width,
  alignment = "center",
  targetType,
  brightcoveVideo,
  megamenuCta
}: CtaItemsType): ReactElement => {
  const IS_TARGET_MODAL = target === TargetValueArray.TARGET_MODAL_POPUP
  const IS_TARGET_TYPE_UNDEFINED = targetType === TargetTypeValueArray.TARGET_TYPE_EMPTY
  const IS_TARGET_TYPE_DAM_FILE = targetType === TargetTypeValueArray.TARGET_TYPE_DAM_FILE
  const IS_TARGET_TYPE_CALL_BACK = targetType === TargetTypeValueArray.TARGET_TYPE_CALL_BACK

  const dispatch = useDispatch()
  const isDdp = useSelector(IS_DDP_TEMPLATE)

  const ctaIcon = useMemo(() => {
    const ddpMap = isDdp ? "ddp" : "non-ddp"
    const linkType = url && url.includes("://") ? "external" : "internal"
    if (iconType !== "default") {
      return iconType
    }
    if (IS_TARGET_TYPE_UNDEFINED || IS_TARGET_TYPE_DAM_FILE) {
      return UndefinedIconValueMap?.[linkType]?.[style]?.[ddpMap] as string
    }
    const definedIcon = IconValueMap?.[targetType]?.[style]?.[ddpMap]
    if (!definedIcon && !ctaTitle) {
      return "arrow-right"
    }
    return definedIcon
  }, [isDdp, iconType, targetType, url, IS_TARGET_TYPE_UNDEFINED, IS_TARGET_TYPE_DAM_FILE])

  const openModal = (e: MouseEvent, path: string): void => {
    e.stopPropagation()
    e.preventDefault()
    const modalurl = path.replace(".html", "")
    dispatch({
      type: INTERNAL_LINK,
      location: {
        label: ctaTitle,
        pathname: url,
        state: {
          document: "modal",
          type: "CTA",
        }
      }
    })
    dispatch({ type: SET_WOS_XP_MODAL_ACTIVE, value: true })
    dispatch({ type: SET_WOS_XP_PATH, value: modalurl })
  }

  const animationClasses = ctaTitle && animation ? cn([
    "transition-transform",
    "duration-700",
    "standard-ease",
    "group-hover:translate-x-xxs"
  ]) : ""

  const iconStyles = cn(
    "transform",
    size === "large-icon" ? "w-s" : "w-xs",
    animationClasses
  )

  const commonChildren = useMemo(() => (
    <>
      {ctaTitle ? <span className={ctaTitleClass}>{ctaTitle}</span> :  null}
      {ctaIcon ? <Glyph className={iconStyles} id={ctaIcon} role="presentation" /> : null}
    </>
  ), [ctaTitle, ctaIcon])
  const stateObj = megamenuCta || footerCta ? {
    state: {
      text: ctaTitle,
      type: megamenuCta ? "megamenu" : "footer",
      label: ctaTitle,
      detail: ctaTitle
    },
  } : {
    state: {
      text: ctaTitle,
      type: "CTA",
    },
  }
  const configuredLocation = test(
    /.pdf|.doc|.docx|.ppt|.pptx|.zip|.xls|.xlxs|.txt|.ics/,
    url,
  )
    ? {
      label: ctaTitle,
      state: {
        action: "document",
        document: ctaTitle,
        label: ctaTitle,
        loc: url,
        type: "download",
      },
    }
    : stateObj
    
  const classes = ctaClasses({
    iconAlign,
    color,
    style: disabled ? "disabled" : style,
    size: style === "text" ? "text" : size,
    width,
    alignment 
  })

  if (IS_TARGET_TYPE_UNDEFINED) {
    return IS_TARGET_MODAL ? (
      <button
        className={classes}
        onClick={(e): void => {
          openModal(e, url) 
        }}
        role={role}
      >
        {commonChildren}
      </button>
    ) : (
      <Link
        className={classes}
        href={url}
        location={configuredLocation}
        target={target}
      >
        {commonChildren}
      </Link>
    )
  }

  if (IS_TARGET_TYPE_DAM_FILE) {
    return (
      <Link
        className={classes}
        href={url}
        location={configuredLocation}
        target={TargetValueArray.TARGET_BLANK}
      >
        {commonChildren}
      </Link>
    )
  }

  if (IS_TARGET_TYPE_CALL_BACK){
    return <button
      className={classes}
      onClick={(event): void => {
        callback &&  callback(event)
      }}
    >
      {commonChildren}
    </button>
  }
  
  return <></>
}

export default CtaV2Dx
