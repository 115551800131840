import React from "react"
import { string } from "prop-types"

const propTypes = {
  unique: string,
}
const Search = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
    <path d="M19 19.0001L14.7 14.7001M17 9.00006C17 13.4183 13.4183 17.0001 9 17.0001C4.58172 17.0001 1 13.4183 1 9.00006C1 4.58178 4.58172 1.00006 9 1.00006C13.4183 1.00006 17 4.58178 17 9.00006Z" stroke="inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

Search.propTypes = propTypes
export default Search
