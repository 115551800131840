import { setContext } from "@apollo/client/link/context";

// apply this cache context to all Drupal queries
export const context = {
  context: {
    shouldCache: true,
  },
};

export const setHttpMethod = setContext((_, previousContext) => {
  const { shouldCache = false } = previousContext;
  return {
    ...previousContext,
    fetchOptions: {
      method: shouldCache ? "GET" : "POST",
    },
  };
});
