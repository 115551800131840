import React, {forwardRef, useEffect, useMemo} from "react"
import {bool, func, node, number, shape} from "prop-types"
import {useSpring} from "react-spring"
import {easeCubic} from "d3-ease"

import {useMeasure} from "../utils"
import Primitives from "primitives"

const propTypes = {
  allowScrollX: bool,
  allowScrollY: bool,
  children: node,
  config: shape({friction: number, mass: number, tension: number}),
  delay: number,
  duration: number,
  ease: func,
  scrollByPercentage: bool,
  scrollX: number,
  scrollY: number,
}

const defaultProps = {
  allowScrollX: false,
  allowScrollY: false,
  scrollByPercentage: false,
  scrollX: 0,
  scrollY: 0,
}

const ScrollBox = forwardRef((props, ref) => (
  <Primitives.AnimatedBox {...props} ref={ref} />
))

const ScrollTo = ({
  allowScrollX = false,
  allowScrollY = false,
  children,
  config = {friction: 30, mass: 1, tension: 180},
  delay,
  ease = easeCubic,
  duration,
  scrollByPercentage,
  scrollX,
  scrollY,
  ...props
}) => {
  const [
    bind,
    {height: viewHeight, width: viewWidth},
    {scrollHeight, scrollWidth},
  ] = useMeasure()
  const configVals = useMemo(() => {
    if (duration) {
      return {
        ...config,
        duration,
        ease,
      }
    }
    return config
  }, [config, duration, ease])

  const {x, y} = useMemo(
    () => ({
      x: scrollByPercentage ? (scrollWidth - viewWidth) * scrollX : scrollX,
      y: scrollByPercentage ? (scrollHeight - viewHeight) * scrollY : scrollY,
    }),
    [
      scrollByPercentage,
      scrollHeight,
      scrollWidth,
      scrollX,
      scrollY,
      viewHeight,
      viewWidth,
    ],
  )
  const [scrollXProps, setScrollX] = useSpring(() => ({
    config: configVals,
    from: {scroll: 0},
    scroll: -x,
  }))
  const [scrollYProps, setScrollY] = useSpring(() => ({
    config: configVals,
    from: {scroll: 0},
    scroll: -y,
  }))

  useEffect(() => {
    setScrollX({from: {scroll: 0}, scroll: x})
    setScrollY({from: {scroll: 0}, scroll: y})
  }, [setScrollX, setScrollY, x, y])

  return (
    <ScrollBox
      overflowX={allowScrollX ? "auto" : "visible"}
      overflowY={allowScrollY ? "auto" : "visible"}
      scrollLeft={scrollXProps.scroll}
      scrollTop={scrollYProps.scroll}
      {...bind}
      {...props}
    >
      {children}
    </ScrollBox>
  )
}

ScrollTo.propTypes = propTypes
ScrollTo.defaultProps = defaultProps
ScrollTo.displayName = "Animations.ScrollTo"

export default ScrollTo
