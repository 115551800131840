import { createGlobalStyle } from "styled-components";

import { colors } from "./deprecated/system";

export default createGlobalStyle`
#buorg {
  background: ${(props) => colors("browserUpdateBackground", props)};
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: fixed;
  right: 0;
  text-align: left;
  top: 0;
  z-index: 9999;
  > div {
    background-color: ${(props) =>
      colors("browserUpdateContainerBackground", props)};
    color: ${(props) => colors("browserUpdateTextColor", props)};
    border: 1px solid ${(props) => colors("browserUpdateBorder", props)};
    font-size: 18px;
    padding: 50px;
    margin: 100px auto 0;
    max-width: 600px;
    position: relative;
    &::before {
      background: url("/react/images/buorg-sprite.png") 0 0 no-repeat;
      content: "";
      display: block;
      height: 24px;
      left: 10px;
      position: absolute;
      top: 10px;
      width: 107px;
    }
  }
}

#buorgul {
  background: ${(props) => colors("browserUpdateIcon", props)};
  color: white;
  display: inline-block;
  box-shadow: none;
  padding: 0 4px;
}

#buorgig {
  background: url("/react/images/buorg-sprite.png") 0 100% no-repeat transparent;
  box-shadow: none;
  border: none;
  overflow: hidden;
  position: absolute;
  right: 10px !important;
  top: 10px !important;
  white-space: nowrap;

  text-align: left;
  text-indent: -1000px;
  line-height: 18px;
  padding: 0 5px !important;
  width: 20px !important;
  height: 20px !important;
}
#richTextContainer {
word-break: break-word;
}
.richText #richTextContainer  a
{
    color: rgb(50, 83, 220);
    text-decoration: underline;
    transition: color 0.7s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
.richText #richTextContainer   a:hover {
  color: rgb(28, 55, 165);
}
#richTextContainer .rte-body3 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing:0;
  color: #677283;
}
span.small {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing:0;
}
#richTextContainer .rte-body4 {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0;
  font-weight: 400;
  color: #677283;
}
#richTextContainer .rte-largeEyebrow {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 4px;
  font-weight: 400;
  color: #bbc0c8;
}
#richTextContainer .rte-eyebrow {
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 1.5px;
  font-weight: 400;
  color: #bbc0c8;
}
#richTextContainer p  {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0;
  font-weight: 400;
  color: #677283;
}
#richTextContainer .rte-body2{
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: #677283;
}

#richTextContainer .rteIntegrationSetPosition p {
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
}

#richTextContainer  h2 {
  font-size: 28px;
  line-height: 30px;
  letter-spacing: 0px;
  font-weight: 600;
  color: #222;
}
#richTextContainer   h3 {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.3px;
  font-weight: 600;
  color: #222;
}
 #richTextContainer  h4 {
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.3px;
  font-weight: 600;
  color: #222;
}
   #richTextContainer h5 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: #222;
}
 #richTextContainer  h6 {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: #222;
}
.rte-supplemental-blockQuote
  {
    padding-left:48px;
    padding-right:48px;
  }
  #articleContainer .rte-supplemental-blockQuote
  {
    padding-left:0;
    padding-right:0;
  }

@media screen and (max-width: 700px) {
   #richTextContainer  h1 span.rte-largeHeader {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0px;
    font-weight: 600;
    color: #222;
  }
   #richTextContainer  h1 {
    font-size: 32px;
    line-height: 35px;
    letter-spacing: 0px;
    font-weight: 600;
    color: #222;
  }
  .rte-supplemental-blockQuote
  {
    padding-left:24px;
    padding-right:24px;
  }
}
@media screen and (min-width: 701px) and (max-width: 949px) {
  
   #richTextContainer   h1  {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0px;
    font-weight: 600;
    color: #222;
  }
   #richTextContainer  h1 span.rte-largeHeader {
    font-size: 48px;
    line-height: 52px;
    letter-spacing: -0.2px;
    font-weight: 600;
    color: #222;
  }
}

@media (min-width: 950px) {
 
   #richTextContainer  h1 span.rte-largeHeader {
    font-size: 56px;
    line-height: 48px;
    letter-spacing: -0.5px;
    font-weight: 600;
    color: #222;
  }
   #richTextContainer  h1 {
    font-size: 42px ;
    line-height: 48px;
    letter-spacing: -0.5px;
    font-weight: 600;
    color: #222;
  }
   #richTextContainer  h2 {
    font-size: 32px;
    line-height: 35px;
    letter-spacing: 0px;
    font-weight: 600;
    color: #222;
  }  
}
@media screen and  (min-width: 1440px) {
   #richTextContainer  h1 {
    font-size: 48px;
    line-height: 52px;
    letter-spacing: -0.5px;
    font-weight: 600;
    color: #222;
  }
  #richTextContainer  h2 {
    font-size: 36px;
    line-height: 39px;
    letter-spacing: 0px;
    font-weight: 600;
    color: #222;
  }      
  #richTextContainer h1 span.rte-largeHeader {
    font-size: 60px;
    line-height: 64px;
    letter-spacing: -0.5px;
    font-weight: 600;
    color: #222;
  }  
  #richTextContainer p  {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0;
    font-weight: 400;
    color: #677283;
  }
}
@media screen and  (min-width: 1440px) {
  // 950 styles
  #articleContainer #richTextContainer  h1 {
   font-size: 42px;
   line-height: 48px;
   letter-spacing: -0.5px;
   font-weight: 600;
   color: #222;
  }    
  #articleContainer #richTextContainer h1 span.rte-largeHeader {
   font-size: 56px;
   line-height: 48px;
   letter-spacing: -0.5px;
   font-weight: 200;
   color: #222;
  }
  #articleContainer #richTextContainer p  {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0;
    font-weight: 400;
    color: #677283;
  }
  #articleContainer #richTextContainer .rte-body2{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    font-weight: 400;
    color: #677283;
  }
}

@media screen and  (min-width: 1800px) {
  #richTextContainer .rte-body2{
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;
    font-weight: 400;
    color: #677283;
  }
}
#richTextContainer .rte-footnote{
  color: rgb(180, 185, 189);
  margin-top: 16px;
  background-position: right center;
  font-size: 14px;
  line-height: 18px;
}
.rte-supplemental-blockQuote .rteIntegrationSetPosition  , .rteIntegrationSetPositionInline{
  margin-top:50px;
  }
  #articleContainer .vjs-modal-dialog-content
  {
    overflow:auto;
  }
  
  @media (min-width: 950px) { 
     .rte-supplemental-blockQuote .rteIntegrationSetPosition {
      position: absolute;
      left: 69%;
      right: 8vw;
    }
  }

  @media (min-width: 1319px) { 
    .rte-supplemental-blockQuote .rteIntegrationSetPosition {
     position: absolute;
     left: 68%;
     right: 9vw;
     margin-top:0;
   }
  }

  .vjs-fluid.aemQcVideoSettings.vjs-controls-enabled.vjs-playing. > .vjs-control-bar{
    display:flex;
  }
  .vjs-fluid.aemQcVideoSettings.vjs-paused.vjs-user-inactive > .vjs-control-bar {
    display:none;
  }
  .vjs-fluid.aemQcVideoSettings.vjs-has-started.vjs-paused > .vjs-control-bar {
    display:flex !important;
  } 
  .Accordion-BackgroundColor  .accordionHeadingstyles :is(h1,h2,h3,h4,h5,h6)
  {
    margin-top:0;
  }
  #leadership-listing div {
    padding-top: 10px;
}
#richTextContainer :is(h1,h2,h3,h4,h5,h6)
{
  margin-top:0;
}
#richTextContainer :is(h1,h2)
{   
  margin-bottom:24px;
}
#richTextContainer :is(h3,h4)
{   
  margin-bottom:16px;
}
#richTextContainer :is(h5,h6) 
{   
  margin-bottom:8px;
}
`;
