import StickyNavWrapper from "components/sticky-nav-wrapper"
import { GET_IS_MOBILE, GET_MODEL_DATA } from "lib/redux/selectors"
import throttle from "lodash.throttle"
import React, { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Waypoint } from "react-waypoint"
import styles from "./Menu.module.css"
import MenuItemsLayoutCompact from "./MenuItemsLayoutCompact"
import MenuItemsLayoutDefault from "./MenuItemsLayoutDefault"
import Primitives from "primitives"
import { INTERNAL_LINK } from "lib/redux/actions"

export default function Menu({
  developerLinkTitle,
  developerLinkUrl,
  items = [],
  currentPageId,
  currentPageChildrenId,
  cta,
  urlSearch
}) {
  const dispatch = useDispatch()
  const isMobileView = useSelector(GET_IS_MOBILE)
  const modelData = useSelector(GET_MODEL_DATA)
  const nid = modelData?.pageId

  const [sticky, setSticky] = useState(false)
  const throttledSticky = throttle(setSticky, 5)

  const onPositionChangeHandler = useCallback(
    ({currentPosition, previousPosition}) => {
      if (currentPosition !== previousPosition) {
        throttledSticky(currentPosition === Waypoint.above)
      }
    },
    [throttledSticky],
  )


  const onTabClick = useCallback(
    ({ url, title }) => {
      dispatch({
        location: {
          action: "tab click",
          pathname: window?.location?.pathname,
          state: {
            text: title,
            type: "tab",
          }
        },
        type: INTERNAL_LINK,
      })
      return setTimeout(
        () => window.location.href = url,
        50
      )
    },
    [dispatch],
  )

  return (
    <Primitives.Box position="relative" zIndex="2">
      <StickyNavWrapper
        isSticky={sticky}
        nav={
          <div className={`${styles.menu} allow-theme ${!isMobileView ? styles.globalWrapper : ""}`}>
            <div className={`${styles.menuContainer} ${!isMobileView ? styles.globalContainer : ""}`}>
              {isMobileView ? (
                <MenuItemsLayoutCompact
                  developerLinkTitle={developerLinkTitle}
                  developerLinkUrl={developerLinkUrl}
                  items={items}
                  currentPageId={currentPageId}
                  currentPageChildrenId={currentPageChildrenId}
                  cta={cta}
                  urlSearch={urlSearch}
                  onTabClick={onTabClick}
                />
              ) : (
                <MenuItemsLayoutDefault
                  developerLinkTitle={developerLinkTitle}
                  developerLinkUrl={developerLinkUrl}
                  items={items}
                  currentPageId={currentPageId}
                  cta={cta}
                  urlSearch={urlSearch}
                  onTabClick={onTabClick}
                />
              )}
            </div>
          </div>
        }
        nid={nid}
        onPositionChangeHandler={onPositionChangeHandler}
        stickyNavHeight={60}
        topOffsetNumber={60}
      />
    </Primitives.Box>
  )
}
