import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string
}

const GenericDocument = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id="Glyphs/Documents/*Generic_Document"
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-generic-document${unique}`}
        xlinkHref={`#path-1-generic-document${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-generic-document${unique}`}>
        <path
          d="M5,2 L13.7928932,2 C13.9255015,2 14.0526784,2.05267842 14.1464466,2.14644661 L19.8535534,7.85355339 C19.9473216,7.94732158 20,8.07449854 20,8.20710678 L20,21 C20,21.5522847 19.5522847,22 19,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,3 C4,2.44771525 4.44771525,2 5,2 Z M13,4 L13,9 L18,9 L13,4 Z M6.5,13 C6.22385763,13 6,13.2238576 6,13.5 L6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L17.5,15 C17.7761424,15 18,14.7761424 18,14.5 L18,13.5 C18,13.2238576 17.7761424,13 17.5,13 L6.5,13 Z M6.5,17 C6.22385763,17 6,17.2238576 6,17.5 L6,18.5 C6,18.7761424 6.22385763,19 6.5,19 L17.5,19 C17.7761424,19 18,18.7761424 18,18.5 L18,17.5 C18,17.2238576 17.7761424,17 17.5,17 L6.5,17 Z"
          fill="inherit"
          id={`path-1-generic-document${unique}`}
        />
      </g>
    </g>
  </svg>
)

GenericDocument.propTypes = propTypes
export default GenericDocument
