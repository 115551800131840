import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Placeholder = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/general/placeholder-placeholder${unique}`}
      stroke="none"
      strokeWidth={1}
    />
  </svg>
)

Placeholder.propTypes = propTypes
export default Placeholder
