import { css } from "styled-components";

import { colors } from "./system";
import hexToRGBA from "./hex-to-rgba";
import { transitionEase } from "./utils";

export const inlineLinkUnderline = (
  textColor = "gray-dark",
  fontWeight = 400,
) => css`
  border-bottom: 1px solid ${(props) => colors(textColor, props)};
  border-left: none;
  border-radius: 0;
  border-right: none;
  border-top: none;
  color: ${(props) => colors(textColor, props)};
  font-weight: ${fontWeight};
  padding: 0;
  &:hover {
    border-color: ${(props) => colors("dark-aqua", props)};
    color: ${(props) => colors("dark-aqua", props)};
  }
`;

export const inlineLink = (hoverColor, color) => css`
  &:hover {
    border-color: ${hexToRGBA(color, 0.5)};
    color: ${hoverColor};
  }
  border: 1px solid ${hexToRGBA(color, 0.1)};
  border-radius: 3px;
  color: ${color};
  display: inline-block;
  font-weight: 600;
  padding: 0 3px 2px 3px;
  text-decoration: none;
  ${transitionEase("0.2s", ["border-color", "color"])};
`;

export const inlineLinkWrapper = css`
  ${(props) =>
    props.textColor === "dark"
      ? inlineLink(colors("dark-aqua", props), colors("gray-sub", props))
      : inlineLink(
          colors(props.textColor, props),
          colors(props.textColor, props),
        )};
`;
