export const CLEAR_GLOBAL_ERROR_STATE = "CLEAR_GLOBAL_ERROR_STATE"
export const FETCH_INITIAL_SCROLL_POSITION = "FETCH_INITIAL_SCROLL_POSITION"
export const FETCH_SCROLL_POSITION = "FETCH_SCROLL_POSITION"
export const FETCH_VIEWPORT = "FETCH_VIEWPORT"
export const RESET_SCROLL_CHECKPOINT_MAP = "RESET_SCROLL_CHECKPOINT_MAP"
export const SCROLL_TO_HASH = "SCROLL_TO_HASH"
export const SET_API_HEADERS = "SET_API_HEADERS"
export const SET_BASE_URL = "SET_BASE_URL"
export const SET_CONTEXT = "SET_CONTEXT"
export const SET_COOKIES_ALLOWED = "SET_COOKIES_ALLOWED"
export const SET_GLOBAL_ERROR_STATE = "SET_GLOBAL_ERROR_STATE"
export const SET_INITIAL_SCROLL_POSITION = "SET_INITIAL_SCROLL_POSITION"
export const SET_PREVIOUS_ROUTE = "SET_PREVIOUS_ROUTE"
export const SET_SCROLL_DIRECTION = "SET_SCROLL_DIRECTION"
export const TOGGLE_SHARE_OVERLAY = "TOGGLE_SHARE_OVERLAY"
export const TRIGGER_SCROLL_CHECKPOINT = "TRIGGER_SCROLL_CHECKPOINT"
export const UPDATE_SCROLL_CHECKPOINT_MAP = "UPDATE_SCROLL_CHECKPOINT_MAP"
export const UPDATE_SCROLL_POSITION = "UPDATE_SCROLL_POSITION"
export const UPDATE_VIEWPORT = "UPDATE_VIEWPORT"
export const SET_PRODUCT_TITLE = "SET_PRODUCT_TITLE"
export const SET_MODEL_DATA = "SET_MODEL_DATA"
export const SET_DISQUSSHORT_NAME = "SET_DISQUSSHORT_NAME"
export const SET_TAB_PAGE_DATA = "SET_TAB_PAGE_DATA"
export const UPDATE_MODEL_DATA = "UPDATE_MODEL_DATA"
export const SET_SEARCH_ORIGIN = "SET_SEARCH_ORIGIN"
export const SET_ONETRUST_LOADED = "SET_ONETRUST_LOADED"
export const SET_ONETRUST_CONSENT_STATUS = "SET_ONETRUST_CONSENT_STATUS"
export const SET_PAGE_VIEW_STATUS = "SET_PAGE_VIEW_STATUS"
export const SET_IS_DX = "SET_IS_DX"

export const initialScrollCheckpointMap = [25, 50, 75, 100]
const initialState = {
  baseUrl: null,
  context: null,
  cookiesAllowed: null,
  error: null,
  headers: null,
  height: null,
  initialScrollPosition: null,
  isDX: false,
  lastPageView: null,
  oneTrustConsentStatus: null,
  oneTrustLoaded: false,
  pagemodeldata: {},
  previousRoute: null,
  producttitle: "product",
  scrollCheckpoint: null,
  scrollCheckpointMap: initialScrollCheckpointMap,
  scrollDirection: {
    direction: "up",
    hide: true,
  },
  scrollPercentage: null,
  searchOrigin: null,
  shareOverlayOpen: false,
  tabPageData: [],
  width: null,
}

const reducer = {
  [CLEAR_GLOBAL_ERROR_STATE]: (state) => ({
    ...state,
    error: null,
  }),
  [RESET_SCROLL_CHECKPOINT_MAP]: (state) => ({ ...state, scrollCheckpointMap: initialScrollCheckpointMap }),
  [SET_API_HEADERS]: (state, { value }) => ({
    ...state,
    headers: value,
  }),
  [SET_BASE_URL]: (state, { value }) => ({
    ...state,
    baseUrl: value,
  }),
  [SET_CONTEXT]: (state, { value }) => ({
    ...state,
    context: value,
  }),
  [SET_COOKIES_ALLOWED]: (state, { value }) => ({
    ...state,
    cookiesAllowed: value,
  }),
  [SET_DISQUSSHORT_NAME]: (state, { value }) => ({
    ...state,
    disqusShortName: value,
  }),
  [SET_GLOBAL_ERROR_STATE]: (state, { value }) => ({
    ...state,
    error: value,
  }),
  [SET_INITIAL_SCROLL_POSITION]: (state, { value }) => ({
    ...state,
    initialScrollPosition: value,
  }),
  [SET_IS_DX]: (state, { value }) => ({
    ...state,
    isDX: value,
  }),
  [SET_MODEL_DATA]: (state, { value }) => ({
    ...state,
    pagemodeldata: {
      ...value,
    },
  }),
  [SET_ONETRUST_CONSENT_STATUS]: (state, { value }) => ({
    ...state,
    oneTrustConsentStatus: value,
  }),
  [SET_ONETRUST_LOADED]: (state, { value }) => ({
    ...state,
    oneTrustLoaded: value,
  }),
  [SET_PAGE_VIEW_STATUS]: (state, { value }) => ({
    ...state,
    lastPageView: value,
  }),
  [SET_PREVIOUS_ROUTE]: (state, { value }) => ({
    ...state,
    previousRoute: value,
  }),
  [SET_PRODUCT_TITLE]: (state, { value }) => ({
    ...state,
    producttitle: value,
  }),
  [SET_SCROLL_DIRECTION]: (state, { value }) => ({
    ...state,
    scrollDirection: value,
  }),
  [SET_SEARCH_ORIGIN]: (state, { value }) => ({
    ...state,
    searchOrigin: value,
  }),
  [SET_TAB_PAGE_DATA]: (state, { value }) => ({
    ...state,
    tabPageData: value,
  }),
  [TOGGLE_SHARE_OVERLAY]: (state, { value }) => ({
    ...state,
    shareOverlayOpen: value,
  }),
  [TRIGGER_SCROLL_CHECKPOINT]: (state, { value }) => ({
    ...state,
    scrollCheckpoint: value,
  }),
  [UPDATE_MODEL_DATA]: (state, { value }) => ({
    ...state,
    pagemodeldata: {
      ...state.pagemodeldata,
      ...value,
    },
  }),
  [UPDATE_SCROLL_CHECKPOINT_MAP]: (state, { value }) => ({
    ...state,
    scrollCheckpointMap: value,
  }),
  [UPDATE_SCROLL_POSITION]: (state, { value }) => ({
    ...state,
    scrollPercentage: value,
  }),
  [UPDATE_VIEWPORT]: (state, { value }) => ({ ...state, ...value }),
}

function globalsReducer(state = initialState, action) {
  const func = reducer[action.type]
  return func ? func(state, action) : state
}

export default globalsReducer
