
import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}
const LeftArrow = (props) => (
  <svg {...props} fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M7.99998 12.6668L3.33331 8.00016M3.33331 8.00016L7.99998 3.3335M3.33331 8.00016H12.6666" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
    </g>
  </svg>
)

LeftArrow.propTypes = propTypes
export default LeftArrow
