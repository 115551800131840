/* eslint-disable no-console */
import { isNil, keys } from "ramda"
import { channel } from "redux-saga"
import {
  actionChannel,
  all,
  call,
  fork,
  put,
  select,
  take,
} from "redux-saga/effects"

import {
  BLOCKER_CLICK,
  CLICK_INTERCEPTOR_RESULT_ITEM,
  DOWNLOAD_LINK,
  EXTERNAL_LINK,
  INTERNAL_LINK,
  ROUTE_TRANSITION,
  SET_MAIN_MENU_ACTIVE_ITEM,
  SET_MODEL_DATA,
  SET_ONETRUST_CONSENT_STATUS,
  SET_SHOW_LOGIN_PANEL,
  SUBMIT_WEBFORM,
  TOGGLE_SITE_MAP_PANEL,
  TRACK_GLOBAL_SEARCH_RESULTS,
  TRIGGER_MENU_SLIDER,
  TRIGGER_SCROLL_CHECKPOINT,
  USER_INFO_LOADED,
  USER_LOGGED_IN,
  USER_LOGIN_FAILURE,
  USER_LOGIN_REQUEST,
  VIDEO_MILESTONE,
  VIDEO_PAUSE,
  VIDEO_PLAY,
} from "lib/redux/actions"
import {
  trackBlocker,
  trackDownload,
  trackFooterLink,
  trackLink,
  trackLogin,
  trackMegaMenuEvent,
  trackMenuSlider,
  trackScrollPosition,
  trackSearchResultClick,
  trackSearchResults,
  trackUserLogin,
  trackVideoMilestone,
  trackVideoPause,
  trackVideoPlay,
  trackView,
  trackWebformSubmit,
} from "./analytics"
import CREATPOINT_EVENT_MAP from "./analytics-createpoint"

export const EVENT_MAP = {
  [BLOCKER_CLICK]: trackBlocker,
  [CLICK_INTERCEPTOR_RESULT_ITEM]: trackSearchResultClick,
  [DOWNLOAD_LINK]: trackDownload,
  [EXTERNAL_LINK]: trackLink,
  [INTERNAL_LINK]: trackLink,
  [ROUTE_TRANSITION]: trackLink,
  [SET_MAIN_MENU_ACTIVE_ITEM]: trackMegaMenuEvent,
  [SET_MODEL_DATA]: trackView,
  [SET_ONETRUST_CONSENT_STATUS]: trackView,
  [SET_SHOW_LOGIN_PANEL]: trackLogin,
  [SUBMIT_WEBFORM]: trackWebformSubmit,
  [TOGGLE_SITE_MAP_PANEL]: trackFooterLink,
  [TRACK_GLOBAL_SEARCH_RESULTS]: trackSearchResults,
  [TRIGGER_MENU_SLIDER]: trackMenuSlider,
  [TRIGGER_SCROLL_CHECKPOINT]: trackScrollPosition,
  [USER_INFO_LOADED]: trackView,
  [USER_LOGGED_IN]: trackUserLogin,
  [USER_LOGIN_FAILURE]: trackUserLogin,
  [USER_LOGIN_REQUEST]: trackUserLogin,
  [VIDEO_MILESTONE]: trackVideoMilestone,
  [VIDEO_PAUSE]: trackVideoPause,
  [VIDEO_PLAY]: trackVideoPlay,
  ...CREATPOINT_EVENT_MAP,
}

export function* handleEvent(chan) {
  try {
    while (true) {
      // eslint-disable-next-line
      const {type, event} = yield take(chan)
      if (typeof window.utag !== "undefined") {
        if (type === "view") {
          yield setTimeout(() => {
            return window.utag?.[type]?.(event) 
          }, 250)
        } else {
          yield window.utag?.[type]?.(event)
        }
      } // utag pkg?
    }
  } catch (error) {
    console.log(error)
  }
}

export function* getEvent(action, eventMap, prevState) {
  try {
    return yield call(eventMap[action.type], action, prevState)
  } catch (error) {
    console.log(error)
    return null
  }
}

export function* handleActions(actionChan, eventChan, eventMap) {
  while (true) {
    const prevState = yield select()
    const action = yield take(actionChan)
    const event = yield call(eventMap[action.type], action, prevState)
    if (!isNil(event)) {
      yield put(eventChan, event)
    }
  }
}

export default function* analyticsSaga() {
  const actionChan = yield actionChannel(keys(EVENT_MAP))
  const eventChan = yield call(channel)

  console.debug({ EVENT_MAP, actionChan, eventChan })

  yield all([
    fork(handleActions, actionChan, eventChan, EVENT_MAP),
    fork(handleEvent, eventChan),
  ])
}
