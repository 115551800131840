import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Sitemap = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/sitemap-sitemap${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-sitemap${unique}`}
        xlinkHref={`#path-1-sitemap${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-sitemap${unique}`}>
        <path
          d="M19,15 L19,13 L12.5,13 L12.5,15 L14,15 C14.2761424,15 14.5,15.2238576 14.5,15.5 L14.5,19.5 C14.5,19.7761424 14.2761424,20 14,20 L10,20 C9.72385763,20 9.5,19.7761424 9.5,19.5 L9.5,15.5 C9.5,15.2238576 9.72385763,15 10,15 L11.5,15 L11.5,13 L5,13 L5,15 L6.5,15 C6.77614237,15 7,15.2238576 7,15.5 L7,19.5 C7,19.7761424 6.77614237,20 6.5,20 L2.5,20 C2.22385763,20 2,19.7761424 2,19.5 L2,15.5 C2,15.2238576 2.22385763,15 2.5,15 L4,15 L4,12.5 C4,12.2238576 4.22385763,12 4.5,12 L11.5,12 L11.5,9 L9.5,9 C9.22385763,9 9,8.77614237 9,8.5 L9,4.5 C9,4.22385763 9.22385763,4 9.5,4 L14.5,4 C14.7761424,4 15,4.22385763 15,4.5 L15,8.5 C15,8.77614237 14.7761424,9 14.5,9 L12.5,9 L12.5,12 L19.5,12 C19.7761424,12 20,12.2238576 20,12.5 L20,15 L21.5,15 C21.7761424,15 22,15.2238576 22,15.5 L22,19.5 C22,19.7761424 21.7761424,20 21.5,20 L17.5,20 C17.2238576,20 17,19.7761424 17,19.5 L17,15.5 C17,15.2238576 17.2238576,15 17.5,15 L19,15 Z"
          fill="inherit"
          id={`path-1-sitemap${unique}`}
        />
      </g>
    </g>
  </svg>
)

Sitemap.propTypes = propTypes
export default Sitemap
