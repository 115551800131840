export * from "./reducers/cover/cover-reducer"
export * from "./reducers/createpoint/createpoint-reducer"
export * from "./reducers/data/data-reducer"
export * from "./reducers/drupal/drupal-reducer"
export * from "./reducers/globals/globals-reducer"
export * from "./reducers/global-search/global-search-reducer"
export * from "./reducers/home-promo/home-promo-reducer"
export * from "./reducers/node/node-reducer"
export * from "./reducers/ui/ui-reducer"
export * from "./reducers/user/user-reducer"
export * from "./reducers/videos/videos-reducer"

/** Analytics Only Actions */
export const CLICK_INTERCEPTOR_RESULT_ITEM = "CLICK_INTERCEPTOR_RESULT_ITEM"
export const DOWNLOAD_LINK = "DOWNLOAD_LINK"
export const EXTERNAL_LINK = "EXTERNAL_LINK"
export const INTERNAL_LINK = "INTERNAL_LINK"
export const SUBMIT_WEBFORM = "SUBMIT_WEBFORM"
export const TOGGLE_SITE_MAP_PANEL = "TOGGLE_SITE_MAP_PANEL"
export const TRIGGER_MENU_SLIDER = "TRIGGER_MENU_SLIDER"
export const USER_LOGGED_IN = "USER_LOGGED_IN"
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE"
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST"
export const TRACK_MARKETO_FORM="TRACK_MARKETO_FORM"
