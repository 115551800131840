import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Dashboard = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/dashboard-dashboard${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`grey600-dashboard${unique}`}
        xlinkHref={`#path-1-dashboard${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-dashboard${unique}`}>
        <path
          d="M14,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,8 C21,8.55228475 20.5522847,9 20,9 L14,9 C13.4477153,9 13,8.55228475 13,8 L13,4 C13,3.44771525 13.4477153,3 14,3 Z M14,11 L20,11 C20.5522847,11 21,11.4477153 21,12 L21,20 C21,20.5522847 20.5522847,21 20,21 L14,21 C13.4477153,21 13,20.5522847 13,20 L13,12 C13,11.4477153 13.4477153,11 14,11 Z M4,15 L10,15 C10.5522847,15 11,15.4477153 11,16 L11,20 C11,20.5522847 10.5522847,21 10,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,16 C3,15.4477153 3.44771525,15 4,15 Z M4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,12 C11,12.5522847 10.5522847,13 10,13 L4,13 C3.44771525,13 3,12.5522847 3,12 L3,4 C3,3.44771525 3.44771525,3 4,3 Z"
          fill="inherit"
          id={`path-1-dashboard${unique}`}
        />
      </g>
    </g>
  </svg>
)

Dashboard.propTypes = propTypes
export default Dashboard
