import { cva } from "class-variance-authority"

export const tabMenuItemButtonClass = cva(
    "h-[68px] border-b-4",
    {
        variants: {
            activeState: {
                active: "border-theme-blue-500",
                inactive: "border-transparent"
            }
        }
    }
)

export const tabMenuItemTextClass = cva(
    "body-s",
    {
        variants: {
            activeState: {
                active: "text-theme-neutral-800",
                inactive: "text-theme-neutral-600"
            }
        }
    }
)