import React from "react"
import {string} from "prop-types"


const propTypes = {
  unique: string
}

const FacebookWOS = ({unique = "", ...props}) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3879 7.91712H14.514L13.9704 11.0422H11.3879V18.3337H8.26386V11.0422H5.83337V7.91712H8.26386V5.8334C8.26386 3.53932 9.97999 1.66699 12.0814 1.66699H14.514V4.79206H12.0814C11.6972 4.79206 11.3879 5.25545 11.3879 5.8334V7.91712Z"
          fill="inherit" />
  </svg>
)

FacebookWOS.propTypes = propTypes
export default FacebookWOS
