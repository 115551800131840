import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Home = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/home-home${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`combined-shape-home${unique}`}
        xlinkHref={`#path-1-home${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-home${unique}`}>
        <path
          d="M12,5.41421356 L4.06066017,13.3535534 C3.86539803,13.5488155 3.54881554,13.5488155 3.35355339,13.3535534 L2.64644661,12.6464466 C2.45118446,12.4511845 2.45118446,12.134602 2.64644661,11.9393398 L11.6464466,2.93933983 C11.8417088,2.74407768 12.1582912,2.74407768 12.3535534,2.93933983 L21.3535534,11.9393398 C21.5488155,12.134602 21.5488155,12.4511845 21.3535534,12.6464466 L20.6464466,13.3535534 C20.4511845,13.5488155 20.134602,13.5488155 19.9393398,13.3535534 L12,5.41421356 Z M17.5,3 L18.5,3 C18.7761424,3 19,3.22385763 19,3.5 L19,7 L17,5 L17,3.5 C17,3.22385763 17.2238576,3 17.5,3 Z M13.5,15 L10.5,15 C10.2238576,15 10,15.2238576 10,15.5 L10,20 C10,20.5522847 9.55228475,21 9,21 L6,21 C5.44771525,21 5,20.5522847 5,20 L5,15.4142136 C5,15.1489971 5.10535684,14.8946432 5.29289322,14.7071068 L11.6464466,8.35355339 C11.8417088,8.15829124 12.1582912,8.15829124 12.3535534,8.35355339 L18.7071068,14.7071068 C18.8946432,14.8946432 19,15.1489971 19,15.4142136 L19,20 C19,20.5522847 18.5522847,21 18,21 L15,21 C14.4477153,21 14,20.5522847 14,20 L14,15.5 C14,15.2238576 13.7761424,15 13.5,15 Z"
          fill="inherit"
          id={`path-1-home${unique}`}
        />
      </g>
    </g>
  </svg>
)

Home.propTypes = propTypes
export default Home
