import React, { useMemo } from "react";
import { withTheme } from "styled-components";
import { map, pathOr } from "ramda";
import { object } from "prop-types";

import { themePropType } from "lib/prop-types";
import Background from "components/background/modules/background";
import Primitives from "primitives";

const propTypes = {
  refContainer: object,
  theme: themePropType,
};

const GridContainer = ({ theme, refContainer, ...props }) => {
  const computedProps = useMemo(() => {
    const gutters = pathOr([], ["grid", "gutters"], theme);
    return {
      ...props,
      mx: map((elem) => `-${elem}`, gutters),
      width: "auto",
    };
  }, [props, theme]);
  return (
    <Primitives.Box ref={refContainer} width={1}>
      <Background flexWrap="wrap" {...computedProps} />
    </Primitives.Box>
  );
};

GridContainer.propTypes = propTypes;
GridContainer.displayName = "Modules.GridContainer";

export default withTheme(GridContainer);
