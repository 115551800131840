import {createSelector} from "reselect"
import {identity, is, pathOr} from "ramda"

export const createStateSelector = (stateKey) => (
  key,
  defaultValue = undefined,
  func = identity,
) =>
  createSelector(
    pathOr(defaultValue, [stateKey, ...(is(Array, key) ? key : [key])]),
    func,
  )
