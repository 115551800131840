import {createSelector} from "reselect"
import {concat, equals, join, map, pipe, prop, propOr, props} from "ramda"

import {fieldOrNull, pathOrNull, propOrNull} from "lib/utils/helpers"
import {createStateSelector} from "lib/redux/utils"
import {GET_BASE_URL, GET_PREVIOUS_ROUTE} from "../globals/globals-selectors"

const makeSelector = createStateSelector("nodeReducer")

export const GET_PAGE_BREADCRUMB = makeSelector("breadcrumb", {})
export const GET_PAGE_METADATA = makeSelector("metaData", {})
export const GET_PAGE_UPSTREAMHEADERS = makeSelector("upstreamHeaders", {})
export const GET_ACTIVE_NODE_PATH = makeSelector("activeNodeId", "")
export const GET_IS_HOME_NODE = makeSelector("activeNodeId", "")
export const GET_COMPONENTS = makeSelector("components", {})
export const GET_NODES = makeSelector("nodes", {})
export const GET_ACTIVE_ROUTE = makeSelector("activeRoute", "")
export const GET_ACTIVE_NODE = createSelector(
  [GET_ACTIVE_NODE_PATH, GET_NODES],
  propOrNull,
)
export const GET_PREVIOUS_NODE = createSelector(
  [GET_PREVIOUS_ROUTE, GET_NODES],
  propOrNull,
)
export const GET_FULL_URL = createSelector(
  GET_BASE_URL,
  GET_ACTIVE_NODE_PATH,
  (root, activPath) => join("/", [root, activPath]),
)
export const GET_NODE_COMPONENTS = createSelector(
  [GET_ACTIVE_NODE],
  propOr([], "components"),
)
export const GET_CONTENT_POD = createSelector(
  [GET_ACTIVE_NODE],
  propOr({}, "pdpContentPod"),
)
export const GET_ACTIVE_COMPONENTS = createSelector(
  [GET_NODE_COMPONENTS, GET_COMPONENTS],
  props,
)
export const getFirstComponent = createSelector(
  [GET_ACTIVE_COMPONENTS],
  (components) => components[0],
)
export const GET_HEADER = createSelector(GET_ACTIVE_NODE, propOr([], "header"))
export const GET_HEADER_COMPONENTS = createSelector(
  [GET_HEADER, GET_COMPONENTS],
  props,
)
export const GET_FIRST_COVERBAND_BG_COLOR = createSelector(
  [GET_HEADER_COMPONENTS],
  (headerComponent) =>
    equals(pathOrNull([0, "bundle"])(headerComponent), "cover_band")
      ? fieldOrNull("bgColor", headerComponent[0])
      : null,
)
export const GET_ACTIVE_NODE_STATUS = createSelector(
  [GET_ACTIVE_NODE],
  (node) => {
    return parseInt(propOr(200, "statusCode", node), 10)
  },
)
export const GET_COVER_ITEMS = createSelector(
  [GET_ACTIVE_NODE],
  propOr([], "coverItems"),
)
export const GET_COVER_ITEMS_COMPONENTS = createSelector(
  [GET_COVER_ITEMS, GET_COMPONENTS],
  props,
)
export const GET_COVER_ITEMS_PATHS = createSelector(
  [GET_COVER_ITEMS_COMPONENTS],
  map(pipe(prop("path"), concat("/"))),
)
export const GET_ADMIN_MENU_DATA = makeSelector("admin", {})

export const makeGetSpecificNodeSelector = (nodepath) =>
  createSelector(GET_NODES, propOrNull(nodepath))

export const makeComponentSelector = ({id}) =>
  createSelector(GET_COMPONENTS, propOr(null, id))

export const makeComponentsSelector = ({ids}) =>
  createSelector(GET_COMPONENTS, (components) =>
    map((id) => propOr(null, id, components), ids),
  )
