/* eslint-disable */

export default {
  entities: {
    menu: {
      regions: {
        en: {
          languageCode: "en",
          title: "United States",
          language: "English",
          path: "https://www.qualcomm.com"
        },
        zhCn: {
          languageCode: "zh-cn",
          title: "China",
          language: "简体中文",
          path: "http://www.qualcomm.cn"
        }
      },
      socialFooter: {
        name: "menu-social-footer",
        items: [
          "https://www.facebook.com/qualcomm-7913",
          "http://www.instagram.com/qualcomm/-7917",
          "http://www.linkedin.com/company/2017-7914",
          "https://twitter.com/qualcomm-7912",
          "https://www.youtube.com/qualcomm-7915"
        ]
      },
      mainMenu: {
        name: "main-menu",
        items: [
          "/products-8843",
          "/solutions-8872",
          "/support-8913",
          "/company-8928"
        ]
      },
      legalFooter: {
        name: "menu-legal",
        items: [
          "/site/terms-of-use-3551",
          "/site/privacy-3547",
          "/site/cookies-3548"
        ]
      },
      homeQuickLinks: {
        name: "menu-home-quick-links",
        items: [
          "/company-4414",
          "/info/investor-relations-4493",
          "/contact-4497",
          "/company/careers-4411",
          "/invention-4494",
          "/news-4412",
          "/products-4499",
          "/company/about/leadership-4498",
          "/company/sustainability-4495",
          "/company/facilities/offices-4413",
          "/invention/licensing-4496"
        ]
      },
      globalFooter: {
        name: "menu-global-footer",
        items: ["nolink-3316"]
      },
      footer: {
        name: "menu-footer",
        items: [
          "/company/about-2815",
          "/company/careers-4451",
          "/company/facilities/offices-2818",
          "/contact-2816",
          "/support-8664",
          "https://assets.qualcomm.com/subscription-center-sign-up.html -10466"
        ]
      }
    },
    menuItems: {
      "/products/platforms-9177": {
        mlid: 9177,
        plid: 9918,
        title: "Mobile Computing",
        depth: 3,
        path: "/products/platforms",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/products/platforms-9177",
        linkPath: "/products/platforms"
      },
      "/products/platforms-10678": {
        mlid: 10678,
        plid: 9918,
        title: "Consumer Electronics",
        depth: 3,
        path: "/products/platforms",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/products/platforms-10678",
        linkPath: "/products/platforms"
      },
      "/products/platforms-10715": {
        mlid: 10715,
        plid: 9918,
        title: "Automotive",
        depth: 3,
        path: "/products/platforms",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/products/platforms-10715",
        linkPath: "/products/platforms"
      },
      "/products/platforms-10716": {
        mlid: 10716,
        plid: 9918,
        title: "IoT",
        depth: 3,
        path: "/products/platforms",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/products/platforms-10716",
        linkPath: "/products/platforms"
      },
      "/products/platforms-9920": {
        mlid: 9920,
        plid: 9918,
        title: "Voice and Music",
        depth: 3,
        path: "/products/platforms",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/products/platforms-9920",
        linkPath: "/products/platforms"
      },
      "/products/platforms-9918": {
        mlid: 9918,
        plid: 8843,
        title: "Platforms",
        depth: 2,
        path: "/products/platforms",
        hasChildren: true,
        expanded: false,
        separator: false,
        children: [
          "/products/platforms-9177",
          "/products/platforms-10678",
          "/products/platforms-10715",
          "/products/platforms-10716",
          "/products/platforms-9920"
        ],
        id: "/products/platforms-9918",
        linkPath: "/products/platforms"
      },
      "/products/mobile-processors-10718": {
        mlid: 10718,
        plid: 10717,
        title: "Mobile Processors",
        depth: 3,
        path: "/products/mobile-processors",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/products/mobile-processors-10718",
        linkPath: "/products/mobile-processors"
      },
      "/products/embedded-platforms-10719": {
        mlid: 10719,
        plid: 10717,
        title: "Application Processors",
        depth: 3,
        path: "/products/embedded-platforms",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/products/embedded-platforms-10719",
        linkPath: "/products/embedded-platforms"
      },
      "/products/server-processors-10720": {
        mlid: 10720,
        plid: 10717,
        title: "Server Processors",
        depth: 3,
        path: "/products/server-processors",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/products/server-processors-10720",
        linkPath: "/products/server-processors"
      },
      "/products/mobile-processors-10717": {
        mlid: 10717,
        plid: 8843,
        title: "Processors",
        depth: 2,
        path: "/products/mobile-processors",
        hasChildren: true,
        expanded: false,
        separator: false,
        children: [
          "/products/mobile-processors-10718",
          "/products/embedded-platforms-10719",
          "/products/server-processors-10720"
        ],
        id: "/products/mobile-processors-10717",
        linkPath: "/products/mobile-processors"
      },
      "/products/modems-8845": {
        mlid: 8845,
        plid: 8844,
        title: "Cellular Modems",
        depth: 3,
        path: "/products/modems",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/products/modems-8845",
        linkPath: "/products/modems"
      },
      "/products/bluetooth-8846": {
        mlid: 8846,
        plid: 8844,
        title: "Bluetooth",
        depth: 3,
        path: "/products/bluetooth",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/products/bluetooth-8846",
        linkPath: "/products/bluetooth"
      },
      "/products/wi-fi-10267": {
        mlid: 10267,
        plid: 8844,
        title: "Wi-Fi",
        depth: 3,
        path: "/products/wi-fi",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/products/wi-fi-10267",
        linkPath: "/products/wi-fi"
      },
      "/products/rf-8850": {
        mlid: 8850,
        plid: 8844,
        title: "RF Components",
        depth: 3,
        path: "/products/rf",
        hasChildren: true,
        expanded: false,
        separator: false,
        children: [],
        id: "/products/rf-8850",
        linkPath: "/products/rf"
      },
      "nolink-8844": {
        mlid: 8844,
        plid: 8843,
        title: "Connectivity",
        depth: 2,
        path: null,
        hasChildren: true,
        expanded: false,
        separator: false,
        children: [
          "/products/modems-8845",
          "/products/bluetooth-8846",
          "/products/wi-fi-10267",
          "/products/rf-8850"
        ],
        id: "nolink-8844",
        linkPath: "nolink"
      },
      "/products-8843": {
        mlid: 8843,
        plid: 0,
        title: "Products",
        depth: 1,
        path: "/products",
        hasChildren: true,
        expanded: false,
        separator: false,
        children: [
          "/products/platforms-9918",
          "/products/mobile-processors-10717",
          "nolink-8844"
        ],
        id: "/products-8843",
        linkPath: "/products"
      },
      "/solutions/mobile-computing/laptops-8886": {
        mlid: 8886,
        plid: 9946,
        title: "Mobile PCs",
        depth: 3,
        path: "/solutions/mobile-computing/laptops",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/solutions/mobile-computing/laptops-8886",
        linkPath: "/solutions/mobile-computing/laptops"
      },
      "/solutions/mobile-computing/extended-reality-8887": {
        mlid: 8887,
        plid: 9946,
        title: "Mobile XR",
        depth: 3,
        path: "/solutions/mobile-computing/extended-reality",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/solutions/mobile-computing/extended-reality-8887",
        linkPath: "/solutions/mobile-computing/extended-reality"
      },
      "/solutions/mobile-computing/smartphones-8888": {
        mlid: 8888,
        plid: 9946,
        title: "Smartphones",
        depth: 3,
        path: "/solutions/mobile-computing/smartphones",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/solutions/mobile-computing/smartphones-8888",
        linkPath: "/solutions/mobile-computing/smartphones"
      },
      "/solutions/mobile-computing/tablets-8889": {
        mlid: 8889,
        plid: 9946,
        title: "Tablets",
        depth: 3,
        path: "/solutions/mobile-computing/tablets",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/solutions/mobile-computing/tablets-8889",
        linkPath: "/solutions/mobile-computing/tablets"
      },
      "/solutions/mobile-computing-9946": {
        mlid: 9946,
        plid: 8872,
        title: "Mobile Computing",
        depth: 2,
        path: "/solutions/mobile-computing",
        hasChildren: true,
        expanded: false,
        separator: false,
        children: [
          "/solutions/mobile-computing/laptops-8886",
          "/solutions/mobile-computing/extended-reality-8887",
          "/solutions/mobile-computing/smartphones-8888",
          "/solutions/mobile-computing/tablets-8889"
        ],
        id: "/solutions/mobile-computing-9946",
        linkPath: "/solutions/mobile-computing"
      },
      "/solutions/networking/carrier-networks-8892": {
        mlid: 8892,
        plid: 8891,
        title: "Carrier Networks",
        depth: 3,
        path: "/solutions/networking/carrier-networks",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/solutions/networking/carrier-networks-8892",
        linkPath: "/solutions/networking/carrier-networks"
      },
      "/solutions/networking/enterprise-networks-8893": {
        mlid: 8893,
        plid: 8891,
        title: "Enterprise Networks",
        depth: 3,
        path: "/solutions/networking/enterprise-networks",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/solutions/networking/enterprise-networks-8893",
        linkPath: "/solutions/networking/enterprise-networks"
      },
      "/solutions/networking/home-networks-8894": {
        mlid: 8894,
        plid: 8891,
        title: "Home Networks",
        depth: 3,
        path: "/solutions/networking/home-networks",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/solutions/networking/home-networks-8894",
        linkPath: "/solutions/networking/home-networks"
      },
      "/solutions/networking/carrier-networks/small-cells-8895": {
        mlid: 8895,
        plid: 8891,
        title: "Small Cells",
        depth: 3,
        path: "/solutions/networking/carrier-networks/small-cells",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/solutions/networking/carrier-networks/small-cells-8895",
        linkPath: "/solutions/networking/carrier-networks/small-cells"
      },
      "/solutions/networking-8891": {
        mlid: 8891,
        plid: 8872,
        title: "Networking",
        depth: 2,
        path: "/solutions/networking",
        hasChildren: true,
        expanded: false,
        separator: false,
        children: [
          "/solutions/networking/carrier-networks-8892",
          "/solutions/networking/enterprise-networks-8893",
          "/solutions/networking/home-networks-8894",
          "/solutions/networking/carrier-networks/small-cells-8895"
        ],
        id: "/solutions/networking-8891",
        linkPath: "/solutions/networking"
      },
      "/solutions/automotive-8873": {
        mlid: 8873,
        plid: 8903,
        title: "Automotive",
        depth: 3,
        path: "/solutions/automotive",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/solutions/automotive-8873",
        linkPath: "/solutions/automotive"
      },
      "/solutions/internet-of-things/consumer-electronics-8904": {
        mlid: 8904,
        plid: 8903,
        title: "Consumer Electronics",
        depth: 3,
        path: "/solutions/internet-of-things/consumer-electronics",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/solutions/internet-of-things/consumer-electronics-8904",
        linkPath: "/solutions/internet-of-things/consumer-electronics"
      },
      "/solutions/internet-of-things/smart-home-8905": {
        mlid: 8905,
        plid: 8903,
        title: "Smart Homes",
        depth: 3,
        path: "/solutions/internet-of-things/smart-home",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/solutions/internet-of-things/smart-home-8905",
        linkPath: "/solutions/internet-of-things/smart-home"
      },
      "/solutions/internet-of-things/smart-cities-8906": {
        mlid: 8906,
        plid: 8903,
        title: "Smart Cities",
        depth: 3,
        path: "/solutions/internet-of-things/smart-cities",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/solutions/internet-of-things/smart-cities-8906",
        linkPath: "/solutions/internet-of-things/smart-cities"
      },
      "/solutions/internet-of-things/voice-and-music-8908": {
        mlid: 8908,
        plid: 8903,
        title: "Voice and Music",
        depth: 3,
        path: "/solutions/internet-of-things/voice-and-music",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/solutions/internet-of-things/voice-and-music-8908",
        linkPath: "/solutions/internet-of-things/voice-and-music"
      },
      "/solutions/internet-of-things/consumer-electronics/wearables-8907": {
        mlid: 8907,
        plid: 8903,
        title: "Wearables",
        depth: 3,
        path: "/solutions/internet-of-things/consumer-electronics/wearables",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/solutions/internet-of-things/consumer-electronics/wearables-8907",
        linkPath: "/solutions/internet-of-things/consumer-electronics/wearables"
      },
      "nolink-8903": {
        mlid: 8903,
        plid: 8872,
        title: "More Solutions",
        depth: 2,
        path: null,
        hasChildren: true,
        expanded: false,
        separator: false,
        children: [
          "/solutions/automotive-8873",
          "/solutions/internet-of-things/consumer-electronics-8904",
          "/solutions/internet-of-things/smart-home-8905",
          "/solutions/internet-of-things/smart-cities-8906",
          "/solutions/internet-of-things/voice-and-music-8908",
          "/solutions/internet-of-things/consumer-electronics/wearables-8907"
        ],
        id: "nolink-8903",
        linkPath: "nolink"
      },
      "/solutions-8872": {
        mlid: 8872,
        plid: 0,
        title: "Solutions",
        depth: 1,
        path: "/solutions",
        hasChildren: true,
        expanded: false,
        separator: false,
        children: [
          "/solutions/mobile-computing-9946",
          "/solutions/networking-8891",
          "nolink-8903"
        ],
        id: "/solutions-8872",
        linkPath: "/solutions"
      },
      "/support-8913": {
        mlid: 8913,
        plid: 0,
        title: "Support",
        depth: 1,
        path: "/support",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/support-8913",
        linkPath: "/support"
      },
      "/news/media-center-8941": {
        mlid: 8941,
        plid: 8939,
        title: "Media Center",
        depth: 3,
        path: "/news/media-center",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/news/media-center-8941",
        linkPath: "/news/media-center"
      },
      "/news/releases-8943": {
        mlid: 8943,
        plid: 8939,
        title: "Press Announcements",
        depth: 3,
        path: "/news/releases",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/news/releases-8943",
        linkPath: "/news/releases"
      },
      "/news/onq-8942": {
        mlid: 8942,
        plid: 8939,
        title: "OnQ Blog",
        depth: 3,
        path: "/news/onq",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/news/onq-8942",
        linkPath: "/news/onq"
      },
      "/news/topics/qualcomm-podcast-10721": {
        mlid: 10721,
        plid: 8939,
        title: "Qualcomm Podcast",
        depth: 3,
        path: "/news/topics/qualcomm-podcast",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/news/topics/qualcomm-podcast-10721",
        linkPath: "/news/topics/qualcomm-podcast"
      },
      "/news-8939": {
        mlid: 8939,
        plid: 8928,
        title: "News and Media",
        depth: 2,
        path: "/news",
        hasChildren: true,
        expanded: false,
        separator: false,
        children: [
          "/news/media-center-8941",
          "/news/releases-8943",
          "/news/onq-8942",
          "/news/topics/qualcomm-podcast-10721"
        ],
        id: "/news-8939",
        linkPath: "/news"
      },
      "/invention/5g-9175": {
        mlid: 9175,
        plid: 9174,
        title: "5G and Wireless Technology",
        depth: 3,
        path: "/invention/5g",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/invention/5g-9175",
        linkPath: "/invention/5g"
      },
      "/invention/artificial-intelligence-8935": {
        mlid: 8935,
        plid: 9174,
        title: "Artificial Intelligence",
        depth: 3,
        path: "/invention/artificial-intelligence",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/invention/artificial-intelligence-8935",
        linkPath: "/invention/artificial-intelligence"
      },
      "/invention-8936": {
        mlid: 8936,
        plid: 9174,
        title: "Extended Reality (XR)",
        depth: 3,
        path: "/invention",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/invention-8936",
        linkPath: "/invention"
      },
      "/invention/university-relations-9176": {
        mlid: 9176,
        plid: 9174,
        title: "University Relations",
        depth: 3,
        path: "/invention/university-relations",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/invention/university-relations-9176",
        linkPath: "/invention/university-relations"
      },
      "/invention-9174": {
        mlid: 9174,
        plid: 8928,
        title: "Research and Invention",
        depth: 2,
        path: "/invention",
        hasChildren: true,
        expanded: false,
        separator: false,
        children: [
          "/invention/5g-9175",
          "/invention/artificial-intelligence-8935",
          "/invention-8936",
          "/invention/university-relations-9176"
        ],
        id: "/invention-9174",
        linkPath: "/invention"
      },
      "/company/about-8930": {
        mlid: 8930,
        plid: 8929,
        title: "About us",
        depth: 3,
        path: "/company/about",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/company/about-8930",
        linkPath: "/company/about"
      },
      "/company/careers-8931": {
        mlid: 8931,
        plid: 8929,
        title: "Careers",
        depth: 3,
        path: "/company/careers",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/company/careers-8931",
        linkPath: "/company/careers"
      },
      "/info/investor-relations-8932": {
        mlid: 8932,
        plid: 8929,
        title: "Investor Relations",
        depth: 3,
        path: "/info/investor-relations",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/info/investor-relations-8932",
        linkPath: "/info/investor-relations"
      },
      "/invention/licensing-8933": {
        mlid: 8933,
        plid: 8929,
        title: "Licensing",
        depth: 3,
        path: "/invention/licensing",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/invention/licensing-8933",
        linkPath: "/invention/licensing"
      },
      "/company/locations-10712": {
        mlid: 10712,
        plid: 8929,
        title: "Locations",
        depth: 3,
        path: "/company/locations",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/company/locations-10712",
        linkPath: "/company/locations"
      },
      "/company/sustainability-10713": {
        mlid: 10713,
        plid: 8929,
        title: "Sustainability",
        depth: 3,
        path: "/company/sustainability",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/company/sustainability-10713",
        linkPath: "/company/sustainability"
      },
      "/company/wireless-reach-10714": {
        mlid: 10714,
        plid: 8929,
        title: "Wireless Reach",
        depth: 3,
        path: "/company/wireless-reach",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/company/wireless-reach-10714",
        linkPath: "/company/wireless-reach"
      },
      "/invention/testimonials-9693": {
        mlid: 9693,
        plid: 8929,
        title: "Ecosystem Testimonials",
        depth: 3,
        path: "/invention/testimonials",
        hasChildren: false,
        expanded: false,
        separator: false,
        icon: "",
        children: [],
        id: "/invention/testimonials-9693",
        linkPath: "/invention/testimonials"
      },
      "/company-8929": {
        mlid: 8929,
        plid: 8928,
        title: "Our Company",
        depth: 2,
        path: "/company",
        hasChildren: true,
        expanded: false,
        separator: false,
        children: [
          "/company/about-8930",
          "/company/careers-8931",
          "/info/investor-relations-8932",
          "/invention/licensing-8933",
          "/company/locations-10712",
          "/company/sustainability-10713",
          "/company/wireless-reach-10714",
          "/invention/testimonials-9693"
        ],
        id: "/company-8929",
        linkPath: "/company"
      },
      "/company-8928": {
        mlid: 8928,
        plid: 0,
        title: "Company",
        depth: 1,
        path: "/company",
        hasChildren: true,
        expanded: false,
        separator: false,
        children: ["/news-8939", "/invention-9174", "/company-8929"],
        id: "/company-8928",
        linkPath: "/company"
      },
      "/company/about-2815": {
        mlid: 2815,
        plid: 0,
        title: "About Qualcomm",
        depth: 1,
        path: "/company/about",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/company/about-2815",
        linkPath: "/company/about"
      },
      "/company/careers-4451": {
        mlid: 4451,
        plid: 0,
        title: "Careers",
        depth: 1,
        path: "/company/careers",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/company/careers-4451",
        linkPath: "/company/careers"
      },
      "/company/facilities/offices-2818": {
        mlid: 2818,
        plid: 0,
        title: "Offices",
        depth: 1,
        path: "/company/facilities/offices",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/company/facilities/offices-2818",
        linkPath: "/company/facilities/offices"
      },
      "/contact-2816": {
        mlid: 2816,
        plid: 0,
        title: "Contact Us",
        depth: 1,
        path: "/contact",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/contact-2816",
        linkPath: "/contact"
      },
      "/support-8664": {
        mlid: 8664,
        plid: 0,
        title: "Support",
        depth: 1,
        path: "/support",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/support-8664",
        linkPath: "/support"
      },
      "https://assets.qualcomm.com/subscription-center-sign-up.html -10466": {
        mlid: 10466,
        plid: 0,
        title: "Subscription Center",
        depth: 1,
        path: "https://assets.qualcomm.com/subscription-center-sign-up.html ",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id:
          "https://assets.qualcomm.com/subscription-center-sign-up.html -10466",
        linkPath:
          "https://assets.qualcomm.com/subscription-center-sign-up.html "
      },
      "/site/terms-of-use-3551": {
        mlid: 3551,
        plid: 0,
        title: "Terms of Use",
        depth: 1,
        path: "/site/terms-of-use",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/site/terms-of-use-3551",
        linkPath: "/site/terms-of-use"
      },
      "/site/privacy-3547": {
        mlid: 3547,
        plid: 0,
        title: "Privacy",
        depth: 1,
        path: "/site/privacy",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/site/privacy-3547",
        linkPath: "/site/privacy"
      },
      "/site/cookies-3548": {
        mlid: 3548,
        plid: 0,
        title: "Cookies",
        depth: 1,
        path: "/site/cookies",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/site/cookies-3548",
        linkPath: "/site/cookies"
      },
      "/solutions/automotive-820": {
        mlid: 820,
        plid: 807,
        title: "Automotive",
        depth: 3,
        path: "/solutions/automotive",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/solutions/automotive-820",
        linkPath: "/solutions/automotive"
      },
      "/solutions/health-care-6547": {
        mlid: 6547,
        plid: 807,
        title: "Health Care",
        depth: 3,
        path: "/solutions/health-care",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/solutions/health-care-6547",
        linkPath: "/solutions/health-care"
      },
      "/solutions/internet-of-things-2290": {
        mlid: 2290,
        plid: 807,
        title: "Internet of Things",
        depth: 3,
        path: "/solutions/internet-of-things",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/solutions/internet-of-things-2290",
        linkPath: "/solutions/internet-of-things"
      },
      "/solutions/mobile-computing-6546": {
        mlid: 6546,
        plid: 807,
        title: "Mobile Computing",
        depth: 3,
        path: "/solutions/mobile-computing",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/solutions/mobile-computing-6546",
        linkPath: "/solutions/mobile-computing"
      },
      "/solutions/networking-2803": {
        mlid: 2803,
        plid: 807,
        title: "Networking",
        depth: 3,
        path: "/solutions/networking",
        hasChildren: true,
        expanded: true,
        separator: false,
        children: [],
        id: "/solutions/networking-2803",
        linkPath: "/solutions/networking"
      },
      "/solutions-807": {
        mlid: 807,
        plid: 3316,
        title: "Solutions",
        depth: 2,
        path: "/solutions",
        hasChildren: true,
        expanded: true,
        separator: false,
        children: [
          "/solutions/automotive-820",
          "/solutions/health-care-6547",
          "/solutions/internet-of-things-2290",
          "/solutions/mobile-computing-6546",
          "/solutions/networking-2803"
        ],
        id: "/solutions-807",
        linkPath: "/solutions"
      },
      "/products/mobile-processors-8274": {
        mlid: 8274,
        plid: 8273,
        title: "Mobile Processors",
        depth: 3,
        path: "/products/mobile-processors",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/products/mobile-processors-8274",
        linkPath: "/products/mobile-processors"
      },
      "/products/bluetooth-8275": {
        mlid: 8275,
        plid: 8273,
        title: "Bluetooth Products",
        depth: 3,
        path: "/products/bluetooth",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/products/bluetooth-8275",
        linkPath: "/products/bluetooth"
      },
      "/products/modems-8276": {
        mlid: 8276,
        plid: 8273,
        title: "Cellular Modems",
        depth: 3,
        path: "/products/modems",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/products/modems-8276",
        linkPath: "/products/modems"
      },
      "/products/wi-fi-8277": {
        mlid: 8277,
        plid: 8273,
        title: "Wi-Fi Products",
        depth: 3,
        path: "/products/wi-fi",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/products/wi-fi-8277",
        linkPath: "/products/wi-fi"
      },
      "/products/catalog-8278": {
        mlid: 8278,
        plid: 8273,
        title: "Product Catalog",
        depth: 3,
        path: "/products/catalog",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/products/catalog-8278",
        linkPath: "/products/catalog"
      },
      "/products-8273": {
        mlid: 8273,
        plid: 3316,
        title: "Products",
        depth: 2,
        path: "/products",
        hasChildren: true,
        expanded: false,
        separator: false,
        children: [
          "/products/mobile-processors-8274",
          "/products/bluetooth-8275",
          "/products/modems-8276",
          "/products/wi-fi-8277",
          "/products/catalog-8278"
        ],
        id: "/products-8273",
        linkPath: "/products"
      },
      "http://qronicles.qualcomm.com/-8270": {
        mlid: 8270,
        plid: 2130,
        title: "Qronicles of Invention",
        depth: 3,
        path: "http://qronicles.qualcomm.com/",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "http://qronicles.qualcomm.com/-8270",
        linkPath: "http://qronicles.qualcomm.com/"
      },
      "/invention/stories-2139": {
        mlid: 2139,
        plid: 2130,
        title: "Stories",
        depth: 3,
        path: "/invention/stories",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/invention/stories-2139",
        linkPath: "/invention/stories"
      },
      "/invention/ventures-2132": {
        mlid: 2132,
        plid: 2130,
        title: "Ventures",
        depth: 3,
        path: "/invention/ventures",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/invention/ventures-2132",
        linkPath: "/invention/ventures"
      },
      "/invention/licensing-819": {
        mlid: 819,
        plid: 2130,
        title: "Licensing",
        depth: 3,
        path: "/invention/licensing",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/invention/licensing-819",
        linkPath: "/invention/licensing"
      },
      "/invention/testimonials-9692": {
        mlid: 9692,
        plid: 2130,
        title: "Ecosystem Testimonials",
        depth: 3,
        path: "/invention/testimonials",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/invention/testimonials-9692",
        linkPath: "/invention/testimonials"
      },
      "/invention/technologies/wireless-8271": {
        mlid: 8271,
        plid: 2130,
        title: "Wireless Technologies",
        depth: 3,
        path: "/invention/technologies/wireless",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/invention/technologies/wireless-8271",
        linkPath: "/invention/technologies/wireless"
      },
      "/invention/5g-8272": {
        mlid: 8272,
        plid: 2130,
        title: "5G",
        depth: 3,
        path: "/invention/5g",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/invention/5g-8272",
        linkPath: "/invention/5g"
      },
      "/invention-2130": {
        mlid: 2130,
        plid: 3316,
        title: "Invention",
        depth: 2,
        path: "/invention",
        hasChildren: true,
        expanded: true,
        separator: false,
        children: [
          "http://qronicles.qualcomm.com/-8270",
          "/invention/stories-2139",
          "/invention/ventures-2132",
          "/invention/licensing-819",
          "/invention/testimonials-9692",
          "/invention/technologies/wireless-8271",
          "/invention/5g-8272"
        ],
        id: "/invention-2130",
        linkPath: "/invention"
      },
      "/news/onq-839": {
        mlid: 839,
        plid: 809,
        title: "OnQ",
        depth: 3,
        path: "/news/onq",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/news/onq-839",
        linkPath: "/news/onq"
      },
      "/news/releases-832": {
        mlid: 832,
        plid: 809,
        title: "Press Releases",
        depth: 3,
        path: "/news/releases",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/news/releases-832",
        linkPath: "/news/releases"
      },
      "/news-809": {
        mlid: 809,
        plid: 3316,
        title: "News",
        depth: 2,
        path: "/news",
        hasChildren: true,
        expanded: true,
        separator: false,
        children: ["/news/onq-839", "/news/releases-832"],
        id: "/news-809",
        linkPath: "/news"
      },
      "/company/about-806": {
        mlid: 806,
        plid: 798,
        title: "About",
        depth: 3,
        path: "/company/about",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/company/about-806",
        linkPath: "/company/about"
      },
      "/company/careers-812": {
        mlid: 812,
        plid: 798,
        title: "Careers",
        depth: 3,
        path: "/company/careers",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/company/careers-812",
        linkPath: "/company/careers"
      },
      "/company/events/ces-10011": {
        mlid: 10011,
        plid: 10010,
        title: "CES",
        depth: 4,
        path: "/company/events/ces",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/company/events/ces-10011",
        linkPath: "/company/events/ces"
      },
      "/company/events-10010": {
        mlid: 10010,
        plid: 798,
        title: "Events",
        depth: 3,
        path: "/company/events",
        hasChildren: true,
        expanded: false,
        separator: false,
        children: ["/company/events/ces-10011"],
        id: "/company/events-10010",
        linkPath: "/company/events"
      },
      "/info/investor-relations-817": {
        mlid: 817,
        plid: 798,
        title: "Investor Information",
        depth: 3,
        path: "/info/investor-relations",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/info/investor-relations-817",
        linkPath: "/info/investor-relations"
      },
      "/company/facilities/offices-814": {
        mlid: 814,
        plid: 798,
        title: "Offices & Facilities",
        depth: 3,
        path: "/company/facilities/offices",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/company/facilities/offices-814",
        linkPath: "/company/facilities/offices"
      },
      "/company/sustainability-813": {
        mlid: 813,
        plid: 798,
        title: "Sustainability",
        depth: 3,
        path: "/company/sustainability",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/company/sustainability-813",
        linkPath: "/company/sustainability"
      },
      "/company/wireless-reach-815": {
        mlid: 815,
        plid: 798,
        title: "Wireless Reach",
        depth: 3,
        path: "/company/wireless-reach",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/company/wireless-reach-815",
        linkPath: "/company/wireless-reach"
      },
      "/company-798": {
        mlid: 798,
        plid: 3316,
        title: "Company",
        depth: 2,
        path: "/company",
        hasChildren: true,
        expanded: true,
        separator: false,
        children: [
          "/company/about-806",
          "/company/careers-812",
          "/company/events-10010",
          "/info/investor-relations-817",
          "/company/facilities/offices-814",
          "/company/sustainability-813",
          "/company/wireless-reach-815"
        ],
        id: "/company-798",
        linkPath: "/company"
      },
      "/contact-841": {
        mlid: 841,
        plid: 810,
        title: "Contact Us",
        depth: 3,
        path: "/contact",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/contact-841",
        linkPath: "/contact"
      },
      "/info/developers-800": {
        mlid: 800,
        plid: 810,
        title: "Developers",
        depth: 3,
        path: "/info/developers",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/info/developers-800",
        linkPath: "/info/developers"
      },
      "/info/analyst-relations-805": {
        mlid: 805,
        plid: 810,
        title: "Industry Analysts",
        depth: 3,
        path: "/info/analyst-relations",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/info/analyst-relations-805",
        linkPath: "/info/analyst-relations"
      },
      "/info/investor-relations-3343": {
        mlid: 3343,
        plid: 810,
        title: "Investors",
        depth: 3,
        path: "/info/investor-relations",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/info/investor-relations-3343",
        linkPath: "/info/investor-relations"
      },
      "/info/manufacturers-3342": {
        mlid: 3342,
        plid: 810,
        title: "Manufacturers",
        depth: 3,
        path: "/info/manufacturers",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/info/manufacturers-3342",
        linkPath: "/info/manufacturers"
      },
      "/info/public-policy-802": {
        mlid: 802,
        plid: 810,
        title: "Policy Makers",
        depth: 3,
        path: "/info/public-policy",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/info/public-policy-802",
        linkPath: "/info/public-policy"
      },
      "/support-8665": {
        mlid: 8665,
        plid: 810,
        title: "Support",
        depth: 3,
        path: "/support",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/support-8665",
        linkPath: "/support"
      },
      "/-810": {
        mlid: 810,
        plid: 3316,
        title: "Information For",
        depth: 2,
        path: "/",
        hasChildren: true,
        expanded: true,
        separator: false,
        children: [
          "/contact-841",
          "/info/developers-800",
          "/info/analyst-relations-805",
          "/info/investor-relations-3343",
          "/info/manufacturers-3342",
          "/info/public-policy-802",
          "/support-8665"
        ],
        id: "/-810",
        linkPath: "/"
      },
      "nolink-3316": {
        mlid: 3316,
        plid: 0,
        title: "Site Map",
        depth: 1,
        path: null,
        hasChildren: true,
        expanded: true,
        separator: false,
        children: [
          "/solutions-807",
          "/products-8273",
          "/invention-2130",
          "/news-809",
          "/company-798",
          "/-810"
        ],
        id: "nolink-3316",
        linkPath: "nolink"
      },
      "https://www.facebook.com/qualcomm-7913": {
        mlid: 7913,
        plid: 0,
        title: "Facebook",
        depth: 1,
        path: "https://www.facebook.com/qualcomm",
        hasChildren: false,
        expanded: false,
        separator: false,
        icon: "facebook",
        children: [],
        id: "https://www.facebook.com/qualcomm-7913",
        linkPath: "https://www.facebook.com/qualcomm"
      },
      "http://www.instagram.com/qualcomm/-7917": {
        mlid: 7917,
        plid: 0,
        title: "Instagram",
        depth: 1,
        path: "http://www.instagram.com/qualcomm/",
        hasChildren: false,
        expanded: false,
        separator: false,
        icon: "instagram",
        children: [],
        id: "http://www.instagram.com/qualcomm/-7917",
        linkPath: "http://www.instagram.com/qualcomm/"
      },
      "http://www.linkedin.com/company/2017-7914": {
        mlid: 7914,
        plid: 0,
        title: "LinkedIn",
        depth: 1,
        path: "http://www.linkedin.com/company/2017",
        hasChildren: false,
        expanded: false,
        separator: false,
        icon: "linkedin",
        children: [],
        id: "http://www.linkedin.com/company/2017-7914",
        linkPath: "http://www.linkedin.com/company/2017"
      },
      "https://twitter.com/qualcomm-7912": {
        mlid: 7912,
        plid: 0,
        title: "Twitter",
        depth: 1,
        path: "https://twitter.com/qualcomm",
        hasChildren: false,
        expanded: false,
        separator: false,
        icon: "twitter",
        children: [],
        id: "https://twitter.com/qualcomm-7912",
        linkPath: "https://twitter.com/qualcomm"
      },
      "https://www.youtube.com/qualcomm-7915": {
        mlid: 7915,
        plid: 0,
        title: "YouTube",
        depth: 1,
        path: "https://www.youtube.com/qualcomm",
        hasChildren: false,
        expanded: false,
        separator: false,
        icon: "youtube",
        children: [],
        id: "https://www.youtube.com/qualcomm-7915",
        linkPath: "https://www.youtube.com/qualcomm"
      },
      "/company-4414": {
        mlid: 4414,
        plid: 0,
        title: "Information About Qualcomm ",
        depth: 1,
        path: "/company",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/company-4414",
        linkPath: "/company"
      },
      "/info/investor-relations-4493": {
        mlid: 4493,
        plid: 0,
        title: "Investor Relations",
        depth: 1,
        path: "/info/investor-relations",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/info/investor-relations-4493",
        linkPath: "/info/investor-relations"
      },
      "/contact-4497": {
        mlid: 4497,
        plid: 0,
        title: "Contact Information",
        depth: 1,
        path: "/contact",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/contact-4497",
        linkPath: "/contact"
      },
      "/company/careers-4411": {
        mlid: 4411,
        plid: 0,
        title: "Career Opportunities",
        depth: 1,
        path: "/company/careers",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/company/careers-4411",
        linkPath: "/company/careers"
      },
      "/invention-4494": {
        mlid: 4494,
        plid: 0,
        title: "Invention @ Qualcomm",
        depth: 1,
        path: "/invention",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/invention-4494",
        linkPath: "/invention"
      },
      "/news-4412": {
        mlid: 4412,
        plid: 0,
        title: "News & Blogs",
        depth: 1,
        path: "/news",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/news-4412",
        linkPath: "/news"
      },
      "/products-4499": {
        mlid: 4499,
        plid: 0,
        title: "Products and Technologies",
        depth: 1,
        path: "/products",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/products-4499",
        linkPath: "/products"
      },
      "/company/about/leadership-4498": {
        mlid: 4498,
        plid: 0,
        title: "A Qualcomm Executive",
        depth: 1,
        path: "/company/about/leadership",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/company/about/leadership-4498",
        linkPath: "/company/about/leadership"
      },
      "/company/sustainability-4495": {
        mlid: 4495,
        plid: 0,
        title: "Details About Social Responsibility",
        depth: 1,
        path: "/company/sustainability",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/company/sustainability-4495",
        linkPath: "/company/sustainability"
      },
      "/company/facilities/offices-4413": {
        mlid: 4413,
        plid: 0,
        title: "A Qualcomm Office Location",
        depth: 1,
        path: "/company/facilities/offices",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/company/facilities/offices-4413",
        linkPath: "/company/facilities/offices"
      },
      "/invention/licensing-4496": {
        mlid: 4496,
        plid: 0,
        title: "Technology Licensing Information",
        depth: 1,
        path: "/invention/licensing",
        hasChildren: false,
        expanded: false,
        separator: false,
        children: [],
        id: "/invention/licensing-4496",
        linkPath: "/invention/licensing"
      }
    }
  },
  result: {
    footer: "footer",
    globalFooter: "globalFooter",
    homeQuickLinks: "homeQuickLinks",
    legalFooter: "legalFooter",
    mainMenu: "mainMenu",
    socialFooter: "socialFooter"
  }
}
