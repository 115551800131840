import React from "react";
import { bool, node, number, oneOfType, shape, string } from "prop-types";
import Primitives from "primitives";

const propTypes = {
  /** Any additional classes to attach to the heading element */
  className: string,
  /** Semantic heading level of the element, from 1 to 6 */
  headingLevel: oneOfType([string, number]),
  /** Main heading or sub-heading? */
  isSubtitle: bool,
  /** tertiaryNav component data  */
  tertiaryNav: shape({
    /** Object with information needed for next page link */
    next: shape({
      /** next id */
      mlid: string,
      /** link path */
      path: string,
      /** link title */
      title: string,
    }),
    /** Object holding information about parent page for parent link component */
    parent: shape({
      /** parent id */
      mlid: string,
      /** link path */
      path: string,
      /** link title */
      title: string,
    }),
    /** Object with information needed for previous page link */
    previous: shape({
      /** previous id */
      mlid: string,
      /** link path */
      path: string,
      /** link title */
      title: string,
    }),
  }),
  /** Data for thumbnail image */
  thumbnailImg: oneOfType([
    shape({
      /** String url of the image */
      url: string,
    }),
    string,
  ]),
  /** Text to display in the element */
  title: node,
};

const HeaderTag = ({
  className,
  /**
   * Is this isSubtitle prop used anywhere? Can't find it in any other components
   */
  isSubtitle = false,
  headingLevel = 2,
  /**
   * Is tertiaryNav or thumbnailImg actually used? They're in props but not in the component
   */
  tertiaryNav,
  thumbnailImg,
  title,
  ...props
}) => {
  const hLevel = isSubtitle
    ? parseInt(headingLevel, 10) + 1 || 3
    : headingLevel;
  const hTag = `h${hLevel}`;

  /**
   * Using the text primitive here allows styled-system props to be used freely with HeaderTag
   * This also sets up instances of it to remove the old typography system in favor of styled-system props
   * It may be best in the future to phase out this component entirely in favor of text primitives in components where it's currently used, not sure if there's a real advantage of breaking it out like this anymore
   */
  return (
    <Primitives.Text
      className={className}
      data-test-id="header-tag"
      tag={hTag}
      {...props}
    >
      {title}
    </Primitives.Text>
  );
};

HeaderTag.propTypes = propTypes;

export default HeaderTag;
