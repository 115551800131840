import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Linkedin = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/linkedin-linkedin${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`fill-1-linkedin${unique}`}
        xlinkHref={`#path-1-linkedin${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-linkedin${unique}`}>
        <path
          d="M3.32914199,21.00028 L3.32914199,8.86867731 L7.13479202,8.86867731 L7.13479202,21.00028 L3.32914199,21.00028 Z M13.080027,13.993686 L13.080027,21.00028 L9.41430892,21.00028 L9.41430892,11.6585711 C9.41430892,11.6585711 9.35433807,9.34469591 9.35433807,8.93489512 L12.9575865,8.93489512 L13.080027,10.5990861 C13.8121711,9.430904 14.912886,8.54508461 16.379673,8.54508461 C18.9459255,8.54508461 20.7787845,10.490389 20.7787845,13.993686 L20.7787845,21.00028 L17.1118171,21.00028 L17.1118171,14.3834965 C17.1118171,12.4369427 16.2160025,11.6585711 15.1177864,11.6585711 C14.0170715,11.6585711 13.080027,12.4369427 13.080027,13.993686 Z M7.6139341,5.3828718 C7.6139341,6.69723287 6.54695277,7.76296481 5.2325917,7.76296481 C3.91698123,7.76296481 2.8499999,6.69723287 2.8499999,5.3828718 C2.8499999,4.06726133 3.91698123,3.00028 5.2325917,3.00028 C6.54695277,3.00028 7.6139341,4.06726133 7.6139341,5.3828718 Z"
          fill="inherit"
          id={`path-1-linkedin${unique}`}
        />
      </g>
    </g>
  </svg>
)

Linkedin.propTypes = propTypes
export default Linkedin
