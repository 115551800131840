import styled from "styled-components";

import { shouldForwardProp } from "../shared";
import textProps from "./shared";

const H5 = styled.h5.withConfig({
  shouldForwardProp,
})`
  ${textProps};
`;

export default H5;
