import React from "react"
import { Provider } from "react-redux"
import { createBrowserHistory } from "history"
import { Router } from "react-router-dom"
import { ApolloProvider } from "@apollo/client"

import ThemeContainer from "components/theme/ThemeContainer"
import configureStore from "store"
import createClient from "lib/apolloClient/client"
import GlobalFooter from "components/dx/organisms/GlobalFooter/GlobalFooterMapping"
import CommonConfiguration from "components/CommonConfiguration/CommonConfiguration"
import  ThemeCss from "styles/theme.module.css"
import "styles/global-styles.module.css"
import "styles/rte.css"
import "./tailwind.preflight.css"
import "./tailwind.css"

function FooterApp() {
  const store = configureStore({})
  const history = createBrowserHistory()
  const client = createClient()
  
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <ThemeContainer>
          <CommonConfiguration>
            <Router history={history}>
              <GlobalFooter />
            </Router>
          </CommonConfiguration>
        </ThemeContainer>
      </ApolloProvider>
    </Provider>
  )
}

export default FooterApp
