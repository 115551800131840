import React, { ReactElement, memo, useCallback, useContext, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  append,
  complement,
  equals,
  includes,
  isEmpty,
  reject,
} from "ramda"
import {
  GET_PREVIOUS_MENU_ACTIVE_ITEM,
  GET_NAVIGATION_MAIN_MENU_ITEMS,
  GET_IS_MOBILE
} from "lib/redux/selectors"
import {
  SET_MAIN_MENU_ACTIVE_ITEM,
  SET_MAIN_MENU_ACTIVE_ITEM_NOTRACK,
  SET_SHOWING_BLOCKER,
  SET_MEGA_MENU_DATA,
  SET_PREVIOUS_MENU_ACTIVE_ITEM,
  SET_PREVIOUS_ROUTE
} from "lib/redux/actions"
import GlobalNavMenu from "../../molecules/GlobalNavMenu/GlobalNavMenu"
import UserAccount from "../../molecules/UserAccount/UserAccount"
import { LogoComponent, SearchIconComponent } from "components/navigation/main-menu/components"
import { GET_MAIN_MENU_ACTIVE } from "components/navigation/utils"
import QualcommThemeContext from "components/QualcommThemeContext/ThemeContext"
import DesktopSearchComponent from "../../../navigation/main-menu/desktop/wos-desktop-search/DesktopSearchComponent"
import { GlobalNavMenuMobile } from "./GlobalNavMobile"
import { Icon } from "../../atoms/Icon"
import { useHistory } from "react-router"

const GlobalNav = ({isImpersonating}: {isImpersonating: boolean}): ReactElement => {
  const dispatch = useDispatch()
  const { push } = useHistory()

  const [previousRoute, setPreviousRoute] = useState<string | null>(null)

  const navMenuItems: any = useSelector(GET_NAVIGATION_MAIN_MENU_ITEMS)

  const isMobile = useSelector(GET_IS_MOBILE)

  const {
    activeItems,
    isSearchActive,
    isShowingRoot
  }: {
    activeItems: any,
    isSearchActive: any,
    isShowingRoot: boolean,
    megaMenuActiveDesktop: number,
  } = useSelector(GET_MAIN_MENU_ACTIVE)

  const previousMenuItems: any = useSelector(GET_PREVIOUS_MENU_ACTIVE_ITEM)

  const value = useContext(QualcommThemeContext)

  const searchIconOnClick = useCallback(() => {
    if (location.pathname !== value?.searchResultsPagePath || (value?.wosFlag && !isMobile)) {
      dispatch({
        type: SET_MAIN_MENU_ACTIVE_ITEM,
        value: isSearchActive ? [] : ["search"],
      })
      setPreviousRoute(
        isSearchActive ? null : `${location.pathname}${location.search}`,
      )
      dispatch({
        type: SET_PREVIOUS_ROUTE,
        value: isSearchActive ? null : `${location.pathname}${location.search}`,
      })
    } else {
      push(previousRoute || "/")
      window.location.reload()
    }
  }, [dispatch, isMobile, isSearchActive, location.pathname, location.search, previousRoute, push, value?.searchResultsPagePath, value?.wosFlag])

  const userIconOnClick = useCallback(() => {
    dispatch({
      type: SET_MAIN_MENU_ACTIVE_ITEM,
      value: "account",
    })
  }, [])

  const navItemClick = useCallback(
    ({ children, touts, enableLink, title }, evt) => {
      if (!isEmpty(children)) {
        evt.preventDefault()
        const active = includes(title, activeItems)
        const linkItems = active
          ? reject(equals(title), activeItems)
          : append(title, reject(complement(equals("rootItems")), activeItems))
        dispatch({
          type: SET_SHOWING_BLOCKER,
          value: !isEmpty(linkItems) ? "all" : null,
        })
        dispatch({
          action: "open-close",
          type: SET_MAIN_MENU_ACTIVE_ITEM,
          value: linkItems,
        })
        dispatch({
          type: SET_MEGA_MENU_DATA,
          value: { enableLink, items: children, touts },
        })
      }
    },
    [activeItems, dispatch],
  )

  const megaMenuItemClick = useCallback(
    ({ children, path, touts }, evt, isRootItem, title, menuData) => {
      if (!isEmpty(children)) {
        // evt.preventDefault()
        const active = includes(path, activeItems)
        const linkItems = active
          ? reject(equals(path), activeItems)
          : append(path, reject(complement(equals("rootItems")), activeItems))

        dispatch({
          type: SET_SHOWING_BLOCKER,
          value: !isEmpty(linkItems) ? "all" : null,
        })
        dispatch({ type: SET_MAIN_MENU_ACTIVE_ITEM_NOTRACK, value: linkItems })
        dispatch({
          type: SET_MEGA_MENU_DATA,
          value: { items: children, touts: touts },
        })
        if (isRootItem) {
          dispatch({
            type: SET_PREVIOUS_MENU_ACTIVE_ITEM,
            value: {
              items: children, linkItems: linkItems, touts: touts
              , isRootItem: isRootItem, rootTitle: title, parentTitle: title
              , menuData
            },
          })
        } else {
          dispatch({
            type: SET_PREVIOUS_MENU_ACTIVE_ITEM,
            value: { ...previousMenuItems, isRootItem: isRootItem, parentTitle: title, menuData }
          })
        }
      }
    },
    [activeItems, dispatch, previousMenuItems]
  )

  const getNavStyles = useCallback(() => {
    if (isImpersonating) {
      const topMargin = isMobile ? "mt-[32px]" : "mt-[41px]"
      return `bg-white ${topMargin}`
    }
    return "bg-white"
  }, [isImpersonating])

  return (
    <div className="tw-preflight">
      <nav className={getNavStyles()}>
        <div className="h-[4px] self-stretch" style={{backgroundImage: "linear-gradient(to left, rgb(231, 19, 36), rgb(50, 83, 220))"}} />

        <div className="flex w-full justify-center items-center border-b-[1px]">
          <div className="flex items-center justify-between w-full max-w-[1680px] px-s sm:px-l xl:px-[130px] py-s gap-[10px]">
            <div className="flex items-center gap-x-l">
              <LogoComponent key="desktop-logo" />

              {!isMobile && (
                <GlobalNavMenu
                  activeMenuItem={activeItems[0]}
                  handleMegaMenuItemClick={megaMenuItemClick}
                  handleNavItemClick={navItemClick}
                  navMenuItems={navMenuItems}
                />
              )}
            </div>

            <div className="flex items-center gap-x-s">
              {isMobile ? <SearchIconComponent
                closeText={"Close"}
                isActive={isSearchActive || location.pathname === value?.searchResultsPagePath}
                onClick={searchIconOnClick}
                searchText={"Search Qualcomm.com"}
              /> : <DesktopSearchComponent
                closeText="Close"
                isActive={isSearchActive}
                onClick={searchIconOnClick}
                searchText="Search Qualcomm.com"
              />}

              <div className="h-6 w-[1px] bg-theme-neutral-300" />

              {isMobile ? <button onClick={() => {
                dispatch({
                  type: SET_MAIN_MENU_ACTIVE_ITEM,
                  value: isShowingRoot ? [] : ["rootItems"],
                })
              }}>
                <Icon
                  glyphId={isShowingRoot ? "dx-close" : "dx-hamburger"}
                  size="large"
                  variant={isShowingRoot ? "blue" : "dark-gray"}
                />
              </button> : <UserAccount handleUserIconClick={userIconOnClick} />}
            </div>
          </div>
        </div>
      </nav>
      {!!isShowingRoot && isMobile && <GlobalNavMenuMobile navMenuItems={navMenuItems} />}
    </div>
  )
}

export default memo(GlobalNav)
