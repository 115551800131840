import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const EmailSubscribe = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/communications/email_subscribe-email-subscribe${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <g
        fill="inherit"
        id={`group-email-subscribe${unique}`}
        transform="translate(2.000000, 1.000000)"
      >
        <use
          fill="inherit"
          id={`mask-email-subscribe${unique}`}
          xlinkHref={`#path-1-email-subscribe${unique}`}
        />
        <g fill="inherit" id={`colors/core/gunmetal-email-subscribe${unique}`}>
          <path
            d="M17.5,4.5 L17.5,1.5 C17.5,1.22385763 17.2761424,1 17,1 C16.7238576,1 16.5,1.22385763 16.5,1.5 L16.5,4.5 L13.5,4.5 C13.2238576,4.5 13,4.72385763 13,5 C13,5.27614237 13.2238576,5.5 13.5,5.5 L16.5,5.5 L16.5,8.5 C16.5,8.77614237 16.7238576,9 17,9 C17.2761424,9 17.5,8.77614237 17.5,8.5 L17.5,5.5 L20.5,5.5 C20.7761424,5.5 21,5.27614237 21,5 C21,4.72385763 20.7761424,4.5 20.5,4.5 L17.5,4.5 Z M10.2889411,3 C10.1009227,3.63351622 10,4.30479189 10,5 C10,6.83813284 10.705526,8.50895974 11.8610057,9.75690852 L10,11.3799642 L2.79568183,5.0967975 C2.57299924,4.93349694 2.26009807,4.98163558 2.0967975,5.20431817 C1.93349694,5.42700076 1.98163558,5.73990193 2.20431817,5.9032025 L10,12.6200358 L12.5594294,10.4148072 C13.7669944,11.4057491 15.3130379,12 17,12 C18.0741303,12 19.0911297,11.7590798 20,11.3282376 L20,18 C20,18.5522847 19.5522847,19 19,19 L1,19 C0.44771525,19 6.76353751e-17,18.5522847 0,18 L0,4 C-6.76353751e-17,3.44771525 0.44771525,3 1,3 L10.2889411,3 Z M17,0 C19.765,0 22,2.235 22,5 C22,7.765 19.765,10 17,10 C14.235,10 12,7.765 12,5 C12,2.235 14.235,0 17,0 Z"
            fill="inherit"
            id={`path-1-email-subscribe${unique}`}
          />
        </g>
      </g>
    </g>
  </svg>
)

EmailSubscribe.propTypes = propTypes
export default EmailSubscribe
