import React, { useState, useEffect, useCallback } from "react"
import Menu from "components/DeveloperDetail/blocks/parent/Menu"
import  ThemeCss from "styles/theme.module.css"
import "styles/global-styles.module.css"
import { useSelector } from "react-redux"
import {GET_CURRENT_THEME} from "lib/redux/selectors"
import { gql, useQuery } from "@apollo/client"
import { contains, find, last, map, propEq, replace, toLower, values } from "ramda"
import { getMeta } from "lib/apolloClient/client"
import { hasData } from "lib/utils/helpers"
import useLazyQueryPromise from "lib/hooks/use-lazy-query-promise"


export const isExternalLink = contains("://")

const PAGE_QUERY = gql`
  query GetPage($path: String) {
    getPagePath(path: $path) {
      pageData
    }
  }
`

export const configurePath = (path = "", url = "") => {
  if (isExternalLink(path)) {
    return path
  }
  const relativePath = replace("/content/qcomm-martech/us/en/home/", "", path)
  const segments = relativePath.split("/")
  const hash = last(segments)

  return `${url}/${segments.slice(0, -1).join("/")}#${hash}`
}

export const urlMap = {
  test: "https://test.www.qualcomm.com",
  stage: "https://stage.www.qualcomm.com",
  prod: "https://www.qualcomm.com",
}

const mapItems = (items, env) => map(
  (item) => ({
    ...item,
    childPages: hasData(item?.childPages) ? mapItems(item?.childPages, env) : null,
    id: item?.title === "Docs" ? "docs" : null,
    path: item?.title === "Docs" ? "" : configurePath(item?.path, urlMap?.[env])
  }), items)

const SecondaryNav = ({secondaryNavPath}) => {
  const [path, setPath] = useState()
  const currentTheme = useSelector(GET_CURRENT_THEME)
  const [tabData, setTabData] = useState()
  const env = toLower(getMeta("Environment") || "prod")

  const [onPathSet] = useLazyQueryPromise(PAGE_QUERY)

  useEffect(
    () => {
      const showCallback = ({detail}) => {
        onPathSet( {
          fetchPolicy: "cache-and-network",
          variables: {
            path: detail
          }
        }).then(({data}) => {
          const pageData = data?.getPagePath?.pageData?.[":items"]?.root?.[":items"]?.responsivegrid?.[":items"]
          const tabData = find(propEq(":type", "qcomm-martech/components/content/tabs/v3/tabs"), values(pageData))
          const items = mapItems(tabData?.menuItems, env)
    
          setTabData({...tabData, items})
        })
      }
      const hideCallback = (event) => {
        setPath(null)
        setTabData(null)
      }
      if (secondaryNavPath) {
        showCallback({detail: secondaryNavPath})
      }
      window.addEventListener("showSecondaryNav", showCallback)
      window.addEventListener("hideSecondaryNav", hideCallback)
      return () => {
        window.removeEventListener("showSecondaryNav", showCallback)
        window.removeEventListener("hideSecondaryNav", hideCallback)
      }
    },
    []
  )
  const themeClass = `${ThemeCss["common-theme"]} ${ThemeCss[`${currentTheme}-theme`]} allow-theme developer-detail`

  return hasData(tabData) ? (
    <div className={themeClass}>
      <Menu
        currentPageId="docs"
        {...tabData}
      />
    </div>
  ) : null
}

export default SecondaryNav
