import React, {useCallback, useEffect, useState} from "react"
import {bool, func, string} from "prop-types"
import {equals} from "ramda"
import {useSelector} from "react-redux"

import GlobalSearchBar from "./SearchBarComponent"
import useDebounce from "lib/hooks/use-debounce"
import {GET_ACTIVE_ITEMS, GET_SEARCH_PLACEHOLDER} from "lib/redux/reducers/ui/ui-selectors"

const propTypes = {
  isOnSearchPage: bool,
  onSearchBlur: func,
  onSearchChange: func,
  onSearchFocus: func,
  onSearchSubmit: func,
  urlQuery: string,
  wosFlag: bool,
}

export function GlobalSearchBarContainer({
  isOnSearchPage,
  onSearchBlur,
  onSearchFocus,
  onSearchSubmit,
  onSearchChange,
  urlQuery,
  wosFlag,
  ...props
}) {
  const placeholder = useSelector(GET_SEARCH_PLACEHOLDER)
  const isMainMenuSearchActive = equals(useSelector(GET_ACTIVE_ITEMS), [
    "search",
  ])
  const [searchBarValue, setSearchBarValue] = useState(urlQuery)
  const debounced = useDebounce(searchBarValue, 100)
  const [isLoaded, setLoaded] = useState(false)
  const handleSubmit = useCallback(() => {
    onSearchSubmit(searchBarValue)
  }, [onSearchSubmit, searchBarValue])

  const handleChange = useCallback(
    ({target}) => {
      onSearchFocus()
      setSearchBarValue(target.value)
    },
    [onSearchFocus, setSearchBarValue],
  )

  useEffect(() => {
    if (isLoaded) {
      onSearchChange(debounced)
    } else{
      setTimeout(()=>{
        setLoaded(true)
      },
      500) 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounced, onSearchChange])

  useEffect(() => {
    setSearchBarValue(urlQuery)
  }, [urlQuery])

  useEffect(() => {
    if (!wosFlag && isMainMenuSearchActive) {
      setSearchBarValue("")
    }
  }, [isMainMenuSearchActive, isOnSearchPage, wosFlag])

  return (
    <GlobalSearchBar
      {...props}
      handleBlur={onSearchBlur}
      handleChange={handleChange}
      handleFocus={onSearchFocus}
      handleSubmit={handleSubmit}
      isOnSearchPage={isOnSearchPage}
      placeholder={placeholder}
      value={searchBarValue}
      wosFlagDesktop={props?.wosFlagDesktop}
      wosFlagMobile={props?.wosFlagMobile}
      wosFlag={wosFlag}
    />
  )
}

GlobalSearchBarContainer.propTypes = propTypes

export default GlobalSearchBarContainer
