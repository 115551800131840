import {
  always,
  any,
  both,
  complement,
  either,
  equals,
  ifElse,
  last,
  length,
  map,
  path,
  pathEq,
  pathOr,
  pipe,
  props,
  reduce,
  split,
  without,
} from "ramda"
import { bool, func, object, oneOfType, shape, string } from "prop-types"
import { createSelector } from "reselect"

import { hasData } from "lib/utils/helpers"
import {
  GET_ACTIVE_ITEMS,
  GET_FEATURED_TOUTS,
  GET_HIDE_LOGIN,
  GET_IS_MOBILE,
  GET_IS_ROOT_ACTIVE,
  GET_LOGGED_IN,
  GET_MAIN_MENU_ITEM_DATA,
  GET_MENU_ITEMS,
  GET_TOUTS,
} from "lib/redux/selectors"

export const GET_MAIN_MENU_ACTIVE = createSelector(
  [GET_ACTIVE_ITEMS, GET_LOGGED_IN],
  (activeItems, isLoggedIn) => ({
    activeItems,
    isLoggedIn,
    isSearchActive: equals(["search"], activeItems),
    isShowingRoot: both(length, complement(equals(["search"])))(activeItems),
    megaMenuActiveDesktop: length(
      without(["rootItems", "search"], activeItems),
    ),
  }),
)

export const computeShouldToutsDisplay = (touts) => {
  const isHorizontal = pathEq([0, "vertical"], 0, touts)
  return reduce(
    (acc, tout) => {
      return acc || isHorizontal
        ? any(hasData, props(["iconImage", "headline", "linkPath"], tout))
        : any(
          hasData,
          props(
            [
              "linkText",
              "linkPath",
              "description",
              "mobileImage",
              "desktopImage",
            ],
            tout,
          ),
        )
    },
    false,
    touts,
  )
}

export const GET_MEGA_MENU = createSelector(
  [
    GET_MAIN_MENU_ITEM_DATA,
    GET_MENU_ITEMS,
    GET_FEATURED_TOUTS,
    GET_TOUTS,
    GET_IS_ROOT_ACTIVE,
    GET_IS_MOBILE,
    GET_HIDE_LOGIN,
  ],
  (
    rootItems = [],
    menuItems = [],
    featuredTouts,
    touts,
    isRoot,
    isMobile,
    hideLogin,
  ) => ({
    featuredTouts,
    hideLogin,
    isMobile,
    isRoot,
    menuItems,
    rootItems,
    touts,
  }),
)

export const buildTouts = (menuItems, activeItems, featuredTouts, touts) => {
  const parentId = pathOr("", [last(activeItems), "mlid"], menuItems)
  const items = pathOr([], [parentId, "items"], featuredTouts)
  return map((toutId) => {
    const {
      id,
      headline,
      description,
      mobileImage,
      desktopImage,
      iconImage,
      linkText,
      linkPath,
      vertical,
    } = touts[toutId]
    return {
      description,
      desktopImage,
      headline,
      iconImage,
      id,
      linkPath,
      linkText,
      mobileImage,
      vertical,
    }
  }, items)
}

export const buildItems = (activeItems, mainMenuItems, menuItems) =>
  pipe(
    last,
    ifElse(
      either(equals("rootItems"), equals("search")),
      always(mainMenuItems),
      (id) => path([id, "children"], menuItems),
    ),
  )(activeItems)

export const loginPropsPropType = shape({
  /** onClick handler for account settings */
  accountOnClick: func,
  firstName: string,
  /** onBlur handler to control modal visibility when focus changes */
  handleModalCloseOnFocusChange: func,
  impersonateOnClick: func,
  isImpersonating: bool,
  isInternalUser: bool,
  /** Boolean to represent if the user is logged in or not */
  isLoggedIn: bool.isRequired,
  lastName: string,
  /** Boolean to represent loading true or false */
  loading: bool,
  /** log in string - user instruction */
  logIn: string.isRequired,
  /** Create a QC account string - user instruction */
  logInQCAccount: string.isRequired,
  /** log out string - user instruction */
  logOut: string.isRequired,
  /** ref for wrapper element - technically this can be either a function or native DOM Element but SSR doesn't like Element */
  loginModalWrapperRef: oneOfType([func, object]),
  /** onClick handler for navigating to a login page */
  loginOnClick: func,
  /** onClick handler for log out */
  logoutOnClick: func,
  /** onClick handler for navigating to register */
  registerOnClick: func,
  /** User email string displayed when logged in */
  userEmail: string,
  /** User full name string displayed when logged in */
  userFullName: string,
})

export const createMegaMenuSection = (activeList = "") => {
  const item = typeof activeList === "string" ? activeList : last(activeList)
  return item ? last(split("/", item)) : ""
}
