import {
  arrayOf,
  bool,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from "prop-types";

// eslint-disable-next-line import/prefer-default-export
export const globalSearchResultPropType = {
  accessGroups: arrayOf(string),
  alternateText: string,
  authors: arrayOf(string),
  body: string,
  category: string,
  chipProducts: arrayOf(string),
  classification: string,
  contentType: string,
  context: string,
  dcn: string,
  description: string,
  distroType: string,
  downloadOptions: arrayOf(shape({ id: oneOfType([string, number]) })),
  downloadText: string,
  filters: object,
  fullText: string,
  highlights: arrayOf(string),
  indexedOn: string,
  isDownloadable: bool,
  link: string,
  os: arrayOf(string),
  pageNumber: number,
  pkLevelCompositeIds: arrayOf(string),
  pkLevelCompositeIdsRequiresVerification: arrayOf(string),
  primaryContentId: oneOfType([number, string]),
  productFamilyId: number,
  publishedOn: string,
  publishingApplication: string,
  publishingApplicationResourceId: string,
  rawDocPointer: string,
  requiresAuthorization: bool,
  requiresVerification: bool,
  resource: string,
  resourceId: string,
  resourceSubType: string,
  resourceType: oneOf([
    "Documents",
    "Software",
    "Products",
    "Videos",
    "News",
    "Page",
  ]),
  revision: string,
  score: number,
  secondaryPassagePageNumbers: arrayOf(string),
  secondaryPassageTitles: arrayOf(string),
  showDownloadOptions: bool,
  size: number,
  softwareBuildType: string,
  softwareProduct: arrayOf(string),
  suiteId: string,
  title: string,
  visibilityGroups: arrayOf(string),
};
