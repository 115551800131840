import React from "react"
import { Provider } from "react-redux"
import { createBrowserHistory } from "history"
import { Router } from "react-router-dom"
import { ApolloProvider } from "@apollo/client"

import { GlobalStyles } from "lib/styles"
import ThemeContainer from "components/theme/ThemeContainer"
import configureStore from "store"
import Navigation from "components/navigation"
import createClient, { getMeta } from "lib/apolloClient/client"
import Globals from "components/globals"
import { NavigationContextProvider } from "components/navigation/navigation-provider"
import CommonConfiguration from "components/CommonConfiguration/CommonConfiguration"
import SecondaryNav from "SecondaryNav"
import  ThemeCss from "styles/theme.module.css"
import "styles/global-styles.module.css"
import "styles/rte.css"
import "./tailwind.preflight.css"
import "./tailwind.css"

function HeaderApp() {
  const store = configureStore({})
  const history = createBrowserHistory()
  const client = createClient()
  const secondaryNav = getMeta("SecondaryNav")
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <ThemeContainer>
          <CommonConfiguration>
            <NavigationContextProvider>
              <GlobalStyles />
              <Router history={history}>
                <Navigation />
                <SecondaryNav key={secondaryNav} secondaryNavPath={secondaryNav} />
                <Globals />
              </Router>
            </NavigationContextProvider>
          </CommonConfiguration>
        </ThemeContainer>
      </ApolloProvider>
    </Provider>
  )
}

export default HeaderApp
