import React from "react"
import {useSelector} from "react-redux"
import dayjs from "dayjs"
import {func, string} from "prop-types"

// import {env} from "config"
import Cta from "components/cta"
import Primitives from "primitives"
import {GET_IS_MOBILE} from "lib/redux/selectors"

const containerStyles = {
  alignItems: "center",
  backgroundColor: "blue",
  flexDirection: "row",
  justifyContent: "space-between",
  left: 0,
  position: "fixed",
  px: [3, "40px"],
  top: 0,
  width: 1,
  zIndex: 11,
}
const emailTimingStyles = {
  color: "text-light",
  fontSize: 2,
  fontWeight: 1,
  letterSpacing: "0.5px",
  lineHeight: 2,
}
const labelStyles = {
  as: "h6",
  color: "text-light",
  display: ["none", "none", "inherit"],
  fontSize: 2,
  fontWeight: 2,
  letterSpacing: "0.5px",
  lineHeight: 2,
  mr: 2,
}
const textContainerStyles = {
  alignItems: "center",
  backgroundColor: "blue",
  "data-test-id": "impersonation-banner-wrapper",
  flexDirection: "row",
  py: "12px",
  width: ["70%", "auto"],
}
const emailMobileStyles = {
  ...emailTimingStyles,
  display: ["inline-block", "inherit", "inherit", "none"],
  maxWidth: "100%",
  mr: [0, 0, 6],
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}

const ImpersonationBannerComponent = ({
  endImpersonationClick,
  expires,
  userFullName,
  userEmail,
}) => {
  const isMobile = useSelector(GET_IS_MOBILE)
  // if (env.QC_PUBLIC_IMPERSONATION !== "true") {
  //   return null
  // }
  return (
    <Primitives.Flex {...containerStyles}>
      <Primitives.Flex {...textContainerStyles}>
        <Primitives.Text {...labelStyles}>Impersonating:</Primitives.Text>
        <Primitives.Text
          display={["none", "none", "none", "inherit"]}
          {...emailTimingStyles}
          mr={6}
        >
          {`${userFullName} (${userEmail})`}
        </Primitives.Text>
        <Primitives.Text {...emailMobileStyles}>{userEmail}</Primitives.Text>
        <Primitives.Text
          display={["none", "none", "inherit"]}
          {...emailTimingStyles}
        >
          {`Session expires: ${dayjs
            .utc(expires)
            .format("MMM D, YYYY, h:mma")}`}
        </Primitives.Text>
      </Primitives.Flex>
      <Cta
        darkUi
        data-test-id="end-impersonation-button"
        mb="0"
        onClick={endImpersonationClick}
        size="small"
        version="tertiary"
      >
        {!isMobile ? "End impersonation" : "End"}
      </Cta>
    </Primitives.Flex>
  )
}
ImpersonationBannerComponent.propTypes = {
  endImpersonationClick: func,
  expires: string,
  userEmail: string,
  userFullName: string,
}

export default ImpersonationBannerComponent
