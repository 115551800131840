import React from "react"
import {string} from "prop-types"

const propTypes = {
  color: string,
  unique: string
}

const Close = ({unique = "", color = "inherit", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill={color}
      fillRule="evenodd"
      id={`glyphs/nav/close-close${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`combined-shape-close${unique}`}
        xlinkHref={`#path-1-close${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-close${unique}`}>
        <path
          d="M12,10.5857864 L19.4393398,3.14644661 C19.634602,2.95118446 19.9511845,2.95118446 20.1464466,3.14644661 L20.8535534,3.85355339 C21.0488155,4.04881554 21.0488155,4.36539803 20.8535534,4.56066017 L13.4142136,12 L20.8535534,19.4393398 C21.0488155,19.634602 21.0488155,19.9511845 20.8535534,20.1464466 L20.1464466,20.8535534 C19.9511845,21.0488155 19.634602,21.0488155 19.4393398,20.8535534 L12,13.4142136 L4.56066017,20.8535534 C4.36539803,21.0488155 4.04881554,21.0488155 3.85355339,20.8535534 L3.14644661,20.1464466 C2.95118446,19.9511845 2.95118446,19.634602 3.14644661,19.4393398 L10.5857864,12 L3.14644661,4.56066017 C2.95118446,4.36539803 2.95118446,4.04881554 3.14644661,3.85355339 L3.85355339,3.14644661 C4.04881554,2.95118446 4.36539803,2.95118446 4.56066017,3.14644661 L12,10.5857864 Z"
          fill="inherit"
          id={`path-1-close${unique}`}
        />
      </g>
    </g>
  </svg>
)

Close.propTypes = propTypes
export default Close
