import React, {ReactElement} from "react"
import {map} from "ramda"
import {MegaMenuLevel3Props} from "./MegaMenuLevel3.interface"
import {MegaMenuItemType} from "../../utils/common.interface"
import {Icon} from "components/dx/atoms/Icon"
import Link from "components/linkTs"

const MegaMenuLevel3 = ({
  item,
  handleItemClick,
}: MegaMenuLevel3Props): ReactElement => {
  const {title, children, shortUrlPath, path, touts} = item

  return (
    <div className="flex flex-col gap-xs">
      {shortUrlPath ? (
        <Link
          aria-labelledby={title}
          className="flex items-center gap-x-xxs"
          location={{
            label: title,
            pathname: shortUrlPath,
            state: {
              document: title,
              type: "megamenu",
              header_menu: "navigation",
            }
          }}
          url={shortUrlPath}
        >
          <h3 className="body-s font-[520] text-theme-neutral-800">{title}</h3>
          <Icon
            className="w-[5px] h-[10px]"
            glyphId="dx-chevron-right"
            variant="blue"
          />
        </Link>
      ) : (
        <h3 className="body-s font-[520] text-theme-neutral-800">{title}</h3>
      )}

      {children && children.length > 0 && (
        <div className="flex flex-col gap-xxs border-l border-solid border-theme-neutral-300 pl-xs">
          {map(
            ({title, shortUrlPath}: MegaMenuItemType) => (         
              <Link
                key={`${title}-link-to-${shortUrlPath}`}
                aria-labelledby={title}
                className="flex text-left"
                location={{
                  label: title,
                  pathname: shortUrlPath,
                  state: {
                    document: title,
                    type: "megamenu",
                    header_menu: "navigation",
                  }
                }}
                onClick={(evt: any) => handleItemClick({ children, path, touts }, evt, true, title, {})}
                url={shortUrlPath}
              >
                <h3 className="body-s text-theme-neutral-600">{title}</h3>
              </Link>             
            ),
            children
          )}
        </div>
      )}
    </div>
  )
}

export default MegaMenuLevel3