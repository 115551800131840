import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const DesktopComputer = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/devices/desktop_computer-desktop-computer${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-desktop-computer${unique}`}
        xlinkHref={`#path-1-desktop-computer${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-desktop-computer${unique}`}>
        <path
          d="M9,19 L9,17 L2.5,17 C2.22385763,17 2,16.7761424 2,16.5 L2,4.5 C2,4.22385763 2.22385763,4 2.5,4 L21.5,4 C21.7761424,4 22,4.22385763 22,4.5 L22,16.5 C22,16.7761424 21.7761424,17 21.5,17 L15,17 L15,19 L17.5,19 C17.7761424,19 18,19.2238576 18,19.5 C18,19.7761424 17.7761424,20 17.5,20 L6.5,20 C6.22385763,20 6,19.7761424 6,19.5 C6,19.2238576 6.22385763,19 6.5,19 L9,19 Z M3,5 L3,16 L21,16 L21,5 L3,5 Z"
          fill="inherit"
          id={`path-1-desktop-computer${unique}`}
        />
      </g>
    </g>
  </svg>
)

DesktopComputer.propTypes = propTypes
export default DesktopComputer
