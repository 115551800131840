import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Laptop = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/devices/laptop-laptop${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-laptop${unique}`}
        xlinkHref={`#path-1-laptop${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-laptop${unique}`}>
        <path
          d="M4.4461245,15 C4.16998213,15 3.9461245,14.7761424 3.9461245,14.5 L3.9461245,5.5 C3.9461245,5.22385763 4.16998213,5 4.4461245,5 L19.4461245,5 C19.7222669,5 19.9461245,5.22385763 19.9461245,5.5 L19.9461245,14.5 C19.9461245,14.7761424 19.7222669,15 19.4461245,15 L19.6406205,15 C19.8121173,15 19.9716483,15.0878935 20.0632751,15.2328613 L21.9594238,18.2328613 C22.1069604,18.466287 22.0373335,18.775118 21.8039078,18.9226546 C21.7239679,18.9731806 21.631338,19 21.5367692,19 L2.33312855,19 C2.05698617,19 1.83312855,18.7761424 1.83312855,18.5 C1.83312855,18.4057429 1.85977151,18.3134055 1.90998474,18.2336369 L3.798443,15.2336369 C3.88997745,15.0882255 4.04976412,15 4.2215868,15 L4.4461245,15 Z M5,6 L5,14 L19,14 L19,6 L5,6 Z"
          fill="inherit"
          id={`path-1-laptop${unique}`}
        />
      </g>
    </g>
  </svg>
)

Laptop.propTypes = propTypes
export default Laptop
