import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const ExitFullscreen = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/av/exit_fullscreen-exit-fullscreen${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-exit-fullscreen${unique}`}
        xlinkHref={`#path-1-exit-fullscreen${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-exit-fullscreen${unique}`}>
        <path
          d="M17,17 L17,20.5 C17,20.7761424 16.7761424,21 16.5,21 L15.5,21 C15.2238576,21 15,20.7761424 15,20.5 L15,16.5 L15,15.5 C15,15.2238576 15.2238576,15 15.5,15 L20.5,15 C20.7761424,15 21,15.2238576 21,15.5 L21,16.5 C21,16.7761424 20.7761424,17 20.5,17 L17,17 Z M9,16.5 L9,20.5 C9,20.7761424 8.77614237,21 8.5,21 L7.5,21 C7.22385763,21 7,20.7761424 7,20.5 L7,17 L3.5,17 C3.22385763,17 3,16.7761424 3,16.5 L3,15.5 C3,15.2238576 3.22385763,15 3.5,15 L8.5,15 C8.77614237,15 9,15.2238576 9,15.5 L9,16.5 Z M15,7.5 L15,3.5 C15,3.22385763 15.2238576,3 15.5,3 L16.5,3 C16.7761424,3 17,3.22385763 17,3.5 L17,7 L20.5,7 C20.7761424,7 21,7.22385763 21,7.5 L21,8.5 C21,8.77614237 20.7761424,9 20.5,9 L15.5,9 C15.2238576,9 15,8.77614237 15,8.5 L15,7.5 Z M7,7 L7,3.5 C7,3.22385763 7.22385763,3 7.5,3 L8.5,3 C8.77614237,3 9,3.22385763 9,3.5 L9,7.5 L9,8.5 C9,8.77614237 8.77614237,9 8.5,9 L3.5,9 C3.22385763,9 3,8.77614237 3,8.5 L3,7.5 C3,7.22385763 3.22385763,7 3.5,7 L7,7 Z"
          fill="inherit"
          id={`path-1-exit-fullscreen${unique}`}
        />
      </g>
    </g>
  </svg>
)

ExitFullscreen.propTypes = propTypes
export default ExitFullscreen
