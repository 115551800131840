import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const YoutubeBox = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/social/youtube_box-youtube-box${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-youtube-box${unique}`}
        xlinkHref={`#path-1-youtube-box${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-youtube-box${unique}`}>
        <path
          d="M4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 Z M14.4055393,11.8989168 L10.4677027,9.85891066 L10.4677027,13.9378459 L14.4055393,11.8989168 Z M19.0004,11.8989168 C19.0004,16.8578336 19.0004,16.8578336 11.9993229,16.8578336 C5.0004,16.8578336 5.0004,16.8578336 5.0004,11.8989168 C5.0004,6.94000006 5.0004,6.94000006 11.9993229,6.94000006 C19.0004,6.94000006 19.0004,6.94000006 19.0004,11.8989168 Z"
          fill="inherit"
          id={`path-1-youtube-box${unique}`}
        />
      </g>
    </g>
  </svg>
)

YoutubeBox.propTypes = propTypes
export default YoutubeBox
