import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "components/modal";
import {
  GET_SEARCH_MODAL_VALUE,
  GET_SEARCH_TIPS_MODAL_ACTIVE,
} from "lib/redux/selectors";
import { SET_SEARCH_TIPS_MODAL_ACTIVE } from "lib/redux/actions";
import Primitives from "primitives";

const SearchTipsModalContainer = () => {
  const isActive = useSelector(GET_SEARCH_TIPS_MODAL_ACTIVE);

  // const searchTips = useSelector(
  //   makeLocalizedStringSelector({key: "SEARCH_TIPS_COPY"}),
  // )
  const searchTips = useSelector(GET_SEARCH_MODAL_VALUE);

  const dispatch = useDispatch();

  const toggleContentPodModal = useCallback(() => {
    dispatch({ type: SET_SEARCH_TIPS_MODAL_ACTIVE, value: !isActive });
  }, [dispatch, isActive]);

  return (
    isActive && (
      <Modal closeModal={toggleContentPodModal} large showFullscreenMobile>
        <Primitives.RawHtml html={searchTips} />
      </Modal>
    )
  );
};

export default SearchTipsModalContainer;
