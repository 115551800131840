import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const VolumeMute = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/av/volume_mute-volume-mute${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-volume-mute${unique}`}
        xlinkHref={`#path-1-volume-mute${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-volume-mute${unique}`}>
        <path
          d="M5.65775911,7.47014828 L2.58815612,4.03941552 C2.4040258,3.83362281 2.42158664,3.51752775 2.62737934,3.33339743 L3.37262066,2.66660257 C3.57841336,2.48247225 3.89450843,2.50003309 4.07863874,2.7058258 L20.4118439,20.9605845 C20.5959742,21.1663772 20.5784134,21.4824723 20.3726207,21.6666026 L19.6273793,22.3333974 C19.4215866,22.5175277 19.1054916,22.4999669 18.9213613,22.2941742 L17.3256799,20.5107656 C16.8032995,20.8400996 16.2482934,21.1224534 15.6666,21.3519 C15.3446,21.4789 14.9966,21.2239 14.9966,20.8769 L14.9966,19.7829 C14.9966,19.5869 15.1146,19.4139 15.2926,19.3329 C15.5226626,19.2288537 15.746931,19.1142706 15.9647846,18.989765 L12.9999,15.6760704 L12.9999,20.8424 C12.9999,21.3944 12.5519,21.8424 11.9999,21.8424 C11.7579,21.8424 11.5239,21.7544 11.3409,21.5944 L5.3409,16.3444 C5.2449,16.2594 5.1659,16.1584 5.1089,16.0464 L3.9999,16.0464 C2.8959,16.0464 1.9999,15.1504 1.9999,14.0464 L1.9999,10.0464 C1.9999,8.9424 2.8959,8.0464 3.9999,8.0464 L5.1089,8.0464 C5.1659,7.9344 5.2449,7.8324 5.3409,7.7474 L5.65775911,7.47014828 Z M8.64935159,4.85250486 L11.3409,2.4974 C11.7569,2.1334 12.3889,2.1754 12.7529,2.5914 C12.9119,2.7744 12.9999,3.0074 12.9999,3.2504 L12.9999,9.7148825 L8.64935159,4.85250486 Z M17.4084641,14.6421011 L14.9966,11.9464884 L14.9966,7.8179 C14.9966,7.3979 15.4916,7.1549 15.8156,7.4219 C17.1486,8.5219 17.9996,10.1829 17.9996,12.0469 C17.9996,12.9770906 17.7872197,13.8571186 17.4084641,14.6421011 Z M20.2033939,17.7658462 L18.8223608,16.2223387 C19.5689907,15.0075583 19.9996,13.577935 19.9996,12.0469 C19.9996,8.8029 18.0676,6.0149 15.2926,4.7589 C15.1146,4.6779 14.9966,4.5059 14.9966,4.3099 L14.9966,3.2149 C14.9966,2.8689 15.3446,2.6139 15.6666,2.7409 C19.3756,4.2039 21.9996,7.8179 21.9996,12.0469 C21.9996,14.1738206 21.3355566,16.1453591 20.2033939,17.7658462 Z"
          fill="inherit"
          id={`path-1-volume-mute${unique}`}
        />
      </g>
    </g>
  </svg>
)

VolumeMute.propTypes = propTypes
export default VolumeMute
