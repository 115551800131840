import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const AllDone = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/all_done-all-done${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`fill-1-all-done${unique}`}
        xlinkHref={`#path-1-all-done${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-all-done${unique}`}>
        <path
          d="M17.2591652,6.23455084 C17.4311563,6.01855084 17.3961581,5.70455084 17.1801692,5.53255084 L16.3982094,4.90955084 C16.1822205,4.73655084 15.8672367,4.77255084 15.6952456,4.98855084 L10.2655247,11.8025508 L11.6864516,13.2295508 L17.2591652,6.23455084 Z M22.8118798,5.53255084 L22.02992,4.90955084 C21.8139311,4.73655084 21.4989472,4.77255084 21.3259561,4.98855084 L12.145428,16.5115508 L12.0054352,16.3715508 L10.5845083,14.9435508 L10.4385158,14.7965508 L9.01758883,13.3695508 L8.19563108,12.5425508 C7.99964115,12.3455508 7.6836574,12.3455508 7.48766747,12.5405508 L6.77970386,13.2465508 C6.59371342,13.4295508 6.58971363,13.7195508 6.7537052,13.9175508 C6.76270474,13.9275508 6.76670453,13.9425508 6.77770396,13.9535508 L7.76365328,14.9435508 L9.18358029,16.3705508 L10.7504998,17.9455508 L11.9154399,19.1155508 C11.9284392,19.1295508 11.9434384,19.1415508 11.9574377,19.1535508 C12.1734266,19.3255508 12.4884104,19.2905508 12.6604016,19.0745508 L22.8908757,6.23455084 C23.0628669,6.01855084 23.0278687,5.70455084 22.8118798,5.53255084 Z M6.51371753,16.5115508 L7.93464449,17.9385508 L7.02969101,19.0745508 C6.85769985,19.2905508 6.54271604,19.3255508 6.32672715,19.1535508 C6.31172792,19.1415508 6.29772864,19.1295508 6.28372936,19.1155508 L1.14599345,13.9535508 C0.951003475,13.7565508 0.951003475,13.4405508 1.14799335,13.2465508 L1.85695691,12.5405508 C2.05194688,12.3455508 2.36793064,12.3455508 2.56392057,12.5425508 L3.96684845,13.9525508 L5.41177418,15.4045508 L6.51371753,16.5115508 Z"
          fill="inherit"
          id={`path-1-all-done${unique}`}
        />
      </g>
    </g>
  </svg>
)

AllDone.propTypes = propTypes
export default AllDone
