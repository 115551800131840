import React from "react"
import { string } from "prop-types"
import style from "./theme-icon.module.css"

const propTypes = {
  unique: string
}

const YoutubeWOS = ({ unique = "", ...props }) => (
 <svg width="20" height="20" viewBox="0 0 20 20" fill="inherit" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.8636 10.0197L8.17569 7.5911V12.447L12.8636 10.0197ZM18.3337 10.0197C18.3337 15.9232 18.3337 15.9232 9.99904 15.9232C1.66699 15.9232 1.66699 15.9232 1.66699 10.0197C1.66699 4.11621 1.66699 4.11621 9.99904 4.11621C18.3337 4.11621 18.3337 4.11621 18.3337 10.0197Z"
 fill={'inherit'}/>
</svg>

)

YoutubeWOS.propTypes = propTypes
export default YoutubeWOS
