import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const List = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/list-list${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        fillRule="nonzero"
        id={`grey600-list${unique}`}
        xlinkHref={`#path-1-list${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-list${unique}`}>
        <path
          d="M2,13 C1.4477,13 1,12.5523 1,12 C1,11.4477 1.4477,11 2,11 C2.5523,11 3,11.4477 3,12 C3,12.5523 2.5523,13 2,13 Z M2,17 C1.4477,17 1,16.5523 1,16 C1,15.4477 1.4477,15 2,15 C2.5523,15 3,15.4477 3,16 C3,16.5523 2.5523,17 2,17 Z M2,9 C1.4477,9 1,8.5523 1,8 C1,7.4477 1.4477,7 2,7 C2.5523,7 3,7.4477 3,8 C3,8.5523 2.5523,9 2,9 Z M5.5,7 L22.5,7 C22.7761424,7 23,7.22385763 23,7.5 L23,8.5 C23,8.77614237 22.7761424,9 22.5,9 L5.5,9 C5.22385763,9 5,8.77614237 5,8.5 L5,7.5 C5,7.22385763 5.22385763,7 5.5,7 Z M5.5,11 L22.5,11 C22.7761424,11 23,11.2238576 23,11.5 L23,12.5 C23,12.7761424 22.7761424,13 22.5,13 L5.5,13 C5.22385763,13 5,12.7761424 5,12.5 L5,11.5 C5,11.2238576 5.22385763,11 5.5,11 Z M5.5,15 L22.5,15 C22.7761424,15 23,15.2238576 23,15.5 L23,16.5 C23,16.7761424 22.7761424,17 22.5,17 L5.5,17 C5.22385763,17 5,16.7761424 5,16.5 L5,15.5 C5,15.2238576 5.22385763,15 5.5,15 Z"
          fill="inherit"
          id={`path-1-list${unique}`}
        />
      </g>
    </g>
  </svg>
)

List.propTypes = propTypes
export default List
