import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Mac = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/alert/mac-mac${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-mac${unique}`}
        xlinkHref={`#path-1-mac${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-mac${unique}`}>
        <path
          d="M20,17.607 C19.214,19.887 16.861,23.924 14.437,23.968 C12.829,23.999 12.312,23.015 10.474,23.015 C8.637,23.015 8.062,23.938 6.542,23.998 C3.97,24.097 0,18.171 0,13.003 C0,8.256 3.308,5.903 6.198,5.86 C7.748,5.832 9.212,6.905 10.157,6.905 C11.106,6.905 12.884,5.615 14.753,5.804 C15.535,5.837 17.732,6.119 19.142,8.181 C15.401,10.623 15.984,15.73 20,17.607 Z M14.778,0 C11.952,0.114 9.646,3.079 9.968,5.531 C12.58,5.734 15.086,2.806 14.778,0 Z"
          id={`path-1-mac${unique}`}
        />
      </g>
    </g>
  </svg>
)

Mac.propTypes = propTypes
export default Mac
