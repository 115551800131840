import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const MediaChat = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/media/chat-media-chat${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-media-chat${unique}`}
        xlinkHref={`#path-1-media-chat${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-media-chat${unique}`}>
        <path
          d="M18.0572995,12 L17,12 L17,9 C17,8.44771525 16.5522847,8 16,8 L9,8 L9,5 C9,4.44771525 9.44771525,4 10,4 L20,4 C20.5522847,4 21,4.44771525 21,5 L21,11 L21.0000005,13.77624 C21.0000005,14.0523824 20.7761429,14.27624 20.5000005,14.27624 C20.3657209,14.27624 20.2370849,14.2222292 20.1430547,14.1263682 L18.0572995,12 Z M6,18 L3.85355339,20.1464466 C3.7597852,20.2402148 3.63260824,20.2928932 3.5,20.2928932 C3.22385763,20.2928932 3,20.0690356 3,19.7928932 L3,17 L3,11 C3,10.4477153 3.44771525,10 4,10 L14,10 C14.5522847,10 15,10.4477153 15,11 L15,17 C15,17.5522847 14.5522847,18 14,18 L6,18 Z"
          fill="inherit"
          id={`path-1-media-chat${unique}`}
        />
      </g>
    </g>
  </svg>
)

MediaChat.propTypes = propTypes
export default MediaChat
