import {mergeDeepRight} from "ramda"

import {
  ERROR_FIXTURE_MENUS,
  ERROR_FIXTURE_TOUTS,
} from "lib/utils/error/fixtures"
import {SET_GLOBAL_ERROR_STATE} from "../globals/globals-reducer"
import {SET_MAIN_MENU_ACTIVE_ITEM, SET_MAIN_MENU_ACTIVE_ITEM_NOTRACK} from "../ui/ui-reducer"

export const FETCH_FEATURED_TOUTS_SUCCESS = "FETCH_FEATURED_TOUTS_SUCCESS"
export const FETCH_MENUS_DATA_SUCCESS = "FETCH_MENUS_DATA_SUCCESS"
export const FETCH_PRODUCT_ROUTES_DATA_SUCCESS =
  "FETCH_PRODUCT_ROUTES_DATA_SUCCESS"
export const SET_LOGIN_STATUS = "SET_LOGIN_STATUS"
export const SET_MEGA_MENU_DATA = "SET_MEGA_MENU_DATA"
export const SET_NAVIGATION_MAIN_MENU_ITEMS = "SET_NAVIGATION_MAIN_MENU_ITEMS"
export const SET_NAVIGATION_MAIN_MENU_PRODUCT_CTA = "SET_NAVIGATION_MAIN_MENU_PRODUCT_CTA"
const initialState = {
  featuredTouts: {},
  globalSearchSuggestions: [],
  loggedIn: false,
  loggingIn: false,
  loginError: false,
  menus: [],
  productRoutes: [],
  username: null,
  productCta: {}
}

const reducer = {
  [FETCH_FEATURED_TOUTS_SUCCESS]: (state, {value}) => ({
    ...state,
    featuredTouts: value,
  }),
  [FETCH_MENUS_DATA_SUCCESS]: (state, {value}) => ({
    ...state,
    menus: value,
  }),
  [FETCH_PRODUCT_ROUTES_DATA_SUCCESS]: (state, {value}) => ({
    ...state,
    productRoutes: value,
  }),
  [SET_GLOBAL_ERROR_STATE]: (state, {menus, featuredTouts}) => ({
    featuredTouts: mergeDeepRight(ERROR_FIXTURE_TOUTS, featuredTouts),
    menus: mergeDeepRight(ERROR_FIXTURE_MENUS, menus),
    ...state,
  }),
  [SET_LOGIN_STATUS]: (state, {value}) => ({
    ...state,
    loggedIn: value,
  }),
  [SET_MAIN_MENU_ACTIVE_ITEM]: (state, {value}) => ({
    ...state,
    globalSearchSuggestions: value,
  }),
  [SET_MAIN_MENU_ACTIVE_ITEM_NOTRACK]: (state, {value}) => ({
    ...state,
    globalSearchSuggestions: value,
  }),
  [SET_MEGA_MENU_DATA]: (state, { value }) => ({	
    ...state,	
    megaMenuData: value,	
  }),
  [SET_NAVIGATION_MAIN_MENU_ITEMS]: (state, {value}) => ({
    ...state,
    mainMenuItems: value,
  }),	
  [SET_NAVIGATION_MAIN_MENU_PRODUCT_CTA]: (state, {value}) => ({
    ...state,
    productCta: value,
  }),
}

function dataReducer(state = initialState, action) {
  const func = reducer[action.type]
  return func ? func(state, action) : state
}

export default dataReducer
