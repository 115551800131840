import React from "react";
import ReactDOM from "react-dom";

import HeaderApp from "./HeaderApp";
import FooterApp from "./FooterApp";

const headerElement = document.getElementById("header");
if (headerElement) {
  ReactDOM.render(
    <React.StrictMode>
      <HeaderApp />
    </React.StrictMode>,
    headerElement,
  );
}

const footerElement = document.getElementById("footer");
if (footerElement) {
  ReactDOM.render(
    <React.StrictMode>
      <FooterApp />
    </React.StrictMode>,
    footerElement,
  );
}
