import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Calendar = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/time/calender-calendar${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-calendar${unique}`}
        xlinkHref={`#path-1-calendar${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-calendar${unique}`}>
        <path
          d="M18,5 L20,5 C20.5522847,5 21,5.44771525 21,6 L21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,6 C3,5.44771525 3.44771525,5 4,5 L6,5 L6,3.5 C6,3.22385763 6.22385763,3 6.5,3 L7.5,3 C7.77614237,3 8,3.22385763 8,3.5 L8,5 L16,5 L16,3.5 C16,3.22385763 16.2238576,3 16.5,3 L17.5,3 C17.7761424,3 18,3.22385763 18,3.5 L18,5 Z M5,10 L5,18.5 C5,18.7761424 5.22385763,19 5.5,19 L18.5,19 C18.7761424,19 19,18.7761424 19,18.5 L19,10 L5,10 Z"
          fill="inherit"
          id={`path-1-calendar${unique}`}
        />
      </g>
    </g>
  </svg>
)

Calendar.propTypes = propTypes
export default Calendar
