import React, {ReactElement, ReactNode, useCallback} from "react"
import {useDispatch} from "react-redux"

import {callIfFunc} from "lib/utils/helpers"
import {EXTERNAL_LINK} from "lib/redux/actions"
import ExternalLinkComponent from "./ExternalLinkComponent"

interface ExternalLinkContainerType {
  className?: string | undefined;
  children: ReactNode;
  linkHandler?: Function;
  onClick?: Function;
  url?: string | undefined; 
}

const ExternalLinkContainer = ({
  className,
  children,
  onClick,
  linkHandler: linkHandlerOverride,
  url,
  ...props
}: ExternalLinkContainerType): ReactElement => {
  const dispatch = useDispatch()
  const linkHandler = useCallback(
    (link, event) => {
      callIfFunc(onClick, event)
      callIfFunc(linkHandlerOverride, event, props)
      if (typeof linkHandlerOverride !== "function") {
        dispatch({location: link, type: EXTERNAL_LINK})
      }
    },
    [dispatch, linkHandlerOverride, onClick,props],
  )
  return <ExternalLinkComponent className={className} linkHandler={linkHandler} url={url} {...props}>{children}</ExternalLinkComponent>
}

export default ExternalLinkContainer
