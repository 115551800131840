import React from "react"


const AccordionCollapse = ({unique = "", ...props}) => {

    return <svg {...props} width="20" height="20" viewBox="0 0 20 20" stroke={'inherit'} fill="none"
                xmlns="http://www.w3.org/2000/svg">
        <path d="M15.834 10L4.16732 10" stroke="inherit" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
}

export default AccordionCollapse;
