/**
 *      Grid system allows quick creation of responsive grid based systems. Includes
 *      a Container and an element that takes a columns prop that can either be a
 *      set column or an array to change column widths responsively.
 *
 *    // Theme setup required for GridSystem
 *    grid: {
 *      // Number of columns at each breakpoint
 *      columns: [6, 8, 12, 12, 12, 12],
 *      // Gutters between columns at each breakpoint
 *      gutters: ["4px", "8px", "12px", "12px", "12px", "16px"],
 *      // Side margins at each breakpoint
 *      margins: [3, 5, 5, 7],
 *      // Maximum width of Grid
 *      maxWidth: 1420
 *    }
 *
 *    <GridSystem.Wrapper> // Main section container -- handles maxwidth -- side gutters -- background images
 *      <GridSystem.Container> // Row -- counteracts column outer padding
 *        <GridSystem.Element columns={[6, 8, 4]}>// Column</GridSystem.Element> // Column -- handles gutters -- responsive widths
 *        <GridSystem.Element columns={[6, 8, 4]}>// Column</GridSystem.Element>
 *        <GridSystem.Element columns={[6, 8, 4]}>// Column</GridSystem.Element>
 *      </GridSystem.Container>
 *      <GridSystem.Container> // Row -- counteracts column outer padding
 *        <GridSystem.Element columns={[6, 8, 3]}>// Column</GridSystem.Element> // Column -- handles gutters -- responsive widths
 *        <GridSystem.Element columns={[6, 8, 3]}>// Column</GridSystem.Element>
 *        <GridSystem.Element columns={[6, 8, 3]}>// Column</GridSystem.Element>
 *        <GridSystem.Element columns={[6, 8, 3]}>// Column</GridSystem.Element>
 *      </GridSystem.Container>
 *    </GridSystem.Wrapper>
 */
import Container from "./GridContainer";
import Element from "./GridElement";
import Wrapper from "./GridWrapper";

const GridSystem = { Container, Element, Wrapper };
export default GridSystem;
