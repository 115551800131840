import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Preferences = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/preferences${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <g fill="inherit" id={`colors/flat/gunmetal-preferences${unique}`}>
        <path
          d="M11.7080218,8 L2.5,8 C2.22385763,8 2,7.77614237 2,7.5 L2,6.5 C2,6.22385763 2.22385763,6 2.5,6 L11.7080218,6 C12.0938002,5.11704761 12.9748405,4.5 14,4.5 C15.0251595,4.5 15.9061998,5.11704761 16.2919782,6 L21.5,6 C21.7761424,6 22,6.22385763 22,6.5 L22,7.5 C22,7.77614237 21.7761424,8 21.5,8 L16.2919782,8 C15.9061998,8.88295239 15.0251595,9.5 14,9.5 C12.9748405,9.5 12.0938002,8.88295239 11.7080218,8 Z M5.70802184,13 L2.5,13 C2.22385763,13 2,12.7761424 2,12.5 L2,11.5 C2,11.2238576 2.22385763,11 2.5,11 L5.70802184,11 C6.09380015,10.1170476 6.97484048,9.5 8,9.5 C9.02515952,9.5 9.90619985,10.1170476 10.2919782,11 L21.5,11 C21.7761424,11 22,11.2238576 22,11.5 L22,12.5 C22,12.7761424 21.7761424,13 21.5,13 L10.2919782,13 C9.90619985,13.8829524 9.02515952,14.5 8,14.5 C6.97484048,14.5 6.09380015,13.8829524 5.70802184,13 Z M13.7080218,18 L2.5,18 C2.22385763,18 2,17.7761424 2,17.5 L2,16.5 C2,16.2238576 2.22385763,16 2.5,16 L13.7080218,16 C14.0938002,15.1170476 14.9748405,14.5 16,14.5 C17.0251595,14.5 17.9061998,15.1170476 18.2919782,16 L21.5,16 C21.7761424,16 22,16.2238576 22,16.5 L22,17.5 C22,17.7761424 21.7761424,18 21.5,18 L18.2919782,18 C17.9061998,18.8829524 17.0251595,19.5 16,19.5 C14.9748405,19.5 14.0938002,18.8829524 13.7080218,18 Z M14,8.5 C14.8284271,8.5 15.5,7.82842712 15.5,7 C15.5,6.17157288 14.8284271,5.5 14,5.5 C13.1715729,5.5 12.5,6.17157288 12.5,7 C12.5,7.82842712 13.1715729,8.5 14,8.5 Z M8,13.5 C8.82842712,13.5 9.5,12.8284271 9.5,12 C9.5,11.1715729 8.82842712,10.5 8,10.5 C7.17157288,10.5 6.5,11.1715729 6.5,12 C6.5,12.8284271 7.17157288,13.5 8,13.5 Z M16,18.5 C16.8284271,18.5 17.5,17.8284271 17.5,17 C17.5,16.1715729 16.8284271,15.5 16,15.5 C15.1715729,15.5 14.5,16.1715729 14.5,17 C14.5,17.8284271 15.1715729,18.5 16,18.5 Z"
          fill="inherit"
          id={`path-1-preferences${unique}`}
        />
      </g>
    </g>
  </svg>
)

Preferences.propTypes = propTypes
export default Preferences
