import { hasData } from "lib/utils/helpers";

export const cacheConfig = {
  typePolicies: {
    BreadcrumbItem: {
      fields: {
        children: {
          merge(existing = [], incoming = []) {
            return hasData(incoming) ? [...incoming] : [...existing];
          },
        },
      },
    },
    ProductDetailPageNode: {
      fields: {
        attributes: {
          merge(existing = {}, incoming = {}) {
            return hasData(incoming) ? incoming : existing;
          },
        },
      },
    },
  },
};
