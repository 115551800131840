import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Business = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/communications/business-business${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-business${unique}`}
        xlinkHref={`#path-1-business${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-business${unique}`}>
        <path
          d="M13,21 L10,21 L10,11 C10,10.4477153 9.55228475,10 9,10 L8,10 L8,4 C8,3.44771525 8.44771525,3 9,3 L13,3 C13.5522847,3 14,3.44771525 14,4 L14,13 C13.4477153,13 13,13.4477153 13,14 L13,21 Z M19,21 L19,14 C19,13.4477153 18.5522847,13 18,13 L15,13 L15,8 L20,8 C20.5522847,8 21,8.44771525 21,9 L21,21 L19,21 Z M14.5,14 L17.5,14 C17.7761424,14 18,14.2238576 18,14.5 L18,21 L14,21 L14,14.5 C14,14.2238576 14.2238576,14 14.5,14 Z M4,11 L8.5,11 C8.77614237,11 9,11.2238576 9,11.5 L9,21 L3,21 L3,12 C3,11.4477153 3.44771525,11 4,11 Z"
          fill="inherit"
          id={`path-1-business${unique}`}
        />
      </g>
    </g>
  </svg>
)

Business.propTypes = propTypes
export default Business
