import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Video = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id="Glyphs/Media/*Video"
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-video${unique}`}
        xlinkHref={`#path-1-video${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-video${unique}`}>
        <path
          d="M3,5 L21,5 C21.5522847,5 22,5.44771525 22,6 L22,18 C22,18.5522847 21.5522847,19 21,19 L3,19 C2.44771525,19 2,18.5522847 2,18 L2,6 C2,5.44771525 2.44771525,5 3,5 Z M10.023607,9.3618034 C9.95417927,9.32708954 9.87762274,9.30901699 9.80000019,9.30901699 C9.52385782,9.30901699 9.30000019,9.53287462 9.30000019,9.80901699 L9.30000019,14.190983 C9.30000019,14.2686056 9.31807274,14.3451621 9.3527866,14.4145898 C9.47628122,14.6615791 9.77661774,14.7616912 10.023607,14.6381966 L14.405573,12.4472136 C14.5023367,12.3988317 14.5807979,12.3203705 14.6291798,12.2236068 C14.7526744,11.9766175 14.6525622,11.676281 14.405573,11.5527864 L10.023607,9.3618034 Z"
          fill="inherit"
          id={`path-1-video${unique}`}
        />
      </g>
    </g>
  </svg>
)

Video.propTypes = propTypes
export default Video
