import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Devboard = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/products/devboard-devboard${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-devboard${unique}`}
        xlinkHref={`#path-1-devboard${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-devboard${unique}`}>
        <path
          d="M2,6 L22,6 C22.5522847,6 23,6.44771525 23,7 L23,17 C23,17.5522847 22.5522847,18 22,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M3.5,8 C3.22385763,8 3,8.22385763 3,8.5 L3,11.5 C3,11.7761424 3.22385763,12 3.5,12 L6.5,12 C6.77614237,12 7,11.7761424 7,11.5 L7,8.5 C7,8.22385763 6.77614237,8 6.5,8 L3.5,8 Z M15.1337822,14.5 L13.5,14.5 L13.5,9.8662178 C13.7989007,9.69331309 14,9.37014118 14,9 C14,8.44771525 13.5522847,8 13,8 C12.4477153,8 12,8.44771525 12,9 C12,9.37014118 12.2010993,9.69331309 12.5,9.8662178 L12.5,14.5 L7.8662178,14.5 C7.69331309,14.2010993 7.37014118,14 7,14 C6.44771525,14 6,14.4477153 6,15 C6,15.5522847 6.44771525,16 7,16 C7.37014118,16 7.69331309,15.7989007 7.8662178,15.5 L15.1337822,15.5 C15.3066869,15.7989007 15.6298588,16 16,16 C16.5522847,16 17,15.5522847 17,15 C17,14.4477153 16.5522847,14 16,14 C15.6298588,14 15.3066869,14.2010993 15.1337822,14.5 Z M19.5,9.5 L19.5,14 C19.5,14.0431182 19.5054579,14.0849616 19.5157206,14.1248771 C19.2081718,14.2954336 19,14.6234063 19,15 C19,15.5522847 19.4477153,16 20,16 C20.5522847,16 21,15.5522847 21,15 C21,14.6234063 20.7918282,14.2954336 20.4842794,14.1248771 C20.4945421,14.0849616 20.5,14.0431182 20.5,14 L20.5,9 C20.5,8.72385763 20.2761424,8.5 20,8.5 L17.8662178,8.5 C17.6933131,8.20109931 17.3701412,8 17,8 C16.4477153,8 16,8.44771525 16,9 C16,9.55228475 16.4477153,10 17,10 C17.3701412,10 17.6933131,9.79890069 17.8662178,9.5 L19.5,9.5 Z"
          fill="inherit"
          id={`path-1-devboard${unique}`}
        />
      </g>
    </g>
  </svg>
)

Devboard.propTypes = propTypes
export default Devboard
