import React from "react"
import styled  from "styled-components"
import { css } from "styled-components/macro"
import {
  array,
  bool,
  func,
  node,
  number,
  objectOf,
  oneOfType,
  string
} from "prop-types"

import {transitionEase} from "lib/styles/deprecated/utils"
import Primitives from "primitives"
import {buttonStyles, getSpacing, getUIColors, linkStyles} from "./utils"

export const ButtonContainer = styled(({disabled, version, ...props}) => (
  <Primitives.Button {...props} />
))`
  cursor: pointer;
  text-decoration: none;
  ${({disabled}) =>
  disabled
    ? css`
          opacity: 0.35;
        `
    : null};
  ${({version}) =>
      version === "primary"
        ? css`
          position: relative;
        `
        : null};
`

export const ButtonBack = styled(({ctaStyle, hover, ui, version, ...props}) => (
  <Primitives.Flex {...props} />
))`
  display: inline-flex;
  ${transitionEase("0.7s", [
  "background-color",
  "color",
  "border-color",
  "opacity"
])};
  ${({ui, version, ...props}) =>
  version === "link"
    ? linkStyles(props)
    : buttonStyles({ui, version, ...props})};
`

export const LinkContainer = styled(ButtonContainer)`
  display: inline-block;
`

const propTypes = {
  /** Button content, usually a text string, but can be a React component */
  children: node,
  /** Styled components generated className */
  className: string,
  /** A string which represents a color from the color library */
  color: string,
  containerProps: objectOf(oneOfType([array, string, number])),
  /** Cypress test id */
  "data-test-id": string,
  /** A boolean to represent whether the cta should be disabled */
  disabled: bool,
  /**
   * Determines what to render for the CtaContainer.
   * Defaults to an anchor tag if a url prop is present and a <button> tag otherwise
   * Sometimes, Tag is a Link which is rendered as the function: MapProps.
   */
  forwardedAs: oneOfType([node, func, string]),
  /** A function that will be called when the button is clicked */
  handleClick: func,
  /** A function that will be called on mouse down */
  handleMouseDown: func,
  justifyContent: string,
  /** Margin bottom, implements style-system */
  mb: oneOfType([array, string, number]),
  /** Margin right, implements style-system */
  mr: oneOfType([array, string, number]),
  /** A function that will be called when the button is clicked */
  onClick: func,
  /** Cta size, determines which size styling to apply */
  size: string,
  /** Target for when Ctas are links, used for opening a new tab or window */
  target: string,
  /** A to determine whether the dark or light version of the cta is used */
  ui: string,
  /** Url provided to CtaContainer for links */
  url: string,
  /** Cta version, determines which version styles to apply */
  version: string,
  /** Determines Cta width, defaults to "auto", implements style-system */
  width: oneOfType([array, string, number])
}

const CtaContainer = ({
  children,
  className,
  color,
  containerProps,
  "data-test-id": dataTestId,
  disabled,
  forwardedAs,
  innerCtaWidth,
  mb,
  mr = "10px",
  handleClick,
  handleMouseDown,
  justifyContent,
  size = "large",
  ui = "light",
  url,
  version = "secondary",
  target,
  width,
  ...props
}) => {
  const Constructor = url ? LinkContainer : ButtonContainer
  return (
    <Constructor
      className={className}
      data-test-id={dataTestId}
      disabled={disabled}
      forwardedAs={forwardedAs}
      href={url}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      target={url && target}
      version={version}
      width={width}
      {...containerProps}
    >
      <ButtonBack
        alignItems="center"
        color={color}
        justifyContent={justifyContent}
        lineHeight={0}
        mb={mb}
        mr={mr}
        ui={ui}
        version={version}
        width={innerCtaWidth || width || "auto"}
        {...getSpacing(version, size)}
        {...getUIColors(ui, version)}
        {...props}
      >
        {children}
      </ButtonBack>
    </Constructor>
  )
}

CtaContainer.propTypes = propTypes
CtaContainer.displayName = "Modules.CtaContainer"

export default CtaContainer
