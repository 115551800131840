import React from "react";
import {
  arrayOf,
  func,
  node,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from "prop-types";

import Primitives from "primitives";

const propTypes = {
  /** Child content string or react node */
  children: node,
  innerRef: oneOfType([object, func]),
  /** Action creator used by external link analytics events, provided via redux connect HoC */
  linkHandler: func,
  /** Location object, used only for analytics on external links */
  location: shape({
    state: shape({
      text: oneOfType([arrayOf(string), string]),
      type: string,
    }),
  }),
  /** Click handler function */
  onClick: func,
  /** Link target type */
  target: oneOf(["_self", "_blank", "_top", "_parent"]),
  /** Link path */
  url: string,
};

const ExternalLink = ({
  children,
  url = null,
  innerRef,
  location = {},
  linkHandler,
  onClick,
  target,
  ...props
}) => (
  <Primitives.Box
    as="a"
    href={url}
    onClick={(evt) => {
      if (onClick) {
        onClick(evt);
      } else if (linkHandler) {
        linkHandler(
          {
            pathname: url,
            ...location,
          },
          evt,
        );
      }
    }}
    ref={innerRef}
    target={target}
    {...props}
  >
    {children}
  </Primitives.Box>
);

ExternalLink.propTypes = propTypes;

export default ExternalLink;
