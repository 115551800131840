import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Solutions = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/applications/solutions-solutions${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-solutions${unique}`}
        xlinkHref={`#path-1-solutions${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-solutions${unique}`}>
        <path
          d="M12,17 C9.23857625,17 7,14.7614237 7,12 C7,9.23857625 9.23857625,7 12,7 C14.7614237,7 17,9.23857625 17,12 C17,14.7614237 14.7614237,17 12,17 Z M12,5 C11.1715729,5 10.5,4.32842712 10.5,3.5 C10.5,2.67157288 11.1715729,2 12,2 C12.8284271,2 13.5,2.67157288 13.5,3.5 C13.5,4.32842712 12.8284271,5 12,5 Z M12,22 C11.1715729,22 10.5,21.3284271 10.5,20.5 C10.5,19.6715729 11.1715729,19 12,19 C12.8284271,19 13.5,19.6715729 13.5,20.5 C13.5,21.3284271 12.8284271,22 12,22 Z M20.5,14 C19.6715729,14 19,13.3284271 19,12.5 C19,11.6715729 19.6715729,11 20.5,11 C21.3284271,11 22,11.6715729 22,12.5 C22,13.3284271 21.3284271,14 20.5,14 Z M18,7.5 C17.1715729,7.5 16.5,6.82842712 16.5,6 C16.5,5.17157288 17.1715729,4.5 18,4.5 C18.8284271,4.5 19.5,5.17157288 19.5,6 C19.5,6.82842712 18.8284271,7.5 18,7.5 Z M18,19.5 C17.1715729,19.5 16.5,18.8284271 16.5,18 C16.5,17.1715729 17.1715729,16.5 18,16.5 C18.8284271,16.5 19.5,17.1715729 19.5,18 C19.5,18.8284271 18.8284271,19.5 18,19.5 Z M6,19.5 C5.17157288,19.5 4.5,18.8284271 4.5,18 C4.5,17.1715729 5.17157288,16.5 6,16.5 C6.82842712,16.5 7.5,17.1715729 7.5,18 C7.5,18.8284271 6.82842712,19.5 6,19.5 Z M3.5,14 C2.67157288,14 2,13.3284271 2,12.5 C2,11.6715729 2.67157288,11 3.5,11 C4.32842712,11 5,11.6715729 5,12.5 C5,13.3284271 4.32842712,14 3.5,14 Z M6,7.5 C5.17157288,7.5 4.5,6.82842712 4.5,6 C4.5,5.17157288 5.17157288,4.5 6,4.5 C6.82842712,4.5 7.5,5.17157288 7.5,6 C7.5,6.82842712 6.82842712,7.5 6,7.5 Z"
          fill="inherit"
          id={`path-1-solutions${unique}`}
        />
      </g>
    </g>
  </svg>
)

Solutions.propTypes = propTypes
export default Solutions
