import {gql} from "@apollo/client"

import {
  INTERCEPTOR_COMPUTED_RESOURCE_FIELDS_FRAGMENT,
  INTERCEPTOR_FACET_RESPONSE_FRAGMENT,
  INTERCEPTOR_RESOURCE_FIELDS_FRAGMENT,
} from "gql/fragments/interceptor"

export const GET_GS_INITIAL_FILTERS = gql`
  query globalSearchInitialFilters($searchInput: InterceptorSearchInput!) {
    pdpSearchInterceptorResources(searchInput: $searchInput) {
      fields {
        ...searchInterceptorFacets
      }
      filters
      numberFound
    }
  }
  ${INTERCEPTOR_FACET_RESPONSE_FRAGMENT}
`

export const GET_GS_DOCS = gql`
  query globalSearchDocs($searchInput: InterceptorSearchInput!) {
    pdpSearchInterceptorResources(searchInput: $searchInput) {
      fields {
        ...searchInterceptorFacets
      }
      filters
      numberFound
      resources {
        ...interceptorResourceFields
        ...interceptorComputedResourceFields
      }
      spellcheck
    }
  }
  ${INTERCEPTOR_FACET_RESPONSE_FRAGMENT}
  ${INTERCEPTOR_RESOURCE_FIELDS_FRAGMENT}
  ${INTERCEPTOR_COMPUTED_RESOURCE_FIELDS_FRAGMENT}
`

export const FilterCategoriesArray = [
  {
    facetId: "Products",
    label: "Products",
  },
  {
    facetId: "Documents",
    label: "Documents",
  },
  {
    facetId: "Videos",
    label: "Videos",
  },
  {
    facetId: "Software",
    label: "Tools and Software",
  },
  {
    facetId: "News",
    label: "News",
  },
]

export const FilterCategories = {
  Documents: {
    label: "Documents",
  },
  News: {
    label: "News",
  },
  Products: {
    label: "Products",
  },
  Software: {
    label: "Tools and Software",
  },
  Videos: {
    label: "Videos",
  },
}
