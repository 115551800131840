import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Email = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/communications/email-email${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-email${unique}`}
        xlinkHref={`#path-1-email${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-email${unique}`}>
        <path
          d="M3,4 L21,4 C21.5522847,4 22,4.44771525 22,5 L22,19 C22,19.5522847 21.5522847,20 21,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,5 C2,4.44771525 2.44771525,4 3,4 Z M19.2043182,6.0967975 L12,12.3799642 L4.79568183,6.0967975 C4.57299924,5.93349694 4.26009807,5.98163558 4.0967975,6.20431817 C3.93349694,6.42700076 3.98163558,6.73990193 4.20431817,6.9032025 L12,13.6200358 L19.7956818,6.9032025 C20.0183644,6.73990193 20.0665031,6.42700076 19.9032025,6.20431817 C19.7399019,5.98163558 19.4270008,5.93349694 19.2043182,6.0967975 Z"
          fill="inherit"
          id={`path-1-email${unique}`}
        />
      </g>
    </g>
  </svg>
)

Email.propTypes = propTypes
export default Email
