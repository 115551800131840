import {useCallback, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"

import {
  SET_IMPERSONATION_STATUS,
  SET_SHOW_IMPERSONATION,
  SET_SHOW_LOGIN_PANEL,
} from "lib/redux/actions"
import {
  GET_IMPERSONATION_STATUS,
  GET_LOGGED_IN,
  GET_LOGGING_IN,
  GET_USER, IS_USER_INFO_LOADING_COMPLETED,
  makeLocalizedStringSelector,
} from "lib/redux/selectors"
import {
  handleLoginRedirect,
  handleLogoutRedirect,
  handleProfileRedirect,
  handleRegisterRedirect,
} from "lib/utils/createpoint"
import END_IMPERSONATION_QUERY from "gql/queries/user/endImpersonate"
import useLazyQueryPromise from "lib/hooks/use-lazy-query-promise"

const INTERNAL_ORG_ID = "1001"
const getLogIn = makeLocalizedStringSelector({key: "Log in"})
const getLogOut = makeLocalizedStringSelector({key: "Log out"})
const getLogInQCAccount = makeLocalizedStringSelector({
  key: "Log in to your Qualcomm account.",
})

const useUserInfo = () => {
  const isImpersonating = useSelector(GET_IMPERSONATION_STATUS)
  const isLoggedIn = useSelector(GET_LOGGED_IN)
  const logIn = useSelector(getLogIn)
  const logOut = useSelector(getLogOut)
  const logInQCAccount = useSelector(getLogInQCAccount)
  const dispatch = useDispatch()
  const loading = useSelector(GET_LOGGING_IN)
  const userInfo = useSelector(GET_USER)
  const isUserInfoLoadingCompleted = useSelector(IS_USER_INFO_LOADING_COMPLETED)

  const impersonateOnClick = useCallback(() => {
    dispatch({type: SET_SHOW_IMPERSONATION, value: true})
    dispatch({type: SET_SHOW_LOGIN_PANEL, value: false})
  }, [dispatch])

  const [endImpersonateQuery] = useLazyQueryPromise(END_IMPERSONATION_QUERY)
  const endImpersonationClick = useCallback(() => {
    endImpersonateQuery().then(({data: endData}) => {
      if (endData?.deleteImpersonation?.status === 200) {
        handleLogoutRedirect()
      }
    })
  }, [dispatch, endImpersonateQuery])

  const {
    impersonationExpiration,
    firstName,
    impersonating,
    lastName,
    organization,
    username,
    userStatus,
  } = userInfo
  const userFullName = `${firstName} ${lastName}`
  const isFullyRegisteredUser = userInfo?.userStatus?.toUpperCase() === "FRU"
  const isLightRegisteredUser = userInfo?.userStatus?.toUpperCase() === "LRU"

  useEffect(() => {
    if (!loading && userInfo && impersonating !== isImpersonating) {
      dispatch({
        type: SET_IMPERSONATION_STATUS,
        value: impersonating,
      })
    }
  }, [dispatch, impersonating, isImpersonating, loading, userInfo])

  return {
    accountOnClick: handleProfileRedirect,
    endImpersonationClick,
    expires: impersonationExpiration,
    firstName,
    impersonateOnClick,
    isImpersonating: impersonating,
    isInternalUser: organization?.id === INTERNAL_ORG_ID,
    isLoggedIn,
    lastName,
    loading,
    logIn,
    logInQCAccount,
    logOut,
    loginOnClick: handleLoginRedirect,
    logoutOnClick: handleLogoutRedirect,
    registerOnClick: handleRegisterRedirect,
    userEmail: username,
    userFullName,
    isLightRegisteredUser,
    isFullyRegisteredUser,
    isUserInfoLoadingCompleted,
  }
}

export default useUserInfo
