import styled from "styled-components";

import { shouldForwardProp } from "../shared";
import textProps from "./shared";
import { bodyVariants } from "./variants";

const Span = styled.span.withConfig({
  shouldForwardProp,
})`
  ${textProps};
  ${bodyVariants};
`;

export default Span;
