import { elementType } from "prop-types";
import styled, { css } from "styled-components";

import { responsiveStringOrNumberPropType } from "lib/prop-types";
import { defaultSystemProps, shouldForwardProp } from "./shared";

const buttonReset = css`
  background-color: transparent;
  padding: 0;
  margin: 0;
  color: inherit;
  border: none;
  font: inherit;
  line-height: normal;
  overflow: visible;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
`;

const Button = styled.button.withConfig({
  shouldForwardProp,
})`
  ${buttonReset};
  ${defaultSystemProps};
`;

const propTypes = {
  // Font size css style system prop
  fontSize: responsiveStringOrNumberPropType,
  // Polymorphic prop that determines what's ultimately being rendered
  tag: elementType,
};

Button.propTypes = propTypes;
Button.displayName = "Primitives.Button";

export default Button;
