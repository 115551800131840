import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const AddPersonUser = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/nav/add_person_user-add-person-user${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-add-person-user${unique}`}
        xlinkHref={`#path-1-add-person-user${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-add-person-user${unique}`}>
        <path
          d="M20,6 L21.4992073,6 C21.7753497,6 21.9992073,6.22385763 21.9992073,6.5 L21.9992073,7.5 C21.9992073,7.77614237 21.7753497,8 21.4992073,8 L20,8 L20,9.5 C20,9.77614237 19.7761424,10 19.5,10 L18.5,10 C18.2238576,10 18,9.77614237 18,9.5 L18,8 L16.4992073,8 C16.223065,8 15.9992073,7.77614237 15.9992073,7.5 L15.9992073,6.5 C15.9992073,6.22385763 16.223065,6 16.4992073,6 L18,6 L18,4.5 C18,4.22385763 18.2238576,4 18.5,4 L19.5,4 C19.7761424,4 20,4.22385763 20,4.5 L20,6 Z M15.9918333,19.56 C16.0863333,20.1456 15.3548333,21 14.593,21 L3.407,21 C2.64516667,21 1.91366667,20.1444 2.00816667,19.56 C2.02566667,19.4904 2.04083333,19.4184 2.0595,19.35 C2.8715,16.3656 5.46966667,14.0028 8.587,13.8204 C8.72466667,13.8132 8.86116667,13.8 9,13.8 C9.13883333,13.8 9.2765,13.8132 9.413,13.8204 C12.5315,14.0028 15.1285,16.3656 15.9405,19.3488 C15.9591667,19.4184 15.9755,19.4892 15.9918333,19.56 Z M9,12.59988 C6.42283333,12.59988 4.33333333,10.45068 4.33333333,7.79988 C4.33333333,5.14908 6.42283333,2.99988 9,2.99988 C11.5771667,2.99988 13.6013333,5.13348 13.6666667,7.79988 C13.6666667,10.45068 11.5771667,12.59988 9,12.59988 Z"
          fill="inherit"
          id={`path-1-add-person-user${unique}`}
        />
      </g>
    </g>
  </svg>
)

AddPersonUser.propTypes = propTypes
export default AddPersonUser
