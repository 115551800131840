import {gql} from "@apollo/client"

const GET_USER_INFO = gql`
  query getUserInfo {
    userInfo {
      username
      firstName
      lastName
      userStatus
      activeAgreements {
        eca
      }
      impersonating
      impersonator
      impersonationExpiration
      organization {
        id
        name
        verified
      }
      qcGuid
      qlaSigned
      canAccessSalesCenter
    }
  }
`

export default GET_USER_INFO
