
import React, {useCallback, useEffect, useState, useContext} from "react"
import {useSelector} from "react-redux"
import styled from "styled-components"

import useOnOffSwitch from "lib/hooks/use-on-off-switch"
import useSearchQuery from "lib/hooks/use-search-query"
import {GET_BREADCRUMB_DATA, GET_BREADCRUMB_HIDE_SHOW, GET_SHOW_LOGIN_PANEL} from "lib/redux/selectors"
import QualcommThemeContext from "components/QualcommThemeContext/ThemeContext"
import Primitives from "primitives"
import Autosuggest from "components/navigation/global-search-nav/autosuggest"
import SearchInput from "components/search-input"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { bool, func, string } from "prop-types"
import {Icon} from "components/dx/atoms/Icon"
import { hasData } from "lib/utils/helpers"

const glyphStyles = {
  cursor: "pointer",
  fill: "mainMenuIconFill"
}

const StyledSearchInput = styled(SearchInput)`
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  font-stretch: 110;
  font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
  width: 240px;
`

const FadeIn = styled(Primitives.Flex)`
  transition: opacity 166ms linear 166ms;
`
const SearchAnimation = styled(Primitives.Flex)`
  transition: width 333ms cubic-bezier(0.6,0.0,0.61,1.0);
`

const DesktopSearchComponent = ({onClick, closeText, isActive, searchText}) => {

  const results = useSelector(GET_BREADCRUMB_DATA)
  const hideshowbreadcrumb = useSelector(GET_BREADCRUMB_HIDE_SHOW)

  const value = useContext(QualcommThemeContext)
  const {pathname, search} = useLocation()
  const showingLoginPanel = useSelector(GET_SHOW_LOGIN_PANEL)
  const isSearchActive = isActive && !showingLoginPanel
  const isOnSearchPage = pathname === value.searchResultsPagePath

  const {
    searchQuery,
    searchValue,
    setActiveCategory,
    setSearchQuery,
    setSearchValue,
  } = useSearchQuery(value.searchResultsPagePath)

  const [isFocused, onFocus, onBlur] = useOnOffSwitch(false)
  const [searchInputChanged, setSearchBarChanged] = useState(false)

  // Used to hide the autosuggest on submit regardless of the query value
  const setUrlQuery = useCallback(
    () => {
      onBlur()
      setSearchQuery(searchValue || "")
    },
    [onBlur, searchValue, setSearchQuery],
  )

  useEffect(() => {
    setSearchValue(searchQuery)
  }, [searchQuery, setSearchValue])


  useEffect(() => {
    if (isActive && !isOnSearchPage) {
      setSearchValue("")
    }
  }, [isActive, isOnSearchPage, setSearchValue])

  useEffect(() => {
    onBlur()
  }, [onBlur, search])

  const handleChange = useCallback(
    ({target}) => {
      onFocus()
      if (target.value !== searchValue) {
        setSearchBarChanged(true)
        setSearchValue(target.value)
      }
    },
    [onFocus, searchValue, setSearchValue],
  )

  const onSearchBlur = useCallback(() => {
    onBlur()
    setSearchBarChanged(false)
  }, [onBlur, setSearchBarChanged])

  return (
    <>
      <SearchAnimation
        height="24px"
        width={isActive ? "280px" : "24px"}
        overflow={isActive ? "visible" : "hidden"}
        position="relative"
        flex="1 0 auto"
        justifyContent="flex-start"
      >
        <Primitives.Flex width="280px" justifyContent="flex-start" flexDirection="row" alignItems="center" data-test-id="gs-nav-wrapper"
          onBlur={onSearchBlur}
        >
          <Primitives.Button
            data-test-id="global-search-icon"
            aria-expanded={isActive}
            aria-label={isActive ? closeText : searchText}
            isActive={isActive}
            onClick={onClick}
            height="24px"
            width="24px"
            {...glyphStyles}
          >
            <Icon
              glyphId="dx-search"
              size="large"
              variant="dark-gray"
            />
          </Primitives.Button>
          <FadeIn
            alignItems="center"
            flex="0 1 auto"
            flexDirection="row"
            justifyContent="flex-start"
            opacity={isActive ? 1 : 0}
            width="262px"
          >
            {isActive ? (
              <>
                <StyledSearchInput
                  isAutoFocused
                  onChange={handleChange}
                  onFocus={onFocus}
                  onSubmit={setUrlQuery}
                  placeholder={"Search"}
                  showSearchTips
                  value={searchValue}
                  searchLabel=""
                />
                <Primitives.Button
                  data-test-id="global-search-icon"
                  aria-label={closeText}
                  onClick={onClick}
                  position="absolute"
                  right="0px"
                  {...glyphStyles}
                >
                  <Icon
                    glyphId="dx-close"
                    variant="dark-gray"
                  />
                  {/* <WosCross height="24px" width="24px" /> */}
                </Primitives.Button>
              </>
            ) : null}
          </FadeIn>
        </Primitives.Flex>
      </SearchAnimation>
      
      {isSearchActive  ? (
        <Primitives.Flex position="absolute" top={hasData(results) && hideshowbreadcrumb && !isOnSearchPage ? "calc(100% - 40.67px)" : "100%"} width="333px">
          <Autosuggest
            isSearchFocused={isFocused}
            searchInputChanged={searchInputChanged}
            searchValue={searchValue}
            setActiveCategory={setActiveCategory}
            setUrlQuery={setUrlQuery}
            wosFlagDesktop
          />
        </Primitives.Flex>
      ) : null}
    </>
  )
}
DesktopSearchComponent.propTypes = {
  onClick: func,
  closeText: string,
  isActive: bool,
  searchText: string
}

export default DesktopSearchComponent