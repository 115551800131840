import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Watch = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/devices/watch-watch${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-watch${unique}`}
        xlinkHref={`#path-1-watch${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-watch${unique}`}>
        <path
          d="M17.8057188,6.31935732 C18.1297279,6.48480251 18.3515787,6.82175639 18.3515787,7.21052632 L18.3515787,16.7894737 C18.3515787,17.1318516 18.1795158,17.4340421 17.9171436,17.6142915 C17.4295557,18.6585038 16.9471248,19.8929556 16.4698509,21.3176468 C16.3333031,21.7252637 15.9515232,22 15.5216432,22 L8.57466904,21.9999931 C8.13342848,21.9999931 7.7443392,21.7107966 7.61713307,21.28829 C7.16224942,19.7774244 6.71182497,18.5748753 6.26585971,17.6806427 C5.94185064,17.5151975 5.71999979,17.1782436 5.71999979,16.7894737 L5.71999979,7.21052632 C5.71999979,6.8681484 5.8920627,6.56595793 6.15443496,6.38570848 C6.64202283,5.34149621 7.12445372,4.10704444 7.60172763,2.68235316 C7.73827542,2.27473635 8.12005535,2 8.54993529,2 L15.4969095,2.00000687 C15.9381501,2.00000687 16.3272393,2.28920337 16.4544455,2.71170999 C16.9093291,4.22257555 17.3597536,5.42512467 17.8057188,6.31935732 Z M7.27263137,7.26315789 C6.99648899,7.26315789 6.77263137,7.48701552 6.77263137,7.76315789 L6.77263137,16.2368421 C6.77263137,16.5129845 6.99648899,16.7368421 7.27263137,16.7368421 L16.7989472,16.7368421 C17.0750895,16.7368421 17.2989472,16.5129845 17.2989472,16.2368421 L17.2989472,7.76315789 C17.2989472,7.48701552 17.0750895,7.26315789 16.7989472,7.26315789 L7.27263137,7.26315789 Z"
          fill="inherit"
          id={`path-1-watch${unique}`}
        />
      </g>
    </g>
  </svg>
)

Watch.propTypes = propTypes
export default Watch
