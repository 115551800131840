import React from "react"
import { string } from "prop-types"

const propTypes = {
  unique: string
}

const YoutubeWOS = ({ unique = "", ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="inherit"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M15.7972 5.15233C17.6795 7.6948 18.609 10.5626 18.2615 13.8642C18.2601 13.8782 18.2522 13.891 18.2397 13.8994C16.8142 14.861 15.4332 15.4445 14.0715 15.8315C14.0609 15.8345 14.0496 15.8343 14.0391 15.8311C14.0286 15.8278 14.0195 15.8216 14.013 15.8133C13.6984 15.4112 13.4126 14.9873 13.1622 14.5421C13.1478 14.5159 13.1609 14.4843 13.1905 14.474C13.6445 14.3168 14.0761 14.1285 14.4913 13.9055C14.524 13.8879 14.5261 13.8449 14.4959 13.8242C14.4078 13.7641 14.3205 13.7009 14.237 13.6378C14.2213 13.6261 14.2003 13.6238 14.1826 13.6316C11.487 14.7752 8.53405 14.7752 5.80656 13.6316C5.78885 13.6244 5.76781 13.6269 5.7526 13.6383C5.66927 13.7015 5.58177 13.7641 5.49447 13.8242C5.46427 13.8449 5.46677 13.8879 5.49968 13.9055C5.91489 14.1243 6.34656 14.3168 6.79989 14.4747C6.82926 14.4851 6.84322 14.5159 6.82864 14.5421C6.58364 14.9879 6.29781 15.4118 5.97739 15.8139C5.96343 15.8302 5.94052 15.8377 5.91885 15.8315C4.56364 15.4445 3.1826 14.861 1.75719 13.8994C1.74531 13.891 1.73677 13.8776 1.73552 13.8636C1.44511 11.0078 2.03698 8.11625 4.1976 5.15175C4.20281 5.14391 4.21073 5.13778 4.21989 5.13415C5.28302 4.6859 6.42197 4.35613 7.61239 4.1678C7.63405 4.16474 7.65572 4.17392 7.66697 4.19153C7.81405 4.43077 7.98218 4.73758 8.09593 4.9883C9.35072 4.81222 10.6251 4.81222 11.9061 4.9883C12.0199 4.74294 12.1822 4.43077 12.3286 4.19153C12.3338 4.1828 12.3419 4.1758 12.3517 4.17154C12.3615 4.16727 12.3726 4.16597 12.3832 4.1678C13.5743 4.3567 14.7132 4.68648 15.7755 5.13415C15.7849 5.13778 15.7926 5.14391 15.7972 5.15233ZM8.73385 10.5822C8.74697 9.73793 8.07697 9.03935 7.23593 9.03935C6.40176 9.03935 5.73822 9.73181 5.73822 10.5822C5.73822 11.4323 6.41489 12.1248 7.23593 12.1248C8.0703 12.1248 8.73385 11.4323 8.73385 10.5822ZM14.2718 10.5822C14.2849 9.73793 13.6149 9.03935 12.774 9.03935C11.9397 9.03935 11.2761 9.73181 11.2761 10.5822C11.2761 11.4323 11.9528 12.1248 12.774 12.1248C13.6149 12.1248 14.2718 11.4323 14.2718 10.5822Z"
      fill="inherit" />
  </svg>
)

YoutubeWOS.propTypes = propTypes
export default YoutubeWOS
