import {useEffect, useRef, useState} from "react"
import ResizeObserver from "resize-observer-polyfill"

export const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export const useMeasure = () => {
  const ref = useRef()
  const [scroll, setScroll] = useState({
    scrollHeight: 0,
    scrollWidth: 0,
  })
  const [bounds, set] = useState({
    height: 0,
    left: 0,
    scrollHeight: 0,
    scrollWidth: 0,
    top: 0,
    width: 0,
  })
  const [ro] = useState(
    () =>
      new ResizeObserver(([entry]) => {
        set(entry.contentRect)
        setScroll({
          scrollHeight: entry.target.scrollHeight,
          scrollWidth: entry.target.scrollWidth,
        })
      }),
  )
  useEffect(() => {
    if (ref.current) ro.observe(ref.current)
    return () => ro.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return [{ref}, bounds, scroll]
}
