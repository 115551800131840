import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const NoteAdd = ({unique = "", ...props}) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs fill="inherit" />
    <g
      fill="inherit"
      fillRule="evenodd"
      id={`glyphs/documents/note_add-note-add${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <use
        fill="inherit"
        id={`combined-shape-note-add${unique}`}
        xlinkHref={`#path-1-note-add${unique}`}
      />
      <g fill="inherit" id={`colors/core/gunmetal-note-add${unique}`}>
        <path
          d="M13,14 L13,11.5 C13,11.2238576 12.7761424,11 12.5,11 L11.5,11 C11.2238576,11 11,11.2238576 11,11.5 L11,14 L8.5,14 C8.22385763,14 8,14.2238576 8,14.5 L8,15.5 C8,15.7761424 8.22385763,16 8.5,16 L11,16 L11,18.5 C11,18.7761424 11.2238576,19 11.5,19 L12.5,19 C12.7761424,19 13,18.7761424 13,18.5 L13,16 L15.5,16 C15.7761424,16 16,15.7761424 16,15.5 L16,14.5 C16,14.2238576 15.7761424,14 15.5,14 L13,14 Z M5,2 L13.7928932,2 C13.9255015,2 14.0526784,2.05267842 14.1464466,2.14644661 L19.8535534,7.85355339 C19.9473216,7.94732158 20,8.07449854 20,8.20710678 L20,21 C20,21.5522847 19.5522847,22 19,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,3 C4,2.44771525 4.44771525,2 5,2 Z M13,4 L13,9 L18,9 L13,4 Z"
          fill="inherit"
          id={`path-1-note-add${unique}`}
        />
      </g>
    </g>
  </svg>
)

NoteAdd.propTypes = propTypes
export default NoteAdd
