import React from "react"
import {string} from "prop-types"

const propTypes = {
  unique: string,
}

const Phone = ({unique = "", ...props}) => (
  <svg viewBox="0 0 46 72" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      fillRule="evenodd"
      id={`desktop-phone${unique}`}
      stroke="none"
      strokeWidth={1}
    >
      <g
        id={`1440px-rffe-sub-cat-products-v1-phone${unique}`}
        transform="translate(-486.000000, -829.000000)"
      >
        <g
          id={`block-3-phone${unique}`}
          transform="translate(431.000000, 777.000000)"
        >
          <g
            id={`content-phone${unique}`}
            transform="translate(32.000000, 48.000000)"
          >
            <g
              id={`page-1-phone${unique}`}
              transform="translate(23.000000, 4.000000)"
            >
              <polygon
                fill="#FFFFFF"
                fillOpacity={0}
                id={`fill-1-phone${unique}`}
                points="0 72 46 72 46 0 0 0"
              />
              <g
                id={`echo/01-phone${unique}`}
                transform="translate(16.000000, 0.000000)"
              >
                <path
                  d="M5.52631579,14.5000056 C5.52631579,9.44223397 9.76782685,5.34210526 14.9999971,5.34210526 C20.2321732,5.34210526 24.4736842,9.44223397 24.4736842,14.5000056 C24.4736842,19.557766 20.2321732,23.6578947 14.9999971,23.6578947 C9.76782685,23.6578947 5.52631579,19.557766 5.52631579,14.5000056 Z"
                  fill="#CCD9FD"
                  id={`fill-3-phone${unique}`}
                />
                <path
                  d="M15,29 C12.9749994,29 11.013751,28.6181374 9.16125,27.86168 C7.37625039,27.1306739 5.77124861,26.0869089 4.39500058,24.7558306 C3.01875255,23.4211213 1.93499879,21.8718334 1.18124792,20.1443456 C0.397498403,18.3586643 0,16.4566134 0,14.5 C0,12.5433923 0.397498403,10.6449724 1.18124792,8.85566016 C1.93499879,7.12816661 3.01875255,5.57888441 4.39500058,4.24780611 C5.77124861,2.91672782 7.37625039,1.86932609 9.16125,1.13832577 C11.013751,0.381868322 12.9749994,0 15,0 C17.0250006,0 18.9899981,0.381868322 20.83875,1.13832577 C22.6274987,1.86932609 24.2287514,2.91672782 25.6087485,4.24780611 C26.9850024,5.57888441 28.0650012,7.12816661 28.8225012,8.85566016 C29.6062507,10.6449724 30,12.5433923 30,14.5 C30,16.4566134 29.6062507,18.3586643 28.8225012,20.1443456 C28.0650012,21.8718334 26.9850024,23.4211213 25.6087485,24.7558306 C24.2287514,26.0869089 22.6274987,27.1306739 20.83875,27.86168 C18.9899981,28.6181374 17.0250006,29 15,29 Z M15,0.440056032 C6.98250108,0.440056032 0.457501609,6.74993502 0.457501609,14.5 C0.457501609,22.2537017 6.98250108,28.559944 15,28.559944 C23.0212539,28.559944 29.5462534,22.2537017 29.5462534,14.5 C29.5462534,6.74993502 23.0212539,0.440056032 15,0.440056032 Z"
                  fill="#7BA0FF"
                  id={`fill-4-phone${unique}`}
                />
                <path
                  d="M10.2631579,14.5000028 C10.2631579,11.9711189 12.3839115,9.92105263 14.999997,9.92105263 C17.6160826,9.92105263 19.7368421,11.9711189 19.7368421,14.5000028 C19.7368421,17.0288811 17.6160826,19.0789474 14.999997,19.0789474 C12.3839115,19.0789474 10.2631579,17.0288811 10.2631579,14.5000028 Z"
                  fill="#3253DC"
                  id={`fill-5-phone${unique}`}
                />
              </g>
              <path
                d="M30.4587106,71.5234283 C2.54770639,71.5234283 2.54770639,71.5234283 2.54770639,71.5234283 C1.51323224,71.5234283 0.6254068,70.6962033 0.6254068,69.672024 C0.6254068,14.724738 0.6254068,14.724738 0.6254068,14.724738 C0.6254068,13.700556 1.51323224,12.87333 2.54770639,12.87333 C30.4587106,12.87333 30.4587106,12.87333 30.4587106,12.87333 C31.4931875,12.87333 32.3334451,13.700556 32.3334451,14.724738 C32.3334451,69.672024 32.3334451,69.672024 32.3334451,69.672024 C32.3334451,70.6962033 31.4931875,71.5234283 30.4587106,71.5234283 Z"
                fill="#7BA0FF"
                id={`fill-6-phone${unique}`}
              />
              <path
                d="M13.5119769,66.4779033 C19.4429733,66.4779033 19.4429733,66.4779033 19.4429733,66.4779033 C19.987938,66.4779033 20.4334616,66.9104883 20.4334616,67.4453061 C20.4334616,67.9761819 19.987938,68.4126864 19.4429733,68.4126864 C13.5119769,68.4126864 13.5119769,68.4126864 13.5119769,68.4126864 C12.9670122,68.4126864 12.5254663,67.9761819 12.5254663,67.4453061 C12.5254663,66.9104883 12.9670122,66.4779033 13.5119769,66.4779033 Z M3.5759002,14.88402 C3.5759002,14.88402 3.5759002,14.88402 29.3830502,14.88402 C29.9260486,14.88402 30.3184557,15.320997 30.3184557,15.805224 C30.3184557,15.805224 30.3184557,15.805224 30.3184557,62.597637 C30.3184557,63.0818613 29.9260486,63.5188428 29.3830502,63.5188428 C29.3830502,63.5188428 29.3830502,63.5188428 3.5759002,63.5188428 C3.0368298,63.5188428 2.64047216,63.0818613 2.64047216,62.597637 C2.64047216,62.597637 2.64047216,62.597637 2.64047216,15.805224 C2.64047216,15.320997 3.0368298,14.88402 3.5759002,14.88402 Z"
                fill="#CCD9FD"
                id={`fill-7-phone${unique}`}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

Phone.propTypes = propTypes
export default Phone
